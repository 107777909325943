import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SimpleCellSelection, SimpleCellSelectionItem } from '../../models/components.model';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-simple-cell-selection',
    templateUrl: './simple-cell-selection.component.html',
    styleUrls: ['./simple-cell-selection.component.scss'],
    standalone: true,
    imports: [NgFor]
})
export class SimpleCellSelectionComponent implements OnInit {
    @Input()
    public items: SimpleCellSelectionItem[];

    @Input()
    public selected: SimpleCellSelection;

    @Output()
    public selectionChange: EventEmitter<SimpleCellSelection> = new EventEmitter<SimpleCellSelection>();

    constructor() {
    }

    public ngOnInit() {
    }

    public onItemClick(event, item: SimpleCellSelectionItem) {
        event.preventDefault();

        const index = this.items.indexOf(item);
        this.selectionChange.emit({
            item,
            index,
        });
    }
}
