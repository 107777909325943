/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 7/4//17.
 */

import { Action } from '@ngrx/store';
import { EntitiesState } from '../../../../core/store/state/entities.state';
import {
    AddAllTradesToSelectionAction,
    AddTradeToSelectionAction,
    RemoveTradeFromSelectionAction,
    TradeActionTypes,
} from '../actions/trade.actions';
import { TradeMetaState, TradeState } from '../state/trades.state';

export const initialState: TradeMetaState = {
    selections: [],
};

/**
 * The reducer responsible for the entities part of the @link{TradeState}
 * @param state
 * @param action
 * @returns {EntitiesState<TradeState>}
 */
export function tradesModuleMetaReducer(state: TradeMetaState = initialState,
                                        action: Action): TradeMetaState {

    switch (action.type) {

        case TradeActionTypes.ADD_TRADE_TO_SELECTION:
            const addTradeToSelectionAction = action as AddTradeToSelectionAction;
            return {
                ...state,
                selections: [...state.selections, addTradeToSelectionAction.payload.id],
            };

        case TradeActionTypes.REMOVE_TRADE_FROM_SELECTION:
            const removeTradeFromSelectionAction = action as RemoveTradeFromSelectionAction;
            return {
                ...state,
                selections: state.selections.filter((id) => id !== removeTradeFromSelectionAction.payload.id),
            };

        case TradeActionTypes.ADD_ALL_TRADES_TO_SELECTION:
            const addAllTradesToSelectionAction = action as AddAllTradesToSelectionAction;
            return {
                ...state,
                selections: addAllTradesToSelectionAction.payload.ids,
            };

        case TradeActionTypes.REMOVE_ALL_TRADES_FROM_SELECTION:
            return {
                ...state,
                selections: [],
            };

        default:
            return state;
    }
}
