import { TabConfig } from "../../../../shared/models/tabs.model";
import { TargetMarketCustomerData } from "../../../target-market/models/target-market-customer-data.model";
import { TargetMarketCustomCustomer } from "../../../target-market/models/target-market-customer.model";

export interface CustomerSelectionState {
    tab: {
        selected?: CustomerType;
        list: CustomerTabConfig[];
        link?: any[];
    }
    activated: {
        label: string;
        labelParams?: LabelParams;
        link: any[];
        customer: TargetMarketCustomCustomer | TargetMarketCustomerData;
    }
}

export enum CustomerTypeList {
    own = 'own',
    reference = 'reference',
    hal = 'hal'
}

export type CustomerType = keyof typeof CustomerTypeList;

export interface CustomerTabConfig extends TabConfig {
    id: CustomerType;
}

export interface LabelParams {
    customerId?: string;
    customerName: string;
}
