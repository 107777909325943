import { DatePipe, DecimalPipe, PercentPipe, NgIf, NgSwitch, NgStyle } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
} from '@angular/core';
import { get } from 'lodash';
import { combineLatest, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, startWith, tap } from 'rxjs/operators';
import { ConfigurationService } from '../../../core/services/configuration.service';
import { DynamicChartAxisTickFormat, DynamicChartConfig, DynamicChartData, ExtendedDynamicChartReferenceLine, isDynamicChartData } from '../../models/dynamic-chart.model';
import { EChartsOption } from 'echarts';
import { isDate } from '../../helpers/date.helper';
import { TranslocoService } from '@ngneat/transloco';
import { TooltipFormatterCallback, TooltipOption, TopLevelFormatterParams, XAXisOption, YAXisOption } from 'echarts/types/dist/shared';
import { HEXtoRGB, rgbToString } from '../../../core/helpers/colors.helper';
import { NgxEchartsModule } from 'ngx-echarts';

const LINE_CHART_MAX_HEIGHT = 400;
const LINE_CHART_MIN_HEIGHT = 150;

/**
 * Component that displays a dynamic chart based on the configuration and data it is given
 */
@Component({
    selector: 'app-dynamic-chart',
    templateUrl: './dynamic-chart.component.html',
    styleUrls: ['./dynamic-chart.component.scss'],
    standalone: true,
    imports: [NgIf, NgSwitch, NgStyle, NgxEchartsModule]
})
export class DynamicChartComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

    @ContentChild('tooltipTemplate') public tooltipTemplateRef: TemplateRef<any>;

    /* Component Classes */

    @HostBinding('class.dynamic-chart') public dynamicChartClass = true;

    /**
     * The chart's config
     */
    @Input() public config: DynamicChartConfig;

    /**
     * The chart's data
     */
    @Input() public data: DynamicChartData | any[];

    @Output()
    public selectControl: EventEmitter<any> = new EventEmitter();

    @Output()
    public colorsChange: EventEmitter<any> = new EventEmitter();

    @Input()
    public referenceLines: ExtendedDynamicChartReferenceLine[];

    @Input()
    public useParentWidth = false;

    @Input()
    public maxChartHeight = LINE_CHART_MAX_HEIGHT;

    @Input()
    public offsetHeight = 0;

    @Input()
    public tooltipFormatter: TooltipFormatterCallback<TopLevelFormatterParams>;

    @Input()
    public formatXAxis: (value: any, tooltip: boolean) => string;

    @Input()
    public formatYAxis: (value: any, tooltip: boolean) => string;

    /**
     * List of transformed data
     */
    public transformedData: any[];
    public transformedAggregation: any;

    /**
     * List of color schemes
     */
    public colorScheme: string[];

    /**
     * List of colors
     */
    public colors: { name: string, value: string }[];

    public elementWidth = 250;
    public elementHeight = 300;
    public parentHeight = 0;

    public resize$: Observable<Event>;
    public afterViewChecked$: Subject<void>;
    public subscription = new Subscription();
    public chartView: number[];

    public loading = false;
    public echartsInstance: any;
    public echartsOptions: EChartsOption = {};
    public categoryData = [];

    constructor(
        protected chartElement: ElementRef,
        private datePipe: DatePipe,
        private decimalPipe: DecimalPipe,
        private percentPipe: PercentPipe,
        private element: ElementRef<HTMLElement>,
        private appConfigService: ConfigurationService,
        private translocoService: TranslocoService
    ) {
        this.resize$ = fromEvent(window, 'resize');
        this.afterViewChecked$ = new Subject<void>();

        this.subscription.add(combineLatest([
            this.resize$.pipe(startWith(null as Event)),
            this.afterViewChecked$.asObservable(),
        ]).pipe(
            debounceTime(200),
            tap(() => {
                this.evaluateElementDimensions();
                this.calculateChartViewDimensions();
            }),
        ).subscribe());
    }

    public get xAxisDataType() {
        
        if (this.config.chartType === 'bar') {
            return 'category';
        }
        
        const xAxisValue = this.data[0]?.values[0]?.x;
        
        if (
            typeof xAxisValue === 'string' &&
            isDate(xAxisValue)
        ) {
            return 'time';
        }

        return 'value';
    }

    public get yAxisDataType() {
        
        if (this.config.chartType === 'horizontal-bar') {
            return 'category';
        }
        
        const yAxisValue = this.data[0]?.values[0]?.y;

        if (
            typeof yAxisValue === 'string' &&
            isDate(yAxisValue)
        ) {
            return 'time';
        }

        return 'value';
    }

    public get xAxisConfig(): XAXisOption {
        return {
            type: this.xAxisDataType,
            axisLabel: {
                show: this.config.chartType !== 'horizontal-bar',
                rotate: 40,
                margin: 10,
                hideOverlap: true,
                formatter: (value, index) => {
                    return this._formatAxisTick(value, this.xAxisTickFormat);
                }
            } as any,
            scale: true,
            boundaryGap: false,
            axisLine: {onZero: false},
            splitLine: {show: true},
            min: this.config.options.xAxisMin,
            max: this.config.options.xAxisMax,
            axisPointer: {
                z: 100
            },
            ...(
                this.config.chartType === 'bar' ? {
                    data: this.categoryData,
                    scale: false,
                } : {
                    scale: this.config.chartType !== 'horizontal-bar',
                }
            ),
        };
    }

    public get yAxisConfig(): YAXisOption {

        return {
            type: this.yAxisDataType,
            min: this.config.options.yAxisMin,
            max: this.config.options.yAxisMax,
            ...(
                this.config.chartType !== 'horizontal-bar' ? {} : {
                    data: this.categoryData,
                    axisLabel: {
                        margin: 20,
                        width: '90',
                        overflow: 'truncate',
                    } as any,
                }
            ),
        };
    }

    public get yAxisTickFormat(): DynamicChartAxisTickFormat {
        return this.config?.options?.yAxisTickFormat ?? {
            type: 'number',
            format: '1.2-3',
        };
    }

    public get xAxisTickFormat(): DynamicChartAxisTickFormat {
        return this.config?.options?.xAxisTickFormat ?? (this.xAxisDataType === 'time' ? {
            type: 'date',
            format: 'shortDate',
        } : {
            type: 'number',
            format: '1.2-3',
        });
    }

    public get tooltipOptions(): TooltipOption {
        return {
            trigger: this.config.chartType === 'bubble' ? 'item' : 'axis',
            axisPointer: {
                animation: true,
                type: this.config.chartType === 'horizontal-bar' ? 'shadow' : 'line',
            },
            extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3) !important;',
            appendToBody: true,
            transitionDuration: 0,
            formatter: (params, asyncTicket) => {
                if (!Array.isArray(params)) {
                    params = [params];
                }

                if (this.tooltipFormatter) {
                    return this.tooltipFormatter(params, asyncTicket);
                }

                let view = '<div style="margin: 0px 0 0;line-height:1;"><div style="margin: 0px 0 0;line-height:1;">';
                const d = params[0];

                if (d) {
                    const val = Array.isArray(d.value) ? d.value[0] : d.value;
                    const nameFormatted = (d.name && d.name.length) ? d.name : this.formatXAxisTick(val, true);
                    view += '<div style="font-size:14px;color:#666;font-weight:400;line-height:1;">' + nameFormatted + '</div>';
                }

                view += '<div style="margin: 10px 0 0;line-height:1;">';
                params.forEach((param) => {
                    const value = Array.isArray(param.value) ? param.value[1] : param.value;
                    const valueFormat = param.axisDim === 'x' ? this.formatYAxisTick(value, true) : this.formatXAxisTick(value, true);


                    view += '<div style="margin: 0px 0 0;line-height:1;"><div style="margin: 0px 0 0;line-height:1;">';
                    view += param.marker;
                    view += '<span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">' + param.seriesName + '</span>';
                    view += '<span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">' + valueFormat + '</span>';
                    view += '<div style="clear:both"></div>';
                    view += '</div><div style="clear:both"></div></div>';
                });

                view += '<div style="clear:both"></div></div>';
                view += '<div style="clear:both"></div></div><div style="clear:both"></div></div>';

                return view;
            }
        };
    }

    public get currentLang() {
        return this.translocoService.getActiveLang();
    }

    @HostBinding('class.dynamic-chart--legend-below') get legendBelow() {
        return this.config && this.config.options && this.config.options.legendBelow;
    }

    public ngAfterViewInit() {
        this.afterViewChecked$.next();
    }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public onChartInit(ec) {
        this.echartsInstance = ec;
    }

    /**
     * Formatting function for the X axis ticks
     * @param value
     * @param tooltip
     * @return {string}
     */
    public formatXAxisTick = (value: any, tooltip = false): string => {
        if (this.formatXAxis) {
            return this.formatXAxis(value, tooltip);
        }

        return this._formatAxisTick(value, this.xAxisTickFormat);
    }

    /**
     * Formatting function for the Y axis ticks
     * @param value
     * @param tooltip
     * @return {string}
     */
    public formatYAxisTick = (value: any, tooltip = false): string => {
        if (this.formatYAxis) {
            return this.formatYAxis(value, tooltip);
        }

        return this._formatAxisTick(value, this.yAxisTickFormat);
    }

    public calculateChartViewDimensions() {
        if (this.config.chartType === 'line') {
            // try to approach a good value for the chart height here.
            this.elementHeight = Math.min(
                Math.max(Math.max(
                    LINE_CHART_MIN_HEIGHT,
                    this.elementWidth,
                ), this.parentHeight * 1.3),
                this.maxChartHeight,
            );

            this.chartView = [this.elementWidth, this.elementHeight];
        } else if (this.transformedData) {
            const height: number = Math.max(this.transformedData.length * 35 + 40, Math.max(250, this.elementHeight));
            this.chartView = [this.elementWidth, height];
        }
    }

    public ngOnInit(): void { }

    public ngOnChanges(changes: SimpleChanges) {
        if ((changes.config || changes.data) && this.config && this.data) {
            this._onUpdateData();
            this._onUpdateChartOptions();

            this.evaluateElementDimensions();
            this.calculateChartViewDimensions();

            this.loading = false;
        }
    }

    public onChartSelect(item) {
        this.selectControl.emit(item);
    }

    public pieChartLabelFormatting(name: string) {
        if (this.config && this.config.options && this.config.options.showValueInLabel) {
            const item = this.transformedData.find((d) => d.name === name);

            if (item && this.transformedAggregation) {
                const percentValue = Math.floor((item.value / this.transformedAggregation) * 100);
                return `${name} (${percentValue}%)`;
            }
        }

        return name;
    }

    public onChartRendered(event) {
    }

    private _onUpdateChartOptions() {
        const showLegend = this.config.options.showLegend !== false;
        const disableDataZoom = get(this.config.options, 'disableDataZoom', this.config.chartType !== 'line');
        const dataZoomPosition = get(this.config.options, 'dataZoomPosition', '8%');
        const legendPosition = get(this.config.options, 'legendPosition', 0);

        let gridHeight = '80%';
        if ((!showLegend && !disableDataZoom) || (showLegend && disableDataZoom)) {
            gridHeight = '90%';
        } else if (!showLegend && disableDataZoom) {
            gridHeight = '95%';
        }

        const primaryColor = this.appConfigService.configuration.environment.colorScheme.primary || undefined;
        const baseConfig = {
            tooltip: this.tooltipOptions as any,
            grid: {
                top: '5%',
                left: '5%',
                right: '8%',
                height: gridHeight,
                ...(this.config.chartType !== 'horizontal-bar' ? {
                    containLabel: true,
                    left: '5%',
                } : {
                    containLabel: false,
                    left: '120px',
                })
            },
            legend: {
                bottom: legendPosition,
                left: 'center',
                show: showLegend
            },
            dataZoom: !disableDataZoom ? [
                {
                    type: 'inside',
                    xAxisIndex: 0,
                    start: 0,
                    end: 100
                },
                {
                    show: true,
                    xAxisIndex: 0,
                    type: 'slider',
                    start: 0,
                    end: 100,
                    bottom: dataZoomPosition,
                    zlevel: 100,
                    borderColor: rgbToString(HEXtoRGB(primaryColor), 0.75),
                    fillerColor: rgbToString(HEXtoRGB(primaryColor), 0.25),
                    handleStyle: {
                        borderColor: rgbToString(HEXtoRGB(primaryColor), 0.75),
                    },
                    moveHandleStyle: {
                        color: rgbToString(HEXtoRGB(primaryColor), 0.5),
                    },
                    emphasis: {
                        moveHandleStyle: {
                            color: rgbToString(HEXtoRGB(primaryColor), 0.75),
                        }
                    },
                    labelFormatter: (value, valueStr) => {
                        return this.formatXAxisTick(value);
                    }
                }
            ] : null,
            series: this.transformedData,
        };

        if (this.config.chartType === 'custom') {
            this.echartsOptions = {
                ...baseConfig,
                ...this.config.chartConfig,
            };
            return;
        }

        this.echartsOptions = {
            ...baseConfig,
            xAxis: this.xAxisConfig as any,
            yAxis: this.yAxisConfig as any,
            color: this.colorScheme,
        };
    }

    private evaluateElementDimensions() {
        const host = this.useParentWidth ? this.element.nativeElement.closest('div') : this.element.nativeElement;
        const style = getComputedStyle(host);
        const rect = host.getBoundingClientRect();

        this.elementWidth = rect.width - parseInt(style.paddingLeft, 10) - parseInt(style.paddingRight, 10);

        if (!this.parentHeight) {
            this.parentHeight = rect.height;
        }

        if (!this.elementHeight) {
            this.elementHeight = rect.height;
        }
    }

    private _formatAxisTick(value, format: DynamicChartAxisTickFormat): string {
        if (!format) {
            return value.toString();
        }

        switch (format.type) {
            case 'number':
                return this.decimalPipe.transform(value, format.format, this.currentLang);

            case 'percent':
                return this.percentPipe.transform(value, format.format, this.currentLang);

            case 'date':
                return this.datePipe.transform(value, format.format, this.currentLang);

            case 'named':
                return get(
                    this.config.options.xAxisTickNameMap,
                    this.datePipe.transform(value, 'yyyy-MM-dd'),
                    this.datePipe.transform(value, 'dd.MM.yyyy'),
                );

            default:
                return value.toString();
        }
    }

    /**
     * Called when the graph data has changed
     * @private
     */
    private _onUpdateData() {
        this.colorScheme = this.appConfigService.configuration.environment.chartColors.default;
        const inputData = this.data as any[];

        if (this.config.chartType === 'custom') {
            this.transformedData = inputData;
            return;
        }

        if (this.config.chartType === 'line') {
            this.transformedData = inputData.map((series) => {
                const data = {
                    name: series.label,
                    showSymbol: false,
                    type: 'line',
                    emphasis: {
                        focus: 'series',
                        areaStyle: {}
                    },
                    encode: {
                        x: 0,
                        y: 1
                    },
                    data: series.values.map((value) => {
                        return [value.x, value.y];
                    }),
                };

                return data;
            });
        } else if (this.config.chartType === 'bar' || this.config.chartType === 'horizontal-bar') {
            this.transformedData = inputData.map((series) => {
                const sortedSeriesData = series.values.map((value) => {
                    return [value.x, value.y];
                }).sort((a, b) => {
                    return a[1] - b[1];
                });

                this.categoryData = sortedSeriesData.map((v) => v[0]);

                let barMaxWidth: any = '100%';
                if ((this.elementHeight || this.elementWidth) && this.config?.options?.minNumberOfGroups) {
                    const viewSize = this.config.chartType === 'bar' ? this.elementWidth : this.elementHeight;

                    barMaxWidth = Math.ceil(
                        (viewSize / this.config?.options?.minNumberOfGroups) - (viewSize * 0.1)
                    );
                }

                return {
                    name: series.label,
                    type: 'bar',
                    encode: {
                        x: 0,
                        y: 1,
                    },
                    label: {
                        show: true,
                        formatter: (params) => {
                            return this.formatXAxisTick(params.value);
                        },
                        position: 'insideLeft',
                    },
                    itemStyle: [{
                        normal: {
                            label: {
                                show: true,
                                position: 'inside',
                            }
                        }
                    }],
                    splitNumber: 3,
                    barMaxWidth,
                    data: sortedSeriesData.map((v) => v[1]),
                };
            });
        } else if (this.config.chartType === 'bubble') {
            this.transformedData = inputData.map((series) => {
                return {
                    name: series.label,
                    type: 'scatter',
                    encode: {
                        x: 0,
                        y: 1,
                    },
                    data: series.values.map((value) => {
                        return [value.x, value.y, (value.r || 1) * 20, value.meta || null];
                    }),
                    emphasis: {
                        focus: 'series',
                    },
                    symbolSize(data) {
                        return data[2];
                    },
                    selectedMode: 'multiple',
                };
            });

            this.colorScheme = this.appConfigService.configuration.environment.chartColors.bubble;
        } else if (this.config.chartType === 'pie') {
            this.transformedData = inputData.map((series) => {
                return {
                    name: series.label,
                    type: 'pie',
                    radius: '50%',
                    label: {
                        position: 'inside',
                    },
                    data: series.values.map((value) => {
                        return {
                            name: value.x,
                            value: value.y,
                        };
                    }),
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                };
            });
        }

        this.colors = inputData.map((series, i) => {
            return {
                name: series.label,
                value: this.colorScheme[Math.min(i, this.data.length)],
            };
        });

        this.colorsChange.emit(this.colors);
    }
}
