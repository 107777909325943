/**
 * Created by Alex Klein <alex@rocketloop.de> on 10/17/17.
 */

import { Action } from '@ngrx/store';
import {
    LoadRecommendationFilterStructureSucceededAction,
    LoadRecommendationFilterValuesSucceededAction,
    LoadRecommendationSucceededAction,
    LoadRecommendationTableStructureSucceededAction,
    LoadRecommendationTableValuesSucceededAction,
    RecommendationsActionTypes,
    UpdateRecommendationsListSucceededAction,
    UpdateRecommendationTypeAction,
} from '../actions/recommendation.actions';
import { RecommendationState } from '../state/recommendations.state';

export const initialState: RecommendationState = {
    filterStructures: null,
    filterValues: null,
    tableStructures: null,
    tableValues: {},
    recommendationMapping: {},
    recommendationsUpdated: null,
};

/**
 * The reducer responsible for the @link{WatchlistState}
 */
export function reducer(state: RecommendationState = initialState, action: Action): RecommendationState {
    switch (action.type) {

        // Stores the recommendation filter structure
        case RecommendationsActionTypes.LOAD_RECOMMENDATION_FILTER_STRUCTURE_SUCCEEDED:
            const loadRecommendationFilterStructureSucceededAction = action as LoadRecommendationFilterStructureSucceededAction;
            return {
                ...state,
                filterStructures: loadRecommendationFilterStructureSucceededAction.payload.structure,
            };

        // Stores the recommendation filter values
        case RecommendationsActionTypes.LOAD_RECOMMENDATION_FILTER_VALUES_SUCCEEDED:
            const loadRecommendationFilterValuesSucceededAction = action as LoadRecommendationFilterValuesSucceededAction;
            return {
                ...state,
                filterValues: loadRecommendationFilterValuesSucceededAction.payload.values,
            };

        // Stores the recommendation table structure
        case RecommendationsActionTypes.LOAD_RECOMMENDATION_TABLE_STRUCTURE_SUCCEEDED:
            const loadRecommendationTableStructureSucceededAction = action as LoadRecommendationTableStructureSucceededAction;
            return {
                ...state,
                tableStructures: loadRecommendationTableStructureSucceededAction.payload.structure,
            };

        // Stores the recommendation table values
        case RecommendationsActionTypes.LOAD_RECOMMENDATION_TABLE_VALUES_SUCCEEDED:
            const loadRecommendationTableValuesSucceededAction = action as LoadRecommendationTableValuesSucceededAction;
            return {
                ...state,
                tableValues: loadRecommendationTableValuesSucceededAction.payload.entities.reduce(
                    (products, product) => {
                        products[product.id] = {
                            ...products[product.id],
                            ...product,
                        };
                        return products;
                    }, {
                        ...state.tableValues,
                    }),
            };

        // Stores the recommendation list
        case RecommendationsActionTypes.LOAD_RECOMMENDATIONS_SUCCEEDED:
            const loadRecommendationListSucceededAction = action as LoadRecommendationSucceededAction;
            return {
                ...state,
                recommendationMapping: loadRecommendationListSucceededAction.payload.recommendations.reduce((list, recommendation) => {
                    list[recommendation.productDq] = recommendation.type;
                    return list;
                }, {...state.recommendationMapping}),
            };

        // Stores the recommendation type change
        case RecommendationsActionTypes.UPDATE_RECOMMENDATION_TYPE:
            const updateRecommendationTypeAction = action as UpdateRecommendationTypeAction;
            return {
                ...state,
                recommendationMapping: {
                    ...state.recommendationMapping,
                    [updateRecommendationTypeAction.payload.id]: updateRecommendationTypeAction.payload.status,
                },
            };

        // Stores the updated recommendation list
        case RecommendationsActionTypes.UPDATE_RECOMMENDATIONS_SUCCEEDED:
            const updateRecommendationsListSucceededAction = action as UpdateRecommendationsListSucceededAction;
            return {
                ...state,
                recommendationsUpdated: true,
            };

        default:
            return state;

        // Stores the updated recommendation list
        case RecommendationsActionTypes.UPDATE_RECOMMENDATIONS:
            return {
                ...state,
                recommendationsUpdated: false,
            };

    }
}
