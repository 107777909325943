import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, ValidationErrors, FormsModule } from '@angular/forms';
import * as moment from 'moment';
import { isMomentHoliday } from '../../../core/helpers/holidays.helper';
import { FormControlConfig } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';
import { TimeInputComponent } from '../time-input/time-input.component';
import { NgIf } from '@angular/common';
import { DateInputComponent } from '../date-input/date-input.component';

@Component({
    selector: 'app-filter-date-input',
    templateUrl: './filter-date-input.component.html',
    styleUrls: ['./filter-date-input.component.scss'],
    standalone: true,
    imports: [DateInputComponent, FormsModule, NgIf, TimeInputComponent]
})
export class FilterDateInputComponent implements OnInit, SingleValueFilterControl<string> {
    @Input() public config: FormControlConfig;
    @Input() public values: any;
    @Input() public disabled = false;
    @Output() public blurControl: EventEmitter<void> = new EventEmitter();

    @ViewChild('input', {static: true}) public dateInput: AbstractControl;

    public dateFormat = 'YYYY-MM-DD';
    public timeFormat = 'HH:mm';

    public label: string;
    public onChange: () => void;
    public onTouched: () => void;

    public value: moment.Moment;

    public set dateValue(dateValue) {
        this.value = moment();

        if (dateValue && dateValue.includes('-')) {
            const [year, month, date] = dateValue.split('-').map(value => parseInt(value, 10));

            this.value.set({
                year,
                month: month - 1,
                date
            });
        } else {
            this.value = null;
        }

        this.onChange();
    }

    public get dateValue(): string {
        if (this.value) {
            return this.value.format(this.dateFormat);
        } else {
            return '';
        }
    }

    public set timeValue(timeValue) {
        if (timeValue && timeValue.includes(':')) {
            if (!this.value) {
                this.value = moment();
            }

            const [hour, minute] = timeValue.split(':').map(value => parseInt(value, 10));
            this.value.set({hour, minute});

            this.onChange();
        }
    }

    public get timeValue(): string {
        if (this.value) {
            return this.value.format(this.timeFormat);
        } else {
            return '';
        }
    }

    constructor() {
    }

    public ngOnInit() {
    }

    public writeValue(value: string): void {
        if (value) {
            if (this.config.options?.showTimeInput) {
                this.value = moment(value);
            } else {
                this.value = moment(value, this.dateFormat);
            }
        } else {
            this.value = null;
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                if (this.value) {
                    if (this.config.options?.showTimeInput) {
                        fn(this.value?.toISOString(true));
                    } else {
                        fn(this.value?.format(this.dateFormat));
                    }
                } else {
                    fn(null);
                }
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
       this.disabled = isDisabled;
    }

    public validate(c: AbstractControl): ValidationErrors | any {
        return (this.dateInput.valid)
            ? null : {
                dateInput: 'Invalid value specified.',
            };
    }

    public dateFilter(date: moment.Moment) {
        if (this.config && this.config.options && this.config.options.filterHolidays && date) {
            return date.day() !== 0 && date.day() !== 6 && !isMomentHoliday(date);
        }

        return true;
    }

    onDateChange(value: string) {
        this.dateValue = value;
    }

    onTimeChange(value: string) {
        this.timeValue = value;
    }
}
