import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { get } from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { FormValues } from '../../../../core/models/form.model';
import { eventContainsFiles } from '../../../../routes/product-details/helpers/product-attachments.helper';
import { EntityEvent } from '../../../components/entity-table/entity-table.component';
import { ComponentRegistries, getInjectionTokenForComponentType } from '../../../helpers/dynamic-component.helper';
import { EntityWidgetAdditionalDataChannel, EntityWidgetOptions } from '../../../models/widgets.model';
import { SpinnerComponent } from '../../../components/spinner/spinner.component';
import { NgClass, NgIf, NgStyle } from '@angular/common';

const COLUMN_WIDTH_CONFIG = {
    1: 'col-lg-4 col-xxl-3 col-pr-4',
    2: 'col-lg-8 col-xxl-6 col-pr-8',
    3: 'col-lg-12 col-xxl-9 col-pr-12',
    4: 'col-lg-12 col-xxl-12 col-pr-12',
};

@Component({
    selector: 'app-entity-widget',
    templateUrl: './entity-widget.component.html',
    styleUrls: ['./entity-widget.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf, NgStyle, SpinnerComponent]
})
export class EntityWidgetComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    private _title: string;
    private _subTitle: string;

    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public refresh: boolean;
    @Input() public type: string;
    @Input() public options: EntityWidgetOptions;
    @Input() public data: any;
    @Input() public formValues: FormValues;
    @Input() public additionalData: EntityWidgetAdditionalDataChannel;
    @Input() public path: string;
    @Input() public infoText: string;
    @Input() public isLoadingData = false;

    @Input() set title(title: string) {
        this._title = title;
    }

    @Input() set subTitle(subtitle: string) {
        this._subTitle = subtitle;
    }

    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();
    @Output() public componentEvent: EventEmitter<EntityEvent> = new EventEmitter();

    @ViewChild('widgetContent', {read: ViewContainerRef, static: true}) public widgetContentRef;

    @HostBinding('class')
    get columnClasses() {
        const classes = [`entity-widget--col-${this.width}`];

        if (this.type === 'graph') {
            classes.push(`entity-widget__card--xx_bigger`);
        }

        return classes.join(' ');
    }

    @HostBinding('class.entity-widget') public entityWidgetClass = true;
    @HostBinding('class.card-column') public cardColumnClass = true;
    public widgetCardStyles: string[] = [];
    public hasDragOver = false;
    public dragEnterCounter = 0;
    public actionSubscription: Subscription;
    public componentEventSubscription: Subscription;

    public disableTitle: boolean;

    private isViewInitialized = false;
    public componentRef: ComponentRef<EntityWidgetContentComponent>;

    constructor(private injector: Injector, private componentFactoryResolver: ComponentFactoryResolver) {
    }

    get hasFileHandler(): boolean {
        return Boolean(this.componentRef && this.componentRef.instance && this.componentRef.instance.fileHandler);
    }

    get title(): string {
        if (this._title) {
            return this._title;
        } else {
            return get(this.options, 'title') as string;
        }
    }

    get subTitle(): string {
        if (this._subTitle) {
            return this._subTitle;
        } else {
            return get(this.options, 'subTitle') as string;
        }
    }

    public ngOnInit() {
    }

    public ngAfterViewInit() {
        this.isViewInitialized = true;
        this._updateComponent({});
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.type) {
            this._updateComponent(changes, true);
        } else {
            this._updateComponent(changes);
        }
    }

    @HostListener('dragover', ['$event'])
    public onDragOver(e: DragEvent) {
        if (eventContainsFiles(e) && this.hasFileHandler) {
            e.preventDefault();
            e.stopPropagation();
            this.hasDragOver = true;
        }
    }

    @HostListener('dragenter', ['$event'])
    public onDragEnter(e: Event) {
        this.dragEnterCounter++;
        this.hasDragOver = true;
    }

    @HostListener('dragleave', ['$event'])
    public onDragLeave(e: Event) {
        this.dragEnterCounter--;
        if (this.dragEnterCounter <= 0) {
            this._resetDragState();
        }
    }

    @HostListener('dragend', ['$event'])
    public onDragEnd(e: Event) {
        this._resetDragState();
    }

    @HostListener('drop', ['$event'])
    public onDrop(e: DragEvent) {
        if (eventContainsFiles(e) && this.hasFileHandler) {
            e.preventDefault();
            e.stopPropagation();
            this.componentRef.instance.fileHandler.next(e.dataTransfer.files);
        }
        this._resetDragState();
    }

    /**
     * Update the injected component
     */
    private _updateComponent(changes: SimpleChanges, recreate = false) {
        let compChanges: SimpleChanges = {};

        if (!this.componentRef || recreate) {
            if (!this.type) {
                return;
            }
            if (this.componentRef && recreate) {
                this.componentRef.destroy();
            }

            const injectionToken = getInjectionTokenForComponentType<EntityWidgetContentComponent>(
                ComponentRegistries.ENTITY_WIDGET,
                this.type,
            );

            if (injectionToken) {
                const type = this.injector.get(injectionToken);

                // @ts-ignore
                if (type.disableTitle) {
                    this.disableTitle = true;
                }

                const factory = this.componentFactoryResolver.resolveComponentFactory(type);
                this.componentRef = this.widgetContentRef.createComponent(factory);

                compChanges = {
                    productId: new SimpleChange(null, this.entityId, true),
                    width: new SimpleChange(null, this.width, true),
                    refresh: new SimpleChange(null, this.refresh, true),
                    options: new SimpleChange(null, this.options, true),
                    data: new SimpleChange(null, this.data, true),
                    formValues: new SimpleChange(null, this.formValues, true),
                    additionalData: new SimpleChange(null, this.additionalData, true),
                };
            }
        } else {
            if (changes.entityId) {
                compChanges.entityId = changes.entityId;
            }
            if (changes.width) {
                compChanges.width = changes.width;
            }
            if (changes.refresh) {
                compChanges.refresh = changes.refresh;
            }
            if (changes.options) {
                compChanges.options = changes.options;
            }
            if (changes.data) {
                compChanges.data = changes.data;
            }
            if (changes.formValues) {
                compChanges.formValues = changes.formValues;
            }
            if (changes.additionalData) {
                compChanges.additionalData = changes.additionalData;
            }
        }

        if (changes.options) {
            this._calculateWidgetCardStyles();
        }

        if (this.componentRef && this.componentRef.instance) {

            if (this.componentRef.instance.action) {
                if (this.actionSubscription) {
                    this.actionSubscription.unsubscribe();
                }
                this.actionSubscription = this.componentRef.instance.action.subscribe(this.action);
            }

            if (this.componentRef.instance.componentEvent) {
                if (this.componentEventSubscription) {
                    this.componentEventSubscription.unsubscribe();
                }
                this.componentEventSubscription = this.componentRef.instance.componentEvent.subscribe(this.componentEvent);
            }

            this.componentRef.instance.entityId = this.entityId;
            this.componentRef.instance.width = this.width;
            this.componentRef.instance.options = this.options;
            this.componentRef.instance.data = this.data;
            this.componentRef.instance.formValues = this.formValues;
            this.componentRef.instance.additionalData = this.additionalData;
            this.componentRef.instance.ngOnChanges(compChanges);
        }
    }

    private _calculateWidgetCardStyles() {
        this.widgetCardStyles = [
            ...(
                (this.options && this.options.styleHints)
                    ? this.options.styleHints.map((hint) => `entity-widget__card--${hint}`)
                    : []
            ),
            'entity-widget__card--' + this.type,
        ];
    }

    private _resetDragState() {
        this.hasDragOver = false;
        this.dragEnterCounter = 0;
    }

    public forceFormValidation() {
        if (this.componentRef && this.componentRef.instance && isFormValidationWidget(this.componentRef.instance)) {
            this.componentRef.instance.forceFormValidation();
        }
    }

    public ngOnDestroy(): void {
        if (this.componentEventSubscription) {
            this.componentEventSubscription.unsubscribe();
        }

        if (this.actionSubscription) {
            this.actionSubscription.unsubscribe();
        }
    }
}

export interface EntityWidgetContentComponent extends OnChanges {
    entityId: string;
    width: number;
    options: EntityWidgetOptions;
    data: any;
    additionalData?: EntityWidgetAdditionalDataChannel;
    action: EventEmitter<{ type: string, payload: any }>;
    componentEvent?: EventEmitter<EntityEvent>;
    fileHandler?: Subject<FileList>;
    formValues?: FormValues;
}

export interface EntityWidgetWithValidation {
    formValidation(): boolean;

    forceFormValidation?(): void;
}

export function isFormValidationWidget(x: any): x is EntityWidgetContentComponent & EntityWidgetWithValidation {
    return x.forceFormValidation !== undefined;
}
