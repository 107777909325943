import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map, startWith, take } from 'rxjs/operators';
import { TargetMarketCustomCustomer } from '../../../routes/target-market/models/target-market-customer.model';
import { truthy } from '../../../shared/helpers/general.helper';
import { getFirstMenuItemLink } from '../../helpers/menu.helper';
import { AuthService } from '../../services/auth.service';
import { ConfigurationService } from '../../services/configuration.service';
import { MenuService } from '../../services/menu.service';
import { UiService } from '../../services/ui.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/state/index.state';
import { StringsService } from '../../services/strings.service';
import * as CustomerSelectionSelectors from '../../../routes/customer-selection/store/selectors/customer-selection.selectors';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LabelParams } from '../../../routes/customer-selection/store/state/customer-selection.state';
import * as SearchActions from '../../store/actions/search.actions';
import * as UserSelectors from '../../store/selectors/user.selectors';
import { Shortcuts, UserProfile } from '../../api/models/user.model';
import { GroupedResults } from '../../models/search.model';
import * as SearchSelectors from '../../store/selectors/search.selectors';
import { ProductSelectionIndicatorComponent } from '../../../routes/shared/product-shared/components/product-selection-indicator/product-selection-indicator.component';
import { SearchComponent } from '../search/search.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { ButtonComponent } from 'chroma-ui';
import { ConnectionPositionPair, OverlayModule } from '@angular/cdk/overlay';
import { UserActions } from '../../store/actions/user.actions';
import { ImageReferenceResolverService } from '../../services/image-reference-resolver.service';
import { DERICON_ID } from '../../../../config';

@Component({
    selector: 'app-top-bar',
    templateUrl: './top-bar.component.html',
    standalone: true,
    imports: [RouterLink, NgIf, SearchComponent, ProductSelectionIndicatorComponent, AsyncPipe, TranslocoModule, ButtonComponent, OverlayModule]
})
export class TopBarComponent implements OnInit  {
    
    public canSelectCustomer$: Observable<boolean>;
    public activatedCustomer$: Observable<any>;
    public activatedLabel$: Observable<string>;
    public activatedLabelParams$: Observable<LabelParams>;
    public activatedLink$: Observable<any[]>;
    public profileLink$: Observable<any[]>;
    public logoUrl$: Observable<any[]>;
    public logoUrl: string;
    public activatedTargetMarketReferenceCustomer$: Observable<TargetMarketCustomCustomer>;
    public displaySearch$: Observable<boolean>;
    public profileMenuIsOpen = false;
    public logoImgUrl: string;
    public userProfile$: Observable<UserProfile>;

    public products$: Observable<Array<GroupedResults>>
    public shortcuts$: Observable<Shortcuts>;

    public overlayPositions = [
        new ConnectionPositionPair(
            {
                originX: 'end',
                originY: 'bottom'
            },
            {
                overlayX: 'end',
                overlayY: 'top'
            },
            90,
            10
        )
    ];

    public get dericonIdLink(): string {
        return `${DERICON_ID}/?lang=${this.translocoService.getActiveLang()}`;
    }

    constructor(
        private uiService: UiService,
        private authService: AuthService,
        private router: Router,
        private menuService: MenuService,
        private configurationService: ConfigurationService,
        private store: Store<AppState>,
        private readonly stringsService: StringsService,
        private imageResolver: ImageReferenceResolverService,
        private translocoService: TranslocoService
    ) {
        this.displaySearch$ = this.configurationService.displaySearch$;

        this.logoImgUrl = this.configurationService.getLogoImageUrl();

        const getSlideDownOutletRoute = () => {
            const urlTree = this.router.parseUrl(this.router.url);
            const slideDownOutlet = urlTree.root.children.primary.children['slide-down'];

            return get(slideDownOutlet, 'segments[0].path', '');
        };

        this.profileLink$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            startWith(null as NavigationEnd),
            map(getSlideDownOutletRoute),
            map((slideDownOutletRoute) => {
                if (slideDownOutletRoute === 'profile') {
                    return ['/app', {outlets: {'slide-down': null}}];
                } else {
                    return [
                        '/app',
                        {
                            outlets: {
                                'slide-down': ['profile'],
                            },
                        },
                    ];
                }
            }),
        );

        this.activatedLink$ = this.store.select(CustomerSelectionSelectors.selectActivatedLink);

        this.logoUrl$ = this.menuService.getMenuConfig().pipe(
            filter(truthy),
            map((menuConfig) => getFirstMenuItemLink(menuConfig)),
        );

        this.canSelectCustomer$ = this.menuService.isTmcSelectable();

        this.products$ = this.store.select(SearchSelectors.selectProducts);
        this.shortcuts$ = this.store.select(UserSelectors.selectShortcuts);
    }

    ngOnInit(): void {
        this.activatedCustomer$ = this.store.select(CustomerSelectionSelectors.selectActivatedCustomer);
        this.activatedLabelParams$ = this.store.select(CustomerSelectionSelectors.selectActivatedLabelParams);
        this.activatedLabel$ = this.store.select(CustomerSelectionSelectors.selectActivatedLabel);

        this.userProfile$ = this.store.select(UserSelectors.selectUserProfile);

        this.stringsService.loadStringsByPath('targetMarket');
    }

    public onTermSearch(term: string): void {
        this.store.dispatch(SearchActions.loadSolrResults({ term }));
    }

    public onOpenMenu(): void {
        this.uiService.openMenu();
    }

    public onSignOut(): void {
        this.authService.signOut();
    }

    public onOverlayChange(): void {
        this.userProfile$.pipe(
            take(1),
            filter((userProfile) => !userProfile)
        ).subscribe(() =>
            this.store.dispatch(UserActions.loadProfile())
        );
        
        this.profileMenuIsOpen = !this.profileMenuIsOpen;
    }

    public resolveDerifilesImage(ref: string): string {
        return this.imageResolver.resolveRemoteImagePath(ref);
    }
}
