import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { isArray } from 'lodash';
import { EntityWidgetOptions } from '../../../models/widgets.model';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-entity-widget-watchlist-select',
    templateUrl: './entity-widget-watchlist-select.component.html',
    styleUrls: ['./entity-widget-watchlist-select.component.scss'],
    standalone: true,
    imports: [NgFor]
})
export class EntityWidgetWatchlistSelectComponent implements OnInit, OnChanges, EntityWidgetContentComponent {
    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public options: EntityWidgetOptions;
    @Input() public data: { [key: string]: WatchlistItem };
    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();

    constructor() {
    }

    public ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges): void {
    }

    get values() {
        if (!this.data) {
            return [];
        }

        let selectedIds = [...Object.keys(this.data)];

        if (this.options && this.options.watchlistIds && isArray(this.options.watchlistIds)) {
            selectedIds = [...this.options.watchlistIds] as string[];
        }

        const result = [];
        selectedIds.forEach((id) => {
            if (this.data[id]) {
                result.push(this.data[id]);
            }
        });

        return result;
    }

    public onCheckboxChange(e: Event, value: WatchlistItem) {
        e.preventDefault();

        this.data[value.id].enabled = !this.data[value.id].enabled;

        this.action.next({
            type: 'watchlist-select-change',
            payload: {
                entityId: this.entityId,
                watchlistId: value.id,
                enabled: this.data[value.id].enabled,
            },
        });
    }
}

export interface WatchlistItem {
    id: string;
    enabled: boolean;
    label: string;
}
