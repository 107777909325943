/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 4/6/17.
 * Adapted from https://github.com/ng2-ui/sticky/blob/f36d36ce9d2f9d119d6f51bc25217f9faaf91ac4/src/sticky.directive.ts
 */

import { AfterViewInit, Directive, ElementRef, EventEmitter, HostBinding, Input, NgZone, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { throttle } from 'lodash';
import { Subscription } from 'rxjs';
import { ScrollService } from '../../core/services/scroll.service';
import { computedStyle } from '../helpers/dom.helper';

@Directive({
    selector: '[appSticky]',
    standalone: true
})
export class StickyDirective implements AfterViewInit, OnDestroy, OnChanges {

    @Input() public stickyAfter: string;  // css selector to be sticky after

    public el: HTMLElement;
    public parentEl: HTMLElement;
    public fillerEl: HTMLElement;
    @Input() public stickyOffset = 0;
    @Input() public contained = false;
    @Input() public netherOffset = 0;

    @Input() public stickyZ = 500;

    @Output() public sticky: EventEmitter<boolean> = new EventEmitter();
    @Output() public headerHeight: EventEmitter<number> = new EventEmitter();

    @Input() public disableInitialScroll = false;

    public diff: any;
    public original: any;

    public throttledScrollHandler = throttle(() => this.scrollHandler(), 50);

    @HostBinding('class.filter-sticky')
    public isSticky = false;

    @Input()
    @HostBinding('class.no-overlay')
    public noOverlay = false;

    @Input()
    public disabled = false;

    private subscriptions: Subscription[] = [];

    constructor(
        el: ElementRef,
        private zone: NgZone,
        private scrollService: ScrollService
    ) {
        this.el = this.el = el.nativeElement;
        this.parentEl = this.el.parentElement;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled) {
            if (this.disabled) {
                this.reset();
            } else {
                if (!this.disableInitialScroll) {
                    this.scrollHandler();
                }

                this.attach();
            }
        }

    }

    public ngAfterViewInit(): void {
        this.el.style.boxSizing = 'border-box';

        if (this.stickyAfter) {
            const cetStickyAfterEl = document.querySelector(this.stickyAfter);
            if (cetStickyAfterEl) {
                this.stickyOffset = cetStickyAfterEl.getBoundingClientRect().bottom;
            }
        }

        // set the parent relatively positioned
        const allowedPositions = ['absolute', 'fixed', 'relative'];
        const parentElPosition = computedStyle(this.el.parentElement, 'position');
        this.parentEl = this.el.parentElement;
        if (allowedPositions.indexOf(parentElPosition) === -1) { // inherit, initial, unset
            this.parentEl.style.position = 'relative';
        }

        this.diff = {
            top: this.el.offsetTop - this.parentEl.offsetTop,
            left: this.el.offsetLeft - this.parentEl.offsetLeft,
        };

        const elRect = this.el.getBoundingClientRect();

        this.headerHeight.next(elRect.height);

        this.original = {
            boundingClientRect: elRect,
            position: computedStyle(this.el, 'position'),
            float: computedStyle(this.el, 'float'),
            top: computedStyle(this.el, 'top'),
            bottom: computedStyle(this.el, 'bottom'),
            width: computedStyle(this.el, 'width'),
            offsetTop: this.el.offsetTop,
            offsetLeft: this.el.offsetLeft,
            marginTop: parseInt(computedStyle(this.el, 'marginTop'), 10),
            marginBottom: parseInt(computedStyle(this.el, 'marginBottom'), 10),
            marginLeft: parseInt(computedStyle(this.el, 'marginLeft'), 10),
            marginRight: parseInt(computedStyle(this.el, 'marginLeft'), 10),
            zIndex: parseInt(computedStyle(this.el, 'zIndex'), 10),
        };

        if (!this.disabled) {
            if (!this.disableInitialScroll) {
                this.scrollHandler();
            }
            this.attach();
        }

        this.subscriptions.push(
            this.scrollService.overlayScroll$.asObservable().subscribe(() =>  
                this.scrollHandler()
            )
        );
    }

    public ngOnDestroy(): void {
        this.detach();
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }

    public attach(): void {
        this.zone.runOutsideAngular(() => {
            window.addEventListener('scroll', this.throttledScrollHandler);
            window.addEventListener('resize', this.throttledScrollHandler);
        });
    }

    public detach(): void {
        this.zone.runOutsideAngular(() => {
            window.removeEventListener('scroll', this.throttledScrollHandler);
            window.removeEventListener('resize', this.throttledScrollHandler);
        });
    }

    public reset(): void {
        this.detach();

        if (this.isSticky) {
            const dynProps = this.calcDynProps();

            if (this.fillerEl) {
                this.parentEl.removeChild(this.fillerEl); // IE11 does not work with el.remove()
                this.fillerEl = undefined;
            }
            Object.assign(this.el.style, {
                position: this.original.position,
                float: this.original.float,
                top: this.original.top,
                bottom: this.original.bottom,
                width: this.original.width,
                zIndex: this.original.zIndex,
            }, dynProps);

            this.isSticky = false;
            this.sticky.next(false);
        }
    }

    public calcDynProps() {
        const parentRect: ClientRect = this.el.parentElement.getBoundingClientRect();
        const bodyRect: ClientRect = document.body.getBoundingClientRect();
        let dynProps;

        if (this.original.float === 'right') {
            const right = bodyRect.right - parentRect.right + this.original.marginRight;
            dynProps = { right: right + 'px' };
        } else if (this.original.float === 'left') {
            const left = parentRect.left - bodyRect.left + this.original.marginLeft;
            dynProps = { left: left + 'px' };
        } else {
            dynProps = { width: parentRect.width + 'px' };
        }

        return dynProps;
    }

    public scrollHandler(): void {
        const parentRect = this.el.parentElement.getBoundingClientRect();
        const elRect = this.el.getBoundingClientRect();
        const dynProps = this.calcDynProps();

        this.headerHeight.next(elRect.height);

        if (
            this.contained &&
                parentRect.top * -1 + this.stickyOffset > 0 &&
                parentRect.top * -1 + this.stickyOffset + this.netherOffset < parentRect.height - elRect.height ||
            !this.contained &&
                parentRect.top * -1 +
                this.stickyOffset > (this.isSticky ? this.fillerEl.offsetTop : this.el.offsetTop)
        ) {
            /**
             * sticky element is in the middle of container
             */

            // if not floating, add an empty filler element, since the original elements becames 'fixed'
            if (this.original.float !== 'left' && this.original.float !== 'right' && !this.fillerEl) {
                this.fillerEl = document.createElement('div');
                this.fillerEl.style.height = this.el.offsetHeight + 'px';
                this.parentEl.insertBefore(this.fillerEl, this.el);
            }

            Object.assign(this.el.style, {
                position: 'fixed', // fixed is a lot smoother than absolute
                float: 'none',
                top: this.stickyOffset + 'px',
                bottom: 'inherit',
                zIndex: this.stickyZ,
            }, dynProps);

            this.isSticky = true;
            this.sticky.next(true);
        } else {
            /**
             * sticky element is in the original position
             */
            if (this.fillerEl) {
                this.parentEl.removeChild(this.fillerEl); // IE11 does not work with el.remove()
                this.fillerEl = undefined;
            }
            Object.assign(this.el.style, {
                position: this.original.position,
                float: this.original.float,
                top: this.original.top,
                bottom: this.original.bottom,
                width: '100%',
                zIndex: this.original.zIndex,
            });

            this.isSticky = false;
            this.sticky.next(false);
        }
    }
}
