import { Action } from '@ngrx/store';
import {
    LoadVolatilityMatrixTableStructureSucceededAction,
    LoadVolatilityMatrixTableValuesAction,
    LoadVolatilityMatrixTableValuesSucceededAction,
    VolatilityMatrixActionType,
} from '../actions/volatility-matrix.actions';
import { VolatilityMatrixState } from '../state/volatility-matrix.state';

export const initialState: VolatilityMatrixState = {
    volatilityTableStructure: null,
    volatilityTableValues: null,
    volatilitySelectedEntity: null,
    loadingVolatilityTableValues: false,
};

export function reducer(state: VolatilityMatrixState = initialState,
                        action: Action): VolatilityMatrixState {

    switch (action.type) {
        case VolatilityMatrixActionType.LOAD_VOLATILITY_MATRIX_TABLE_VALUES:
            const loadValuesAction = action as LoadVolatilityMatrixTableValuesAction;

            return {
                ...state,
                loadingVolatilityTableValues: true,
            };

        case VolatilityMatrixActionType.LOAD_VOLATILITY_MATRIX_TABLE_VALUES_SUCCEEDED:
            const loadValuesSucceededAction = action as LoadVolatilityMatrixTableValuesSucceededAction;

            return {
                ...state,
                loadingVolatilityTableValues: false,
                volatilityTableValues: loadValuesSucceededAction.payload.data,
                volatilitySelectedEntity: loadValuesSucceededAction.payload.entity,
            };

        case VolatilityMatrixActionType.LOAD_VOLATILITY_MATRIX_TABLE_VALUES_FAILED:
            return {
                ...state,
                loadingVolatilityTableValues: false,
            };

        case VolatilityMatrixActionType.LOAD_VOLATILITY_MATRIX_TABLE_STRUCTURE_SUCCEEDED:
            const loadStructureSucceededAction = action as LoadVolatilityMatrixTableStructureSucceededAction;

            return {
                ...state,
                volatilityTableStructure: loadStructureSucceededAction.payload.structure,
            };
    }

    return state;
}
