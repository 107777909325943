import { Observable } from 'rxjs';

export const HSL_RANGE = 240.0 / 360.0;
export const HSL_LIGHTNESS = 0.7;
export const HSL_SATURATION = 0.95;

export function getHue(val, mean = 0.25, c = 20.0) {
    const y = 1 / (1.0 + Math.exp(c * (val - mean)));

    return HSL_RANGE * y;
}

export function hueToRGB(p, q, t) {
    if (t < 0) {
        t += 1;
    }
    if (t > 1) {
        t -= 1;
    }
    if (t < 1 / 6) {
        return p + (q - p) * 6 * t;
    }
    if (t < 1 / 2) {
        return q;
    }
    if (t < 2 / 3) {
        return p + (q - p) * (2 / 3 - t) * 6;
    }
    return p;
}

export function HSLtoRGB(h, s, l) {
    let r;
    let g;
    let b;

    if (s === 0) {
        r = g = b = l;
    } else {
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;

        r = hueToRGB(p, q, h + 1 / 3);
        g = hueToRGB(p, q, h);
        b = hueToRGB(p, q, h - 1 / 3);
    }

    return [r * 255, g * 255, b * 255];
}

export function RGBtoHEX(r, g, b) {
    /*tslint:disable-next-line */
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export function HEXtoRGB(hex) {
    if(hex === undefined) return undefined;

    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export function rgbToString(rgb: { r: number, g: number, b: number}, alpha?: number) {
    if(rgb === undefined) return undefined;

    const { r, g, b } = rgb;

    if(alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return `rgb(${r}, ${g}, ${b})`;
}

export function calculateColorBrightness(r, g, b) {
    return (r * 299 + g * 587 + b * 114) / 1000;
}

export function grayscaleIE10(src): Observable<string> {
    return new Observable((observable) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const imgObj = new Image();

        imgObj.onload = () => {
            canvas.width = imgObj.width;
            canvas.height = imgObj.height;
            ctx.drawImage(imgObj, 0, 0);

            const imgPixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
            for (let y = 0; y < imgPixels.height; y++) {
                for (let x = 0; x < imgPixels.width; x++) {
                    const i = (y * 4) * imgPixels.width + x * 4;
                    const avg = (imgPixels.data[i] + imgPixels.data[i + 1] + imgPixels.data[i + 2]) / 3;
                    imgPixels.data[i] = avg;
                    imgPixels.data[i + 1] = avg;
                    imgPixels.data[i + 2] = avg;
                }
            }
            ctx.putImageData(imgPixels, 0, 0, 0, 0, imgPixels.width, imgPixels.height);

            const dataUrl = canvas.toDataURL();
            observable.next(dataUrl);
            observable.complete();
        };

        imgObj.src = src;
        imgObj.crossOrigin = 'anonymous';
    });
}
