import { Action } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { FormStructureConfig, FormValues } from '../../../../core/models/form.model';
import { type } from '../../../../core/store/util';
import { ExtendedDynamicChartDataResponse } from '../../../../shared/models/dynamic-chart.model';
import { Entity, EntityTableConfig } from '../../../../shared/models/entities.model';
import { MultiRequestStructure, UnderlyingFilterValue } from '../../../shared/product-designer-shared/models/product-designer-components.model';
import {
    InitialValuesResponse, MatrixPricingAvailabilities,
    OfferResponse, OfferResponseAcceptData, OfferResponseCreate, OfferResponseDeclineData, OfferResponseNoteUpdateData,
    ProductComponent,
    ProductComponentStructure, ProductDecomposeDictValue, ProductDecomposeResult, ProductDesignerHistoryStructures, ProductGroupStructure,
    ProductIssuerOrganization,
    ProductRequestParameters,
    ProductResponse,
    ProductStructure,
    ProductType, ProductValidation,
    SurveyData,
} from '../../../shared/product-designer-shared/models/product-designer.model';

export const ProductDesignerActions = {
    LOAD_PRODUCT_DESIGNER_STRUCTURE: type('DPD:LOAD_PRODUCT_DESIGNER_STRUCTURE'),
    LOAD_PRODUCT_DESIGNER_STRUCTURE_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_STRUCTURE_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_STRUCTURE_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_STRUCTURE_FAILURE'),

    LOAD_PRODUCT_DESIGNER_VALUES: type('DPD:LOAD_PRODUCT_DESIGNER_VALUES'),
    LOAD_PRODUCT_DESIGNER_VALUES_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_VALUES_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_VALUES_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_VALUES_FAILURE'),

    LOAD_PRODUCT_DESIGNER_INITIAL_VALUES: type('DPD:LOAD_PRODUCT_DESIGNER_INITIAL_VALUES'),
    LOAD_PRODUCT_DESIGNER_INITIAL_VALUES_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_INITIAL_VALUES_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_INITIAL_VALUES_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_INITIAL_VALUES_FAILURE'),

    ADD_PRODUCT_DESIGNER_PRODUCT_COMPONENT: type('DPD:ADD_PRODUCT_DESIGNER_PRODUCT_COMPONENT'),
    ADD_PRODUCT_DESIGNER_GROUP_PRODUCT_COMPONENTS: type('DPD:ADD_PRODUCT_DESIGNER_GROUP_PRODUCT_COMPONENTS'),
    RECOMPOSE_PRODUCT_COMPONENTS: type('DPD:RECOMPOSE_PRODUCT_COMPONENTS'),

    UPDATE_PRODUCT_REQUEST: type('DPD:UPDATE_PRODUCT_REQUEST'),
    UPDATE_PRODUCT_OFFERS: type('DPD:UPDATE_PRODUCT_OFFERS'),

    COPY_PRODUCT_REQUEST: type('DPD:COPY_PRODUCT_REQUEST'),

    CREATE_PRODUCT_REQUEST: type('DPD:CREATE_PRODUCT_REQUEST'),
    CREATE_PRODUCT_REQUEST_SUCCESS: type('DPD:CREATE_PRODUCT_REQUEST_SUCCESS'),
    CREATE_PRODUCT_REQUEST_FAILURE: type('DPD:CREATE_PRODUCT_REQUEST_FAILURE'),
    RESET_PRODUCT_REQUEST_FAILURE: type('DPD:RESET_PRODUCT_REQUEST_FAILURE'),

    ADD_OFFER_REQUEST: type('DPD:ADD_OFFER_REQUEST'),
    ADD_OFFER_REQUEST_SUCCESS: type('DPD:ADD_OFFER_REQUEST_SUCCESS'),
    ADD_OFFER_REQUEST_FAILURE: type('DPD:ADD_OFFER_REQUEST_FAILURE'),

    ACCEPT_OFFER_RESPONSE_REQUEST: type('DPD:ACCEPT_OFFER_RESPONSE_REQUEST'),
    ACCEPT_OFFER_RESPONSE_SUCCESS: type('DPD:ACCEPT_OFFER_RESPONSE_SUCCESS'),
    ACCEPT_OFFER_RESPONSE_FAILURE: type('DPD:ACCEPT_OFFER_RESPONSE_FAILURE'),

    DECLINE_OFFER_RESPONSE_REQUEST: type('DPD:DECLINE_OFFER_RESPONSE_REQUEST'),
    DECLINE_OFFER_RESPONSE_SUCCESS: type('DPD:DECLINE_OFFER_RESPONSE_SUCCESS'),
    DECLINE_OFFER_RESPONSE_FAILURE: type('DPD:DECLINE_OFFER_RESPONSE_FAILURE'),

    UPDATE_OFFER_RESPONSE_NOTE_REQUEST: type('DPD:UPDATE_OFFER_RESPONSE_NOTE_REQUEST'),
    UPDATE_OFFER_RESPONSE_NOTE_SUCCESS: type('DPD:UPDATE_OFFER_RESPONSE_NOTE_SUCCESS'),
    UPDATE_OFFER_RESPONSE_NOTE_FAILURE: type('DPD:UPDATE_OFFER_RESPONSE_NOTE_FAILURE'),

    PUBLISH_PRODUCT_REQUEST: type('DPD:PUBLISH_PRODUCT_REQUEST'),
    PUBLISH_PRODUCT_REQUEST_SUCCESS: type('DPD:PUBLISH_PRODUCT_REQUEST_SUCCESS'),
    PUBLISH_PRODUCT_REQUEST_FAILURE: type('DPD:PUBLISH_PRODUCT_REQUEST_FAILURE'),

    LOAD_PRODUCT_DESIGNER_UNDERLYINGS_GRAPH: type('DPD:LOAD_PRODUCT_DESIGNER_UNDERLYINGS_GRAPH'),
    LOAD_PRODUCT_DESIGNER_UNDERLYINGS_GRAPH_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_UNDERLYINGS_GRAPH_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_UNDERLYINGS_GRAPH_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_UNDERLYINGS_GRAPH_FAILURE'),

    LOAD_PRODUCT_DESIGNER_DATA_FOR_PRODUCT_TYPE: type('DPD:LOAD_PRODUCT_DESIGNER_DATA_FOR_PRODUCT_TYPE'),
    LOAD_PRODUCT_DESIGNER_DATA_FOR_PRODUCT_TYPE_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_DATA_FOR_PRODUCT_TYPE_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_DATA_FOR_PRODUCT_TYPE_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_DATA_FOR_PRODUCT_TYPE_FAILURE'),

    LOAD_PRODUCT_DESIGNER_DATA_BY_PRODUCT_ID: type('DPD:LOAD_PRODUCT_DESIGNER_DATA_BY_PRODUCT_ID'),
    LOAD_PRODUCT_DESIGNER_DATA_BY_PRODUCT_ID_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_DATA_BY_PRODUCT_ID_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_DATA_BY_PRODUCT_ID_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_DATA_BY_PRODUCT_ID_FAILURE'),

    RESET_PRODUCT_DESIGNER_PRODUCT_RESPONSE: type('DPD:RESET_PRODUCT_DESIGNER_PRODUCT_RESPONSE'),

    LOAD_PRODUCT_DESIGNER_PRODUCT_TYPES: type('DPD:LOAD_PRODUCT_DESIGNER_PRODUCT_TYPES'),
    LOAD_PRODUCT_DESIGNER_PRODUCT_TYPES_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_PRODUCT_TYPES_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_PRODUCT_TYPES_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_PRODUCT_TYPES_FAILURE'),

    LOAD_PRODUCT_DESIGNER_HISTORY_STRUCTURES: type('DPD:LOAD_PRODUCT_DESIGNER_HISTORY_STRUCTURES'),
    LOAD_PRODUCT_DESIGNER_HISTORY_STRUCTURES_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_HISTORY_STRUCTURES_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_HISTORY_STRUCTURES_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_HISTORY_STRUCTURES_FAILURE'),

    LOAD_PRODUCT_DESIGNER_HISTORY_INITIAL_VALUES_REQUEST: type('DPD:LOAD_PRODUCT_DESIGNER_HISTORY_INITIAL_VALUES_REQUEST'),
    LOAD_PRODUCT_DESIGNER_HISTORY_INITIAL_VALUES_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_HISTORY_INITIAL_VALUES_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_HISTORY_INITIAL_VALUES_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_HISTORY_INITIAL_VALUES_FAILURE'),

    LOAD_PRODUCT_DESIGNER_REQUEST_PARAMETERS_VALUES: type('DPD:LOAD_PRODUCT_DESIGNER_REQUEST_PARAMETERS_VALUES'),
    LOAD_PRODUCT_DESIGNER_REQUEST_PARAMETERS_VALUES_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_REQUEST_PARAMETERS_VALUES_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_REQUEST_PARAMETERS_VALUES_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_REQUEST_PARAMETERS_VALUES_FAILURE'),

    LOAD_PRODUCT_DESIGNER_HISTORY_TABLE_VALUES: type('DPD:LOAD_PRODUCT_DESIGNER_HISTORY_TABLE_VALUES'),
    LOAD_PRODUCT_DESIGNER_HISTORY_TABLE_VALUES_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_HISTORY_TABLE_VALUES_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_HISTORY_TABLE_VALUES_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_HISTORY_TABLE_VALUES_FAILURE'),

    LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_STRUCTURE: type('DPD:LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_STRUCTURE'),
    LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_STRUCTURE_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_STRUCTURE_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_STRUCTURE_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_STRUCTURE_FAILURE'),

    LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_VALUES: type('DPD:LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_VALUES'),
    LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_VALUES_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_VALUES_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_VALUES_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_VALUES_FAILURE'),

    LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_STRUCTURE: type('DPD:LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_STRUCTURE'),
    LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_STRUCTURE_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_STRUCTURE_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_STRUCTURE_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_STRUCTURE_FAILURE'),

    LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_VALUES: type('DPD:LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_VALUES'),
    LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_VALUES_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_VALUES_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_VALUES_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_VALUES_FAILURE'),

    LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_STRUCTURE: type('DPD:LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_STRUCTURE'),
    LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_STRUCTURE_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_STRUCTURE_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_STRUCTURE_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_STRUCTURE_FAILURE'),

    LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_VALUES: type('DPD:LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_VALUES'),
    LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_VALUES_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_VALUES_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_VALUES_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_VALUES_FAILURE'),

    VALIDATE_PRODUCT_DESIGNER_PRODUCT: type('DPD:VALIDATE_PRODUCT_DESIGNER_PRODUCT'),
    VALIDATE_PRODUCT_DESIGNER_PRODUCT_SUCCESS: type('DPD:VALIDATE_PRODUCT_DESIGNER_PRODUCT_SUCCESS'),
    VALIDATE_PRODUCT_DESIGNER_PRODUCT_FAILURE: type('DPD:VALIDATE_PRODUCT_DESIGNER_PRODUCT_FAILURE'),

    LOAD_PRODUCT_DESIGNER_ALL_ISSUERS: type('DPD:LOAD_PRODUCT_DESIGNER_ALL_ISSUERS'),
    LOAD_PRODUCT_DESIGNER_ALL_ISSUERS_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_ALL_ISSUERS_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_ALL_ISSUERS_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_ALL_ISSUERS_FAILURE'),

    TRANSFORM_PRODUCT_DESIGNER_COMPONENT: type('DPD:TRANSFORM_PRODUCT_DESIGNER_COMPONENT'),
    TRANSFORM_PRODUCT_DESIGNER_COMPONENT_SUCCESS: type('DPD:TRANSFORM_PRODUCT_DESIGNER_COMPONENT_SUCCESS'),
    TRANSFORM_PRODUCT_DESIGNER_COMPONENT_FAILURE: type('DPD:TRANSFORM_PRODUCT_DESIGNER_COMPONENT_FAILURE'),

    LOAD_PRODUCT_DESIGNER_AVAILABLE_USERS: type('DPD:LOAD_PRODUCT_DESIGNER_AVAILABLE_USERS'),
    LOAD_PRODUCT_DESIGNER_AVAILABLE_USERS_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_AVAILABLE_USERS_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_AVAILABLE_USERS_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_AVAILABLE_USERS_FAILURE'),

    LOAD_PRODUCT_DESIGNER_SURVEY_DATA: type('DPD:LOAD_PRODUCT_DESIGNER_SURVEY_DATA'),
    LOAD_PRODUCT_DESIGNER_SURVEY_DATA_SUCCESS: type('DPD:LOAD_PRODUCT_DESIGNER_SURVEY_DATA_SUCCESS'),
    LOAD_PRODUCT_DESIGNER_SURVEY_DATA_FAILURE: type('DPD:LOAD_PRODUCT_DESIGNER_SURVEY_DATA_FAILURE'),

    LOAD_MATRIX_PRICING_AVAILABILITIES_REQUEST: type('DPD:LOAD_MATRIX_PRICING_AVAILABILITIES_REQUEST'),
    LOAD_MATRIX_PRICING_AVAILABILITIES_SUCCESS: type('DPD:LOAD_MATRIX_PRICING_AVAILABILITIES_SUCCESS'),
    LOAD_MATRIX_PRICING_AVAILABILITIES_FAILURE: type('DPD:LOAD_MATRIX_PRICING_AVAILABILITIES_FAILURE'),

    LOAD_REQUEST_FORM_MULTI_REQUEST_STRUCTURE_REQUEST: type('DPD:LOAD_REQUEST_FORM_MULTI_REQUEST_STRUCTURE_REQUEST'),
    LOAD_REQUEST_FORM_MULTI_REQUEST_STRUCTURE_SUCCESS: type('DPD:LOAD_REQUEST_FORM_MULTI_REQUEST_STRUCTURE_SUCCESS'),
    LOAD_REQUEST_FORM_MULTI_REQUEST_STRUCTURE_FAILURE: type('DPD:LOAD_REQUEST_FORM_MULTI_REQUEST_STRUCTURE_FAILURE'),

    RESET_PRODUCT_DESIGNER_STATE: type('DPD:RESET_PRODUCT_DESIGNER_STATE'),
};

export interface CopyProductRequestAction extends Action {
    payload: {
        response: ProductResponse
    }
}

export function copyProductRequest(response: ProductResponse): CopyProductRequestAction {
    return {
        type: ProductDesignerActions.COPY_PRODUCT_REQUEST,
        payload: {
            response
        }
    };
}

export interface LoadMatrixPricingAvailabilitiesRequestAction extends Action {
    payload: {
        moduleId?: string;
        productType: string;
        solveTarget: string;
    }
}

export function loadMatrixPricingAvailabilitiesRequest(productType: string, solveTarget: string, moduleId?: string): LoadMatrixPricingAvailabilitiesRequestAction {
    return {
        type: ProductDesignerActions.LOAD_MATRIX_PRICING_AVAILABILITIES_REQUEST,
        payload: {
            productType,
            moduleId,
            solveTarget,
        }
    };
}

export interface LoadMatrixPricingAvailabilitiesSuccessAction extends Action {
    payload: {
        data: MatrixPricingAvailabilities
    }
}

export function loadMatrixPricingAvailabilitiesSuccess(data: MatrixPricingAvailabilities): LoadMatrixPricingAvailabilitiesSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_MATRIX_PRICING_AVAILABILITIES_SUCCESS,
        payload: {
            data,
        }
    };
}

export interface LoadMatrixPricingAvailabilitiesFailureAction extends Action {
    payload: {
        error: AppError;
    }
}

export function loadMatrixPricingAvailabilitiesFailure(error: AppError): LoadMatrixPricingAvailabilitiesFailureAction {
    return {
        type: ProductDesignerActions.LOAD_MATRIX_PRICING_AVAILABILITIES_FAILURE,
        payload: {
            error,
        }
    };
}

export interface AddProductDesignerGroupProductComponents extends Action {
    payload: {
        group: ProductGroupStructure;
        decomposeResult: ProductDecomposeResult;
    };
}

export function addProductDesignerGroupProductComponents(group: ProductGroupStructure, decomposeResult: ProductDecomposeResult): AddProductDesignerGroupProductComponents {
    return {
        type: ProductDesignerActions.ADD_PRODUCT_DESIGNER_GROUP_PRODUCT_COMPONENTS,
        payload: {
            group,
            decomposeResult,
        },
    };
}

export interface RecomposeProductComponentsAction extends Action {
    payload: {
        group: ProductGroupStructure;
        decomposedEvent: ProductDecomposeDictValue;
    };
}

export function recomposeProductComponents(group: ProductGroupStructure, decomposedEvent: ProductDecomposeDictValue): RecomposeProductComponentsAction {
    return {
        type: ProductDesignerActions.RECOMPOSE_PRODUCT_COMPONENTS,
        payload: {
            group,
            decomposedEvent,
        },
    };
}

export interface LoadProductDesignerAvailableUsersAction extends Action {
    payload: {
        filter: any,
        reload: boolean,
    };
}

export function loadProductDesignerAvailableUsers(filter: any, reload: boolean = false): LoadProductDesignerAvailableUsersAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_AVAILABLE_USERS,
        payload: {
            filter,
            reload,
        },
    };
}

export interface LoadProductDesignerAvailableUsersSuccessAction extends Action {
    payload: {
        users: Entity[],
    };
}

export function loadProductDesignerAvailableUsersSuccess(users: Entity[]): LoadProductDesignerAvailableUsersSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_AVAILABLE_USERS_SUCCESS,
        payload: {
            users,
        },
    };
}

export interface LoadProductDesignerAvailableUsersFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadProductDesignerAvailableUsersFailure(error: AppError): LoadProductDesignerAvailableUsersFailureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_AVAILABLE_USERS_FAILURE,
        payload: {
            error,
        },
    };
}

//

export interface UpdateProductRequestAction extends Action {
    payload: {
        request: ProductRequestParameters;
    };
}

export function updateProductRequest(request: ProductRequestParameters): UpdateProductRequestAction {
    return {
        type: ProductDesignerActions.UPDATE_PRODUCT_REQUEST,
        payload: {
            request,
        },
    };
}

//

export interface CreateProductRequestAction extends Action {
    payload: {
        request: ProductRequestParameters;
        productId?: string;
        shouldPublish: boolean;
    };
}

export function createProductRequest(request: ProductRequestParameters, productId?: string, shouldPublish = false): CreateProductRequestAction {
    return {
        type: ProductDesignerActions.CREATE_PRODUCT_REQUEST,
        payload: {
            request,
            productId,
            shouldPublish,
        },
    };
}

export interface CreateProductRequestSuccessAction extends Action {
    payload: {
        response: ProductResponse;
        shouldPublish: boolean;
    };
}

export function createProductRequestSuccess(response: ProductResponse, shouldPublish = false): CreateProductRequestSuccessAction {
    return {
        type: ProductDesignerActions.CREATE_PRODUCT_REQUEST_SUCCESS,
        payload: {
            response,
            shouldPublish,
        },
    };
}

export interface CreateProductRequestFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function createProductRequestFailure(error: AppError): CreateProductRequestFailureAction {
    return {
        type: ProductDesignerActions.CREATE_PRODUCT_REQUEST_FAILURE,
        payload: {
            error,
        },
    };
}

export interface ResetCreateProductRequestError extends Action {
    payload?: any;
}

export function resetCreateProductRequestError(): ResetCreateProductRequestError {
    return {
        type: ProductDesignerActions.RESET_PRODUCT_REQUEST_FAILURE,
    };
}

//

export interface AddOfferRequestAction extends Action {
    payload: {
        data: OfferResponseCreate[];
    };
}

export function addOfferRequest(data: OfferResponseCreate[]): AddOfferRequestAction {
    return {
        type: ProductDesignerActions.ADD_OFFER_REQUEST,
        payload: {
            data,
        },
    };
}

export interface AddOfferRequestSuccessAction extends Action {
    payload?: void;
}

export function addOfferRequestSuccess(): AddOfferRequestSuccessAction {
    return {
        type: ProductDesignerActions.ADD_OFFER_REQUEST_SUCCESS,
    };
}

export interface AddOfferRequesFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function addOfferRequestFailure(error: AppError): AddOfferRequesFailureAction {
    return {
        type: ProductDesignerActions.ADD_OFFER_REQUEST_FAILURE,
        payload: {
            error,
        },
    };
}

//


export interface AcceptOfferResponseRequestAction extends Action {
    payload: {
        data: OfferResponseAcceptData;
        moduleId?: string;
    };
}

export function acceptOfferResponseRequest(data: OfferResponseAcceptData, moduleId?: string): AcceptOfferResponseRequestAction {
    return {
        type: ProductDesignerActions.ACCEPT_OFFER_RESPONSE_REQUEST,
        payload: {
            data,
            moduleId
        },
    };
}

export interface AcceptOfferResponseSuccessAction extends Action {
    payload?: {
        data: ProductResponse;
    };
}

export function acceptOfferResponseSuccess(data: ProductResponse): AcceptOfferResponseSuccessAction {
    return {
        type: ProductDesignerActions.ACCEPT_OFFER_RESPONSE_SUCCESS,
        payload: {
            data
        }
    };
}

export interface AcceptOfferResponseFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function acceptOfferResponseFailure(error: AppError): AcceptOfferResponseFailureAction {
    return {
        type: ProductDesignerActions.ACCEPT_OFFER_RESPONSE_FAILURE,
        payload: {
            error,
        },
    };
}

//

export interface DeclineOfferResponseRequestAction extends Action {
    payload: {
        data: OfferResponseDeclineData;
        moduleId?: string;
    };
}

export function declineOfferResponseRequest(data: OfferResponseDeclineData, moduleId?: string): DeclineOfferResponseRequestAction {
    return {
        type: ProductDesignerActions.DECLINE_OFFER_RESPONSE_REQUEST,
        payload: {
            data,
            moduleId,
        },
    };
}

export interface DeclineOfferResponseSuccessAction extends Action {
    payload?: {
        data: ProductResponse;
    };
}

export function declineOfferResponseSuccess(data: ProductResponse): DeclineOfferResponseSuccessAction {
    return {
        type: ProductDesignerActions.DECLINE_OFFER_RESPONSE_SUCCESS,
        payload: {
            data
        }
    };
}

export interface DeclineOfferResponseFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function declineOfferResponseFailure(error: AppError): DeclineOfferResponseFailureAction {
    return {
        type: ProductDesignerActions.DECLINE_OFFER_RESPONSE_FAILURE,
        payload: {
            error,
        },
    };
}


//



export interface UpdateOfferResponseNoteRequestAction extends Action {
    payload: {
        data: OfferResponseNoteUpdateData;
        moduleId?: string;
    };
}

export function updateOfferResponseNoteRequest(data: OfferResponseNoteUpdateData, moduleId?: string): UpdateOfferResponseNoteRequestAction {
    return {
        type: ProductDesignerActions.UPDATE_OFFER_RESPONSE_NOTE_REQUEST,
        payload: {
            data,
            moduleId
        },
    };
}

export interface UpdateOfferResponseNoteSuccessAction extends Action {
    payload?: {
        data: ProductResponse;
    };
}

export function updateOfferResponseNoteSuccess(data: ProductResponse): UpdateOfferResponseNoteSuccessAction {
    return {
        type: ProductDesignerActions.UPDATE_OFFER_RESPONSE_NOTE_SUCCESS,
        payload: {
            data
        }
    };
}

export interface UpdateOfferResponseNoteFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function updateOfferResponseNoteFailure(error: AppError): UpdateOfferResponseNoteFailureAction {
    return {
        type: ProductDesignerActions.UPDATE_OFFER_RESPONSE_NOTE_FAILURE,
        payload: {
            error,
        },
    };
}



//

export interface PublishProductRequestAction extends Action {
    payload: {
        productId: string;
    };
}

export function publishProductRequest(productId: string): PublishProductRequestAction {
    return {
        type: ProductDesignerActions.PUBLISH_PRODUCT_REQUEST,
        payload: {
            productId,
        },
    };
}

export interface PublishProductRequestSuccessAction extends Action {
    payload: {
        response: ProductResponse,
    };
}

export function publishProductRequestSuccess(response: ProductResponse): PublishProductRequestSuccessAction {
    return {
        type: ProductDesignerActions.PUBLISH_PRODUCT_REQUEST_SUCCESS,
        payload: {
            response,
        },
    };
}

export interface PublishProductRequestFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function publishProductRequestFailure(error: AppError): PublishProductRequestFailureAction {
    return {
        type: ProductDesignerActions.PUBLISH_PRODUCT_REQUEST_FAILURE,
        payload: {
            error,
        },
    };
}

//

export interface UpdateProductOffersAction extends Action {
    payload: {
        offers: OfferResponse[],
    };
}

export function updateProductOffers(offers: OfferResponse[]): UpdateProductOffersAction {
    return {
        type: ProductDesignerActions.UPDATE_PRODUCT_OFFERS,
        payload: {
            offers,
        },
    };
}

//

export interface LoadProductDesignerStructureAction extends Action {
    payload: {
        type?: string,
        moduleId?: string,
        loadInitialValues?: boolean,
    };
}

export function loadProductDesignerStructure(productType?: string, loadInitialValues?: boolean, moduleId?: string): LoadProductDesignerStructureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_STRUCTURE,
        payload: {
            type: productType,
            moduleId,
            loadInitialValues
        },
    };
}

export interface LoadProductDesignerStructureSuccessAction extends Action {
    payload: {
        structure: ProductStructure,
        moduleId?: string,
        loadInitialValues?: boolean
    };
}

export function loadProductDesignerStructureSuccess(structure: ProductStructure, loadInitialValues?: boolean, moduleId?: string): LoadProductDesignerStructureSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_STRUCTURE_SUCCESS,
        payload: {
            structure,
            moduleId,
            loadInitialValues
        },
    };
}

export interface LoadProductDesignerStructureFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadProductDesignerStructureFailure(error: AppError): LoadProductDesignerStructureFailureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_STRUCTURE_FAILURE,
        payload: {
            error,
        },
    };
}

//

export interface LoadProductDesignerUnderlyingsGraphAction extends Action {
    payload: {
        underlyings: UnderlyingFilterValue[],
    };
}

export function loadProductDesignerUnderlyingsGraph(underlyings: UnderlyingFilterValue[]): LoadProductDesignerUnderlyingsGraphAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_UNDERLYINGS_GRAPH,
        payload: {
            underlyings,
        },
    };
}

export interface LoadProductDesignerUnderlyingsGraphSuccessAction extends Action {
    payload: {
        data: ExtendedDynamicChartDataResponse,
    };
}

export function loadProductDesignerUnderlyingsGraphSuccess(data: ExtendedDynamicChartDataResponse): LoadProductDesignerUnderlyingsGraphSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_UNDERLYINGS_GRAPH_SUCCESS,
        payload: {
            data,
        },
    };
}

export interface LoadProductDesignerUnderlyingsGraphFailureActions extends Action {
    payload: {
        error: AppError,
    };
}

export function loadProductDesignerUnderlyingsGraphFailure(error: AppError): LoadProductDesignerUnderlyingsGraphFailureActions {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_UNDERLYINGS_GRAPH_FAILURE,
        payload: {
            error,
        },
    };
}

//

export interface LoadProductDesignerValuesAction extends Action {
    payload: {
        type?: string,
        moduleId?: string
    };
}

export function loadProductDesignerValues(productType?: string, moduleId?: string): LoadProductDesignerValuesAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_VALUES,
        payload: {
            type: productType,
            moduleId
        },
    };
}

export interface LoadProductDesignerValuesSuccessAction extends Action {
    payload: {
        values: FormValues,
    };
}

export function loadProductsDesignerValuesSuccess(values: FormValues): LoadProductDesignerValuesSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_VALUES_SUCCESS,
        payload: {
            values,
        },
    };
}

export interface LoadProductDesignerValuesFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadProductDesignerValuesFailure(error: AppError): LoadProductDesignerValuesFailureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_VALUES_FAILURE,
        payload: {
            error,
        },
    };
}

//

export interface LoadProductDesignerInitialValuesAction extends Action {
    payload: {
        productType: string;
        structure: ProductStructure;
        moduleId: string;
        copyRequestId?: string;
        copyRequestModule?: string;
    };
}

export function loadProductDesignerInitialValues(productType: string, structure: ProductStructure, moduleId: string, copyRequestId?: string, copyRequestModule?: string): LoadProductDesignerInitialValuesAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_INITIAL_VALUES,
        payload: {
            productType,
            structure,
            moduleId,
            copyRequestId,
            copyRequestModule
        },
    };
}

export interface LoadProductDesignerInitialValuesSuccessAction extends Action {
    payload: {
        data: InitialValuesResponse;
        structure: ProductStructure;
    };
}

export function loadProductDesignerInitialValuesSuccess(data: InitialValuesResponse, structure: ProductStructure): LoadProductDesignerInitialValuesSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_INITIAL_VALUES_SUCCESS,
        payload: {
            data,
            structure,
        },
    };
}

export interface LoadProductDesignerInitialValuesFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadProductDesignerInitialValuesFailure(error: AppError): LoadProductDesignerInitialValuesFailureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_INITIAL_VALUES_FAILURE,
        payload: {
            error,
        },
    };
}

//
export interface AddProductDesignerProductComponentAction extends Action {
    payload: {
        component: string;
        group: string;
        initial: FormValues;
    };
}

export function addProductDesignerProductComponent(component: string, group: string, initial: FormValues): AddProductDesignerProductComponentAction {
    return {
        type: ProductDesignerActions.ADD_PRODUCT_DESIGNER_PRODUCT_COMPONENT,
        payload: {
            component,
            group,
            initial,
        },
    };
}

export interface LoadProductDesignerDataByProductIdAction extends Action {
    payload: {
        productId: string;
        loadStructure: boolean;
        moduleId: string;
    };
}

export function loadProductDesignerDataByProductId(productId: string, loadStructure = true, moduleId: string): LoadProductDesignerDataByProductIdAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_DATA_BY_PRODUCT_ID,
        payload: {
            productId,
            loadStructure,
            moduleId,
        },
    };
}

export interface LoadProductDesignerDataByProductIdSuccessAction extends Action {
    payload: {
        data: ProductResponse,
        structure: ProductStructure,
        loadStructure: boolean;
    };
}

export function loadProductDesignerDataByProductIdSuccess(data: ProductResponse, structure: ProductStructure, loadStructure = true): LoadProductDesignerDataByProductIdSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_DATA_BY_PRODUCT_ID_SUCCESS,
        payload: {
            data,
            structure,
            loadStructure,
        },
    };
}

export interface LoadProductDesignerDataByProductIdFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadProductDesignerDataByProductIdFailure(error: AppError): LoadProductDesignerDataByProductIdFailureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_DATA_BY_PRODUCT_ID_FAILURE,
        payload: {
            error,
        },
    };
}

//

export interface LoadProductDesignerSurveyDataAction extends Action {
    payload: {
        surveyId: string;
        moduleId: string;
    };
}

export function loadProductDesignerSurveyData(surveyId: string, moduleId: string): LoadProductDesignerSurveyDataAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_DATA,
        payload: {
            surveyId,
            moduleId,
        },
    };
}

export interface LoadProductDesignerSurveyDataSuccessAction extends Action {
    payload: {
        data: SurveyData,
    };
}

export function loadProductDesignerSurveyDataSuccess(data: SurveyData): LoadProductDesignerSurveyDataSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_DATA_SUCCESS,
        payload: {
            data,
        },
    };
}

export interface LoadProductDesignerSurveyDataFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadProductDesignerSurveyDataFailure(error: AppError): LoadProductDesignerSurveyDataFailureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_DATA_FAILURE,
        payload: {
            error,
        },
    };
}

//
export interface LoadProductDesignerProductTypesAction extends Action {
    payload: {
        moduleId: string
    };
}

export function loadProductDesignerProductTypes(moduleId: string): LoadProductDesignerProductTypesAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_PRODUCT_TYPES,
        payload: {
            moduleId
        }
    };
}

export interface LoadProductDesignerProductTypesSuccessAction extends Action {
    payload: {
        productTypes: ProductType[],
    };
}

export function loadProductDesignerProductTypesSuccess(productTypes: ProductType[]): LoadProductDesignerProductTypesSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_PRODUCT_TYPES_SUCCESS,
        payload: {
            productTypes,
        },
    };
}

export interface LoadProductDesignerProductTypesFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadProductDesignerProductTypesFailure(error: AppError): LoadProductDesignerProductTypesFailureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_PRODUCT_TYPES_FAILURE,
        payload: {
            error,
        },
    };
}

//

export interface LoadProductDesignerHistoryStructuresAction extends Action {
    payload: {
        moduleId: string;
    };
}

export function loadProductDesignerHistoryStructures(moduleId: string): LoadProductDesignerHistoryStructuresAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_STRUCTURES,
        payload: {
            moduleId,
        }
    };
}

export interface LoadProductDesignerHistoryStructuresSuccessAction extends Action {
    payload: {
        structures: ProductDesignerHistoryStructures,
    };
}

export function loadProductDesignerHistoryStructuresSuccess(structures: ProductDesignerHistoryStructures): LoadProductDesignerHistoryStructuresSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_STRUCTURES_SUCCESS,
        payload: {
            structures,
        },
    };
}

export interface LoadProductDesignerHistoryStructuresFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadProductDesignerHistoryStructuresFailure(error: AppError): LoadProductDesignerHistoryStructuresFailureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_STRUCTURES_FAILURE,
        payload: {
            error,
        },
    };
}

//

export interface LoadProductDesignerHistoryInitialValuesAction extends Action {
    payload: {
        moduleId: string;
    };
}

export function loadProductDesignerHistoryInitialValues(moduleId: string): LoadProductDesignerHistoryInitialValuesAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_INITIAL_VALUES_REQUEST,
        payload: {
            moduleId,
        }
    };
}

export interface LoadProductDesignerHistoryInitialValuesSuccessAction extends Action {
    payload: {
        values: FormValues,
    };
}

export function loadProductDesignerHistoryInitialValuesSuccess(values: FormValues): LoadProductDesignerHistoryInitialValuesSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_INITIAL_VALUES_SUCCESS,
        payload: {
            values,
        },
    };
}

export interface LoadProductDesignerHistoryInitialValuesFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadProductDesignerHistoryInitialValuesFailure(error: AppError): LoadProductDesignerHistoryInitialValuesFailureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_INITIAL_VALUES_FAILURE,
        payload: {
            error,
        },
    };
}

//

export interface LoadProductDesignerRequestParameterValuesAction extends Action {
    payload: {
        productType: string;
    };
}

export function loadProductDesignerRequestParameterValues(productType: string): LoadProductDesignerRequestParameterValuesAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_REQUEST_PARAMETERS_VALUES,
        payload: {
            productType,
        },
    };
}

export interface LoadProductDesignerRequestParameterValuesSuccessAction extends Action {
    payload: {
        values: FormValues,
    };
}

export function loadProductDesignerRequestParameterValuesSuccess(values: FormValues): LoadProductDesignerRequestParameterValuesSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_REQUEST_PARAMETERS_VALUES_SUCCESS,
        payload: {
            values,
        },
    };
}

export interface LoadProductDesignerRequestParameterValuesFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadProductDesignerRequestParameterValuesFailure(error: AppError): LoadProductDesignerRequestParameterValuesFailureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_REQUEST_PARAMETERS_VALUES_FAILURE,
        payload: {
            error,
        },
    };
}

//

export interface LoadProductDesignerHistoryTableValuesAction extends Action {
    payload: {
        moduleId: string;
    };
}

export function loadProductDesignerHistoryTableValues(moduleId: string): LoadProductDesignerHistoryTableValuesAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_TABLE_VALUES,
        payload: {
            moduleId,
        }
    };
}

export interface LoadProductDesignerHistoryTableValuesSuccessAction extends Action {
    payload: {
        values: Entity[],
    };
}

export function loadProductDesignerHistoryTableValuesSuccess(values: Entity[]): LoadProductDesignerHistoryTableValuesSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_TABLE_VALUES_SUCCESS,
        payload: {
            values,
        },
    };
}

export interface LoadProductDesignerHistoryTableValuesFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadProductDesignerHistoryTableValuesFailure(error: AppError): LoadProductDesignerHistoryTableValuesFailureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_TABLE_VALUES_FAILURE,
        payload: {
            error,
        },
    };
}

//

export interface LoadProductDesignerHistoryFilterStructureAction extends Action {
    payload?: void;
}

export function loadProductDesignerHistoryFilterStructure(): LoadProductDesignerHistoryFilterStructureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_STRUCTURE,
    };
}

export interface LoadProductDesignerHistoryFilterStructureSuccessAction extends Action {
    payload: {
        structure: FormStructureConfig,
    };
}

export function loadProductDesignerHistoryFilterStructureSuccess(structure: FormStructureConfig): LoadProductDesignerHistoryFilterStructureSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_STRUCTURE_SUCCESS,
        payload: {
            structure,
        },
    };
}

export interface LoadProductDesignerHistoryFilterStructureFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadProductDesignerHistoryFilterStructureFailure(error: AppError): LoadProductDesignerHistoryFilterStructureFailureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_STRUCTURE_FAILURE,
        payload: {
            error,
        },
    };
}

//

export interface LoadProductDesignerHistoryFilterValuesAction extends Action {
    payload: {
        moduleId: string;
    };
}

export function loadProductDesignerHistoryFilterValues(moduleId: string): LoadProductDesignerHistoryFilterValuesAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_VALUES,
        payload: {
            moduleId,
        }
    };
}

export interface LoadProductDesignerHistoryFilterValuesSuccessAction extends Action {
    payload: {
        values: FormValues,
    };
}

export function loadProductDesignerHistoryFilterValuesSuccess(values: FormValues): LoadProductDesignerHistoryFilterValuesSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_VALUES_SUCCESS,
        payload: {
            values,
        },
    };
}

export interface LoadProductDesignerHistoryFilterValuesFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadProductDesignerHistoryFilterValuesFailure(error: AppError): LoadProductDesignerHistoryFilterValuesFailureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_VALUES_FAILURE,
        payload: {
            error,
        },
    };
}

//

// ---- Survey Start ------
//

export interface LoadProductDesignerSurveyTableStructureAction extends Action {
    payload?: void;
}

export function loadProductDesignerSurveyTableStructure(): LoadProductDesignerSurveyTableStructureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_STRUCTURE,
    };
}

export interface LoadProductDesignerSurveyTableStructureSuccessAction extends Action {
    payload: {
        structure: EntityTableConfig,
    };
}

export function loadProductDesignerSurveyTableStructureSuccess(structure: EntityTableConfig): LoadProductDesignerSurveyTableStructureSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_STRUCTURE_SUCCESS,
        payload: {
            structure,
        },
    };
}

export interface LoadProductDesignerSurveyTableStructureFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadProductDesignerSurveyTableStructureFailure(error: AppError): LoadProductDesignerSurveyTableStructureFailureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_STRUCTURE_FAILURE,
        payload: {
            error,
        },
    };
}

//

export interface LoadProductDesignerSurveyTableValuesAction extends Action {
    payload?: void;
}

export function loadProductDesignerSurveyTableValues(): LoadProductDesignerSurveyTableValuesAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_VALUES,
    };
}

export interface LoadProductDesignerSurveyTableValuesSuccessAction extends Action {
    payload: {
        values: Entity[],
    };
}

export function loadProductDesignerSurveyTableValuesSuccess(values: Entity[]): LoadProductDesignerSurveyTableValuesSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_VALUES_SUCCESS,
        payload: {
            values,
        },
    };
}

export interface LoadProductDesignerSurveyTableValuesFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadProductDesignerSurveyTableValuesFailure(error: AppError): LoadProductDesignerSurveyTableValuesFailureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_VALUES_FAILURE,
        payload: {
            error,
        },
    };
}

//

export interface LoadProductDesignerSurveyFilterStructureAction extends Action {
    payload?: void;
}

export function loadProductDesignerSurveyFilterStructure(): LoadProductDesignerSurveyFilterStructureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_STRUCTURE,
    };
}

export interface LoadProductDesignerSurveyFilterStructureSuccessAction extends Action {
    payload: {
        structure: FormStructureConfig,
    };
}

export function loadProductDesignerSurveyFilterStructureSuccess(structure: FormStructureConfig): LoadProductDesignerSurveyFilterStructureSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_STRUCTURE_SUCCESS,
        payload: {
            structure,
        },
    };
}

export interface LoadProductDesignerSurveyFilterStructureFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadProductDesignerSurveyFilterStructureFailure(error: AppError): LoadProductDesignerSurveyFilterStructureFailureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_STRUCTURE_FAILURE,
        payload: {
            error,
        },
    };
}

//

export interface LoadProductDesignerSurveyFilterValuesAction extends Action {
    payload?: void;
}

export function loadProductDesignerSurveyFilterValues(): LoadProductDesignerSurveyFilterValuesAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_VALUES,
    };
}

export interface LoadProductDesignerSurveyFilterValuesSuccessAction extends Action {
    payload: {
        values: FormValues,
    };
}

export function loadProductDesignerSurveyFilterValuesSuccess(values: FormValues): LoadProductDesignerSurveyFilterValuesSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_VALUES_SUCCESS,
        payload: {
            values,
        },
    };
}

export interface LoadProductDesignerSurveyFilterValuesFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadProductDesignerSurveyFilterValuesFailure(error: AppError): LoadProductDesignerSurveyFilterValuesFailureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_VALUES_FAILURE,
        payload: {
            error,
        },
    };
}

//

// ---- Survey End -----

export interface ValidateProductDesignerProductAction extends Action {
    payload: {
        request: ProductRequestParameters,
    };
}

export function validateProductDesignerProduct(request: ProductRequestParameters): ValidateProductDesignerProductAction {
    return {
        type: ProductDesignerActions.VALIDATE_PRODUCT_DESIGNER_PRODUCT,
        payload: {
            request,
        },
    };
}

export interface ValidateProductDesignerProductSuccessAction extends Action {
    payload: {
        validation: ProductValidation,
    };
}

export function validateProductDesignerProductSuccess(validation: ProductValidation): ValidateProductDesignerProductSuccessAction {
    return {
        type: ProductDesignerActions.VALIDATE_PRODUCT_DESIGNER_PRODUCT_SUCCESS,
        payload: {
            validation,
        },
    };
}

export interface ValidateProductDesignerProductFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function validateProductDesignerProductFailure(error: AppError): ValidateProductDesignerProductFailureAction {
    return {
        type: ProductDesignerActions.VALIDATE_PRODUCT_DESIGNER_PRODUCT_FAILURE,

        payload: {
            error,
        },
    };
}

// ----


export interface LoadRequestFormMultiRequestStructureAction extends Action {
    payload: {
        productType: string;
        solveTarget: string;
        moduleId: string;
    };
}

export function loadRequestFormMultiRequestStructure( productType: string, solveTarget: string, moduleId?: string): LoadRequestFormMultiRequestStructureAction {
    return {
        type: ProductDesignerActions.LOAD_REQUEST_FORM_MULTI_REQUEST_STRUCTURE_REQUEST,
        payload: {
            productType,
            solveTarget,
            moduleId,
        },
    };
}

export interface LoadRequestFormMultiRequestStructureSuccessAction extends Action {
    payload: {
        structure: MultiRequestStructure,
    };
}

export function loadRequestFormMultiRequestStructureSuccess(structure: MultiRequestStructure): LoadRequestFormMultiRequestStructureSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_REQUEST_FORM_MULTI_REQUEST_STRUCTURE_SUCCESS,
        payload: {
            structure,
        },
    };
}

export interface LoadRequestFormMultiRequestStructureFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadRequestFormMultiRequestStructureFailure(error: AppError): LoadRequestFormMultiRequestStructureFailureAction {
    return {
        type: ProductDesignerActions.LOAD_REQUEST_FORM_MULTI_REQUEST_STRUCTURE_FAILURE,

        payload: {
            error,
        },
    };
}

// ---

export interface LoadProductDesignerAllIssuersAction extends Action {
    payload: {
        moduleId: string;
        productType: string;
    };
}

export function loadProductDesignerAllIssuers(
    moduleId: string,
    productType: string
): LoadProductDesignerAllIssuersAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_ALL_ISSUERS,
        payload: {
            moduleId,
            productType
        }
    };
}

export interface LoadProductDesignerAllIssuersSuccessAction extends Action {
    payload: {
        issuers: ProductIssuerOrganization[],
    };
}

export function loadProductDesignerAllIssuersSuccess(issuers: ProductIssuerOrganization[]): LoadProductDesignerAllIssuersSuccessAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_ALL_ISSUERS_SUCCESS,
        payload: {
            issuers,
        },
    };
}

export interface LoadProductDesignerAllIssuersFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadProductDesignerAllIssuersFailure(error: AppError): LoadProductDesignerAllIssuersFailureAction {
    return {
        type: ProductDesignerActions.LOAD_PRODUCT_DESIGNER_ALL_ISSUERS_FAILURE,

        payload: {
            error,
        },
    };
}

// ---
export interface TransformProductDesignerComponentAction extends Action {
    payload: {
        group: string;
        component: ProductComponentStructure;
        data: ProductComponent;
    };
}

export function transformProductDesignerComponent(group: string, component: ProductComponentStructure, data: ProductComponent): TransformProductDesignerComponentAction {
    return {
        type: ProductDesignerActions.TRANSFORM_PRODUCT_DESIGNER_COMPONENT,
        payload: {
            group,
            component,
            data,
        },
    };
}

export interface TransformProductDesignerComponentSuccessAction extends Action {
    payload: {
        group: string;
        component: ProductComponentStructure;
        data: ProductComponent;
    };
}

export function transformProductDesignerComponentSuccess(group: string, component: ProductComponentStructure, data: ProductComponent): TransformProductDesignerComponentSuccessAction {
    return {
        type: ProductDesignerActions.TRANSFORM_PRODUCT_DESIGNER_COMPONENT_SUCCESS,
        payload: {
            group,
            component,
            data,
        },
    };
}

export interface TransformProductDesignerComponentFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function transformProductDesignerComponentFailure(error: AppError): TransformProductDesignerComponentFailureAction {
    return {
        type: ProductDesignerActions.TRANSFORM_PRODUCT_DESIGNER_COMPONENT_FAILURE,
        payload: {
            error,
        },
    };
}

export interface ResetProductDesignerProductResponseAction extends Action {
    payload: {
        data?: any;
    };
}

export function resetProductDesignerProductResponse(data?: any): ResetProductDesignerProductResponseAction {
    return {
        type: ProductDesignerActions.RESET_PRODUCT_DESIGNER_PRODUCT_RESPONSE,
        payload: {
            data,
        },
    };
}

export interface ResetProductDesignerStateAction extends Action {
    payload?: void;
}

export function resetProductDesignerState(): ResetProductDesignerStateAction {
    return {
        type: ProductDesignerActions.RESET_PRODUCT_DESIGNER_STATE,
    };
}
