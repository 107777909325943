import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { isEqual, range } from 'lodash';
import { Subscription } from 'rxjs';
import { FormControlConfig, FormControlSelectOption } from '../../../../core/models/form.model';
import { SingleValueFilterControl } from '../../../../shared/components/filter-control';
import { DateInputComponent } from '../../../../shared/components/date-input/date-input.component';
import { NgIf, NgFor } from '@angular/common';

/**
 * FilterTradeDateComponent that is responsible for the trade date selector filter
 */
@Component({
    selector: 'app-trade-date',
    templateUrl: './filter-trade-date.component.html',
    styleUrls: ['./filter-trade-date.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor, DateInputComponent]
})
export class FilterTradeDateComponent implements OnInit, OnDestroy, SingleValueFilterControl<any> {

    public dateRegex = /^(0[1-9]|[12][0-9]|3[01])[/.](0[1-9]|1[012])[/.](19|20)\d\d$/;

    @Input() public config: FormControlConfig;

    @Input() public values: FormControlSelectOption[];

    @Input() public disabled = false;

    private _value: TradeDateFilterValue;

    public filterForm: UntypedFormGroup;

    public formSubscription: Subscription;

    get value(): TradeDateFilterValue {
        return this._value;
    }

    public onChange: () => void;

    public onTouched: () => void;

    public availableYears: number[];

    constructor(private fb: UntypedFormBuilder) {
        this._updateForm();
        const currentYear = new Date().getFullYear();
        this.availableYears = range(currentYear - 50, currentYear + 1);
    }

    public ngOnInit() {
    }

    /**
     * Writes the trade date filter value
     * @param {TradeDateFilterValue} value
     */
    public writeValue(value: TradeDateFilterValue): void {
        if (!isEqual(this._value, value)) {
            this._value = value;

            if (this.filterForm) {
                this.filterForm.patchValue(this._value, {emitEvent: false});
                this._updateForm();
            }

            if (this.onChange) {
                this.onChange();
            }
        }
    }

    /**
     * Returns if the trade date filter value is valid or not
     * @param {TradeDateFilterValue} value
     * @returns {boolean}
     * @private
     */
    protected _isValidValue(value: TradeDateFilterValue) {
        if (value && value.params && value.params.to && value.params.from) {
            return value.params.from <= value.params.to;
        } else {
            return true;
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this._value);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this._isValidValue(this._value)) ? null : {
            dateTradeError: 'Invalid value specified',
        };
    }

    private _updateForm() {
        if (this.formSubscription) {
            this.formSubscription.unsubscribe();
        }
        const formConfig: any = {
            type: [''],
        };
        if (this.filterForm && this.filterForm.value) {
            const type = this.filterForm.value.type;
            if (type === 'range') {
                formConfig.range = this.fb.group({
                    from: [''],
                    to: [''],
                });
            }
            if (type === 'month') {
                formConfig.month = this.fb.group({
                    month: [new Date().getMonth() + 1],
                    year: [new Date().getFullYear()],
                });
            }
        }
        this.filterForm = this.fb.group(formConfig);
        this.formSubscription = this.filterForm.valueChanges.subscribe((value) => {
            const oldValue = this._value;
            this._value = value;
            if (!oldValue || value.type !== oldValue.type) {
                this._updateForm();
            } else {
                this.onChange();
            }
        });
        if (this._value) {
            this.filterForm.patchValue(this._value);
        }
    }

    public ngOnDestroy(): void {
        delete this.onChange;
    }

}

export interface TradeDateFilterValue {
    type: string;
    params: any;
}
