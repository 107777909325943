import { Injectable } from '@angular/core';
import { get } from 'lodash';
import { StyleDecorator } from '../../shared/models/decorators.model';
import { Entity } from '../../shared/models/entities.model';
import { calculateColorBrightness, getHue, HSL_LIGHTNESS, HSL_SATURATION, HSLtoRGB, RGBtoHEX } from '../helpers/colors.helper';

export interface StyleDecoratorResult {
    styles?: {
        [key: string]: string,
    };
    classNames?: {
        [key: string]: boolean,
    };
}

@Injectable()
export class EntityTableStyleDecoratorService {
    private EMPTY = {
        classNames: {bright: false},
    };

    constructor() {
    }

    public getDataFromPath(entity: Entity, path: string): any {
        return get(entity, path);
    }

    public none(data, context: Entity, options): StyleDecoratorResult {
        return {};
    }

    public relative(data, context: Entity, options): StyleDecoratorResult {
        let val = (data === null) ? 0.0 : parseFloat(data);
        const decoratorOptions = {minVal: 0, maxVal: 100, normalize: true, ...options};

        const percentColors = [
            {pct: 0.0, color: {r: 0xBD, g: 0x36, b: 0x2F}}, // red #BD362F
            {pct: 0.5, color: {r: 0xff, g: 0xff, b: 0x00}},
            {pct: 1.0, color: {r: 0x00, g: 0x80, b: 0x00}}, // green
        ];

        const getColorForPercentage = function (pct) {
            let i = 1;

            for (i = 1; i < percentColors.length - 1; i++) {
                if (pct < percentColors[i].pct) {
                    break;
                }
            }
            const lower = percentColors[i - 1];
            const upper = percentColors[i];
            const range = upper.pct - lower.pct;
            const rangePct = (pct - lower.pct) / range;
            const pctLower = 1 - rangePct;
            const pctUpper = rangePct;
            const color = {
                r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
                g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
                b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
            };

            return [color.r, color.g, color.b];
        };

        if (decoratorOptions.minVal == decoratorOptions.maxVal) {
            val = 1;
        } else {
            val = (val - decoratorOptions.minVal) / (decoratorOptions.maxVal - decoratorOptions.minVal);
        } // normalize to [0, 1]

        const rgb = getColorForPercentage(val);
        const rgbHex = RGBtoHEX(rgb[0], rgb[1], rgb[2]);
        const brightness = calculateColorBrightness(rgb[0], rgb[1], rgb[2]);

        return {
            styles: {'background-color': rgbHex},
            classNames: {
                bright: brightness <= 170,
            },
        };
    }

    public heatmap(data, context: Entity, options): StyleDecoratorResult {
        let val = (data === null) ? 0.0 : parseFloat(data);
        const saturation = (data === null) ? 0.0 : HSL_SATURATION;
        const decoratorOptions = {hueMean: 0.25, hueC: 20.0, minVal: 0, maxVal: 100, normalize: true, ...options};

        if (decoratorOptions.normalize) {
            val = (val - decoratorOptions.minVal) / (decoratorOptions.maxVal - decoratorOptions.minVal); // normalize to [0, 1]
        }

        const rgb = HSLtoRGB(
            getHue(
                val,
                decoratorOptions.hueMean,
                decoratorOptions.hueC,
            ),
            saturation, HSL_LIGHTNESS,
        ).map((c) => Math.round(c));

        const rgbHex = RGBtoHEX(rgb[0], rgb[1], rgb[2]);
        const brightness = calculateColorBrightness(rgb[0], rgb[1], rgb[2]);

        return {
            styles: {'background-color': rgbHex},
            classNames: {
                bright: brightness <= 170,
            },
        };
    }

    public applyDecorator(name: string, entity: Entity, path: string, options?: any): StyleDecoratorResult {
        let decorator: StyleDecorator;

        if (this[name]) {
            decorator = this[name];
        } else {
            decorator = this.none;
        }

        options = options || {};

        const data = this.getDataFromPath(entity, path);

        if (data !== undefined && data !== null) {
            return decorator.bind(this)(data, entity, options);
        } else {
            return this.EMPTY;
        }
    }
}
