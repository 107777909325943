import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LatestIssuedProductsItem } from './entity-widget-latest-issued-products.component';
import { TruncatePipe } from '../../../pipes/truncate.pipe';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { NgIf, NgFor } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-entity-widget-latest-issued-products-item',
    template: `
        <div class="entity-widget-latest-issued-products__list__item"
             [routerLink]="['/app', {'outlets': {'overlay': ['product-details', latestIssuedProduct?.dq || '']}}]">
            <div class="entity-widget-latest-issued-products__list__item__content">
                <a class="entity-widget-latest-issued-products__list__item__name" href="#"
                   [routerLink]="['/app', {'outlets': {'overlay': ['product-details', latestIssuedProduct?.dq || '']}}]">{{latestIssuedProduct.dericonName | truncate:[70] }}</a>
                <span class="entity-widget-latest-issued-products__list__item__meta" *ngIf="latestIssuedProduct.rows">
                    <span class="d-block" *ngFor="let r of latestIssuedProduct.rows">{{r}}</span>
                </span>
                <span class="entity-widget-latest-issued-products__list__item__issue-date">{{ latestIssuedProduct.issueDate | translocoDate: { dateStyle: 'medium' } }}</span>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [RouterLink, NgIf, NgFor, TranslocoLocaleModule, TruncatePipe]
})
export class EntityWidgetLatestIssuedProductsItemComponent {
    @Input()
    public latestIssuedProduct: LatestIssuedProductsItem;
}
