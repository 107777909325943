import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { ConfigurationWindow } from '../../../declarations';
import { Configuration } from '../../shared/models/configuration';
import { AppState } from '../store/state/index.state';
import { filter, map } from 'rxjs/operators';
import { truthy } from '../../shared/helpers/general.helper';
import { includes } from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    
    public configuration: Configuration;

    public displaySearch$ = this.store.select((state) => {
        return state.config.uiFeatureFlags && !includes(state.config.uiFeatureFlags, 'DISABLE_GLOBAL_PRODUCT_SEARCH_BOX');
    });

    constructor(private store: Store<AppState>) {
        this.configuration = (window as unknown as ConfigurationWindow).configuration;
    }

    public getMercuryEnvironmentUrl(): Observable<string> {
        return this.store.select((state) => state.config.mercuryEnvironmentUrl);
    }

    public getLogoImageUrl(type = 'logo'): string {
        return this.configuration.environment.assets[type];
    }
}
