<div class="col double-dropdown-input__label label-selection">
    {{ config?.options?.label }}
    <div *ngIf="config?.options?.helpText" class="double-dropdown-input__info" [matTooltip]="config?.options?.helpText"></div>
</div>
<div *ngIf="visibleInput else: textOnly" [formGroup]="form" class="form-group input-row">
    <div class="value-selection">
        <div class="app-filter-radio-button-group" [class.has-selection]="!!form.get(valuePath).value" [class.select-less-than]="config?.options?.displayLessThanSelection">
            <mat-button-toggle-group [formControlName]="valuePath" [multiple]="false">
                <mat-button-toggle *ngFor="let value of inputValues" [value]="value.id" disableRipple="true">
                    {{ value.label }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
    <div>
        <div class="range-filter__stretch">
            <div class="range-filter__center">
                <app-icon
                    *ngIf="!!form.get(valuePath).value"
                    class="reset-icon"
                    icon="derifin:reset"
                    (click)="resetButtonGroup($event)"></app-icon>
            </div>
        </div>
    </div>
</div>

<ng-template #textOnly>
    <ng-container *ngIf="form.get(valuePath).value">
        <ng-container *ngFor="let value of inputValues">
            <span *ngIf="value.id === form.get(valuePath).value">{{ value.label }}</span>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!form.get(valuePath).value || form.get(valuePath).value === 'NONE'">
        <span>&mdash;</span>
    </ng-container>
</ng-template>
