/**
 * Created by Alex Klein <alex@rocketloop.de> on 10/17/17.
 */

import { Action } from '@ngrx/store';
import {
    ClearModuleDataAction,
    LoadFactSheetContextSucceededAction,
    LoadListModuleContextSucceededAction,
    LoadModuleDataSucceededAction,
    LoadProductSelectionSucceededAction,
    LoadSingleModuleContextSucceededAction,
    LoadWatchlistContextSucceededAction,
    LoadWatchlistModuleDataAction, LoadWatchlistModuleDataFailedAction,
    LoadWatchlistModuleDataSucceededAction,
    RiskManagerActionTypes,
    SaveWatcherSucceededAction,
    UpdateWatcherAction,
} from '../actions/risk-manager.actions';
import { RiskManagerState } from '../state/risk-manager.state';

export const initialState: RiskManagerState = {
    currentContext: null,
    structure: {},
    values: {},
    watcher: {},
    watchlist: {},
    watchlistContext: {},
    productSelectionTableStructure: {},
    productSelectionTableValues: {},
    productSelectionFilterStructure: {},
    settingsSaved: null,
    watchlistSaved: null,
    loadingContext: null,
    loadingModuleData: null,
    loadingWatchlistData: null,
    loadingWatchlistContext: null,
};

/**
 * The reducer responsible for the @link{RiskManagerState}
 * @param state
 * @param action
 * @returns {any}
 */
export function reducer(state: RiskManagerState = initialState,
                        action: Action): RiskManagerState {
    switch (action.type) {
        case RiskManagerActionTypes.LOAD_FACT_SHEET_CONTEXT_SUCCEEDED:
            const loadFactSheetContextSucceededAction = action as LoadFactSheetContextSucceededAction;
            return {
                ...state,
                currentContext: loadFactSheetContextSucceededAction.payload.context,
            };

        case RiskManagerActionTypes.LOAD_LIST_MODULE_CONTEXT_SUCCEEDED:
            const loadListModuleContextSucceeded = action as LoadListModuleContextSucceededAction;
            return {
                ...state,
                currentContext: loadListModuleContextSucceeded.payload.context,
            };

        case RiskManagerActionTypes.LOAD_WATCHLIST_CONTEXT: {
            return {
                ...state,
                loadingWatchlistContext: true,
            };
        }

        case RiskManagerActionTypes.LOAD_WATCHLIST_CONTEXT_SUCCEEDED: {
            const loadWatchlistContextSucceeded = action as LoadWatchlistContextSucceededAction;

            return {
                ...state,
                loadingWatchlistContext: false,
                watchlist: {
                    ...state.watchlist,
                    [loadWatchlistContextSucceeded.payload.watchlistId]: {},
                },
                watchlistContext: {
                    ...state.watchlistContext,
                    [loadWatchlistContextSucceeded.payload.watchlistId]: loadWatchlistContextSucceeded.payload.context,
                }
            }
        }

        case RiskManagerActionTypes.LOAD_WATCHLIST_CONTEXT_FAILED: {
            return {
                ...state,
                loadingWatchlistContext: false,
            };
        }

        case RiskManagerActionTypes.LOAD_WATCHLIST_MODULE_DATA: {
            const loadWatchlistModuleData = action as LoadWatchlistModuleDataAction;

            return {
                ...state,
                loadingWatchlistData: {
                    ...(state.loadingWatchlistData || {}),
                    [loadWatchlistModuleData.payload.context.instrumentType]: true,
                },
            };
        }

        case RiskManagerActionTypes.LOAD_WATCHLIST_MODULE_DATA_SUCCEEDED: {
            const loadWatchlistModuleDataSucceeded = action as LoadWatchlistModuleDataSucceededAction;

            return {
                ...state,
                loadingWatchlistData: {
                    ...(state.loadingWatchlistData || {}),
                    [loadWatchlistModuleDataSucceeded.payload.context.instrumentType]: false,
                },
                watchlist: {
                    ...state.watchlist,
                    [loadWatchlistModuleDataSucceeded.payload.context.watchlistId]: {
                        ...(state.watchlist[loadWatchlistModuleDataSucceeded.payload.context.watchlistId] || {}),
                        [loadWatchlistModuleDataSucceeded.payload.context.instrumentType]: loadWatchlistModuleDataSucceeded.payload.data,
                    },
                },
            };
        }

        case RiskManagerActionTypes.LOAD_WATCHLIST_MODULE_DATA_FAILED: {
            const loadWatchlistModuleDataFailed = action as LoadWatchlistModuleDataFailedAction;

            return {
                ...state,
                loadingWatchlistData: {
                    [loadWatchlistModuleDataFailed.payload.context.instrumentType]: false,
                },
            }
        }

        case RiskManagerActionTypes.LOAD_SINGLE_MODULE_CONTEXT:
            return {
                ...state,
                loadingContext: true,
            };

        case RiskManagerActionTypes.LOAD_SINGLE_MODULE_CONTEXT_SUCCEEDED:
            const loadSingleModuleContextSucceeded = action as LoadSingleModuleContextSucceededAction;
            return {
                ...state,
                currentContext: loadSingleModuleContextSucceeded.payload.context,
                loadingContext: false,
            };

        case RiskManagerActionTypes.LOAD_SINGLE_MODULE_CONTEXT_FAILED:
            return {
                ...state,
                loadingContext: false,
            };

        case RiskManagerActionTypes.LOAD_MODULE_DATA:
            return {
                ...state,
                loadingModuleData: true,
            };

        case RiskManagerActionTypes.LOAD_MODULE_DATA_SUCCEEDED:
            const loadModuleDataSucceeded = action as LoadModuleDataSucceededAction;
            return {
                ...state,
                loadingModuleData: false,
                structure: {
                    ...state.structure,
                    [loadModuleDataSucceeded.payload.moduleId]: loadModuleDataSucceeded.payload.data.structure,
                },
                values: {
                    ...state.values,
                    [loadModuleDataSucceeded.payload.moduleId]: loadModuleDataSucceeded.payload.data.values,
                },
                watcher: {
                    ...state.watcher,
                    [loadModuleDataSucceeded.payload.moduleId]: loadModuleDataSucceeded.payload.data.watcher,
                },
            };

        case RiskManagerActionTypes.LOAD_MODULE_DATA_FAILED:
            return {
                ...state,
                loadingModuleData: false,
            };

        case RiskManagerActionTypes.SAVE_WATCHER:
            return {
                ...state,
                settingsSaved: null,
            };

        case RiskManagerActionTypes.SAVE_WATCHER_SUCCEEDED:
            const saveWatcherSucceededAction = action as SaveWatcherSucceededAction;
            return {
                ...state,
                watcher: {
                    ...state.watcher,
                    [saveWatcherSucceededAction.payload.moduleId]: saveWatcherSucceededAction.payload.watcher,
                },
                settingsSaved: true,
            };

        case RiskManagerActionTypes.SAVE_WATCHERS: {
            return {
                ...state,
                watchlistSaved: null,
            };
        }

        case RiskManagerActionTypes.SAVE_WATCHERS_SUCCEEDED: {
            return {
                ...state,
                watchlistSaved: true,
            };
        }

        case RiskManagerActionTypes.UPDATE_WATCHER:
            const updateWatcherAction = action as UpdateWatcherAction;
            return {
                ...state,
                watcher: {
                    ...state.watcher,
                    [updateWatcherAction.payload.moduleId]: {
                        ...state.watcher[updateWatcherAction.payload.moduleId],
                        ...updateWatcherAction.payload.watcherData,
                    },
                },
            };
        case RiskManagerActionTypes.LOAD_PRODUCT_SELECTION_SUCCEEDED:
            const loadProductSelectionSucceededAction = action as LoadProductSelectionSucceededAction;
            return {
                ...state,
                productSelectionTableStructure: {
                    ...state.productSelectionTableStructure,
                    [loadProductSelectionSucceededAction.payload.moduleId]:
                    loadProductSelectionSucceededAction.payload.data.structure.table,
                },
                productSelectionTableValues: {
                    ...state.productSelectionTableValues,
                    [loadProductSelectionSucceededAction.payload.moduleId]:
                    loadProductSelectionSucceededAction.payload.data.values.table,
                },
                productSelectionFilterStructure: {
                    ...state.productSelectionFilterStructure,
                    [loadProductSelectionSucceededAction.payload.moduleId]:
                    loadProductSelectionSucceededAction.payload.data.structure.search,
                },
            };

        case RiskManagerActionTypes.CLEAR_MODULE_DATA:
            const clearModuleDataAction = action as ClearModuleDataAction;
            return {
                ...state,
                structure: {
                    ...state.structure,
                    [clearModuleDataAction.payload.moduleId]: null,
                },
                watcher: {
                    ...state.watcher,
                    [clearModuleDataAction.payload.moduleId]: null,
                },
                values: {
                    ...state.values,
                    [clearModuleDataAction.payload.moduleId]: null,
                },
                currentContext: null,
                loadingModuleData: null,
                loadingContext: null,
            };

        default:
            return state;
    }
}
