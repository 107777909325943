<div class="form-group row {{config?.options.additionalClasses || ''}}" [class.has-danger]="!disabled && !input.valid">
    <div class="col-{{config?.options.labelCols || 6}}">{{ label }}</div>
    <div class="col-4">
        <input (blur)="blurControl.next()" #input="ngModel" class="form-control" [min]="values?.min" [max]="values?.max"
               [attr.min]="values?.min" [attr.max]="values?.max" (ngModelChange)="onInputChange($event)"
               [ngModel]="config?.options.isPercent ? (+value) * 100 : value" [disabled]="disabled"
               [placeholder]="config?.options?.placeholder || ''" placement="top"
               [matTooltip]="(values && values.min !== undefined && values.max !== undefined) ? ('filters.numberInput.tooltip' | transloco:values) : ''"
               triggers="focus:blur"/>
    </div>
    <div class="col-2">
        {{ unit }}
    </div>
</div>
