/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 09/27/2017.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DerifinApi } from '../../../core/api/derifin.api';
import { ApiResponse } from '../../../core/api/models/api.model';
import { FormValues } from '../../../core/models/form.model';
import { AuthStoreService } from '../../../core/services/auth-store.service';
import { ConfigurationService } from '../../../core/services/configuration.service';
import { LogglyLoggerService } from '../../../core/services/loggly-logger.service';
import { TargetMarketCriteriaStructure } from '../models/target-market-criteria-structure.model';
import { TargetMarketCriteria } from '../models/target-market-criteria.model';

import { TargetMarketCustomerData } from '../models/target-market-customer-data.model';
import { TargetMarketCustomCustomer, TargetMarketCustomCustomerData } from '../models/target-market-customer.model';
import { transformTargetMarketSubset } from './target-market.transform';

/**
 * API abstraction layer for the Target Market API
 */
@Injectable()
export class TargetMarketApi extends DerifinApi {

    constructor(http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, appConfigService: ConfigurationService) {
        super('m/target-market', appConfigService.configuration.environment.id, http, authStoreService, loggerService, appConfigService);
    }

    /**
     * Get the set of target market customer data
     * @returns {Observable<TargetMarketCustomerData>}
     */
    public getTargetMarketCutomerData(): Observable<TargetMarketCustomerData[]> {
        return this.get('').pipe(
            map((response: ApiResponse<TargetMarketCustomerData[]>) => response.data),
        );
    }

    /**
     * Get the structure for the target market criteria form
     * @returns {Observable<TargetMarketCriteriaStructure>}
     */
    public getTargetMarketCriteriaStructure(): Observable<TargetMarketCriteriaStructure> {
        return this.get('structure').pipe(
            map((response: ApiResponse<TargetMarketCriteriaStructure>) => response.data),
        );
    }

    /**
     * Get the available values for the target market criteria form
     * @returns {Observable<FormValues>}
     */
    public getTargetMarketCriteriaAvailableValues(): Observable<FormValues> {
        return this.get('values').pipe(
            map((response: ApiResponse<FormValues>) => response.data),
        );
    }

    /**
     * Encode the given target market criteria into an encoded string representation
     * @param {TargetMarketCriteria} targetMarketCriteria
     * @returns {Observable<string>}
     */
    public encodeTargetMarketCriteria(targetMarketCriteria: TargetMarketCriteria): Observable<string> {
        return this.post('encode', targetMarketCriteria).pipe(
            map((response: ApiResponse<string>) => response.data),
        );
    }

    /**
     * Get the decoded target market criteria from the given encoded string representation
     * @param {string} encodedTargetMarketCriteria
     * @returns {Observable<TargetMarketCriteria>}
     */
    public decodeTargetMarketCriteria(encodedTargetMarketCriteria: string): Observable<TargetMarketCriteria> {
        return this.get(`decode/${encodedTargetMarketCriteria}`).pipe(
            map((response: ApiResponse<TargetMarketCriteria>) => response.data),
        );
    }

    /**
     * Get the custom target market customers
     * @returns {Observable<any>}
     */
    public getTargetMarketCustomCustomers(): Observable<TargetMarketCustomCustomer[]> {
        return this.get('custom').pipe(
            map((response: ApiResponse<TargetMarketCustomCustomer[]>) => response.data),
        );
    }

    /**
     * Get the reference target market customers
     * @returns {Observable<any>}
     */
    public getTargetMarketReferenceCustomers(): Observable<TargetMarketCustomCustomer[]> {
        return this.get('reference').pipe(
            map((response: ApiResponse<TargetMarketCustomCustomer[]>) => response.data),
        );
    }

    /**
     * Create a target market custom customer
     * @returns {Observable<TargetMarketCustomCustomer>}
     */
    public createTargetMarketCustomCustomer(data: TargetMarketCustomCustomerData): Observable<TargetMarketCustomCustomer> {
        return this.post(`custom`, data).pipe(
            map((response: ApiResponse<TargetMarketCustomCustomer>) => response.data),
        );
    }

    /**
     * Update the target market custom customer with the given id
     * @returns {Observable<TargetMarketCustomCustomer>}
     */
    public updateTargetMarketCustomCustomer(
        customerId: string,
        data: TargetMarketCustomCustomerData,
    ): Observable<TargetMarketCustomCustomer> {
        return this.post(`custom/${customerId}`, {
            ...data,
            id: customerId,
        }).pipe(
            map((response: ApiResponse<TargetMarketCustomCustomer>) => response.data),
        );
    }

    /**
     * Remove the target market custom customer with the given id
     * @returns {Observable<any>}
     */
    public removeTargetMarketCustomCustomer(customerId: string): Observable<void> {
        return this.delete(`custom/${customerId}`).pipe(
            map((response) => null),
        );
    }

    /** Speed boost **/

    /**
     * Get the custom target market customer suggestions
     * @returns {Observable<any>}
     */
    public getTargetMarketCustomerSuggestions(filter: string): Observable<any> {
        return this.get('customer/custom/', {
            params: {
                filter,
            },
        }).pipe(
            map((response: ApiResponse<any>) => transformTargetMarketSubset(response.data)),
        );
    }

    /**
     * Get the criteria for the given criteria id
     * @returns {Observable<any>}
     */
    public getTargetMarketCustomerCriteria(criteriaId: string): Observable<TargetMarketCustomerData> {
        return this.get(`customer/${criteriaId}`).pipe(
            map((response: ApiResponse<TargetMarketCustomerData>) => response.data),
        );
    }
}
