import { Action } from '@ngrx/store';
import { set } from 'lodash';
import { LoadStringSuccessAction, StringsActionTypes } from '../actions/strings.action';
import { StringsState } from '../state/strings.state';

export const initialState: StringsState = {
    data: {},
    loading: false,
};

/**
 * The reducer responsible for the @link{StringsState}
 * @param state
 * @param action
 * @returns {any}
 */
export function stringsReducer(state: StringsState = initialState, action: Action): StringsState {

    switch (action.type) {
        case StringsActionTypes.LOAD_STRINGS: {
            return {
                ...state,
                loading: true,
            };
        }

        case StringsActionTypes.LOAD_STRINGS_FAILED: {
            return {
                ...state,
                loading: false,
            };
        }

        case StringsActionTypes.LOAD_STRINGS_SUCCESS: {
            const stringsLoadSuccessAction = action as LoadStringSuccessAction;
            const currentState = {...state, loading: false};

            // set(currentState.data, stringsLoadSuccessAction.payload.path, stringsLoadSuccessAction.payload.strings);
            return currentState;
        }

        default:
            return state;

    }

}
