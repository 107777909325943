import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { get, merge } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { FormControlGroupConfig, FormStructureConfig, FormValues } from '../../../../core/models/form.model';
import { DynamicChartTimeFrame, DynamicChartTimeFrameOption, ExtendedDynamicChartDataResponse } from '../../../models/dynamic-chart.model';
import { EntityWidgetAdditionalDataChannel, EntityWidgetOptions } from '../../../models/widgets.model';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { WidgetDataActionTypes } from '../../../../core/store/actions/widgets-data.action';
import { DynamicChartNewComponent } from '../../../components/dynamic-chart-new/dynamic-chart-new.component';
import { SpinnerComponent } from '../../../components/spinner/spinner.component';
import { NgIf, NgStyle } from '@angular/common';

type ExtendedWidgetGraphOptions = EntityWidgetOptions & {
    showForm: boolean;
    formStructure: FormStructureConfig;
    formInitialValues: FormValues;
    formAvailableValues: FormValues;

    filterControls: FormControlGroupConfig[];
    initialRequest: ExtendedWidgetGraphData;
};

interface ExtendedWidgetGraphData {
    category: {
        timeFrame: DynamicChartTimeFrame;
        type: string;
    };
    context: {
        entityIds: string[];
        type: string;
    };
}

@Component({
    selector: 'app-entity-widget-graph-extended',
    templateUrl: './entity-widget-graph-extended.component.html',
    styleUrls: ['./entity-widget-graph-extended.component.scss'],
    standalone: true,
    imports: [NgIf, NgStyle, SpinnerComponent, DynamicChartNewComponent]
})
export class EntityWidgetGraphExtendedComponent implements OnInit, OnChanges, EntityWidgetContentComponent {

    @Input() public entityId: string;
    @Input() public width: number;

    @Input() public options: ExtendedWidgetGraphOptions;
    @Input() public data: ExtendedWidgetGraphData;
    @Input() public formValues: FormValues;

    @Input() public additionalData: EntityWidgetAdditionalDataChannel<ExtendedDynamicChartDataResponse>;
    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();

    public filterValue$: Subject<FormValues> = new BehaviorSubject(null);
    public filterRequestData: ExtendedWidgetGraphData;

    public get timeFrameOptions(): Array<DynamicChartTimeFrameOption> {
        return get(this.formValues, this.options.timeFrameValuePath);
    }

    constructor() { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.options || changes.data) {
            if (this.data) {
                this.filterRequestData = merge({}, this.options.initialRequest, this.data);
                this.loadChartData(this.filterRequestData);
            }
        }
    }

    public ngOnInit() { }

    public loadChartData(requestData: ExtendedWidgetGraphData): void {
        this.action.emit({
            type: WidgetDataActionTypes.DYNAMIC_CHART_LOAD_GRAPH_DATA,
            payload: {
                requestData
            }
        });
    }

    public onSelectedTimeFrame(timeFrame: DynamicChartTimeFrame): void {
        this.loadChartData({ 
            ...this.filterRequestData,
            category: {
                ...this.filterRequestData.category,
                timeFrame
            }
        });
    }
}
