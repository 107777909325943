/**
 * Created by Alex Klein <alex@rocketloop.de>
 */

import { Action } from '@ngrx/store';
import { GetModuleDataResponse, GetProductSelectionResponse } from '../../api/models/risk-manager.model';
import { AppError } from '../../errors/base.errors';
import {
    RiskManagerContext,
    RiskManagerModuleWatcher,
    RiskManagerWatchlistContext,
    WatchlistRiskManagerData
} from '../../models/risk-manager.model';
import { type } from '../util';

export const RiskManagerActionTypes = {

    LOAD_FACT_SHEET_CONTEXT: type('RISK_MANAGER:LOAD_FACT_SHEET_CONTEXT'),
    LOAD_FACT_SHEET_CONTEXT_SUCCEEDED: type('RISK_MANAGER:LOAD_FACT_SHEET_CONTEXT_SUCCEEDED'),
    LOAD_FACT_SHEET_CONTEXT_FAILED: type('RISK_MANAGER:LOAD_FACT_SHEET_CONTEXT_FAILED'),

    LOAD_LIST_MODULE_CONTEXT: type('RISK_MANAGER:LOAD_LIST_MODULE_CONTEXT'),
    LOAD_LIST_MODULE_CONTEXT_SUCCEEDED: type('RISK_MANAGER:LOAD_LIST_MODULE_CONTEXT_SUCCEEDED'),
    LOAD_LIST_MODULE_CONTEXT_FAILED: type('RISK_MANAGER:LOAD_LIST_MODULE_CONTEXT_FAILED'),

    LOAD_SINGLE_MODULE_CONTEXT: type('RISK_MANAGER:LOAD_SINGLE_MODULE_CONTEXT'),
    LOAD_SINGLE_MODULE_CONTEXT_SUCCEEDED: type('RISK_MANAGER:LOAD_SINGLE_MODULE_CONTEXT_SUCCEEDED'),
    LOAD_SINGLE_MODULE_CONTEXT_FAILED: type('RISK_MANAGER:LOAD_SINGLE_MODULE_CONTEXT_FAILED'),

    LOAD_MODULE_DATA: type('RISK_MANAGER:LOAD_MODULE_DATA'),
    LOAD_MODULE_DATA_SUCCEEDED: type('RISK_MANAGER:LOAD_MODULE_DATA_SUCCEEDED'),
    LOAD_MODULE_DATA_FAILED: type('RISK_MANAGER:LOAD_MODULE_DATA_FAILED'),

    LOAD_WATCHLIST_CONTEXT: type('RISK_MANAGER:LOAD_WATCHLIST_CONTEXT'),
    LOAD_WATCHLIST_CONTEXT_SUCCEEDED: type('RISK_MANAGER:LOAD_WATCHLIST_CONTEXT_SUCCEEDED'),
    LOAD_WATCHLIST_CONTEXT_FAILED: type('RISK_MANAGER:LOAD_WATCHLIST_CONTEXT_FAILED'),

    LOAD_WATCHLIST_MODULE_DATA: type('RISK_MANAGER:LOAD_WATCHLIST_MODULE_DATA'),
    LOAD_WATCHLIST_MODULE_DATA_SUCCEEDED: type('RISK_MANAGER:LOAD_WATCHLIST_MODULE_DATA_SUCCEEDED'),
    LOAD_WATCHLIST_MODULE_DATA_FAILED: type('RISK_MANAGER:LOAD_WATCHLIST_MODULE_DATA_FAILED'),

    SAVE_WATCHER: type('RISK_MANAGER:SAVE_WATCHER'),
    SAVE_WATCHER_SUCCEEDED: type('RISK_MANAGER:SAVE_WATCHER_SUCCEEDED'),
    SAVE_WATCHER_FAILED: type('RISK_MANAGER:SAVE_WATCHER_FAILED'),

    SAVE_WATCHERS: type('RISK_MANAGER:SAVE_WATCHERS'),
    SAVE_WATCHERS_SUCCEEDED: type('RISK_MANAGER:SAVE_WATCHERS_SUCCEEDED'),
    SAVE_WATCHERS_FAILED: type('RISK_MANAGER:SAVE_WATCHERS_FAILED'),

    UPDATE_WATCHER: type('RISK_MANAGER:UPDATE_WATCHER'),

    LOAD_PRODUCT_SELECTION: type('RISK_MANAGER:LOAD_PRODUCT_SELECTION'),
    LOAD_PRODUCT_SELECTION_SUCCEEDED: type('RISK_MANAGER:LOAD_PRODUCT_SELECTION_SUCCEEDED'),
    LOAD_PRODUCT_SELECTION_FAILED: type('RISK_MANAGER:LOAD_PRODUCT_SELECTION_FAILED'),

    CLEAR_MODULE_DATA: type('RISK_MANAGER:CLEAR_MODULE_DATA'),
};

/** LoadWatchlistContextAction **/

export interface LoadWatchlistContextAction extends Action {
    payload: {
        moduleId: string;
        watchlistId: string;
    }
}

export function loadWatchlistContext(moduleId: string, watchlistId: string): LoadWatchlistContextAction {
    return {
        type: RiskManagerActionTypes.LOAD_WATCHLIST_CONTEXT,
        payload: {
            moduleId,
            watchlistId,
        }
    };
}

/** LoadWatchlistContextSucceededAction **/

export interface LoadWatchlistContextSucceededAction extends Action {
    payload: {
        moduleId: string;
        watchlistId: string;
        context: RiskManagerWatchlistContext;
    }
}

export function loadWatchlistContextSucceeded(moduleId: string, watchlistId: string, context: RiskManagerWatchlistContext): LoadWatchlistContextSucceededAction {
    return {
        type: RiskManagerActionTypes.LOAD_WATCHLIST_CONTEXT_SUCCEEDED,
        payload: {
            moduleId,
            watchlistId,
            context,
        }
    };
}

/** LoadWatchlistContextFailedAction **/

export interface LoadWatchlistContextFailedAction extends Action {
    payload: {
        moduleId: string;
        watchlistId: string;
        error: AppError;
    }
}

export function loadWatchlistContextFailed(moduleId: string, watchlistId: string, error: AppError): LoadWatchlistContextFailedAction {
    return {
        type: RiskManagerActionTypes.LOAD_WATCHLIST_CONTEXT_FAILED,
        payload: {
            moduleId,
            watchlistId,
            error
        }
    };
}

/** LoadWatchlistModuleDataAction **/
export interface LoadWatchlistModuleDataAction extends Action {
    payload: {
        context: RiskManagerContext;
        moduleId: string;
    }
}

export function loadWatchlistModuleData(moduleId: string, context: RiskManagerContext): LoadWatchlistModuleDataAction {
    return {
        type: RiskManagerActionTypes.LOAD_WATCHLIST_MODULE_DATA,
        payload: {
            context,
            moduleId
        }
    }
}

/** LoadWatchlistModuleDataSucceededAction **/
export interface LoadWatchlistModuleDataSucceededAction extends Action {
    payload: {
        data: WatchlistRiskManagerData;
        context: RiskManagerContext;
    }
}

export function loadWatchlistModuleDataSucceeded(context: RiskManagerContext, data: WatchlistRiskManagerData): LoadWatchlistModuleDataSucceededAction {
    return {
        type: RiskManagerActionTypes.LOAD_WATCHLIST_MODULE_DATA_SUCCEEDED,
        payload: {
            context,
            data,
        }
    }
}

/** LoadWatchlistModuleDataFailedAction **/
export interface LoadWatchlistModuleDataFailedAction extends Action {
    payload: {
        context: RiskManagerContext;
        error: AppError;
    }
}

export function loadWatchlistModuleDataFailed(context: RiskManagerContext, error: AppError): LoadWatchlistModuleDataFailedAction {
    return {
        type: RiskManagerActionTypes.LOAD_WATCHLIST_MODULE_DATA_FAILED,
        payload: {
            context,
            error,
        }
    };
}

/** LoadFactSheetContextAction **/

export interface LoadFactSheetContextAction extends Action {
    payload: {
        moduleId: string;
        productDq: string;
    };
}

export function loadFactSheetContext(moduleId: string, productDq: string): LoadFactSheetContextAction {
    return {
        type: RiskManagerActionTypes.LOAD_FACT_SHEET_CONTEXT,
        payload: {
            moduleId,
            productDq,
        },
    };
}

/** LoadFactSheetContextSucceededAction **/

export interface LoadFactSheetContextSucceededAction extends Action {
    payload: {
        moduleId: string;
        context: any
    };
}

export function loadFactSheetContextSucceeded(moduleId: string, context: any): LoadFactSheetContextSucceededAction {
    return {
        type: RiskManagerActionTypes.LOAD_FACT_SHEET_CONTEXT_SUCCEEDED,
        payload: {
            moduleId,
            context,
        },
    };
}

/** LoadFactSheetContextFailedAction **/

export interface LoadFactSheetContextFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadFactSheetContextFailed(error: AppError): LoadFactSheetContextFailedAction {
    return {
        type: RiskManagerActionTypes.LOAD_FACT_SHEET_CONTEXT_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadSingleModuleContextAction **/

export interface LoadSingleModuleContextAction extends Action {
    payload: {
        moduleId: string;
        productDq: string;
    };
}

export function loadSingleModuleContext(moduleId: string, productDq: string): LoadSingleModuleContextAction {
    return {
        type: RiskManagerActionTypes.LOAD_SINGLE_MODULE_CONTEXT,
        payload: {
            moduleId,
            productDq,
        },
    };
}

/** LoadSingleModuleContextSucceededAction **/

export interface LoadSingleModuleContextSucceededAction extends Action {
    payload: {
        moduleId: string;
        context: any
    };
}

export function loadSingleModuleContextSucceeded(moduleId: string, context: any): LoadSingleModuleContextSucceededAction {
    return {
        type: RiskManagerActionTypes.LOAD_SINGLE_MODULE_CONTEXT_SUCCEEDED,
        payload: {
            moduleId,
            context,
        },
    };
}

/** LoadSingleModuleContextFailedAction **/

export interface LoadSingleModuleContextFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadSingleModuleContextFailed(error: AppError): LoadSingleModuleContextFailedAction {
    return {
        type: RiskManagerActionTypes.LOAD_SINGLE_MODULE_CONTEXT_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadListModuleContextAction **/

export interface LoadListModuleContextAction extends Action {
    payload: {
        moduleId: string;
    };
}

export function loadListModuleContext(moduleId: string): LoadListModuleContextAction {
    return {
        type: RiskManagerActionTypes.LOAD_LIST_MODULE_CONTEXT,
        payload: {
            moduleId,
        },
    };
}

/** LoadListModuleContextSucceededAction **/

export interface LoadListModuleContextSucceededAction extends Action {
    payload: {
        moduleId: string;
        context: any
    };
}

export function loadListModuleContextSucceeded(moduleId: string, context: any): LoadListModuleContextSucceededAction {
    return {
        type: RiskManagerActionTypes.LOAD_LIST_MODULE_CONTEXT_SUCCEEDED,
        payload: {
            moduleId,
            context,
        },
    };
}

/** LoadListModuleContextFailedAction **/

export interface LoadListModuleContextFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadListModuleContextFailed(error: AppError): LoadListModuleContextFailedAction {
    return {
        type: RiskManagerActionTypes.LOAD_LIST_MODULE_CONTEXT_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadModuleDataAction **/

export interface LoadModuleDataAction extends Action {
    payload: {
        moduleId: string;
        context: any
    };
}

export function loadModuleData(moduleId: string, context: any): LoadModuleDataAction {
    return {
        type: RiskManagerActionTypes.LOAD_MODULE_DATA,
        payload: {
            moduleId,
            context,
        },
    };
}

/** LoadModuleDataSucceededAction **/

export interface LoadModuleDataSucceededAction extends Action {
    payload: {
        moduleId: string;
        data: GetModuleDataResponse;
    };
}

export function loadModuleDataSucceeded(moduleId: string, data: GetModuleDataResponse): LoadModuleDataSucceededAction {
    return {
        type: RiskManagerActionTypes.LOAD_MODULE_DATA_SUCCEEDED,
        payload: {
            moduleId,
            data,
        },
    };
}

/** LoadModuleDataFailedAction **/

export interface LoadModuleDataFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadModuleDataFailed(error: AppError): LoadModuleDataFailedAction {
    return {
        type: RiskManagerActionTypes.LOAD_MODULE_DATA_FAILED,
        payload: {
            error,
        },
    };
}

/** SaveWatcherAction **/

export interface SaveWatcherAction extends Action {
    payload: {
        moduleId: string,
        context: any,
        watcher: any,
    };
}

export function saveWatcher(moduleId: string, context: any, watcher: any): SaveWatcherAction {
    return {
        type: RiskManagerActionTypes.SAVE_WATCHER,
        payload: {
            moduleId,
            context,
            watcher,
        },
    };
}

/** SaveWatcherSucceededAction **/

export interface SaveWatcherSucceededAction extends Action {
    payload: {
        moduleId: string,
        watcher: RiskManagerModuleWatcher,
    };
}

export function saveWatcherSucceeded(moduleId: string, watcher: RiskManagerModuleWatcher): SaveWatcherSucceededAction {
    return {
        type: RiskManagerActionTypes.SAVE_WATCHER_SUCCEEDED,
        payload: {
            moduleId,
            watcher,
        },
    };
}

/** SaveWatcherFailedAction **/

export interface SaveWatcherFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function saveWatcherFailed(error: AppError): SaveWatcherFailedAction {
    return {
        type: RiskManagerActionTypes.SAVE_WATCHER_FAILED,
        payload: {
            error,
        },
    };
}

/** SaveWatchersAction **/
export interface SaveWatchersAction extends Action {
    payload: {
        moduleId: string;
        data: Record<'context' | 'watcher', any>[],
    }
}

export function saveWatchers(moduleId: string, data: Record<'context' | 'watcher', any>[]): SaveWatchersAction {
    return {
        type: RiskManagerActionTypes.SAVE_WATCHERS,
        payload: {
            moduleId,
            data,
        }
    };
}

/** SaveWatchersSucceededAction **/
export interface SaveWatchersSucceededAction extends Action {
    payload: {
        moduleId: string;
        watchers: RiskManagerModuleWatcher[],
    }
}

export function saveWatchersSucceeded(moduleId: string, watchers: RiskManagerModuleWatcher[]): SaveWatchersSucceededAction {
    return {
        type: RiskManagerActionTypes.SAVE_WATCHERS_SUCCEEDED,
        payload: {
            moduleId,
            watchers,
        }
    };
}

/** SaveWatchersFailedAction **/
export interface SaveWatchersFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function saveWatchersFailed(error: AppError): SaveWatchersFailedAction {
    return {
        type: RiskManagerActionTypes.SAVE_WATCHERS_FAILED,
        payload: {
            error,
        },
    };
}


/** UpdateWatcherAction **/

export interface UpdateWatcherAction extends Action {
    payload: {
        moduleId: string;
        watcherData: any
    };
}

export function updateWatcher(moduleId: string, watcherData: any): UpdateWatcherAction {
    return {
        type: RiskManagerActionTypes.UPDATE_WATCHER,
        payload: {
            moduleId,
            watcherData,
        },
    };
}

/** LoadProductSelectionAction **/

export interface LoadProductSelectionAction extends Action {
    payload: {
        moduleId: string,
    };
}

export function loadProductSelection(moduleId: string): LoadProductSelectionAction {
    return {
        type: RiskManagerActionTypes.LOAD_PRODUCT_SELECTION,
        payload: {
            moduleId,
        },
    };
}

/** LoadProductSelectionSucceededAction **/

export interface LoadProductSelectionSucceededAction extends Action {
    payload: {
        moduleId: string;
        data: GetProductSelectionResponse
    };
}

export function loadProductSelectionSucceeded(moduleId: string, data: GetProductSelectionResponse): LoadProductSelectionSucceededAction {
    return {
        type: RiskManagerActionTypes.LOAD_PRODUCT_SELECTION_SUCCEEDED,
        payload: {
            moduleId,
           data,
        },
    };
}

/** LoadProductSelectionFailedAction **/

export interface LoadProductSelectionFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadProductSelectionFailed(error: AppError): LoadProductSelectionFailedAction {
    return {
        type: RiskManagerActionTypes.LOAD_PRODUCT_SELECTION_FAILED,
        payload: {
            error,
        },
    };
}

/** ClearModuleDataAction **/

export interface ClearModuleDataAction extends Action {
    payload: {
        moduleId: string;
    };
}

export function clearModuleData(moduleId: string): ClearModuleDataAction {
    return {
        type: RiskManagerActionTypes.CLEAR_MODULE_DATA,
        payload: {
            moduleId,
        },
    };
}
