import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DerifinApi } from '../../../../core/api/derifin.api';
import { AuthStoreService } from '../../../../core/services/auth-store.service';
import { ConfigurationService } from '../../../../core/services/configuration.service';
import { LogglyLoggerService } from '../../../../core/services/loggly-logger.service';
import { Entity, EntityTableConfig } from '../../../../shared/models/entities.model';
import { filterStructure } from './data/filter-structure';

import { generateDividendsTableConfig, generateVolatilityMatrixTableConfig, generateVolatilityTTMTableConfig, generateVolatiltyMoneynessTableConfig } from './data/tableConfig';
import { arraysToObjects, transformDiviDataToEntities, transformVolaDataForMatrix, transformVolaDataToEntities } from './market-analysis.transform';

import { DataRequestResponse, MarketAnalysisProductsData, VolatilityDataRequestResponse } from './model/market-analysis.model';

/**
 * API abstraction layer for the market analysis API
 */
@Injectable()
export class MarketAnalysisApi extends DerifinApi {
    constructor(
        http: HttpClient,
        authStoreService: AuthStoreService,
        loggerService: LogglyLoggerService,
        appConfigService: ConfigurationService,
        private translocoService: TranslocoService
    ) {
        super('m/marketdata', appConfigService.configuration.environment.id, http, authStoreService, loggerService, appConfigService);
    }

    /**
     * Get market analysis structure
     */
    public getMarketAnalysisStructure(): Observable<DataRequestResponse> {
        return new Observable<DataRequestResponse>((observable) => {
            const data: DataRequestResponse = {
                filter: filterStructure,
            };

            observable.next(data);
            observable.complete();
        });
    }

    public getMarketAnalysisData(configId: string, filter, limit: number, offset: number): Observable<Entity[]> {
        const endpoint = filter.view === 'dividends' ? 'dividends' : 'volatility';
        const transformer = filter.view === 'dividends' ? transformDiviDataToEntities : transformVolaDataToEntities;

        return this.get(`${configId}/${endpoint}/${filter.index}`).pipe(
            map((res) => transformer(res.data, filter)),
        );
    }

    public getMarketAnalysisTableStructure(filter: any, data: Entity[]): Observable<EntityTableConfig> {
        return new Observable<EntityTableConfig>((observable) => {
            let structure = null;

            const view = filter.view || 'dividends';
            const volaType = filter.volaType || 'ttm';

            try {
                if (view === 'dividends') {
                    structure = generateDividendsTableConfig(filter, data, this.translocoService);
                } else {
                    if (volaType === 'ttm') {
                        structure = generateVolatilityTTMTableConfig(filter, data, this.translocoService);
                    } else {
                        structure = generateVolatiltyMoneynessTableConfig(filter, data, this.translocoService);
                    }
                }
            } catch (e) {
                console.error(e);
            }

            observable.next(structure);
            observable.complete();
        });
    }

    public getMarketAnalysisVolatilityMatrixTableStructure(): Observable<EntityTableConfig> {
        return new Observable<EntityTableConfig>((observable) => {
            observable.next(generateVolatilityMatrixTableConfig(this.translocoService));
            observable.complete();
        });
    }

    public getMarketAnalysisVolatilityMatrixTableValues(configId: string = 'DEFAULT', isin: string, tableData: Entity[]): Observable<VolatilityDataRequestResponse> {
        const found = (tableData && tableData.find((entity) => entity.isin === isin)) || null;

        if (found) {
            return new Observable<VolatilityDataRequestResponse>((observable) => {
                const data: VolatilityDataRequestResponse = transformVolaDataForMatrix(found);
                observable.next(data);
                observable.complete();
            });
        } else {
            return this.get(`${configId}/volatility-by-isin/${isin}`).pipe(
                map((res) => res.data),
                map((data) => transformVolaDataForMatrix(data)),
            );
        }
    }

    public getMarketAnalysisProductsFilterStructureAndValues(configId: string): Observable<DataRequestResponse> {
        return this.get(`${configId}/products/filter`).pipe(
            map((res) => res.data),
            map((data) => ({
                filter: data,
            })),
        );
    }

    public getMarketAnalysisProductsData(configId: string, filter, limit: number, offset: number): Observable<MarketAnalysisProductsData> {
        return this.post(`${configId}/products/analysis`, filter).pipe(
            map((res) => res.data),
            map((data) => ({
                products: arraysToObjects(data.columns.products, data.data.products),
                underlyings: arraysToObjects(data.columns.underlyings, data.data.underlyings),
                issuers: arraysToObjects(data.columns.issuers, data.data.issuers),
            })),
        );
    }
}
