import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input, OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { EntityWidgetAdditionalDataChannel, EntityWidgetOptions } from '../../../models/widgets.model';
import { Entity, EntityTableConfig } from '../../../models/entities.model';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { WidgetDataActionTypes } from '../../../../core/store/actions/widgets-data.action';
import { get } from 'lodash';
import { EntityTableComponent } from '../../../components/entity-table/entity-table.component';
import { NgFor, AsyncPipe } from '@angular/common';


export interface WorkingDayOption {
    label: string;
    value: number;
}

export interface TopUnderlyingsFilter {
    workingDays: number;
    provisioned: 'ALL' | 'PROVISIONED' | 'UNPROVISIONED';
    limit: number;
}

export interface TopUnderlyingItem extends Entity {
    label: string;
    relativeBenchmark: number;
}

export type TopUnderlyingsDataResponse = TopUnderlyingItem[];

@Component({
    selector: 'app-entity-widget-top-underlyings',
    templateUrl: './entity-widget-top-underlyings.component.html',
    styleUrls: ['./entity-widget-top-underlyings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatButtonToggleModule, NgFor, EntityTableComponent, AsyncPipe, TranslocoModule]
})
export class EntityWidgetTopUnderlyingsComponent implements OnInit, OnDestroy, OnChanges, EntityWidgetContentComponent {
    @Input() entityId: string;
    @Input() width: number;
    @Input() options: EntityWidgetOptions;
    @Input() data: TopUnderlyingItem[];
    @Input() additionalData: EntityWidgetAdditionalDataChannel<TopUnderlyingItem[]>;

    @Output() action = new EventEmitter<{ type: string; payload: any }>();

    subscription = new Subscription();

    tableConfig$: Observable<EntityTableConfig>;
    data$: BehaviorSubject<TopUnderlyingItem[]>;
    loading$: BehaviorSubject<boolean>;
    workingDayOptions$: Observable<WorkingDayOption[]>;
    filter$: BehaviorSubject<TopUnderlyingsFilter>;

    constructor(private translocoService: TranslocoService) {
        this.tableConfig$ = combineLatest([
            this.translocoService.selectTranslate('widgets.topUnderlyings.label'),
            this.translocoService.selectTranslate('widgets.topUnderlyings.relativeBenchmark')
        ]).pipe(
            map(([label, relativeBenchmark]) => {
                const config: EntityTableConfig = {
                    columns: [
                        {
                            label: label,
                            path: 'label',
                            weight: 1
                        },
                        {
                            label: relativeBenchmark,
                            path: 'relativeBenchmark',
                            decorator: 'percent'
                        },
                    ]
                }

                return config;
            })
        );

        this.workingDayOptions$ = combineLatest([
            this.translocoService.selectTranslate('widgets.topUnderlyings.today'),
            this.translocoService.selectTranslate('widgets.topUnderlyings.todayAndYesterday')
        ]).pipe(
            map(([today, todayAndYesterday]) => {
                return [
                    {
                        label: today,
                        value: 1
                    },
                    {
                        label: todayAndYesterday,
                        value: 2
                    },
                    {
                        label: '5',
                        value: 5
                    },
                    {
                        label: '20',
                        value: 20
                    }
                ];
            })
        );


        this.loading$ = new BehaviorSubject<boolean>(false);
        this.data$ = new BehaviorSubject<TopUnderlyingItem[]>([]);
        this.filter$ = new BehaviorSubject<TopUnderlyingsFilter>({workingDays: 1, provisioned: 'ALL', limit: 10});
    }

    ngOnInit() {
        this.subscription.add(
            this.filter$.subscribe(filter => {
                this.loadData(filter);
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        const data = get(changes, 'additionalData.currentValue.data') as unknown as TopUnderlyingItem[];
        const loading = get(changes, 'additionalData.currentValue.loading') as unknown as boolean;


        if(data !== undefined) {
            this.data$.next(
                data.map((item) => {
                    return {
                        ...item,
                        relativeBenchmark: item.relativeBenchmark * 100
                    };
                })
            );
        }

        if(loading !== undefined) {
            this.loading$.next(loading);
        }
    }

    setProvisionedFilter(event: MatButtonToggleChange) {
        this.filter$.next({
            ...this.filter$.value,
            provisioned: event.value
        });
    }

    setWorkingDaysFilter(event: MatButtonToggleChange) {
        this.filter$.next({
            ...this.filter$.value,
            workingDays: event.value
        });
    }

    loadData(filter: TopUnderlyingsFilter) {
        this.action.emit({
            type: WidgetDataActionTypes.TOP_UNDERLYINGS_LOAD_DATA,
            payload: {
                filter
            }
        });
    }
}
