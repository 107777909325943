import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'nl2br',
    standalone: true
})
export class Nl2BrPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {
    }

    transform(value: string | string[], sanitizeBeforehand?: boolean): string {
        let input = '';

        if (Array.isArray(value)) {
            input = value.join('');
        } else {
            input = value;
        }

        if (typeof input !== 'string') {
            return input;
        }

        let result: any;
        const textParsed = input.replace(/(?:\r\n|\r|\n)/g, '<br />');

        if (sanitizeBeforehand) {
            result = this.sanitizer.sanitize(SecurityContext.HTML, textParsed);
        } else {
            result = textParsed;
        }

        return result;
    }

}
