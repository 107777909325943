import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    addToOverlayNavigationStack,
    clearOverlayNavigationStack,
    closeMenu,
    openMenu,
    popOverlayNavigationStack,
    setOverlayClosed,
    setOverlayOpen,
} from '../store/actions/ui.action';
import { AppState } from '../store/state/index.state';

/**
 * Service responsible for UI state and interactions
 */
@Injectable()
export class UiService {
    constructor(private readonly store: Store<AppState>) {
    }

    /** Actions **/

    /**
     * Dispatch the openMenu action to the store
     */
    public openMenu() {
        this.store.dispatch(openMenu());
    }

    /**
     * Dispatch the closeMenu action to the store
     */
    public closeMenu() {
        this.store.dispatch(closeMenu());
    }

    /**
     * Set the overlay state to open
     */
    public setOverlayOpen() {
        this.store.dispatch(setOverlayOpen());
    }

    /**
     * Set the overlay state to closed
     */
    public setOverlayClosed() {
        this.store.dispatch(setOverlayClosed());
    }

    /**
     * Add the given url to the overlay navigation stack
     * @param {string} url
     */
    public addUrlToOverlayNavigationStack(url: string) {
        this.store.dispatch(addToOverlayNavigationStack(url));
    }

    /**
     * Pop the last url from the overlay navigation stack
     */
    public popOverlayNavigationStack() {
        this.store.dispatch(popOverlayNavigationStack());
    }

    /**
     * Clear the overlay navigation stack
     */
    public clearOverlayNavigationStack() {
        this.store.dispatch(clearOverlayNavigationStack());
    }

    /** Selectors **/

    /**
     * Get the current menu visibility
     * @returns {Observable<boolean>}
     */
    public isMenuVisible(): Observable<boolean> {
        return this.store.pipe(
            select((state: AppState) => state.ui.menuVisible),
        );
    }

    /**
     * Get the current overlay state
     * @returns {Observable<boolean>}
     */
    public isOverlayOpen(): Observable<boolean> {
        return this.store.pipe(
            select((state: AppState) => state.ui.overlayOpen),
        );
    }

    /**
     * Get the current overlay navigation stack
     * @returns {Observable<string[]>}
     */
    public getOverlayNavigationStack(): Observable<string[]> {
        return this.store.pipe(
            select((state: AppState) => state.ui.overlayNavigationStack),
        );
    }

    /**
     * Get previous overlay navigation stack url
     * @returns {Observable<string>}
     */
    public getPreviousOverlayNavigationStackUrl(): Observable<string | null> {
        return this.getOverlayNavigationStack().pipe(
            map((stack) => {
                if (stack.length > 1) {
                    return stack[stack.length - 2];
                } else {
                    return null;
                }
            }),
        );
    }
}
