import { Component, Input } from '@angular/core';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Icons, Icon, CustomViewBox } from '@dericon/wms-icons';
import { camelCase } from 'lodash';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    standalone: true,
    imports: [MatIconModule, NgClass]
})
export class IconComponent {
    public _namespace: string;
    @Input()
    public size = -1;

    @Input()
    public padding = -1;

    @Input()
    public inline = false;

    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
    ) {
    }

    public _icon: string;

    @Input()
    set icon(value: Icon) {
        const valueSplitted = value.split(':');

        this._namespace = valueSplitted[0];
        this._icon = valueSplitted[1];

        const icon = camelCase(`${this._namespace}-${this._icon}`);
        this.addToRegistry(icon);
    }

    public addToRegistry(icon: string): void {
        this.iconRegistry.addSvgIconLiteralInNamespace(
            this._namespace,
            this._icon,
            this.sanitizer.bypassSecurityTrustHtml(`
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${CustomViewBox[icon] ? CustomViewBox[icon] : '24 24'}" width="100%" height="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                    <path d="${Icons[icon]}"/>
                </svg>
            `),
        );
    }
}
