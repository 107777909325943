import {Type} from '@angular/core';

import {FormControlGroupConfig, FormControlSelectEquivalence, FormStructureConfig} from '../../core/models/form.model';
import {AdditionalWidgetData} from '../../core/store/state/widgets-data.state';
import {EntityWidgetContentComponent} from '../widgets/legacy/entity-widget/entity-widget.component';
import {EntityTableColumn} from './entities.model';

export interface EntityWidgetConfig {
    path?: string;
    width: number;
    refreshable?: boolean;
    postLoading?: boolean;
    type: string;
    options: EntityWidgetOptions;
}

export interface EntityWidgetOptions {
    title: {
        path?: string;
        default?: string;
    } | string;
    styleHints?: string[];
    pov?: string[];
    defaultVisibility?: boolean;
    visibilityIndicatorPath?: string;
    infoText?: string;
    path?: string;
    conditionPath?: string;
    conditionValues?: any;
    id?: string;
    checkWidgetMode: CheckWidgetMode;

    [key: string]: any;
}

export interface EntityWidgetEquivalenceCheck extends EntityWidgetOptions {
    dropdownValues: FormControlSelectEquivalence[];
}

export interface EntityWidgetAlertOptions extends EntityWidgetOptions {
    informationTextPath: string;
}

export interface EntityWidgetBasketsAdminOptions extends EntityWidgetOptions {
    displayMode: 'STANDALONE' | 'INTEGRATED';
    displayPaOnly: Array<string>;
    path: string;
    values: string;
}

export interface EntityWidgetGraphOptions extends EntityWidgetOptions {
    title: {
        path?: string;
        default?: string;
    } | string;
    labelX: string;
    labelY: string;
}

export interface EntityWidgetSheetOptions extends EntityWidgetOptions {
    title: {
        path?: string;
        default?: string;
    } | string;
    fields: EntityTableColumn[];
    columns?: number;
}

export interface EntityWidgetDecorfinDataOptions extends EntityWidgetOptions {
    filterControls: FormControlGroupConfig[];
    brokerDecorfinId: string;
    mode: string;
}

export interface EntityWidgetFormOptions extends EntityWidgetOptions {
    segment: FormStructureConfig;
    values?: string;
}

export interface EntityWidgetMischproduktAnteileOptions extends EntityWidgetOptions {
    values: string;
    multiAssetFractionsFromKVG: string;
}

export interface MarkdownWidgetOptions extends EntityWidgetOptions {
    title: TitleWithPath | string;
    text: string;
}

export interface TitleWithPath {
    path?: string;
    default?: string;
}

export interface ProductActivationWidgetOptions extends EntityWidgetOptions {
    bulletsPath: string;
}

export interface ImageMarkdownOptions extends EntityWidgetOptions {
    title: {
        path?: string;
        default?: string;
    } | string;
    styleHints: string[];
    text: string;
    imagePath?: string;
    imageReference?: string;
    imagePosition: string;
    imageHeight?: number;
}

export interface ImageOptions extends EntityWidgetOptions {
    styleHints: string[];
    image: string;
}

export interface ProductDocumentUploadOptions extends EntityWidgetOptions {
    fileType: string;
}

export interface ProductDocumentUploadFile {
    publishDate: string;
    link: string;
}


export interface MarketDataOptions extends EntityWidgetOptions {
    enableGraph: boolean;
    numberOfColumns: number;
}

export interface EntityWidgetAdditionalDataChannel<T = any, M = any> extends AdditionalWidgetData<T> {
    meta?: M;
}

export type EntityWidgetType = Type<EntityWidgetContentComponent>;

export class SelectItem {
    public id: string;
    public text: string;
}

export enum CheckWidgetMode {
    TMC_ONLY = 'TMC_ONLY',
    TMC_SUSTAINABILITY = 'TMC_SUSTAINABILITY',
    TMC_SUSTAINABILITY_SUITABILITY = 'TMC_SUSTAINABILITY_SUITABILITY'
}
