/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 4/25/17.
 */
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

import { isPlainObject } from 'lodash';
import { AuthErrors } from '../errors/auth.errors';
import { BaseErrors, HttpError } from '../errors/base.errors';
import { LogglyLoggerService } from '../services/loggly-logger.service';

export class BaseApi {
    constructor(
        protected baseUrl: string,
        protected http: HttpClient,
        protected loggerService: LogglyLoggerService,
    ) {
    }

    public get(endpoint: string, apiOptions: ApiOptions = {}): Observable<any> {
        const options = {
            ...apiOptions,
            headers: this.getHeaders(apiOptions),
        };
        if (endpoint.length > 0) {
            return this.http.get(`${this.baseUrl}/${endpoint}`, options).pipe(catchError(this.createErrorHandler(null, options)));
        } else {
            return this.http.get(`${this.baseUrl}`, options).pipe(catchError(this.createErrorHandler(null, options)));
        }
    }

    public post(endpoint: string, body: any, apiOptions: ApiOptions = {}): Observable<any> {
        const options = {
            ...apiOptions,
            headers: this.getHeaders(apiOptions),
        };
        return this.http.post(`${this.baseUrl}/${endpoint}`, body, options).pipe(catchError(this.createErrorHandler(body, options)));
    }

    public put(endpoint: string, body: any, apiOptions: ApiOptions = {}): Observable<any> {
        const options = {
            ...apiOptions,
            headers: this.getHeaders(apiOptions),
        };

        return this.http.put(`${this.baseUrl}/${endpoint}`, body, options).pipe(catchError(this.createErrorHandler(body, options)));
    }

    public patch(endpoint: string, body: any, apiOptions: ApiOptions = {}): Observable<any> {
        const options = {
            ...apiOptions,
            headers: this.getHeaders(apiOptions),
        };

        return this.http.patch(`${this.baseUrl}/${endpoint}`, body, options).pipe(catchError(this.createErrorHandler(body, options)));
    }

    public delete(endpoint: string, apiOptions: ApiOptions = {}): Observable<any> {
        const options = {
            ...apiOptions,
            headers: this.getHeaders(apiOptions),
        };

        return this.http.delete(`${this.baseUrl}/${endpoint}`, options).pipe(catchError(this.createErrorHandler(null, options)));
    }

    private getHeaders(apiOptions: ApiOptions): { [header: string]: string | string[] } {
        const headers = apiOptions.headers || {};
        if (apiOptions.body) {
            headers['Content-Type'] = 'application/json';
        }
        if (apiOptions.basicAuth) {
            headers.Authorization = `Basic ${window.btoa(
                String.fromCharCode(
                    ...new TextEncoder()
                        .encode(apiOptions.basicAuth.username + ':' + apiOptions.basicAuth.password)
                )
            )}`;
        }
        return headers;
    }

    private parseJwt(token: string) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    /**
     * This method produces an error handler.
     * @param body Request body
     * @param options Request options
     */
    private createErrorHandler(body?: any, options: ApiOptions = {}): (errorResponse: HttpErrorResponse) => Observable<never> {
        return (errorResponse: HttpErrorResponse) => {

            const detailedError: HttpError = {
                name: errorResponse.name,
                type: 'http',
                severity: errorResponse.status === 500 ? 'FATAL' : 'WARN',
                message: errorResponse.message,
                url: errorResponse.url,
                statusCode: errorResponse.status,
                request: {
                    headers: this.cleanRequestHeadersForLogging(options.headers),
                    body: this.cleanRequestBodyForLogging(body),
                },
                response: {
                    headers: errorResponse
                        .headers
                        .keys()
                        .map((headerKey) => [headerKey, errorResponse.headers.getAll(headerKey)])
                        .reduce((result, [key, value]: [string, string]) => ({
                            ...result,
                            [key]: value,
                        }), {}),
                    body: errorResponse.error,
                },
                stack: (new Error()).stack,
            };

            if (environment.production) {
                this.loggerService.log(detailedError);
            }

            if (errorResponse.status === 403) {
                return throwError({
                    errorCode: AuthErrors.FORBIDDEN,
                    httpError: true,
                    statusCode: 403,
                });
            } else if (errorResponse.status === 401) {
                return throwError({
                    errorCode: AuthErrors.TOKEN_EXPIRED,
                    httpError: true,
                    statusCode: 401,
                });
            } else {
                return throwError({
                    errorCode: BaseErrors.UNKNOWN,
                    httpError: true,
                    statusCode: errorResponse.status,
                    originalError: errorResponse,
                });
            }
        };
    }

    private cleanRequestBodyForLogging(body?: any) {
        if (isPlainObject(body)) {
            Object.keys(body).forEach((k) => {
                if (k.toLowerCase().includes('password')) {
                    body[k] = '{hidden}';
                }
            });
        }

        return body;
    }

    /**
     * Clean request headers for logging by removing sensitive information.
     * @param headers Headers object
     */
    private cleanRequestHeadersForLogging(headers?: { [header: string]: string | string[]; }) {
        if (!headers) {
            return {};
        }

        if (headers.Authorization) {
            const newHeaders = {
                ...headers,
                Authorization: '{hidden}',
            };

            // extract user id from token
            try {
                const authHeader = '' + (headers.Authorization as string);
                const authParts = authHeader.split(' ');

                if (authParts.length === 2 && authParts[0] === 'Bearer') {
                    const decodedJwt = this.parseJwt(authParts[1]);
                    newHeaders['X-USER-ID'] = decodedJwt.userId;
                }

            } catch (e) {
            }

            return newHeaders;
        }

        return headers;
    }
}

export interface ApiOptions {
    token?: string;
    authenticated?: boolean;
    basicAuth?: {
        username: string;
        password: string;
    };
    body?: any;
    headers?: {
        [header: string]: string | string[];
    };
    params?:
        | HttpParams
        | {
        [param: string]: string | string[];
    };
    observe?: any;
    reportProgress?: boolean;
    responseType?: any;
    withCredentials?: boolean;
}
