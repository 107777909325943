import { Injectable } from '@angular/core';
import { get, isString, mapValues } from 'lodash';
import { Observable } from 'rxjs';
import { ImageMaps, isSimpleReferenceAddress, parseReference } from '../helpers/image-referece-resolver.helper';
import {
    ImageReference,
    ImageReferenceAddress,
    ImageReferenceSet, ReferenceAddress,
    SimpleReferenceAddress,
    UnifiedImageReference,
} from '../models/image-reference.model';

@Injectable()
export class ImageReferenceResolverService {
    public getImageReferenceFromAddress(path: string | ImageReferenceAddress): UnifiedImageReference {
        const address = isString(path) ? parseReference(path) : path;

        if (isSimpleReferenceAddress(address)) {
            return {
                type: 'SIMPLE',
                simple: {
                    path: this.resolveImageAddress(address),
                    ref: address,
                },
            };
        } else {
            const set = mapValues(address.paths, (p) => {
                return {
                    path: this.resolveImageAddress(p),
                    ref: { ...p, type: 'SIMPLE' },
                } as ImageReference;
            }) as { [key: string]: ImageReference };

            return {
                type: 'SET',
                set: new ImageReferenceSet(set),
            };
        }
    }

    public resolveImageAddress(address: ReferenceAddress): string {
        if (address.location === 'LOCAL') {
            return this.resolveLocalImagePath(address.path);
        }

        return this.resolveRemoteImagePath(address.path);
    }

    public resolveLocalImagePath(ref: string): string {
        const value = get(ImageMaps, ref);

        if (!value) {
            return ['/assets', 'img', ref].join('/');
        }

        const path = ref.split('.');
        path.pop();

        return ['/assets', 'img', ...path, value].join('/');
    }

    public resolveRemoteImagePath(ref: string): string {
        return ['/derifiles', ref].join('/');
    }

    public getImageUrlForReferenceSync(ref: string): string {
        const path = ref.split('.');
        const img = path.pop();

        const value = get(ImageMaps, ref);

        return ['/assets', 'img', ...path, value].join('/');
    }
}
