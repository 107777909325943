<ng-container *ngIf="additionalData">
    <div
        *ngIf="additionalData.loading"
        [ngStyle]="{ display: 'flex', flex: 1, 'align-items': 'center', 'justify-content': 'center', 'position': 'absolute', width: '100%' }">
        <app-spinner type="small-boxes"></app-spinner>
    </div>
    <ng-container *ngIf="additionalData.data?.chart">
        <app-dynamic-chart-new
            [useParentWidth]="true"
            [options]="additionalData?.data?.options"
            [data]="additionalData?.data?.chart"
            [initialTimeFrame]="data?.category?.timeFrame"
            [timeFrameOptions]="timeFrameOptions"
            (selectTimeFrame)="onSelectedTimeFrame($event)">
        </app-dynamic-chart-new>
    </ng-container>
</ng-container>
