import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthStoreService } from '../services/auth-store.service';
import { ConfigurationService } from '../services/configuration.service';
import { LogglyLoggerService } from '../services/loggly-logger.service';
import { AuthenticatedApi } from './authenticated.api';
import { UserProfile } from './models/user.model';

@Injectable({
    providedIn: 'root',
})
export class UserProfileApi extends AuthenticatedApi {
    constructor(http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, appConfigService: ConfigurationService) {
        super(appConfigService.configuration.api.duse.baseUrl, http, authStoreService, loggerService);
    }

    public getProfile(): Observable<UserProfile> {
        return this.get('profile/me');
    }
}
