/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 07/05/2017.
 */

import { Action } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { type } from '../../../../core/store/util';
import { Entity, UpdatedData } from '../../../../shared/models/entities.model';

export const ProductActionTypes = {
    LOAD_PRODUCTS_FOR_QUERY_SET: type('PRODUCTS:LOAD_PRODUCTS_FOR_QUERY_SET'),
    PRODUCTS_FOR_QUERY_SET_LOADED: type('PRODUCTS:PRODUCTS_FOR_QUERY_SET_LOADED'),
    LOAD_PRODUCTS_FOR_QUERY_SET_FAILED: type('PRODUCTS:LOAD_PRODUCTS_FOR_QUERY_SET_FAILED'),

    LOAD_UPDATED_PRODUCTS_DATA: type('PRODUCTS:LOAD_UPDATED_PRODUCTS_DATA'),
    UPDATED_PRODUCTS_DATA_LOADED: type('PRODUCTS:UPDATED_PRODUCTS_DATA_LOADED'),
    LOAD_UPDATED_PRODUCTS_DATA_FAILED: type('PRODUCTS:LOAD_UPDATED_PRODUCTS_DATA_FAILED'),

    RESTART_UPDATE_PRODUCTS_DATA: type('PRODUCTS:RESTART_UPDATE_PRODUCTS_DATA'),

    CLEAR_PRODUCTS: type('PRODUCTS:CLEAR_PRODUCTS'),
};

/** LoadProductsForQuerySetAction **/

export interface LoadProductsForQuerySetAction extends Action {
    payload: {
        productTypeId: string;
        querySetId: string;
        encodedCriteria: string;
        passCriteria: boolean;
        limit: number;
        offset: number;
    };
}

export function loadProductsForQuerySet(
    productTypeId: string,
    querySetId: string,
    encodedCriteria: string,
    passCriteria: boolean,
    limit: number,
    offset?: number
): LoadProductsForQuerySetAction {
    return {
        type: ProductActionTypes.LOAD_PRODUCTS_FOR_QUERY_SET,
        payload: {
            productTypeId,
            querySetId,
            encodedCriteria,
            passCriteria,
            limit,
            offset
        }
    };
}

/** ProductsForQuerySetLoadedAction **/

export interface ProductsForQuerySetLoadedAction extends Action {
    payload: {
        querySetId: string;
        products: Entity[];
    };
}

export function productsForQuerySetLoaded(querySetId: string, products: Entity[]): ProductsForQuerySetLoadedAction {
    return {
        type: ProductActionTypes.PRODUCTS_FOR_QUERY_SET_LOADED,
        payload: {
            querySetId,
            products,
        },
    };
}

/** LoadProductsForQuerySetFailedAction **/

export interface LoadProductsForQuerySetFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadProductsForQuerySetFailed(error: AppError): LoadProductsForQuerySetFailedAction {
    return {
        type: ProductActionTypes.LOAD_PRODUCTS_FOR_QUERY_SET_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadUpdatedProductsDataAction **/

export interface LoadUpdatedProductsDataAction extends Action {
    payload: {
        querySetId: string;
    };
}

export function loadUpdatedProductsData(querySetId: string): LoadUpdatedProductsDataAction {
    return {
        type: ProductActionTypes.LOAD_UPDATED_PRODUCTS_DATA,
        payload: {
            querySetId,
        },
    };
}

/** LoadUpdatedProductsDataLoadedAction **/

export interface UpdatedProductsDataLoadedAction extends Action {
    payload: {
        querySetId: string;
        data: UpdatedData[];
    };
}

export function updatedProductsDataLoaded(querySetId: string, data: any[]): UpdatedProductsDataLoadedAction {
    return {
        type: ProductActionTypes.UPDATED_PRODUCTS_DATA_LOADED,
        payload: {
            querySetId,
            data,
        },
    };
}

/** LoadUpdatedProductsDataFailedAction **/

export interface LoadUpdatedProductsDataFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadUpdatedProductsDataFailed(error: AppError): LoadUpdatedProductsDataFailedAction {
    return {
        type: ProductActionTypes.LOAD_UPDATED_PRODUCTS_DATA_FAILED,
        payload: {
            error,
        },
    };
}

/** RestartUpdateProductsDataAction **/

export interface RestartUpdateProductsDataAction extends Action {
    payload: {
        querySetId: string;
        delayTime?: number;
    };
}

export function restartUpdateProductsData(querySetId: string, delayTime?: number): RestartUpdateProductsDataAction {
    return {
        type: ProductActionTypes.RESTART_UPDATE_PRODUCTS_DATA,
        payload: {
            querySetId,
            delayTime,
        },
    };
}

export function isRestartUpdateProductDataAction(obj: any): obj is RestartUpdateProductsDataAction {
    return typeof obj.querySetId === 'string' && typeof obj.delayTime === 'number';
}

/**
 * ClearProducts
 */
export interface ClearProductsAction extends Action {
    payload: null;
}

export function clearProducts(): ClearProductsAction {
    return {
        type: ProductActionTypes.CLEAR_PRODUCTS,
        payload: null,
    };
}
