import { createReducer, on } from "@ngrx/store";
import * as IssueMessageActions from "../actions/issue-message.action";
import { IssueMessageState } from "../state/issue-message.state";

export const initialState: IssueMessageState = {
    message: '',
    updatedAt: '',
    hidden: true
}

export const reducer = createReducer(initialState,
    on(IssueMessageActions.setIssueMessage, (state, { message, updatedAt, hidden }): IssueMessageState =>
        ({
            ...state,
            message,
            updatedAt,
            hidden
        })
    ),
    on(IssueMessageActions.hideIssueMessage, (state): IssueMessageState => 
        ({
            ...state,
            hidden: true
        })
    )
);
