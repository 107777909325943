import {FileListState} from '../state/file-list.state';
import {Action} from '@ngrx/store';
import {DocumentListGroupActionTypes, LoadDocumentListGroupSuccessAction} from '../actions/file-list.actions';

export const initialState: FileListState = {
    fileListGroup: null,
    loading: false,
    error: null,
};

export function reducer(
    state: FileListState = initialState,
    action: Action
): FileListState {

    switch (action.type) {
        case DocumentListGroupActionTypes.CLEAR_DOCUMENT_LIST_GROUP:
            return {
                ...initialState
            };

        case DocumentListGroupActionTypes.DOCUMENT_LIST_GROUP_REQUESTED:
            return {
                ...state,
                fileListGroup: null,
                loading: true,
                error: null
            };

        case DocumentListGroupActionTypes.DOCUMENT_LIST_GROUP_SUCCCESS:
            const listLoadedAction = action as LoadDocumentListGroupSuccessAction;
            return {
                ...state,
                fileListGroup: listLoadedAction.payload.fileListGroup,
                loading: false,
                error: null
            };
        default:
            return state;
    }
}
