<ng-container *ngFor="let widget of structure">
    <app-entity-widget
        *ngIf="getVisibilityForWidget(widget)"
        [path]="getPathForWidget(widget)"
        [isLoadingData]="getIsLoadingWidgetData(widget)"
        [type]="widget.type"
        [width]="widget.width"
        [refresh]="widget.refreshable"
        [options]="getOptionsForWidget(widget)"
        [entityId]="entityId"
        [additionalData]="getAdditionalDataForWidget(widget)"
        [data]="getDataForWidgetWithLoading(widget)"
        [title]="getTitleForWidget(widget)"
        [subTitle]="getSubTitleForWidget(widget)"
        [infoText]="getInfoTextForWidget(widget)"
        (action)="onAction($event, widget)"
        [formValues]="values"
        (componentEvent)="onComponentEvent($event, widget)"></app-entity-widget>
</ng-container>
