<!--<figure #chart></figure>-->
<div echarts
     [options]="options"
     [style.width]="size[0] + 'px'"
     [style.height]="size[1] + 'px'"
     (chartInit)="onChartInit($event)"
     (chartRendered)="onChartRendered($event)"
     [initOpts]="{
        width: size[0],
        height: size[1]
     }"
></div>
