import { wrappedNative } from './wrappedNative.validator';

export { startsWithValidator } from './startsWith.validator';
export { endsWithValidator } from './endsWith.validator';

export const minValidator = wrappedNative('min');
export const maxValidator = wrappedNative('max');
export const requiredValidator = wrappedNative('required', true);
export const requiredTrueValidator = wrappedNative('requiredTrue', true);
export const emailValidator = wrappedNative('email', true);
export const minLengthValidator = wrappedNative('minLength');
export const maxLengthValidator = wrappedNative('maxLength');
export const patternValidator = wrappedNative('pattern');
export const nullValidator = wrappedNative('nullValidator', true);
