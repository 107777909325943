/**
 * Created by Alex Klein <alex@rocketloop.de> on 10/17/2017.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DerifinApi } from '../../../../core/api/derifin.api';
import { ApiResponse } from '../../../../core/api/models/api.model';
import { AuthStoreService } from '../../../../core/services/auth-store.service';
import { ConfigurationService } from '../../../../core/services/configuration.service';
import { LogglyLoggerService } from '../../../../core/services/loggly-logger.service';
import {
    CreateWatchlistRequest,
    Watchlist,
    WatchlistData,
    WatchlistImportProduct,
    WatchlistImportValidationResult,
    WatchlistProductGroup,
    WatchlistStructures,
    WatchlistValues,
} from '../models/watchlist.model';
import { WatchListDataResponse, WatchlistProductsCheckData, WatchlistProductsCheckResponse } from './models/watchlist.model';
import { transformWatchlistDataResponse, transformWatchlistStructures, transformWatchlistTabsStructures } from './watchlist.transform';
import { MultiRowComments } from '../../../../shared/components/entity-table-multi-row-comment/entity-table-multi-row-comment.interface';

/**
 * API abstraction layer for the Watchlist API
 */
@Injectable()
export class WatchlistApi extends DerifinApi {

    constructor(http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, appConfigService: ConfigurationService) {
        super('m/watchlist', appConfigService.configuration.environment.id, http, authStoreService, loggerService, appConfigService);
    }

    /**
     * Get the watchlists
     * @returns {Observable<TargetMarketCustomerData>}
     */
    public getWatchlists(): Observable<Watchlist[]> {
        return this.get('overview').pipe(
            map((response: ApiResponse<Watchlist[]>) => response.data),
        );
    }

    /**
     * Get the watchlists for the specified id
     * @returns {Observable<TargetMarketCustomerData>}
     */
    public getWatchlist(id: string): Observable<Watchlist> {
        return this.get(`${id}`).pipe(
            map((response: ApiResponse<Watchlist>) => response.data),
        );
    }

    /**
     * Get the watchlist
     * @returns {Observable<TargetMarketCustomerData>}
     */
    public getWatchlistValues(): Observable<WatchlistValues> {
        return this.get('values').pipe(
            map((response: ApiResponse<WatchlistValues>) => response.data),
        );
    }

    /**
     * Create a new watchlist
     * @param {CreateWatchlistRequest} createWatchlistRequest
     * @returns {Observable<Watchlist>}
     */
    public createWatchlist(createWatchlistRequest: CreateWatchlistRequest): Observable<Watchlist> {
        return this.post('', createWatchlistRequest).pipe(
            map((response: ApiResponse<Watchlist>) => response.data),
        );
    }

    /**
     * Update the watchlist with the specified id
     * @param {string} id
     * @param data
     * @returns {Observable<Watchlist>}
     */
    public updateWatchlist(id: string, data: any): Observable<Watchlist> {
        return this.post(`${id}`, {
            id,
            ...data,
        }).pipe(
            map((response: ApiResponse<Watchlist>) => response.data),
        );
    }

    /**
     * Patch the watchlist with the specified id
     * @param {string} id
     * @param data
     * @returns {Observable<Watchlist>}
     */
    public patchWatchlist(id: string, data: any): Observable<Watchlist> {
        return this.patch(`${id}`, {
            id,
            ...data,
        }).pipe(
            map((response: ApiResponse<Watchlist>) => response.data),
        );
    }

    /**
     * Delete the watchlists with the specified id
     * @param {string} id
     * @returns {Observable<void>}
     */
    public deleteWatchList(id: string): Observable<void> {
        return this.delete(`${id}`).pipe(
            map((response) => null),
        );
    }

    /**
     * Clear the the products for all watchlists
     * @param {string} id
     * @returns {Observable<Watchlist>}
     */
    public clearWatchlist(id: string): Observable<Watchlist> {
        return this.post(`${id}/clear`, {}).pipe(
            map((response: ApiResponse<Watchlist>) => response.data),
        );
    }

    /**
     * Add products to watchlist
     * @param {string} id
     * @param {string[]} dqs
     * @returns {Observable<Watchlist>}
     */
    public addProductsToWatchlist(id: string, dqs: string[]): Observable<Watchlist> {
        return this.post(`${id}/add`, dqs).pipe(
            map((response: ApiResponse<Watchlist>) => response.data),
        );
    }

    /**
     * Remove products from watchlist
     * @param {string} id
     * @param {string[]} dqs
     * @returns {Observable<Watchlist>}
     */
    public removeProductsFromWatchlist(id: string, dqs: string[]): Observable<Watchlist> {
        return this.post(`${id}/remove`, dqs).pipe(
            map((response: ApiResponse<Watchlist>) => response.data),
        );
    }

    /**
     * Get the watchlist structures
     * @param {string} id
     * @returns {Observable<WatchlistStructures>}
     */
    public getWatchlistStructures(id: string): Observable<any> {
        return this.get(`${id}/structure`).pipe(
            map((response: ApiResponse<WatchlistStructures>) => {
                return {
                    structures: transformWatchlistStructures(id, response),
                    actions: response.data.actions,
                };
            }),
        );
    }

    /**
     * Get the watchlists structure
     * @param {string} id
     * @param {string} groupId
     * @returns {Observable<WatchlistProductGroup>}
     */
    public getWatchlistStructure(id: string, groupId: string): Observable<WatchlistProductGroup> {
        return this.get(`${id}/structure/${groupId}`).pipe(
            map((response: ApiResponse<WatchlistProductGroup>) => response.data),
        );
    }

    /**
     * Get the watchlists tab structure
     * @param id
     */
    public getWatchlistTabsStructure(id: string): Observable<WatchlistStructures> {
        return this.get(`${id}/structure`).pipe(
            map((response: ApiResponse<WatchlistStructures>) => {
                return transformWatchlistTabsStructures(id, response);
            }),
        )
    }

    /**
     * Get the watchlists data / watchlists products
     * @param {string} id
     * @returns {Observable<any>}
     */
    public getWatchlistData(id: string): Observable<WatchlistData> {
        return this.get(`${id}/products`).pipe(
            map((response: ApiResponse<WatchListDataResponse>) => transformWatchlistDataResponse(id, response)),
        );
    }

    public getWatchlistEquivalenceData(id: string, deriBwIds: Array<string>): Observable<WatchlistProductsCheckResponse> {
        return this.post(`${id}/equivalence-check`, deriBwIds).pipe(
            map((response: ApiResponse<WatchlistProductsCheckResponse>) => response.data),
        );
    }

    public updateWatchlistEquivalenceComments(id: string, comments: MultiRowComments): Observable<void> {
        return this.put(`${id}/equivalence-check/comments`, comments);
    }

    /**
     * Get the watchlists data / watchlists products for a specific groupId
     * @param {string} id
     * @param {string} groupId
     * @returns {Observable<any>}
     */
    public getSingleWatchlistData(id: string, groupId: string): Observable<any> {
        return this.get(`${id}/products/${groupId}`).pipe(
            map((response: ApiResponse<any>) => response.data),
        );
    }

    public validateWatchlistImportProducts(id: string, productIds: string[]): Observable<WatchlistImportValidationResult> {
        return this.post(`${id}/quick-fill/check`, productIds).pipe(
            map((response: WatchlistImportValidationResult) => response),
        );
    }

    public executeWatchlistImportProducts(id: string, data: WatchlistImportProduct[], reset: boolean): Observable<Watchlist> {
        const urlParams: { reset?: string } = {};

        if (reset) {
            urlParams.reset = 'true';
        }

        return this.post(`${id}/quick-fill/execute`, data, { params: { ...urlParams } }).pipe(
            map((response: ApiResponse<Watchlist>) => response.data),
        );
    }

    public performTmcCheck(watchlistId: string): Observable<WatchlistProductsCheckData> {
        return this.get(`${watchlistId}/target-market-criteria-check`).pipe(
            map((response: ApiResponse<WatchlistProductsCheckData>) => response.data),
        );
    }
}
