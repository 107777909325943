<app-dynamic-chart
    [style.minHeight]="'350px'"
    [config]="{
        'chartType': 'custom',
        'options': {
            'disableDataZoom': false,
            'dataZoomPosition': '10%',
            'legendPosition': 0
        },
        'chartConfig': chartConfig
    }"
    [data]="transformedData"
    [useParentWidth]="useParentWidth"
    [maxChartHeight]="maxChartHeight"
    [offsetHeight]="timeFrames?.nativeElement.offsetHeight"
    [referenceLines]="referenceLines"
    (selectControl)="onSelectControl($event)"
    [formatXAxis]="formatXAxisTick.bind(this)"
    [formatYAxis]="formatYAxisTick.bind(this)"
></app-dynamic-chart>
<div
    #timeFrames
    *ngIf="timeFrameOptions?.length"
    class="app-filter-radio-button-group d-flex justify-content-center pt-4">
    <mat-button-toggle-group (change)="onTimeFrameChange($event.value)">
        <ng-container *ngFor="let option of timeFrameOptions">
            <mat-button-toggle [checked]="isTimeFrameSelected(option.id)" [value]="option.id">{{ option.label }}</mat-button-toggle>
        </ng-container>
    </mat-button-toggle-group>
</div>
