import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Entity, EntityTableColumn } from '../../models/entities.model';
import { EntityTableCellComponent } from '../entity-table-component-cell/entity-table-component-cell.component';
import { get } from 'lodash';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiRowCommentArrayItem, MultiRowCommentForm } from './entity-table-multi-row-comment.interface';
import { EntityTableCellComponent as EntityTableCellComponent_1 } from '../entity-table-cell/entity-table-cell.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-entity-table-multi-row-comment',
    templateUrl: './entity-table-multi-row-comment.component.html',
    styleUrls: ['./entity-table-multi-row-comment.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgFor, EntityTableCellComponent_1, NgIf]
})
export class EntityTableMultiRowCommentComponent implements OnInit, OnChanges, EntityTableCellComponent {

  @Input() public product: Entity;
  @Input() public options: {
    fieldSeparator: string;
    fields: EntityTableColumn[];
  };
  @Input() public path: string;

  @Output() public event = new EventEmitter();

  public get lines(): Array<{ dq: string }> {
    return get(this.product, this.path, []);
  }

  public commentsForm = new FormGroup<MultiRowCommentForm>({
    comments: new FormArray<FormGroup<MultiRowCommentArrayItem>>([])
  });

  public get comments(): FormArray<FormGroup<MultiRowCommentArrayItem>> {
    return this.commentsForm.controls.comments;
  }

  constructor() { }

  public ngOnInit(): void {
    this.commentsForm.valueChanges.subscribe((values) =>
      this.event.next({
        type: 'COMMENTS_INPUT',
        payload: {
          [this.product.dq]: Object.assign({}, 
            ...values.comments.map((comment) => comment)
          )
        }
      })
    );
    
    this.lines.forEach((line) =>
      this.comments.push(
        new FormGroup<MultiRowCommentArrayItem>({
          [line.dq]: new FormControl<string>(
            get(
              this.product,
              `enriched.equivalenceCheckResult.comments.${line.dq}`,
              ''
            )
          )
        })
      )
    );
  }

  public ngOnChanges(): void { }

}
