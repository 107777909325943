import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DerifinApi } from './derifin.api';
import { FormValues } from '../models/form.model';
import { AuthStoreService } from '../services/auth-store.service';
import { ConfigurationService } from '../services/configuration.service';
import { LogglyLoggerService } from '../services/loggly-logger.service';
import { Observable } from 'rxjs';
import { FormSaveResponse } from './models/form.model';
import { getEntityId } from '../helpers/entities.helper';

@Injectable()
export class ProductFormApi extends DerifinApi {

    constructor(http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, private appConfigService: ConfigurationService) {
        super('m/form', appConfigService.configuration.environment.id, http, authStoreService, loggerService, appConfigService);
    }

    public saveBisonDataForProduct(dq: string, contentType: string, data: FormValues): Observable<FormSaveResponse> {
        return this.post(`${contentType}/${dq}`, data);
    }

    public saveCustomProductData<T>(data: FormValues, contentType: string = 'BISON_CUSTOM_PRODUCT'): Observable<FormSaveResponse<T>> {
        const entityId = getEntityId(data);

        if (entityId) {
            return this.post(`${contentType}/${entityId}`, data).pipe(
                map((res) => ({
                    ...res,
                    data: res.data || data,
                })),
            );
        }

        return this.post(`${contentType}`, data).pipe(
            map((res) => ({
                ...res,
                data: res.data || data,
            })),
        );
    }

    public deleteBisonDataForProduct(dq: string, contentType: string) {
        return this.delete(`${contentType}/${dq}`);
    }
}
