/**
 * Created by Alex Klein <alex@rocketloop.de> on 5/5/17.
 */
import { ApiResponse } from '../../../core/api/models/api.model';
import { FormValues } from '../../../core/models/form.model';
import { Entity, EntityTableConfig, QuerySet, UpdatedData } from '../../../shared/models/entities.model';
import { ReportData } from '../models/assets.model';
import { FilterStructureConfig } from '../models/filters.model';
import {
    AssetResponseData,
    CreateAssetQueryResponseData,
    CreateAssetQueryResponseMeta,
    GetAssetFilterAvailableValuesResponseData,
    GetAssetFilterInitialValuesResponseData,
    GetAssetFilterStructureResponseData,
    ReportResponseData,
} from './models/assets.model';

export function transformAssetFilterStructure(
    structureResponse: ApiResponse<GetAssetFilterStructureResponseData>,
): FilterStructureConfig {
    return {
        common: structureResponse.data.common,
        specific: structureResponse.data.specific,
    };
}

export function transformAssetFilterAvailableValues(
    availableValuesResponse: ApiResponse<GetAssetFilterAvailableValuesResponseData>,
): FormValues {
    return availableValuesResponse.data;
}

export function transformAssetFilterInitialValues(
    initialValuesResponse: ApiResponse<GetAssetFilterInitialValuesResponseData>,
): FormValues {
    return initialValuesResponse.data;
}

export function transformCreateAssetQuerySetResponse(
    createAssetQuerySetResponse: ApiResponse<CreateAssetQueryResponseData, CreateAssetQueryResponseMeta>,
): QuerySet {
    return {
        id: createAssetQuerySetResponse.data.id,
        structure: transformAssetTableConfig(createAssetQuerySetResponse.data.structure),
    };
}

export function transformAssetTableConfig(config: EntityTableConfig): EntityTableConfig {
    return config;
}

export function transformAssetsResponse(productsResponse: ApiResponse<AssetResponseData[]>): Entity[] {
    return productsResponse.data.map(transformAssetResponseData);
}

export function transformAssetResponseData(assetResponseData: AssetResponseData): Entity {
    return {
        ...assetResponseData,
        id: assetResponseData.productDq,
    };
}

export function transformUpdatedAssetsData(data: any): UpdatedData {
    const id = data._id;
    delete data._id;
    return {
        id,
        ...data,
    };
}

export function transformReportResponseData(reportResponseData: ApiResponse<ReportResponseData>): ReportData {
    return reportResponseData.data;
}
