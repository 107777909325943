export type ImageReferenceAddress = SimpleReferenceAddress | SetReferenceAddress;
export type ReferenceLocation = 'LOCAL' | 'REMOTE';
export type ReferenceType = 'SET' | 'SIMPLE';

export interface ReferenceAddress {
    location: ReferenceLocation | string;
    mode?: 'BW' | 'COLOR';
    path: string;
}

export interface SimpleReferenceAddress extends ReferenceAddress {
    type: ReferenceType;
}

export function isSimpleReferenceAddress(address: ImageReferenceAddress): address is SimpleReferenceAddress {
    return address.type === 'SIMPLE';
}

export interface SetReferenceAddress {
    type: ReferenceType;
    paths: { [key: string]: ReferenceAddress };
}

export interface UnifiedImageReference {
    type: ReferenceType;
    simple?: ImageReference;
    set?: ImageReferenceSet;
}

export interface ImageReference {
    path: string;
    ref: SimpleReferenceAddress;
}

export class ImageReferenceSet {
    private readonly imageSet: { [key: string]: ImageReference };

    constructor(set: { [key: string]: ImageReference } = {}) {
        this.imageSet = {...set};
    }

    public add(key: string, value: ImageReference): void {
        this.imageSet[key] = value;
    }

    public has(key: string): boolean {
        return !!this.imageSet.hasOwnProperty(key);
    }

    public get(key: string): ImageReference {
        if (!this.has(key)) {
            return null;
        }

        return this.imageSet[key];
    }
}
