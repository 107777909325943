<div>
    <div class="range-filter__row row">
        <div class="col-5">
            <div class="form-group range-filter__stretch">
                <div class="range-filter__center">
                    <label class="custom-control custom-switch">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            [checked]="enabled"
                            (click)="toggle($event)"
                            [disabled]="disabled">
                        <span class="custom-control-indicator"></span>
                        <span class="custom-control-description">{{label}}</span>
                    </label>
                </div>
            </div>
        </div>

        <div class="col-3">
            <div class="form-group range-filter__stretch">
                <div class="range-filter__center">
                    <label class="left-input-label">{{minLabel}}</label>
                    <app-number-input
                        class="form-control ratio-input"
                        type="text"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        [ngModel]="min"
                        (ngModelChange)="onMinValueChanged($event)"
                        [disabled]="disabled"></app-number-input>
                    <div class="right-input-label-wrapper">
                        <label class="right-input-label">{{unit}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3">
            <div class="form-group range-filter__stretch">
                <div class="range-filter__center">
                    <label class="left-input-label">{{maxLabel}}</label>
                    <app-number-input
                        class="form-control ratio-input"
                        type="text"
                        pattern="[0-9]+([\.,][0-9]+)?"
                        [ngModel]="max"
                        (ngModelChange)="onMaxValueChanged($event)"
                        [disabled]="disabled"></app-number-input>
                    <label class="right-input-label">{{unit}}</label>
                </div>
            </div>
        </div>
        <div class="col-1">
            <div class="form-group range-filter__stretch">
                <div class="range-filter__center">
                    <app-icon
                        *ngIf="enabled && !disabled"
                        class="reset-icon"
                        icon="derifin:reset"
                        (click)="onResetClicked()"></app-icon>
                </div>
            </div>
        </div>
    </div>
</div>
