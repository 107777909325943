import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AdState } from "../state/ad.state";

export const selectAd = createFeatureSelector<AdState>('ad');

export const selectAdData = createSelector(
    selectAd,
    (state: AdState) => state.data
);

export const selectPolling = createSelector(
    selectAd,
    (state: AdState) => state.polling
);
