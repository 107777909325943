import { Component, Input, OnInit } from '@angular/core';
import { getImagePathFromReference } from '../../../../../core/helpers/image-referece-resolver.helper';
import { isSimpleReferenceAddress, UnifiedImageReference } from '../../../../../core/models/image-reference.model';
import { ImageReferenceResolverService } from '../../../../../core/services/image-reference-resolver.service';
import { FocusListItem } from '../focus-list-widget.component';
import { ReplaceLineBreaks } from '../../../../pipes/replace-line-breaks.pipe';
import { GrayscaleDirective } from '../../../../directives/grayscale.directive';

@Component({
    selector: 'app-focus-list-widget-item',
    templateUrl: './focus-list-widget-item.component.html',
    styleUrls: ['./focus-list-widget-item.component.scss'],
    standalone: true,
    imports: [GrayscaleDirective, ReplaceLineBreaks]
})
export class FocusListWidgetItemComponent implements OnInit {
    @Input() public item: FocusListItem;
    public backgroundImage: string;

    public imageSet: UnifiedImageReference;
    public imageColor: string;
    public colorMode: string;

    constructor(
        private imageReferenceResolverService: ImageReferenceResolverService,
    ) {
    }

    public ngOnInit() {
        this.imageSet = this.imageReferenceResolverService.getImageReferenceFromAddress(this.item.imageReference);

        this.colorMode = 'COLOR';
        if (isSimpleReferenceAddress(this.item.imageReference)) {
            this.colorMode = this.item.imageReference.mode;
        }

        this.imageColor = getImagePathFromReference(this.imageSet, 'COLOR');
        this.backgroundImage = this.imageColor;
    }
}
