<a 
   href="#"
   class="icon-button recommendation-entity-table-cell__type"
   [class.recommendation-entity-table-cell__type--selected]="status === 'BUY'"
   (click)="onTypeSelected($event, 'BUY')">{{ 'recommendation.chooser.buy' | transloco }}</a>
<a 
   href="#"
   class="icon-button recommendation-entity-table-cell__type"
   [class.recommendation-entity-table-cell__type--selected]="status === 'HOLD'"
   (click)="onTypeSelected($event, 'HOLD')">{{ 'recommendation.chooser.hold' | transloco }}</a>
<a 
   href="#"
   class="icon-button recommendation-entity-table-cell__type"
   [class.recommendation-entity-table-cell__type--selected]="status === 'SELL'"
   (click)="onTypeSelected($event, 'SELL')">{{ 'recommendation.chooser.sell' | transloco }}</a>
<a 
   href="#"
   class="icon-button recommendation-entity-table-cell__type"
   [class.recommendation-entity-table-cell__type--selected]="status === 'NONE'"
   (click)="onTypeSelected($event, 'NONE')">{{ 'recommendation.chooser.none' | transloco }}</a>
