import { Target } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { FormStructureConfig, FormValues } from '../../../core/models/form.model';
import * as RouterActions from '../../../core/store/actions/router.action';
import { AppState } from '../../../core/store/state/index.state';
import { truthy } from '../../../shared/helpers/general.helper';
import { TargetMarketCriteriaStructure, TargetMarketCriteriaStructureCustomerDetail, TargetMarketSuggestionEntry } from '../models/target-market-criteria-structure.model';
import { TargetMarketCriteria } from '../models/target-market-criteria.model';
import { TargetMarketCustomerData } from '../models/target-market-customer-data.model';
import { TargetMarketCustomCustomer, TargetMarketCustomCustomerData, TargetMarketCustomer } from '../models/target-market-customer.model';
import {
    activateTargetMarketCustomCustomer,
    activateTargetMarketCustomer,
    activateTargetMarketReferenceCustomer,
    clearTargetMarketCriteria,
    clearTargetMarketCustomerSuggestions,
    clearTargetMarketSelectedCustomer,
    clearTempTargetMarketCriteria,
    createTargetMarketCustomCustomer,
    disengageTargetMarketCriteria,
    engageTargetMarketCriteria,
    loadTargetMarketCustomCustomers,
    loadTargetMarketCustomerSuggestions,
    loadTargetMarketReferenceCustomers,
    loadTargetMarketSelectedCustomer,
    removeTargetMarketCustomCustomer,
    saveTargetMarketCustomCustomer,
    selectTargetMarketCustomCustomer,
    setTempTargetMarketCriteria,
    storeTargetMarketCustomerCustomer,
    unselectTargetMarketCustomer, updateTargetMarketCriteria,
} from '../store/actions/target-market.actions';

/**
 * Service responsible for the target market feature
 */
@Injectable()
export class TargetMarketService {

    public static lastCustomerId = 0;

    constructor(private store: Store<AppState>) {
    }

    /** Selectors **/

    /**
     * Get from the store whether the target market criteria are engaged
     * @returns {Observable<boolean>}
     */
    public isTargetMarketCriteriaEngaged(): Observable<boolean> {
        return this.store.pipe(
            select((state) => Boolean(state.targetMarket.encodedCriteria)),
        );
    }

    /**
     * Get the current target market criteria from the store
     * @returns {Observable<TargetMarketCriteria>}
     */
    public getTargetMarketCriteria(): Observable<TargetMarketCriteria> {
        return this.store.pipe(
            select((state) => state.targetMarket.criteria),
        );
    }

    /**
     * Get the current encoded target market criteria from the store
     * @returns {Observable<TargetMarketCriteria>}
     */
    public getEncodedTargetMarketCriteria(): Observable<string> {
        return this.store.pipe(
            select((state) => state.targetMarket.encodedCriteria),
        );
    }

    /**
     * Get all custom target market customers
     * @returns {Observable<TargetMarketCustomCustomer>}
     */
    public getTargetMarketCustomCustomers(): Observable<TargetMarketCustomCustomer[]> {
        return this.store.pipe(
            select((state) => state.targetMarket.customCustomers),
            map((customers) => Object.keys(customers).map((id) => customers[id])),
        );
    }

    /**
     * Get all reference target market customers
     * @returns {Observable<TargetMarketCustomCustomer>}
     */
    public getTargetMarketReferenceCustomers(): Observable<TargetMarketCustomCustomer[]> {
        return this.store.pipe(
            select((state) => state.targetMarket.referenceCustomers),
            map((customers) => Object.keys(customers).map((id) => customers[id])),
        );
    }

    /**
     * Get the target market customer specified by id
     * @param customerId
     * @returns {Observable<TargetMarketCustomCustomer>}
     */
    public getTargetMarketCustomCustomer(customerId: string): Observable<TargetMarketCustomCustomer> {
        return this.store.pipe(
            select((state) => state.targetMarket.customCustomers[customerId]),
        );
    }

    /**
     * Get the reference target market customer specified by id
     * @param customerId
     * @returns {Observable<TargetMarketCustomCustomer>}
     */
    public getTargetMarketReferenceCustomer(customerId: string): Observable<TargetMarketCustomCustomer> {
        return this.store.pipe(
            select((state) => state.targetMarket.referenceCustomers[customerId]),
        );
    }

    /**
     * Search for custom target market customers that fit the given query
     * @param query
     * @returns {Observable<TargetMarketCustomCustomer[]>}
     */
    public searchForTargetMarketCustomCustomer(query: string): Observable<TargetMarketCustomCustomer[]> {
        const searcher = new RegExp(query);
        return this.getTargetMarketCustomCustomers().pipe(
            map((customers) => {
                return customers.filter((customer: TargetMarketCustomCustomer) => {
                    return customer.details &&
                        (searcher.test(customer.details.customerName) || searcher.test(
                            customer.details.customerId));
                });
            }),
        );

    }

    /**
     * Get the activated target market customer if engaged, else null
     * @returns {Observable<TargetMarketCustomCustomer>}
     */
    public getActivatedTargetMarketCustomCustomer(): Observable<TargetMarketCustomCustomer> {
        return this.store.pipe(
            select((state) => state.targetMarket.activatedCustomCustomerId),
            switchMap((id) => {
                if (!id) {
                    return of(null);
                }
                return this.getTargetMarketCustomCustomer(id);
            }),
        );
    }

    /**
     * Get the activated target market customer if engaged, else null
     * @returns {Observable<TargetMarketCustomCustomer>}
     */
    public getActivatedTargetMarketReferenceCustomer(): Observable<TargetMarketCustomCustomer> {
        return this.store.pipe(
            select((state) => state.targetMarket.activatedReferenceCustomerId),
            switchMap((id) => {
                if (!id) {
                    return of(null);
                }
                return this.getTargetMarketReferenceCustomer(id);
            }),
        );
    }

    /**
     * Get whether a target market customer is activated
     * @returns {Observable<boolean>}
     */
    public isTargetMarketCustomerActivated(): Observable<boolean> {
        return this.store.pipe(
            select((state) => state.targetMarket.activatedCustomCustomerId),
            map((id) => !!id),
        );
    }

    /**
     * Get the target market criteria structure
     * @returns {Observable<TargetMarketCriteriaStructure>}
     */
    public getTargetMarketCriteriaStructure(): Observable<TargetMarketCriteriaStructure> {
        return this.store.pipe(
            select((state: AppState) => state.targetMarket.structure),
        );
    }

    /**
     * Get the target market criteria structure
     * @returns {Observable<TargetMarketCriteriaStructure>}
     */
    public getTargetMarketCriteriaFormStructure(): Observable<FormStructureConfig> {
        return this.getTargetMarketCriteriaStructure().pipe(
            filter(truthy),
            map((structure) => {
                return {
                    sectionsOrientation: 'vertical',
                    areas: [
                        {
                            weight: 1,
                            sections: structure.sections,
                        },
                    ],
                };
            }),
        );
    }

    /**
     * Get the structure for the custom target market criteria customer's details
     * @returns {Observable<TargetMarketCriteriaStructureCustomerDetail[]>}
     */
    public getTargetMarketCriteriaCustomCustomerDetailsStructure(): Observable<TargetMarketCriteriaStructureCustomerDetail[]> {
        return this.getTargetMarketCriteriaStructure().pipe(
            filter(truthy),
            filter((structure) => !!structure.custom),
            map((structure: TargetMarketCriteriaStructure) => structure.custom.details),
        );
    }

    /**
     * Get the target market criteria available values
     * @returns {Observable<FormValues>}
     */
    public getTargetMarketCriteriaAvailableValues(): Observable<FormValues> {
        return this.store.pipe(
            select((state: AppState) => state.targetMarket.values),
        );
    }

    /**
     * Get the activate target market customer
     * @returns {Observable<TargetMarketCustomer>}
     */
    public getActivatedTargetMarketCustomer(): Observable<TargetMarketCustomerData> {
        return this.store.pipe(
            select((state: AppState) => state.targetMarket.activatedCustomer),
        );
    }

    /**
     * Get the current criteria in the temp criteria slot
     * @returns {Observable<TargetMarketCriteria>}
     */
    public getTempTargetMarketCriteria(): Observable<TargetMarketCriteria> {
        return this.store.pipe(
            select((state: AppState) => state.targetMarket.tempCriteria),
        );
    }

    /**
     * Get custom customer saved state
     */
    public getTargetMarketCustomCustomerSaved() {
        return this.store.pipe(
            select((state: AppState) => state.targetMarket.customCustomerSaved),
        );
    }

    /**
     * Get custom customer saved state
     */
    public getTargetMarketCustomCustomerCreated() {
        return this.store.pipe(
            select((state: AppState) => state.targetMarket.customCustomerCreated),
        );
    }

    /**
     * Get selected customer data
     */
    public getTargetMarketSelectedCustomerData(): Observable<TargetMarketCustomerData> {
        return this.store.pipe(
            select((state: AppState) => state.targetMarket.selectedCustomer),
        );
    }

    /**
     * Get customer suggestions
     */
    public getTargetMarketCustomCustomerSuggestions(): Observable<TargetMarketSuggestionEntry[]> {
        return this.store.pipe(
            select((state: AppState) => state.targetMarket.suggestions),
        );
    }

    /** Actions **/

    /**
     * Set the target market criteria to engaged
     */
    public engageTargetMarketCriteria(criteria: TargetMarketCriteria, encodedCriteria: string) {
        this.store.dispatch(engageTargetMarketCriteria(criteria, encodedCriteria));
    }

    /**
     * Set the target market criteria to disengaged
     */
    public disengageTargetMarketCriteria() {
        this.store.dispatch(disengageTargetMarketCriteria());
    }

    /**
     * Update the current target market criteria
     * @param criteria
     */
    public updateTargetMarketCriteria(criteria: TargetMarketCriteria) {
        this.store.dispatch(updateTargetMarketCriteria(criteria));
    }

    /**
     * Clear the current target market criteria
     */
    public clearTargetMarketCriteria() {
        this.store.dispatch(clearTargetMarketCriteria());
    }

    /**
     * Update an existing target market customer
     * @param customer
     */
    public updateTargetMarketCustomCustomer(customer: TargetMarketCustomCustomer) {
        this.store.dispatch(storeTargetMarketCustomerCustomer(customer));
    }

    /**
     * Select the given target market customer and engage their criteria
     * @param customer
     */
    public selectTargetMarketCustomCustomer(customer: TargetMarketCustomCustomer) {
        this.store.dispatch(selectTargetMarketCustomCustomer(customer));
    }

    /**
     * Unselect the currently selected target market customer and disengage the target market criteria
     */
    public unselectTargetMarketCustomer() {
        this.store.dispatch(unselectTargetMarketCustomer());
    }

    /**
     * Activate the given target market customer
     * @param {TargetMarketCustomer} customer
     */
    public activateTargetMarketCustomer(customer: TargetMarketCustomerData) {
        this.store.dispatch(activateTargetMarketCustomer(customer));
    }

    /**
     * Load the custom target market customers
     */
    public loadTargetMarketCustomCustomers() {
        this.store.dispatch(loadTargetMarketCustomCustomers());
    }

    /**
     * Load the reference target market customers
     */
    public loadTargetMarketReferenceCustomers() {
        this.store.dispatch(loadTargetMarketReferenceCustomers());
    }

    /**
     * Activate the given target market custom customer
     * @param {TargetMarketCustomCustomer} customer
     */
    public activateTargetMarketCustomCustomer(customer: TargetMarketCustomCustomer) {
        this.store.dispatch(activateTargetMarketCustomCustomer(customer));
    }

    /**
     * Activate the given target market custom customer
     * @param {TargetMarketCustomCustomer} customer
     */
    public activateTargetMarketReferenceCustomer(customer: TargetMarketCustomCustomer) {
        this.store.dispatch(activateTargetMarketReferenceCustomer(customer));
    }

    /**
     * Save the target market custom customer with the given id
     * @param {string} customerId
     * @param {TargetMarketCustomCustomerData} data
     */
    public saveTargetMarketCustomCustomer(customerId: string, data: TargetMarketCustomCustomerData) {
        this.store.dispatch(saveTargetMarketCustomCustomer(customerId, data));
    }

    /**
     * Create a new target market custom customer
     * @param {TargetMarketCustomCustomerData} data
     */
    public createTargetMarketCustomCustomer(data: TargetMarketCustomCustomerData) {
        this.store.dispatch(createTargetMarketCustomCustomer(data));
    }

    /**
     * Remove the target market custom customer with the given id
     * @param {string} customerId
     */
    public removeTargetMarketCustomCustomer(customerId: string) {
        this.store.dispatch(removeTargetMarketCustomCustomer(customerId));
    }

    /**
     * Remove the target market reference customer with the given id
     * @param {string} customerId
     */
    public removeTargetMarketReferenceCustomer(customerId: string) {
        this.store.dispatch(removeTargetMarketCustomCustomer(customerId));
    }

    /**
     * Set the given target market criteria to the temp criteria slot
     * @param {TargetMarketCriteria} criteria
     */
    public setTempTargetMarketCriteria(criteria: TargetMarketCriteria) {
        this.store.dispatch(setTempTargetMarketCriteria(criteria));
    }

    /**
     * Clear the temp criteria slot
     */
    public clearTempTargetMarketCriteria() {
        this.store.dispatch(clearTempTargetMarketCriteria());
    }

    /**
     * Load the target market customer suggestions by the given filter
     * @param {string} f
     */
    public loadTargetMarketCustomerSuggestions(f: string) {
        this.store.dispatch(loadTargetMarketCustomerSuggestions(f));
    }

    /**
     * Clear the target market customer suggestions
     */
    public clearTargetMarketCustomerSuggestions() {
        this.store.dispatch(clearTargetMarketCustomerSuggestions());
    }

    /**
     * Load the target market selected customer by the given criteriaId
     * @param {string} criteriaId
     */
    public loadTargetMarketSelectedCustomer(criteriaId: string) {
        this.store.dispatch(loadTargetMarketSelectedCustomer(criteriaId));
    }

    /**
     * Clear the target market selected customer
     */
    public clearTargetMarketSelectedCustomer() {
        this.store.dispatch(clearTargetMarketSelectedCustomer());
    }

}
