/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 5/6/17.
 */

import { MenuItemConfig, MenuItemTargetConfig } from '../models/config.model';
import { Menu, MenuItem } from '../models/menu.model';

export const MenuItemModuleType = {
    STATIC: 'STATIC',
    DYNAMIC: 'DYNAMIC',
    NONE: 'NONE',
};

export const MenuItemTargetModuleTypes = {
    DASHBOARD: 'DASHBOARD',
    PRODUCT: 'PRODUCT',
    CUSTOM_PRODUCTS: 'CUSTOM_PRODUCTS',
    DPC: 'DPC',
    ADMIN: 'ADMIN',
    WATCHLIST: 'WATCHLIST',
    WATCHLIST_GROUP: 'WATCHLIST_GROUP',
    UNIVERSE: 'UNIVERSE',
    RECOMMENDATION: 'UNDERLYING_RECOMMENDATION',
    TRADE: 'TRADE',
    ASSET: 'ASSET',
    DPC_CREATE: 'DPC_CREATE',
    DPC_ISSUED: 'DPC_ISSUED',
    DPC_HISTORY: 'DPC_HISTORY',
    RISKMAN_LIST: 'RISKMAN_LIST',
    RISKMAN_SINGLE: 'RISKMAN_SINGLE',
    MARKET_ANALYSIS_BASIC: 'MARKET_ANALYSIS_BASIC',
    MARKET_ANALYSIS_PRODUCTS: 'MARKET_ANALYSIS_PRODUCTS',
    SHORTLIST: 'SHORTLIST',
    BETHMANN_CSV_EXPORT: 'BETHMANN_CSV_EXPORT',
    ADVISORY_BASKETS: 'ADVISORY_BASKETS',

    PRODUCT_DESIGNER: 'PRODUCT_DESIGNER',
    PRODUCT_DESIGNER_EDITOR: 'PRODUCT_DESIGNER_EDITOR',
    PRODUCT_DESIGNER_REQUEST: 'PRODUCT_DESIGNER_REQUEST',
    PRODUCT_DESIGNER_SURVEYS: 'PRODUCT_DESIGNER_SURVEYS',

    RFQ_ISSUER_OFFER: 'RFQ_ISSUER_OFFER',
    PRODUCT_DESIGNER_ISSUER: 'PRODUCT_DESIGNER_ISSUER',

    ADMIN_DASHBOARD: 'ADMIN_DASHBOARD',
    ADMIN_USER_MANAGER: 'ADMIN_USER_MANAGER',
    ADMIN_PORTFOLIOS: 'ADMIN_PORTFOLIOS',

    AUDIT: 'AUDIT',

    FILE_LISTS: 'FILE_LISTS',
    FILE_LIST_ADMINISTRATION: 'FILE_LISTS_ADMIN'
};

export function getFirstMenuItemLink(menu: MenuItemConfig): any[] {
    let node = menu;
    while (node.children.length > 0) {
        node = node.children[0];
    }
    return createMenuItemFromMenuItemConfig(node).link;
}

export function createMenuFromMenuItemConfig(menuItemConfig: MenuItemConfig): Menu {
    return {
        items: (menuItemConfig && menuItemConfig.children)
            ? menuItemConfig.children.map((item: MenuItemConfig) => {
                return createMenuItemFromMenuItemConfig(item);
            })
            : [],
    };
}

export function createMenuItemFromMenuItemConfig(menuItemConfig: MenuItemConfig): MenuItem {
    const childHasShowParents = menuItemConfig.children && menuItemConfig.children.length > 0 && menuItemConfig.children.reduce((acc, current) => {
        return acc || current.displayState === 'SHOW_PARENTS';
    }, false);
    const hasSubmenu = childHasShowParents || menuItemConfig.hasSubmenu;

    return {
        label: menuItemConfig.label,
        icon: menuItemConfig.icon,
        children: (menuItemConfig.children && menuItemConfig.children.length > 0)
            ? menuItemConfig.children.map((item: MenuItemConfig) => {
                return createMenuItemFromMenuItemConfig(item);
            })
            : null,
        link: (menuItemConfig.config && !hasSubmenu) ? getRouterLinkFromMenuItemTargetConfig(
            menuItemConfig.config) : null,
        hasSubmenu,
        displayState: menuItemConfig.displayState,
    };
}

export function getRouterLinkFromMenuItemTargetConfig(menuItemTargetConfig: MenuItemTargetConfig): any[] {

    switch (menuItemTargetConfig.type) {

        case MenuItemTargetModuleTypes.DASHBOARD:
            return ['/app/dashboard', menuItemTargetConfig.id];

        case MenuItemTargetModuleTypes.PRODUCT:
            return ['/app/products', menuItemTargetConfig.id];

        case MenuItemTargetModuleTypes.CUSTOM_PRODUCTS:
            return ['/app/other-products', menuItemTargetConfig.id];

        case MenuItemTargetModuleTypes.WATCHLIST:
            return ['/app/watchlists', menuItemTargetConfig.id];

        case MenuItemTargetModuleTypes.WATCHLIST_GROUP:
            return ['/app/watchlists-group', menuItemTargetConfig.id];

        case MenuItemTargetModuleTypes.UNIVERSE:
            return ['/app/bwlist', menuItemTargetConfig.id];

        case MenuItemTargetModuleTypes.RECOMMENDATION:
            return ['/app/recommendation'];

        case MenuItemTargetModuleTypes.TRADE:
            return ['/app/trades', menuItemTargetConfig.id];

        case MenuItemTargetModuleTypes.ASSET:
            return ['/app/assets', menuItemTargetConfig.id];

        case MenuItemTargetModuleTypes.DPC_CREATE:
            return ['/app/dpc/create'];

        case MenuItemTargetModuleTypes.DPC_ISSUED:
            return ['/app/dpc/issued'];

        case MenuItemTargetModuleTypes.DPC_HISTORY:
            return ['/app/dpc/history'];

        case MenuItemTargetModuleTypes.RISKMAN_SINGLE:
            return ['/app/risk-manager/single', menuItemTargetConfig.id];

        case MenuItemTargetModuleTypes.RISKMAN_LIST:
            return ['/app/risk-manager/list', menuItemTargetConfig.id];

        case MenuItemTargetModuleTypes.MARKET_ANALYSIS_BASIC:
            return ['/app/market-analysis', menuItemTargetConfig.id, 'basic', 'dividends'];

        case MenuItemTargetModuleTypes.MARKET_ANALYSIS_PRODUCTS:
            return ['/app/market-analysis', menuItemTargetConfig.id, 'products'];

        case MenuItemTargetModuleTypes.SHORTLIST:
            return ['/app/shortlist', menuItemTargetConfig.id];

        case MenuItemTargetModuleTypes.BETHMANN_CSV_EXPORT:
            return ['/app/products-export', menuItemTargetConfig.id];

        case MenuItemTargetModuleTypes.PRODUCT_DESIGNER:
            return ['/app/product-designer', '_product_designer_editor', 'editor'];

        case MenuItemTargetModuleTypes.PRODUCT_DESIGNER_EDITOR:
            return ['/app/product-designer', menuItemTargetConfig.id, 'editor'];

        case MenuItemTargetModuleTypes.PRODUCT_DESIGNER_REQUEST:
            return ['/app/product-designer', menuItemTargetConfig.id, 'history'];

        case MenuItemTargetModuleTypes.PRODUCT_DESIGNER_SURVEYS:
            return ['/app/product-designer', menuItemTargetConfig.id, 'surveys'];

        case MenuItemTargetModuleTypes.RFQ_ISSUER_OFFER:
            return ['/app/rfq-issuer/offer', menuItemTargetConfig.id];

        case MenuItemTargetModuleTypes.PRODUCT_DESIGNER_ISSUER:
            return ['/app/rfq-issuer/list'];

        case MenuItemTargetModuleTypes.ADVISORY_BASKETS:
            return ['/app/advisor-product-baskets', menuItemTargetConfig.id];

        case MenuItemTargetModuleTypes.ADMIN_DASHBOARD:
            return ['/admin/admin-dashboard'];

        case MenuItemTargetModuleTypes.ADMIN_USER_MANAGER:
            return ['/admin/user-manager'];

        case MenuItemTargetModuleTypes.ADMIN_PORTFOLIOS:
            return ['/admin/portfolio-manager/', menuItemTargetConfig.id];

        case MenuItemTargetModuleTypes.FILE_LISTS:
            return ['/app/file-lists/', menuItemTargetConfig.id];

        case MenuItemTargetModuleTypes.FILE_LIST_ADMINISTRATION:
            return ['/app/file-list-administration', menuItemTargetConfig.id];

        case MenuItemTargetModuleTypes.AUDIT:
            return ['/app/audits', menuItemTargetConfig.id];

        default:
            return null;
    }

}

export function findParentMenuItemForMenuItemTargetType(
    menuItemTargetType: string,
    root: MenuItemConfig,
): MenuItemConfig {
    const parentsQueue = [root];
    while (parentsQueue.length > 0) {
        const parent = parentsQueue.shift();
        if (parent.children && parent.children.length > 0) {
            for (const child of parent.children) {
                if (child.config && child.config.type === menuItemTargetType) {
                    return parent;
                }
                parentsQueue.push(child);
            }
        }
    }
    return null;
}

export function findMenuItemForTargetType(
    menuItemTargetTypes: string[],
    root: MenuItemConfig
): MenuItemConfig {
    if (root && root.config && menuItemTargetTypes.includes(root.config.type)) {
        return root;
    }

    if (root && root.children) {
        for (const child of root.children) {
            const item = findMenuItemForTargetType(menuItemTargetTypes, child);

            if (item) {
                return item;
            }
        }
    }
}

export function findParentMenuItemForMenuItemTargetId(
    menuItemTargetId: string,
    root: MenuItemConfig,
): MenuItemConfig {
    const parentsQueue = [root];
    while (parentsQueue.length > 0) {
        const parent = parentsQueue.shift();
        if (parent.children && parent.children.length > 0) {
            for (const child of parent.children) {
                if (child.config && child.config.id === menuItemTargetId) {
                    return parent;
                }
                parentsQueue.push(child);
            }
        }
    }
    return null;
}

export function findParentMenuItemForMenuTarget(
    menuTarget: MenuItemTargetConfig,
    current: MenuItemConfig,
    path: MenuItemConfig[] = [],
): MenuItemConfig {
    if (menuTarget.type === MenuItemModuleType.NONE) {
        return null;
    }

    if (current.config && (
        (menuTarget.type === MenuItemModuleType.DYNAMIC && current.config.id === menuTarget.id) ||
        (menuTarget.type === MenuItemModuleType.STATIC && (
            current.config.type !== 'SHORTLIST' && current.config.type === menuTarget.id ||
            current.config.type === 'SHORTLIST' && current.config.id === menuTarget.shortlistId
        ))
    )) {
        if (current.displayState === 'SHOW_SIBLINGS' || !current.displayState) {
            return path[path.length - 1];
        } else if (current.displayState === 'SHOW_PARENTS') {
            return path[path.length - 2];
        } else {
            return null;
        }
    }

    if (current.children && current.children.length > 0) {
        for (const child of current.children) {
            const result = findParentMenuItemForMenuTarget(menuTarget, child, [...path, current]);

            if (result) {
                return result;
            }
        }
    }

    return null;
}
