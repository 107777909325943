import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ConfigurationService } from "../services/configuration.service";
import { LogglyLoggerService } from "../services/loggly-logger.service";
import { IssueMessageResponse } from "../store/state/issue-message.state";
import { BaseApi } from "./base.api";

@Injectable()
export class IssueMessageApi extends BaseApi {

    constructor(
        http: HttpClient,
        loggerService: LogglyLoggerService,
        appConfigService: ConfigurationService
    ) {
        super(
            appConfigService.configuration.api.derifin.dmzUrl,
            http, loggerService
        );
    }

    public getIssueMessage(): Observable<IssueMessageResponse> {
        return this.get('issue-message').pipe(
            map((response) => response)
        );
    }
}
