<div class="entity-table-multi-row-cell__line" *ngFor="let line of lines; let last = last;">
    <ng-container *ngFor="let field of options?.fields; let lastField = last;">
        <div class="label" *ngIf="showLabelForField(field)">{{field.label}}:</div>
        <div *ngIf="field.decorator === 'component'; else regularCell" appEntityTableComponentCell
             [component]="field.decoratorOptions?.component" [decoratorOptions]="field.decoratorOptions"
             [componentOptions]="field.decoratorOptions?.componentOptions" [entity]="line" [path]="field.path"
             (event)="onComponentEvent($event)"></div>
        <ng-template #regularCell>
            <div appEntityTableCell [decorator]="field.decorator" [decoratorOptions]="field.decoratorOptions"
                 [entity]="line" [path]="field.path"></div>
        </ng-template>
        <div class="separator" *ngIf="options?.fieldSeparator && !lastField">{{options?.fieldSeparator}}</div>
    </ng-container>
</div>
