import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControlConfig, FormControlGroupConfig } from '../../../../core/models/form.model';
import { FilterComponent } from '../../../components/filter/filter.component';
import { FilterControlContainerComponent } from '../../../components/filter-control-container/filter-control-container.component';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'app-entity-widget-decorfin-data-form',
    templateUrl: './entity-widget-decorfin-data-form.component.html',
    styleUrls: ['./entity-widget-decorfin-data-form.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor, FilterControlContainerComponent]
})
export class EntityWidgetDecorfinDataFormComponent extends FilterComponent implements OnInit, OnChanges {
    @Input() public structureGroups: FormControlGroupConfig[];

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.structureGroups) {
            this.updateFormGroup();
        }

        if (changes.initialValues && this.initialValues) {
            this.setInitialValues();
        }

        if (changes.overrideValues && this.overrideValues) {
            this.setOverrideValues();
        }

        if (changes.rawValue && this.rawValue) {
            this.filterForm.patchValue(this.rawValue, {emitEvent: false});
        }
    }

    public ngOnInit() {
    }

    public getFilterControls(): FormControlConfig[] {
        if (this.structureGroups && Array.isArray(this.structureGroups)) {
            return [].concat(this.structureGroups.reduce((controls4, controlGroup) => {
                return [
                    ...controls4,
                    ...controlGroup.controls.map((c) => {
                        return {
                            ...c,
                            _parent: controlGroup,
                        };
                    })];
            }, []));
        } else {
            return [];
        }
    }

}
