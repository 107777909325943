import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TrackByFunction, ViewChild } from '@angular/core';
import { NgSelectComponent, NgSelectModule } from '@dericon/ng-select';

import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, FormsModule } from '@angular/forms';
import { isEqual } from 'lodash';
import { merge, Subscription } from 'rxjs';
import { SelectItem } from '../../models/widgets.model';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-multi-select-dropdown',
    templateUrl: './multi-select-dropdown.component.html',
    styleUrls: ['./multi-select-dropdown.component.scss'],
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiSelectDropdownComponent),
            multi: true,
        }, {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => MultiSelectDropdownComponent),
            multi: true,
        }],
    standalone: true,
    imports: [NgSelectModule, FormsModule, NgIf, NgFor]
})
export class MultiSelectDropdownComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
    @ViewChild(NgSelectComponent, {static: true}) public ngSelectComponent: NgSelectComponent;

    @Input() public items: SelectItem[];
    @Input() public placeholder = '';
    @Input() public allowClear = true;
    @Input() public disabled = false;
    @Input() public hideSelections = false;

    @Input() public noMatches: string;
    @Input() public initiallyFocused: boolean;

    @Output() public data = new EventEmitter<SelectItem[]>();
    @Output() public selected = new EventEmitter<SelectItem[]>();
    @Output() public removed = new EventEmitter<SelectItem>();
    @Output() public typed = new EventEmitter<string>();

    @Output() public closed = new EventEmitter<void>();
    @Output() public opened = new EventEmitter<boolean>();

    public onChange: () => void;

    public onTouched: () => void;

    public value: number | string;

    public displayValue: string;

    public _active: SelectItem[] = [];

    public trackById: TrackByFunction<any> = (index: number, item: SelectItem) => item?.id;

    @Input()
    public set filter(fn: (item) => boolean) {
        this._filter = fn;
    }

    @Input()
    public set active(items: SelectItem[]) {
        if(!isEqual(items, this._active)){
            this._active = items;
        }
    }

    public get active(): SelectItem[] {
        return this._active;
    }

    private subscription = new Subscription();

    public _filter = (item) => true;

    constructor() {
    }

    public setInputValue(value: string = '') {
        /* setTimeout(() => {
            const el = this.element.nativeElement.querySelector('div.ui-select-container > input');
            if (el) {
                el.value = value;
            }
        }, 0);
        */
        console.log('setInputValue: Refactoring needed');
    }

    public ngOnInit() {
        this.subscription.add(this.ngSelectComponent.closeEvent.asObservable().subscribe(() => {
            this.closed.emit();
        }));

        this.subscription.add(this.ngSelectComponent.openEvent.asObservable().subscribe(() => {
            this.opened.emit(true);
        }));

        this.subscription.add(this.ngSelectComponent.searchEvent.asObservable().subscribe((e) => {
            this.typed.emit(e.term);
        }));

        this.subscription.add(this.ngSelectComponent.changeEvent.asObservable().subscribe((e: SelectItem[]) => {
            this.selected.emit(e);
        }));

        this.subscription.add(this.ngSelectComponent.removeEvent.asObservable().subscribe((e: SelectItem) => {
            this.removed.emit(e);
        }));

        this.subscription.add(merge(
            this.typed.asObservable(),
            this.selected.asObservable(),
            this.removed.asObservable(),
        ).subscribe((_) => {
            this.data.emit(this._active);

            if (this.onChange) {
                this.onChange();
            }
        }));

        if (this.initiallyFocused) {
            this.ngSelectComponent.focus();
            this.ngSelectComponent.open();
        }
    }

    public onRemoveClick(item: SelectItem, e: Event) {
        e.preventDefault();

        this._active = this._active.filter((a) => {
            return item.id !== a.id;
        });

        this.removed.emit(item);
    }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public writeValue(values: string[]): void {
        if(values) {
            if (!Array.isArray(values)) {
                values = [values];
            }

            const mappedValues = values ? values.map((value) => {
                return this.items.find((item) => item.id === value);
            }) : [];

            if (!isEqual(this._active, mappedValues)) {
                this._active = mappedValues;
            }
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this._active.map((active) => active.id));
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return true;
    }

    public setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }
}
