<ngx-charts-chart
    [view]="[width, height]"
    [showLegend]="legend"
    [legendOptions]="legendOptions"
    [activeEntries]="activeEntries"
    [animations]="animations"
    (legendLabelActivate)="onActivate($event, undefined, true)"
    (legendLabelDeactivate)="onDeactivate($event, undefined, true)"
    (legendLabelClick)="onClick($event)"
>
    <svg:g [attr.transform]="transform" class="bar-chart chart">
        <svg:g
            ngx-charts-grid-panel-series
            [xScale]="valueScale"
            [yScale]="groupScale"
            [data]="results"
            [dims]="dims"
            [orient]="orient.Horizontal"
        ></svg:g>
        <svg:g
            ngx-charts-x-axis
            *ngIf="xAxis"
            [xScale]="valueScale"
            [dims]="dims"
            [showGridLines]="showGridLines"
            [showLabel]="showXAxisLabel"
            [labelText]="xAxisLabel"
            [trimTicks]="trimXAxisTicks"
            [rotateTicks]="rotateXAxisTicks"
            [maxTickLength]="maxXAxisTickLength"
            [tickFormatting]="xAxisTickFormatting"
            [ticks]="xAxisTicks"
            (dimensionsChanged)="updateXAxisHeight($event)"
        ></svg:g>
        <svg:g
            ngx-charts-y-axis
            *ngIf="yAxis"
            [yScale]="groupScale"
            [dims]="dims"
            [showLabel]="showYAxisLabel"
            [labelText]="yAxisLabel"
            [trimTicks]="trimYAxisTicks"
            [maxTickLength]="maxYAxisTickLength"
            [tickFormatting]="yAxisTickFormatting"
            [ticks]="yAxisTicks"
            [yAxisOffset]="dataLabelMaxWidth.negative"
            (dimensionsChanged)="updateYAxisWidth($event)"
        ></svg:g>
        <svg:g
            *ngFor="let group of results; let index = index; trackBy: trackBy"
            [@animationState]="'active'"
            [attr.transform]="groupTransform(group)"
        >
            <svg:g
                ngx-charts-series-horizontal
                [xScale]="valueScale"
                [activeEntries]="activeEntries"
                [yScale]="innerScale"
                [colors]="colors"
                [dims]="dims"
                [gradient]="gradient"
                [tooltipDisabled]="tooltipDisabled"
                [tooltipTemplate]="tooltipTemplate"
                [seriesName]="group.name"
                [roundEdges]="roundEdges"
                [animations]="animations"
                [showDataLabel]="showDataLabel"
                [dataLabelFormatting]="dataLabelFormatting"
                [noBarWhenZero]="noBarWhenZero"
                (select)="onClick($event, group)"
                (activate)="onActivate($event, group)"
                (deactivate)="onDeactivate($event, group)"
                (dataLabelWidthChanged)="onDataLabelMaxWidthChanged($event, index)"
            />
        </svg:g>
    </svg:g>
</ngx-charts-chart>
