import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, interval, Observable, Subject, Subscription } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import * as AdAction from '../../../../core/store/actions/ad.action';
import * as AdSelectors from '../../../../core/store/selectors/ad.selectors';
import * as AuthSelectors from '../../../../core/store/selectors/auth.selectors';
import * as ConfigSelectors from '../../../../core/store/selectors/config.selectors';
import * as ProductDetailsSelectors from '../../../../routes/product-details/store/selectors/product-details.selectors';
import { Ad, Polling } from '../../../../core/store/state/ad.state';
import { truthy } from '../../../helpers/general.helper';
import * as RouterSelectors from '../../../../core/store/selectors/router.selectors';
import { AdContentComponent } from '../ad-content/ad-content.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-ad-for-overlay',
    templateUrl: './ad-for-overlay.component.html',
    styleUrls: ['./ad-for-overlay.component.scss'],
    standalone: true,
    imports: [NgIf, AdContentComponent, AsyncPipe]
})
export class AdForOverlayComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  private polling$: Observable<Polling>;

  private stopPolling$ = new Subject<boolean>();

  public ad$: Observable<Ad>;

  constructor(private store: Store) { }

  public ngOnInit(): void {
    this.ad$ = this.store.select(AdSelectors.selectAdData);
    this.polling$ = this.store.select(AdSelectors.selectPolling);

    this.subscriptions.push(
      combineLatest([
        this.store.select(ConfigSelectors.selectShowAdvertising).pipe(
          filter(truthy)
        ),
        this.store.select(AuthSelectors.selectAuthUserId).pipe(
          filter(truthy)
        ),
        this.store.select(RouterSelectors.selectOverlayContext).pipe(
          filter(truthy)
        ),
        this.store.select(ProductDetailsSelectors.selectProductDq).pipe(
          filter(truthy)
        )
      ]).subscribe(() =>
        this.store.dispatch(
          AdAction.activatePolling()
        )
      )
    );

    this.subscriptions.push(
      this.polling$.pipe(
        filter((polling) => polling.active),
        switchMap((polling) => interval(polling.interval).pipe(
          takeUntil(this.stopPolling$)
        ))
      ).subscribe(() =>
        this.store.dispatch(
          AdAction.loadAd()
        )
      )
    );

    this.subscriptions.push(
      this.polling$.pipe(
        filter((polling) => !polling.active),
        tap(() => this.stopPolling$.next())
      ).subscribe()
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) =>
      subscription.unsubscribe()
    );
  }
}
