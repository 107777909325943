import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { forOwn, get, has, isPlainObject } from 'lodash';
import { Entity } from '../../../models/entities.model';
import {EntityWidgetConfig, EntityWidgetOptions, EntityWidgetSheetOptions} from '../../../models/widgets.model';
import { GenericActionStructure, EntityActionsComponent } from '../../../components/entity-actions/entity-actions.component';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { EntityTableComponent } from '../../../components/entity-table/entity-table.component';
import { NgIf } from '@angular/common';
import { EntityDetailsTableComponent } from '../../../components/entity-details-table/entity-details-table.component';

@Component({
    selector: 'app-entity-widget-product-promotion-express',
    templateUrl: './entity-widget-product-promotion-express.component.html',
    styleUrls: ['./entity-widget-product-promotion-express.component.scss'],
    standalone: true,
    imports: [EntityDetailsTableComponent, NgIf, EntityTableComponent, EntityActionsComponent]
})
export class EntityWidgetProductPromotionExpressComponent implements OnInit, OnChanges, EntityWidgetContentComponent {

    @HostBinding('class.entity-widget-product-promotion') public entityWidgetProductPromotionClass = true;

    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public options: EntityWidgetOptions;
    @Input() public data: Entity;
    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();

    constructor() {

    }

    public ngOnChanges(changes: SimpleChanges): void {

    }

    public ngOnInit() {
    }

    public getEntityIdInObj(obj: any) {
        if (!obj) { return null; }

        if (has(obj, 'dq')) {
            return obj.dq;
        }

        if (has(obj, '_id')) {
            return obj._id;
        }

        const keys = Object.keys(obj);
        for (const key of keys) {
            if (isPlainObject(obj[key])) {
                return this.getEntityIdInObj(obj[key]);
            }
        }

        return null;
    }

    public tryToFindEntityId(action: GenericActionStructure) {
        if (action.pathToId) {
            return get(this.data, action.pathToId);
        }

        if (this.entityId) { return this.entityId; }

        // now we need to get our hands dirty
        // let's look into data and try to find any property that corresponds to dq or _id
        // example dq: QP00DE000PZ9RA34
        let objId = this.getEntityIdInObj(this.data);

        // if we still did not find anything, we will try to find a DQ id in the path property
        // example path: cms.product.promotion.express.QP00DE000PZ9RA34
        if (!objId && this.options && this.options.path) {
            objId = /(Q[A-Z0-9]+)/i.exec(this.options.path)[1];
        }

        return objId;
    }

    public onAction(action: GenericActionStructure) {
        this.action.next({
            type: action.type,
            payload: {
                dq: this.tryToFindEntityId(action),
                linkType: action.linkType,
                link: action.link,
            },
        });
    }
}
