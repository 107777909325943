import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
    loadAuthUser,
    refreshToken,
    signIn,
    signInRedirectToken,
    signOut,
    validateToken,
    resetRefreshToken, resetAccessToken,
} from '../store/actions/auth.action';
import { AppState } from '../store/state/index.state';

@Injectable()
export class AuthService {

    constructor(private readonly store: Store<AppState>) {
    }

    /** Actions **/

    public signIn(username: string, password: string, returnUrl?: string) {
        this.store.dispatch(signIn(username, password, returnUrl));
    }

    public signOut(redirectToLogin: boolean = false, returnUrl?: string) {
        this.store.dispatch(signOut(redirectToLogin, returnUrl));
    }

    public signInViaRedirectToken(redirectToken: string) {
        this.store.dispatch(signInRedirectToken(redirectToken));
    }

    public validateToken() {
        this.store.dispatch(validateToken());
    }

    public loadAuthUser() {
        this.store.dispatch(loadAuthUser());
    }

    public resetRefreshToken() {
        this.store.dispatch(resetRefreshToken());
    }

    public resetAccessToken() {
        this.store.dispatch(resetAccessToken());
    }

    /** Selectors **/

    public getSignInError(): Observable<string> {
        return this.store.pipe(
            select((state) => state.auth.signInError),
        );
    }

    public isSigningIn(): Observable<boolean> {
        return this.store.pipe(
            select((state) => state.auth.signingIn),
        );
    }

}
