<table class="table entity-details-table__table">
    <tbody>
        <tr *ngFor="let field of fields" [class.entity-details-table__table__separator]="isSeparatorRow(field)">
            <th class="entity-details-table__line-label" [attr.colspan]="isSeparatorRow(field) ? 2 : 1">{{field.label}}</th>

            <ng-container *ngIf="!isSeparatorRow(field)">
                <ng-container [ngSwitch]="field.decorator">
                    <ng-container *ngSwitchCase="'composite'">
                        <td
                            class="entity-details-table-cell entity-table-cell--composite"
                            [class.entity-table-cell--composite-horizontal]="field.decoratorOptions?.horizontal">
                            <ng-container *ngFor="let line of field.decoratorOptions?.lines; let last = last;">
                                <div appEntityTableCell [decorator]="line.decorator" [decoratorOptions]="line.decoratorOptions"
                                    [entity]="entity" [path]="line.path"></div>

                                <ng-container *ngIf="field.decoratorOptions.separator && !last">{{field.decoratorOptions.separator}}</ng-container>
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container *ngSwitchCase="'list'">
                        <td [class.entity-table-cell--composite-horizontal]="field.decoratorOptions.horizontal">
                            <ng-container *ngFor="let value of entityTableDecorator.getDataFromPath(entity, field.path); let i = index; let last = last;">
                                <div
                                    appEntityTableCell
                                    [entity]="entity"
                                    [path]="field.path + '.' + i"
                                    [decorator]="field.decoratorOptions.field.decorator"
                                    [decoratorOptions]="field.decoratorOptions.field.decoratorOptions"></div>

                                <ng-container *ngIf="field.decoratorOptions.separator && !last">{{ field.decoratorOptions.separator }}</ng-container>
                            </ng-container>
                        </td>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <td
                            appEntityTableCell
                            classPrefix="entity-details-table-cell"
                            [decorator]="field.decorator"
                            [decoratorOptions]="field.decoratorOptions"
                            [entity]="entity"
                            [path]="field.path"></td>
                    </ng-container>
                </ng-container>
            </ng-container>
        </tr>
    </tbody>
</table>
