<div class="row">
    <div class="col">
        <app-entity-details-table [fields]="options?.productInformationFields" [entity]="data?.productInformation"></app-entity-details-table>
    </div>
    <div class="col">
        <app-entity-table *ngIf="options?.strikeTableFields && data?.strikes"
            [config]="{ columns: options?.strikeTableFields }"
            [stickyHeader]="false"
            [entities]="data?.strikes"
        ></app-entity-table>

        <div class="entity-widget-product-promotion__content" *ngIf="data">
            {{data.description}}
        </div>
        <div class="entity-widget-product-promotion__action">
            <app-entity-actions [genericActionsStructure]="options?.actions"
                                (actionClicked)="onAction($event)"></app-entity-actions>
        </div>
    </div>
</div>

