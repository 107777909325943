import { flatten } from 'flat';
import { get, includes, isArray, isString, omit, pick, memoize } from 'lodash';
import * as moment from 'moment';
import { isMomentHoliday } from '../../../../core/helpers/holidays.helper';
import { AdvancedCondition, FormControlConfig, FormStructureElementConfig, FormValues } from '../../../../core/models/form.model';
import { ComponentDataType, ComponentToDataTypeMap, ComponentType } from '../models/product-designer-components.model';
import {
    LifecycleStateType, OfferResponse, OfferResponseState,
    Product,
    ProductComponent,
    ProductComponentStructure,
    ProductComponentStructureWithMeta,
    ProductGroupStructure,
    ProductRequestParameters,
    productRequestValues,
    ProductResponse,
    ProductStructure,
} from '../models/product-designer.model';

export const PRIVATE_DATA_PATH = '__data';

export function getComponentStructure(structure: ProductStructure, componentName: string): ProductComponentStructure {
    return structure.groups.reduce((acc, curr) => {
        const component = curr.components.find((component) =>
            component.name === componentName
        );
        
        return component ? { ...component, __group: curr } : acc;
    }, null as any);
}

export function getDataByComponent<Type extends keyof ComponentToDataTypeMap>(product: Product = {}, type: Type): ComponentDataType<Type>[] {
    return Object.keys(product).reduce((acc, k) => {
        return [
            ...acc,
            ...product[k].filter((c) => c.type === String(type)),
        ];
    }, []);
}

export function getComponentRestrictionForField(component: ProductComponentStructure, field: string) {
    if (component.fieldRestrictions && component.fieldRestrictions.fields) {
        return component.fieldRestrictions.fields.find((f) => f.name === field);
    }

    return null;
}

export function isFieldHidden(field: string, structure: ProductComponentStructure) {
    const restriction = getComponentRestrictionForField(structure, field);

    return restriction && restriction.hidden;
}

export function hasGroupComponentOfType(group: ProductGroupStructure, ...types: string[]): boolean {
    return group.components.some((component) => types.includes(component.name));
}

export function hasDataForComponent(product: Product = {}, type: string): boolean {
    const matches = getDataByComponent(product, ComponentType[type]);

    return !!(matches && matches.length);
}

export function hasDataForComponentType<Type extends keyof ComponentToDataTypeMap>(product: Product = {}, type: Type): boolean {
    const matches = getDataByComponent(product, type);

    return !!(matches && matches.length);
}

export function getDataByFirstComponent<Type extends keyof ComponentToDataTypeMap>(product: Product = {}, type: Type, defaultValue = {}): ComponentDataType<Type> {
    const matches = getDataByComponent(product, type);
    const data = matches && matches.length && matches[0];
    let result = data;

    if (result && result[PRIVATE_DATA_PATH]) {
        result = cleanUpProductComponentData(result as ProductComponent);
    }

    return result || defaultValue;
}

export function getDataByFirstComponentProperty<Type extends keyof ComponentToDataTypeMap, K extends keyof ComponentDataType<Type>>(
    product: Product = {},
    type: Type,
    property: string,
): ComponentDataType<Type>[K] {
    const data = getDataByFirstComponent(product, type);
    let result = null;

    if (data) {
        result = property ? data[property] : omit(data, 'type');
    }

    return result;
}

export function getBarrierValue(product: Product = {}): number | null {
    const capitalProtectionData = getDataByFirstComponent(product, ComponentType.CAPITAL_PROTECTION);

    if (capitalProtectionData) {
        const data = capitalProtectionData;

        if (data.mode === 'CAPITAL_PROTECTION') {
            return data.capitalGuarantee > 1 ? data.capitalGuarantee / 100 : data.capitalGuarantee;
        } else if (data.mode === 'BARRIER') {
            return +(data.barrier);
        }
    }

    return null;
}

export function getCouponValue(product: Product = {}): number | null {
    const autoCallCouponData = getDataByFirstComponent(product, ComponentType.AUTOCALL_COUPON, null);

    if (autoCallCouponData) {
        return +autoCallCouponData.coupon;
    }

    return null;
}

export function getAllControlsOfComponentStructure(structure: ProductComponentStructure) {
    return structure.form.sections.reduce((controls2, section) => {
        return controls2.concat(section.columns.reduce((controls3, column) => {
            return controls3.concat(column.controlGroups.reduce((controls4, controlGroup) => {
                return [...controls4, ...controlGroup.controls.map((c) => {
                    return {
                        ...c,
                        _parent: controlGroup,
                    };
                })];
            }, []));
        }, []));
    }, []);
}

export function getProductRequestColumns(productRequest: ProductRequestParameters, productData: Product, productResponse?: ProductResponse, hasManualIssuers?: boolean, hideComments?: boolean) {
    let columns = [];

    const varianceInformationDTO = productResponse && (productResponse.requestDetails.varianceInformationDTO || productResponse.requestDetails.varianceInformation);
    const varianceInformation = varianceInformationDTO && varianceInformationDTO.variances && varianceInformationDTO.variances[0];

    if (varianceInformation && varianceInformation.totalSteps > 0) {
        let validResponse = Object.values(productResponse.responses).find((respGroup) => {
            const autoIssuer = respGroup.find((resp) => resp.issuerInformation.issuerType === 'AUTO' && !!resp.variance && resp.variance.componentDiff.length);

            return !!autoIssuer;
        });

        if (validResponse) {
            validResponse = [...validResponse].sort((a, b) =>
                a.variance.step - b.variance.step
            );

            columns = validResponse.map((res, index) => {
                const componentDiff = res.variance.componentDiff[0];

                return {
                    l: componentDiff.value,
                    formatPercent: true,
                    v: componentDiff.value,
                    i: index,
                };
            });
        }
    } else {
        if (hasManualIssuers && !hideComments) {
            columns.push({l: 'Kommentar', v: 0, i: 1, simpleText: true});
        }

        columns.push({l: 'Angebot', v: 0, i: 0});
    }

    return columns;
}

export function getValuesForProductRequest(productRequest: ProductRequestParameters) {
    if (!productRequest) {
        return null;
    }

    const productOfferType = productRequest.offerType;
    const mappedOfferType = productRequestValues.offerTypes.find((o) => o.id === productOfferType);
    let offerType = null;

    if (mappedOfferType) {
        offerType = mappedOfferType ? mappedOfferType.label : null;
    }

    let issuers = productRequest.issuers;
    if (!issuers || !Array.isArray(issuers)) {
        issuers = [];
    }

    return {
        createdAt: productRequest.createdAt,
        offerType,
        awaitUntil: productRequest.awaitResponseUntil,
        issuers: issuers.map((e) => {
            const ee = productRequestValues.emittents.find((v) => v.id === e);
            return ee;
        }).filter((e) => !!e),
        expectedSalesVolume: productRequest.expectedSalesVolume,
        secureCondition: productRequest.secureCondition,
        notes: productRequest.notes,
    };
}

/*
Wochenende: Tag vorher (Freitag)
Feiertage: Tag nachher
 */
export function getMaturityTable(
    value: Partial<{
        strike: number,
        maturityCount: number,
        maturityRule: string,
        stepDown: number,
        lastStrike: number,
        coupon?: number,
        amount?: number,
        nonCallablePeriods?: number,
    }>,
    valueFields = ['coupon', 'value', 'strike', 'amount', 'autocallable'],
    productData?: Product,
) {
    const table = [];
    const strikeFixingDate = productData ? getControlValue(productData, {}, 'PRODUCT_COMMONS/strikeFixing') : null;
    const currentDate = strikeFixingDate ? moment(strikeFixingDate, 'YYYY-MM-DD').utc(false) : moment().utc(false);

    const strike = value.strike || 0;
    const stepDown = value.stepDown || 0;
    const maturityCount = value.maturityCount || 0;
    const lastStrike = (value.lastStrike === null ? null : +value.lastStrike);
    const nonCallablePeriods = value.nonCallablePeriods || 0;

    let currentDay: number;

    for (let i = 1; i <= value.maturityCount; i++) {
        if (i === 1) {
            currentDay = currentDate.date();
        }

        switch (value.maturityRule) {
            case 'ANNUALLY':
                currentDate.set('y', currentDate.get('y') + 1);
                currentDate.set('date', currentDay);
                break;
            case 'SEMIANNUALLY':
                currentDate.add(6, 'M');
                break;
            case 'QUARTERLY':
                currentDate.add(1, 'Q');
                break;
            case 'MONTHLY':
                currentDate.add(1, 'M');
                break;
        }

        const updatedDate = moment(currentDate);

        while (isMomentHoliday(updatedDate)) {
            updatedDate.add(1, 'd');
        }

        while (updatedDate.day() === 5 || updatedDate.day() === 6 || isMomentHoliday(updatedDate)) {
            updatedDate.add(1, 'd');
        }

        let currentValue = null;

        if (nonCallablePeriods < i) {
            currentValue = (i === maturityCount)
                ? lastStrike === null ? Math.max(0, strike - stepDown * (i - nonCallablePeriods - 1)) : (+lastStrike || 0)
                : Math.max((+lastStrike || 0), strike - stepDown * (i - nonCallablePeriods - 1));
        }

        const rowValue = {
            date: updatedDate.toDate(),
            value: currentValue,
            strike: currentValue,
            coupon: value.coupon || 0,
            amount: value.amount || 0,
            autocallable: nonCallablePeriods < i,
        };

        for (const f of Object.keys(rowValue)) {
            if (f === 'date') {
                continue;
            }

            if (!valueFields.includes(f)) {
                delete rowValue[f];
            }
        }

        table.push(rowValue);
    }

    return table;
}

export function getControlValue(product: Product, controlData: FormValues, target: string) {
    const chunks = target.split('/');
    let dataPath = chunks[0];
    let thisData = controlData;

    if (chunks.length > 1) {
        dataPath = chunks[1];

        const branch = chunks[0].split(':');
        const ctype = branch[0];
        const cid = branch[1];

        let data: any[] = getDataByComponent(product, ComponentType[ctype]);

        if (cid) {
            data = data.filter((d) => d.id && d.id === cid);
        }

        thisData = data.length ? data[0] : {};

        if (thisData.hasOwnProperty(PRIVATE_DATA_PATH)) {
            thisData = thisData[PRIVATE_DATA_PATH];
        }
    }

    return get(flatten(thisData || {}), dataPath);
}

export function getValidMaxOccuranceValue(value: any) {
    return value !== undefined && value !== null ? +value : -1;
}

export function evaluateCardinalityForComponent(product: Product, component: ProductComponentStructure) {
    const componentDatas: any[] = [
        ...getDataByComponent(product, component.name as any),
    ];

    if (component.transformation && Array.isArray(component.transformation.targetComponents)) {
        component.transformation.targetComponents.forEach((targetComponent) => {
            componentDatas.push(...getDataByComponent(product, targetComponent.name as any));
        });
    }

    const componentData = componentDatas[0] || {};

    let minOccurance = component.minOccurance || 0;
    let maxOccurance = getValidMaxOccuranceValue(component.maxOccurance);

    if (component.conditionalCardinality && Array.isArray(component.conditionalCardinality)) {
        component.conditionalCardinality.forEach((cardinality) => {
            const isValid = evaluateAdvancedFormConditions(product, componentData, cardinality.conditions);

            if (isValid) {
                minOccurance = Math.max(minOccurance, cardinality.minOccurance);

                if (maxOccurance === -1 && cardinality.maxOccurance !== -1) {
                    maxOccurance = cardinality.maxOccurance;
                } else {
                    maxOccurance = Math.min(maxOccurance, cardinality.maxOccurance);
                }
            }
        });
    }

    let state = 0;

    if (minOccurance && componentDatas.length < +minOccurance) {
        state = -1;
    } else if ((maxOccurance !== undefined || maxOccurance !== null) && maxOccurance !== -1 && componentDatas.length > +maxOccurance) {
        state = 1;
    }

    return {
        state,
        minOccurance,
        maxOccurance,
        diff: state < 0 ? Math.abs(minOccurance - componentDatas.length) : Math.abs(maxOccurance - componentDatas.length),
    };
}

export function evaluateAdvancedFormConditions(product: Product, controlData: FormValues, advancedConditions: AdvancedCondition[]) {
    let isValid = true;

    advancedConditions.forEach((condition, i) => {
        let controlValue = null;

        switch (condition.type) {
            case 'TARGET_VALUE':
                controlValue = getControlValue(product, controlData, 'TARGET_VALUE/target');
                break;
            case 'HAS_VALUE':
                controlValue = getControlValue(product, controlData, condition.target);

                break;
            case 'PD_COMPONENT_COUNT':
                controlValue = getDataByComponent(product, ComponentType[condition.target]).length;
                break;
            default:
                break;
        }

        let conditionEvaluation = true;

        switch (condition.relation) {
            case 'EQ': {
                const conditionValue = isString(condition.value) ? condition.value : +condition.value;
                conditionEvaluation = (controlValue === conditionValue);
                break;
            }
            case 'NEQ': {
                const conditionValue = isString(condition.value) ? condition.value : +condition.value;
                conditionEvaluation = (controlValue !== conditionValue);
                break;
            }
            case 'GTQ':
                conditionEvaluation = (controlValue >= +condition.value);
                break;
            case 'LTQ':
                conditionEvaluation = (controlValue <= +condition.value);
                break;
            case 'LT':
                conditionEvaluation = (controlValue < +condition.value);
                break;
            case 'GT':
                conditionEvaluation = (controlValue > +condition.value);
                break;

            case 'IN':
                conditionEvaluation = includes(condition.value, controlValue);
                break;
            case 'NIN':
                conditionEvaluation = !includes(condition.value, controlValue);
        }

        if (condition.logic === 'AND' || !condition.logic || i === 0) {
            isValid = (isValid && conditionEvaluation);
        } else {
            isValid = (isValid || conditionEvaluation);
        }
    });

    return isValid;
}

export function isControlVisible(control: FormControlConfig | FormStructureElementConfig, rawValue: FormValues, fullProductData: Product) {
    if (control.options && control.options.advancedConditions) {
        return evaluateAdvancedFormConditions(fullProductData, rawValue, control.options.advancedConditions);
    }

    if (control.options && control.options.conditionPath && control.options.conditionValues) {
        const conditionValue = get(flatten(rawValue || {}), control.options.conditionPath);
        return includes(control.options.conditionValues, conditionValue);
    } else {
        return true;
    }
}

export function isControlVisibleInConditionalTree(control: FormControlConfig | FormStructureElementConfig, rawValue: FormValues, fullProductData: Product) {
    if (!isControlVisible(control, rawValue, fullProductData)) {
        return false;
    }

    // check recursively upstream, if parents are hidden. That would also hide the child controls.
    if (control._parent && !isControlVisibleInConditionalTree(control._parent, rawValue, fullProductData)) {
        return false;
    }

    // control dependencies can also be sidewards (sibilings), so we have to also check the visibility recursively sidestream
    if (control.options && control.options.conditionPath && control.options.conditionValues) {
        const filterControls = this.getFilterControls();
        const conditionalControl = filterControls.find((c) => c.path === control.options.conditionPath);

        return conditionalControl ? isControlVisibleInConditionalTree(conditionalControl, rawValue, fullProductData) : true;
    } else {
        return true;
    }
}

export function isProductStructure(struct: ProductStructure | ProductComponentStructure): struct is ProductStructure {
    return struct && (struct as ProductStructure).groups !== undefined;
}

export const getControlForComponentField = memoize((structure: ProductStructure | ProductComponentStructure, componentType: ComponentType | string, field: string, addParentReferences = false) => {
    const componentStructure = isProductStructure(structure) ? getComponentStructure(structure, componentType) : structure;
    if(!componentStructure) return null;

    let foundControl = null;

    componentStructure.form.sections.forEach((section) => {
        if (addParentReferences) {
            section._parent = componentStructure.form;
        }

        section.columns.forEach((column) => {
            if (addParentReferences) {
                column._parent = section;
            }

            column.controlGroups.forEach((controlGroup) => {
                if (addParentReferences) {
                    controlGroup._parent = column;
                }

                controlGroup.controls.forEach((control) => {
                    if (addParentReferences) {
                        control._parent = controlGroup;
                    }

                    if (control.path === field || control.__hadEmptyPath) {
                        foundControl = control;
                        return false;
                    }
                });
            });
        });
    });

    return foundControl;
});

export function getDataPathForControl(control: FormControlConfig) {
    if (!control || !control.path) {
        return PRIVATE_DATA_PATH;
    }

    return control.path;
}

export function getProductDataByComponents(values: ProductRequestParameters, structure: ProductStructure) {
    const data = {};

    const components = [
        {
            type: 'TARGET_VALUE',
            target: values.solveTarget,
        },
        ...values.components,
    ];

    components.forEach((component) => {
        const componentStructure = getComponentStructure(structure, component.type);

        if (componentStructure && componentStructure.__group) {
            const componentData = prepareComponentStructureAndData(componentStructure, component);

            data[componentStructure.__group.name] = data[componentStructure.__group.name] || [];
            data[componentStructure.__group.name].push({
                ...componentStructure.initial,
                ...componentData,
            });
        }

    });

    return data;
}

export function prepareComponentStructureAndData(structure: ProductComponentStructure, data: ProductComponent) {
    let componentData = {};
    if (structure) {
        componentData = {...data};

        const structWithMeta = prepareComponentStructure(structure);
        componentData = cleanupComponentDataWithStructureMeta(structWithMeta, data);
    }

    return componentData;
}

export function prepareComponentStructure(structure: ProductComponentStructure): ProductComponentStructureWithMeta {
    const allPaths = [];
    let hasEmptyPathControl = false;

    structure = { ...structure, form: {
        ...structure.form,
        sections: [...structure.form.sections].map((section) => ({
            ...section, columns: section.columns.map((column) => ({
                ...column, controlGroups: column.controlGroups.map((controlGroup) => ({
                    ...controlGroup, controls: controlGroup.controls.map((control) => {
                        if ((!control.path && !control.options.path) || control.__hadEmptyPath || control.path === PRIVATE_DATA_PATH) {
                            hasEmptyPathControl = true;
                            return { ...control, __hadEmptyPath: true };
                        } else {
                            allPaths.push(control.path || control.options.path);
                        }
                    })
                }))
            }))
        }))
    }};

    return {
        structure,
        hasEmptyPathControl,
        allPaths
    };
}

export function cleanupComponentDataWithStructureMeta(structMeta: ProductComponentStructureWithMeta, data: ProductComponent, source?: ProductComponent) {
    const hasEmptyPathControl = structMeta.hasEmptyPathControl;
    const allPaths = structMeta.allPaths;

    if (data.type === 'AUTOCALL_PAYMENT_MATURITY' && source) {
        Object.assign(data, pick(source, 'barrier', 'memory'));
    }

    if (hasEmptyPathControl) {
        const privateData = omit(data, 'type', ...allPaths);

        return {
            type: data.type,
            ...omit(data, ...Object.keys(privateData)),
            [PRIVATE_DATA_PATH]: privateData,
        };
    }

    return data;
}

export function cleanUpProductComponentData(data: ProductComponent) {
    const copy = {
        ...(omit(data, PRIVATE_DATA_PATH)),
        ...(data[PRIVATE_DATA_PATH] || {}),
    };

    return copy;
}

export function getComponentsFromProductData(product: Product) {
    return Object.keys(product).reduce((arr, k) => {
        return [
            ...arr,
            ...(
                product[k].reduce((sub, c) => {
                    const res = [...sub];

                    if (c.type !== 'TARGET_VALUE') {
                        const copy = {
                            ...(omit(c, PRIVATE_DATA_PATH)),
                            ...(c[PRIVATE_DATA_PATH] || {}),
                        };

                        res.push(copy);
                    }

                    return res;
                }, [])
            ),
        ];
    }, []);
}

export function creatProductRequestObject(product: Product, requestData: ProductRequestParameters, forValidation = false): ProductRequestParameters {
    const components = getComponentsFromProductData(product);
    let request: ProductRequestParameters = {
        components,
        offerType: 'INDICATIVE',
        awaitUntil: requestData.awaitUntil || requestData.awaitResponseUntil,
    };

    if (forValidation) {
        request = {
            ...request,
            ...pick(requestData, 'offerType', 'solveTarget', 'productType', 'varianceInformation'),
            issuers: [],
        };
    } else {
        let varianceInfo = null;

        if (requestData.offerType === 'MATRIX' && requestData.matrixPricing !== 'NONE') {
            varianceInfo = {
                variances: [{
                    type: requestData.matrixPricing,
                    totalSteps: requestData.matrixPricingSteps,
                    value: requestData.matrixPricingValue,
                }],
            };
        }

        request = {
            ...request,
            ...omit(
                requestData,
                'matrixPricing', 'matrixPricingData', 'matrixPricingSteps',
                'matrixPricingValue', 'components', 'awaitUntil',
                'awaitResponseUntil', 'changed'
            ),
            // varianceInformation: varianceInfo,
            notes: isArray(requestData.notes) ? requestData.notes : [requestData.notes],
        };
    }

    return request;
}

export function getVarianceInfoFromProductRequest(requestData: ProductRequestParameters) {
    const varianceDTO = requestData.varianceInformationDTO || requestData.varianceInformation;
    if (varianceDTO && varianceDTO.variances && varianceDTO.variances.length) {
        return varianceDTO.variances[0];
    }

    return null;
}

export function getTargetValue(productData: Product) {
    const targetData = getDataByFirstComponent(productData, ComponentType.TARGET_VALUE);

    if (!targetData) {
        return null;
    }

    return targetData.target;
}

export function isQuoteInSomeState(currentQuote: ProductResponse, ...states: LifecycleStateType[]) {
    if (currentQuote) {
        return states.some((state) => state === currentQuote.lifecycle.currentState.state);
    }

    return false;
}

export function isQuoteNotInState(currentQuote: ProductResponse, ...states: LifecycleStateType[]) {
    if (currentQuote) {
        return states.every((state) => state !== currentQuote.lifecycle.currentState.state);
    }

    return true;
}

export function isResponseInSomeState(response: OfferResponse<any>, ...states: OfferResponseState[]) {
    if (response) {
        return states.some((state) => state === response.state);
    }

    return false;
}

export function isResponseNotInState(response: OfferResponse<any>, ...states: OfferResponseState[]) {
    if (response) {
        return states.every((state) => state !== response.state);
    }

    return true;
}

