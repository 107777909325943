import { Injector } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { isString } from 'lodash';

export class ObjectMaker {
    public static angularInjector: Injector = null;
    public static language = '';
    public static translocoService: TranslocoService = null;

    public static translate(key: string) {
        if (!ObjectMaker.translocoService && ObjectMaker.angularInjector) {
            ObjectMaker.translocoService = ObjectMaker.angularInjector.get(TranslocoService);
        }

        if (ObjectMaker.translocoService) {
            return ObjectMaker.translocoService.translate(key);
        }

        return '';
    }

    public static toJson(key: string, config: any = null, values: any = []) {
        const message = config && config.message && isString(config.message) ? config.message : null;
        let messageKey;
        if (!message && config && config.messageKey) {
            messageKey = config.messageKey;
        }
        let messageText = (message) ? message : ObjectMaker.translate(`validationMessages.${key}`);
        values.forEach((t, index) => {
            messageText = messageText.replace(`{{${index}}}`, t);
        });
        const jObject = {};
        jObject[key] = {
            message: messageText, refValues: values,
        };
        if (config && config.isAddMessageKey) {
            jObject[key]['messageKey'] = messageKey;
        }
        return jObject;
    }

    public static null() {
        return null;
    }
}
