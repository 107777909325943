import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { AuthStoreService } from '../services/auth-store.service';

/**
 * Guard class that prevents authenticated users from accessing public facing parts of the application
 */
@Injectable()
export class NoAuthGuard  {
    constructor(
        private authStoreService: AuthStoreService,
        private router: Router,
    ) {
    }

    /**
     * Guard method called when a route is requested to be activated
     */
    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authStoreService.isRefreshTokenStillValid(next.queryParams).pipe(
            take(1),
            map((isValid) => {
                if (isValid || state.root.paramMap.has('redirectToken')) {
                    this.router.navigate(['/app']);
                }

                return !isValid;
            }),
        );
    }
}
