<div class="row">
    <div class="col-12 order-last order-xl-first" [class.col-xl-12]="!enableProvisionToggle" [class.col-xl-8]="enableProvisionToggle">
        <div class="app-filter-radio-button-group">
            <mat-button-toggle-group [value]="activeFilter" (change)="onActiveFilterChange($event)">
                <mat-button-toggle *ngFor="let value of filters" [value]="value" disableRipple="true">
                    {{ value.label }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>

    <div class="col-12 col-xl-4 order-first order-xl-last" style="text-align: right" *ngIf="enableProvisionToggle">
        <label class="custom-control custom-switch">
            <input class="custom-control-input" type="checkbox" [ngModel]="showProvisioned" (ngModelChange)="showProvisionedChanged($event)">
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">Provisioniert</span>
        </label>
    </div>
</div>

<ng-container *ngIf="filteredData$ | async as filteredData; else noDataAvailable">
    <div *ngIf="filteredData.length; else: noDataAvailable">
        <ul class="entity-widget-top-trades__list">
            <li *ngFor="let trade of filteredData; let i=index">
                <app-entity-widget-top-trades-item [trade]="trade"></app-entity-widget-top-trades-item>
            </li>
        </ul>
    </div>
</ng-container>

<ng-template #noDataAvailable>
    <div style="text-align: center; padding: 2rem">
        Keine Daten für die gewählten Filter vorhanden.
    </div>
</ng-template>
