import { BondPayoutValue } from '../../../product-designer/components/filter-pd-bond-payout/filter-pd-bond-payout.interface';
import { MultiRequestFieldStructure } from './product-designer.model';

export enum ComponentType {
    TARGET_VALUE = 'TARGET_VALUE',
    PRODUCT_COMMONS = 'PRODUCT_COMMONS',
    PRICE_FIXING = 'PRICE_FIXING',
    SUBSCRIPTION_PHASE = 'SUBSCRIPTION_PHASE',
    ISSUE_DATE = 'ISSUE_DATE',
    PRODUCT_CURRENCY = 'PRODUCT_CURRENCY',
    QUANTO = 'QUANTO',
    NOTIONAL = 'NOTIONAL',
    SETTLEMENT = 'SETTLEMENT',
    WRAPPING_DETAILS = 'WRAPPING_DETAILS',
    COMBINED_WEIGHTED_UNDERLYING = 'COMBINED_WEIGHTED_UNDERLYING',
    UNDERLYING_EXTENDED = 'UNDERLYING_EXTENDED',
    CAPITAL_GUARANTEE = 'CAPITAL_GUARANTEE',
    BARRIER = 'BARRIER',
    AIRBAG = 'AIRBAG',
    FINAL_MATURITY = 'FINAL_MATURITY',
    CAPITAL_PROTECTION = 'CAPITAL_PROTECTION',
    STRIKE = 'STRIKE',
    COUPON = 'COUPON',
    PAYMENT = 'PAYMENT',
    AUTOCALL_COUPON = 'AUTOCALL_COUPON',
    AUTOCALL_COUPON_EXTENDED = 'AUTOCALL_COUPON_EXTENDED',
    MARGIN = 'MARGIN',
    PRODUCT_PRICE = 'PRODUCT_PRICE',
    AUTOCALL_PAYMENT = 'AUTOCALL_PAYMENT',
    AUTOCALL_PAYMENT_MATURITY = 'AUTOCALL_PAYMENT_MATURITY',
    PAYMENT_MATURITY = 'PAYMENT_MATURITY',
    CAP = 'CAP',
    STRIKE_COUPON = 'STRIKE_COUPON'
}

export type ComponentToDataTypeMap = {
    [ComponentType.PRODUCT_COMMONS]: ProductCommonsValue,
    [ComponentType.CAPITAL_PROTECTION]: CapitalProtectionValue,
    [ComponentType.AUTOCALL_COUPON]: AutocallCouponValue,
    [ComponentType.TARGET_VALUE]: TargetValue,
    [ComponentType.AUTOCALL_COUPON_EXTENDED]: AutocallCouponFilterExtendedValue,
    [ComponentType.COMBINED_WEIGHTED_UNDERLYING]: any,
    [ComponentType.UNDERLYING_EXTENDED]: UnderlyingExtendedValue,
    [ComponentType.PAYMENT_MATURITY]: MaturityAutocallExtendedValue,
};

export type ComponentDataType<CType extends keyof ComponentToDataTypeMap> = Partial<ComponentToDataTypeMap[CType]>;

export interface TargetValue {
    target: string;
}

export interface ProductCommonsValue {
    price: number;
    strikeFixing: string;
    nominal: string | number;
    currency: string;
    subscriptionPhase: boolean;
    subscriptionStart: string;
    subscriptionEnd: string;
    quanto: boolean;
    physical: boolean;
    wrapping: string;
}

export interface PayoutsTableRow {
    date: Date;
    coupon: number;
}

export interface CapitalProtectionValue {
    mode: string;
    capitalGuarantee: number;
    barrier: number;
    observation: string;
    observationInterval: string;
    observationStart: string;
    observationEnd: string;
    airbag: number;
    addAirbag: boolean;
}

export interface AutocallBaseValue {
    maturityRule?: string;
    maturityCount?: number;
    strike?: number;
    stepDown?: number;
    lastStrike?: number;
    maturities?: any[];
    nonCallablePeriods?: number;
}

export interface AutocallCouponValue extends AutocallBaseValue {
    coupon: number;
}

export interface AutocallCouponMaturityItem {
    coupon?: number;
    strike?: number;
    date?: string;
}

export interface AutocallCouponFilterExtendedValue extends AutocallBaseValue {
    coupon: number;
    maturities: AutocallCouponMaturityItem[];
}

export interface UnderlyingExtendedValue {
    rule: string;
    underlyings: UnderlyingValue[];
}

export interface UnderlyingFilterValue {
    underlying?: string;
    isin?: string;
    dq?: string;
    weight: number;
    name?: string;
}

export interface UnderlyingValue extends UnderlyingFilterValue {
    underlyingName?: string;
    isin?: string | 'CUSTOM';
    wkn?: string;
    weight: number;
    name?: string;
}

export interface MaturityAutocallExtendedValue extends AutocallBaseValue {
    maturities: MaturityAutocallExtendedMaturityItem[];
    barrier: number;
    memory: boolean;
}

export interface MaturityAutocallExtendedMaturityItem {
    amount?: number;
    strike?: number;
    date?: string;
    autocallable?: boolean;
}

export interface MultiRequestStructure {
    columns: MultiRequestFieldStructure[];
}

export interface MultiRequestColumnConfig {
    label: string;
    inputType: string;
    componentType: string;
    options?: any;
    styleHints?: string[];
    path: string;
    values: string;
}

export interface MultiRequestVarianceData {
    entries: MultiRequestVarianceEntry[];
}

export interface MultiRequestVarianceEntry {
    componentType?: string;
    value: any;
    path?: string;
}
