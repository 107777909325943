export interface SolrResultsResponse {
    grouped: {
        instrumentType: {
            groups: Array<{
                groupValue: InstrumentType;
                doclist: {
                    docs: Array<SearchResult>;
                };
            }>;
        };
    };
}

export interface SolrFundResultsResponse {
    response: {
        docs: Array<SearchResult>;
    };
}

export interface GroupedResults {
    instrumentType: InstrumentType;
    results: Array<SearchResult>;
}

export interface SearchResult {
    id: string;
    isin: string;
    wkn: string;
    name: string;
    link?: Array<any>;
    issuer?: string;
    subTypeDE?: string;
}

export enum InstrumentType {
    Stock = 'STOCK',
    Bond = 'BOND',
    Fund = 'FUND',
    Derivate = 'DERIVATE'
}

export enum NotifyOnProductActivationState {
    Request = 1,
    Loading = 2,
    Requested = 3
}
