import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MarketAnalysisApi } from './api/market-analysis.api';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [],
    providers: [MarketAnalysisApi],
})
export class MarketAnalysisSharedModule {
}
