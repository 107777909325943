<div
    cdkOverlayOrigin
    class="tw-inline-flex tw-w-full"
    [class.tw-pointer-events-none]="panelOpen"
    (click)="toggle()">
    <div class="tw-w-full">
        <span class="tw-text-base tw-leading-6 tw-text-black dark:tw-text-old-black">{{ empty ? 'Option bitte wählen' : _selectionModel.selected[0].viewValue }}</span>
    </div>
    <span class="material-symbols-rounded tw-text-primary-base">expand_more</span>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="_preferredOverlayOrigin"
    [cdkConnectedOverlayPositions]="_positions"
    [cdkConnectedOverlayOpen]="panelOpen"
    [cdkConnectedOverlayWidth]="_overlayWidth"
    (overlayOutsideClick)="toggle()">
    <div class="tw-w-full tw-bg-white tw-border tw-border-solid tw-border-neutral-100 tw-py-1.25 tw-rounded tw-shadow-soft dark:tw-border-old-gray-200 dark:tw-rounded-none dark:tw-shadow-none">
        <ng-content></ng-content>
    </div>
</ng-template>
