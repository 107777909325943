import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EntityWidgetOptions } from '../../../models/widgets.model';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { Nl2BrPipe } from '../../../pipes/nl2br.pipe';

@Component({
    selector: 'app-entity-widget-paragraph',
    templateUrl: './entity-widget-paragraph.component.html',
    styleUrls: ['./entity-widget-paragraph.component.scss'],
    standalone: true,
    imports: [Nl2BrPipe]
})
export class EntityWidgetParagraphComponent implements OnInit, OnChanges, EntityWidgetContentComponent {

    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public options: EntityWidgetOptions;
    @Input() public data: string[];
    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();

    constructor() {
    }

    public ngOnChanges(changes: SimpleChanges): void {

    }

    public ngOnInit() {
    }

}
