import { Action } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { type } from '../../../../core/store/util';
import { Entity, EntityTableConfig } from '../../../../shared/models/entities.model';
import { DataRequestResponse, VolatilityDataRequestResponse } from '../../../shared/market-analysis-shared/api/model/market-analysis.model';

/* tslint:disable:max-line-length */
export const VolatilityMatrixActionType = {
    LOAD_VOLATILITY_MATRIX_TABLE_STRUCTURE: type('VOLATILITY_MATRIX:LOAD_VOLATILITY_MATRIX_TABLE_STRUCTURE'),
    LOAD_VOLATILITY_MATRIX_TABLE_STRUCTURE_SUCCEEDED: type('VOLATILITY_MATRIX:LOAD_VOLATILITY_MATRIX_TABLE_STRUCTURE_SUCCEEDED'),
    LOAD_VOLATILITY_MATRIX_TABLE_STRUCTURE_FAILED: type('VOLATILITY_MATRIX:LOAD_VOLATILITY_MATRIX_TABLE_STRUCTURE_FAILED'),

    LOAD_VOLATILITY_MATRIX_TABLE_VALUES: type('VOLATILITY_MATRIX:LOAD_VOLATILITY_MATRIX_TABLE_VALUES'),
    LOAD_VOLATILITY_MATRIX_TABLE_VALUES_SUCCEEDED: type('VOLATILITY_MATRIX:LOAD_VOLATILITY_MATRIX_TABLE_VALUES_SUCCEEDED'),
    LOAD_VOLATILITY_MATRIX_TABLE_VALUES_FAILED: type('VOLATILITY_MATRIX:LOAD_VOLATILITY_MATRIX_TABLE_VALUES_FAILED'),
};

/** LoadVolatilityMatrixTableStructureAction **/

export interface LoadVolatilityMatrixTableStructureAction extends Action {
    payload?: void;
}

export function loadVolatilityMatrixTableStructure(): LoadVolatilityMatrixTableStructureAction {
    return {
        type: VolatilityMatrixActionType.LOAD_VOLATILITY_MATRIX_TABLE_STRUCTURE,
    };
}

/** LoadVolatilityMatrixTableStructureSucceededAction **/

export interface LoadVolatilityMatrixTableStructureSucceededAction extends Action {
    payload: {
        structure: EntityTableConfig,
    };
}

export function loadVolatilityMatrixTableStructureSucceeded(structure: EntityTableConfig): LoadVolatilityMatrixTableStructureSucceededAction {
    return {
        type: VolatilityMatrixActionType.LOAD_VOLATILITY_MATRIX_TABLE_STRUCTURE_SUCCEEDED,
        payload: {
            structure,
        },
    };
}

/** LoadVolatilityMatrixTableStructureFailedAction **/

export interface LoadVolatilityMatrixTableStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadVolatilityMatrixTableStructureFailed(error: AppError): LoadVolatilityMatrixTableStructureFailedAction {
    return {
        type: VolatilityMatrixActionType.LOAD_VOLATILITY_MATRIX_TABLE_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadVolatilityMatrixTableValuesAction **/

export interface LoadVolatilityMatrixTableValuesAction extends Action {
    payload: {
        isin: string;
        configId: string;
    };
}

export function loadVolatilityMatrixTableValues(isin: string, configId: string): LoadVolatilityMatrixTableValuesAction {
    return {
        type: VolatilityMatrixActionType.LOAD_VOLATILITY_MATRIX_TABLE_VALUES,
        payload: {
            isin,
            configId,
        },
    };
}

/** LoadVolatilityMatrixTableValuesSucceededAction **/

export interface LoadVolatilityMatrixTableValuesSucceededAction extends Action {
    payload: {
        entity: Entity,
        data: Entity[],
    };
}

export function loadVolatilityMatrixTableValuesSucceeded(res: VolatilityDataRequestResponse): LoadVolatilityMatrixTableValuesSucceededAction {
    return {
        type: VolatilityMatrixActionType.LOAD_VOLATILITY_MATRIX_TABLE_VALUES_SUCCEEDED,
        payload: {
            data: res.data,
            entity: res.entity,
        },
    };
}

/** LoadVolatilityMatrixTableValuesFailedAction **/

export interface LoadVolatilityMatrixTableValuesFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadVolatilityMatrixTableValuesFailed(error: AppError): LoadVolatilityMatrixTableValuesFailedAction {
    return {
        type: VolatilityMatrixActionType.LOAD_VOLATILITY_MATRIX_TABLE_VALUES_FAILED,
        payload: {
            error,
        },
    };
}
