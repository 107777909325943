import { Action } from '@ngrx/store';
import { AuthActionTypes } from '../actions/auth.action';
import { ConfigActionTypes, ConfigLoadedAction } from '../actions/config.action';
import { ConfigState } from '../state/config.state';
import { isAnyValid } from '../util';
import { get } from 'lodash';

/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 5/6/17.
 */

export const initialState: ConfigState = {
    menu: null,
    tmcSelectable: false,
    dq: null,
    id: null,
    mercuryEnvironmentId: null,
    mercuryEnvironmentUrl: null,
    name: null,
    organization: null,
    pdDemoMode: false,
    uiFeatureFlags: [],
};

/**
 * The reducer responsible for the @link{ConfigState}
 * @param state
 * @param action
 * @returns {any}
 */
export function configReducer(state: ConfigState = initialState, action: Action): ConfigState {

    switch (action.type) {

        case ConfigActionTypes.CONFIG_LOADED:
            const configLoadedAction = action as ConfigLoadedAction;
            const uiFeatureFlags = get(configLoadedAction.payload, 'config.uiFeatureFlags', []);

            return {
                ...state,
                ...configLoadedAction.payload.config,
                uiFeatureFlags
            };

        case AuthActionTypes.SIGN_OUT:
            return {...initialState};

        default:
            return state;

    }

}
