import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    checkEquivalenceRequest,
    dynamicChartLoadGraphDataRequest,
    topUnderlyingsLoadDataRequest
} from '../store/actions/widgets-data.action';
import { AppState } from '../store/state/index.state';
import { AdditionalWidgetData, AdditionalWidgetDataMap } from '../store/state/widgets-data.state';
import { DynamicChartRequestData } from '../../shared/models/dynamic-chart.model';
import { TopUnderlyingsFilter } from '../../shared/widgets/legacy/entity-widget-top-underlyings/entity-widget-top-underlyings.component';

@Injectable()
export class AdditionalWidgetsDataService {
    constructor(private store: Store<AppState>) {
    }

    public getAdditionalWidgetsDataMap(): Observable<AdditionalWidgetDataMap> {
        return this.store.pipe(
            select((state: AppState) => state.additionalWidgetsData.data),
        );
    }

    public getAdditionalWidgetDataByPath(path: string): Observable<AdditionalWidgetData> {
        return this.getAdditionalWidgetsDataMap().pipe(
            map((data) => data[path]),
        );
    }

    public dynamicChartLoadGraphData(path: string, requestData: DynamicChartRequestData) {
        this.store.dispatch(dynamicChartLoadGraphDataRequest(requestData, path));
    }

    public topUnderlyingsLoadData(path: string, filter: TopUnderlyingsFilter) {
        this.store.dispatch(topUnderlyingsLoadDataRequest(filter, path));
    }

    public checkEquivalence(
        path: string,
        entityId: string,
        deriBwIds: string[],
        label: string,
        watchlistId: string
    ): void {
        this.store.dispatch(
            checkEquivalenceRequest(
                deriBwIds,
                entityId,
                path,
                label,
                watchlistId
            )
        );
    }
}
