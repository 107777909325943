<app-slider
    *ngIf="data$ | async as items"
    [pause]="true"
    [selectedItemIndex]="-1"
    [numberOfItems]="items.length"
    [splideOptions]="splideOptions"
>
    <div *ngFor="let item of items" class="splide__slide " style="padding: 10px;">
        <div class="entity-widget-market-indicator__item">
            <span class="entity-widget-market-indicator__item__name">{{ item.name }}</span>
            <ng-container *ngIf="item && item.quote > 0; else noQuote">
                <span
                    [class]="getClassesForItem(['entity-widget-market-indicator__item__change', item.change >= 0 ? 'positive' : 'negative'], item, 'change')">
                    <span class="entity-widget-market-indicator__item__indicator"
                          [class.positive]="item.change >= 0"></span>
                    <span>{{ item.change | translocoPercent }}</span>
                </span>
                <span [class]="getClassesForItem(['entity-widget-market-indicator__item__value'], item, 'quote')">
                    <span *ngIf="isCurrencySymbol(item.currency)">{{ item.quote | translocoCurrency:'symbol':undefined:item.currency }}</span>
                    <span *ngIf="!isCurrencySymbol(item.currency)">{{ item.quote | translocoDecimal }} {{ item.currency }}</span>
                </span>
            </ng-container>
            <ng-template #noQuote>
                <span class="entity-widget-market-indicator__item__value"><span>N/A</span></span>
            </ng-template>
        </div>
    </div>
</app-slider>
