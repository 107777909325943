import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, Validators } from '@angular/forms';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[min]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MinValidationDirective, multi: true }],
    standalone: true
})
export class MinValidationDirective implements Validator {

    @Input() public min: number;

    public validate(control: AbstractControl): { [key: string]: any } {
        return Validators.min(this.min)(control);
    }

}
