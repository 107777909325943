<div class="form-group">
    <select class="form-control" [ngModel]="currentQuickSelectValue" (ngModelChange)="onQuickSelectChange($event)"
            [disabled]="disabled">
        <option [value]="null" disabled style="display: none;">Schnellwahl</option>
        <option *ngFor="let value of values?.quickSelect" [value]="value.id">{{value.label}}</option>
    </select>
</div>
<div class="form-group" [class.has-danger]="!dateInput.disabled && !dateInput.valid">
    <app-date-input #dateInput="ngModel"
                    [placeholder]="'general.date.placeholder' | transloco"
                    [ngModel]="currentValue"
                    [excludeDays]="values?.excludedDays"
                    [disabled]="disabled"
                    (ngModelChange)="onDatePickerChange($event)"></app-date-input>
</div>
