import { Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormControlConfig, FormControlSelectOption, FormValues } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';
import { NumberInputComponent } from '../number-input/number-input.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-filter-labelled-number-comparison-input',
    templateUrl: './filter-labelled-number-comparison-input.component.html',
    styleUrls: ['./filter-labelled-number-comparison-input.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, MatTooltipModule, MatButtonToggleModule, NgFor, NumberInputComponent]
})
export class FilterLabelledNumberComparisonInputComponent implements OnInit, OnChanges, OnDestroy, SingleValueFilterControl<any> {

    @HostBinding('class.double-dropdown-input') public doubleDropdownInputClass = true;

    @Input() public config: FormControlConfig<FilterLabelledNumberComparisonInputOptions>;

    @Input() public values: any;

    @Input() public disabled = false;

    public onChange: (value: any) => void;

    public onTouched: () => void;

    public form: UntypedFormGroup;

    public valueChangesSubscription: Subscription;

    public paths = FilterFundFigureKey;

    get operatorValues(): FormControlSelectOption[] {
        return this.values || [];
    }

    constructor(private fb: UntypedFormBuilder) {
    }

    public ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.config && this.config) {
            this._updateForm();
        }
        if ((changes.config || changes.values) && this.values) {
            this._updateDefaultValues();
        }
    }

    public ngOnDestroy() {
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }
    }

    public writeValue(values: FormValues): void {
        if (values) {
            this.form.patchValue(values, {emitEvent: false});
        } else {
            this.form.patchValue({
                [FilterFundFigureKey.INPUT]: null,
                [FilterFundFigureKey.OPERATOR]: this.config.options.defaultOperator,
            }, {emitEvent: false});
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = (value) => {
            if (fn) {
                if (this.form.valid) {
                    fn(value);
                } else {
                    fn(undefined); // @INFO(alex): we don't want this filter input data to appear in our
                                   //              filter object later when the form is not valid.
                                   //              !! This is experimental, let's monitor it. !!
                }
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * Checks if the filter is valid
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this.form.valid) ? null : {
            doubleDropdownInputError: 'Invalid filter state specified',
        };
    }

    public setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    private _updateForm() {
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }
        const fields = {
            [FilterFundFigureKey.OPERATOR]: [null, Validators.required],
            [FilterFundFigureKey.INPUT]: [null, Validators.required],
        };

        const oldValue = (this.form) ? this.form.value : undefined;
        this.form = this.fb.group(fields);
        if (oldValue) {
            this.form.patchValue(oldValue);
        }
        this.valueChangesSubscription = this.form.valueChanges.subscribe((value) => this.onChange(value));
    }

    private _updateDefaultValues() {
        if (this.config && this.config.options) {
            const operatorControl = this.form.get(FilterFundFigureKey.OPERATOR);
            if ((operatorControl.value === undefined || operatorControl.value === null) && this.config.options.defaultOperator) {
                this.form.patchValue({
                    [FilterFundFigureKey.OPERATOR]: this.config.options.defaultOperator,
                }, { emitEvent: false });
            }
        }

    }
}

enum FilterFundFigureKey {
    INPUT = 'input',
    OPERATOR = 'operator',
}

export interface FilterLabelledNumberComparisonInputOptions {
    label: string;
    helpText?: string;
    unit?: string;
    sampleInput: string;
    defaultOperator?: string;
}
