import { AfterViewInit, Directive, ElementRef, HostListener, OnDestroy, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appSyncColumnsWidths]',
    standalone: true
})
export class SyncColumnsWidthsDirective implements AfterViewInit, OnDestroy {

  public parentEl: HTMLElement;
  public tablesEl: NodeListOf<HTMLTableElement>;
  public observer: ResizeObserver;

  constructor(
    private el: ElementRef,
    private rendered: Renderer2
  ) { }

  @HostListener('window:resize')
	onResize(): void {
		this.resizeColumns();
	}

  public ngAfterViewInit(): void {
    this.parentEl = this.el.nativeElement;
    this.tablesEl = this.parentEl.querySelectorAll('table');

    this.observer = new ResizeObserver((resizes) => 
      resizes.forEach(() => this.resizeColumns())
    );

    if (this.isSynchronizable()) {
      this.observer.observe(this.tablesEl[1]);

      Array.from(this.tablesEl[0].rows).forEach((row) => 
        this.observer.observe(row.cells[0])
      );
    }
  }

  public ngOnDestroy(): void {
    this.observer.disconnect();
  }

  public resizeColumns(): void {
    if (this.isSynchronizable()) {
      const bodyWidths = Array.from(this.tablesEl[1].rows[0].cells).map(
        cell => cell.getBoundingClientRect().width
      );
  
      Array.from(this.tablesEl[0].rows[0].cells).forEach((cell, index) => 
        this.rendered.setStyle(cell, 'min-width', `${bodyWidths[index]}px`)
      );
    }
  }

  public isSynchronizable(): boolean {
    return this.tablesEl.length === 2 &&
    this.tablesEl[0].rows[0].cells.length > 0 &&
    this.tablesEl[0].rows[0].cells.length === this.tablesEl[1].rows[0].cells.length;
  }
}
