import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { Orientation, XAxisComponent, AxesModule } from '@swimlane/ngx-charts';
import { DynamicChartXAxisTicksComponent } from './x-axis-ticks.components';
import { NgIf } from '@angular/common';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'g[app-charts-x-axis]',
    template: `
    <svg:g [attr.class]="xAxisClassName" [attr.transform]="transform">
      <svg:g
        app-charts-x-axis-ticks
        *ngIf="xScale"
        [minimal]="minimal"
        [trimTicks]="trimTicks"
        [rotateTicks]="rotateTicks"
        [maxTickLength]="maxTickLength"
        [tickFormatting]="tickFormatting"
        [tickArguments]="tickArguments"
        [tickStroke]="tickStroke"
        [scale]="xScale"
        [orient]="xOrient"
        [showGridLines]="showGridLines"
        [gridLineHeight]="dims.height"
        [width]="dims.width"
        [tickValues]="ticks"
        (dimensionsChanged)="emitTicksHeight($event)"
      />
      <svg:g
        ngx-charts-axis-label
        *ngIf="showLabel"
        [label]="labelText"
        [offset]="labelOffset"
        [orient]="orient.Bottom"
        [height]="dims.height"
        [width]="dims.width"
      ></svg:g>
    </svg:g>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, DynamicChartXAxisTicksComponent, AxesModule]
})
export class DynamicChartXAxisComponent extends XAxisComponent {
    @Input()
    public minimal = false;
    
    public orient = Orientation;

    @ViewChild(DynamicChartXAxisTicksComponent) public ticksComponent: DynamicChartXAxisTicksComponent;
}
