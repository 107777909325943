import { createAction, props } from "@ngrx/store";

export const getIssueMessage = createAction(
    '[Issue Message] Get Issue Message'
);

export const setIssueMessage = createAction(
    '[Issue Message] Set Issue Message',
    props<{ 
        message: string,
        updatedAt: string,
        hidden: boolean;
    }>()
);

export const hideIssueMessage = createAction(
    '[Issue Message] Hide Issue Message'
);
