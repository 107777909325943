<form class="row filter__form filter__{{orientation}}" [ngClass]="{ 'text-only': !visibleInput }" [formGroup]="filterForm">
    <ng-container *ngIf="structure">
        <ng-container *ngFor="let area of structure.areas">
            <section class="card-column col-md filter__col-{{area.weight}}" *ngIf="isControlVisibleInConditionalTree(area)">
                <div class="card split-card" [class.card-muted]="muted" [class.card-flat]="flat">
                    <div class="row">
                        <ng-container *ngFor="let section of area.sections; let last = last">
                            <section class="col-md filter__col-{{section.weight}}" *ngIf="isControlVisibleInConditionalTree(section)">
                                <div class="card-block">
                                    <h3 class="card-title" [innerHTML]="section.title === '&nbsp;' ? '&nbsp;' : (section.translateTitle ? (section.title | transloco) : section.title)"></h3>
                                    <div class="row">

                                        <ng-container *ngFor="let column of section.columns">
                                            <div
                                                *ngIf="isControlVisibleInConditionalTree(column)"
                                                class="col-md filter__col-{{column.weight}}"
                                            >
                                                <ng-container *ngFor="let controlGroup of column.controlGroups">
                                                    <div
                                                        *ngIf="hasVisibleControls(controlGroup) && isControlVisibleInConditionalTree(controlGroup)"
                                                        class="card-form__section">

                                                        <h4 class="card-headline" *ngIf="controlGroup.title">
                                                            {{ (controlGroup.translateTitle ? (controlGroup.title | transloco) : controlGroup.title) }}
                                                        </h4>

                                                        <ng-container *ngFor="let control of controlGroup.controls">
                                                            <div [class.has-danger]="structure?.options?.enableFormValidation && field.invalid && field.errors && (field.touched || field.dirty)" *ngIf="getControl(control) as field">
                                                                <app-filter-control-container
                                                                    *ngIf="isControlVisibleInConditionalTree(control)"
                                                                    [config]="control"
                                                                    [initiallyDisabled]="disabled || control.disabled"
                                                                    [values]="getAvailableDataForControl(control.values)"
                                                                    [formControlName]="control.path"
                                                                    [visibleInput]="visibleInput">
                                                                </app-filter-control-container>

                                                                <div *ngIf="structure?.options?.enableFormValidation &&  field.invalid && field.errors && (field.touched || field.dirty)"
                                                                     class="form-control-feedback">
                                                                    <ul *ngIf="field.errors" class="list-unstyled">
                                                                        <li *ngFor="let item of field.errors | keyvalue | slice:0:1">{{item.value?.message || item.value}}</li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </ng-container>

                                    </div>
                                </div>
                            </section>

                            <div *ngIf="!last" class="card-divider" [ngClass]="{'horizontal': structure.sectionsOrientation === 'vertical'}"></div>
                        </ng-container>
                    </div>

                    <ng-content select="[card]"></ng-content>
                </div>
            </section>
        </ng-container>
    </ng-container>

    <ng-content select="[container]"></ng-content>
</form>
