import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { unflatten } from 'flat';
import { get, isEqual } from 'lodash';
import { debounceTime } from 'rxjs/operators';
import { FormValues } from '../../../core/models/form.model';
import { TranslocoModule } from '@ngneat/transloco';
import { FilterControlContainerComponent } from '../filter-control-container/filter-control-container.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-risk-manager-general-settings',
    templateUrl: './risk-manager-general-settings.component.html',
    styleUrls: ['./risk-manager-general-settings.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, NgFor, FilterControlContainerComponent, TranslocoModule]
})
export class RiskManagerGeneralSettingsComponent implements OnInit, OnChanges {

    @Input() public controls: any;
    @Input() public values: any;

    @Output() public saveChanges: EventEmitter<void> = new EventEmitter<void>();
    @Output() public settingsChanged: EventEmitter<any> = new EventEmitter<any>();

    public filterForm: UntypedFormGroup;

    public currentFormValues: any;

    constructor(private fb: UntypedFormBuilder) {
        this.filterForm = this.fb.group({});
    }

    public ngOnInit() {

    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.controls && this.controls) {
            this.filterForm = this.fb.group(this.getEmptyControlValues());
            this.setValueChangeListener();
        }

        if (this.controls && this.values) {
            this.filterForm = this.fb.group(this.getFullInitialValues());
            this.setValueChangeListener();
        }
    }

    public setValueChangeListener() {
        this.filterForm.valueChanges.pipe(
            debounceTime(200),
        ).subscribe((values) => {
            const unflattenValues = unflatten(values);

            if (!isEqual(unflattenValues, this.currentFormValues)) {
                this.settingsChanged.next(unflattenValues);
            }

            this.currentFormValues = unflattenValues;
        });
    }

    /**
     * Returns the available data for the filter control
     * @param {string} valuePath
     * @returns {any}
     */
    public getAvailableDataForControl(valuePath: string): any {
        return get(this.values, valuePath);
    }

    /**
     * Returns the visible empty control values
     * @returns {FormValues}
     */
    protected getEmptyControlValues(): FormValues {
        return this.controls.reduce((controls, entry) => {
            controls[entry.control.path] = [];
            return controls;
        }, {});
    }

    /**
     * Returns the all the initial values
     * @returns {FormValues}
     */
    protected getFullInitialValues(): FormValues {
        const controlValues = this.getEmptyControlValues();
        Object.keys(controlValues).forEach((value) => {
            controlValues[value] = get(this.values, value) || undefined;
        });
        return controlValues;
    }

    public onSaveWatcher(e: Event) {
        e.preventDefault();
        this.saveChanges.next();
    }

}
