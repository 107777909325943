import { Action } from '@ngrx/store';
import {
    LoadMarketAnalysisProductsDataAction,
    LoadMarketAnalysisProductsDataSucceededAction,
    LoadMarketAnalysisProductsDynamicDataSucceededAction,
    LoadMarketAnalysisProductsDynamicStructureSucceededAction,
    LoadMarketAnalysisProductsFilterSucceededAction,
    MarketAnalysisProductsActionType,
} from '../actions/market-analysis-products.actions';
import {
    LoadMarketAnalysisDataAction,
    LoadMarketAnalysisDataStructureSucceededAction,
    LoadMarketAnalysisDataSucceededAction,
    LoadMarketAnalysisStructureSucceededAction,
    MarketAnalysisActionType,
} from '../actions/market-analysis.actions';
import { MarketAnalysisState } from '../state/market-analysis.state';

export const initialState: MarketAnalysisState = {
    marketAnalysisFilterStructure: null,
    marketAnalysisFilterValues: null,
    marketAnalysisTableStructure: null,
    marketAnalysisTableValues: null,
    loadingMarketAnalysisTableValues: null,

    marketAnalysisProductsFilterStructure: null,
    marketAnalysisProductsFilterValues: null,
    marketAnalysisProductsFilterInitialValues: null,

    loadingMarketAnalysisProductsData: false,
    marketAnalysisProductsData: null,

    marketAnalysisProductsDynamicFilteredData: null,
    marketAnalysisProductsDynamicFilteredStructure: null,
    marketAnalysisProductsDynamicFilteredValues: null,
    marketAnalysisProductsDynamicFilteredInitialValues: null,
};

/**
 * The reducer responsible for the @link{MarketAnalysisState}
 * @param state
 * @param action
 * @returns {any}
 */
export function reducer(state: MarketAnalysisState = initialState,
                        action: Action): MarketAnalysisState {

    switch (action.type) {
        case MarketAnalysisActionType.LOAD_MARKET_ANALYSIS_STRUCTURE_SUCCEEDED:
            const loadMarketAnalysisStructureSucceededAction = action as LoadMarketAnalysisStructureSucceededAction;

            return {
                ...state,
                marketAnalysisFilterStructure: loadMarketAnalysisStructureSucceededAction.payload.structure.filter.structure.common,
                marketAnalysisFilterValues: loadMarketAnalysisStructureSucceededAction.payload.structure.filter.values,
            };

        case MarketAnalysisActionType.LOAD_MARKET_ANALYSIS_DATA:
            const loadMarketAnalysisData = action as LoadMarketAnalysisDataAction;
            if (loadMarketAnalysisData.payload.offset === undefined || loadMarketAnalysisData.payload.offset === null) {
                return {
                    ...state,
                    loadingMarketAnalysisTableValues: true,
                };
            } else {
                return state;
            }

        case MarketAnalysisActionType.LOAD_MARKET_ANALYSIS_DATA_SUCCEEDED:
            const loadMarketAnalysisDataSucceededAction = action as LoadMarketAnalysisDataSucceededAction;

            return {
                ...state,
                marketAnalysisTableValues: loadMarketAnalysisDataSucceededAction.payload.data,
                loadingMarketAnalysisTableValues: false,
            };

        case MarketAnalysisActionType.LOAD_MARKET_ANALYSIS_DATA_STRUCTURE_SUCCEEDED:
            const loadMarketAnalysisDataStructureSucceededAction = action as LoadMarketAnalysisDataStructureSucceededAction;

            return {
                ...state,
                marketAnalysisTableStructure: loadMarketAnalysisDataStructureSucceededAction.payload.structure,
            };

        case MarketAnalysisProductsActionType.LOAD_MARKET_ANALYSIS_PRODUCTS_FILTER_SUCCEEDED:
            const loadProductsStructureAction = action as LoadMarketAnalysisProductsFilterSucceededAction;

            return {
                ...state,
                marketAnalysisProductsFilterStructure: loadProductsStructureAction.payload.structure.filter.structure.common,
                marketAnalysisProductsFilterValues: loadProductsStructureAction.payload.structure.filter.values,
                marketAnalysisProductsFilterInitialValues: loadProductsStructureAction.payload.structure.filter.filter,
            };

        case MarketAnalysisProductsActionType.LOAD_MARKET_ANALYSIS_PRODUCTS_DATA:
            const loadProductsAction = action as LoadMarketAnalysisProductsDataAction;

            return {
                ...state,
                loadingMarketAnalysisProductsData: true,
            };

        case MarketAnalysisProductsActionType.LOAD_MARKET_ANALYSIS_PRODUCTS_DATA_SUCCEEDED:
            const loadProductsSucceededAction = action as LoadMarketAnalysisProductsDataSucceededAction;

            return {
                ...state,

                loadingMarketAnalysisProductsData: false,
                marketAnalysisProductsData: loadProductsSucceededAction.payload.data,
            };

        case MarketAnalysisProductsActionType.LOAD_MARKET_ANALYSIS_PRODUCTS_DYNAMIC_DATA_SUCCEEDED:
            const loadDynamicData = action as LoadMarketAnalysisProductsDynamicDataSucceededAction;
            return {
                ...state,

                marketAnalysisProductsDynamicFilteredData: loadDynamicData.payload.data,
                marketAnalysisProductsDynamicFilteredValues: {
                    ...state.marketAnalysisProductsDynamicFilteredValues,
                    ...loadDynamicData.payload.values,
                },
                marketAnalysisProductsDynamicFilteredInitialValues: {
                    ...state.marketAnalysisProductsDynamicFilteredInitialValues,
                    ...loadDynamicData.payload.initialValues,
                },
            };

        case MarketAnalysisProductsActionType.LOAD_MARKET_ANALYSIS_PRODUCTS_DYNAMIC_STRUCTURE_SUCCEEDED:
            const loadDynamicStructure = action as LoadMarketAnalysisProductsDynamicStructureSucceededAction;

            return {
                ...state,

                marketAnalysisProductsDynamicFilteredStructure: loadDynamicStructure.payload.structure,
            };
    }

    return state;
}
