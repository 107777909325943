<ul class="filter-bar__actions">
    <li class="filter-bar__action">
        <a href="#" class="filter-bar__action-link" (click)="onResetFilterClick($event)">
            {{ 'products.productList.page.resetFilter' | transloco }}
        </a>
    </li>
    <li class="filter-bar__action">
        <a href="#" class="filter-bar__action-link apply-filter-button" (click)="onApplyFilterClick($event)">
            {{ 'products.productList.page.applyFilter' | transloco }}
        </a>
    </li>
</ul>
