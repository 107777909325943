/**
 * Created by Alex Klein <alex@rocketloop.de> on 10/17/17.
 */

import { LandingActionsUnion, LandingActionTypes } from '../actions/landing.actions';
import { LandingState } from '../state/landing.state';

export const initialState: LandingState = {
    carouselItems: null,
    contactInfos: null,
    loginConfig: {
        headline: '',
        teaser: '',
        contactInfos: []
    }
};

/**
 * The reducer responsible for the @link{LandingState}
 *
 * @param state
 * @param action
 * @returns {any}
 */
export function reducer(state: LandingState = initialState, action: LandingActionsUnion): LandingState {
    switch (action.type) {

        case LandingActionTypes.LANDING_PAGE_DATA_SUCCESS:
            return {
                ...state,
                carouselItems: action.payload.carouselItems,
                contactInfos: action.payload.contactInfos,
                loginConfig: action.payload.loginConfig,
            };

        default:
            return state;
    }
}
