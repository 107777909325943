import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { getFirstMenuItemLink } from '../../../core/helpers/menu.helper';
import { AuthStoreService } from '../../../core/services/auth-store.service';
import { MenuService } from '../../../core/services/menu.service';
import { truthy } from '../../helpers/general.helper';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterLink } from '@angular/router';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink, AsyncPipe, TranslocoModule]
})
export class NotFoundComponent implements OnInit {

  public isTokenValid$: Observable<boolean>;
  public dashboardUrl$: Observable<Array<string>>;

  constructor(
    private menuService: MenuService,
    private authStoreService: AuthStoreService
  ) { }

  ngOnInit(): void {
    this.isTokenValid$ = this.authStoreService.isRefreshTokenStillValid();
    
    this.dashboardUrl$ = this.menuService.getMenuConfig().pipe(
      filter(truthy),
      map((menuConfig) => getFirstMenuItemLink(menuConfig)),
      startWith(['/app'])
    );
  }

}
