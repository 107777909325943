<div class="wms-dialog-inner tw-bg-primary-bg">
    <div class="3xl:tw-container tw-w-full tw-mx-auto tw-px-8">
        <nav class="navbar navbar-toggleable-md header-bar navbar-inverse tw-py-4">
            <div class="3xl:tw-container tw-w-full tw-flex tw-items-center border-0">
                <a class="navbar-nav overlay-header__text mr-auto">
                    <h1 class="headline tw-mb-0 tw-text-black dark:tw-text-white">{{ this.title }}</h1>
                </a>
        
                <div class="navbar-nav">
                    <button
                        type="button"
                        class="btn btn-link icon-link nav-link"
                        title="Schließen"
                        (click)="onCloseClick()">
                        <app-icon
                            class="derifin-icon"
                            icon="derifin:schliessen">
                        </app-icon>
                    </button>
                </div>
            </div>
        </nav>
        <div class="card card-flat">
            <div class="card-block">
                <app-markdown
                    [text]="text"
                    [columns]="true">
                </app-markdown>
            </div>
        </div>
    </div>
</div>