import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProductSelectionService } from '../../routes/product-selection/services/product-selection.service';

@Injectable()
export class EntityActionsActivationTriggersService {
    private _activeTriggers$ = new BehaviorSubject<string[]>([]);
    public activeTriggers$ = this._activeTriggers$.asObservable();

    constructor(private productSelectionService: ProductSelectionService) {
        this.productSelectionService.getNumberOfSelectedProducts().subscribe((productCount) => {
            const triggerName = 'products-selected';

            if (productCount && productCount > 0) {
                this.changeTriggerState(triggerName, true);
            } else {
                this.changeTriggerState(triggerName, false);
            }
        });
    }

    public changeTriggerState(trigger: string, activate: boolean = true) {
        const currentTriggers = this._activeTriggers$.getValue();
        const newTriggers = [...currentTriggers];

        const triggerIndex = currentTriggers.indexOf(trigger);

        if (activate && triggerIndex === -1) {
            newTriggers.push(trigger);
        } else if (!activate && triggerIndex >= 0) {
            newTriggers.splice(triggerIndex, 1);
        }

        this._activeTriggers$.next(newTriggers);
    }
}
