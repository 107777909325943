<div class="row">
    <div class="col">
        <app-entity-details-table [fields]="options?.fields" [entity]="data"></app-entity-details-table>
    </div>
    <div class="col">
        <div class="entity-widget-product-promotion__content" *ngIf="data">
            {{description}}
        </div>
        <div class="entity-widget-product-promotion__action">
            <app-entity-actions [genericActionsStructure]="options?.actions"
                                (actionClicked)="onAction($event)"></app-entity-actions>
        </div>
    </div>
</div>

