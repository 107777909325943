/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 1/9/18.
 */
import { Action, combineReducers } from '@ngrx/store';
import { UploadFile, UploadQueue } from '../../models/upload.model';
import {
    AddFileToUploadQueueAction, CreateUploadQueueAction, RemoveFileFromUploadQueueAction, UpdateUploadProgressAction,
    UploadActionTypes, UploadCompletedAction, UploadFailedAction,
} from '../actions/upload.action';
import { EntitiesState } from '../state/entities.state';
import { StringMappingListState } from '../state/mapping.state';
import { UploadState } from '../state/upload.state';

export const initialState: UploadState = {
    queues: {},
    files: {},
    queueToFilesMapping: {},
};

/**
 * The reducer responsible for the queues part of the @link{UploadState}
 * @param state
 * @param action
 * @returns {EntitiesState<UploadQueue>}
 */
export function uploadQueuesReducer(state: EntitiesState<UploadQueue> = initialState.queues,
                                    action: Action): EntitiesState<UploadQueue> {

    switch (action.type) {

        case UploadActionTypes.CREATE_UPLOAD_QUEUE:
            const createUploadQueueAction = action as CreateUploadQueueAction;
            return {
                ...state,
                [createUploadQueueAction.payload.id]: createUploadQueueAction.payload,
            };

        default:
            return state;
    }
}

/**
 * The reducer responsible for the files part of the @link{UploadState}
 * @param state
 * @param action
 * @returns {EntitiesState<UploadFile>}
 */
export function uploadFilesReducer(state: EntitiesState<UploadFile> = initialState.files,
                                   action: Action): EntitiesState<UploadFile> {

    switch (action.type) {

        case UploadActionTypes.ADD_FILE_TO_UPLOAD_QUEUE:
            const addFileToUploadQueueAction = action as AddFileToUploadQueueAction;
            return {
                ...state,
                [addFileToUploadQueueAction.payload.uploadFile.id]: addFileToUploadQueueAction.payload.uploadFile,
            };

        case UploadActionTypes.REMOVE_FILE_FROM_UPLOAD_QUEUE:
            const removeFileFromUploadQueueAction = action as RemoveFileFromUploadQueueAction;
            return {
                ...state,
                [removeFileFromUploadQueueAction.payload.fileId]: undefined,
            };

        case UploadActionTypes.UPDATE_UPLOAD_PROGRESS:
            const updateUploadProgressAction = action as UpdateUploadProgressAction;
            if (state[updateUploadProgressAction.payload.fileId]) {
                return {
                    ...state,
                    [updateUploadProgressAction.payload.fileId] : {
                        ...state[updateUploadProgressAction.payload.fileId],
                        progress: updateUploadProgressAction.payload.progress,
                    },
                };
            } else {
                return state;
            }

        case UploadActionTypes.UPLOAD_COMPLETED:
            const uploadCompletedAction = action as UploadCompletedAction;
            if (state[uploadCompletedAction.payload.fileId]) {
                return {
                    ...state,
                    [uploadCompletedAction.payload.fileId] : {
                        ...state[uploadCompletedAction.payload.fileId],
                        progress: 1,
                        response: uploadCompletedAction.payload.response,
                    },
                };
            } else {
                return state;
            }

        case UploadActionTypes.UPLOAD_FAILED:
            const uploadFailedAction = action as UploadFailedAction;
            if (state[uploadFailedAction.payload.fileId]) {
                return {
                    ...state,
                    [uploadFailedAction.payload.fileId] : {
                        ...state[uploadFailedAction.payload.fileId],
                        progress: 1,
                        failed: true,
                    },
                };
            } else {
                return state;
            }

        default:
            return state;
    }
}

/**
 * The reducer responsible for the queue to files mapping part of the @link{UploadState}
 * @param state
 * @param action
 * @returns {StringMappingListState<string>}
 */
export function queueToFilesMappingReducer(state: StringMappingListState<string> = initialState.queueToFilesMapping,
                                           action: Action): StringMappingListState<string> {

    switch (action.type) {

        case UploadActionTypes.CREATE_UPLOAD_QUEUE:
            const createUploadQueueAction = action as CreateUploadQueueAction;
            if (!state[createUploadQueueAction.payload.id]) {
                return {
                    ...state,
                    [createUploadQueueAction.payload.id]: [],
                };
            } else {
                return state;
            }

        case UploadActionTypes.ADD_FILE_TO_UPLOAD_QUEUE:
            const addFileToUploadQueueAction = action as AddFileToUploadQueueAction;
            return {
                ...state,
                [addFileToUploadQueueAction.payload.queueId]: [
                    ...state[addFileToUploadQueueAction.payload.queueId],
                    addFileToUploadQueueAction.payload.uploadFile.id,
                ],
            };

        case UploadActionTypes.REMOVE_FILE_FROM_UPLOAD_QUEUE:
            const removeFileFromUploadQueueAction = action as RemoveFileFromUploadQueueAction;
            return {
                ...state,
                [removeFileFromUploadQueueAction.payload.queueId]: state[removeFileFromUploadQueueAction.payload.queueId]
                    .filter((fileId) => fileId !== removeFileFromUploadQueueAction.payload.fileId),
            };

        default:
            return state;
    }
}

/**
 * The reducer responsible for the @link{UploadState}
 * @type {ActionReducer<ProductState>}
 */
export const uploadReducer = combineReducers({
    queues: uploadQueuesReducer,
    files: uploadFilesReducer,
    queueToFilesMapping: queueToFilesMappingReducer,
});
