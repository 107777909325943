import { get } from 'lodash';

/**
 * This function coerces a string into a string literal type.
 * Using tagged union types in TypeScript 2.0, this enables
 * powerful typechecking of our reducers.
 *
 * Since every action label passes through this function it
 * is a good place to ensure all of our action labels
 * are unique.
 */

export const typeCache: { [label: string]: boolean } = {};

export function type<T>(label: T | ''): T {
    if (typeCache[label as string]) {
        throw new Error(`Action type "${label}" is not unique"`);
    }

    typeCache[label as string] = true;

    return label as T;
}

export function isAnyValid(obj: any, ...keys: string[]) {
    let valid = false;

    keys.forEach((key) => {
        const k = get(obj, key, false);

        if (k) {
            valid = true;
        }
    });

    return valid;
}
