/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 5/15/17.
 */

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DerifinApi } from '../../../core/api/derifin.api';
import { FormValues } from '../../../core/models/form.model';
import { AuthStoreService } from '../../../core/services/auth-store.service';
import { ConfigurationService } from '../../../core/services/configuration.service';
import { LogglyLoggerService } from '../../../core/services/loggly-logger.service';
import { EntitySortSetting, QuerySet, UpdatedData } from '../../../shared/models/entities.model';
import { TargetMarketCriteria } from '../../target-market/models/target-market-criteria.model';
import { FilterStructureConfig } from '../models/filters.model';
import {
    transformCreateProductQuerySetResponse,
    transformProductFilterAvailableValues,
    transformProductFilterInitialValues,
    transformProductFilterStructure,
    transformProductsResponse,
    transformUpdatedProductsData,
} from './products.transform';

/**
 * API abstraction layer for the Entity API
 */
@Injectable({providedIn: 'root'})
export class ProductsApi extends DerifinApi {

    constructor(http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, appConfigService: ConfigurationService) {
        super('m/product', appConfigService.configuration.environment.id, http, authStoreService, loggerService, appConfigService);
    }

    /**
     * Get the product filter fields config for the specified product type id from the API
     * @param productType
     * @returns {Observable<FormStructureConfig>}
     */
    public getProductFilterStructure(productType: string): Observable<FilterStructureConfig> {
        return this.get(`${productType}/filter/structure`).pipe(
            map(transformProductFilterStructure),
        );
    }

    /**
     * Get the available values of the product filter for the specified product type id from the API
     * @param productType
     * @returns {Observable<FormValues>}
     */
    public getProductFilterAvailableValues(productType: string): Observable<FormValues> {
        return this.get(`${productType}/filter/values`).pipe(
            map(transformProductFilterAvailableValues),
        );
    }

    /**
     * Get the initial values of the product filter for the specified product type id from the API
     * @param productType
     * @returns {Observable<FormValues>}
     */
    public getProductFilterInitialValues(productType: string): Observable<FormValues> {
        return this.get(`${productType}/filter/filter`).pipe(
            map(transformProductFilterInitialValues),
        );
    }

    /**
     * Create a new product query set for the specified product type id and filter values in the API
     * @param productType
     * @param filterValues
     * @param sortSettings
     * @param targetMarketCriteria
     * @returns {Observable<Response>}
     */
    public createProductQuerySet(
        productType: string,
        filterValues: FormValues,
        sortSettings?: EntitySortSetting,
        targetMarketCriteria?: TargetMarketCriteria,
    ): Observable<QuerySet> {
        return this.post(`${productType}/query`, {
            filter: filterValues,
            customerCriteria: targetMarketCriteria,
            sort: sortSettings,
        }).pipe(
            map(transformCreateProductQuerySetResponse)
        );
    }

    /**
     * Get the count of the query set specified by id from the API
     * @param productType
     * @param querySetId
     * @returns {Observable<number>}
     */
    public getProductQuerySetCount(productType: string, querySetId: string): Observable<number> {
        return this.get(`query/${querySetId}/count`).pipe(
            map((response) => response.data),
        );
    }

    /**
     * Delete a product query set for the specified product type id and the specified query set id from the API
     * @param productType
     * @param querySetId
     * @returns {Observable<T>}
     */
    public deleteProductQuerySet(productType: string, querySetId: string): Observable<any> {
        return this.delete(`query/${querySetId}`);
    }

    /**
     * Get the products for the specified query set from the API
     * @param productType
     * @param querySetId
     * @param limit
     * @param offset
     * @returns {Observable<Entity[]>}
     */
    public getProductsForQuerySet(
        productType: string,
        querySetId: string,
        encodedCriteria: string,
        passCriteria: boolean,
        limit: number,
        offset: number
    ): Observable<any> {
        let params = new HttpParams();

        if (limit) {
            params = params.set('limit', limit);
        }

        if (offset) {
            params = params.set('offset', offset);
        }

        if (encodedCriteria && passCriteria) {
            params = params.set('tmc', encodedCriteria);
        }

        return this.get(`query/${querySetId}/products`, {
            params
        }).pipe(
            map(transformProductsResponse),
        );
    }

    /**
     * Get the products for the specified query set from the API
     * @param querySetId
     * @returns {Observable<Entity[]>}
     */
    public getUpdatedProductsDataForQuerySet(querySetId: string): Observable<UpdatedData[]> {
        return this.get(`query/${querySetId}/active`).pipe(
            map((response) => response.data),
            map((data) => data.map(transformUpdatedProductsData)),
        );
    }

    /**
     * requests to be informed when a product the given product is activated
     *
     * @param entityId
     */
    public notifyOnProductActivation(entityId: string): Observable<null> {
        return this.post('requestProductActivation', {
            entityId,
        });
    }
}
