import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TargetMarketState } from "../state/target-market.state";

export const selectTargetMarket = createFeatureSelector<TargetMarketState>('targetMarket');

export const selectActivatedCustomCustomerId = createSelector(
    selectTargetMarket,
    (state: TargetMarketState) => state.activatedCustomCustomerId
);

export const selectStructure = createSelector(
    selectTargetMarket,
    (state: TargetMarketState) => state.structure
);
