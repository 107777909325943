import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { get, isString } from 'lodash';
import {take} from 'rxjs/operators';
import { getImagePathFromReference } from '../../../../core/helpers/image-referece-resolver.helper';
import { UnifiedImageReference } from '../../../../core/models/image-reference.model';
import {ImageReferenceResolverService} from '../../../../core/services/image-reference-resolver.service';
import { ImageMarkdownOptions } from '../../../models/widgets.model';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { MarkdownComponent } from '../../../components/markdown/markdown.component';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'app-image-markdown-widget',
    templateUrl: './image-markdown-widget.component.html',
    styleUrls: ['./image-markdown-widget.component.scss'],
    standalone: true,
    imports: [NgStyle, MarkdownComponent]
})
export class ImageMarkdownWidgetComponent implements OnInit, OnChanges, EntityWidgetContentComponent {
    public static disableTitle = true;

    @HostBinding('class.image-news-widget') public imageNewsWidgetClass = true;

    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public options: ImageMarkdownOptions;
    @Input() public data: any;
    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();

    public text: string;
    public image: string;

    public resolvedImage: UnifiedImageReference;
    public imageReference: string;
    public imagePath: string;

    get imageStyle() {
        if ((this.options.imagePosition === 'TOP' || this.options.imagePosition === 'BOTTOM') && this.options.imageHeight) {
            return {
                'min-height': `${this.options.imageHeight}px`,
            };
        }

        return {};
    }

    constructor(
        private readonly imageResolver: ImageReferenceResolverService,
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.data && this.options) {
            this.text = get(this.data, this.options.text);
            this.imageReference = isString(this.options.imageReference) ? get(this.data, this.options.imageReference) : this.options.imageReference;
            this.imagePath = get(this.data, this.options.imagePath);

            if (this.imageReference) {
                this.resolvedImage = this.imageResolver.getImageReferenceFromAddress(this.imageReference);
                this.image = getImagePathFromReference(this.resolvedImage, 'COLOR');
            } else {
                this.image = this.imagePath;
            }
        }
    }

    public ngOnInit() {
    }

    public onActionClicked(action: { type: string; payload: any }) {
        this.action.next(action);
    }
}
