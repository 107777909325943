/**
 * Created by Alex Klein <florian@rocketloop.de> on 9/7/17.
 */
import { Action, createActionGroup, props } from '@ngrx/store';

import { Dictionary } from '@ngrx/entity';
import { EntityWidgetConfig } from '../../../shared/models/widgets.model';
import { AsyncLinkResponseData } from '../../api/models/download.model';
import { AppError } from '../../errors/base.errors';
import { Data } from '../../models/data.model';
import { MultiProductReportRequestData, ReportRequestData } from '../../services/async-link.service';
import { type } from '../util';
import { MultiRowComments } from '../../../shared/components/entity-table-multi-row-comment/entity-table-multi-row-comment.interface';
import { StringObject } from '../../models/generic.model';

export const AsyncLinkActionTypes = {
    ONE_PAGER_REQUEST: type('ASYNC_LINK:ONE_PAGER_REQUEST'),
    ONE_PAGER_SUCCESS: type('ASYNC_LINK:ONE_PAGER_SUCCESS'),
    ONE_PAGER_FAILURE: type('ASYNC_LINK:ONE_PAGER_FAILURE'),

    PRODUCTS_EXCEL_REQUEST: type('ASYNC_LINK:PRODUCTS_EXCEL_REQUEST'),
    PRODUCTS_EXCEL_ALL_REQUEST: type('ASYNC_LINK:PRODUCTS_EXCEL_ALL_REQUEST'),
    PRODUCTS_EXCEL_SUCCESS: type('ASYNC_LINK:PRODUCTS_EXCEL_SUCCESS'),
    PRODUCTS_EXCEL_FAILURE: type('ASYNC_LINK:PRODUCTS_EXCEL_FAILURE'),

    WATCHLIST_EXCEL_REQUEST: type('ASYNC_LINK:WATCHLIST_EXCEL_REQUEST'),
    WATCHLIST_EXCEL_SUCCESS: type('ASYNC_LINK:WATCHLIST_EXCEL_SUCCESS'),
    WATCHLIST_EXCEL_FAILURE: type('ASYNC_LINK:WATCHLIST_EXCEL_FAILURE'),

    TRADES_EXCEL_REQUEST: type('ASYNC_LINK:TRADES_EXCEL_REQUEST'),
    TRADES_EXCEL_SUCCESS: type('ASYNC_LINK:TRADES_EXCEL_SUCCESS'),
    TRADES_EXCEL_FAILURE: type('ASYNC_LINK:TRADES_EXCEL_FAILURE'),

    TRADES_EXCEL_ALL_REQUEST: type('ASYNC_LINK:TRADES_EXCEL_ALL_REQUEST'),
    TRADES_EXCEL_ALL_SUCCESS: type('ASYNC_LINK:TRADES_EXCEL_ALL_SUCCESS'),
    TRADES_EXCEL_ALL_FAILURE: type('ASYNC_LINK:TRADES_EXCEL_ALL_FAILURE'),

    ASSETS_EXCEL_REQUEST: type('ASYNC_LINK:ASSETS_EXCEL_REQUEST'),
    ASSETS_EXCEL_SUCCESS: type('ASYNC_LINK:ASSETS_EXCEL_SUCCESS'),
    ASSETS_EXCEL_FAILURE: type('ASYNC_LINK:ASSETS_EXCEL_FAILURE'),

    ASSETS_EXCEL_ALL_REQUEST: type('ASYNC_LINK:ASSETS_EXCEL_ALL_REQUEST'),
    ASSETS_EXCEL_ALL_SUCCESS: type('ASYNC_LINK:ASSETS_EXCEL_ALL_SUCCESS'),
    ASSETS_EXCEL_ALL_FAILURE: type('ASYNC_LINK:ASSETS_EXCEL_ALL_FAILURE'),

    REPORT_REQUEST: type('ASYNC_LINK:REPORT_REQUEST'),
    REPORT_SUCCESS: type('ASYNC_LINK:REPORT_SUCCESS'),
    REPORT_FAILURE: type('ASYNC_LINK:REPORT_FAILURE'),

    MULTI_PRODUCT_REPORT_REQUEST: type('ASYNC_LINK:MULTI_PRODUCT_REPORT_REQUEST'),
    MULTI_PRODUCT_REPORT_SUCCESS: type('ASYNC_LINK:MULTI_PRODUCT_REPORT_SUCCESS'),
    MULTI_PRODUCT_REPORT_FAILURE: type('ASYNC_LINK:MULTI_PRODUCT_REPORT_FAILURE'),

    ATTACHMENT_REQUEST: type('ASYNC_LINK:ATTACHMENT_REQUEST'),
    ATTACHMENT_SUCCESS: type('ASYNC_LINK:ATTACHMENT_SUCCESS'),
    ATTACHMENT_FAILURE: type('ASYNC_LINK:ATTACHMENT_FAILURE'),

    EXTERNAL_PRODUCT_LINK_REQUEST: type('ASYNC_LINK:EXTERNAL_PRODUCT_LINK_REQUEST'),
    EXTERNAL_PRODUCT_LINK_SUCCESS: type('ASYNC_LINK:EXTERNAL_PRODUCT_LINK_SUCCESS'),
    EXTERNAL_PRODUCT_LINK_FAILURE: type('ASYNC_LINK:EXTERNAL_PRODUCT_LINK_FAILURE'),

    BW_LIST_HISTORY_REQUEST: type('ASYNC_LINK:BW_LIST_HISTORY_REQUEST'),
    BW_LIST_HISTORY_SUCCESS: type('ASYNC_LINK:BW_LIST_HISTORY_SUCCESS'),
    BW_LIST_HISTORY_FAILURE: type('ASYNC_LINK:BW_LIST_HISTORY_FAILURE'),

    USERS_EXCEL_REQUEST: type('ASYNC_LINK:USERS_EXCEL_REQUEST'),
    USERS_EXCEL_SUCCESS: type('ASYNC_LINK:USERS_EXCEL_SUCCESS'),
    USERS_EXCEL_FAILURE: type('ASYNC_LINK:USERS_EXCEL_FAILURE'),

    USERS_EXCEL_ALL_REQUEST: type('ASYNC_LINK:USERS_EXCEL_ALL_REQUEST'),
    USERS_EXCEL_ALL_SUCCESS: type('ASYNC_LINK:USERS_EXCEL_ALL_SUCCESS'),
    USERS_EXCEL_ALL_FAILURE: type('ASYNC_LINK:USERS_EXCEL_ALL_FAILURE'),

    SHORTLIST_EXPORT_REQUEST: type('ASYNC_LINK:SHORTLIST_EXPORT_REQUEST'),
    SHORTLIST_EXPORT_SUCCESS: type('ASYNC_LINK:SHORTLIST_EXPORT_SUCCESS'),
    SHORTLIST_EXPORT_FAILURE: type('ASYNC_LINK:SHORTLIST_EXPORT_FAILURE'),

    SHORTLIST_GROUPED_EXPORT_REQUEST: type('ASYNC_LINK:SHORTLIST_GROUPED_EXPORT_REQUEST'),
    SHORTLIST_GROUPED_EXPORT_SUCCESS: type('ASYNC_LINK:SHORTLIST_GROUPED_EXPORT_SUCCESS'),
    SHORTLIST_GROUPED_EXPORT_FAILURE: type('ASYNC_LINK:SHORTLIST_GROUPED_EXPORT_FAILURE'),

    TMC_CHECK_REPORT_REQUEST: type('ASYNC_LINK:TMC_CHECK_REPORT_REQUEST'),
    TMC_CHECK_REPORT_SUCCESS: type('ASYNC_LINK:TMC_CHECK_REPORT_SUCCESS'),
    TMC_CHECK_REPORT_FAILURE: type('ASYNC_LINK:TMC_CHECK_REPORT_FAILURE'),

    RFQ_EXPORT_REQUEST_QUOTE_REQUEST: type('ASYNC_LINK:RFQ_EXPORT_REQUEST_QUOTE_REQUEST'),
    RFQ_EXPORT_REQUEST_QUOTE_SUCCESS: type('ASYNC_LINK:RFQ_EXPORT_REQUEST_QUOTE_SUCCESS'),
    RFQ_EXPORT_REQUEST_QUOTE_FAILURE: type('ASYNC_LINK:RFQ_EXPORT_REQUEST_QUOTE_FAILURE'),

    RFQ_EXPORT_RESULT_QUOTE_REQUEST: type('ASYNC_LINK:RFQ_EXPORT_RESULT_QUOTE_REQUEST'),
    RFQ_EXPORT_RESULT_QUOTE_SUCCESS: type('ASYNC_LINK:RFQ_EXPORT_RESULT_QUOTE_SUCCESS'),
    RFQ_EXPORT_RESULT_QUOTE_FAILURE: type('ASYNC_LINK:RFQ_EXPORT_RESULT_QUOTE_FAILURE'),

    RFQ_MULTI_EXCEL_EXPORT_REQUEST: type('ASYNC_LINK:RFQ_MULTI_EXCEL_EXPORT_REQUEST'),
    RFQ_MULTI_EXCEL_EXPORT_SUCCESS: type('ASYNC_LINK:RFQ_MULTI_EXCEL_EXPORT_SUCCESS'),
    RFQ_MULTI_EXCEL_EXPORT_FAILURE: type('ASYNC_LINK:RFQ_MULTI_EXCEL_EXPORT_FAILURE'),

    EQUIVALENCE_EXPORT_REQUEST: type('ASYNC_LINK:EQUIVALENCE_EXPORT_REQUEST'),
    EQUIVALENCE_EXPORT_SUCCESS: type('ASYNC_LINK:EQUIVALENCE_EXPORT_SUCCESS'),
    EQUIVALENCE_EXPORT_FAILURE: type('ASYNC_LINK:EQUIVALENCE_EXPORT_FAILURE'),

    EQUIVALENCE_CHECK_EXPORT_REQUEST: type('ASYNC_LINK:EQUIVALENCE_CHECK_EXPORT_REQUEST'),
    EQUIVALENCE_CHECK_EXPORT_SUCCESS: type('ASYNC_LINK:EQUIVALENCE_CHECK_EXPORT_SUCCESS'),
    EQUIVALENCE_CHECK_EXPORT_FAILURE: type('ASYNC_LINK:EQUIVALENCE_CHECK_EXPORT_FAILURE'),

    TMC_EXPORT_REQUEST: type('ASYNC_LINK:TMC_EXPORT_REQUEST'),
    TMC_EXPORT_SUCCESS: type('ASYNC_LINK:TMC_EXPORT_SUCCESS'),
    TMC_EXPORT_FAILURE: type('ASYNC_LINK:TMC_EXPORT_FAILURE'),
};

/** Watchlist Equivalence Export **/
export interface EquivalenceExportRequestAction extends Action {
    payload: {
        watchlistId: string;
        resultId: string;
        comments: MultiRowComments;
    };
}

export interface EquivalenceExportSuccessAction extends Action {
    payload: {
        link: string;
    };
}

export interface EquivalenceExportFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function equivalenceExportRequest(watchlistId: string, resultId: string, comments: MultiRowComments): EquivalenceExportRequestAction {
    return {
        type: AsyncLinkActionTypes.EQUIVALENCE_EXPORT_REQUEST,
        payload: {
            watchlistId,
            resultId,
            comments,
        },
    };
}

export function equivalenceExportSuccess(data: AsyncLinkResponseData): EquivalenceExportSuccessAction {
    return {
        type: AsyncLinkActionTypes.EQUIVALENCE_EXPORT_SUCCESS,
        payload: {
            link: data.link,
        },
    };
}

export function equivalenceExportFailure(error: AppError): EquivalenceExportFailureAction {
    return {
        type: AsyncLinkActionTypes.EQUIVALENCE_EXPORT_FAILURE,
        payload: {
            error,
        },
    };
}

export interface EquivalenceCheckExportRequestAction extends Action {
    payload: {
        productDq: string;
        equivalenceCheckId: string;
        comments: StringObject;
    };
}

export interface EquivalenceCheckExportSuccessAction extends Action {
    payload: {
        link: string;
    };
}

export interface EquivalenceCheckExportFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function equivalenceCheckExportRequest(
    productDq: string,
    equivalenceCheckId: string,
    comments: StringObject
): EquivalenceCheckExportRequestAction {
    return {
        type: AsyncLinkActionTypes.EQUIVALENCE_CHECK_EXPORT_REQUEST,
        payload: {
            productDq,
            equivalenceCheckId,
            comments
        }
    };
}

export function equivalenceCheckExportSuccess(data: AsyncLinkResponseData): EquivalenceCheckExportSuccessAction {
    return {
        type: AsyncLinkActionTypes.EQUIVALENCE_CHECK_EXPORT_SUCCESS,
        payload: {
            link: data.link
        }
    };
}

export function equivalenceCheckExportFailure(error: AppError): EquivalenceCheckExportFailureAction {
    return {
        type: AsyncLinkActionTypes.EQUIVALENCE_CHECK_EXPORT_FAILURE,
        payload: {
            error
        }
    };
}

/** Watchlist TMC Export **/
export interface TmcExportRequestAction extends Action {
    payload: {
        watchlistId: string;
        resultId?: string;
        comments: {
            [dq: string]: string;
        };
    };
}

export interface TmcExportSuccessAction extends Action {
    payload: {
        link: string;
    };
}

export interface TmcExportFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function tmcExportRequest(watchlistId: string, comments: Dictionary<string>): TmcExportRequestAction {
    return {
        type: AsyncLinkActionTypes.TMC_EXPORT_REQUEST,
        payload: {
            watchlistId,
            comments,
        },
    };
}

export function tmcExportSuccess(data: AsyncLinkResponseData): TmcExportSuccessAction {
    return {
        type: AsyncLinkActionTypes.TMC_EXPORT_SUCCESS,
        payload: {
            link: data.link,
        },
    };
}

export function tmcExportFailure(error: AppError): TmcExportFailureAction {
    return {
        type: AsyncLinkActionTypes.TMC_EXPORT_FAILURE,
        payload: {
            error,
        },
    };
}

/** ONE PAGER **/

export interface OnePagerRequestAction extends Action {
    payload: {
        productDqs: string[];
        type: string;
        targetMarketCriteria?: string;
        context?: string;
    };
}

export interface OnePagerSuccessAction extends Action {
    payload: {
        link: string;
        confirmationRequired: boolean;
        structure: {
            titlePath: string;
            descriptionPath: string;
            supervisionRequired: boolean;
            supervisionExplanationPath: string;
            productsPath: string;
            productSectionConfiguration: {
                widgets: EntityWidgetConfig[];
            };
            actionSectionConfiguration: {
                actions: {
                    type: string;
                    label: string;
                }[];
            };
        };
        values: Data;
    };
}

export interface OnePagerFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function onePagerRequest(
    productDqs: string[],
    t?: string,
    targetMarketCriteria?: string,
    context?: string
): OnePagerRequestAction {
    return {
        type: AsyncLinkActionTypes.ONE_PAGER_REQUEST,
        payload: {
            productDqs,
            type: t,
            targetMarketCriteria,
            context
        }
    };
}

export function onePagerSuccess(data: AsyncLinkResponseData): OnePagerSuccessAction {
    return {
        type: AsyncLinkActionTypes.ONE_PAGER_SUCCESS,
        payload: {
            link: data.link,
            confirmationRequired: data.confirmationRequired,
            structure: data.structure,
            values: data.values,
        },
    };
}

export function onePagerFailure(error: AppError): OnePagerFailureAction {
    return {
        type: AsyncLinkActionTypes.ONE_PAGER_FAILURE,
        payload: {
            error,
        },
    };
}

/** QUOTE RESULT EXPORT **/

export interface RFQResultExportRequestAction extends Action {
    payload: {
        moduleId: string;
        quoteId: string;
        redirectToken: string;
    };
}

export interface RFQResultExportSuccessAction extends Action {
    payload: {
        link: string;
    };
}

export interface RFQResultExportFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function rfqResultExportRequest(moduleId: string, quoteId: string, redirectToken: string): RFQResultExportRequestAction {
    return {
        type: AsyncLinkActionTypes.RFQ_EXPORT_RESULT_QUOTE_REQUEST,
        payload: {
            moduleId,
            quoteId,
            redirectToken,
        },
    };
}

export function rfqResultExportSuccess(data: AsyncLinkResponseData): RFQResultExportSuccessAction {
    return {
        type: AsyncLinkActionTypes.RFQ_EXPORT_RESULT_QUOTE_SUCCESS,
        payload: {
            link: data.link,
        },
    };
}

export function rfqResultExportFailure(error: AppError): RFQResultExportFailureAction {
    return {
        type: AsyncLinkActionTypes.RFQ_EXPORT_RESULT_QUOTE_FAILURE,
        payload: {
            error,
        },
    };
}

/** QUOTE REQUEST EXPORT **/

export interface RFQRequestExportRequestAction extends Action {
    payload: {
        moduleId: string;
        quoteId: string;
        redirectToken: string;
    };
}

export interface RFQRequestExportSuccessAction extends Action {
    payload: {
        link: string;
    };
}

export interface RFQRequestExportFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function rfqRequestExportRequest(moduleId: string, quoteId: string, redirectToken: string): RFQRequestExportRequestAction {
    return {
        type: AsyncLinkActionTypes.RFQ_EXPORT_REQUEST_QUOTE_REQUEST,
        payload: {
            moduleId,
            quoteId,
            redirectToken,
        },
    };
}

export function rfqRequestExportSuccess(data: AsyncLinkResponseData): RFQRequestExportSuccessAction {
    return {
        type: AsyncLinkActionTypes.RFQ_EXPORT_REQUEST_QUOTE_SUCCESS,
        payload: {
            link: data.link,
        },
    };
}

export function rfqRequestExportFailure(error: AppError): RFQRequestExportFailureAction {
    return {
        type: AsyncLinkActionTypes.RFQ_EXPORT_REQUEST_QUOTE_FAILURE,
        payload: {
            error,
        },
    };
}

/** QUOTE MULTI REQUEST EXCEL EXPORT **/

export interface RFQMultiRequestExcelExportRequestAction extends Action {
    payload: {
        moduleId: string;
        quoteId: string;
    };
}

export interface RFQMultiRequestExcelExportSuccessAction extends Action {
    payload: {
        link: string;
    };
}

export interface RFQMultiRequestExcelExportFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function rfqMultiRequestExcelExportRequest(moduleId: string, quoteId: string): RFQMultiRequestExcelExportRequestAction {
    return {
        type: AsyncLinkActionTypes.RFQ_MULTI_EXCEL_EXPORT_REQUEST,
        payload: {
            moduleId,
            quoteId,
        },
    };
}

export function rfqMultiRequestExcelExportSuccess(data: AsyncLinkResponseData): RFQMultiRequestExcelExportSuccessAction {
    return {
        type: AsyncLinkActionTypes.RFQ_MULTI_EXCEL_EXPORT_SUCCESS,
        payload: {
            link: data.link,
        },
    };
}

export function rfqMultiRequestExcelExportFailure(error: AppError): RFQMultiRequestExcelExportFailureAction {
    return {
        type: AsyncLinkActionTypes.RFQ_MULTI_EXCEL_EXPORT_FAILURE,
        payload: {
            error,
        },
    };
}

/** SHORTLIST EXPORT **/

export interface ShortlistExportRequestAction extends Action {
    payload: {
        id: string;
        dqs?: string[]
    };
}

export interface ShortlistExportSuccessAction extends Action {
    payload: {
        link: string;
    };
}

export interface ShortlistExportFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function shortlistExportRequest(id: string, dqs?: string[]): ShortlistExportRequestAction {
    return {
        type: AsyncLinkActionTypes.SHORTLIST_EXPORT_REQUEST,
        payload: {
            id,
            dqs,
        },
    };
}

export function shortlistExportSuccess(data: AsyncLinkResponseData): ShortlistExportSuccessAction {
    return {
        type: AsyncLinkActionTypes.SHORTLIST_EXPORT_SUCCESS,
        payload: {
            link: data.link,
        },
    };
}

export function shortlistExportFailure(error: AppError): ShortlistExportFailureAction {
    return {
        type: AsyncLinkActionTypes.SHORTLIST_EXPORT_FAILURE,
        payload: {
            error,
        },
    };
}

export interface ShortlistGroupedExportRequestAction extends Action {
    payload: {
        id: string;
    };
}

export interface ShortlistGroupedExportSuccessAction extends Action {
    payload: {
        link: string;
    };
}

export interface ShortlistGroupedExportFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function shortlistGroupedExportRequest(id: string): ShortlistGroupedExportRequestAction {
    return {
        type: AsyncLinkActionTypes.SHORTLIST_GROUPED_EXPORT_REQUEST,
        payload: {
            id
        }
    };
}

export function shortlistGroupedExportSuccess(data: AsyncLinkResponseData): ShortlistGroupedExportSuccessAction {
    return {
        type: AsyncLinkActionTypes.SHORTLIST_GROUPED_EXPORT_SUCCESS,
        payload: {
            link: data.link
        }
    };
}

export function shortlistGroupedExportFailure(error: AppError): ShortlistGroupedExportFailureAction {
    return {
        type: AsyncLinkActionTypes.SHORTLIST_GROUPED_EXPORT_FAILURE,
        payload: {
            error
        }
    };
}

/** TMC CHECK REPORT **/

export interface TmcCheckReportRequestAction extends Action {
    payload: {
        reference: {
            tmc: string;
            label: string;
        };
        comment?: string;
        productDq: string;
    };
}

export interface TmcCheckReportSuccessAction extends Action {
    payload: {
        link: string;
    };
}

export interface TmcCheckReportFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function tmcCheckReportRequest(reference: Record<'tmc' | 'label', string>, productDq: string, comment?: string): TmcCheckReportRequestAction {
    return {
        type: AsyncLinkActionTypes.TMC_CHECK_REPORT_REQUEST,
        payload: {
            reference,
            productDq,
            comment,
        },
    };
}

export function tmcCheckReportSuccess(data: AsyncLinkResponseData): TmcCheckReportSuccessAction {
    return {
        type: AsyncLinkActionTypes.TMC_CHECK_REPORT_SUCCESS,
        payload: {
            link: data.link,
        },
    };
}

export function tmcCheckReportFailure(error: AppError): TmcCheckReportFailureAction {
    return {
        type: AsyncLinkActionTypes.TMC_CHECK_REPORT_FAILURE,
        payload: {
            error,
        },
    };
}

/** PRODUCTS EXCEL **/

export interface ProductsExcelRequestAction extends Action {
    payload: {
        dqs: string[];
        context: string;
    };
}

export interface ProductsExcelAllRequestAction extends Action {
    payload: object;
}

export interface ProductsExcelSuccessAction extends Action {
    payload: {
        link: string;
    };
}

export interface ProductsExcelFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function productsExcelRequest(dqs: string[], context: string): ProductsExcelRequestAction {
    return {
        type: AsyncLinkActionTypes.PRODUCTS_EXCEL_REQUEST,
        payload: {
            dqs,
            context
        }
    };
}

export function productsExcelAllRequest(): ProductsExcelAllRequestAction {
    return {
        type: AsyncLinkActionTypes.PRODUCTS_EXCEL_ALL_REQUEST,
        payload: {},
    };
}

export function productsExcelSuccess(data: AsyncLinkResponseData): ProductsExcelSuccessAction {
    return {
        type: AsyncLinkActionTypes.PRODUCTS_EXCEL_SUCCESS,
        payload: {
            link: data.link,
        },
    };
}

export function productsExcelFailure(error: AppError): ProductsExcelFailureAction {
    return {
        type: AsyncLinkActionTypes.TRADES_EXCEL_FAILURE,
        payload: {
            error,
        },
    };
}

/** WATCHLIST EXCEL */
export interface WatchlistExcelRequestAction extends Action {
    payload: {
        watchlistId: string;
        dqs: string[];
        context: string;
    };
}

export function watchlistExcelRequest(
    watchlistId: string,
    dqs: string[],
    context: string
): WatchlistExcelRequestAction {
    return {
        type: AsyncLinkActionTypes.WATCHLIST_EXCEL_REQUEST,
        payload: {
            watchlistId,
            dqs,
            context
        }
    };
}

export interface WatchlistExcelSuccessAction extends Action {
    payload: {
        link: string;
    };
}

export function watchlistExcelSuccess(data: AsyncLinkResponseData): WatchlistExcelSuccessAction {
    return {
        type: AsyncLinkActionTypes.WATCHLIST_EXCEL_SUCCESS,
        payload: {
            link: data.link,
        },
    };
}

export interface WatchlistExcelFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function watchlistExcelFailure(error: AppError): WatchlistExcelFailureAction {
    return {
        type: AsyncLinkActionTypes.WATCHLIST_EXCEL_FAILURE,
        payload: {
            error,
        },
    };
}

/** USERS EXCEL **/
export interface UsersExcelRequestAction extends Action {
    payload: {
        users: string[];
    };
}

export interface UsersExcelSuccessAction extends Action {
    payload: {
        link: string;
    };
}

export interface UsersExcelFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function usersExcelRequest(users: string[]): UsersExcelRequestAction {
    return {
        type: AsyncLinkActionTypes.USERS_EXCEL_REQUEST,
        payload: {
            users,
        },
    };
}

export function usersExcelSuccess(data: AsyncLinkResponseData): UsersExcelSuccessAction {
    return {
        type: AsyncLinkActionTypes.USERS_EXCEL_SUCCESS,
        payload: {
            link: data.link,
        },
    };
}

export function usersExcelFailure(error: AppError): UsersExcelFailureAction {
    return {
        type: AsyncLinkActionTypes.USERS_EXCEL_FAILURE,
        payload: {
            error,
        },
    };
}

/** ALL USERS EXCEL **/
export interface UsersExcelAllRequestAction extends Action {
    payload?: void;
}

export interface UsersExcelAllSuccessAction extends Action {
    payload: {
        link: string;
    };
}

export interface UsersExcelAllFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function usersExcelAllRequest(): UsersExcelAllRequestAction {
    return {
        type: AsyncLinkActionTypes.USERS_EXCEL_ALL_REQUEST,
    };
}

export function usersExcelAllSuccess(data: AsyncLinkResponseData): UsersExcelAllSuccessAction {
    return {
        type: AsyncLinkActionTypes.USERS_EXCEL_ALL_SUCCESS,
        payload: {
            link: data.link,
        },
    };
}

export function usersExcelAllFailure(error: AppError): UsersExcelAllFailureAction {
    return {
        type: AsyncLinkActionTypes.USERS_EXCEL_ALL_FAILURE,
        payload: {
            error,
        },
    };
}

/** TRADES EXCEL **/

export interface TradesExcelRequestAction extends Action {
    payload: {
        dqs: string[];
    };
}

export interface TradesExcelSuccessAction extends Action {
    payload: {
        link: string;
    };
}

export interface TradesExcelFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function tradesExcelRequest(dqs: string[]): TradesExcelRequestAction {
    return {
        type: AsyncLinkActionTypes.TRADES_EXCEL_REQUEST,
        payload: {
            dqs,
        },
    };
}

export function tradesExcelSuccess(data: AsyncLinkResponseData): TradesExcelSuccessAction {
    return {
        type: AsyncLinkActionTypes.TRADES_EXCEL_SUCCESS,
        payload: {
            link: data.link,
        },
    };
}

export function tradesExcelFailure(error: AppError): TradesExcelFailureAction {
    return {
        type: AsyncLinkActionTypes.TRADES_EXCEL_FAILURE,
        payload: {
            error,
        },
    };
}

/** TRADES EXCEL ALL **/

export interface TradesExcelAllRequestAction extends Action {
    payload: {
        sessionId: string;
    };
}

export interface TradesExcelAllSuccessAction extends Action {
    payload: {
        link: string;
    };
}

export interface TradesExcelAllFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function tradesExcelAllRequest(sessionId: string): TradesExcelAllRequestAction {
    return {
        type: AsyncLinkActionTypes.TRADES_EXCEL_ALL_REQUEST,
        payload: {
            sessionId,
        },
    };
}

export function tradesExcelAllSuccess(data: AsyncLinkResponseData): TradesExcelAllSuccessAction {
    return {
        type: AsyncLinkActionTypes.TRADES_EXCEL_ALL_SUCCESS,
        payload: {
            link: data.link,
        },
    };
}

export function tradesExcelAllFailure(error: AppError): TradesExcelAllFailureAction {
    return {
        type: AsyncLinkActionTypes.TRADES_EXCEL_ALL_FAILURE,
        payload: {
            error,
        },
    };
}

/** ASSETS EXCEL **/

export interface AssetsExcelRequestAction extends Action {
    payload: {
        sessionId: string;
        dqs: string[];
    };
}

export interface AssetsExcelSuccessAction extends Action {
    payload: {
        link: string;
    };
}

export interface AssetsExcelFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function assetsExcelRequest(sessionId: string, dqs: string[]): AssetsExcelRequestAction {
    return {
        type: AsyncLinkActionTypes.ASSETS_EXCEL_REQUEST,
        payload: {
            sessionId,
            dqs,
        },
    };
}

export function assetsExcelSuccess(data: AsyncLinkResponseData): AssetsExcelSuccessAction {
    return {
        type: AsyncLinkActionTypes.ASSETS_EXCEL_SUCCESS,
        payload: {
            link: data.link,
        },
    };
}

export function assetsExcelFailure(error: AppError): AssetsExcelFailureAction {
    return {
        type: AsyncLinkActionTypes.ASSETS_EXCEL_FAILURE,
        payload: {
            error,
        },
    };
}

/** ASSETS EXCEL ALL **/

export interface AssetsExcelAllRequestAction extends Action {
    payload: {
        sessionId: string;
    };
}

export interface AssetsExcelAllSuccessAction extends Action {
    payload: {
        link: string;
    };
}

export interface AssetsExcelAllFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function assetsExcelAllRequest(sessionId: string): AssetsExcelAllRequestAction {
    return {
        type: AsyncLinkActionTypes.ASSETS_EXCEL_ALL_REQUEST,
        payload: {
            sessionId,
        },
    };
}

export function assetsExcelAllSuccess(data: AsyncLinkResponseData): AssetsExcelAllSuccessAction {
    return {
        type: AsyncLinkActionTypes.ASSETS_EXCEL_ALL_SUCCESS,
        payload: {
            link: data.link,
        },
    };
}

export function assetsExcelAllFailure(error: AppError): AssetsExcelAllFailureAction {
    return {
        type: AsyncLinkActionTypes.ASSETS_EXCEL_ALL_FAILURE,
        payload: {
            error,
        },
    };
}

/** REPORT **/

export interface ReportRequestAction extends Action {
    payload: {
        data: ReportRequestData;
    };
}

export interface ReportSuccessAction extends Action {
    payload: {
        link: string;
        confirmationRequired: boolean;
        structure: {
            titlePath: string;
            descriptionPath: string;
            supervisionRequired: boolean;
            supervisionExplanationPath: string;
            productsPath: string;
            productSectionConfiguration: {
                widgets: EntityWidgetConfig[];
            };
            actionSectionConfiguration: {
                actions: {
                    type: string;
                    label: string;
                }[];
            };
        };
        values: Data;
    };
}

export interface ReportFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function reportRequest(data: ReportRequestData): ReportRequestAction {
    return {
        type: AsyncLinkActionTypes.REPORT_REQUEST,
        payload: {
            data,
        },
    };
}

export function reportSuccess(data: AsyncLinkResponseData): ReportSuccessAction {
    return {
        type: AsyncLinkActionTypes.REPORT_SUCCESS,
        payload: {
            link: data.link,
            confirmationRequired: data.confirmationRequired,
            structure: data.structure,
            values: data.values,
        },
    };
}

export function reportFailure(error: AppError): ReportFailureAction {
    return {
        type: AsyncLinkActionTypes.REPORT_FAILURE,
        payload: {
            error,
        },
    };
}

/** MULTI PRODUCT REPORT **/

export interface MultiProductReportRequestAction extends Action {
    payload: {
        data: MultiProductReportRequestData;
    };
}

export function multiProductReportRequest(data: MultiProductReportRequestData): MultiProductReportRequestAction {
    return {
        type: AsyncLinkActionTypes.MULTI_PRODUCT_REPORT_REQUEST,
        payload: {
            data,
        },
    };
}

/** ATTACHMENT **/

export interface AttachmentRequestAction extends Action {
    payload: {
        attachmentId: string;
    };
}

export interface AttachmentSuccessAction extends Action {
    payload: {
        link: string;
    };
}

export interface AttachmentFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function attachmentRequest(attachmentId: string): AttachmentRequestAction {
    return {
        type: AsyncLinkActionTypes.ATTACHMENT_REQUEST,
        payload: {
            attachmentId,
        },
    };
}

export function attachmentSuccess(data: AsyncLinkResponseData): AttachmentSuccessAction {
    return {
        type: AsyncLinkActionTypes.ATTACHMENT_SUCCESS,
        payload: {
            link: data.link,
        },
    };
}

export function attachmentFailure(error: AppError): AttachmentFailureAction {
    return {
        type: AsyncLinkActionTypes.ATTACHMENT_FAILURE,
        payload: {
            error,
        },
    };
}

/** EXTERNAL PRODUCT LINK **/

export interface ExternalProductLinkRequestAction extends Action {
    payload: {
        productDq: string;
        linkType: string;
        tmc?: string;
    };
}

export interface ExternalProductLinkSuccessAction extends Action {
    payload: {
        link: string;
        confirmationRequired: boolean;
        structure: {
            titlePath: string;
            descriptionPath: string;
            supervisionRequired: boolean;
            supervisionExplanationPath: string;
            productsPath: string;
            productSectionConfiguration: {
                widgets: EntityWidgetConfig[];
            };
            actionSectionConfiguration: {
                actions: {
                    type: string;
                    label: string;
                }[];
            };
        };
        values: Data;
    };
}

export interface ExternalProductLinkFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function externalProductLinkRequest(productDq: string, linkType: string, tmc?: string): ExternalProductLinkRequestAction {
    return {
        type: AsyncLinkActionTypes.EXTERNAL_PRODUCT_LINK_REQUEST,
        payload: {
            productDq,
            linkType,
            tmc
        }
    };
}

export function externalProductLinkSuccess(data: AsyncLinkResponseData): ExternalProductLinkSuccessAction {
    return {
        type: AsyncLinkActionTypes.EXTERNAL_PRODUCT_LINK_SUCCESS,
        payload: {
            link: data.link,
            confirmationRequired: data.confirmationRequired,
            structure: data.structure,
            values: data.values,
        },
    };
}

export function externalProductLinkFailure(error: AppError): ExternalProductLinkFailureAction {
    return {
        type: AsyncLinkActionTypes.EXTERNAL_PRODUCT_LINK_FAILURE,
        payload: {
            error,
        },
    };
}

/**
 * BWList History
 */

export interface BWListHistoryRequestAction extends Action {
    payload: {
        module: string;
        timestamp: Date;
        instrumentTypeRestrictions: string[];
    };
}

export interface BWListHistorySuccessAction extends Action {
    payload: {
        link: string;
        confirmationRequired: boolean;
        structure: any;
        values: Data;
    };
}

export interface BWListHistoryFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function bwListHistoryRequest(
    module: string,
    timestamp: Date,
    instrumentTypeRestrictions: string[],
): BWListHistoryRequestAction {
    return {
        type: AsyncLinkActionTypes.BW_LIST_HISTORY_REQUEST,
        payload: {
            module,
            timestamp,
            instrumentTypeRestrictions,
        },
    };
}

export function bwListHistorySuccess(data: AsyncLinkResponseData): BWListHistorySuccessAction {
    return {
        type: AsyncLinkActionTypes.BW_LIST_HISTORY_SUCCESS,
        payload: {
            link: data.link,
            confirmationRequired: data.confirmationRequired,
            structure: data.structure,
            values: data.values,
        },
    };
}

export function bwListHistoryFailure(error: AppError): BWListHistoryFailureAction {
    return {
        type: AsyncLinkActionTypes.BW_LIST_HISTORY_FAILURE,
        payload: {
            error,
        },
    };
}

export type FailureAction =
    | AssetsExcelAllFailureAction
    | AssetsExcelFailureAction
    | AttachmentFailureAction
    | ExternalProductLinkFailureAction
    | OnePagerFailureAction
    | ProductsExcelFailureAction
    | ReportFailureAction
    | TradesExcelAllFailureAction
    | TradesExcelFailureAction
    | BWListHistoryFailureAction
    | TmcCheckReportFailureAction
    | RFQResultExportFailureAction
    | RFQRequestExportFailureAction
    | RFQMultiRequestExcelExportFailureAction
    | EquivalenceExportFailureAction
    | TmcExportFailureAction;

export const AsyncLinkActions = createActionGroup({
    source: 'Async Link',
    events: {
        'Product Compare Export': props<{ dqs: Array<string> }>(),
        
        'Export Success': props<{ link: string }>(),
        'Export Failure': props<{ payload: { error: AppError }}>()
    }
});
