import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ImageReferenceAddress } from '../../../../core/models/image-reference.model';
import { ImageReferenceResolverService } from '../../../../core/services/image-reference-resolver.service';
import { EntityWidgetOptions } from '../../../models/widgets.model';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { FocusListWidgetItemComponent } from './focus-list-widget-item/focus-list-widget-item.component';
import { NgFor } from '@angular/common';

export interface FocusListItem {
    enabledBW?: boolean;
    watchlistId?: string;
    mode?: string;
    targetId?: string;
    type?: string;
    imageReference: ImageReferenceAddress;
    title: string;
    itemImage?: string;
}

@Component({
    selector: 'app-focus-list-widget',
    templateUrl: './focus-list-widget.component.html',
    styleUrls: ['./focus-list-widget.component.scss'],
    standalone: true,
    imports: [NgFor, FocusListWidgetItemComponent]
})
export class FocusListWidgetComponent implements OnInit, OnChanges, EntityWidgetContentComponent {
    public static disableTitle = true;

    @HostBinding('class.focus-list-widget') public focusListWidgetClass = true;

    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public options: EntityWidgetOptions;
    @Input() public data: FocusListItem[];
    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();

    constructor(private readonly imageResolver: ImageReferenceResolverService) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.data && this.options) {

        }
    }

    public ngOnInit() {
    }

    public onActionClicked(action: { type: string; payload: any }) {
        this.action.next(action);
    }

    public onListItemClicked($event: MouseEvent, item: FocusListItem) {
        $event.preventDefault();

        if (item.mode) {
            this.action.next({
                type: 'target-open',
                payload: {
                    mode: item.mode,
                    id: item.targetId,
                    type: item.type,
                },
            });
        } else if (item.watchlistId) {
            this.action.next({
                type: 'watchlist-open',
                payload: {
                    watchlistId: item.watchlistId,
                },
            });
        }
    }
}
