import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthStoreService } from '../services/auth-store.service';
import { AuthService } from '../services/auth.service';

/**
 * Guard class that prevents unauthenticated users from accessing restricted parts of the application
 */
@Injectable()
export class AuthGuard  {
    constructor(
        private authStoreService: AuthStoreService,
        private authService: AuthService,
    ) {
    }

    /**
     * Guard method called when a route is requested to be activated
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authStoreService.isRefreshTokenStillValid().pipe(
            take(1),
            map((isValid) => {
                if (!isValid) {
                    const url = state.url;
                    this.authService.signOut(true, url);
                }
                return isValid;
            }),
        );
    }
}
