import { Action } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { Data } from '../../../../core/models/data.model';
import { type } from '../../../../core/store/util';
import { Entity, EntityTableConfig } from '../../../../shared/models/entities.model';
import { EntityWidgetConfig } from '../../../../shared/models/widgets.model';
import { DataRequestResponse, UnderlyingInfoBoxStructure } from '../../api/models/dpc.model';
import { DPCConfig, DPCInitializeStructure, DPCQuote, RestoredHistoryData } from '../../models/dpc.model';

/**
 * Created by Alex Klein <alex@rocketloop.de> on 02/01/18.
 */

export const DPCActionTypes = {
    LOAD_DPC_INITIALIZE_STRUCTURE: type('DPC:LOAD_DPC_INITIALIZE_STRUCTURE'),
    LOAD_DPC_INITIALIZE_STRUCTURE_SUCCEEDED: type('DPC:LOAD_DPC_INITIALIZE_STRUCTURE_SUCCEEDED'),
    LOAD_DPC_INITIALIZE_STRUCTURE_FAILED: type('DPC:LOAD_DPC_INITIALIZE_STRUCTURE_FAILED'),

    UPDATE_DPC_CONFIG: type('DPC:UPDATE_DPC_CONFIG'),
    UPDATE_DPC_CONFIG_SUCCEEDED: type('DPC:UPDATE_DPC_CONFIG_SUCCEEDED'),
    UPDATE_DPC_CONFIG_FAILED: type('DPC:UPDATE_DPC_CONFIG_FAILED'),

    UPDATE_QUOTE_REQUEST: type('DPC:UPDATE_QUOTE_REQUEST'),

    REQUEST_QUOTE: type('DPC:REQUEST_QUOTE'),
    REQUEST_QUOTE_SUCCEEDED: type('DPC:REQUEST_QUOTE_SUCCEEDED'),
    REQUEST_QUOTE_FAILED: type('DPC:REQUEST_QUOTE_FAILED'),

    GET_QUOTE_OFFERS: type('DPC:GET_QUOTE_OFFERS'),
    GET_QUOTE_OFFERS_SUCCEEDED: type('DPC:GET_QUOTE_OFFERS_SUCCEEDED'),
    GET_QUOTE_OFFERS_FAILED: type('DPC:GET_QUOTE_OFFERS_FAILED'),

    GET_RECENT_HISTORY_STRUCTURE: type('DPC:GET_RECENT_HISTORY_STRUCTURE'),
    GET_RECENT_HISTORY_STRUCTURE_SUCCEEDED: type('DPC:GET_RECENT_HISTORY_STRUCTURE_SUCCEEDED'),
    GET_RECENT_HISTORY_STRUCTURE_FAILED: type('DPC:GET_RECENT_HISTORY_STRUCTURE_FAILED'),

    GET_RECENT_HISTORY_DATA: type('DPC:GET_RECENT_HISTORY_DATA'),
    GET_RECENT_HISTORY_DATA_SUCCEEDED: type('DPC:GET_RECENT_HISTORY_DATA_SUCCEEDED'),
    GET_RECENT_HISTORY_DATA_FAILED: type('DPC:GET_RECENT_HISTORY_DATA_FAILED'),

    SET_CURRENT_UNDERLYING_INFO_BOX_TAB_ID: type('DPC:SET_CURRENT_UNDERLYING_INFO_BOX_TAB_ID'),

    LOAD_DPC_UNDERLYING_INFO_BOX_STRUCTURE: type('DPC:LOAD_DPC_UNDERLYING_INFO_BOX_STRUCTURE'),
    LOAD_DPC_UNDERLYING_INFO_BOX_STRUCTURE_SUCCEEDED: type('DPC:LOAD_DPC_UNDERLYING_INFO_BOX_STRUCTURE_SUCCEEDED'),
    LOAD_DPC_UNDERLYING_INFO_BOX_STRUCTURE_FAILED: type('DPC:LOAD_DPC_UNDERLYING_INFO_BOX_STRUCTURE_FAILED'),

    LOAD_DPC_UNDERLYING_INFO_BOX_TAB_STRUCTURE: type('DPC:LOAD_DPC_UNDERLYING_INFO_BOX_TAB_STRUCTURE'),
    LOAD_DPC_UNDERLYING_INFO_BOX_TAB_STRUCTURE_SUCCEEDED: type('DPC:LOAD_DPC_UNDERLYING_INFO_BOX_TAB_STRUCTURE_SUCCEEDED'),
    LOAD_DPC_UNDERLYING_INFO_BOX_TAB_STRUCTURE_FAILED: type('DPC:LOAD_DPC_UNDERLYING_INFO_BOX_TAB_STRUCTURE_FAILED'),

    LOAD_DPC_UNDERLYING_INFO_BOX_TAB_DATA: type('DPC:LOAD_DPC_UNDERLYING_INFO_BOX_TAB_DATA'),
    LOAD_DPC_UNDERLYING_INFO_BOX_TAB_DATA_SUCCEEDED: type('DPC:LOAD_DPC_UNDERLYING_INFO_BOX_TAB_DATA_SUCCEEDED'),
    LOAD_DPC_UNDERLYING_INFO_BOX_TAB_DATA_FAILED: type('DPC:LOAD_DPC_UNDERLYING_INFO_BOX_TAB_DATA_FAILED'),

    LOAD_ISSUED_PRODUCTS_STRUCTURE: type('DPC:LOAD_ISSUED_PRODUCTS_STRUCTURE'),
    LOAD_ISSUED_PRODUCTS_STRUCTURE_SUCCEEDED: type('DPC:LOAD_ISSUED_PRODUCTS_STRUCTURE_SUCCEEDED'),
    LOAD_ISSUED_PRODUCTS_STRUCTURE_FAILED: type('DPC:LOAD_ISSUED_PRODUCTS_STRUCTURE_FAILED'),

    LOAD_ISSUED_PRODUCTS_DATA: type('DPC:LOAD_ISSUED_PRODUCTS_DATA'),
    LOAD_ISSUED_PRODUCTS_DATA_SUCCEEDED: type('DPC:LOAD_ISSUED_PRODUCTS_DATA_SUCCEEDED'),
    LOAD_ISSUED_PRODUCTS_DATA_FAILED: type('DPC:LOAD_ISSUED_PRODUCTS_DATA_FAILED'),

    LOAD_HISTORY_STRUCTURE: type('DPC:LOAD_HISTORY_STRUCTURE'),
    LOAD_HISTORY_STRUCTURE_SUCCEEDED: type('DPC:LOAD_HISTORY_STRUCTURE_SUCCEEDED'),
    LOAD_HISTORY_STRUCTURE_FAILED: type('DPC:LOAD_HISTORY_STRUCTURE_FAILED'),

    LOAD_HISTORY_DATA: type('DPC:LOAD_HISTORY_DATA'),
    LOAD_HISTORY_DATA_SUCCEEDED: type('DPC:LOAD_HISTORY_DATA_SUCCEEDED'),
    LOAD_HISTORY_DATA_FAILED: type('DPC:LOAD_HISTORY_DATA_FAILED'),

    LOAD_HISTORY_DATA_FOR_RESTORE: type('DPC:LOAD_HISTORY_DATA_FOR_RESTORE'),
    LOAD_HISTORY_DATA_FOR_RESTORE_SUCCEEDED: type('DPC:LOAD_HISTORY_DATA_FOR_RESTORE_SUCCEEDED'),
    LOAD_HISTORY_DATA_FOR_RESTORE_FAILED: type('DPC:LOAD_HISTORY_DATA_FOR_RESTORE_FAILED'),

    ISSUE_PRODUCT: type('DPC:ISSUE_PRODUCT'),
    ISSUE_PRODUCT_SUCCEEDED: type('DPC:ISSUE_PRODUCT_SUCCEEDED'),
    ISSUE_PRODUCT_FAILED: type('DPC:ISSUE_PRODUCT_FAILED'),

    RESET_DPC_STATE: type('DPC:RESET_DPC_STATE'),

    ADD_QUOTE_TO_SELECTION: type('DPC:ADD_QUOTE_TO_SELECTION'),
    ADD_QUOTES_TO_SELECTION: type('DPC:ADD_QUOTES_TO_SELECTION'),
    REMOVE_QUOTE_FROM_SELECTION: type('DPC:REMOVE_QUOTE_FROM_SELECTION'),
    REMOVE_QUOTES_FROM_SELECTION: type('DPC:REMOVE_QUOTES_FROM_SELECTION'),

    CLEAR_QUOTE_SELECTION: type('DPC:CLEAR_QUOTE_SELECTION'),
};

/** LoadDPCInitializeStructureAction **/

export interface LoadDPCInitializeStructureAction extends Action {
    payload: object;
}

export function loadDPCInitializeStructure(): LoadDPCInitializeStructureAction {
    return {
        type: DPCActionTypes.LOAD_DPC_INITIALIZE_STRUCTURE,
        payload: {},
    };
}

/** LoadDPCInitializeStructureSucceededAction **/

export interface LoadDPCInitializeStructureSucceededAction extends Action {
    payload: {
        structure: DPCInitializeStructure,
    };
}

export function loadDPCInitializeStructureSucceeded(structure: DPCInitializeStructure): LoadDPCInitializeStructureSucceededAction {
    return {
        type: DPCActionTypes.LOAD_DPC_INITIALIZE_STRUCTURE_SUCCEEDED,
        payload: {
            structure,
        },
    };
}

/** LoadDPCInitializeStructureFailedAction **/

export interface LoadDPCInitializeStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadDPCInitializeStructureFailed(error: AppError): LoadDPCInitializeStructureFailedAction {
    return {
        type: DPCActionTypes.LOAD_DPC_INITIALIZE_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadDPCInitializeStructureAction **/

export interface UpdateDPCConfigAction extends Action {
    payload: {
        quoteRequest: Data;
        shadowRequest: Data;
    };
}

export function updateDPCConfig(quoteRequest: Data = null, shadowRequest: Data = null): UpdateDPCConfigAction {
    return {
        type: DPCActionTypes.UPDATE_DPC_CONFIG,
        payload: {
            quoteRequest,
            shadowRequest,
        },
    };
}

/** UpdateDPCConfigSucceededAction **/

export interface UpdateDPCConfigSucceededAction extends Action {
    payload: {
        config: DPCConfig,
    };
}

export function updateDPCConfigSucceeded(config: DPCConfig): UpdateDPCConfigSucceededAction {
    return {
        type: DPCActionTypes.UPDATE_DPC_CONFIG_SUCCEEDED,
        payload: {
            config,
        },
    };
}

/** UpdateDPCConfigFailedAction **/

export interface UpdateDPCConfigFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function updateDPCConfigFailed(error: AppError): UpdateDPCConfigFailedAction {
    return {
        type: DPCActionTypes.UPDATE_DPC_CONFIG_FAILED,
        payload: {
            error,
        },
    };
}

/** UpdateQuoteRequestAction **/

export interface UpdateQuoteRequestAction extends Action {
    payload: {
        changes: Data;
    };
}

export function updateQuoteRequest(changes: Data): UpdateQuoteRequestAction {
    return {
        type: DPCActionTypes.UPDATE_QUOTE_REQUEST,
        payload: {
            changes,
        },
    };
}

/** RequestQuoteAction **/

export interface RequestQuoteAction extends Action {
    payload: {
        quote: Data;
    };
}

export function requestQuote(quote: Data): RequestQuoteAction {
    return {
        type: DPCActionTypes.REQUEST_QUOTE,
        payload: {
            quote,
        },
    };
}

/** UpdateDPCConfigSucceededAction **/

export interface RequestQuoteSucceededAction extends Action {
    payload: {
        quote: DPCQuote,
    };
}

export function requestQuoteSucceeded(quote: DPCQuote): RequestQuoteSucceededAction {
    return {
        type: DPCActionTypes.REQUEST_QUOTE_SUCCEEDED,
        payload: {
            quote,
        },
    };
}

/** UpdateDPCConfigFailedAction **/

export interface RequestQuoteFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function requestQuoteFailed(error: AppError): RequestQuoteFailedAction {
    return {
        type: DPCActionTypes.REQUEST_QUOTE_FAILED,
        payload: {
            error,
        },
    };
}

/** GetQuoteOffersAction **/

export interface GetQuoteOffersAction extends Action {
    payload: {
        quoteId: string;
    };
}

export function getQuoteOffers(quoteId: string): GetQuoteOffersAction {
    return {
        type: DPCActionTypes.GET_QUOTE_OFFERS,
        payload: {
            quoteId,
        },
    };
}

/** UpdateDPCConfigSucceededAction **/

export interface GetQuoteOffersSucceededAction extends Action {
    payload: {
        offers: Entity[],
    };
}

export function getQuoteOffersSucceeded(offers: Entity[]): GetQuoteOffersSucceededAction {
    return {
        type: DPCActionTypes.GET_QUOTE_OFFERS_SUCCEEDED,
        payload: {
            offers,
        },
    };
}

/** GetQuoteOffersFailedAction **/

export interface GetQuoteOffersFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function getQuoteOffersFailed(error: AppError): GetQuoteOffersFailedAction {
    return {
        type: DPCActionTypes.GET_QUOTE_OFFERS_FAILED,
        payload: {
            error,
        },
    };
}

/** GetRecentHistoryStructureAction **/

export interface GetRecentHistoryStructureAction extends Action {
    payload?: void;
}

export function getRecentHistoryStructure(): GetRecentHistoryStructureAction {
    return {
        type: DPCActionTypes.GET_RECENT_HISTORY_STRUCTURE,
    };
}

/** GetRecentHistoryStructureSucceededAction **/

export interface GetRecentHistoryStructureSucceededAction extends Action {
    payload: {
        structure: EntityTableConfig;
    };
}

export function getRecentHistoryStructureSucceeded(structure: EntityTableConfig): GetRecentHistoryStructureSucceededAction {
    return {
        type: DPCActionTypes.GET_RECENT_HISTORY_STRUCTURE_SUCCEEDED,
        payload: {
            structure,
        },
    };
}

/**  GetRecentHistoryStructureFailedAction **/

export interface GetRecentHistoryStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function getRecentHistoryStructureFailed(error: AppError): GetRecentHistoryStructureFailedAction {
    return {
        type: DPCActionTypes.GET_RECENT_HISTORY_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/** GetRecentHistoryDataAction **/

export interface GetRecentHistoryDataAction extends Action {
    payload: {
        limit: number;
        offset: number;
    };
}

export function getRecentHistoryData(offset: number, limit: number): GetRecentHistoryDataAction {
    return {
        type: DPCActionTypes.GET_RECENT_HISTORY_DATA,
        payload: {
            offset,
            limit,
        },
    };
}

/** GetRecentHistoryDataSucceededAction **/

export interface GetRecentHistoryDataSucceededAction extends Action {
    payload: {
        data: Entity[],
    };
}

export function getRecentHistoryDataSucceeded(data: Entity[]): GetRecentHistoryDataSucceededAction {
    return {
        type: DPCActionTypes.GET_RECENT_HISTORY_DATA_SUCCEEDED,
        payload: {
            data,
        },
    };
}

/**  GetRecentHistoryDataFailedAction **/

export interface GetRecentHistoryDataFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function getRecentHistoryDataFailed(error: AppError): GetRecentHistoryDataFailedAction {
    return {
        type: DPCActionTypes.GET_RECENT_HISTORY_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/** SetCurrentUnderlyingInfoBoxTabIdAction **/

export interface SetCurrentUnderlyingInfoBoxTabIdAction extends Action {
    payload: {
        tabId: string;
    };
}

export function setCurrentUnderlyingInfoBoxTabId(tabId: string): SetCurrentUnderlyingInfoBoxTabIdAction {
    return {
        type: DPCActionTypes.SET_CURRENT_UNDERLYING_INFO_BOX_TAB_ID,
        payload: {
            tabId,
        },
    };
}

/** LoadDPCUnderlyingInfoBoxStructureAction **/

export interface LoadDPCUnderlyingInfoBoxStructureAction extends Action {
    payload: object;
}

export function loadDPCUnderlyingInfoBoxStructure(): LoadDPCUnderlyingInfoBoxStructureAction {
    return {
        type: DPCActionTypes.LOAD_DPC_UNDERLYING_INFO_BOX_STRUCTURE,
        payload: {},
    };
}

/** LoadDPCUnderlyingInfoBoxStructureSucceededAction **/

export interface LoadDPCUnderlyingInfoBoxStructureSucceededAction extends Action {
    payload: {
        structure: UnderlyingInfoBoxStructure,
    };
}

export function loadDPCUnderlyingInfoBoxStructureSucceeded(
    structure: UnderlyingInfoBoxStructure,
): LoadDPCUnderlyingInfoBoxStructureSucceededAction {
    return {
        type: DPCActionTypes.LOAD_DPC_UNDERLYING_INFO_BOX_STRUCTURE_SUCCEEDED,
        payload: {
            structure,
        },
    };
}

/** LoadDPCUnderlyingInfoBoxStructureFailedAction **/

export interface LoadDPCUnderlyingInfoBoxStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadDPCUnderlyingInfoBoxStructureFailed(error: AppError): LoadDPCUnderlyingInfoBoxStructureFailedAction {
    return {
        type: DPCActionTypes.LOAD_DPC_UNDERLYING_INFO_BOX_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadDPCUnderlyingInfoBoxTabStructureAction **/

export interface LoadDPCUnderlyingInfoBoxTabStructureAction extends Action {
    payload: {
        tabId: string;
        quoteRequest: Data;
    };
}

export function loadDPCUnderlyingInfoBoxTabStructure(tabId: string, quoteRequest: Data): LoadDPCUnderlyingInfoBoxTabStructureAction {
    return {
        type: DPCActionTypes.LOAD_DPC_UNDERLYING_INFO_BOX_TAB_STRUCTURE,
        payload: {
            tabId,
            quoteRequest,
        },
    };
}

/** LoadDPCUnderlyingInfoBoxTabStructureSucceededAction **/

export interface LoadDPCUnderlyingInfoBoxTabStructureSucceededAction extends Action {
    payload: {
        tabId: string;
        structure: EntityWidgetConfig[]
    };
}

export function loadDPCUnderlyingInfoBoxTabStructureSucceeded(
    tabId: string,
    structure: EntityWidgetConfig[],
): LoadDPCUnderlyingInfoBoxTabStructureSucceededAction {
    return {
        type: DPCActionTypes.LOAD_DPC_UNDERLYING_INFO_BOX_TAB_STRUCTURE_SUCCEEDED,
        payload: {
            tabId,
            structure,
        },
    };
}

/** LoadDPCUnderlyingInfoBoxTabStructureFailedAction **/

export interface LoadDPCUnderlyingInfoBoxTabStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadDPCUnderlyingInfoBoxTabStructureFailed(error: AppError): LoadDPCUnderlyingInfoBoxTabStructureFailedAction {
    return {
        type: DPCActionTypes.LOAD_DPC_UNDERLYING_INFO_BOX_TAB_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadDPCUnderlyingInfoBoxTabDataAction **/

export interface LoadDPCUnderlyingInfoBoxTabDataAction extends Action {
    payload: {
        tabId: string;
        quoteRequest: Data;
    };
}

export function loadDPCUnderlyingInfoBoxTabData(tabId: string, quoteRequest: Data): LoadDPCUnderlyingInfoBoxTabDataAction {
    return {
        type: DPCActionTypes.LOAD_DPC_UNDERLYING_INFO_BOX_TAB_DATA,
        payload: {
            tabId,
            quoteRequest,
        },
    };
}

/** LoadDPCUnderlyingInfoBoxTabDataSucceededAction **/

export interface LoadDPCUnderlyingInfoBoxTabDataSucceededAction extends Action {
    payload: {
        tabId: string,
        values: Data;
    };
}

export function loadDPCUnderlyingInfoBoxTabDataSucceeded(tabId: string, values: Data): LoadDPCUnderlyingInfoBoxTabDataSucceededAction {
    return {
        type: DPCActionTypes.LOAD_DPC_UNDERLYING_INFO_BOX_TAB_DATA_SUCCEEDED,
        payload: {
            tabId,
            values,
        },
    };
}

/** LoadDPCUnderlyingInfoBoxTabDataFailedAction **/

export interface LoadDPCUnderlyingInfoBoxTabDataFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadDPCUnderlyingInfoBoxTabDataFailed(error: AppError): LoadDPCUnderlyingInfoBoxTabDataFailedAction {
    return {
        type: DPCActionTypes.LOAD_DPC_UNDERLYING_INFO_BOX_TAB_DATA_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadIssuedProductsStructureAction **/

export interface LoadIssuedProductsStructureAction extends Action {
    payload?: void;
}

export function loadIssuedProductsStructure(): LoadIssuedProductsStructureAction {
    return {
        type: DPCActionTypes.LOAD_ISSUED_PRODUCTS_STRUCTURE,
    };
}

/** LoadIssuedProductsStructureSucceededAction **/

export interface LoadIssuedProductsStructureSucceededAction extends Action {
    payload: {
        structure: DataRequestResponse,
    };
}

export function loadIssuedProductsStructureSucceeded(structure: DataRequestResponse): LoadIssuedProductsStructureSucceededAction {
    return {
        type: DPCActionTypes.LOAD_ISSUED_PRODUCTS_STRUCTURE_SUCCEEDED,
        payload: {
            structure,
        },
    };
}

/** LoadIssuedProductsStructureFailedAction **/

export interface LoadIssuedProductsStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadIssuedProductsStructureFailed(error: AppError): LoadIssuedProductsStructureFailedAction {
    return {
        type: DPCActionTypes.LOAD_ISSUED_PRODUCTS_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadIssuedProductsDataAction **/

export interface LoadIssuedProductsDataAction extends Action {
    payload: {
        limit: number;
        offset: number;
        filter: any;
    };
}

export function loadIssuedProductsData(filter: any, limit: number, offset: number): LoadIssuedProductsDataAction {
    return {
        type: DPCActionTypes.LOAD_ISSUED_PRODUCTS_DATA,
        payload: {
            filter,
            limit,
            offset,
        },
    };
}

/** LoadIssuedProductsDataSucceededAction **/

export interface LoadIssuedProductsDataSucceededAction extends Action {
    payload: {
        clear: boolean;
        data: Entity[];
    };
}

export function loadIssuedProductsDataSucceeded(data: Entity[], clear?: boolean): LoadIssuedProductsDataSucceededAction {
    return {
        type: DPCActionTypes.LOAD_ISSUED_PRODUCTS_DATA_SUCCEEDED,
        payload: {
            data,
            clear,
        },
    };
}

/** LoadIssuedProductsDataFailedAction **/

export interface LoadIssuedProductsDataFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadIssuedProductsDataFailed(error: AppError): LoadIssuedProductsDataFailedAction {
    return {
        type: DPCActionTypes.LOAD_ISSUED_PRODUCTS_DATA_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadHistoryStructureAction **/

export interface LoadHistoryStructureAction extends Action {
    payload?: void;
}

export function loadHistoryStructure(): LoadHistoryStructureAction {
    return {
        type: DPCActionTypes.LOAD_HISTORY_STRUCTURE,
    };
}

/** LoadHistoryStructureSucceededAction **/

export interface LoadHistoryStructureSucceededAction extends Action {
    payload: {
        structure: DataRequestResponse,
    };
}

export function loadHistoryStructureSucceeded(structure: DataRequestResponse): LoadHistoryStructureSucceededAction {
    return {
        type: DPCActionTypes.LOAD_HISTORY_STRUCTURE_SUCCEEDED,
        payload: {
            structure,
        },
    };
}

/** LoadHistoryStructureFailedAction **/

export interface LoadHistoryStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadHistoryStructureFailed(error: AppError): LoadHistoryStructureFailedAction {
    return {
        type: DPCActionTypes.LOAD_HISTORY_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadHistoryDataAction **/

export interface LoadHistoryDataAction extends Action {
    payload: {
        limit: number;
        offset: number;
        filter: any;
    };
}

export function loadHistoryData(filter: any, limit: number, offset: number): LoadHistoryDataAction {
    return {
        type: DPCActionTypes.LOAD_HISTORY_DATA,
        payload: {
            filter,
            limit,
            offset,
        },
    };
}

/** LoadHistoryDataSucceededAction **/

export interface LoadHistoryDataSucceededAction extends Action {
    payload: {
        clear: boolean;
        data: Entity[];
    };
}

export function loadHistoryDataSucceeded(data: Entity[], clear?: boolean): LoadHistoryDataSucceededAction {
    return {
        type: DPCActionTypes.LOAD_HISTORY_DATA_SUCCEEDED,
        payload: {
            clear,
            data,
        },
    };
}

/** LoadHistoryDataFailedAction **/

export interface LoadHistoryDataFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadHistoryDataFailed(error: AppError): LoadHistoryDataFailedAction {
    return {
        type: DPCActionTypes.LOAD_HISTORY_DATA_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadHistoryDataForRestoreAction **/

export interface LoadHistoryDataForRestoreAction extends Action {
    payload: {
        historyId: number,
    };
}

export function loadHistoryDataForRestore(historyId: number): LoadHistoryDataForRestoreAction {
    return {
        type: DPCActionTypes.LOAD_HISTORY_DATA_FOR_RESTORE,
        payload: {
            historyId,
        },
    };
}

/** LoadHistoryDataForRestoreSucceededAction **/

export interface LoadHistoryDataForRestoreSucceededAction extends Action {
    payload: {
        data: RestoredHistoryData,
    };
}

export function loadHistoryDataForRestoreSucceeded(data: RestoredHistoryData): LoadHistoryDataForRestoreSucceededAction {
    return {
        type: DPCActionTypes.LOAD_HISTORY_DATA_FOR_RESTORE_SUCCEEDED,
        payload: {
            data,
        },
    };
}

/** LoadHistoryDataForRestoreFailedAction **/

export interface LoadHistoryDataForRestoreFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadHistoryDataForRestoreFailed(error: AppError): LoadHistoryDataForRestoreFailedAction {
    return {
        type: DPCActionTypes.LOAD_HISTORY_DATA_FOR_RESTORE_FAILED,
        payload: {
            error,
        },
    };
}

/** IssueProductAction **/

export interface IssueProductAction extends Action {
    payload: {
        offerId: string;
    };
}

export function issueProduct(offerId: string): IssueProductAction {
    return {
        type: DPCActionTypes.ISSUE_PRODUCT,
        payload: {
            offerId,
        },
    };
}

/** IssueProductSucceededAction **/

export interface IssueProductSucceededAction extends Action {
    payload: {
        offers: Entity[];
    };
}

export function issueProductSucceeded(offers: Entity[]): IssueProductSucceededAction {
    return {
        type: DPCActionTypes.ISSUE_PRODUCT_SUCCEEDED,
        payload: {
            offers,
        },
    };
}

/** IssueProductFailedAction **/

export interface IssueProductFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function issueProductFailed(error: AppError): IssueProductFailedAction {
    return {
        type: DPCActionTypes.ISSUE_PRODUCT_FAILED,
        payload: {
            error,
        },
    };
}

/** ResetDPCStateAction **/

export function resetDPCState(): Action {
    return {
        type: DPCActionTypes.RESET_DPC_STATE,
    };
}

/** AddQuoteToSelectionAction **/

export interface AddQuoteToSelectionAction extends Action {
    payload: {
        quoteId: string;
    };
}

export function addQuoteToSelection(quoteId: string): AddQuoteToSelectionAction {
    return {
        type: DPCActionTypes.ADD_QUOTE_TO_SELECTION,
        payload: {
            quoteId,
        },
    };
}

/** AddQuotesToSelectionAction **/

export interface AddQuotesToSelectionAction extends Action {
    payload: {
        quoteIds: string[];
    };
}

export function addQuotesToSelection(quoteIds: string[]): AddQuotesToSelectionAction {
    return {
        type: DPCActionTypes.ADD_QUOTES_TO_SELECTION,
        payload: {
            quoteIds,
        },
    };
}

/** RemoveQuoteFromSelectionAction **/

export interface RemoveQuoteFromSelectionAction extends Action {
    payload: {
        quoteId: string;
    };
}

export function removeQuoteFromSelection(quoteId: string): RemoveQuoteFromSelectionAction {
    return {
        type: DPCActionTypes.REMOVE_QUOTE_FROM_SELECTION,
        payload: {
            quoteId,
        },
    };
}

/** RemoveQuotesFromSelectionAction **/

export interface RemoveQuotesFromSelectionAction extends Action {
    payload: {
        quoteIds: string[];
    };
}

export function removeQuotesFromSelection(quoteIds: string[]): RemoveQuotesFromSelectionAction {
    return {
        type: DPCActionTypes.REMOVE_QUOTES_FROM_SELECTION,
        payload: {
            quoteIds,
        },
    };
}

/** ClearSelectionAction **/

export interface ClearQuoteSelectionAction extends Action {
    payload: object;
}

export function clearQuoteSelection(): ClearQuoteSelectionAction {
    return {
        type: DPCActionTypes.CLEAR_QUOTE_SELECTION,
        payload: {},
    };
}
