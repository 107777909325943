import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors, FormsModule } from '@angular/forms';
import { isEqual } from 'lodash';
import { FormControlConfig, FormControlSelectOption, FormControlSelectSubItemsOption } from '../../../core/models/form.model';
import { MultiValueFilterControl } from '../filter-control';
import { NgFor, NgIf, NgStyle } from '@angular/common';

@Component({
    selector: 'app-filter-checkbox-select-group',
    templateUrl: './filter-checkbox-select-group.component.html',
    styleUrls: ['./filter-checkbox-select-group.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, FormsModule, NgStyle]
})
export class FilterCheckboxSelectGroupComponent implements OnInit, MultiValueFilterControl<string> {

    @Input() public config: FormControlConfig;

    @Input() public values: FormControlSelectSubItemsOption[];

    @Input() public disabled = false;
    public onChange: () => void;
    public onTouched: () => void;
    public _values: { [value: string]: boolean };

    constructor() {
    }

    public ngOnInit() {
    }

    /**
     * Writes the value for the checkbox group
     * @param {string[]} values
     */
    public writeValue(values: string[]): void {
        if (!values) {
            values = [];
        }
        const transformedValues = values.reduce((vals, value) => {
            vals[value] = true;
            return vals;
        }, {});
        if (!isEqual(this._values, transformedValues)) {
            this._values = transformedValues;
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                const values = Object.keys(this._values).filter((value) => this._values[value]);
                fn(values);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        const invalidValues = Object.keys(this._values).map((value) => {
            return {
                value,
                valid: this._isValidValue(value),
            };
        }).filter((v) => !v.valid);
        if (invalidValues.length > 0) {
            return {
                checkboxGroupError: {
                    message: 'Invalid values specified.',
                    invalidValues,
                },
            };
        } else {
            return null;
        }
    }

    /**
     * Called when the checkbox state has changed
     * @param e
     * @param value
     */
    public onCheckboxChange(e: Event, value: FormControlSelectSubItemsOption) {
        e.preventDefault();

        const checked = this.isCheckboxChecked(value);

        if (checked) {
            value.items.forEach((item) => {
                this._values[item.id] = false;
            });
        } else {
            const defId = value.defaultItem || value.items[0].id;
            this._values[defId] = true;
        }

        this.onChange();
    }

    public isCheckboxChecked(value: FormControlSelectSubItemsOption) {
        if (!value) {
            return false;
        }

        return value.items.reduce((result, current) => {
            return result || !!this._values[current.id];
        }, false);
    }

    public onSingleSelectChange(id: any, value: FormControlSelectSubItemsOption) {
        value.items.forEach((item) => {
            this._values[item.id] = false;
        });

        this._values[id] = true;
        this.onChange();
    }

    public getSelectedItemForValue(value: FormControlSelectSubItemsOption) {
        const selectedItem = value.items.find((item) => !!this._values[item.id]);

        return (selectedItem && selectedItem.id) || value.defaultItem || value.items[0].id;
    }

    /**
     * Checks if the filter value is valid
     * @param {string} value
     * @returns {boolean}
     * @private
     */
    protected _isValidValue(value: string) {
        return (this.values) ? this.values.reduce((hasValue, validValue) => {
            const fv = validValue.items.find((v) => v.id === value);
            return !!fv || hasValue;
        }, false) : false;
    }
}
