import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { transformReportResponseData } from '../../routes/assets/api/assets.transform';
import { ReportResponseData } from '../../routes/assets/api/models/assets.model';
import { AuthStoreService } from '../services/auth-store.service';
import { ConfigurationService } from '../services/configuration.service';
import { LogglyLoggerService } from '../services/loggly-logger.service';
import { DerifinApi } from './derifin.api';

@Injectable()
export class ReportApi extends DerifinApi {

    constructor(http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, appConfigService: ConfigurationService) {
        super('m/report', appConfigService.configuration.environment.id, http, authStoreService, loggerService, appConfigService);
    }

    /**
     * Get the report data
     * @param dq
     */
    public getReportData(dq: string): Observable<ReportResponseData> {
        return this.get(`${dq}/info`).pipe(
            map(transformReportResponseData),
        );
    }
}
