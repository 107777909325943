import { Action } from '@ngrx/store';
import {
    AdvisorProductBasketsActions, LoadFilterValuesFailureAction,
    LoadFilterValuesSuccessAction,
    LoadTableAndFilterStructureFailureAction,
    LoadTableAndFilterStructureSuccessAction, loadTableDataFailure, LoadTableDataFailureAction, LoadTableDataSuccessAction,
} from '../actions/advisor-product-baskets.actions';
import { AdvisorProductBasketsState } from '../state/advisor-product-baskets.state';

export const initialState: AdvisorProductBasketsState = {
    filterStructure: null,
    filterValues: null,
    tableData: null,
    tableStructures: null,

    loadTableAndFilterStructure: false,
    loadFilterValues: false,
    loadTableData: false,

    errorFilterValues: null,
    errorTableAndFilterStructure: null,
    errorTableData: null,
};

export function reducer(state: AdvisorProductBasketsState = initialState,
                        action: Action): AdvisorProductBasketsState {
    switch (action.type) {
        case AdvisorProductBasketsActions.LOAD_TABLE_AND_FILTER_STRUCTURE_REQUEST: {
            return {
                ...state,
                loadTableAndFilterStructure: true,
                errorTableAndFilterStructure: null,
            };
        }

        case AdvisorProductBasketsActions.LOAD_TABLE_AND_FILTER_STRUCTURE_SUCCESS: {
            const loadTableAndFilterStructureRequestAction = action as LoadTableAndFilterStructureSuccessAction;

            return {
                ...state,
                loadTableAndFilterStructure: false,
                filterStructure: loadTableAndFilterStructureRequestAction.payload.filter,
                tableStructures: loadTableAndFilterStructureRequestAction.payload.tables,
            };
        }

        case AdvisorProductBasketsActions.LOAD_TABLE_AND_FILTER_STRUCTURE_FAILURE: {
            const loadTableAndFilterStructureFailureAction = action as LoadTableAndFilterStructureFailureAction;

            return {
                ...state,
                loadTableAndFilterStructure: false,
                errorTableAndFilterStructure: loadTableAndFilterStructureFailureAction.payload.error,
            };
        }

        case AdvisorProductBasketsActions.LOAD_FILTER_VALUES_REQUEST: {
            return {
                ...state,
                loadFilterValues: true,
                errorFilterValues: null,
            };
        }

        case AdvisorProductBasketsActions.LOAD_FILTER_VALUES_SUCCESS: {
            const loadFilterValuesSuccessAction = action as LoadFilterValuesSuccessAction;

            return {
                ...state,
                loadFilterValues: false,
                filterValues: loadFilterValuesSuccessAction.payload.values,
            };
        }

        case AdvisorProductBasketsActions.LOAD_FILTER_VALUES_FAILURE: {
            const loadFilterValuesFailureAction = action as LoadFilterValuesFailureAction;

            return {
                ...state,
                loadFilterValues: false,
                errorFilterValues: loadFilterValuesFailureAction.payload.error,
            };
        }

        case AdvisorProductBasketsActions.LOAD_TABLE_DATA_REQUEST: {
            return {
                ...state,
                loadTableData: true,
                errorTableData: null,
            };
        }

        case AdvisorProductBasketsActions.LOAD_TABLE_DATA_SUCCESS: {
            const loadTableDataSuccessAction = action as LoadTableDataSuccessAction;

            return {
                ...state,
                loadTableData: false,
                tableData: loadTableDataSuccessAction.payload.data,
            };
        }

        case AdvisorProductBasketsActions.LOAD_TABLE_DATA_FAILURE: {
            const loadTableDataFailureAction = action as LoadTableDataFailureAction;

            return {
                ...state,
                loadTableData: false,
                errorTableData: loadTableDataFailureAction.payload.error,
            };
        }
    }

    return state;
}
