import { Action } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { FormStructureConfig, FormValues } from '../../../../core/models/form.model';
import { type } from '../../../../core/store/util';
import { Entity, EntityTableConfig } from '../../../../shared/models/entities.model';
import { OtherProductsCombinedDataAndStructure } from '../../models/other-products.model';

export const OtherProductsActionTypes = {
    LOAD_TABLE_STRUCTURE_REQUEST: type('OTHER_PRODUCTS:LOAD_TABLE_STRUCTURE_REQUEST'),
    LOAD_TABLE_STRUCTURE_SUCCESS: type('OTHER_PRODUCTS:LOAD_TABLE_STRUCTURE_SUCCESS'),
    LOAD_TABLE_STRUCTURE_FAILURE: type('OTHER_PRODUCTS:LOAD_TABLE_STRUCTURE_FAILURE'),

    LOAD_TABLE_DATA_REQUEST: type('OTHER_PRODUCTS:LOAD_TABLE_DATA_REQUEST'),
    LOAD_TABLE_DATA_SUCCESS: type('OTHER_PRODUCTS:LOAD_TABLE_DATA_SUCCESS'),
    LOAD_TABLE_DATA_FAILURE: type('OTHER_PRODUCTS:LOAD_TABLE_DATA_FAILURE'),

    LOAD_FORM_STRUCTURE_REQUEST: type('OTHER_PRODUCTS:LOAD_FORM_STRUCTURE_REQUEST'),
    LOAD_FORM_STRUCTURE_SUCCESS: type('OTHER_PRODUCTS:LOAD_FORM_STRUCTURE_SUCCESS'),
    LOAD_FORM_STRUCTURE_FAILURE: type('OTHER_PRODUCTS:LOAD_FORM_STRUCTURE_FAILURE'),

    LOAD_FORM_VALUES_REQUEST: type('OTHER_PRODUCTS:LOAD_FORM_VALUES_REQUEST'),
    LOAD_FORM_VALUES_SUCCESS: type('OTHER_PRODUCTS:LOAD_FORM_VALUES_SUCCESS'),
    LOAD_FORM_VALUES_FAILURE: type('OTHER_PRODUCTS:LOAD_FORM_VALUES_FAILURE'),

    LOAD_DATA_AND_STRUCTURE_REQUEST: type('OTHER_PRODUCTS:LOAD_DATA_AND_STRUCTURE_REQUEST'),
    LOAD_DATA_AND_STRUCTURE_SUCCESS: type('OTHER_PRODUCTS:LOAD_DATA_AND_STRUCTURE_SUCCESS'),
    LOAD_DATA_AND_STRUCTURE_FAILURE: type('OTHER_PRODUCTS:LOAD_DATA_AND_STRUCTURE_FAILURE'),

    SAVE_FORM_DATA_REQUEST: type('OTHER_PRODUCTS:SAVE_FORM_DATA_REQUEST'),
    SAVE_FORM_DATA_SUCCESS: type('OTHER_PRODUCTS:SAVE_FORM_DATA_SUCCESS'),
    SAVE_FORM_DATA_FAILURE: type('OTHER_PRODUCTS:SAVE_FORM_DATA_FAILURE'),

    DELETE_PRODUCT_REQUEST: type('OTHER_PRODUCTS:DELETE_PRODUCT_REQUEST'),
    DELETE_PRODUCT_SUCCESS: type('OTHER_PRODUCTS:DELETE_PRODUCT_SUCCESS'),
    DELETE_PRODUCT_FAILURE: type('OTHER_PRODUCTS:DELETE_PRODUCT_FAILURE'),
};

/** Data and Structure actions **/
export interface LoadDataAndStructureRequestAction extends Action {
    payload: {
        moduleId: string;
    };
}

export function loadDataAndStructureRequest(moduleId: string): LoadDataAndStructureRequestAction {
    return {
        type: OtherProductsActionTypes.LOAD_DATA_AND_STRUCTURE_REQUEST,
        payload: {
            moduleId,
        },
    };
}

export interface LoadDataAndStructureSuccessAction extends Action {
    payload: {
        data: OtherProductsCombinedDataAndStructure;
    };
}

export function loadDataAndStructureSuccess(data: OtherProductsCombinedDataAndStructure): LoadDataAndStructureSuccessAction {
    return {
        type: OtherProductsActionTypes.LOAD_DATA_AND_STRUCTURE_SUCCESS,
        payload: {
            data,
        },
    };
}

export interface LoadDataAndStructureFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function LoadDataAndStructureFailure(error: AppError): LoadDataAndStructureFailureAction {
    return {
        type: OtherProductsActionTypes.LOAD_DATA_AND_STRUCTURE_FAILURE,
        payload: {
            error,
        },
    };
}

/** Table structure actions **/
export interface LoadTableStructureRequestAction extends Action {
    payload: {
        moduleId: string;
    };
}

export function loadTableStructureRequest(moduleId: string): LoadTableStructureRequestAction {
    return {
        type: OtherProductsActionTypes.LOAD_TABLE_STRUCTURE_REQUEST,
        payload: {
            moduleId,
        },
    };
}

export interface LoadTableStructureSuccessAction extends Action {
    payload: {
        structure: EntityTableConfig;
    };
}

export function loadTableStructureSuccess(structure: EntityTableConfig): LoadTableStructureSuccessAction {
    return {
        type: OtherProductsActionTypes.LOAD_TABLE_STRUCTURE_SUCCESS,
        payload: {
            structure,
        },
    };
}

export interface LoadTableStructureFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadTableStructureFailure(error: AppError): LoadTableStructureFailureAction {
    return {
        type: OtherProductsActionTypes.LOAD_TABLE_STRUCTURE_FAILURE,
        payload: {
            error,
        },
    };
}

/** Table data actions **/
export interface LoadTableDataRequestAction extends Action {
    payload: {
        moduleId: string;
    };
}

export function loadTableDataRequest(moduleId: string): LoadTableDataRequestAction {
    return {
        type: OtherProductsActionTypes.LOAD_TABLE_DATA_REQUEST,
        payload: {
            moduleId,
        },
    };
}

export interface LoadTableDataSuccessAction extends Action {
    payload: {
        data: Entity[];
    };
}

export function loadTableDataSuccess(data: Entity[]): LoadTableDataSuccessAction {
    return {
        type: OtherProductsActionTypes.LOAD_TABLE_DATA_SUCCESS,
        payload: {
            data,
        },
    };
}

export interface LoadTableDataFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadTableDataFailure(error: AppError): LoadTableDataFailureAction {
    return {
        type: OtherProductsActionTypes.LOAD_TABLE_DATA_FAILURE,
        payload: {
            error,
        },
    };
}

/** Save data actions **/
export interface SaveFormDataRequestAction extends Action {
    payload: {
        moduleId: string;
        data: Entity;
    };
}

export function saveFormDataRequest(moduleId: string, data: Entity): SaveFormDataRequestAction {
    return {
        type: OtherProductsActionTypes.SAVE_FORM_DATA_REQUEST,
        payload: {
            moduleId,
            data,
        },
    };
}

export interface SaveFormDataSuccessAction extends Action {
    payload: {
        data: Entity;
    };
}

export function saveFormDataSuccess(data: Entity): SaveFormDataSuccessAction {
    return {
        type: OtherProductsActionTypes.SAVE_FORM_DATA_SUCCESS,
        payload: {
            data,
        },
    };
}

export interface SaveFormDataFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function saveFormDataFailure(error: AppError): SaveFormDataFailureAction {
    return {
        type: OtherProductsActionTypes.SAVE_FORM_DATA_FAILURE,
        payload: {
            error,
        },
    };
}



/** Delete data actions **/
export interface DeleteProductRequestAction extends Action {
    payload: {
        moduleId: string;
        productId: string;
    };
}

export function deleteProductRequest(moduleId: string, productId: string): DeleteProductRequestAction {
    return {
        type: OtherProductsActionTypes.DELETE_PRODUCT_REQUEST,
        payload: {
            moduleId,
            productId,
        },
    };
}

export interface DeleteProductSuccessAction extends Action {
    payload: {
        moduleId: string;
        productId: string;
    };
}

export function deleteProductSuccess(moduleId: string, productId: string): DeleteProductSuccessAction {
    return {
        type: OtherProductsActionTypes.DELETE_PRODUCT_SUCCESS,
        payload: {
            moduleId,
            productId,
        },
    };
}

export interface DeleteProductFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function deleteProductFailure(error: AppError): DeleteProductFailureAction {
    return {
        type: OtherProductsActionTypes.DELETE_PRODUCT_FAILURE,
        payload: {
            error,
        },
    };
}

/** Form Structure Actions **/
export interface LoadFormStructureRequestAction extends Action {
    payload: {
        moduleId: string;
    };
}

export function loadFormStructureRequest(moduleId: string): LoadFormStructureRequestAction {
    return {
        type: OtherProductsActionTypes.LOAD_FORM_STRUCTURE_REQUEST,
        payload: {
            moduleId,
        },
    };
}

export interface LoadFormStructureSuccessAction extends Action {
    payload: {
        structure: FormStructureConfig;
    };
}

export function loadFormStructureSuccess(structure: FormStructureConfig): LoadFormStructureSuccessAction {
    return {
        type: OtherProductsActionTypes.LOAD_FORM_STRUCTURE_SUCCESS,
        payload: {
            structure,
        },
    };
}

export interface LoadFormStructureFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadFormStructureFailure(error: AppError): LoadFormStructureFailureAction {
    return {
        type: OtherProductsActionTypes.LOAD_FORM_STRUCTURE_FAILURE,
        payload: {
            error,
        },
    };
}

/** Form Values Actions **/
export interface LoadFormValuesRequestAction extends Action {
    payload: {
        moduleId: string;
    };
}

export function loadFormValuesRequest(moduleId: string): LoadFormValuesRequestAction {
    return {
        type: OtherProductsActionTypes.LOAD_FORM_VALUES_REQUEST,
        payload: {
            moduleId,
        },
    };
}

export interface LoadFormValuesSuccessAction extends Action {
    payload: {
        values: FormValues;
    };
}

export function loadFormValuesSuccess(values: FormValues): LoadFormValuesSuccessAction {
    return {
        type: OtherProductsActionTypes.LOAD_FORM_VALUES_SUCCESS,
        payload: {
            values,
        },
    };
}

export interface LoadFormValuesFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadFormValuesFailure(error: AppError): LoadFormValuesFailureAction {
    return {
        type: OtherProductsActionTypes.LOAD_FORM_VALUES_FAILURE,
        payload: {
            error,
        },
    };
}
