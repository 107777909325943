/**
 * Created by Alex Klein <alex@rocketloop.de>
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RiskManagerModuleWatcher, RiskManagerWatchlistContext } from '../models/risk-manager.model';
import { AuthStoreService } from '../services/auth-store.service';
import { ConfigurationService } from '../services/configuration.service';
import { LogglyLoggerService } from '../services/loggly-logger.service';
import { DerifinApi } from './derifin.api';
import { ApiResponse } from './models/api.model';
import { GetModuleDataResponse, GetProductSelectionResponse } from './models/risk-manager.model';
import { RiskManagerModule } from '../../routes/risk-manager/risk-manager.module';

/**
 * API abstraction layer for the risk manager API
 */
@Injectable()
export class RiskManagerApi extends DerifinApi {
    constructor(http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, appConfigService: ConfigurationService) {
        super('m/riskman', appConfigService.configuration.environment.id, http, authStoreService, loggerService, appConfigService);
    }

    /**
     * Get the list module context
     * @param {string} moduleId
     * @returns {Observable<any>}
     */
    public getListModuleContext(moduleId: string): Observable<any> {
        return this.get(`module/${moduleId}/context`).pipe(map((response: ApiResponse<any>) => response.data));
    }

    /**
     * Get the single module context
     * @param {string} moduleId
     * @param {string} productDq
     * @returns {Observable<any>}
     */
    public getSingleModuleContext(moduleId: string, productDq: string): Observable<any> {
        return this.get(`module/${moduleId}/product/${productDq}/context`).pipe(
            map((response: ApiResponse<any>) => response.data),
        );
    }

    /**
     * Get the factsheet context
     * @param {string} productDq
     * @returns {Observable<any>}
     */
    public getFactSheetContext(productDq: string): Observable<any> {
        return this.get(`fact-sheet/${productDq}/context`).pipe(map((response: ApiResponse<any>) => response.data));
    }

    /**
     * Get the watchlist context
     * @param watchlistId
     * @returns {Observable<RiskManagerWatchlistContext>}
     */
    public getWatchlistContext(watchlistId: string): Observable<RiskManagerWatchlistContext> {
        return this.get(`watchlist/${watchlistId}/context`).pipe(
            map((response: ApiResponse<RiskManagerWatchlistContext>) => response.data)
        );
    }

    /**
     * Get the data for the specified module id and context
     * @param {string} moduleId
     * @param currentContext
     * @returns {Observable<any>}
     */
    public getLoadModuleData(moduleId: string, currentContext: any): Observable<GetModuleDataResponse> {
        return this.post(`content`, currentContext).pipe(map((response: ApiResponse<any>) => response.data));
    }

    /**
     * Save the watcher
     * @param context
     * @param watcher
     * @returns {Observable<RiskManagerModuleWatcher>}
     */
    public saveWatcher(context: any, watcher: any): Observable<RiskManagerModuleWatcher> {
        return this.post(`watchers`, {
            context,
            watcher,
        }).pipe(map((response: ApiResponse<any>) => response.data));
    }


    /**
     * Save the a list of watchers
     * @param context
     * @param watcher
     * @returns {Observable<RiskManagerModuleWatcher[]>}
     */
    public saveWatchers(moduleId: string, data: Record<'context' | 'watcher', any>[]): Observable<RiskManagerModuleWatcher[]> {
        return this.post(`multi-watchers`, data).pipe(
            map((response: ApiResponse<RiskManagerModuleWatcher[]>) => response.data),
        );
    }

    /**
     * Get the product selection
     * @returns {Observable<GetProductSelectionResponse>}
     */
    public getProductSelection(moduleId: string): Observable<GetProductSelectionResponse> {
        return this.get(`module/${moduleId}/content/product-selection`).pipe(
            map((response: ApiResponse<any>) => response.data),
        );
    }
}
