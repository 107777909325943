<div class="wms-dialog-inner create-watchlist-dialog tw-bg-primary-bg tw-text-black dark:tw-text-white">
    <nav class="navbar navbar-toggleable-md header-bar navbar-inverse header-bar tw-py-4">
        <div class="3xl:tw-container tw-w-full tw-mx-auto tw-px-8 tw-flex">
            <i class="mr-auto"></i>
            <div class="navbar-nav">
                <a (click)="onCloseClick($event)" class="nav-link icon-link close-modal-button" href="#">
                    <app-icon class="derifin-icon" icon="derifin:schliessen"></app-icon>
                </a>
            </div>
        </div>
    </nav>
    
    <div class="modal-body">
        <h1 class="modal-form__title">{{ 'watchlists.modals.createWatchlist.initial.title' | transloco }}</h1>
    
        <form
            [formGroup]="createWatchlistForm"
            class="form-inline form-inverse modal-form__form"
            (submit)="onSubmit($event)" novalidate>
            <label class="sr-only" for="watchlistName">
                {{ 'watchlists.modals.createWatchlist.initial.labels.name' | transloco }}
            </label>
            <input
                id="watchlistName"
                type="text"
                class="form-control mr-sm-2 modal-form__field--large watchlist-name"
                placeholder="{{ 'watchlists.modals.createWatchlist.initial.placeholders.name' | transloco }}"
                formControlName="name"
                [appAutofocus]>
    
            <button
                type="submit"
                class="icon-button icon-button--inverse finish-modal-button"
                [disabled]="!createWatchlistForm.valid">
                <app-icon class="icon-button__icon" icon="derifin:checkmark-plain"></app-icon>
            </button>
        </form>
    </div>
</div>
