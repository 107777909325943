/**
 * Created by Florian Reifschneider <florian@rocketloop.de>
 */
import { InjectionToken } from '@angular/core';
import { ComponentRegistries, registerInjectionTokenForComponentType } from '../helpers/dynamic-component.helper';
import { EntityWidgetType } from '../models/widgets.model';

/** Entity Widgets **/

export const WIDGET_TYPE_PARAGRAPH = new InjectionToken<EntityWidgetType>(`WIDGET_TYPE:PARAGRAPH`);
export const WIDGET_TYPE_TABLE = new InjectionToken<EntityWidgetType>(`WIDGET_TYPE:TABLE`);
export const WIDGET_TYPE_SHEET = new InjectionToken<EntityWidgetType>(`WIDGET_TYPE:SHEET`);
export const WIDGET_TYPE_GRAPH = new InjectionToken<EntityWidgetType>(`WIDGET_TYPE:GRAPH`);
export const WIDGET_TYPE_GRAPH_EXTENDED = new InjectionToken<EntityWidgetType>(`WIDGET_TYPE:GRAPH_EXTENDED`);

export const WIDGET_TYPE_IMAGE_MARKDOWN = new InjectionToken(`WIDGET_TYPE:IMAGE_MARKDOWN`);
export const WIDGET_TYPE_IMAGE = new InjectionToken(`WIDGET_TYPE:IMAGE`);
export const WIDGET_TYPE_MARKDOWN = new InjectionToken(`WIDGET_TYPE:MARKDOWN`);
export const WIDGET_TYPE_PRODUCT_PROMOTION = new InjectionToken(`WIDGET_TYPE:PRODUCT_PROMOTION`);
export const WIDGET_TYPE_PRODUCT_PROMOTION_EXPRESS = new InjectionToken(`WIDGET_TYPE:PRODUCT_PROMOTION_EXPRESS`);
export const WIDGET_TYPE_ALERT = new InjectionToken('WIDGET_TYPE:ALERT');
export const WIDGET_TYPE_TEXT_COPY_PASTE = new InjectionToken<EntityWidgetType>(`WIDGET_TYPE:TEXT_COPY_PASTE`);
export const WIDGET_TYPE_FOCUS_LIST = new InjectionToken(`WIDGET_TYPE:FOCUS_LIST`);
export const WIDGET_TYPE_QUICKLINK = new InjectionToken(`WIDGET_TYPE:QUICKLINK`);
export const WIDGET_TYPE_DECORFIN_DATA = new InjectionToken(`WIDGET_TYPE:DECORFIN_DATA`);
export const WIDGET_TYPE_WATCHLIST_SELECT = new InjectionToken(`WIDGET_TYPE:WATCHLIST_SELECT`);
export const WIDGET_TYPE_MARKET_INDICATOR = new InjectionToken(`WIDGET_TYPE:MARKET_INDICATOR`);
export const WIDGET_TYPE_FOCUSLIST_CHANGE = new InjectionToken(`WIDGET_TYPE:FOCUSLIST_CHANGE`);
export const WIDGET_TYPE_TOP_TRADES = new InjectionToken(`WIDGET_TYPE:TOP_TRADES`);
export const WIDGET_TYPE_LATEST_ISSUED_PRODUCTS = new InjectionToken(`WIDGET_TYPE:LATEST_ISSUED_PRODUCTS`);
export const WIDGET_TYPE_TOP_UNDERLYINGS = new InjectionToken(`WIDGET_TYPE:TOP_UNDERLYINGS`);
export const WIDGET_TYPE_MARKET_DATA = new InjectionToken(`WIDGET_TYPE:MARKET_DATA`);
export const WIDGET_TYPE_COMBINED = new InjectionToken(`WIDGET_TYPE:COMBINED`);
export const WIDGET_TYPE_MERCURY = new InjectionToken(`WIDGET_TYPE:MERCURY`);
export const WIDGET_TYPE_DERICON_RECOMMEND = new InjectionToken(`WIDGET_TYPE:DERICON_RECOMMEND`);
export const WIDGET_TYPE_FORM = new InjectionToken(`WIDGET_TYPE:FORM`);
export const WIDGET_TYPE_BASKETS_ADMIN = new InjectionToken(`WIDGET_TYPE:BASKETS_ADMIN`);
export const WIDGET_TYPE_EQUIVALENCE_CHECK = new InjectionToken(`WIDGET_TYPE:EQUIVALENCE_CHECK`);
export const WIDGET_TYPE_MISCHPRODUKTE_ANTEILE = new InjectionToken(`WIDGET_TYPE:MISCHPRODUKTE_ANTEILE`);

registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'combined', WIDGET_TYPE_COMBINED);

registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'abs-text', WIDGET_TYPE_PARAGRAPH);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'text-copy-paste', WIDGET_TYPE_TEXT_COPY_PASTE);

registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'table', WIDGET_TYPE_TABLE);

registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'product-sheet', WIDGET_TYPE_SHEET);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'sheet', WIDGET_TYPE_SHEET);

registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'graph', WIDGET_TYPE_GRAPH);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'extended-chart', WIDGET_TYPE_GRAPH_EXTENDED);

registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'markdown', WIDGET_TYPE_MARKDOWN);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'image-markdown', WIDGET_TYPE_IMAGE_MARKDOWN);

registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'image', WIDGET_TYPE_IMAGE);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'focus-list', WIDGET_TYPE_FOCUS_LIST);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'quicklink', WIDGET_TYPE_QUICKLINK);

registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'product-promotion',
    WIDGET_TYPE_PRODUCT_PROMOTION,
);

registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'product-promotion-express',
    WIDGET_TYPE_PRODUCT_PROMOTION_EXPRESS,
);

registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'information', WIDGET_TYPE_ALERT);

registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'decorfin-data', WIDGET_TYPE_DECORFIN_DATA);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'watchlist-select', WIDGET_TYPE_WATCHLIST_SELECT);

registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'market-indicator', WIDGET_TYPE_MARKET_INDICATOR);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'market-overview', WIDGET_TYPE_MARKET_DATA);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'focuslist-change', WIDGET_TYPE_FOCUSLIST_CHANGE);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'top-trades', WIDGET_TYPE_TOP_TRADES);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'latest-issued-products', WIDGET_TYPE_LATEST_ISSUED_PRODUCTS);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'top-underlyings', WIDGET_TYPE_TOP_UNDERLYINGS);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'mercury-top-posts', WIDGET_TYPE_MERCURY);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'dericon-recommend', WIDGET_TYPE_DERICON_RECOMMEND);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'product-form', WIDGET_TYPE_FORM);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'bison-product-basket-administration', WIDGET_TYPE_BASKETS_ADMIN);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'equivalence-check', WIDGET_TYPE_EQUIVALENCE_CHECK);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_WIDGET, 'bison-exchange-multi-asset-fractions', WIDGET_TYPE_MISCHPRODUKTE_ANTEILE);

/** Entity Table Cell Components **/

export const ENTITY_TABLE_FACTSHEET_CELL = new InjectionToken<EntityWidgetType>(`TABLE_CELL:FACTSHEET`);
export const ENTITY_TABLE_ACTION_CELL = new InjectionToken<EntityWidgetType>(`TABLE_CELL:ACTION`);
export const ENITIY_TABLE_SELECTBOX_CELL = new InjectionToken<EntityWidgetType>(`TABLE_CELL:SELECTBOX`);
export const ENTITY_TABLE_IMAGE_CELL = new InjectionToken<EntityWidgetType>(`TABLE_CELL:IMAGE`);
export const ENTITY_TABLE_TEXTAREA = new InjectionToken<EntityWidgetType>(`TABLE_CELL:TEXTAREA`);
export const ENTITY_TABLE_TEXT_FIELD = new InjectionToken<EntityWidgetType>(`TABLE_CELL:TEXT_FIELD`);
export const ENTITY_TABLE_EQUIVALENT_PRODUCTS = new InjectionToken<EntityWidgetType>(`TABLE_CELL:EQUIVALENT_PRODUCTS`);
export const ENTITY_TABLE_COMBINED_MULTI_ROW = new InjectionToken<EntityWidgetType>(`TABLE_CELL:COMBINED_MULTI_ROW`);
export const ENTITY_TABLE_COMBINED_MULTI_ROW_COMMENT = new InjectionToken<EntityWidgetType>(`TABLE_CELL:COMBINED_MULTI_ROW_COMMENT`);

registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_TABLE_CELL, 'factsheet', ENTITY_TABLE_FACTSHEET_CELL);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_TABLE_CELL, 'action', ENTITY_TABLE_ACTION_CELL);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_TABLE_CELL, 'recommendation', ENTITY_TABLE_ACTION_CELL);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_TABLE_CELL, 'selectbox', ENITIY_TABLE_SELECTBOX_CELL);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_TABLE_CELL, 'image', ENTITY_TABLE_IMAGE_CELL);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_TABLE_CELL, 'textarea', ENTITY_TABLE_TEXTAREA);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_TABLE_CELL, 'text_field', ENTITY_TABLE_TEXT_FIELD);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_TABLE_CELL, 'equivalent-products', ENTITY_TABLE_EQUIVALENT_PRODUCTS);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_TABLE_CELL, 'combined-multi-row', ENTITY_TABLE_COMBINED_MULTI_ROW);
registerInjectionTokenForComponentType(ComponentRegistries.ENTITY_TABLE_CELL, 'combined-multi-row-comment', ENTITY_TABLE_COMBINED_MULTI_ROW_COMMENT);
