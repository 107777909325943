import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuComponent } from '../../../core/components/menu/menu.component';
import { MenuItemComponent } from '../menu-item/menu-item.component';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-inline-menu',
    templateUrl: './inline-menu.component.html',
    standalone: true,
    imports: [CommonModule, MenuItemComponent]
})
export class InlineMenuComponent extends MenuComponent implements OnInit, OnDestroy {

    private subscriptions: Subscription[] = [];

    constructor(private router: Router) {
        super();
    }

    public ngOnInit() {
        this.subscriptions.push(
            this.router.events.pipe(
                filter((event) =>
                    event instanceof NavigationStart
                )
            ).subscribe(() =>
                this.currentExpandedPath = []
            )
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }

    public updateRows() {
        this.rows = [this._menu.items];
    }
}
