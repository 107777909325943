<div class="simple-cell-selection__container">
    <ul class="simple-cell-selection__list">
        <li *ngFor="let item of items"
            [class.selected]="selected && item === selected.item"
            (click)="onItemClick($event, item)"
        >
            {{ item.text }}
        </li>
    </ul>
</div>
