import { InjectionToken, Type } from '@angular/core';

export const ComponentRegistries = {
    ENTITY_WIDGET: 'widget',
    ENTITY_TABLE_CELL: 'table-cell',
    FILTER_CONTROL: 'filter-control',
};

export interface DynamicComponentInjectionTokenMap {
    [componentType: string]: InjectionToken<Type<any>>;
}

export const injectionTokenMap: { [registry: string]: DynamicComponentInjectionTokenMap } = {};

/**
 * Register an InjectionToken for the given component type
 * @param registry
 * @param {string} componentType
 * @param {InjectionToken<T>} token
 */
export function registerInjectionTokenForComponentType<T>(registry: string, componentType: string, token: InjectionToken<Type<T>>) {
    if (!injectionTokenMap[registry]) { injectionTokenMap[registry] = {}; }
    injectionTokenMap[registry][componentType] = token;
}

/**
 * Get an InjectionToken for the given component type
 * @param registry
 * @param {string} componentType
 * @returns {InjectionToken<T>}
 */
export function getInjectionTokenForComponentType<T>(registry: string, componentType: string): InjectionToken<Type<T>> {
    return (injectionTokenMap[registry]) ? injectionTokenMap[registry][componentType] : undefined;
}
