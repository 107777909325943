/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 3/30/17.
 */

import { Action } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { FormValues } from '../../../../core/models/form.model';
import { type } from '../../../../core/store/util';
import { TargetMarketCriteriaStructure, TargetMarketSuggestionEntry } from '../../models/target-market-criteria-structure.model';
import { TargetMarketCriteria } from '../../models/target-market-criteria.model';
import { TargetMarketCustomerData } from '../../models/target-market-customer-data.model';
import { TargetMarketCustomCustomer, TargetMarketCustomCustomerData } from '../../models/target-market-customer.model';

export const TargetMarketActionTypes = {
    ENGAGE_TARGET_MARKET_CRITERIA: type('TARGET_MARKET:ENGAGE_TARGET_MARKET_CRITERIA'),
    ENGAGE_TARGET_MARKET_CRITERIA_FAILED: type('TARGET_MARKET:ENGAGE_TARGET_MARKET_CRITERIA_FAILED'),

    DISENGAGE_TARGET_MARKET_CRITERIA: type('TARGET_MARKET:DISENGAGE_TARGET_MARKET_CRITERIA'),

    UPDATE_TARGET_MARKET_CRITERIA: type('TARGET_MARKET:UPDATE_TARGET_MARKET_CRITERIA'),
    CLEAR_TARGET_MARKET_CRITERIA: type('TARGET_MARKET:CLEAR_TARGET_MARKET_CRITERIA'),

    STORE_TARGET_MARKET_CUSTOM_CUSTOMER: type('TARGET_MARKET:STORE_TARGET_MARKER_CUSTOMER'),

    SELECT_TARGET_MARKET_CUSTOM_CUSTOMER: type('TARGET_MARKET:SELECT_TARGET_MARKET_CUSTOM_CUSTOMER'),
    UNSELECT_TARGET_MARKET_CUSTOMER: type('TARGET_MARKET:UNSELECT_TARGET_MARKET_CUSTOMER'),

    LOAD_CUSTOMER_ENABLED: type('LOAD_CUSTOMER_ENABLED'),
    LOADED_CUSTOMER_ENABLED: type('LOADED_CUSTOMER_ENABLED'),

    LOAD_CUSTOM_ENABLED: type('LOAD_CUSTOM_ENABLED'),
    LOADED_CUSTOM_ENABLED: type('LOADED_CUSTOM_ENABLED'),

    LOAD_TARGET_MARKET_CRITERIA_STRUCTURE: type('TARGET_MARKET:LOAD_TARGET_MARKET_CRITERIA_STRUCTURE'),
    LOAD_TARGET_MARKET_CRITERIA_STRUCTURE_SUCCEEDED: type(
        'TARGET_MARKET:LOAD_TARGET_MARKET_CRITERIA_STRUCTURE_SUCCEEDED'),
    LOAD_TARGET_MARKET_CRITERIA_STRUCTURE_FAILED: type('TARGET_MARKET:LOAD_TARGET_MARKET_CRITERIA_STRUCTURE_FAILED'),

    LOAD_TARGET_MARKET_CRITERIA_AVAILABLE_VALUES: type('TARGET_MARKET:LOAD_TARGET_MARKET_CRITERIA_AVAILABLE_VALUES'),
    LOAD_TARGET_MARKET_CRITERIA_AVAILABLE_VALUES_SUCCEEDED: type(
        'TARGET_MARKET:LOAD_TARGET_MARKET_CRITERIA_AVAILABLE_VALUES_SUCCEEDED'),
    LOAD_TARGET_MARKET_CRITERIA_AVAILABLE_VALUES_FAILED: type(
        'TARGET_MARKET:LOAD_TARGET_MARKET_CRITERIA_AVAILABLE_VALUES_FAILED'),

    ACTIVATE_TARGET_MARKET_CUSTOMER: type('TARGET_MARKET:ACTIVATE_TARGET_MARKET_CUSTOMER'),

    LOAD_TARGET_MARKET_CUSTOM_CUSTOMERS: type('TARGET_MARKET:LOAD_TARGET_MARKET_CUSTOM_CUSTOMERS'),
    LOAD_TARGET_MARKET_CUSTOM_CUSTOMERS_SUCCEEDED: type('TARGET_MARKET:LOAD_TARGET_MARKET_CUSTOM_CUSTOMERS_SUCCEEDED'),
    LOAD_TARGET_MARKET_CUSTOM_CUSTOMERS_FAILED: type('TARGET_MARKET:LOAD_TARGET_MARKET_CUSTOM_CUSTOMERS_FAILED'),

    LOAD_TARGET_MARKET_REFERENCE_CUSTOMERS: type('TARGET_MARKET:LOAD_TARGET_MARKET_REFERENCE_CUSTOMERS'),
    LOAD_TARGET_MARKET_REFERENCE_CUSTOMERS_SUCCEEDED: type('TARGET_MARKET:LOAD_TARGET_MARKET_REFERENCE_CUSTOMERS_SUCCEEDED'),
    LOAD_TARGET_MARKET_REFERENCE_CUSTOMERS_FAILED: type('TARGET_MARKET:LOAD_TARGET_MARKET_REFERENCE_CUSTOMERS_FAILED'),

    ACTIVATE_TARGET_MARKET_CUSTOM_CUSTOMER: type('TARGET_MARKET:ACTIVATE_TARGET_MARKET_CUSTOM_CUSTOMER'),
    ACTIVATE_TARGET_MARKET_REFERENCE_CUSTOMER: type('TARGET_MARKET:ACTIVATE_TARGET_MARKET_REFERENCE_CUSTOMER'),

    SAVE_TARGET_MARKET_CUSTOM_CUSTOMER: type('TARGET_MARKET:SAVE_TARGET_MARKET_CUSTOM_CUSTOMER'),
    SAVE_TARGET_MARKET_CUSTOM_CUSTOMER_SUCCEEDED: type('TARGET_MARKET:SAVE_TARGET_MARKET_CUSTOM_CUSTOMER_SUCCEEDED'),
    SAVE_TARGET_MARKET_CUSTOM_CUSTOMER_FAILED: type('TARGET_MARKET:SAVE_TARGET_MARKET_CUSTOM_CUSTOMER_FAILED'),

    CREATE_TARGET_MARKET_CUSTOM_CUSTOMER: type('TARGET_MARKET:CREATE_TARGET_MARKET_CUSTOM_CUSTOMER'),
    CREATE_TARGET_MARKET_CUSTOM_CUSTOMER_SUCCEEDED: type(
        'TARGET_MARKET:CREATE_TARGET_MARKET_CUSTOM_CUSTOMER_SUCCEEDED'),
    CREATE_TARGET_MARKET_CUSTOM_CUSTOMER_FAILED: type('TARGET_MARKET:CREATE_TARGET_MARKET_CUSTOM_CUSTOMER_FAILED'),

    REMOVE_TARGET_MARKET_CUSTOM_CUSTOMER: type('TARGET_MARKET:REMOVE_TARGET_MARKET_CUSTOM_CUSTOMER'),
    REMOVE_TARGET_MARKET_CUSTOM_CUSTOMER_SUCCEEDED: type(
        'TARGET_MARKET:REMOVE_TARGET_MARKET_CUSTOM_CUSTOMER_SUCCEEDED'),
    REMOVE_TARGET_MARKET_CUSTOM_CUSTOMER_FAILED: type('TARGET_MARKET:REMOVE_TARGET_MARKET_CUSTOM_CUSTOMER_FAILED'),

    SET_TEMP_TARGET_MARKET_CRITERIA: type('TARGET_MARKET:SET_TEMP_TARGET_MARKET_CRITERIA'),
    CLEAR_TEMP_TARGET_MARKET_CRITERIA: type('TARGET_MARKET:CLEAR_TEMP_TARGET_MARKET_CRITERIA'),

    LOAD_TARGET_MARKET_CUSTOMER_SUGGESTIONS: type('TARGET_MARKET:LOAD_TARGET_MARKET_CUSTOMER_SUGGESTIONS'),
    LOAD_TARGET_MARKET_CUSTOMER_SUGGESTIONS_SUCCEEDED: type('TARGET_MARKET:LOAD_TARGET_MARKET_CUSTOMER_SUGGESTIONS_SUCCEEDED'),
    LOAD_TARGET_MARKET_CUSTOMER_SUGGESTIONS_FAILED: type('TARGET_MARKET:LOAD_TARGET_MARKET_CUSTOMER_SUGGESTIONS_FAILED'),

    CLEAR_TARGET_MARKET_CUSTOMER_SUGGESTIONS: type('TARGET_MARKET:CLEAR_TARGET_MARKET_CUSTOMER_SUGGESTIONS'),

    LOAD_TARGET_MARKET_SELECTED_CUSTOMER: type('TARGET_MARKET:LOAD_TARGET_MARKET_SELECTED_CUSTOMER'),
    LOAD_TARGET_MARKET_SELECTED_CUSTOMER_SUCCEEDED: type('TARGET_MARKET:LOAD_TARGET_MARKET_SELECTED_CUSTOMER_SUCCEEDED'),
    LOAD_TARGET_MARKET_SELECTED_CUSTOMER_FAILED: type('TARGET_MARKET:LOAD_TARGET_MARKET_SELECTED_CUSTOMER_FAILED'),

    CLEAR_TARGET_MARKET_SELECTED_CUSTOMER: type('TARGET_MARKET:CLEAR_TARGET_MARKET_SELECTED_CUSTOMER'),

};

/** Target Market Engagement Actions **/

export interface EngageTargetMarketCriteriaAction extends Action {
    payload: {
        criteria: TargetMarketCriteria;
        encodedCriteria: string;
    };
}

/**
 * Create an ENGAGE_TARGET_MARKET_CRITERIA action
 * @returns {Action}
 */
export function engageTargetMarketCriteria(criteria: TargetMarketCriteria,
                                           encodedCriteria: string): EngageTargetMarketCriteriaAction {
    return {
        type: TargetMarketActionTypes.ENGAGE_TARGET_MARKET_CRITERIA,
        payload: {
            criteria,
            encodedCriteria,
        },
    };
}

/** EngageTargetMarketCriteriaFailedAction **/

export interface EngageTargetMarketCriteriaFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function engageTargetMarketCriteriaFailed(error: AppError): EngageTargetMarketCriteriaFailedAction {
    return {
        type: TargetMarketActionTypes.ENGAGE_TARGET_MARKET_CRITERIA_FAILED,
        payload: {
            error,
        },
    };
}

/**
 * Create an DISENGAGE_TARGET_MARKET_CRITERIA action
 * @returns {Action}
 */
export function disengageTargetMarketCriteria(): Action {
    return {
        type: TargetMarketActionTypes.DISENGAGE_TARGET_MARKET_CRITERIA,
    };
}

/** Update Target Market Criteria Action**/

export interface UpdateTargetMarketCriteriaAction extends Action {
    payload: {
        criteria: FormValues;
    };
}

export function updateTargetMarketCriteria(criteria: FormValues): UpdateTargetMarketCriteriaAction {
    return {
        type: TargetMarketActionTypes.UPDATE_TARGET_MARKET_CRITERIA,
        payload: {
            criteria,
        },
    };
}

/** Clear Target Market Criteria **/

export interface ClearTargetMarketCriteriaAction extends Action {
    payload?: void;
}

export function clearTargetMarketCriteria(): ClearTargetMarketCriteriaAction {
    return {
        type: TargetMarketActionTypes.CLEAR_TARGET_MARKET_CRITERIA,
    };
}

/** Store Target Market Custom Customer **/

export interface StoreTargetMarketCustomCustomerAction extends Action {
    payload: {
        customer: TargetMarketCustomCustomer,
    };
}

export function storeTargetMarketCustomerCustomer(customer: TargetMarketCustomCustomer): StoreTargetMarketCustomCustomerAction {
    return {
        type: TargetMarketActionTypes.STORE_TARGET_MARKET_CUSTOM_CUSTOMER,
        payload: {
            customer,
        },
    };
}

/** Select Target Market Customer **/

export interface SelectTargetMarketCustomerCustomerAction extends Action {
    payload: {
        customer: TargetMarketCustomCustomer,
    };
}

export function selectTargetMarketCustomCustomer(customer: TargetMarketCustomCustomer): SelectTargetMarketCustomerCustomerAction {
    return {
        type: TargetMarketActionTypes.SELECT_TARGET_MARKET_CUSTOM_CUSTOMER,
        payload: {
            customer,
        },
    };
}

/** Unselect Target Market Customer **/

export interface UnselectTargetMarketCustomerAction extends Action {
    payload?: void;
}

export function unselectTargetMarketCustomer(): UnselectTargetMarketCustomerAction {
    return {
        type: TargetMarketActionTypes.UNSELECT_TARGET_MARKET_CUSTOMER,
    };
}

/** LoadTargetMarketCriteriaStructureAction **/

export interface LoadTargetMarketCriteriaStructureAction extends Action {
    payload?: void;
}

export function loadTargetMarketCriteriaStructure(): LoadTargetMarketCriteriaStructureAction {
    return {
        type: TargetMarketActionTypes.LOAD_TARGET_MARKET_CRITERIA_STRUCTURE,
    };
}

/** LoadTargetMarketCriteriaStructureSucceededAction **/

export interface LoadTargetMarketCriteriaStructureSucceededAction extends Action {
    payload: {
        structure: TargetMarketCriteriaStructure;
    };
}

export function loadTargetMarketCriteriaStructureSucceeded(
    structure: TargetMarketCriteriaStructure,
): LoadTargetMarketCriteriaStructureSucceededAction {
    return {
        type: TargetMarketActionTypes.LOAD_TARGET_MARKET_CRITERIA_STRUCTURE_SUCCEEDED,
        payload: {
            structure,
        },
    };
}

/** LoadTargetMarketCriteriaStructureFailedAction **/

export interface LoadTargetMarketCriteriaStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadTargetMarketCriteriaStructureFailed(error: AppError): LoadTargetMarketCriteriaStructureFailedAction {
    return {
        type: TargetMarketActionTypes.LOAD_TARGET_MARKET_CRITERIA_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadTargetMarketCriteriaAvailableValuesAction **/

export interface LoadTargetMarketCriteriaAvailableValuesAction extends Action {
    payload?: void;
}

export function loadTargetMarketCriteriaAvailableValues(): LoadTargetMarketCriteriaAvailableValuesAction {
    return {
        type: TargetMarketActionTypes.LOAD_TARGET_MARKET_CRITERIA_AVAILABLE_VALUES,
    };
}

/** LoadTargetMarketCriteriaAvailableValuesSucceededAction **/

export interface LoadTargetMarketCriteriaAvailableValuesSucceededAction extends Action {
    payload: {
        values: FormValues;
    };
}

export function loadTargetMarketCriteriaAvailableValuesSucceeded(
    values: FormValues,
): LoadTargetMarketCriteriaAvailableValuesSucceededAction {
    return {
        type: TargetMarketActionTypes.LOAD_TARGET_MARKET_CRITERIA_AVAILABLE_VALUES_SUCCEEDED,
        payload: {
            values,
        },
    };
}

/** LoadTargetMarketCriteriaAvailableValuesFailedAction **/

export interface LoadTargetMarketCriteriaAvailableValuesFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadTargetMarketCriteriaAvailableValuesFailed(error: AppError): LoadTargetMarketCriteriaAvailableValuesFailedAction {
    return {
        type: TargetMarketActionTypes.LOAD_TARGET_MARKET_CRITERIA_AVAILABLE_VALUES_FAILED,
        payload: {
            error,
        },
    };
}

/** ActivateTargetMarketCustomerAction **/

export interface ActivateTargetMarketCustomerAction extends Action {
    payload: {
        customer: TargetMarketCustomerData;
    };
}

export function activateTargetMarketCustomer(customer: TargetMarketCustomerData): ActivateTargetMarketCustomerAction {
    return {
        type: TargetMarketActionTypes.ACTIVATE_TARGET_MARKET_CUSTOMER,
        payload: {
            customer,
        },
    };
}

/** LoadTargetMarketCustomCustomersAction **/

export interface LoadTargetMarketCustomCustomersAction extends Action {
    payload: object;
}

export function loadTargetMarketCustomCustomers(): LoadTargetMarketCustomCustomersAction {
    return {
        type: TargetMarketActionTypes.LOAD_TARGET_MARKET_CUSTOM_CUSTOMERS,
        payload: {},
    };
}

/** LoadTargetMarketCustomCustomersSucceededAction **/

export interface LoadTargetMarketCustomCustomersSucceededAction extends Action {
    payload: {
        customers: TargetMarketCustomCustomer[];
    };
}

export function loadTargetMarketCustomCustomersSucceeded(
    customers: TargetMarketCustomCustomer[],
): LoadTargetMarketCustomCustomersSucceededAction {
    return {
        type: TargetMarketActionTypes.LOAD_TARGET_MARKET_CUSTOM_CUSTOMERS_SUCCEEDED,
        payload: {
            customers,
        },
    };
}

/** LoadTargetMarketCustomCustomersFailedAction **/

export interface LoadTargetMarketCustomCustomersFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadTargetMarketCustomCustomersFailed(error: AppError): LoadTargetMarketCustomCustomersFailedAction {
    return {
        type: TargetMarketActionTypes.LOAD_TARGET_MARKET_CUSTOM_CUSTOMERS_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadTargetMarketReferenceCustomersAction **/
export interface LoadTargetMarketReferenceCustomersAction extends Action {
    payload: object;
}

export function loadTargetMarketReferenceCustomers(): LoadTargetMarketReferenceCustomersAction {
    return {
        type: TargetMarketActionTypes.LOAD_TARGET_MARKET_REFERENCE_CUSTOMERS,
        payload: {},
    };
}

/** LoadTargetMarketCustomCustomersSucceededAction **/
export interface LoadTargetMarketReferenceCustomersSucceededAction extends Action {
    payload: {
        customers: TargetMarketCustomCustomer[];
    };
}

export function loadTargetMarketReferenceCustomersSucceeded(
    customers: TargetMarketCustomCustomer[],
): LoadTargetMarketReferenceCustomersSucceededAction {
    return {
        type: TargetMarketActionTypes.LOAD_TARGET_MARKET_REFERENCE_CUSTOMERS_SUCCEEDED,
        payload: {
            customers,
        },
    };
}

/** LoadTargetMarketReferenceCustomersFailedAction **/
export interface LoadTargetMarketReferenceCustomersFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadTargetMarketReferenceCustomersFailed(error: AppError): LoadTargetMarketReferenceCustomersFailedAction {
    return {
        type: TargetMarketActionTypes.LOAD_TARGET_MARKET_REFERENCE_CUSTOMERS_FAILED,
        payload: {
            error,
        },
    };
}

/** ActivateTargetMarketCustomCustomerAction **/

export interface ActivateTargetMarketCustomCustomerAction extends Action {
    payload: {
        customer: TargetMarketCustomCustomer;
    };
}

export function activateTargetMarketCustomCustomer(
    customer: TargetMarketCustomCustomer,
): ActivateTargetMarketCustomCustomerAction {
    return {
        type: TargetMarketActionTypes.ACTIVATE_TARGET_MARKET_CUSTOM_CUSTOMER,
        payload: {
            customer,
        },
    };
}

/** ActivateTargetMarketReferenceCustomerAction **/

export interface ActivateTargetMarketReferenceCustomerAction extends Action {
    payload: {
        customer: TargetMarketCustomCustomer;
    };
}

export function activateTargetMarketReferenceCustomer(
    customer: TargetMarketCustomCustomer,
): ActivateTargetMarketReferenceCustomerAction {
    return {
        type: TargetMarketActionTypes.ACTIVATE_TARGET_MARKET_REFERENCE_CUSTOMER,
        payload: {
            customer,
        },
    };
}

/** SaveTargetMarketCustomCustomerAction **/

export interface SaveTargetMarketCustomCustomerAction extends Action {
    payload: {
        customerId: string;
        data: TargetMarketCustomCustomerData;
    };
}

export function saveTargetMarketCustomCustomer(customerId: string,
                                               data: TargetMarketCustomCustomerData): SaveTargetMarketCustomCustomerAction {
    return {
        type: TargetMarketActionTypes.SAVE_TARGET_MARKET_CUSTOM_CUSTOMER,
        payload: {
            customerId,
            data,
        },
    };
}

/** SaveTargetMarketCustomCustomerSucceededAction **/

export interface SaveTargetMarketCustomCustomerSucceededAction extends Action {
    payload: {
        customer: TargetMarketCustomCustomer,
    };
}

export function saveTargetMarketCustomCustomerSucceeded(
    customer: TargetMarketCustomCustomer,
): SaveTargetMarketCustomCustomerSucceededAction {
    return {
        type: TargetMarketActionTypes.SAVE_TARGET_MARKET_CUSTOM_CUSTOMER_SUCCEEDED,
        payload: {
            customer,
        },
    };
}

/** SaveTargetMarketCustomCustomerFailedAction **/

export interface SaveTargetMarketCustomCustomerFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function saveTargetMarketCustomCustomerFailed(error: AppError): SaveTargetMarketCustomCustomerFailedAction {
    return {
        type: TargetMarketActionTypes.SAVE_TARGET_MARKET_CUSTOM_CUSTOMER_FAILED,
        payload: {
            error,
        },
    };
}

/** CreateTargetMarketCustomCustomerAction **/

export interface CreateTargetMarketCustomCustomerAction extends Action {
    payload: {
        data: TargetMarketCustomCustomerData,
    };
}

export function createTargetMarketCustomCustomer(data: TargetMarketCustomCustomerData): CreateTargetMarketCustomCustomerAction {
    return {
        type: TargetMarketActionTypes.CREATE_TARGET_MARKET_CUSTOM_CUSTOMER,
        payload: {
            data,
        },
    };
}

/** CreateTargetMarketCustomCustomerSucceededAction **/

export interface CreateTargetMarketCustomCustomerSucceededAction extends Action {
    payload: {
        customer: TargetMarketCustomCustomer,
    };
}

export function createTargetMarketCustomCustomerSucceeded(
    customer: TargetMarketCustomCustomer,
): CreateTargetMarketCustomCustomerSucceededAction {
    return {
        type: TargetMarketActionTypes.CREATE_TARGET_MARKET_CUSTOM_CUSTOMER_SUCCEEDED,
        payload: {
            customer,
        },
    };
}

/** CreateTargetMarketCustomCustomerFailedAction **/

export interface CreateTargetMarketCustomCustomerFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function createTargetMarketCustomCustomerFailed(error: AppError): CreateTargetMarketCustomCustomerFailedAction {
    return {
        type: TargetMarketActionTypes.CREATE_TARGET_MARKET_CUSTOM_CUSTOMER_FAILED,
        payload: {
            error,
        },
    };
}

/** RemoveTargetMarketCustomCustomerAction **/

export interface RemoveTargetMarketCustomCustomerAction extends Action {
    payload: {
        customerId: string,
    };
}

export function removeTargetMarketCustomCustomer(customerId: string): RemoveTargetMarketCustomCustomerAction {
    return {
        type: TargetMarketActionTypes.REMOVE_TARGET_MARKET_CUSTOM_CUSTOMER,
        payload: {
            customerId,
        },
    };
}

/** RemoveTargetMarketCustomCustomerSucceededAction **/

export interface RemoveTargetMarketCustomCustomerSucceededAction extends Action {
    payload: {
        customerId: string,
    };
}

export function removeTargetMarketCustomCustomerSucceeded(customerId: string): RemoveTargetMarketCustomCustomerSucceededAction {
    return {
        type: TargetMarketActionTypes.REMOVE_TARGET_MARKET_CUSTOM_CUSTOMER_SUCCEEDED,
        payload: {
            customerId,
        },
    };
}

/** RemoveTargetMarketCustomCustomerFailedAction **/

export interface RemoveTargetMarketCustomCustomerFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function removeTargetMarketCustomCustomerFailed(error: AppError): RemoveTargetMarketCustomCustomerFailedAction {
    return {
        type: TargetMarketActionTypes.REMOVE_TARGET_MARKET_CUSTOM_CUSTOMER_FAILED,
        payload: {
            error,
        },
    };
}

/** SetTempTargetMarketCriteriaAction **/

export interface SetTempTargetMarketCriteriaAction extends Action {
    payload: {
        criteria: TargetMarketCriteria;
    };
}

export function setTempTargetMarketCriteria(criteria: TargetMarketCriteria): SetTempTargetMarketCriteriaAction {
    return {
        type: TargetMarketActionTypes.SET_TEMP_TARGET_MARKET_CRITERIA,
        payload: {
            criteria,
        },
    };
}

/** ClearTempTargetMarketCriteriaAction **/

export interface ClearTempTargetMarketCriteriaAction extends Action {
    payload?: void;
}

export function clearTempTargetMarketCriteria(): ClearTempTargetMarketCriteriaAction {
    return {
        type: TargetMarketActionTypes.CLEAR_TEMP_TARGET_MARKET_CRITERIA,
    };
}

/** LoadTargetMarketCustomerSuggestionsAction **/

export interface LoadTargetMarketCustomerSuggestionsAction extends Action {
    payload: {
        filter: string,
    };
}

export function loadTargetMarketCustomerSuggestions(filter: string): LoadTargetMarketCustomerSuggestionsAction {
    return {
        type: TargetMarketActionTypes.LOAD_TARGET_MARKET_CUSTOMER_SUGGESTIONS,
        payload: {
            filter,
        },
    };
}

/** LoadTargetMarketCustomerSuggestionsSucceededAction **/

export interface LoadTargetMarketCustomerSuggestionsSucceededAction extends Action {
    payload: {
        data: TargetMarketSuggestionEntry[];
    };
}

export function loadTargetMarketCustomerSuggestionsSucceeded(
    data: TargetMarketSuggestionEntry[],
): LoadTargetMarketCustomerSuggestionsSucceededAction {
    return {
        type: TargetMarketActionTypes.LOAD_TARGET_MARKET_CUSTOMER_SUGGESTIONS_SUCCEEDED,
        payload: {
            data,
        },
    };
}

/** LoadTargetMarketCustomerSuggestionsFailedAction **/

export interface LoadTargetMarketCustomerSuggestionsFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadTargetMarketCustomerSuggestionsFailed(error: AppError): LoadTargetMarketCustomerSuggestionsFailedAction {
    return {
        type: TargetMarketActionTypes.LOAD_TARGET_MARKET_CUSTOMER_SUGGESTIONS_FAILED,
        payload: {
            error,
        },
    };
}

/** ClearTargetMarketCustomerSuggestionsAction **/

export interface ClearTargetMarketCustomerSuggestionsAction extends Action {
    payload?: void;
}

export function clearTargetMarketCustomerSuggestions(): ClearTargetMarketCustomerSuggestionsAction {
    return {
        type: TargetMarketActionTypes.CLEAR_TARGET_MARKET_CUSTOMER_SUGGESTIONS,
    };
}

/** LoadTargetMarketSelectedCustomerAction **/

export interface LoadTargetMarketSelectedCustomerAction extends Action {
    payload: {
        criteriaId: string;
    };
}

export function loadTargetMarketSelectedCustomer(criteriaId: string): LoadTargetMarketSelectedCustomerAction {
    return {
        type: TargetMarketActionTypes.LOAD_TARGET_MARKET_SELECTED_CUSTOMER,
        payload: {
            criteriaId,
        },
    };
}

/** LoadTargetMarketSelectedCustomerSucceededAction **/

export interface LoadTargetMarketSelectedCustomerSucceededAction extends Action {
    payload: {
        customerData: TargetMarketCustomerData,
    };
}

export function loadTargetMarketSelectedCustomerSucceeded(
    customerData: TargetMarketCustomerData,
): LoadTargetMarketSelectedCustomerSucceededAction {
    return {
        type: TargetMarketActionTypes.LOAD_TARGET_MARKET_SELECTED_CUSTOMER_SUCCEEDED,
        payload: {
            customerData,
        },
    };
}

/** LoadTargetMarketSelectedCustomerFailedAction **/

export interface LoadTargetMarketSelectedCustomerFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadTargetMarketSelectedCustomerFailed(error: AppError): LoadTargetMarketSelectedCustomerFailedAction {
    return {
        type: TargetMarketActionTypes.LOAD_TARGET_MARKET_SELECTED_CUSTOMER_FAILED,
        payload: {
            error,
        },
    };
}

/** ClearTargetMarketSelectedCustomerAction **/

export interface ClearTargetMarketSelectedCustomerAction extends Action {
    payload?: void;
}

export function clearTargetMarketSelectedCustomer(): ClearTargetMarketSelectedCustomerAction {
    return {
        type: TargetMarketActionTypes.CLEAR_TARGET_MARKET_SELECTED_CUSTOMER,
    };
}
