import { Injectable } from '@angular/core';
import * as elementResizeDetectorMaker from 'element-resize-detector';

@Injectable()
export class ResizeService {
    private resizeDetector: elementResizeDetectorMaker.Erd;

    constructor() {
        this.resizeDetector = elementResizeDetectorMaker({ strategy: 'scroll' });
    }

    public addResizeEventListener(element: HTMLElement, handler: (elem: HTMLElement) => void): void {
        this.resizeDetector.listenTo(element, handler);
    }

    public removeResizeEventListener(element: HTMLElement): void {
        this.resizeDetector.uninstall(element);
    }
}
