import { Action } from '@ngrx/store';
import { flatten, remove } from 'lodash';
import { ProductComponent } from '../../../shared/product-designer-shared/models/product-designer.model';

import {
    AcceptOfferResponseFailureAction,
    AcceptOfferResponseSuccessAction,
    AddOfferRequesFailureAction,
    AddProductDesignerGroupProductComponents,
    AddProductDesignerProductComponentAction,
    CopyProductRequestAction,
    CreateProductRequestFailureAction,
    CreateProductRequestSuccessAction,
    LoadMatrixPricingAvailabilitiesFailureAction,
    LoadMatrixPricingAvailabilitiesSuccessAction,
    LoadProductDesignerAllIssuersSuccessAction,
    LoadProductDesignerAvailableUsersSuccessAction,
    LoadProductDesignerDataByProductIdAction,
    LoadProductDesignerDataByProductIdFailureAction,
    LoadProductDesignerDataByProductIdSuccessAction,
    LoadProductDesignerHistoryFilterStructureSuccessAction,
    LoadProductDesignerHistoryFilterValuesSuccessAction,
    LoadProductDesignerHistoryInitialValuesSuccessAction,
    LoadProductDesignerHistoryStructuresSuccessAction,
    LoadProductDesignerHistoryTableValuesSuccessAction,
    LoadProductDesignerInitialValuesFailureAction,
    LoadProductDesignerInitialValuesSuccessAction,
    LoadProductDesignerProductTypesFailureAction,
    LoadProductDesignerProductTypesSuccessAction,
    LoadProductDesignerRequestParameterValuesSuccessAction,
    LoadProductDesignerStructureFailureAction,
    LoadProductDesignerStructureSuccessAction,
    LoadProductDesignerSurveyDataSuccessAction,
    LoadProductDesignerSurveyFilterStructureSuccessAction,
    LoadProductDesignerSurveyFilterValuesSuccessAction,
    LoadProductDesignerSurveyTableStructureSuccessAction,
    LoadProductDesignerSurveyTableValuesSuccessAction,
    LoadProductDesignerUnderlyingsGraphSuccessAction,
    LoadProductDesignerValuesFailureAction,
    LoadProductDesignerValuesSuccessAction,
    LoadRequestFormMultiRequestStructureFailureAction,
    LoadRequestFormMultiRequestStructureSuccessAction,
    ProductDesignerActions,
    PublishProductRequestSuccessAction,
    RecomposeProductComponentsAction,
    ResetProductDesignerProductResponseAction,
    UpdateProductRequestAction,
    ValidateProductDesignerProductSuccessAction,
} from '../actions/product-designer.actions';
import { ProductDesignerState } from '../state/product-designer.state';
import { mergeDeep } from '../../../../shared/helpers/general.helper';
import { cleanupComponentDataWithStructureMeta, getProductDataByComponents, prepareComponentStructure } from '../../../shared/product-designer-shared/helpers/product-designer.helpers';

export const initialState: ProductDesignerState = {
    structure: null,
    structureError: null,
    productTypes: null,

    currentProductData: {},
    values: null,
    targetValues: null,
    underlyingsGraphData: null,
    underlyingsGraphOptions: null,
    underlyingsGraphLoading: false,

    currentProductRequest: {
        createdAt: (new Date()).toISOString(),
        offerType: 'INDICATIVE',
        matrixPricingData: {type: 'BARRIER', totalSteps: 0, value: 0},
    },

    productValidationResult: null,
    currentProductResponse: null,
    currentProductResponseCopy: null,
    currentProductOffers: null,
    requestParameterValues: null,
    currentMultiRequestStructure: null,

    historyTableStructure: null,
    historyTableValues: null,

    historyFilterStructure: null,
    historyFilterValues: null,
    historyFilterInitialValues: null,

    surveyFilterStructure: null,
    surveyFilterValues: null,

    surveyTableStructure: null,
    surveyTableValues: null,

    availableUsers: null,
    currentSurveyData: null,

    allAvailableIssuers: null,
    validatedIssuers: null,

    matrixPricingAvailabilities: null,

    loadingCreateProductRequest: false,
    loadingStructure: false,
    loadingProductById: false,
    loadingValues: false,
    loadingInitialValues: false,
    loadingProductTypes: false,
    loadingAllAvailableIssuers: false,
    loadingAddOfferRequest: false,
    loadingProductValidationResult: false,
    loadingMatrixPricingAvailabilities: false,
    loadingOfferResponseIssued: false,
    loadingHistoryFilterValues: false,
    loadingHistoryStructures: false,
    loadingHistoryData: false,
    loadingHistoryInitialValues: false,
    loadingMultiRequestStructure: false,

    errorLoadingCreateProductRequest: null,
    errorLoadingStructure: null,
    errorLoadingValues: null,
    errorLoadingInitialValues: null,
    errorLoadingProductTypes: null,
    errorAddOfferRequest: null,
    errorLoadingMatrixPricingAvailabilities: null,
    errorOfferResponseIssued: null,
    errorLoadingProductById: null,
    errorLoadingMultiRequestStructure: null,

    successCreateProductRequest: false,
    successAddOfferRequest: null,
};

export function reducer(state: ProductDesignerState = initialState, action: Action): ProductDesignerState {
    switch (action.type) {
        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_STRUCTURE: {
            return {
                ...state,
                loadingStructure: true,
                errorLoadingStructure: null,
                errorLoadingProductById: null,
                structure: null,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_STRUCTURE_SUCCESS: {
            const loadProductDesignerStructureSuccessAction = action as LoadProductDesignerStructureSuccessAction;
            const structure = loadProductDesignerStructureSuccessAction.payload.structure;
            /**
             * __target: {
                        values: {}
                        }
             */
            return {
                ...state,
                structure,
                targetValues: structure.solveTargets.map((t) => ({
                    id: t.solveTarget,
                    label: t.name,
                })),
                loadingStructure: false,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_STRUCTURE_FAILURE: {
            const loadProductDesignerStructureFailureAction = action as LoadProductDesignerStructureFailureAction;

            return {
                ...state,
                loadingStructure: false,
                errorLoadingStructure: loadProductDesignerStructureFailureAction.payload.error,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_VALUES: {
            return {
                ...state,
                loadingValues: true,
                errorLoadingValues: null,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_VALUES_SUCCESS: {
            const loadProductDesignerValuesSuccessAction = action as LoadProductDesignerValuesSuccessAction;

            return {
                ...state,
                values: loadProductDesignerValuesSuccessAction.payload.values,
                loadingValues: false,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_VALUES_FAILURE: {
            const loadProductDesignerValuesFailureAction = action as LoadProductDesignerValuesFailureAction;

            return {
                ...state,
                loadingValues: false,
                errorLoadingValues: loadProductDesignerValuesFailureAction.payload.error,
            };
        }

        case ProductDesignerActions.LOAD_REQUEST_FORM_MULTI_REQUEST_STRUCTURE_REQUEST: {
            return {
                ...state,
                loadingMultiRequestStructure: true,
                errorLoadingMultiRequestStructure: null,
            };
        }

        case ProductDesignerActions.LOAD_REQUEST_FORM_MULTI_REQUEST_STRUCTURE_SUCCESS: {
            const loadRequestFormMultiRequestStructureSuccessAction = action as LoadRequestFormMultiRequestStructureSuccessAction;

            return {
                ...state,
                loadingMultiRequestStructure: false,
                currentMultiRequestStructure: loadRequestFormMultiRequestStructureSuccessAction.payload.structure,
                errorLoadingMultiRequestStructure: null,
            };
        }

        case ProductDesignerActions.LOAD_REQUEST_FORM_MULTI_REQUEST_STRUCTURE_FAILURE: {
            const loadRequestFormMultiRequestStructureFailureAction = action as LoadRequestFormMultiRequestStructureFailureAction;

            return {
                ...state,
                loadingMultiRequestStructure: false,
                currentMultiRequestStructure: null,
                errorLoadingMultiRequestStructure: loadRequestFormMultiRequestStructureFailureAction.payload.error,
            };
        }

        case ProductDesignerActions.ADD_PRODUCT_DESIGNER_PRODUCT_COMPONENT: {
            const addProductDesignerProductComponentAction = action as AddProductDesignerProductComponentAction;

            const group = addProductDesignerProductComponentAction.payload.group;
            const component = addProductDesignerProductComponentAction.payload.component;

            return {
                ...state,
                currentProductData: {
                    ...state.currentProductData,
                    [group]: [
                        ...(state.currentProductData[group] ? state.currentProductData[group] : []),
                        {
                            ...addProductDesignerProductComponentAction.payload.initial,
                            type: component,
                        } as ProductComponent,
                    ],
                },
            };
        }

        case ProductDesignerActions.RECOMPOSE_PRODUCT_COMPONENTS: {
            const recomposeProductComponentsAction = action as RecomposeProductComponentsAction;
            const groupName = recomposeProductComponentsAction.payload.group.name;
            const currentGroupData = state.currentProductData[groupName] ? state.currentProductData[groupName] : [];

            const originIndex = recomposeProductComponentsAction.payload.decomposedEvent.event.origin.index;
            const deleteLength = recomposeProductComponentsAction.payload.decomposedEvent.event.result.target.reduce((acc, current) => {
                return acc + current.values.length;
            }, 0);

            const originEvent = recomposeProductComponentsAction.payload.decomposedEvent.event.origin;

            originEvent.structure.__group = recomposeProductComponentsAction.payload.group;
            const structWithMeta = prepareComponentStructure(originEvent.structure);

            const targetStructs = recomposeProductComponentsAction.payload.decomposedEvent.event.result.target.reduce((acc, current) => {
                acc.push(current.structure);

                return acc;
            }, []);

            const structureGroups = state.structure.groups.map((group) => {
                if (group.name === groupName) {
                    targetStructs.forEach((struct) => {
                        const cIndex = group.components.findIndex((c) => c.name === struct.name);
                        if (cIndex !== -1) {
                            group.components = remove(group.components, (v, i) => {
                                return i === cIndex;
                            });
                        }
                    });

                    group.components.push(structWithMeta.structure);
                }

                return group;
            });

            currentGroupData.splice(originIndex, deleteLength);

            return {
                ...state,
                currentProductData: {
                    ...state.currentProductData,
                    [groupName]: [
                        ...currentGroupData,
                        originEvent.component
                    ]
                },
                structure: {
                    ...state.structure,
                    groups: structureGroups,
                },
            };
        }

        case ProductDesignerActions.ADD_PRODUCT_DESIGNER_GROUP_PRODUCT_COMPONENTS: {
            const addProductDesignerGroupProductComponentsAction = action as AddProductDesignerGroupProductComponents;

            const groupName = addProductDesignerGroupProductComponentsAction.payload.group.name;
            const sourceComponent = addProductDesignerGroupProductComponentsAction.payload.decomposeResult.source;
            const currentGroupData = state.currentProductData[groupName] ? state.currentProductData[groupName] : [];

            const componentsMap = {};

            const newComponentsWithMeta = addProductDesignerGroupProductComponentsAction.payload.decomposeResult.target.map((t) => {
                t.structure.__group = addProductDesignerGroupProductComponentsAction.payload.group;

                const structWithMeta = prepareComponentStructure(t.structure);
                componentsMap[structWithMeta.structure.name] = structWithMeta;

                return structWithMeta;
            });

            const structureGroups = state.structure.groups.map((group) => {
                if (group.name === addProductDesignerGroupProductComponentsAction.payload.group.name) {
                    group.components.push(...newComponentsWithMeta.map((c) => c.structure));

                    const cIndex = group.components.findIndex((c) => c.name === sourceComponent.type);

                    if (cIndex !== -1) {
                        group.components.splice(cIndex, 1);
                    }
                }

                return group;
            });

            const dataIndex = currentGroupData.findIndex((d) => d.type === sourceComponent.type);
            currentGroupData.splice(dataIndex, 1);

            return {
                ...state,
                currentProductData: {
                    ...state.currentProductData,
                    [groupName]: [
                        ...currentGroupData,
                        ...(
                            flatten(addProductDesignerGroupProductComponentsAction.payload.decomposeResult.target.map((t) => {
                                const structWithMeta = componentsMap[t.structure.name];

                                if (t.values) {
                                    return t.values.map((v) => {
                                        return {
                                            type: t.structure.name,
                                            ...cleanupComponentDataWithStructureMeta(structWithMeta, v, addProductDesignerGroupProductComponentsAction.payload.decomposeResult.source),
                                        };
                                    });
                                }

                                return {
                                    type: t.structure.name,
                                    ...cleanupComponentDataWithStructureMeta(structWithMeta, t.value, addProductDesignerGroupProductComponentsAction.payload.decomposeResult.source),
                                };
                            }))
                        )
                    ]
                },
                values: mergeDeep(
                    {},
                    state.values,
                    ...(addProductDesignerGroupProductComponentsAction.payload.decomposeResult.target.map((target) => {
                        return target.selectionValues;
                    })),
                ),
                structure: {
                    ...state.structure,
                    groups: structureGroups,
                },
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_UNDERLYINGS_GRAPH: {
            return {
                ...state,
                underlyingsGraphLoading: true,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_UNDERLYINGS_GRAPH_SUCCESS: {
            const loadProductDesignerUnderlyingsGraphAction = action as LoadProductDesignerUnderlyingsGraphSuccessAction;

            return {
                ...state,
                underlyingsGraphData: loadProductDesignerUnderlyingsGraphAction.payload.data.chart,
                underlyingsGraphOptions: loadProductDesignerUnderlyingsGraphAction.payload.data.options,
                underlyingsGraphLoading: false,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_UNDERLYINGS_GRAPH_FAILURE: {
            return {
                ...state,
                underlyingsGraphLoading: false,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_INITIAL_VALUES: {
            return {
                ...state,
                loadingInitialValues: true,
                errorLoadingInitialValues: null,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_INITIAL_VALUES_SUCCESS: {
            const loadProductDesignerInitialValuesSuccessAction = action as LoadProductDesignerInitialValuesSuccessAction;
            let parameters = null;
            let productData = null;

            if (state.currentProductResponseCopy) {
                productData = getProductDataByComponents(
                    state.currentProductResponseCopy.requestDetails,
                    loadProductDesignerInitialValuesSuccessAction.payload.structure,
                );
                parameters = state.currentProductResponseCopy.requestDetails;
            } else {
                parameters = loadProductDesignerInitialValuesSuccessAction.payload.data.requestParameters;
                productData = getProductDataByComponents(
                    loadProductDesignerInitialValuesSuccessAction.payload.data.requestParameters,
                    loadProductDesignerInitialValuesSuccessAction.payload.structure,
                );
            }

            // TODO: Revisit again to finish up structure mapping / refactoring.
            /*const productRequest = {
                offerType: parameters.offerType,
                solveTarget: parameters.solveTarget,
                answerDate: parameters.awaitUntil,
                emittent: parameters.issuers,
                comments: parameters.notes.join('\n'),
                expectedSalesVolume: parameters.expectedSalesVolume || 1000,
                conditionsSecurity: parameters.secureCondition,
            } as ProductRequestParameters;*/

            return {
                ...state,
                currentProductResponse: null,
                currentProductData: {
                    ...(productData || {}),
                },
                currentProductRequest: {
                    ...parameters,
                },
                currentProductResponseCopy: null,
                loadingInitialValues: false,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_INITIAL_VALUES_FAILURE: {
            const loadProductDesignerInitialValuesFailureAction = action as LoadProductDesignerInitialValuesFailureAction;

            return {
                ...state,
                loadingInitialValues: false,
                errorLoadingInitialValues: loadProductDesignerInitialValuesFailureAction.payload.error,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_DATA_BY_PRODUCT_ID: {
            const loadProductDesignerDataByProductIdAction = action as LoadProductDesignerDataByProductIdAction;
            const loadStructure = loadProductDesignerDataByProductIdAction.payload.loadStructure;

            if (loadStructure) {
                return {
                    ...state,
                    loadingProductById: true,
                    currentProductResponse: null,
                    currentProductData: {},
                    currentProductRequest: null,
                    currentProductOffers: null,
                    errorLoadingProductById: null,
                };
            } else {
                return state;
            }
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_DATA_BY_PRODUCT_ID_FAILURE: {
            const loadProductDesignerDataByProductIdFailureAction = action as LoadProductDesignerDataByProductIdFailureAction;

            return {
                ...state,
                loadingProductById: false,
                errorLoadingProductById: loadProductDesignerDataByProductIdFailureAction.payload.error,
            };
        }

        case ProductDesignerActions.RESET_PRODUCT_DESIGNER_PRODUCT_RESPONSE: {
            const resetProductDesignerResponseAction = action as ResetProductDesignerProductResponseAction;

            let data = state.currentProductData;
            if (resetProductDesignerResponseAction.payload.data) {
                data = {...resetProductDesignerResponseAction.payload.data};
            }

            return {
                ...state,
                currentProductData: data,
                currentProductResponse: null,
                currentProductOffers: null,
            };
        }

        case ProductDesignerActions.RESET_PRODUCT_DESIGNER_STATE: {
            return {
                ...initialState,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_DATA_BY_PRODUCT_ID_SUCCESS: {
            const loadProductDesignerDataByProductIdSuccessAction = action as LoadProductDesignerDataByProductIdSuccessAction;
            const parameters = loadProductDesignerDataByProductIdSuccessAction.payload.data.requestDetails;
            const loadStructure = loadProductDesignerDataByProductIdSuccessAction.payload.loadStructure;
            const structure = loadProductDesignerDataByProductIdSuccessAction.payload.structure || state.structure;

            const productData = getProductDataByComponents(
                loadProductDesignerDataByProductIdSuccessAction.payload.data.requestDetails,
                structure,
            );

            return {
                ...state,
                ...(loadStructure ? {structure: loadProductDesignerDataByProductIdSuccessAction.payload.structure} : {}),
                loadingProductById: false,
                targetValues: structure.solveTargets.map((t) => ({
                    id: t.solveTarget,
                    label: t.name,
                })),
                currentProductResponse: {
                    ...loadProductDesignerDataByProductIdSuccessAction.payload.data,
                },
                currentProductData: {
                    ...(productData || {}),
                },
                currentProductRequest: {
                    ...parameters,
                },

                currentProductOffers: [
                    ...flatten(Object.values(loadProductDesignerDataByProductIdSuccessAction.payload.data.responses)),
                ],
                errorLoadingProductById: null,
            };
        }

        case ProductDesignerActions.PUBLISH_PRODUCT_REQUEST_SUCCESS:
        case ProductDesignerActions.CREATE_PRODUCT_REQUEST_SUCCESS: {
            const createProductRequestSuccessAction = action as CreateProductRequestSuccessAction | PublishProductRequestSuccessAction;
            const parameters = createProductRequestSuccessAction.payload.response.requestDetails;
            const productData = getProductDataByComponents(
                createProductRequestSuccessAction.payload.response.requestDetails,
                state.structure,
            );

            return {
                ...state,
                loadingCreateProductRequest: false,
                successCreateProductRequest: true,
                currentProductResponse: {
                    ...createProductRequestSuccessAction.payload.response,
                },
                currentProductData: {
                    ...(productData || {}),
                },
                currentProductRequest: {
                    ...parameters,
                },
                currentProductOffers: [
                    ...flatten(Object.values(createProductRequestSuccessAction.payload.response.responses)),
                ],
            };
        }

        case ProductDesignerActions.CREATE_PRODUCT_REQUEST: {
            return {
                ...state,
                loadingCreateProductRequest: true,
                errorLoadingCreateProductRequest: null,
                successCreateProductRequest: false,
            };
        }

        case ProductDesignerActions.CREATE_PRODUCT_REQUEST_FAILURE: {
            const createProductRequestFailureAction = action as CreateProductRequestFailureAction;
            return {
                ...state,
                loadingCreateProductRequest: false,
                errorLoadingCreateProductRequest: createProductRequestFailureAction.payload.error,
                successCreateProductRequest: false,
            };
        }

        case ProductDesignerActions.RESET_PRODUCT_REQUEST_FAILURE: {
            return {
                ...state,
                errorLoadingCreateProductRequest: null,
            };
        }

        case ProductDesignerActions.UPDATE_PRODUCT_REQUEST: {
            const updateProductRequestAction = action as UpdateProductRequestAction;

            return {
                ...state,
                currentProductRequest: {
                    ...updateProductRequestAction.payload.request,
                },
            };
        }

        case ProductDesignerActions.COPY_PRODUCT_REQUEST: {
            const copyProductRequestAction = action as CopyProductRequestAction;

            return {
                ...state,
                currentProductResponseCopy: copyProductRequestAction.payload.response,
            };
        }

        case ProductDesignerActions.ADD_OFFER_REQUEST: {
            return {
                ...state,
                loadingAddOfferRequest: true,
                errorAddOfferRequest: null,
                successAddOfferRequest: null,
            };
        }

        case ProductDesignerActions.ADD_OFFER_REQUEST_SUCCESS: {
            return {
                ...state,
                loadingAddOfferRequest: false,
                errorAddOfferRequest: null,
                successAddOfferRequest: true,
            };
        }

        case ProductDesignerActions.ADD_OFFER_REQUEST_FAILURE: {
            const addOfferRequestFailureAction = action as AddOfferRequesFailureAction;
            return {
                ...state,
                loadingAddOfferRequest: false,
                errorAddOfferRequest: addOfferRequestFailureAction.payload.error,
                successAddOfferRequest: false,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_PRODUCT_TYPES: {
            return {
                ...state,
                loadingProductTypes: true,
                errorLoadingProductTypes: null,
            };
        }

        case ProductDesignerActions.UPDATE_OFFER_RESPONSE_NOTE_REQUEST:
        case ProductDesignerActions.ACCEPT_OFFER_RESPONSE_REQUEST:
        case ProductDesignerActions.DECLINE_OFFER_RESPONSE_REQUEST: {
            return {
                ...state,
                loadingOfferResponseIssued: true,
                errorOfferResponseIssued: null,
            };
        }

        case ProductDesignerActions.UPDATE_OFFER_RESPONSE_NOTE_SUCCESS:
        case ProductDesignerActions.ACCEPT_OFFER_RESPONSE_SUCCESS:
        case ProductDesignerActions.DECLINE_OFFER_RESPONSE_SUCCESS: {
            const offerResponseStateChangeAction = action as AcceptOfferResponseSuccessAction;

            return {
                ...state,
                loadingOfferResponseIssued: false,
                currentProductResponse: {
                    ...(offerResponseStateChangeAction.payload.data ? offerResponseStateChangeAction.payload.data : state.currentProductResponse),
                }
            };
        }

        case ProductDesignerActions.UPDATE_OFFER_RESPONSE_NOTE_FAILURE:
        case ProductDesignerActions.ACCEPT_OFFER_RESPONSE_FAILURE:
        case ProductDesignerActions.DECLINE_OFFER_RESPONSE_FAILURE: {
            const offerResponseStateChangeActionFailure = action as AcceptOfferResponseFailureAction;

            return {
                ...state,
                loadingOfferResponseIssued: false,
                errorOfferResponseIssued: offerResponseStateChangeActionFailure.payload.error,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_PRODUCT_TYPES_SUCCESS: {
            const loadProductDesignerProductTypesSuccessAction = action as LoadProductDesignerProductTypesSuccessAction;

            return {
                ...state,
                productTypes: loadProductDesignerProductTypesSuccessAction.payload.productTypes,
                loadingProductTypes: false,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_PRODUCT_TYPES_FAILURE: {
            const loadProductDesignerProductTypesFailureAction = action as LoadProductDesignerProductTypesFailureAction;

            return {
                ...state,
                loadingProductTypes: false,
                errorLoadingProductTypes: loadProductDesignerProductTypesFailureAction.payload.error,
            };
        }

        case ProductDesignerActions.VALIDATE_PRODUCT_DESIGNER_PRODUCT: {
            return {
                ...state,
                loadingProductValidationResult: true,
            };
        }

        case ProductDesignerActions.VALIDATE_PRODUCT_DESIGNER_PRODUCT_SUCCESS: {
            const loadProductDesignerIssuersSuccessAction = action as ValidateProductDesignerProductSuccessAction;

            return {
                ...state,
                productValidationResult: loadProductDesignerIssuersSuccessAction.payload.validation,
                loadingProductValidationResult: false,
            };
        }

        case ProductDesignerActions.VALIDATE_PRODUCT_DESIGNER_PRODUCT_FAILURE: {
            const existingResult = state.productValidationResult;
            let updatedSpecificErrors = {};

            if (existingResult && existingResult.validProviders) {
                updatedSpecificErrors = {
                    ...existingResult.providerSpecificErrors,
                };

                existingResult.validProviders.forEach((key) => {
                    updatedSpecificErrors[key] = [{
                        'code': 'provider.provider.unsupported',
                        'message': 'Nicht unterstützt',
                        'context': {'provider': key},
                    }];
                });
            }

            return {
                ...state,
                productValidationResult: {
                    validProviders: [],
                    providerSpecificErrors: {
                        ...updatedSpecificErrors,
                    }
                },
                loadingProductValidationResult: false,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_STRUCTURES: {
            return {
                ...state,
                loadingHistoryStructures: true,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_STRUCTURES_SUCCESS: {
            const loadProductDesignerHistoryTableStructureSuccessAction = action as LoadProductDesignerHistoryStructuresSuccessAction;

            return {
                ...state,
                historyTableStructure: loadProductDesignerHistoryTableStructureSuccessAction.payload.structures.table,
                historyFilterStructure: loadProductDesignerHistoryTableStructureSuccessAction.payload.structures.filter.common,
                loadingHistoryStructures: false,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_STRUCTURES_FAILURE: {
            return {
                ...state,
                loadingHistoryStructures: false,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_INITIAL_VALUES_REQUEST: {
            return {
                ...state,
                loadingHistoryInitialValues: true,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_INITIAL_VALUES_SUCCESS: {
            const loadProductDesignerHistoryInitialValuesAction = action as LoadProductDesignerHistoryInitialValuesSuccessAction;

            return {
                ...state,
                historyFilterInitialValues: loadProductDesignerHistoryInitialValuesAction.payload.values,
                loadingHistoryInitialValues: false,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_INITIAL_VALUES_FAILURE: {
            return {
                ...state,
                loadingHistoryInitialValues: true,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_REQUEST_PARAMETERS_VALUES_SUCCESS: {
            const loadProductDesignerRequestParametersValuesAction = action as LoadProductDesignerRequestParameterValuesSuccessAction;

            return {
                ...state,
                requestParameterValues: loadProductDesignerRequestParametersValuesAction.payload.values,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_TABLE_VALUES: {
            return {
                ...state,
                loadingHistoryData: true,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_TABLE_VALUES_SUCCESS: {
            const loadProductDesignerHistoryTableValuesSuccessAction = action as LoadProductDesignerHistoryTableValuesSuccessAction;

            return {
                ...state,
                historyTableValues: loadProductDesignerHistoryTableValuesSuccessAction.payload.values,
                loadingHistoryData: false,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_TABLE_VALUES_FAILURE: {
            return {
                ...state,
                loadingHistoryData: false,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_VALUES: {
            return {
                ...state,
                loadingHistoryFilterValues: true,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_VALUES_SUCCESS: {
            const loadProductDesignerHistoryFilterValuesSuccessAction = action as LoadProductDesignerHistoryFilterValuesSuccessAction;

            return {
                ...state,
                historyFilterValues: loadProductDesignerHistoryFilterValuesSuccessAction.payload.values,
                loadingHistoryFilterValues: false,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_HISTORY_FILTER_VALUES_FAILURE: {
            return {
                ...state,
                loadingHistoryFilterValues: false,
            };
        }

        // ---- Survey -------

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_STRUCTURE_SUCCESS: {
            const loadProductDesignerSurveyTableStructureSuccessAction = action as LoadProductDesignerSurveyTableStructureSuccessAction;

            return {
                ...state,
                surveyTableStructure: loadProductDesignerSurveyTableStructureSuccessAction.payload.structure,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_TABLE_VALUES_SUCCESS: {
            const loadProductDesignerSurveyTableValuesSuccessAction = action as LoadProductDesignerSurveyTableValuesSuccessAction;

            return {
                ...state,
                surveyTableValues: loadProductDesignerSurveyTableValuesSuccessAction.payload.values,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_STRUCTURE_SUCCESS: {
            const loadProductDesignerSurveyFilterStructureSuccessAction = action as LoadProductDesignerSurveyFilterStructureSuccessAction;

            return {
                ...state,
                surveyFilterStructure: loadProductDesignerSurveyFilterStructureSuccessAction.payload.structure,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_FILTER_VALUES_SUCCESS: {
            const loadProductDesignerSurveyFilterValuesSuccessAction = action as LoadProductDesignerSurveyFilterValuesSuccessAction;

            return {
                ...state,
                surveyFilterValues: loadProductDesignerSurveyFilterValuesSuccessAction.payload.values,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_AVAILABLE_USERS_SUCCESS: {
            const loadProductDesignerAvailableUsersSuccessAction = action as LoadProductDesignerAvailableUsersSuccessAction;

            return {
                ...state,
                availableUsers: loadProductDesignerAvailableUsersSuccessAction.payload.users,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_SURVEY_DATA_SUCCESS: {
            const loadProductDesignerSurveyDataSuccessAction = action as LoadProductDesignerSurveyDataSuccessAction;
            const data = loadProductDesignerSurveyDataSuccessAction.payload.data;

            return {
                ...state,
                currentProductData: {
                    ...data.productData,
                },
                currentProductRequest: {
                    ...data.productRequest,
                },

                currentProductOffers: [
                    ...(data.offers ? data.offers : []),
                ],

                currentSurveyData: data,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_ALL_ISSUERS: {
            return {
                ...state,
                loadingAllAvailableIssuers: true,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_ALL_ISSUERS_SUCCESS: {
            const loadProductDesignerAllIssuersSuccessAction = action as LoadProductDesignerAllIssuersSuccessAction;

            return {
                ...state,
                loadingAllAvailableIssuers: false,
                allAvailableIssuers: loadProductDesignerAllIssuersSuccessAction.payload.issuers,
            };
        }

        case ProductDesignerActions.LOAD_PRODUCT_DESIGNER_ALL_ISSUERS_FAILURE: {
            return {
                ...state,
                loadingAllAvailableIssuers: false,
            };
        }

        case ProductDesignerActions.LOAD_MATRIX_PRICING_AVAILABILITIES_REQUEST: {
            return {
                ...state,
                loadingMatrixPricingAvailabilities: true,
                errorLoadingMatrixPricingAvailabilities: null,
            };
        }

        case ProductDesignerActions.LOAD_MATRIX_PRICING_AVAILABILITIES_SUCCESS: {
            const loadMatrixPricingAvailabilitiesSuccessAction = action as LoadMatrixPricingAvailabilitiesSuccessAction;

            return {
                ...state,
                loadingMatrixPricingAvailabilities: false,
                matrixPricingAvailabilities: loadMatrixPricingAvailabilitiesSuccessAction.payload.data,
            };
        }

        case ProductDesignerActions.LOAD_MATRIX_PRICING_AVAILABILITIES_FAILURE: {
            const loadMatrixPricingAvailabilitiesFailureAction = action as LoadMatrixPricingAvailabilitiesFailureAction;

            return {
                ...state,
                loadingMatrixPricingAvailabilities: false,
                errorLoadingMatrixPricingAvailabilities: loadMatrixPricingAvailabilitiesFailureAction.payload.error,
            };
        }

        default:
            return state;
    }
}
