<form [formGroup]="commentsForm">
    <ng-container formArrayName="comments">
        <div
            *ngFor="let line of lines; let i = index"
            class="entity-table-multi-row-comment-cell__line">
            <div class="d-flex">
                <ng-container *ngFor="let field of options.fields; let lastField = last;">
                    <div
                        appEntityTableCell
                        [decorator]="field.decorator"
                        [decoratorOptions]="field.decoratorOptions"
                        [entity]="line"
                        [path]="field.path">
                    </div>
                    <div
                        *ngIf="options.fieldSeparator && !lastField"
                        class="separator">{{ options.fieldSeparator }}</div>
                </ng-container>
            </div>
            <ng-container [formGroupName]="i">
                <textarea
                        class="form-control"
                        [formControlName]="line.dq"></textarea>
            </ng-container>
        </div>
    </ng-container>
</form>
