import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'join',
    standalone: true
})
export class JoinPipe implements PipeTransform {

    public transform(value: string[], separator = ''): string {
        return value.join(separator);
    }

}
