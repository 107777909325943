import { Config, MenuItemConfig } from '../models/config.model';

/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 5/5/17.
 */

export function transformConfig(apiConfig: any): Config {
    return {
        ...apiConfig,
        menu: transformMenuItemConfig(apiConfig.menu),
    };
}

export function transformMenuItemConfig(apiMenuItemConfig: any): MenuItemConfig {
    // passthrough
    return {
        ...apiMenuItemConfig,
        children: (apiMenuItemConfig.children) ? apiMenuItemConfig.children.map(transformMenuItemConfig) : [],
    };
}
