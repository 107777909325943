import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { calculateViewDimensions, LineChartComponent, ChartCommonModule, LineChartModule } from '@swimlane/ngx-charts';
import { DynamicChartYAxisComponent } from './y-axis.component';
import { DynamicChartXAxisComponent } from './x-axis.components';
import { NgIf, NgFor } from '@angular/common';

const cache = {};

/**
 * Generates a short id.
 *
 * Description:
 *   A 4-character alphanumeric sequence (364 = 1.6 million)
 *   This should only be used for JavaScript specific models.
 *   http://stackoverflow.com/questions/6248666/how-to-generate-short-uid-like-ax4j9z-in-js
 *
 *   Example: `ebgf`
 */
export function id() {
    let newId = ('0000' + (Math.random() * Math.pow(36, 4) << 0).toString(36)).slice(-4);
    // append a 'a' because neo gets mad
    newId = "a" + newId;
    // ensure not already used
    if (!cache[newId]) {
        cache[newId] = true;
        return newId;
    }
    return id();
}

@Component({
    selector: 'app-dynamic-chart-line',
    templateUrl: './dynamic-chart-line.component.html',
    styleUrls: ['./dynamic-chart-line.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('animationState', [
            transition(':leave', [
                style({
                    opacity: 1,
                }),
                animate(500, style({
                    opacity: 0,
                })),
            ]),
        ]),
    ],
    standalone: true,
    imports: [ChartCommonModule, NgIf, DynamicChartXAxisComponent, DynamicChartYAxisComponent, NgFor, LineChartModule]
})
export class DynamicChartLineComponent extends LineChartComponent {
    @Input()
    public minimal = false;

    public update() {
        super.update();

        this.dims = calculateViewDimensions({
            width: this.width,
            height: this.height,
            margins: this.margin,
            showXAxis: this.xAxis,
            showYAxis: this.minimal ? false : this.yAxis,
            xAxisHeight: this.xAxisHeight,
            yAxisWidth: this.yAxisWidth,
            showXLabel: this.showXAxisLabel,
            showYLabel: this.showYAxisLabel,
            showLegend: this.legend,
            legendType: this.schemeType,
            legendPosition: this.legendPosition,
        });

        if (this.timeline) {
            this.dims.height -= this.timelineHeight + this.margin[2] + this.timelinePadding;
        }

        this.xDomain = this.getXDomain();
        if (this.filteredDomain) {
            this.xDomain = this.filteredDomain;
        }

        this.yDomain = this.getYDomain();
        this.seriesDomain = this.getSeriesDomain();

        this.xScale = this.getXScale(this.xDomain, this.dims.width);
        this.yScale = this.getYScale(this.yDomain, this.dims.height);

        this.updateTimeline();

        this.setColors();
        this.legendOptions = this.getLegendOptions();

        this.transform = `translate(${this.dims.xOffset} , ${this.margin[0]})`;

        this.clipPathId = 'clip' + id().toString();
        this.clipPath = `url(#${this.clipPathId})`;
    }
}
