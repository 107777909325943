<form [formGroup]="timeForm" class="ml-5">
    <div class="nativeTimePicker" *ngIf="showNativeSelect">
        <input class="form-control" type="time" required formControlName="time">
        <span class="validity"></span>
    </div>

    <div class="fallbackTimePicker" *ngIf="showFallbackSelect">
        <div class="d-flex align-items-center">
          <span>
            <select class="form-control" formControlName="hour">
                <option [value]="option" *ngFor="let option of hourOptions">{{option}}</option>
            </select>
          </span>
            <span class="pl-1">
            <select class="form-control" formControlName="minute">
                 <option [value]="option" *ngFor="let option of minuteOptions">{{option}}</option>
            </select>
          </span>
            <span class="pl-1">
                Uhr
            </span>
        </div>
    </div>
</form>
