/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 01/14/2018.
 */

import { Action } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { UploadFile } from '../../../../core/models/upload.model';

export enum ProductAttachmentsActionTypes {
    PRODUCT_ATTACHMENT_UPLOADED = 'PRODUCT_ATTACHMENTS:PRODUCT_ATTACHMENT_UPLOADED',

    DELETE_PRODUCT_ATTACHMENT_REQUEST = 'PRODUCT_ATTACHMENTS:DELETE_PRODUCT_ATTACHMENT_REQUEST',
    DELETE_PRODUCT_ATTACHMENT_SUCCESS = 'PRODUCT_ATTACHMENTS:DELETE_PRODUCT_ATTACHMENT_SUCCESS',
    DELETE_PRODUCT_ATTACHMENT_FAILURE = 'PRODUCT_ATTACHMENTS:DELETE_PRODUCT_ATTACHMENT_FAILURE',
}

/** ProductAttachmentUploadedAction **/

export interface ProductAttachmentUploadedAction extends Action {
    payload: {
        queueId: string;
        uploadFile: UploadFile;
        response: any;
    };
}

export function productAttachmentUploaded(queueId: string, uploadFile: UploadFile, response: any): ProductAttachmentUploadedAction {
    return {
        type: ProductAttachmentsActionTypes.PRODUCT_ATTACHMENT_UPLOADED,
        payload: {
            queueId,
            uploadFile,
            response,
        },
    };
}

/** DeleteProductAttachmentRequestAction **/

export interface DeleteProductAttachmentRequestAction extends Action {
    payload: {
        sessionId: string;
        dataPath: string;
        productDq: string;
        attachmentId: string
    };
}

export function deleteProductAttachmentRequest(
    sessionId: string,
    dataPath: string,
    productDq: string,
    attachmentId: string,
): DeleteProductAttachmentRequestAction {
    return {
        type: ProductAttachmentsActionTypes.DELETE_PRODUCT_ATTACHMENT_REQUEST,
        payload: {
            sessionId,
            dataPath,
            productDq,
            attachmentId
        }
    };
}

export interface DeleteProductAttachmentSuccessAction extends Action {
    payload: {
        sessionId: string;
        dataPath: string;
        productDq: string;
        attachmentId: string
    };
}

export function deleteProductAttachmentSuccess(
    sessionId: string,
    dataPath: string,
    productDq: string,
    attachmentId: string,
): DeleteProductAttachmentSuccessAction {
    return {
        type: ProductAttachmentsActionTypes.DELETE_PRODUCT_ATTACHMENT_SUCCESS,
        payload: {
            sessionId,
            dataPath,
            productDq,
            attachmentId
        }
    };
}

export interface DeleteProductAttachmentFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function deleteProductAttachmentFailure(error: AppError): DeleteProductAttachmentFailureAction {
    return {
        type: ProductAttachmentsActionTypes.DELETE_PRODUCT_ATTACHMENT_FAILURE,
        payload: {
            error,
        },
    };
}
