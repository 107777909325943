import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DerifinApi } from '../../../../core/api/derifin.api';
import { ApiResponse } from '../../../../core/api/models/api.model';
import { FormValues } from '../../../../core/models/form.model';
import { AuthStoreService } from '../../../../core/services/auth-store.service';
import { ConfigurationService } from '../../../../core/services/configuration.service';
import { LogglyLoggerService } from '../../../../core/services/loggly-logger.service';

/**
 * API abstraction layer for the product decorfin API
 */
@Injectable()
export class ProductDecorfinApi extends DerifinApi {

    constructor(http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, appConfigService: ConfigurationService) {
        super('m/decorfin', appConfigService.configuration.environment.id, http, authStoreService, loggerService, appConfigService);
    }

    public updateProductDecorfinData(
        productDq: string,
        brokerDecorfinId: string,
        data: FormValues,
        brokerDecorfinDataMode: string,
        factsheetSessionId: string
    ): Observable<any> {
        return this.put(`product/${productDq}`, {
            brokerDecorfinId,
            data,
            brokerDecorfinDataMode
        }, {
            params: { factsheetSessionId }
        }).pipe(
            map((response: ApiResponse<any>) => response.data),
        );
    }
}
