import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import { get, isString, merge, set } from 'lodash';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Data } from '../../../../core/models/data.model';
import { FormValues } from '../../../../core/models/form.model';
import { AdditionalWidgetsDataService } from '../../../../core/services/widgets-data.service';
import { AdditionalWidgetDataMap } from '../../../../core/store/state/widgets-data.state';
import { PostLoadingData } from '../../../../routes/dashboard/store/state/dashboard.state';
import { getAdditionalDataPathForWidget, truthy } from '../../../helpers/general.helper';
import { EntityWidgetConfig } from '../../../models/widgets.model';
import { EntityEvent } from '../../../components/entity-table/entity-table.component';
import { WidgetDataActionTypes } from '../../../../core/store/actions/widgets-data.action';
import { EntityWidgetComponent } from '../entity-widget/entity-widget.component';
import { StringsService } from '../../../../core/services/strings.service';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-entity-widget-container',
    templateUrl: './entity-widget-container.component.html',
    styleUrls: ['./entity-widget-container.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, EntityWidgetComponent]
})
export class EntityWidgetContainerComponent implements OnInit, OnDestroy {
    @Input() public inverse = false;
    @Input() public responsive = true;
    @Input() public compact = false;
    @Input() public basePath = '';
    @Input() public entityId: string;
    @Input() public columns = 1;
    @Input() public structure: EntityWidgetConfig[];
    @Input() public data: Data;
    @Input() public values: FormValues;
    @Input() public postLoadingState: PostLoadingData;
    @Input() public postLoadingCount: { [key: string]: number };
    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter<{ type: string, payload: any }>();
    @Output() public componentEvent: EventEmitter<EntityEvent> = new EventEmitter();
    public additionalWidgetsData: AdditionalWidgetDataMap = {};
    public additionalDataSubscription: Subscription;

    @ViewChildren(EntityWidgetComponent)
    public widgetComponents: QueryList<EntityWidgetComponent>;

    constructor(
        private readonly additionalWidgetsDataService: AdditionalWidgetsDataService,
        private readonly cdf: ChangeDetectorRef,
        private readonly stringsService: StringsService,
    ) {
    }

    @HostBinding('class')
    get componentClass() {
        const classes = ['widget-container'];

        if (this.inverse) {
            classes.push('widget-container--inverse');
        }

        if (this.compact) {
            classes.push('widget-container--compact');
        }

        if (this.responsive) {
            classes.push('widget-container--responsive');
        } else {
            classes.push(`widget-container--cols-${this.columns}`);
        }

        return classes.join(' ');
    }

    public ngOnInit() {
        this.additionalDataSubscription = this.additionalWidgetsDataService.getAdditionalWidgetsDataMap()
            .pipe(filter(truthy))
            .subscribe((data) => {
                this.additionalWidgetsData = data;
                this.cdf.detectChanges();
            });

        this.stringsService.loadStringsByPath('widgets');
    }

    public isCompositeWidget(config: EntityWidgetConfig) {
        return config.type === 'combined';
    }

    public getPathForWidget(config: EntityWidgetConfig) {
        return get(config, 'path') || get(config, 'options.path') || get(config, 'options.paths');
    }

    public getAdditionalDataForWidget(widget: EntityWidgetConfig) {
        const path = getAdditionalDataPathForWidget(this.basePath, widget, this.entityId);
        return get(this.additionalWidgetsData, path);
    }

    public forceFormValidation() {
        if (this.widgetComponents) {
            this.widgetComponents.forEach((widget) => {
                widget.forceFormValidation();
            });
        }
    }

    /**
     * Get the data for the corresponding path or the whole data object if path is null
     * @param path
     * @returns {any}
     */
    public getDataForWidget(config: EntityWidgetConfig) {
        const path = this.getPathForWidget(config);

        if (path && !this.isCompositeWidget(config)) {
            if (Array.isArray(path)) {
                let result = [];
                path.forEach((p) => {
                    result = result.concat(get(this.data, p));
                });

                return result;
            } else {
                return get(this.data, path);
            }
        } else {
            return this.data;
        }
    }

    public getDataForWidgetWithLoading(config: EntityWidgetConfig) {
        const isLoading = this.getIsLoadingWidgetData(config);
        const data = this.getDataForWidget(config);

        if ((isLoading || isString(data)) && (config.postLoading || config.options.postLoading)) {
            return null;
        }
        return this.getDataForWidget(config);
    }

    public getTitleForWidget(config: EntityWidgetConfig) {
        const title = get(config, 'options.title');

        if (typeof title === 'string') {
            return title;
        } else {
            const path = get(title, 'path');
            const def = get(title, 'default');

            if (path) {
                return get(this.data, path, def);
            } else {
                return def;
            }
        }
    }

    public getSubTitleForWidget(config: EntityWidgetConfig) {
        const subtitle = get(config, 'options.subTitle');

        if (typeof subtitle === 'string') {
            return subtitle;
        } else {
            const path = get(subtitle, 'path');
            const def = get(subtitle, 'default');

            if (path) {
                return get(this.data, path, def);
            } else {
                return def;
            }
        }
    }

    public getOptionsForWidget(config: EntityWidgetConfig) {
        const options = config.options;
        const existingGraphOptions = get(options, 'options');
        const dataGraphOptionsPath = get(options, 'modifiedOptionsPath');
        const dataGraphOptions = get(this.data, dataGraphOptionsPath);
        const mergedGraphOptions = merge(existingGraphOptions, dataGraphOptions);

        set(mergedGraphOptions, 'xAxisTicks', get(dataGraphOptions, 'xAxisTicks'));

        return set(options, 'options', mergedGraphOptions);
    }

    public getVisibilityForWidget(config: EntityWidgetConfig) {
        const conditionPath = get(config, 'options.conditionPath');
        const conditionValues = get(config, 'options.conditionValues');

        if (conditionPath && conditionValues && Array.isArray(conditionValues)) {
            const dataValue = get(this.data, conditionPath);
            return conditionValues.findIndex((value) => value == dataValue) !== -1;
        }

        return get(
            this.data,
            config.options.visibilityIndicatorPath,
            config.options.defaultVisibility
        );
    }

    /**
     * Send the action as an output
     * @param {{type: string, payload: any}} data
     * @param widget
     */
    public onAction(data: { type: string; payload: any }, widget: EntityWidgetConfig) {
        switch (data.type) {
            case WidgetDataActionTypes.DYNAMIC_CHART_LOAD_GRAPH_DATA:
                data.payload.path = getAdditionalDataPathForWidget(this.basePath, widget, this.entityId);

                this.additionalWidgetsDataService.dynamicChartLoadGraphData(data.payload.path, data.payload.requestData);
                break;

            case WidgetDataActionTypes.TOP_UNDERLYINGS_LOAD_DATA:
                data.payload.path = getAdditionalDataPathForWidget(this.basePath, widget, this.entityId);

                this.additionalWidgetsDataService.topUnderlyingsLoadData(data.payload.path, data.payload.filter);
                break;

            case WidgetDataActionTypes.CHECK_EQUIVALENCE:
                data.payload.path = getAdditionalDataPathForWidget(this.basePath, widget, this.entityId);

                this.additionalWidgetsDataService.checkEquivalence(
                    data.payload.path,
                    this.entityId,
                    data.payload.deriBwIds,
                    data.payload.label,
                    data.payload.id
                );
                break;

            default:
                this.action.next(data);
        }
    }

    public onComponentEvent(event: EntityEvent, widget: EntityWidgetConfig) {
        if (event.event.type === 'form-change' || event.event.type === 'form-validation') {
            this.componentEvent.next({
                ...event,
                id: event.id || this.getPathForWidget(widget),
            });
        } else {
            this.componentEvent.next(event);
        }
    }

    public getInfoTextForWidget(config: EntityWidgetConfig) {
        if (config.options.infoText) {
            return config.options.infoText;
        }

        const infoTextPath = get(config, 'options.infoTextPath');

        if (infoTextPath) {
            return get(this.data, infoTextPath);
        }

        return null;
    }

    public ngOnDestroy(): void {
        if (this.additionalDataSubscription) {
            this.additionalDataSubscription.unsubscribe();
        }
    }

    public getIsLoadingWidgetData(widget: EntityWidgetConfig) {
        const widgetData = this.getDataForWidget(widget);

        if (isString(widgetData)) {
            if (
                this.postLoadingState && this.postLoadingState[widgetData] && this.postLoadingState[widgetData].state &&
                (widget.options.refreshable && this.postLoadingCount && this.postLoadingCount[widget.type] < 1)
            ) {
                return true;
            }
        }

        return false;
    }
}
