<div class="row" *ngIf="!disableProductGrouping">
    <div class="col-12">
        <label class="custom-control custom-switch">
            <input class="custom-control-input" type="checkbox" [(ngModel)]="showGrouped">
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">Liste gruppieren nach Produkt</span>
        </label>
    </div>
</div>

<div class="row entity-widget-focuslist-change__filter">
    <div class="col-12">
        <div class="row">
            <div class="col-12 col-xl-6 entity-widget-focuslist-change__filter__type">
                <div class="app-filter-radio-button-group">
                    <mat-button-toggle-group [multiple]="true" [value]="dataFilter.types" (change)="onCheckboxChange($event)">
                        <mat-button-toggle *ngFor="let value of productTypes" [value]="value" disableRipple="true">
                            {{ productTypeToString[value] }}
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </div>
            </div>
            <div class="col-12 col-xl-6 entity-widget-focuslist-change__filter__search">
                <input class="form-control mr-sm-1 col-12" name="search" placeholder="Filtern nach..." type="text" (ngModelChange)="onInputChange($event)" [ngModel]="dataFilter.search">
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12 entity-widget-focuslist-change__tabs">
        <ng-container *ngIf="(disableProductGrouping || !showGrouped) && (groupedByChangeType$ | async) as groupedData">
            <div class="tab-bar-container nav nav-tabs" *ngIf="(changeTypeTabs$ | async) as changeTypeTabs">
                <div class="nav-item" *ngFor="let groupTab of changeTypeTabs">
                    <a class="nav-link" [class.active]="activeTab === groupTab" href="#" (click)="onChangeTab($event, groupTab)">
                        <ng-container [ngSwitch]="groupTab">
                            <ng-container *ngSwitchCase="'INSERT'">
                                <span class="badge badge-indicator badge-success"></span> Hinzugefügt ({{groupedData[groupTab].length}})
                            </ng-container>

                            <ng-container *ngSwitchCase="'DELETE'">
                                <span class="badge badge-indicator badge-danger"></span> Entfernt ({{groupedData[groupTab].length}})
                            </ng-container>
                        </ng-container>
                    </a>
                </div>
            </div>
        </ng-container>
    </div>
</div>



<div class="entity-widget-focuslist-change__container">
    <ng-container *ngIf="(disableProductGrouping || !showGrouped)">
        <ng-container *ngIf="(groupedByChangeType$ | async) as groupedData">
            <table class="table table-sm table-hover table-striped table-sticky">
                <thead>
                    <tr>
                        <th></th>
                        <th>Datum</th>
                        <th>ISIN</th>
                        <th>Produktname</th>
                        <th>Produkt-Typ</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="groupedData[activeTab]; else: noDataMessage">
                    <tr *ngFor="let change of groupedData[activeTab]">
                        <td>
                            <ng-container [ngSwitch]="change.changeType">
                                <ng-container *ngSwitchCase="'INSERT'">
                                    <span class="badge badge-indicator badge-success"></span>
                                </ng-container>

                                <ng-container *ngSwitchCase="'DELETE'">
                                    <span class="badge badge-indicator badge-danger"></span>
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>{{ change.date | translocoDate: { dateStyle: 'short' } }}</td>
                        <td>
                            <a href="#" [routerLink]="['/app', {'outlets': {'overlay': ['product-details', change.dq]}}]">{{change.isin}}</a>
                        </td>
                        <td>
                            <a href="#" [routerLink]="['/app', {'outlets': {'overlay': ['product-details', change.dq]}}]">{{change.name || '-'}}</a>
                        </td>
                        <td>
                            {{productTypeToString[change.productType]}}
                        </td>
                    </tr>
                    </ng-container>
                    <ng-template #noDataMessage>
                        <tr>
                            <td colspan="5" class="text-center">
                                Keine Daten für den gewählten Filter.
                            </td>
                        </tr>
                    </ng-template>
                </tbody>
            </table>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="(!disableProductGrouping && showGrouped) ">
        <table class="table table-sm table-hover table-striped table-sticky">
            <thead>
            <tr>
                <th>ISIN</th>
                <th>Produktname</th>
                <th>Produkt-Typ</th>
                <th>Letzte Änderung</th>
            </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of (groupedData$ | async)">
                    <tr>
                        <td [attr.rowspan]="item.changes.length + 1">
                            <a href="#" [routerLink]="['/app', {'outlets': {'overlay': ['product-details', item.dq]}}]">{{item.isin}}</a>
                        </td>
                        <td>
                            <a href="#" [routerLink]="['/app', {'outlets': {'overlay': ['product-details', item.dq]}}]">{{item.name || '-'}}</a>
                        </td>
                        <td>
                            {{productTypeToString[item.productType]}}
                        </td>
                        <td>{{ item.date | translocoDate: { dateStyle: 'short' } }}</td>
                    </tr>

                    <tr *ngFor="let change of item.changes">
                        <td colspan="2">
                            <ng-container [ngSwitch]="change.changeType">
                                <ng-container *ngSwitchCase="'INSERT'">
                                    <span class="badge badge-indicator badge-success"></span> Hinzugefügt
                                </ng-container>

                                <ng-container *ngSwitchCase="'DELETE'">
                                    <span class="badge badge-indicator badge-danger"></span> Entfernt
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>{{ change.date | translocoDate: { dateStyle: 'medium', timeStyle: 'medium' } }}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </ng-container>
</div>

