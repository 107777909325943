import {NavigationExtras, UrlTree} from '@angular/router';
import { Action } from '@ngrx/store';

export const GO = '[Router] Go';
export const BACK = '[Router] Back';
export const FORWARD = '[Router] Forward';
export const UPDATE_LOCATION = '[Router] Update Location';

export class Go implements Action {
    public readonly type = GO;

    constructor(public payload: {
        path: any[] | string | UrlTree;
        query?: object;
        extras?: NavigationExtras;
    }) {}
}

export class Back implements Action {
    public readonly type = BACK;
}

export class Forward implements Action {
    public readonly type = FORWARD;
}

export type Actions
    = Go
    | Back
    | Forward;

export class UpdateLocation implements Action {
    public readonly type = UPDATE_LOCATION;
}
