<ng-container *ngIf="(tableConfig$ | async) as config">
    <ng-container *ngIf="config.actions as actions">
        <ng-container *ngIf="actions.length > 0">
            <div class="entity-widget-table-actions">
                <app-entity-actions
                    [genericActionsStructure]="actions"
                    (actionClicked)="onActionClicked($event)"></app-entity-actions>
            </div>
        </ng-container>
    </ng-container>
</ng-container>

<ng-container *ngIf="(sortedTableValues$ | async)?.length else emptyTable">
    <app-entity-table
        [entities]="sortedTableValues$ | async | paginate: { itemsPerPage: (currentOptions$ | async).itemsPerPage, currentPage: currentPage }"
        [config]="tableConfig$ | async"
        [stickyHeader]="(tableConfig$ | async).stickyHeader"
        [stickyOffset]="(tableConfig$ | async).stickyOffset"
        [sort]="currentSort$ | async"
        (sortChange)="onSortChanged($event)"
        [selectedEntities]="selectionModel.selected"
        (toggleSelection)="onToggleSelection($event)"
        (toggleSelections)="onToggleSelections($event)"
        (remove)="onRemove($event)"
        (componentEvent)="onComponentEvent($event)"
        [emptyMessage]="'bwlist.list.no-items' | transloco" />

    <ng-container *ngIf="(currentOptions$ | async).paginated">
        <div class="tw-flex tw-justify-end tw-pt-6">
            <app-custom-pagination-controls
                [init]="true"
                (pageChange)="onPageChanged($event)" />
        </div>
    </ng-container>
</ng-container>

<ng-template #emptyTable>
    <div class="tw-text-center tw-pt-6">{{ (currentOptions$ | async).emptyContentText }}</div>
</ng-template>
