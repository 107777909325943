import { ChangeDetectorRef, NgZone, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { isString } from 'lodash';
import * as moment from 'moment';

const formatDistance = (date: Date | number, locale: string = 'de') => {
    let momentDate = moment(date);

    if (!momentDate.isValid()) {
        momentDate = moment();
    }

    momentDate.locale(locale);
    const differenceDays = momentDate.diff(moment(), 'days');

    if (differenceDays > 7) {
        return momentDate.format('llll');
    }

    return momentDate.fromNow();
};

@Pipe({
    name: 'distanceToNow',
    pure: false,
    standalone: true
})
export class DistanceToNowPipe implements PipeTransform, OnDestroy {
    constructor(
        protected changeDetectorRef: ChangeDetectorRef,
        private ngZone: NgZone,
    ) {
    }

    private timer: number;
    private lastExecute = 0;
    private timeToUpdate = 0;
    private lastResponse: any = '';
    private lastValue: any = null;

    private static getSecondsUntilUpdate(seconds: number) {
        const min = 60;
        const hr = min * 60;
        const day = hr * 24;
        if (seconds < min) { // less than 1 min, update every 2 secs
            return 10;
        } else if (seconds < hr) { // less than an hour, update every 30 secs
            return 30;
        } else if (seconds < day) { // less then a day, update every 5 mins
            return 300;
        } else { // update every hour
            return 3600;
        }
    }

    public transform(value: any, localeInput?: string): any {
        const locale = this.getLocale(localeInput);
        const now = new Date();

        if (
            (!this.lastValue || this.lastValue === value) &&
            this.lastExecute > 0 && now.getTime() < (this.lastExecute + this.timeToUpdate)
        ) {
            return this.lastResponse;
        }

        this.removeTimer();

        let date = value;
        if (isString(value)) {
            try {
                date = new Date(value);
            } catch (e) {
            }
        }

        if (!(date instanceof Date) || isNaN(+date) || !date) {
            date = new Date();
        }

        this.lastExecute = now.getTime();
        this.lastValue = value;

        const seconds = Math.round(Math.abs((now.getTime() - date.getTime()) / 1000));
        this.timeToUpdate = (Number.isNaN(seconds)) ? 1000 : DistanceToNowPipe.getSecondsUntilUpdate(seconds) * 1000;
        this.timer = this.ngZone.runOutsideAngular(() => {
            if (typeof window !== 'undefined') {
                return window.setTimeout(() => {
                    this.ngZone.run(() => this.changeDetectorRef.markForCheck());
                }, this.timeToUpdate);
            }
            return null;
        });

        this.lastResponse = formatDistance(date, locale);
        return this.lastResponse;
    }

    protected getLocale(locale: undefined | string): string {
        if (isString(locale)) {
            return locale;
        }

        return 'de';
    }

    public ngOnDestroy(): void {
        this.removeTimer();
        this.lastExecute = 0;
        this.timeToUpdate = 0;
        this.lastValue = null;
    }

    private removeTimer() {
        if (this.timer) {
            window.clearTimeout(this.timer);
            this.timer = null;
        }
    }

}
