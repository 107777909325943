import * as moment from 'moment';
import { isMomentHoliday } from '../../core/helpers/holidays.helper';
import { set } from 'lodash';

export type ParsedMonthValue = {
    type: 'RELATIVE' | 'ABSOLUTE',
    value?: number,
    year?: number,
    month?: number
};

/*
    Valid month formats:  ->  A_2_2021 => Month = 2 | Year = 2021
                          ->  A_4      => Month = 4
                          ->  R_3      => [Relative Date Month] + 3
 */
export function parseMonthValue(input: string): ParsedMonthValue {
    if (!input || input === 'CUSTOM') return null;

    const inputParts = input.split('_');

    if (Array.isArray(inputParts) && inputParts.length > 0) {
        const type = inputParts[0];

        if (type === 'R' && inputParts.length === 2) {
            const value = parseInt(inputParts[1], 10);

            if (isNaN(value)) return null;

            return {
                type: 'RELATIVE',
                value
            };
        } else if (type === 'A' && inputParts.length >= 2) {
            const month = parseInt(inputParts[1], 10);

            if (isNaN(month)) return null;

            const result: ParsedMonthValue = {
                type: 'ABSOLUTE',
                month: month - 1,
            };

            if (inputParts.length === 3) {
                const year = parseInt(inputParts[2], 10);

                if (!isNaN(year)) {
                    result['year'] = year;
                }
            }

            return result;
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export function calculateRealDate(relativeDate: any, monthRelative: string) {
    const monthValue = parseMonthValue(monthRelative);

    if (monthValue !== null) {
        const newDate = moment(relativeDate);

        if (monthValue.type === 'RELATIVE') {
            newDate.add(+monthValue.value, 'months');
        } else {
            newDate.month(+monthValue.month);

            if(monthValue.year) {
                newDate.year(+monthValue.year);
            }
        }

        while (isMomentHoliday(newDate)) {
            newDate.add(1, 'd');
        }

        while (newDate.day() === 0 || newDate.day() === 6 || isMomentHoliday(newDate)) {
            newDate.subtract(1, 'd');
        }

        return newDate;
    }

    return null;
}

export function isDate(value) {
    return moment(value, moment.ISO_8601, true).isValid();
}
