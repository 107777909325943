import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FundsCompareState } from "../state/funds-compare.state";

export const selectFundsCompare = createFeatureSelector<FundsCompareState>('fundsCompare');

export const selectChartFilter = createSelector(
    selectFundsCompare,
    (state: FundsCompareState) => state.chart.structure.filter
);

export const selectChartInitalTimeFrame = createSelector(
    selectFundsCompare,
    (state: FundsCompareState) => state.chart.structure.filter?.category.timeFrame
);

export const selectChartTimeFrameOptions = createSelector(
    selectFundsCompare,
    (state: FundsCompareState) => state.chart.structure.timeFrameOptions
);

export const selectChartData = createSelector(
    selectFundsCompare,
    (state: FundsCompareState) => state.chart.data
);

export const selectChartOptions = createSelector(
    selectFundsCompare,
    (state: FundsCompareState) => state.chart.options
);

export const selectChartLoading = createSelector(
    selectFundsCompare,
    (state: FundsCompareState) => state.chart.loading
);

export const selectTableStructure = createSelector(
    selectFundsCompare,
    (state: FundsCompareState) => state.table.structure
);

export const selectTableProducts = createSelector(
    selectFundsCompare,
    (state: FundsCompareState) => state.table.products
);

export const selectDataSuppliers =  createSelector(
    selectFundsCompare,
    (state: FundsCompareState) => state.dataSuppliers
);
