import { Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { Subscription } from 'rxjs';
import { FormControlConfig, FormControlSelectOption, FormValues } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';
import { NumberInputComponent } from '../number-input/number-input.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-filter-fund-figure-input',
    templateUrl: './filter-fund-figure-input.component.html',
    styleUrls: ['./filter-fund-figure-input.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, MatTooltipModule, MatButtonToggleModule, NgFor, NumberInputComponent]
})
export class FilterFundFigureInputComponent implements OnInit, OnChanges, OnDestroy, SingleValueFilterControl<any> {

    @HostBinding('class.double-dropdown-input') public doubleDropdownInputClass = true;

    @Input() public config: FormControlConfig<FilterFundFigureInputOptions>;

    @Input() public values: any;

    @Input() public disabled = false;

    public onChange: (value: any) => void;

    public onTouched: () => void;

    public form: UntypedFormGroup;

    public valueChangesSubscription: Subscription;

    public paths = FilterFundFigureKey;

    get operatorValues(): FormControlSelectOption[] {
        return (this.values && this.values[FilterFundFigureKey.OPERATOR]) || [];
    }

    get horizonValues(): FormControlSelectOption[] {
        return (this.values && this.values[FilterFundFigureKey.HORIZON]) || [];
    }

    get currencyValues(): FormControlSelectOption[] {
        return (this.values && this.values[FilterFundFigureKey.CURRENCY]) || [];
    }

    constructor(private fb: UntypedFormBuilder) {
    }

    public ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.config && this.config) {
            this._updateForm();
        }
        if ((changes.config || changes.values) && this.values) {
            this._updateDefaultValues();
        }
    }

    public ngOnDestroy() {
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }
    }

    public writeValue(values: FormValues): void {
        if (values) {
            this.form.patchValue(values, {emitEvent: false});
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = (value) => {
            if (fn) {
                if (this.form.valid) {
                    fn(value);
                } else {
                    fn(undefined); // @INFO(alex): we don't want this filter input data to appear in our
                                   //              filter object later when the form is not valid.
                                   //              !! This is experimental, let's monitor it. !!
                }
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * Checks if the filter is valid
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this.form.valid) ? null : {
            doubleDropdownInputError: 'Invalid filter state specified',
        };
    }

    public setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    private _updateForm() {
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }
        const fields = {
            [FilterFundFigureKey.OPERATOR]: [null, Validators.required],
            [FilterFundFigureKey.INPUT]: [null, Validators.required],
            [FilterFundFigureKey.HORIZON]: [null, Validators.required],
            [FilterFundFigureKey.CURRENCY]: [null, Validators.required],
        };

        const oldValue = (this.form) ? this.form.value : undefined;
        this.form = this.fb.group(fields);
        if (oldValue) {
            this.form.patchValue(oldValue);
        }
        this.valueChangesSubscription = this.form.valueChanges.subscribe((value) => this.onChange(value));
    }

    private _updateDefaultValues() {
        if (this.config && this.config.options) {
            const defaultFormValues = {};

            const horizonControl = this.form.get(FilterFundFigureKey.HORIZON);
            if (horizonControl.value === undefined || horizonControl.value === null) {
                defaultFormValues[FilterFundFigureKey.HORIZON] = this.horizonValues[0].id;
            }

            const currencyControl = this.form.get(FilterFundFigureKey.CURRENCY);
            if (currencyControl.value === undefined || currencyControl.value === null) {
                defaultFormValues[FilterFundFigureKey.CURRENCY] = this.currencyValues[0].id;
            }

            const operatorControl = this.form.get(FilterFundFigureKey.OPERATOR);
            if ((operatorControl.value === undefined || operatorControl.value === null) && this.config.options.defaultOperator) {
                defaultFormValues[FilterFundFigureKey.OPERATOR] = this.config.options.defaultOperator;
            }

            this.form.patchValue(defaultFormValues, {emitEvent: false});
        }

    }
}

enum FilterFundFigureKey {
    INPUT = 'input',
    HORIZON = 'horizon',
    OPERATOR = 'operator',
    CURRENCY = 'currency',
}

export interface FilterFundFigureInputOptions {
    label: string;
    helpText?: string;
    unit?: string;
    sampleInput: string;
    defaultOperator?: string;
}
