import { createReducer, on } from '@ngrx/store';
import * as FundsCompareActions from '../actions/funds-compare.actions';
import { FundsCompareState } from '../state/funds-compare.state';

export const initialState: FundsCompareState = {
    chart: {
        structure: {
            timeFrameOptions: []
        },
        loading: false
    },
    table: {
        structure: {
            columns: []
        },
        products: []
    },
    dataSuppliers: []
};

export const reducer = createReducer(initialState,
    on(FundsCompareActions.loadProducts, (state, { dqs }): FundsCompareState =>
        ({ ...state,
            chart: { ...state.chart, loading: true },
            table: { ...state.table,
                products: [
                    ...state.table.products.filter((product) => dqs.includes(product.dq)),
                    ...dqs.filter((dq) =>
                        !state.table.products
                            .map((product) => product.dq)
                            .includes(dq)).map((dq) => ({ dq, loading: true })
                    )
                ]  
            }
        })
    ),
    on(FundsCompareActions.setStructure, (state, { dqs, structure }): FundsCompareState =>
        ({ ...state,
            chart: {
                ...state.chart,
                structure: {
                    filter: {
                        ...structure.chart.initialFilter,
                        context: {
                            ...structure.chart.initialFilter.context,
                            entityIds: dqs
                        }
                    },
                    timeFrameOptions: structure.chart.timeFrameValues
                },
                loading: true
            },
            table: { ...state.table, structure: structure.table },
            dataSuppliers: structure.dataSuppliers
        })
    ),
    on(FundsCompareActions.setProducts, (state, { products }): FundsCompareState =>
        ({ ...state,
            chart: {
                ...state.chart,
                structure: {
                    ...state.chart.structure,
                    filter: {
                        ...state.chart.structure.filter,
                        context: {
                            ...state.chart.structure.filter.context,
                            entityIds: products.map((product) => product.dq)
                        }
                    }
                }
            },
            table: { ...state.table,
                products: products.map((product) => ({ ...product, loading: false })) 
            }
        })
    ),
    on(FundsCompareActions.setChart, (state, { data, options }): FundsCompareState =>
        ({ ...state, chart: { ...state.chart,data, options, loading: false } })
    ),
    on(FundsCompareActions.updateChartTimeFrame, (state, { timeFrame }): FundsCompareState =>
        ({ ...state,
            chart: {
                ...state.chart,
                structure: {
                    ...state.chart.structure,
                    filter: {
                        ...state.chart.structure.filter,
                        category: {
                            ...state.chart.structure.filter.category,
                            timeFrame
                        }
                    }
                },
                loading: true
            }
        })
    )
);
