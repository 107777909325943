import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { filter, map, switchMap } from "rxjs/operators";
import { ProductsApi } from "../../../routes/products/api/products.api";
import { truthy } from "../../../shared/helpers/general.helper";
import { SearchApi } from "../../api/search.api";
import * as SearchActions from "../actions/search.actions";
import { ActionTypes } from "../actions/ui.action";
import * as SearchSelectors from "../selectors/search.selectors";

@Injectable()
export class SearchEffects {

    constructor(
        private actions$: Actions,
        private store: Store,
        private searchApi: SearchApi,
        private productsApi: ProductsApi
    ) { }

    public loadSolrResults$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SearchActions.loadSolrResults),
            switchMap((action) => 
                this.searchApi.getSolrResults(action.term).pipe(
                    map((results) => 
                        SearchActions.prepareSolrResults({ results })
                    )
                )
            )
        );
    });

    public loadSolrFundResults$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SearchActions.loadSolrFundResults),
            concatLatestFrom(() => this.store.select(SearchSelectors.selectTerm)),
            filter(([, term]) => truthy(term)),
            switchMap(([action, term]) => 
                this.searchApi.getSolrFundResults(term).pipe(
                    map((results) => 
                        SearchActions.prepareSolrFundResults({
                            results, dqs: action.dqs
                        })
                    )
                )
            )
        );
    });

    public resetSearch$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                ActionTypes.SET_OVERLAY_OPEN,
                ActionTypes.SET_OVERLAY_CLOSED
            ),
            map(() => SearchActions.resetSearch())
        )
    });

    public notifyOnProductActivation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SearchActions.notifyOnProductActivation),
            concatLatestFrom(() => this.store.select(SearchSelectors.selectTerm)),
            switchMap(([, term]) =>
                this.productsApi.notifyOnProductActivation(term).pipe(
                    map(() =>
                        SearchActions.notifyOnProductActivationRequested()
                    )
                )
            )
        );
    });
}
