import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { CdkOverlayOrigin, CdkConnectedOverlay } from '@angular/cdk/overlay';
import { Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, filter, take } from 'rxjs/operators';
import { Shortcuts, ShortcutType } from '../../api/models/user.model';
import { GroupedResults, InstrumentType, NotifyOnProductActivationState } from '../../models/search.model';
import * as SearchActions from '../../store/actions/search.actions';
import * as SearchSelectors from '../../store/selectors/search.selectors';
import { SearchResultComponent } from './search-result/search-result.component';
import { TranslocoModule } from '@ngneat/transloco';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { NgClass, NgIf, NgFor, NgSwitch, NgSwitchCase, AsyncPipe, KeyValuePipe } from '@angular/common';

export type ActionType = 'link' | 'select';

const MIN_SEARCH_TERM_LENGTH = 2;

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CdkOverlayOrigin, NgClass, FormsModule, ReactiveFormsModule, NgIf, SpinnerComponent, CdkConnectedOverlay, NgFor, NgSwitch, NgSwitchCase, SearchResultComponent, AsyncPipe, KeyValuePipe, TranslocoModule]
})
export class SearchComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public showGroupName = false;
  @Input() public actionType: ActionType = 'link';
  @Input() public displayShortcuts = false;
  @Input() public products: Array<GroupedResults> = [];
  @Input() public shortcuts: Shortcuts = {};

  @Output() public termSearch = new EventEmitter<string>();
  @Output() public selectId = new EventEmitter<string>();

  @ViewChild('search') searchElement: ElementRef;

  public subscriptions: Array<Subscription> = [];

  public searching$: Observable<boolean>;
  public overlayOpen$: Observable<boolean>;
  public notifyOnProductActivation$: Observable<NotifyOnProductActivationState>;
  public notifyOnProductActivation = NotifyOnProductActivationState;
  public triggerOrigin: CdkOverlayOrigin;

  public searchControl = new FormControl('');
  public instrumentType = InstrumentType;
  public shortcutType = ShortcutType;

  @ViewChildren(SearchResultComponent)
  private searchResults: QueryList<SearchResultComponent>;
  private keyManager: ActiveDescendantKeyManager<SearchResultComponent>;

  constructor(
    private store: Store,
    private router: Router,
    private ngZone: NgZone
  ) { }

  public ngOnInit(): void {
    this.searching$ = this.store.select(SearchSelectors.selectSearching);
    this.overlayOpen$ = this.store.select(SearchSelectors.selectOverlayOpen);
    this.notifyOnProductActivation$ = this.store.select(SearchSelectors.selectNotifyOnProductActivation);

    this.subscriptions.push(
      this.searchControl.valueChanges.pipe(
        filter((term) => this.isTermLongEnough(term)),
        debounceTime(300)
      ).subscribe((term) =>
        this.termSearch.next(term)
      )
    );

    this.subscriptions.push(
      this.searchControl.valueChanges.pipe(
        filter((term) => !this.isTermLongEnough(term)),
        debounceTime(300)
      ).subscribe(() =>
        this.closeOverlay()
      )
    );

    this.subscriptions.push(
      this.router.events.pipe(
        filter((event) => event instanceof NavigationStart)
      ).subscribe(() => {
        this.searchControl.reset('');
        if (this.actionType === 'link') {
          this.triggerOrigin = undefined;
        }
      })
    );
  }

  public ngOnChanges(): void {
    this.searchResults?.changes.pipe(
      filter((changes) => changes.length > 0),
      take(1)
    ).subscribe((changes: QueryList<SearchResultComponent>) => {
      this.keyManager = new ActiveDescendantKeyManager(changes).withWrap();
      this.ngZone.onMicrotaskEmpty.pipe(take(1)).subscribe(() =>
        this.keyManager.setFirstItemActive()
      );
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) =>
      subscription.unsubscribe()
    );
  }

  public isTermLongEnough(term: string): boolean {
    return term.length > MIN_SEARCH_TERM_LENGTH;
  }

  public onInputClick(trigger: CdkOverlayOrigin): void {
    this.triggerOrigin = trigger;

    if (this.isTermLongEnough(this.searchControl.value)) {
      this.openOverlay();
    }
  }

  public openOverlay(): void {
    this.store.dispatch(SearchActions.openOverlay());
  }

  public closeOverlay(): void {
    this.store.dispatch(SearchActions.closeOverlay());
  }

  public onOverlayKeydown(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      this.closeOverlay();
    } else if (this.searchResults.length > 0) {
      if (['ArrowUp', 'ArrowDown'].includes(event.code)) {
        this.keyManager.onKeydown(event);
        this.keyManager.activeItem.elementRef.nativeElement.scrollIntoView({
          behavior: 'smooth'
        });
      } else if (event.key === 'Enter' && this.keyManager.activeItem) {
        switch(this.actionType) {
          case 'link': {
            this.router.navigate(this.keyManager.activeItem.link);
            break;
          }
          case 'select': {
            this.selectId.next(this.keyManager.activeItem.id);
            break;
          }
        }
      }
    }
  }

  public onSelectId(id: string): void {
    this.selectId.next(id);
  }

  public onNotifyOnProductActivationClick(): void {
    this.store.dispatch(SearchActions.notifyOnProductActivation());
  }

  @HostListener('document:keydown', ['$event'])
    public onKeyDown(event: KeyboardEvent): void {
      const ctrlKeys = [event.ctrlKey, event.metaKey];

      if (ctrlKeys.includes(true) && event.code === 'KeyK') {
        event.preventDefault();
        this.searchElement.nativeElement.focus();
      }
    }
}
