import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Entity, EntityTableColumn } from '../../models/entities.model';
import { EntityHighlightComponent } from '../entity-highlight/entity-highlight.component';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-entity-highlights',
    templateUrl: './entity-highlights.component.html',
    styleUrls: ['./entity-highlights.component.scss'],
    standalone: true,
    imports: [NgFor, EntityHighlightComponent]
})
export class EntityHighlightsComponent implements OnInit {

    @HostBinding('class.entity-highlights') public entityHighlightsClass = true;

    @Input() public fields: EntityTableColumn[];
    @Input() public entity: Entity;

    constructor() {
    }

    public ngOnInit() {
    }

}
