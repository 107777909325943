import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ExtendedDynamicChartDataResponse } from '../../../models/dynamic-chart.model';
import { EntityWidgetAdditionalDataChannel, MarketDataOptions } from '../../../models/widgets.model';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { NgIf, NgFor, NgClass, NgStyle } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MarketDataItem, MarketDataItemInstrument } from './entity-widget-market-data.type';

@Component({
    selector: 'app-entity-widget-market-data',
    templateUrl: './entity-widget-market-data.component.html',
    styleUrls: ['./entity-widget-market-data.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgClass, NgStyle, MatButtonToggleModule, TranslocoLocaleModule, RouterModule]
})
export class EntityWidgetMarketDataComponent implements OnInit, OnChanges, EntityWidgetContentComponent {

    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public options: MarketDataOptions;
    @Input() public data: Array<MarketDataItem> = [];
    @Input() public additionalData: EntityWidgetAdditionalDataChannel<ExtendedDynamicChartDataResponse>;

    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();

    public tranformedData: Array<Array<Array<MarketDataItemInstrument>>> = [];

    constructor() { }

    public ngOnInit() { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && this.data?.length) {
            const numberOfColumns = Math.min(4, this.options.numberOfColumns);

            this.tranformedData = this.data.map((marketDataItem) => {
                const numberOfRows = Math.ceil(marketDataItem.instruments.length / numberOfColumns);
            
                return Array.from({ length: numberOfRows }, (_, rowIndex) =>
                    Array.from({ length: numberOfColumns }, (_, columnIndex) =>
                        marketDataItem.instruments[rowIndex + columnIndex * numberOfRows]
                    ).filter(Boolean)
                );
          });
        }
    }

    public columnWidth(): { [klass: string]: any; } {
        const numberOfColumns = Math.min(4, this.options.numberOfColumns);

        return {'width': `${100 / numberOfColumns / 2}%`};
    }
}
