<div *ngIf="visibleInput else: textOnly" class="custom-controls-stacked">
    <label *ngFor="let value of values" class="custom-control custom-radio">
        <input
            [name]="config.path" type="radio" class="custom-control-input"
            [checked]="value.id === _value" [disabled]="disabled" (change)="onRadioChange($event, value)">
        <span class="custom-control-indicator"></span>
        <span class="custom-control-description">{{value.label}}</span>
    </label>
</div>

<ng-template #textOnly>
    <ng-container *ngIf="_value">
        <ng-container *ngFor="let value of values">
            <span *ngIf="value.id === _value">{{value.label}}</span>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!_value || _value === 'NONE'">
        <span>&mdash;</span>
    </ng-container>
</ng-template>
