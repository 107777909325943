import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonNeutralComponent } from 'chroma-ui';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[app-new-version-toast]',
    templateUrl: './new-version-toast.component.html',
    styleUrls: ['./new-version-toast.component.scss'],
    standalone: true,
    imports: [TranslocoModule, ButtonNeutralComponent]
})
export class NewVersionToastComponent extends Toast {

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage
  ) {
    super(toastrService, toastPackage);
  }

  action(): void {
    this.toastPackage.triggerAction();
  }

}
