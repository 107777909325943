import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    HostBinding,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { uniq } from 'lodash';
import { Subscription } from 'rxjs';
import { ComponentRegistries, getInjectionTokenForComponentType } from '../../helpers/dynamic-component.helper';
import { truthy } from '../../helpers/general.helper';
import { Entity } from '../../models/entities.model';

/**
 * Component that is used with the `component` decorator options to inject a custom component into the table cell
 */
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[appEntityTableComponentCell]',
    templateUrl: './entity-table-component-cell.component.html',
    styleUrls: ['./entity-table-component-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class EntityTableComponentCellComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() public component: string;
    @Input() public componentOptions: any;
    @Input() public decoratorOptions: any;
    @Input() public entity: Entity;
    @Input() public path: string;
    @Input() public multiIndex = 0;
    @Output() public event: EventEmitter<any> = new EventEmitter();

    @ViewChild('componentContainer', {read: ViewContainerRef, static: true}) public componentContainer: ViewContainerRef;

    private isViewInitialized = false;
    private componentRef: ComponentRef<EntityTableCellComponent>;
    private componentEventSubscription: Subscription;

    @HostBinding('class')
    get classNames() {
        const styleHintClasses = (this.decoratorOptions && this.decoratorOptions.styleHints)
            ? this.decoratorOptions.styleHints.join(' ')
            : '';

        return uniq([
            'entity-table-cell',
            'entity-table-cell--component',
            ...styleHintClasses,
        ].filter(truthy)).join(' ');
    }

    constructor(private injector: Injector, private componentFactoryResolver: ComponentFactoryResolver) {

    }

    public ngOnInit() {
    }

    public ngAfterViewInit() {
        this.isViewInitialized = true;
        this._updateComponent({});
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.component) {
            this._updateComponent(changes, true);
        } else {
            this._updateComponent(changes);
        }
    }

    /**
     * Update the injected component
     * @param {SimpleChanges} changes
     * @param {boolean} recreate
     * @private
     */
    private _updateComponent(changes: SimpleChanges, recreate = false) {
        let compChanges: SimpleChanges = {};
        if (!this.componentRef || recreate) {
            if (this.componentRef && recreate) {
                this.componentRef.destroy();
            }

            const injectionToken = getInjectionTokenForComponentType<EntityTableCellComponent>(
                ComponentRegistries.ENTITY_TABLE_CELL,
                this.component,
            );

            if (injectionToken) {
                const type = this.injector.get(injectionToken);
                const factory = this.componentFactoryResolver.resolveComponentFactory(type);
                this.componentRef = this.componentContainer.createComponent(factory);

                compChanges = {
                    product: new SimpleChange(null, this.entity, true),
                    path: new SimpleChange(null, this.path, true),
                    options: new SimpleChange(null, this.componentOptions, true),
                    decoratorOptions: new SimpleChange(null, this.decoratorOptions, true),
                    multiIndex: new SimpleChange(null, this.multiIndex, true),
                };
            }
        } else {
            if (changes.product) {
                compChanges.product = changes.product;
            }
            if (changes.path) {
                compChanges.path = changes.path;
            }
            if (changes.componentOptions) {
                compChanges.options = changes.componentOptions;
            }
            if (changes.decoratorOptions) {
                compChanges.options = changes.decoratorOptions;
            }
            if (changes.multiIndex) {
                compChanges.multiIndex = changes.multiIndex;
            }
        }

        if (this.componentRef && this.componentRef.instance) {

            this.componentRef.instance.product = this.entity;
            this.componentRef.instance.path = this.path;
            this.componentRef.instance.options = this.componentOptions;
            this.componentRef.instance.multiIndex = this.multiIndex;
            this.componentRef.instance.decoratorOptions = this.decoratorOptions;

            if (this.componentEventSubscription) {
                this.componentEventSubscription.unsubscribe();
            }
            if (this.componentRef.instance.event) {
                this.componentEventSubscription = this.componentRef.instance.event.subscribe(this.event);
            }

            this.componentRef.instance.ngOnChanges(compChanges);
        }
    }

}

export interface EntityTableCellComponent extends OnChanges {
    product: Entity;
    options: any;
    decoratorOptions?: any;
    path: string | string[];
    event?: EventEmitter<any>;
    multiIndex?: number;
}
