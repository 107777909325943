import { Action } from '@ngrx/store';
import {
    CheckEquivalenceFailureAction,
    CheckEquivalenceRequestAction, CheckEquivalenceSuccessAction,
    DynamicChartLoadGraphDataFailureAction,
    DynamicChartLoadGraphDataRequestAction,
    DynamicChartLoadGraphDataSuccessAction,
    TopUnderlyingsLoadDataFailureAction,
    TopUnderlyingsLoadDataRequestAction,
    TopUnderlyingsLoadDataSuccessAction,
    UpdateWidgetsAdditionalDataAction,
    WidgetDataActionTypes,
} from '../actions/widgets-data.action';
import { AdditionalWidgetDataState } from '../state/widgets-data.state';

import { merge } from 'lodash';

export const initialState: AdditionalWidgetDataState = {
    data: {},
};

export function reducer(state: AdditionalWidgetDataState = initialState, action: Action): AdditionalWidgetDataState {
    switch (action.type) {
        case WidgetDataActionTypes.DYNAMIC_CHART_LOAD_GRAPH_DATA_REQUEST: {
            const dynamicChartLoadGraphDataRequestAction = action as DynamicChartLoadGraphDataRequestAction;

            return merge({}, state, {
                data: {
                    [dynamicChartLoadGraphDataRequestAction.payload.path]: {
                        loading: true,
                        error: null,
                    },
                },
            });
        }

        case WidgetDataActionTypes.DYNAMIC_CHART_LOAD_GRAPH_DATA_SUCCESS: {
            const dynamicChartLoadGraphDataSuccessAction = action as DynamicChartLoadGraphDataSuccessAction;

            return {
                ...state,
                data: {
                    ...state.data,
                    [dynamicChartLoadGraphDataSuccessAction.payload.path]: {
                        data: dynamicChartLoadGraphDataSuccessAction.payload.data,
                        error: null,
                        loading: false,
                    },
                },
            };
        }

        case WidgetDataActionTypes.DYNAMIC_CHART_LOAD_GRAPH_DATA_FAILURE: {
            const dynamicChartLoadGraphDataFailureAction = action as DynamicChartLoadGraphDataFailureAction;

            return merge({}, state, {
                data: {
                    [dynamicChartLoadGraphDataFailureAction.payload.path]: {
                        error: dynamicChartLoadGraphDataFailureAction.payload.error,
                        loading: false,
                    },
                },
            });
        }

        case WidgetDataActionTypes.TOP_UNDERLYINGS_LOAD_DATA_REQUEST: {
            const topUnderlyingsLoadDataRequestAction = action as TopUnderlyingsLoadDataRequestAction;

            return merge({}, state, {
                data: {
                    [topUnderlyingsLoadDataRequestAction.payload.path]: {
                        loading: true,
                        error: null,
                    },
                },
            });
        }

        case WidgetDataActionTypes.TOP_UNDERLYINGS_LOAD_DATA_SUCCESS: {
            const topUnderlyingsLoadDataSuccessAction = action as TopUnderlyingsLoadDataSuccessAction;

            return {
                ...state,
                data: {
                    ...state.data,
                    [topUnderlyingsLoadDataSuccessAction.payload.path]: {
                        data: topUnderlyingsLoadDataSuccessAction.payload.data,
                        error: null,
                        loading: false,
                    },
                },
            };
        }

        case WidgetDataActionTypes.TOP_UNDERLYINGS_LOAD_DATA_FAILURE: {
            const topUnderlyingsLoadDataFailureAction = action as TopUnderlyingsLoadDataFailureAction;

            return merge({}, state, {
                data: {
                    [topUnderlyingsLoadDataFailureAction.payload.path]: {
                        error: topUnderlyingsLoadDataFailureAction.payload.error,
                        loading: false,
                    },
                },
            });
        }

        case WidgetDataActionTypes.CHECK_EQUIVALENCE_REQUEST: {
            const checkEquivalenceRequestAction = action as CheckEquivalenceRequestAction;

            return merge({}, state, {
                data: {
                    [checkEquivalenceRequestAction.payload.path]: {
                        loading: true,
                        error: null,
                    },
                },
            });
        }

        case WidgetDataActionTypes.CHECK_EQUIVALENCE_SUCCESS: {
            const checkEquivalenceSuccessAction = action as CheckEquivalenceSuccessAction;

            return {
                ...state,
                data: {
                    ...state.data,
                    [checkEquivalenceSuccessAction.payload.path]: {
                        data: checkEquivalenceSuccessAction.payload.data,
                        error: null,
                        loading: false,
                    },
                },
            };
        }

        case WidgetDataActionTypes.CHECK_EQUIVALENCE_FAILURE: {
            const checkEquivalenceFailureAction = action as CheckEquivalenceFailureAction;

            return merge({}, state, {
                data: {
                    [checkEquivalenceFailureAction.payload.path]: {
                        error: checkEquivalenceFailureAction.payload.error,
                        loading: false,
                    },
                },
            });
        }

        case WidgetDataActionTypes.UPDATE_WIDGETS_ADDITIONAL_DATA:
            const updateWidgetsAdditionalDataAction = action as UpdateWidgetsAdditionalDataAction;

            return {
                ...state,
                data: {
                    ...state.data,
                    [updateWidgetsAdditionalDataAction.payload.path]: updateWidgetsAdditionalDataAction.payload.data,
                },
            };
        default:
            return state;
    }
}
