import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, ValidationErrors, FormsModule } from '@angular/forms';
import * as moment from 'moment';
import { FormControlConfig, FormControlSelectOption } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';
import { TranslocoModule } from '@ngneat/transloco';
import { DateInputComponent } from '../date-input/date-input.component';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-filter-maturity-date',
    templateUrl: './filter-maturity-date.component.html',
    styleUrls: ['./filter-maturity-date.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor, DateInputComponent, TranslocoModule]
})
export class FilterMaturityDateComponent implements OnInit, SingleValueFilterControl<string>, OnChanges {

    @Input() public config: FormControlConfig;

    @Input() public values: MaturityDateValues;

    @Input() public disabled = false;

    public currentValue: string = null;

    public currentQuickSelectValue: string = null;

    public onChange: () => void;

    public onTouched: () => void;

    @ViewChild('dateInput', { static: true }) public dateInput: AbstractControl;

    constructor() {
    }

    public ngOnInit() {
    }

    public writeValue(value: string): void {
        this.currentValue = (this._isValidValue(value)) ? value : null;
    }

    public updateValue(value: string) {
        const oldValue = this.currentValue;
        this.currentValue = (this._isValidValue(value)) ? value : null;
        if (this.currentValue !== oldValue) {
            if (this.onChange) { this.onChange(); }
        }
    }

    protected _isValidValue(value: string) {
        const date = moment(value);
        return date.isValid();
    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this.currentValue);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return ((this.dateInput.disabled || this.dateInput.valid) && this._isValidValue(this.currentValue)) ? null : {
            maturityDateSelector: 'Invalid value specified',
        };
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.values) {
            this.updateValue(this.currentValue);
        }
    }

    public onQuickSelectChange(value: string) {
        this.currentQuickSelectValue = value;
        this.currentValue = (this._isValidValue(value)) ? value : null;
    }

    public onDatePickerChange(value: string) {
        this.updateValue(value);
        this.currentQuickSelectValue = null;
    }

}

export interface MaturityDateValues {
    date: {
        min: string;
        max: string;
    };
    quickSelect: FormControlSelectOption[];
    excludedDays?: string[];
}
