<div
    class="tw-flex"
    [ngClass]="{'tw-pb-2' : stackable}">
    <div class="tw-flex tw-h-5 tw-items-center">
        <input
            type="checkbox"
            [class]="inputStyle()"
            [id]="inputId"
            [checked]="checked"
            [indeterminate]="indeterminate"
            [disabled]="disabled"
            (blur)="onBlur()"
            (click)="onInputClick()"
            (change)="onInteractionEvent($event)"/>
    </div>
    <div class="tw-ml-2 tw-text-sm tw-leading-5">
        <label
            [class]="labelStyle({ disabled })"
            [for]="inputId"><ng-content select="[label]"></ng-content></label>
        <p class="tw-mb-0 tw-mt-1 tw-text-control-description empty:tw-hidden"><ng-content select="[description]"></ng-content></p>
    </div>
</div> 
