import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { chain, groupBy } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityWidgetOptions } from '../../../models/widgets.model';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor, NgSwitch, NgSwitchCase, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-entity-widget-focuslist-change',
    templateUrl: './entity-widget-focuslist-change.component.html',
    styleUrls: ['./entity-widget-focuslist-change.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, MatButtonToggleModule, NgFor, NgSwitch, NgSwitchCase, RouterLink, AsyncPipe, TranslocoLocaleModule]
})
export class EntityWidgetFocuslistChangeComponent implements OnInit, OnChanges, EntityWidgetContentComponent {
    public static disableTitle = false;

    @HostBinding('class.entity-widget-focuslist-change') public focusListWidgetClass = true;

    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public options: EntityWidgetOptions;
    @Input() public data: FocusListChangesData = null;
    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();

    public processedData$: BehaviorSubject<ProcessedFocusListChangeData> = new BehaviorSubject<ProcessedFocusListChangeData>([]);
    public groupedData$: BehaviorSubject<GroupedProcessedFocusListChangeData> = new BehaviorSubject<GroupedProcessedFocusListChangeData>([]);
    public groupedByChangeType$: BehaviorSubject<GroupedChangeTypeFocusListChangeData> = new BehaviorSubject<GroupedChangeTypeFocusListChangeData>({});
    public changeTypeTabs$: Observable<string[]>;

    public productTypeToString = ProductTypeToString;
    public productTypes = Object.keys(FocusListChangeProductType);
    public dataFilter: FocusListChangeFilter = {
        types: [
            FocusListChangeProductType.STOCK,
            FocusListChangeProductType.BOND,
            FocusListChangeProductType.DERIVATE,
            FocusListChangeProductType.FUND,
        ],
        search: '',
    };

    public changeTypesOrder = ['INSERT', 'DELETE'];
    public showGrouped = false;
    public disableProductGrouping = true;
    public activeTab = 'INSERT';
    public filterExpanded = false;

    constructor() {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && this.data) {
            this.transformAndUpdateFocusListChangesData(this.data, this.dataFilter);
        }
    }

    public ngOnInit() {
        this.changeTypeTabs$ = this.groupedByChangeType$.pipe(
            map((data) => {
                return Object.keys(data).sort(
                    (a, b) => this.changeTypesOrder.indexOf(a) - this.changeTypesOrder.indexOf(b),
                );
            }),
        );
    }

    public onInputChange(value: string) {
        this.dataFilter.search = value;

        this.transformAndUpdateFocusListChangesData(this.data, this.dataFilter);
    }

    public transformAndUpdateFocusListChangesData(data: FocusListChangesData, filter: FocusListChangeFilter) {
        if (!data) {
            return;
        }

        const result: ProcessedFocusListChangeData = [];

        Object.keys(data).forEach((productType) => {
            Object.keys(data[productType]).forEach((changeType) => {
                data[productType][changeType].forEach((change: FocusListChange) => {
                    if (filter.types && filter.types.indexOf(FocusListChangeProductType[productType]) !== -1) {
                        const search = ('' + filter.search).toLocaleLowerCase();
                        if (
                            (change.name && change.name.toLocaleLowerCase().indexOf(search) !== -1) ||
                            (change.isin && change.isin.toLocaleLowerCase().indexOf(search) !== -1) ||
                            (change.wkn && change.wkn.toLocaleLowerCase().indexOf(search) !== -1)
                        ) {
                            result.push({
                                ...change,
                                date: new Date(change.date),
                                changeType,
                                productType,
                            });
                        }
                    }
                });
            });
        });

        const sortedResult = result.sort((a, b) => {
            return (b.date as Date).getTime() - (a.date as Date).getTime();
        });

        const groupedResult = chain(sortedResult).groupBy('dq').map((items, dq) => {
            const singleItem = items[0];

            return {
                dq,
                date: singleItem.date,
                isin: singleItem.isin,
                wkn: singleItem.wkn,
                name: singleItem.name,
                productType: singleItem.productType,
                changes: items.reverse(),
            };
        }).value() as any;

        const groupedByChangeType = groupBy(sortedResult, 'changeType') as any;

        this.processedData$.next(sortedResult);
        this.groupedData$.next(groupedResult as GroupedProcessedFocusListChangeData);
        this.groupedByChangeType$.next(groupedByChangeType);
    }

    public onChangeTab(event: MouseEvent, key: any) {
        event.preventDefault();

        this.activeTab = key;
    }

    public onClickFilterToggle(event: MouseEvent) {
        event.preventDefault();

        this.filterExpanded = !this.filterExpanded;
    }

    public onCheckboxChange($event: MatButtonToggleChange) {
        this.dataFilter.types = $event.value;
        this.transformAndUpdateFocusListChangesData(this.data, this.dataFilter);
    }
}

export enum FocusListChangeProductType {
    STOCK = 'STOCK',
    DERIVATE = 'DERIVATE',
    FUND = 'FUND',
    BOND = 'BOND',
}

export const ProductTypeToString = {
    [FocusListChangeProductType.STOCK]: 'Aktie',
    [FocusListChangeProductType.DERIVATE]: 'Zertifikate',
    [FocusListChangeProductType.FUND]: 'Fonds',
    [FocusListChangeProductType.BOND]: 'Anleihen',
};

interface FocusListChangesData {
    STOCK: FocusListChangesDiff;
}

interface FocusListChangesDiff {
    INSERT: FocusListChange[];
    DELETE: FocusListChange[];
}

interface FocusListChange {
    dq: string;
    isin: string;
    wkn: string;
    name: string;
    date: string | Date;
}

interface ChangeCategorisation {
    changeType: string;
    productType: string;
}

interface FocusListChangeFilter {
    types: FocusListChangeProductType[];
    search: string;
}

type FocusListChangeFilterTypes = {
    [key in FocusListChangeProductType]: boolean;
};

type ProcessedFocusListChangeData = (FocusListChange & ChangeCategorisation)[];
type GroupedProcessedFocusListChangeData = {
    dq: string;
    isin: string;
    date: Date;
    wkn: string;
    name: string;
    productType: string;
    changes: ProcessedFocusListChangeData
}[];

interface GroupedChangeTypeFocusListChangeData {
    [key: string]: ProcessedFocusListChangeData;
}
