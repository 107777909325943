import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';

import { AppState } from '../store/state/index.state';
import { Dictionary } from '@ngrx/entity';

@Injectable()
export class ScheduledActionService {
    private actionTimeouts: { [actionType: string]: ScheduledAction[] } = {};
    private clearedActionTypes: Dictionary<boolean> = {};

    constructor(private readonly store: Store<AppState>) {
    }

    public scheduleAction(action: Action, delay: number, unique = false): ScheduledAction {
        if (unique && this.actionTimeouts[action.type] && this.actionTimeouts[action.type].length) {
            return;
        }

        this.clearedActionTypes[action.type] = false;

        const scheduledAction = {
            action,
        } as ScheduledAction;
        scheduledAction.timeoutId = window.setTimeout(() => {
            this.cleanupScheduledAction(scheduledAction);
            if (!this.clearedActionTypes[action.type]) {
                this.store.dispatch(action);
            }
        }, delay);
        this.actionTimeouts[action.type] = this.actionTimeouts[action.type] ? this.actionTimeouts[action.type] : [];
        this.actionTimeouts[action.type].push(scheduledAction);
        return scheduledAction;
    }

    public clearScheduledActionByInstance(scheduledAction: ScheduledAction) {
        const actionType = scheduledAction.action.type;
        const bucket = this.actionTimeouts[actionType];
        if (bucket) {
            const index = bucket.indexOf(scheduledAction);
            if (index > -1) {
                window.clearTimeout(bucket[index].timeoutId);
                bucket.splice(index, 1);
            }
        }
    }

    public clearScheduledActionsByActionType(actionType: string) {
        const bucket = this.actionTimeouts[actionType];
        if (bucket) {
            bucket.forEach((scheduledAction) => {
                window.clearTimeout(scheduledAction.timeoutId);
            });
            this.actionTimeouts[actionType] = [];
            this.clearedActionTypes[actionType] = true;
        }
    }

    private cleanupScheduledAction(scheduledAction: ScheduledAction) {
        const actionType = scheduledAction.action.type;
        const bucket = this.actionTimeouts[actionType];
        if (bucket) {
            const index = bucket.indexOf(scheduledAction);
            if (index > -1) {
                bucket.splice(index, 1);
            }
        }
    }
}

export interface ScheduledAction {
    timeoutId: number;

    action: Action;
}
