/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 7/4/17.
 */

import { Action } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { FormValues } from '../../../../core/models/form.model';
import { type } from '../../../../core/store/util';
import { EntitySortSetting, QuerySet } from '../../../../shared/models/entities.model';
import { TargetMarketCriteria } from '../../../target-market/models/target-market-criteria.model';

export const QuerySetActionTypes = {
    CREATE_QUERY_SET: type('QUERY_SET:CREATE_QUERY_SET'),
    QUERY_SET_CREATED: type('QUERY_SET:QUERY_SET_CREATED'),
    CREATE_QUERY_SET_FAILED: type('QUERY_SET:CREATE_QUERY_SET_FAILED'),

    DELETE_QUERY_SET: type('QUERY_SET:DELETE_QUERY_SET'),
    QUERY_SET_DELETED: type('QUERY_SET:QUERY_SET_DELETED'),
    DELETE_QUERY_SET_FAILED: type('QUERY_SET:DELETE_QUERY_SET_FAILED'),

    LOAD_QUERY_SET_COUNT: type('QUERY_SET:LOAD_QUERY_SET_COUNT'),
    QUERY_SET_COUNT_LOADED: type('QUERY_SET:QUERY_SET_COUNT_LOADED'),
    LOAD_QUERY_SET_COUNT_FAILED: type('QUERY_SET:LOAD_QUERY_SET_COUNT_FAILED'),
};

/** CreateQuerySetAction **/

export interface CreateQuerySetAction extends Action {
    payload: {
        productTypeId: string;
        filter: FormValues;
        oldQuerySetId: string;
        targetMarketCriteria: TargetMarketCriteria;
        encodedCriteria: string;
        passCriteria: boolean;
        sort: EntitySortSetting;
        resort: boolean;
        limit: number;
    };
}

export function createQuerySet(
    productTypeId: string,
    filter: FormValues,
    sort: EntitySortSetting,
    targetMarketCriteria: TargetMarketCriteria,
    oldQuerySetId: string,
    resort: boolean,
    limit: number,
    encodedCriteria: string,
    passCriteria: boolean
): CreateQuerySetAction {
    return {
        type: QuerySetActionTypes.CREATE_QUERY_SET,
        payload: {
            productTypeId,
            filter,
            sort,
            targetMarketCriteria,
            encodedCriteria,
            passCriteria,
            oldQuerySetId,
            resort,
            limit
        }
    };
}

/** QuerySetCreatedAction **/

export interface QuerySetCreatedAction extends Action {
    payload: {
        productTypeId: string;
        querySet: QuerySet;
    };
}

export function querySetCreated(productTypeId: string, querySet: QuerySet): QuerySetCreatedAction {
    return {
        type: QuerySetActionTypes.QUERY_SET_CREATED,
        payload: {
            querySet,
            productTypeId,
        },
    };
}

/** CreateQuerySetFailedAction **/

export interface CreateQuerySetFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function createQuerySetFailed(error: AppError): CreateQuerySetFailedAction {
    return {
        type: QuerySetActionTypes.CREATE_QUERY_SET_FAILED,
        payload: {
            error,
        },
    };
}

/** DeleteQuerySetAction **/

export interface DeleteQuerySetAction extends Action {
    payload: {
        productTypeId: string;
        querySetId: string;
    };
}

export function deleteQuerySet(productTypeId: string, querySetId: string): DeleteQuerySetAction {
    return {
        type: QuerySetActionTypes.DELETE_QUERY_SET,
        payload: {
            productTypeId,
            querySetId,
        },
    };
}

/** QuerySetDeletedAction **/

export interface QuerySetDeletedAction extends Action {
    payload: {
        productTypeId: string;
    };
}

export function querySetDeleted(productTypeId: string): QuerySetDeletedAction {
    return {
        type: QuerySetActionTypes.QUERY_SET_DELETED,
        payload: {
            productTypeId,
        },
    };
}

/** DeleteQuerySetFailedAction **/

export interface DeleteQuerySetFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function deleteQuerySetFailed(error: AppError): DeleteQuerySetFailedAction {
    return {
        type: QuerySetActionTypes.DELETE_QUERY_SET_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadQuerySetCountAction **/

export interface LoadQuerySetCountAction extends Action {
    payload: {
        productTypeId: string;
        querySetId: string;
    };
}

export function loadQuerySetCount(productTypeId: string, querySetId: string): LoadQuerySetCountAction {
    return {
        type: QuerySetActionTypes.LOAD_QUERY_SET_COUNT,
        payload: {
            productTypeId,
            querySetId,
        },
    };
}

/** QuerySetCountLoadedAction **/

export interface QuerySetCountLoadedAction extends Action {
    payload: {
        querySetId: string;
        count: number;
    };
}

export function querySetCountLoaded(querySetId: string, count: number): QuerySetCountLoadedAction {
    return {
        type: QuerySetActionTypes.QUERY_SET_COUNT_LOADED,
        payload: {
            querySetId,
            count,
        },
    };
}

/** LoadQuerySetCountFailedAction **/

export interface LoadQuerySetCountFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadQuerySetCountFailed(error: AppError): LoadQuerySetCountFailedAction {
    return {
        type: QuerySetActionTypes.LOAD_QUERY_SET_COUNT_FAILED,
        payload: {
            error,
        },
    };
}
