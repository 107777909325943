import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormBuilder, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-time-input',
    templateUrl: './time-input.component.html',
    styleUrls: ['./time-input.component.scss'],
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimeInputComponent),
            multi: true,
        }, {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TimeInputComponent),
            multi: true,
        }],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor]
})
export class TimeInputComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
    public showNativeSelect = false;
    public showFallbackSelect = false;
    public timeForm: UntypedFormGroup;

    public minuteOptions = [];
    public hourOptions = [];
    private onChange: () => void;
    private onTouched: any;

    private timeValue = '';
    private formSubscription: Subscription;

    constructor(private fb: UntypedFormBuilder) {
        this.timeForm = this.fb.group({
            time: [''],
            hour: [''],
            minute: [''],
        });
    }

    public ngOnInit() {
        const test = document.createElement('input');

        try {
            test.type = 'time';
        } catch (e) {
            console.log(e.description);
        }

        if (test.type === 'text') {
            this.showNativeSelect = false;
            this.showFallbackSelect = true;

            this.populateMinutes();
            this.populateHours();
        } else {
            this.showNativeSelect = true;
            this.showFallbackSelect = false;
        }

        this.formSubscription = this.timeForm.valueChanges
            .subscribe((value) => {
                if (this.showNativeSelect) {
                    this.timeValue = value.time;
                } else {
                    this.timeValue = value.hour + ':' + value.minute;
                }

                this.onChange();
            });
    }

    public ngOnDestroy(): void {
        if (this.formSubscription) {
            this.formSubscription.unsubscribe();
        }
    }

    private populateHours() {
        for (let i = 0; i <= 23; i++) {
            this.hourOptions.push(String(i < 10 ? '0' + i : i));
        }
    }

    private populateMinutes() {
        for (let i = 0; i <= 59; i++) {
            this.minuteOptions.push(String(i < 10 ? '0' + i : i));
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this.timeValue);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        return undefined;
    }

    public writeValue(time: string): void {
        const momentDate = moment(time, 'HH:mm');

        if (momentDate.isValid()) {
            this.timeValue = momentDate.format('HH:mm');

            this.timeForm.patchValue({
                time: this.timeValue,
                hour: momentDate.format('HH'),
                minute: momentDate.format('mm'),
            });
        }
    }
}
