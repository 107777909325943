import { Component, Inject, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingDialogData } from './loading-dialog-data.interface';
import { NgIf } from '@angular/common';
import { SpinnerComponent } from '../../spinner/spinner.component';

@Component({
    selector: 'app-loading-dialog',
    templateUrl: './loading-dialog.component.html',
    standalone: true,
    imports: [SpinnerComponent, NgIf]
})
export class LoadingDialogComponent {
    public text: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: LoadingDialogData,
        private renderer: Renderer2
    ) {
        this.text = this.data.text;
    }

}
