<ng-container *ngIf="list?.length">
    <div class="pb-2">{{ 'contact-partners.yourContactPartner' | transloco: {count: list?.length} }}</div>
    <div [ngClass]="{'row' : columns}" class="row">
        <ng-container *ngFor="let item of list; let index = index">
            <div class="contact-partner" [ngClass]="[columns ? 'col-' + grid / columns : '', !columns && index > 0 || columns && index > 1 ? 'pt-3' : '']">
                <div class="contact-partner__avatar__container" *ngIf="item.picture">
                    <div class="contact-partner__avatar" [style.backgroundImage]="'url(' + resolveDerifilesImage(item.picture) + ')'"></div>
                </div>
                <strong>{{item.name}}</strong>
                <div class="contact-partner__detail">
                    <app-icon icon="material:phone"></app-icon>
                    <span>{{item.phone}}</span>
                </div>
                <div class="contact-partner__detail">
                    <app-icon icon="material:at"></app-icon>
                    <a [href]="'mailto:' + item.mail">{{item.mail}}</a>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>
