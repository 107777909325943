<div class="mat-calendar-header">
    <div class="mat-calendar-controls">

        <button mat-button type="button" class="mat-calendar-period-button"
                (click)="currentPeriodClicked()" [attr.aria-label]="periodButtonLabel"
                cdkAriaLive="polite">
            {{periodButtonText}}
            <div class="mat-calendar-arrow"
                 [class.mat-calendar-invert]="calendar.currentView !== 'month'"></div>
        </button>

        <div class="mat-calendar-spacer"></div>

        <ng-content></ng-content>

        <button mat-icon-button type="button" class="mat-calendar-previous-button"
                [disabled]="!previousEnabled()" (click)="previousClicked()"
                [attr.aria-label]="prevButtonLabel">
        </button>

        <button mat-button type="button" class="mat-calendar-today-button"
                (click)="todayClicked()"
                [attr.aria-label]="'general.date.today' | transloco">{{ 'general.date.today' | transloco }}
        </button>

        <button mat-icon-button type="button" class="mat-calendar-next-button"
                [disabled]="!nextEnabled()" (click)="nextClicked()"
                [attr.aria-label]="nextButtonLabel">
        </button>
    </div>
</div>
