import { SelectionModel } from "@angular/cdk/collections";
import { CdkOverlayOrigin, ConnectedPosition, OverlayModule } from "@angular/cdk/overlay";
import { AfterContentInit, Component, ContentChildren, ElementRef, Inject, NgZone, OnInit, Optional, QueryList, Self } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";
import { defer, merge, Observable, Subject } from "rxjs";
import { debounceTime, startWith, switchMap, take } from "rxjs/operators";
import { ChromaOption, ChromaOptionSelectionChange } from "../core/option/option";
import { ChromaFormField, CHROMA_FORM_FIELD } from "../form-field/form-field";
import { ChromaFormFieldControl } from "../form-field/form-field-control";

@Component({
    selector: 'chroma-select',
    templateUrl: './select.html',
    standalone: true,
    host: {
        'class': 'tw-w-full'
    },
    providers: [{provide: ChromaFormFieldControl, useExisting: ChromaSelect}],
    imports: [OverlayModule]
})
export class ChromaSelect implements AfterContentInit, OnInit, ControlValueAccessor, ChromaFormFieldControl {

    @ContentChildren(ChromaOption, {descendants: true}) options: QueryList<ChromaOption>;

    private openPanelSubject = new Subject<boolean>();

    _positions: Array<ConnectedPosition> = [
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
          panelClass: 'tw-mt-2.5'
        }
    ];

    private _value: any;

    readonly optionSelectionChanges: Observable<ChromaOptionSelectionChange> = defer(() => {
        const options = this.options;
    
        if (options) {
          return options.changes.pipe(
            startWith(options),
            switchMap(() => merge(...options.map(option => option.selectionChange)))
          );
        }
    
        return this._ngZone.onStable.pipe(
          take(1),
          switchMap(() => this.optionSelectionChanges)
        );
      }) as Observable<ChromaOptionSelectionChange>;

    constructor(
        protected _ngZone: NgZone,
        @Optional() @Inject(CHROMA_FORM_FIELD) protected _parentFormField: ChromaFormField,
        @Self() @Optional() private ngControl: NgControl
    ) {
        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }
    }

    private _panelOpen = false;

    _selectionModel: SelectionModel<ChromaOption>;

    _preferredOverlayOrigin: CdkOverlayOrigin | ElementRef | undefined;

    _overlayWidth: string | number;

    _onChange: (value: any) => void = () => {};

    get focused(): boolean {
        return this._focused || this._panelOpen;
    }
    private _focused = false;
    
    get disabled(): boolean {
        return this._disabled;
    }
    private _disabled = false;

    errorState = false;

    pointer = true;

    ngOnInit() {
        this._selectionModel = new SelectionModel(false);

        this.openPanelSubject.pipe(
            debounceTime(0)
        ).subscribe((panelOpen) =>
            panelOpen ? this.close() : this.open()
        );
    }

    ngAfterContentInit() {
        this._selectionModel.changed.subscribe(event => {
            event.added.forEach(option => option.select());
            event.removed.forEach(option => option.deselect());
        });

        this.options.changes.pipe().subscribe(() => {
            this._resetOptions();
            const correspondingOption = this.options.find((option: ChromaOption) => {
                if (this._selectionModel.isSelected(option)) {
                    return false;
                }

                return option.value != null;
            });

            if (correspondingOption) {
                this._selectionModel.select(correspondingOption);
            }
        });
    }

    toggle(): void {
        this.openPanelSubject.next(this.panelOpen);
    }

    open(): void {
        if (this._parentFormField) {
            this._preferredOverlayOrigin = this._parentFormField.getConnectedOverlayOrigin();
        }
        
        this._overlayWidth = (this._preferredOverlayOrigin as ElementRef).nativeElement.getBoundingClientRect().width;

        if (this._canOpen()) {
            this._panelOpen = true;
        }
    }

    close(): void {
        if (this._panelOpen) {
            this._panelOpen = false;
        }
    }

    writeValue(value: any): void {
        if (value !== this._value) {
            this._value = value;
        }
    }

    registerOnChange(fn: (value: any) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: () => {}): void { }

    get panelOpen(): boolean {
        return this._panelOpen;
    }

    get selected(): ChromaOption | Array<ChromaOption> {
        return this._selectionModel?.selected;
    }

    get empty(): boolean {
        return !this._selectionModel || this._selectionModel.isEmpty();
    }

    protected _canOpen(): boolean {
        return !this._panelOpen && !this.disabled && this.options?.length > 0;
    }

    private _resetOptions(): void {
        this.optionSelectionChanges.subscribe(event => {
            this._onSelect(event.source);

            if (event.isUserInput && this._panelOpen) {
                this.close();
            }
        });
    }

    private _onSelect(option: ChromaOption): void {
        const wasSelected = this._selectionModel.isSelected(option);

        if (wasSelected !== option.selected) {
            option.selected
                ? this._selectionModel.select(option)
                : this._selectionModel.deselect(option);

            this._onChange(option.value);
        }
    }

    onContainerClick() {
        this.open();
    }
}
