import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-external-layout',
    template: `
        <div class="external-layout__content">
            <div class="container">
                <router-outlet></router-outlet>
            </div>
        </div>
    `,
    standalone: true,
    imports: [RouterOutlet]
})
export class ExternalLayoutComponent implements OnInit {
    @HostBinding('class.small-layout') public smallLayoutClass = true;
    @HostBinding('class.compact-layout') public compactLayoutClass = true;

    public ngOnInit(): void {
    }
}
