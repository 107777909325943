/**
 * Get the id for the upload queue for a given product
 * @param {string} productId
 * @returns {string}
 */
export function getProductAttachmentUploadQueueId(productId: string): string {
    return `product-attachments:${productId}`;
}

/**
 * Check if the given event contains files
 * @param {DragEvent} event
 * @returns {boolean}
 */
export function eventContainsFiles(event: DragEvent) {
    if (event.dataTransfer.types) {
        for (const type of event.dataTransfer.types) {
            if (type === 'Files') {
                return true;
            }
        }
    }

    return false;
}
