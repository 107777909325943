<div class="wms-dialog-inner confirm-dialog tw-bg-primary-bg tw-text-black dark:tw-text-white">
    <nav class="navbar navbar-toggleable-md header-bar navbar-inverse header-bar tw-py-4">
        <div class="3xl:tw-container tw-w-full tw-mx-auto tw-px-8 tw-flex">
            <i class="mr-auto"></i>
            <div class="navbar-nav">
                <a (click)="onCloseClick($event)" class="nav-link icon-link close-modal-button" href="#">
                    <app-icon class="derifin-icon" icon="derifin:schliessen"></app-icon>
                </a>
            </div>
        </div>
    </nav>
    
    
    <div class="modal-body">
        <h1 class="modal-form__title">{{title}}</h1>
    
        <p *ngIf="!html">{{message}}</p>
        <div *ngIf="html" [innerHTML]="message"></div>
    
        <div class="modal-actions">
            <a 
                href="#"
                class="modal-actions__action modal-actions__yes"
                (click)="onYesClick($event)">
                {{ 'modals.confirmDialog.yes' | transloco }}
            </a>
    
            <a
                href="#"
                class="modal-actions__action modal-actions__no"
                (click)="onNoClick($event)">
                {{ 'modals.confirmDialog.no' | transloco }}
            </a>
        </div>
    </div>
</div>
