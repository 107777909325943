<form class="row entity-widget__card--compact-form filter__form filter__{{orientation}}" [formGroup]="filterForm">
  <ng-container *ngIf="structureGroups">
    <section class="card-column col-md filter__col-1">
      <div class="card split-card">
        <div class="row">
          <section class="col-md filter__col-1">
            <div class="card-block">
              <div class="row">
                <div class="col-md filter__col-1">
                  <ng-container *ngFor="let controlGroup of structureGroups">
                    <div
                        *ngIf="hasVisibleControls(controlGroup) && isControlVisibleInConditionalTree(controlGroup)"
                        class="card-form__section"
                    >

                      <h4 class="card-headline" *ngIf="controlGroup.title">
                        {{controlGroup.title}}
                      </h4>

                      <ng-container *ngFor="let control of controlGroup.controls">
                        <app-filter-control-container
                                *ngIf="isControlVisibleInConditionalTree(control)"
                                [config]="control"
                                [initiallyDisabled]="disabled || control.disabled"
                                [values]="getAvailableDataForControl(control.values)"
                                [formControlName]="control.path">
                        </app-filter-control-container>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  </ng-container>
</form>
