import { Action } from '@ngrx/store';
import { UploadFile, UploadHandler } from '../../models/upload.model';
import { type } from '../util';

export const UploadActionTypes = {
    CREATE_UPLOAD_QUEUE: type('UPLOAD:CREATE_UPLOAD_QUEUE'),
    ADD_FILE_TO_UPLOAD_QUEUE: type('UPLOAD:ADD_FILE_TO_UPLOAD_QUEUE'),
    UPDATE_UPLOAD_PROGRESS: type('UPLOAD:UPDATE_UPLOAD_PROGRESS'),
    UPLOAD_COMPLETED: type('UPLOAD:UPLOAD_COMPLETED'),
    UPLOAD_FAILED: type('UPLOAD:UPLOAD_FAILED'),
    REMOVE_FILE_FROM_UPLOAD_QUEUE: type('UPLOAD:REMOVE_FILE_FROM_UPLOAD_QUEUE'),
};

/** CreateUploadQueueAction **/

export interface CreateUploadQueueAction extends Action {
    payload: {
        id: string;
        uploadHandler: UploadHandler;
    };
}

export function createUploadQueue(id: string, uploadHandler: UploadHandler): CreateUploadQueueAction {
    return {
        type: UploadActionTypes.CREATE_UPLOAD_QUEUE,
        payload: {
            id,
            uploadHandler,
        },
    };
}

/** AddFileToUploadQueueAction **/

export interface AddFileToUploadQueueAction extends Action {
    payload: {
        queueId: string;
        uploadFile: UploadFile
    };
}

export function addFileToUploadQueue(queueId: string, uploadFile: UploadFile): AddFileToUploadQueueAction {
    return {
        type: UploadActionTypes.ADD_FILE_TO_UPLOAD_QUEUE,
        payload: {
            queueId,
            uploadFile,
        },
    };
}

/** UpdateUploadProgressAction **/

export interface UpdateUploadProgressAction extends Action {
    payload: {
        fileId: string;
        progress: number
    };
}

export function updateUploadProgress(fileId: string, progress: number): UpdateUploadProgressAction {
    return {
        type: UploadActionTypes.UPDATE_UPLOAD_PROGRESS,
        payload: {
            fileId,
            progress,
        },
    };
}

/** UploadCompletedAction **/

export interface UploadCompletedAction extends Action {
    payload: {
        queueId: string;
        fileId: string;
        response: any;
    };
}

export function uploadCompleted(queueId: string, fileId: string, response: any): UploadCompletedAction {
    return {
        type: UploadActionTypes.UPLOAD_COMPLETED,
        payload: {
            queueId,
            fileId,
            response,
        },
    };
}

/** UploadFailedAction **/

export interface UploadFailedAction extends Action {
    payload: {
        queueId: string;
        fileId: string;
        error: any;
    };
}

export function uploadFailed(queueId: string, fileId: string, error: any): UploadFailedAction {
    return {
        type: UploadActionTypes.UPLOAD_FAILED,
        payload: {
            queueId,
            fileId,
            error,
        },
    };
}

/** RemoveFileFromUploadQueueAction **/

export interface RemoveFileFromUploadQueueAction extends Action {
    payload: {
        queueId: string;
        fileId: string;
    };
}

export function removeFileFromUploadQueue(queueId: string, fileId: string): RemoveFileFromUploadQueueAction {
    return {
        type: UploadActionTypes.REMOVE_FILE_FROM_UPLOAD_QUEUE,
        payload: {
            queueId,
            fileId,
        },
    };
}
