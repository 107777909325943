import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormControlConfig, FormControlSelectOptionExtra } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-filter-select-cells',
    templateUrl: './filter-select-cells.component.html',
    styleUrls: ['./filter-select-cells.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf]
})
export class FilterSelectCellsComponent implements OnInit, SingleValueFilterControl<string>, OnChanges {

    @Input() public config: FormControlConfig;

    @Input() public values: FormControlSelectOptionExtra[];

    @Input() public disabled = false;

    public currentValue: string;

    public onChange: () => void;

    public onTouched: () => void;

    constructor() {
    }

    public ngOnInit() {
    }

    public writeValue(value: string): void {
        if (this.currentValue !== value) {
            this.currentValue = value;
        }
    }

    public textFormatter(format = '{0}', ...args) {
        return String(format).replace(/{(\d+)}/g, (match, num) => {
            return typeof args[num] !== 'undefined' ? args[num] : match;
        });
    }

    public getCellTitle(cell: FormControlSelectOptionExtra) {
        if (!this.config.options) {
            return 'label';
        }
        const titlePath = this.config.options.titlePath || 'label';

        if (this.config.options.titleFormat) {
            return this.textFormatter(this.config.options.titleFormat, cell[titlePath]);
        }

        return cell[titlePath];
    }

    public getCellSubtitle(cell: FormControlSelectOptionExtra) {
        if (!this.config.options) {
            return 'label';
        }
        const subtitlePath = this.config.options.subtitlePath || null;
        if (!subtitlePath) {
            return null;
        }

        if (this.config.options.subtitleFormat) {
            return this.textFormatter(this.config.options.subtitleFormat, cell[subtitlePath]);
        }

        return cell[subtitlePath];
    }

    protected _isValidValue(value: string) {
        if (this.values) {
            return this.values.reduce((hasValue, validValue) => {
                return value === validValue.id || hasValue;
            }, false);
        } else {
            return false;
        }

    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this.currentValue);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this._isValidValue(this.currentValue)) ? null : {
            singleSelectError: 'Invalid value specified',
        };
    }

    /**
     * Called when the selection changed
     */
    public onSingleSelectChange(value: string) {
        if (this.currentValue !== value) {
            this.currentValue = value;
            if (this.onChange) {
                this.onChange();
            }
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.values) {
            const currentValueStillValid = this.values
                ? this.values.filter((value) => value.id === this.currentValue).length > 0 :
                false;

            this.currentValue = currentValueStillValid
                ? this.currentValue
                : null;
        }
    }

    public onCellClick(event, value: FormControlSelectOptionExtra) {
        event.preventDefault();

        if (this.currentValue !== value.id) {
            this.currentValue = value.id;
            if (this.onChange) {
                this.onChange();
            }
        }
    }

    public getCellWidth(value: FormControlSelectOptionExtra) {
        let numberOfColumns = 3;

        if (this.config.options && this.config.options.columns) {
            numberOfColumns = parseInt(this.config.options.columns, 10);
        }

        return (95 / numberOfColumns) + '%';
    }
}
