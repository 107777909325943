import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthStoreService } from "../services/auth-store.service";
import { ConfigurationService } from "../services/configuration.service";
import { LogglyLoggerService } from "../services/loggly-logger.service";
import { DerifinApi } from "./derifin.api";
import { Shortcuts } from "./models/user.model";

@Injectable({ providedIn: 'root' })
export class UserApi extends DerifinApi {

    constructor(
        public http: HttpClient,
        public authStoreService: AuthStoreService,
        public loggerService: LogglyLoggerService,
        public appConfigService: ConfigurationService
    ) {
        super('user', appConfigService.configuration.environment.id, http, authStoreService, loggerService, appConfigService);
    }

    public getShortcuts(): Observable<Shortcuts> {
        return this.get('shortcuts').pipe(
            map((response) => response.data.shortcuts)
        );
    }
}
