import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'columnLabel',
    standalone: true
})
export class ColumnLabelPipe implements PipeTransform {

    constructor(private _sanitizer: DomSanitizer) {

    }

    public transform(value: string, args?: any): any {
        const lines = value.split('<br>');
        const sanitizedLines = lines.map((line) => this._sanitizer.sanitize(SecurityContext.HTML, line));
        return this._sanitizer.bypassSecurityTrustHtml(sanitizedLines.join('<br />'));
    }

}
