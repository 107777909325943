<div class="custom-controls-stacked">
    <label class="custom-control" [class.custom-checkbox]="!config.options.switches"
           [class.custom-switch]="config.options.switches">
        <input [id]="config.path" [name]="config.path" type="checkbox" class="custom-control-input"
               [checked]="_value" [disabled]="disabled" (change)="onCheckboxChange($event)">
        <span class="custom-control-indicator"></span>
        <span class="custom-control-description">{{config?.options?.label}}</span>
    </label>
</div>

<div *ngIf="showWarning" class="filter-checkbox-tmc__warning">
    {{ 'filters.checkboxTmc.warning' | transloco }}
</div>

<!-- {{config | json}} -->
