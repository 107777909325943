import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { get } from 'lodash';
import { ImageReferenceResolverService } from '../../../core/services/image-reference-resolver.service';
import { Entity } from '../../models/entities.model';
import { EntityTableCellComponent } from '../entity-table-component-cell/entity-table-component-cell.component';
import {
  EntityTableSelectboxCellOptions,
  EntityTableSelectboxItem,
} from '../entity-table-selectbox-cell/entity-table-selectbox-cell.component';

@Component({
    selector: 'app-entity-table-image-cell',
    templateUrl: './entity-table-image-cell.component.html',
    styleUrls: ['./entity-table-image-cell.component.scss'],
    standalone: true
})
export class EntityTableImageCellComponent implements OnInit, OnChanges, EntityTableCellComponent {

  @Input() public product: Entity;
  @Input() public options: EntityTableImageCellOptions;
  @Input() public path: string;
  @Input() public multiIndex = 0;

  @Output() public event: EventEmitter<any> = new EventEmitter();

  public imageSrc = '';
  public maxHeight = '50px';

  constructor(private readonly imageReferenceResolver: ImageReferenceResolverService) { }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.product) {
      this.imageSrc = this.imageReferenceResolver.getImageUrlForReferenceSync(
          get(this.product, this.path, ''),
      );
    }

    if (changes.options && this.options && this.options.maxHeight) {
      this.maxHeight = this.options.maxHeight + 'px';
    }
  }

}

export interface EntityTableImageCellOptions {
  maxHeight?: number;
}
