import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors, FormsModule } from '@angular/forms';
import { has, isEqual } from 'lodash';
import { FormControlConfig, FormControlSelectOptions } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';
import { DateInputComponent } from '../date-input/date-input.component';
import { NgIf, NgFor } from '@angular/common';

/**
 * FilterMaturityComponent that is responsible for the maturity filter. It includes the instrumentTypeRestrictions any, range, intervall
 * and quarter
 */
@Component({
    selector: 'app-filter-maturity',
    templateUrl: './filter-maturity.component.html',
    styleUrls: ['./filter-maturity.component.scss'],
    standalone: true,
    imports: [NgIf, DateInputComponent, FormsModule, NgFor]
})
export class FilterMaturityComponent implements OnInit, SingleValueFilterControl<any> {

    public dateRegex = /^(0[1-9]|[12][0-9]|3[01])[/.](0[1-9]|1[012])[/.](19|20)\d\d$/;

    @Input() public config: FormControlConfig;

    @Input() public values: FormControlSelectOptions;

    @Input() public disabled = false;

    private _value: MaturityFilterValue;

    get value(): MaturityFilterValue {
        return this._value;
    }

    public onChange: () => void;

    public onTouched: () => void;

    constructor() {
    }

    public ngOnInit() {
    }

    /**
     * Writes the maturity filter value
     * @param {MaturityFilterValue} value
     */
    public writeValue(value: MaturityFilterValue): void {
        if (!isEqual(this._value, value)) {
            this._value = value;
        }
    }

    /**
     * Returns if the maturity filter value is valid or not
     * @param {MaturityFilterValue} value
     * @returns {boolean}
     * @private
     */
    protected _isValidValue(value: MaturityFilterValue) {
        if (value && value.params && value.params.to && value.params.from) {
            if (value.params.from > value.params.to) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this._value);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this._isValidValue(this._value)) ? null : {
            maturityError: 'Invalid value specified',
        };
    }

    /**
     * Called when one of the maturity type was clicked
     * @param e
     * @param value
     */
    public onMaturityTypeClicked(e: Event, value) {
        const target = e.target as HTMLInputElement;

        if (!this._value || this._value.type !== value && this.onChange) {
            this._value = {
                type: value,
                params: {},
            };
            this.onChange();
        }
    }

    /**
     * Called when the range of the first date input changed
     * @param e
     * @param value
     */
    public onRangeFromChanged(value: string) {
        if (value) {
            const date = new Date(value);
            if (!this._value.params.range) {
                this._value.params.range = {};
            }
            if (this._value.params.range.min !== date.toISOString() && this.onChange) {
                this._value.params.range.min = date.toISOString();
                this.onChange();
            }
        } else {
            this._value.params.range.min = null
            this.onChange();
        }
    }

    /**
     * Called when the range of the second date input changed
     * @param e
     * @param value
     */
    public onRangeToChanged(value: string) {
        if (value) {
            const date = new Date(value);
            if (this._value.params.range.max !== date.toISOString() && this.onChange) {
                this._value = {
                    ...this._value,
                    params: {
                        ...this._value.params,
                        range: {
                            ...this._value.params.range,
                            max: date.toISOString(),
                        },
                    },
                };
                this.onChange();
            }
        } else {
            this._value.params.range.max = null;
            this.onChange();
        }
    }

    /**
     * Called when the interval was changed
     * @param e
     * @param position
     */
    public onMaturityFilterIntervalChangedClicked(e, position) {
        if (this._value.params.interval !== position && this.onChange) {
            this._value.params = {};
            this._value.params.interval = position;
            this.onChange();
        }
    }

    /**
     * Called when the quarter was changed
     * @param e
     * @param position
     */
    public onMaturityFilterQuarterChangedClicked(e: Event, position: number) {
        if (this._value.params.quarter !== position && this.onChange) {
            this._value.params = {};
            this._value.params.quarter = position;
            this.onChange();
        }
    }

    /**
     * Returns `true` if the control for the specified type should be displayed,
     * i.e. the type is listed in `values`.
     *
     * @param type Type (one of "any", "range", "interval", "quarter")
     */
    public shouldDisplayControl(type: 'any' | 'range' | 'interval' | 'quarter'): boolean {
        return has(this.values, 'types') && this.values.types.some(({id}) => id === type);
    }
}

export interface MaturityFilterValue {
    type: string;
    params: any;
}
