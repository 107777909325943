/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 4/6/17.
 */

import { Data } from '../../core/models/data.model';

export interface EntityTableColumn {
    label?: string;
    path: string;
    info?: string;
    decorator?: string;
    decoratorOptions?: any;
    styleDecorator?: string;
    styleDecoratorOptions?: any;
    pathToStyleHints?: string;
    pathToStyleDecorator?: string;
    sortPath?: string;
    initialOrder?: 'ASC' | 'DESC';
    weight?: number;
}

export interface EntityTableConfig {
    actions?: EntityListStructureAction[];
    columns: EntityTableColumn[];
    stickyHeader?: boolean;
    stickyOffset?: number;
    selectable?: boolean;
    removable?: boolean;
    switches?: boolean;
    selectionLabel?: string;
    sort?: EntitySortSetting;
    coloredRowFlashWhen?: string;
    layoutMode?: string;
    heading?: string;
    entityType?: string;
    pathToMultiRowCount?: string;
    pathToId?: string;
}

export interface Entity extends Data {
    id?: string;
}

export interface ProductsStore {
    [type: number]: {
        [wkn: string]: Entity;
    };
}

export interface EntityListStructureAction {
    label: string;
    type: string;
    link?: string;
    visibilityTriggers?: string[];
    fadeTriggers?: string[];
}

/**
 * Model representing a product query set
 */
export interface QuerySet {
    id: string;
    structure: EntityTableConfig;
    count?: number;
}

export interface EntitySortSetting {
    path: string;
    order: 'ASC' | 'DESC';
    clientSort?: boolean;
}

export interface UpdatedData {
    id: string;

    [key: string]: any;
}
