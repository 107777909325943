import { createAction, props } from "@ngrx/store";
import { TargetMarketCustomerData } from "../../../target-market/models/target-market-customer-data.model";
import { TargetMarketCustomCustomer } from "../../../target-market/models/target-market-customer.model";
import { CustomerTabConfig, CustomerType } from "../state/customer-selection.state";

export const setTabSelected = createAction(
    '[Customer Selection] Set Tab Selected',
    props<{ selected: CustomerType }>()
);

export const loadTabList = createAction(
    '[Customer Selection] Load Tab List',
    props<{ list: CustomerTabConfig[] }>()
);

export const activateCustomer = createAction(
    '[Customer Selection] Activate Customer',
    props<{ customer: TargetMarketCustomCustomer; customerType: CustomerType }>()
);

export const activateHALCustomer = createAction(
    '[Customer Selection] Activate HAL Customer',
    props<{ customer: TargetMarketCustomerData }>()
);

export const deactivateCustomer = createAction(
    '[Customer Selection] Deactivate Customer'
);
