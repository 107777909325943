/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 5/5/17.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Config } from '../models/config.model';
import { AuthStoreService } from '../services/auth-store.service';
import { ConfigurationService } from '../services/configuration.service';
import { LogglyLoggerService } from '../services/loggly-logger.service';
import { transformConfig } from './config.transform';
import { DerifinApi } from './derifin.api';
import { ApiResponse } from './models/api.model';
import { ConfigResponseData } from './models/config.model';

/**
 * API abstraction layer for the Config API
 */
@Injectable()
export class ConfigApi extends DerifinApi {
    constructor(http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, appConfigService: ConfigurationService) {
        super('config', appConfigService.configuration.environment.id, http, authStoreService, loggerService, appConfigService);
    }

    /**
     * Get the configuration for the currently authenticated user from the Config API
     */
    public getConfig(): Observable<Config> {
        return this.get('').pipe(
            map((response: ApiResponse<ConfigResponseData>) => response.data),
            map(transformConfig)
        );
    }
}
