/**
 * Created by Alex Klein <alex@rocketloop.de> on 05/16/17.
 */

import { Action } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { FormValues } from '../../../../core/models/form.model';
import { type } from '../../../../core/store/util';
import { FilterStructureConfig } from '../../models/filters.model';

export const FilterActionTypes = {
    LOAD_FILTER_STRUCTURE: type('TRADES:LOAD_FILTER_STRUCTURE'),
    FILTER_STRUCTURE_LOADED: type('TRADES:FILTER_STRUCTURE_LOADED'),
    LOAD_FILTER_STRUCTURE_FAILED: type('TRADES:LOAD_FILTER_STRUCTURE_FAILED'),

    LOAD_FILTER_AVAILABLE_VALUES: type('TRADES:LOAD_FILTER_AVAILABLE_VALUES'),
    FILTER_AVAILABLE_VALUES_LOADED: type('TRADES:FILTER_AVAILABLE_VALUES_LOADED'),
    LOAD_FILTER_AVAILABLE_VALUES_FAILED: type('TRADES:LOAD_FILTER_AVAILABLE_VALUES_FAILED'),

    LOAD_FILTER_INITIAL_VALUES: type('TRADES:LOAD_FILTER_INITIAL_VALUES'),
    FILTER_INITIAL_VALUES_LOADED: type('TRADES:FILTER_INITIAL_VALUES_LOADED'),
    LOAD_FILTER_INITIAL_VALUES_FAILED: type('TRADES:LOAD_FILTER_INITIAL_VALUES_FAILED'),
};

/** LoadFilterStructureAction **/

export interface LoadFilterStructureAction extends Action {
    payload: {
        productTypeId: string;
    };
}

export function loadFilterStructure(productTypeId: string): LoadFilterStructureAction {
    return {
        type: FilterActionTypes.LOAD_FILTER_STRUCTURE,
        payload: {
            productTypeId,
        },
    };
}

/** FilterStructureLoadedAction **/

export interface FilterStructureLoadedAction extends Action {
    payload: {
        productTypeId: string;
        config: FilterStructureConfig;
    };
}

export function filterStructureLoaded(productTypeId: string,
                                      config: FilterStructureConfig): FilterStructureLoadedAction {
    return {
        type: FilterActionTypes.FILTER_STRUCTURE_LOADED,
        payload: {
            productTypeId,
            config,
        },
    };
}

/** LoadFilterStructureFailedAction **/

export interface LoadFilterStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadFilterStructureFailed(error: AppError): LoadFilterStructureFailedAction {
    return {
        type: FilterActionTypes.LOAD_FILTER_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

// ----

/** LoadFilterAvailableValuesAction **/

export interface LoadFilterAvailableValuesAction extends Action {
    payload: {
        productTypeId: string;
    };
}

export function loadFilterAvailableValues(productTypeId: string): LoadFilterAvailableValuesAction {
    return {
        type: FilterActionTypes.LOAD_FILTER_AVAILABLE_VALUES,
        payload: {
            productTypeId,
        },
    };
}

/** FilterAvailableValuesLoadedAction **/

export interface FilterAvailableValuesLoadedAction extends Action {
    payload: {
        productTypeId: string;
        availableValues: FormValues;
    };
}

export function filterAvailableValuesLoaded(productTypeId: string,
                                            availableValues: FormValues): FilterAvailableValuesLoadedAction {
    return {
        type: FilterActionTypes.FILTER_AVAILABLE_VALUES_LOADED,
        payload: {
            productTypeId,
            availableValues,
        },
    };
}

/** LoadFilterAvailableValuesFailedAction **/

export interface LoadFilterAvailableValuesFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadFilterAvailableValuesFailed(error: AppError): LoadFilterAvailableValuesFailedAction {
    return {
        type: FilterActionTypes.LOAD_FILTER_AVAILABLE_VALUES_FAILED,
        payload: {
            error,
        },
    };
}

// ----

/** LoadFilterInitialValuesAction **/

export interface LoadFilterInitialValuesAction extends Action {
    payload: {
        productTypeId: string;
    };
}

export function loadFilterInitialValues(productTypeId: string): LoadFilterInitialValuesAction {
    return {
        type: FilterActionTypes.LOAD_FILTER_INITIAL_VALUES,
        payload: {
            productTypeId,
        },
    };
}

/** FilterInitialValuesLoadedAction **/

export interface FilterInitialValuesLoadedAction extends Action {
    payload: {
        productTypeId: string;
        initialValues: FormValues;
    };
}

export function filterInitialValuesLoaded(productTypeId: string,
                                          initialValues: FormValues): FilterInitialValuesLoadedAction {
    return {
        type: FilterActionTypes.FILTER_INITIAL_VALUES_LOADED,
        payload: {
            productTypeId,
            initialValues,
        },
    };
}

/** LoadFilterInitialValuesFailedAction **/

export interface LoadFilterInitialValuesFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadFilterInitialValuesFailed(error: AppError): LoadFilterInitialValuesFailedAction {
    return {
        type: FilterActionTypes.LOAD_FILTER_INITIAL_VALUES_FAILED,
        payload: {
            error,
        },
    };
}
