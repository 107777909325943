<div class="tw-w-38 tw-min-h-[110px] tw-flex tw-flex-col tw-items-center tw-text-center">
    <ng-container *ngIf="item.link">
        <a
            class="tw-flex tw-flex-col tw-items-center tw-no-underline"
            [class.tw-opacity-50]="insideInlineMenu ? !rla.isActive && !currentDepth : !active && !currentDepth"
            routerLinkActive
            #rla="routerLinkActive"
            [routerLink]="item.link">
            <ng-template *ngTemplateOutlet="innerAction; context:{ $implicit: rla.isActive }" />
        </a>
    </ng-container>
    <ng-container *ngIf="!item.link">
        <button
            type="button"
            class="tw-flex tw-flex-col tw-items-center"
            [class.tw-opacity-50]="!active && !currentDepth && !hasActiveSubmenu"
            (click)="handleMenuClick()">
            <ng-template *ngTemplateOutlet="innerAction" />
        </button>
    </ng-container>
    <ng-template #innerAction let-rlaIsActive>
        <ng-container *ngIf="item.icon">
            <app-icon 
                class="menu-item__icon tw-text-primary-base tw-flex tw-mx-auto tw-my-3.75 tw-cursor-pointer"
                [class.dark:tw-text-white]="!insideInlineMenu"
                [class.dark:tw-text-primary-base]="insideInlineMenu"
                [icon]="item.icon" />
        </ng-container>
        <span
            class="tw-text-black tw-cursor-pointer"
            [class.dark:dark:tw-text-white]="!insideInlineMenu"
            [class.dark:tw-text-old-black]="insideInlineMenu">{{ item.label }}</span>
        <ng-container *ngIf="active || (insideInlineMenu && (rlaIsActive || hasActiveSubmenu))">
            <span
                class="tw-w-2.5 tw-h-2.5 tw-mt-3.75 tw-mx-auto tw-rounded-full tw-bg-primary-base"
                [class.dark:tw-bg-white]="!insideInlineMenu"
                [class.dark:tw-bg-primary-base]="insideInlineMenu"></span>
        </ng-container>
    </ng-template>
    <div
        *ngIf="item.hasSubmenu && (active || (hasActiveSubmenu && insideInlineMenu))"
        class="tw-border-t tw-border-solid tw-border-black"
        [class.dark:tw-border-white]="!insideInlineMenu"
        [class.dark:tw-border-old-black]="insideInlineMenu"
        [@submenuState]="'subMenu'">
        <app-submenu-item
            *ngFor="let subitem of item.children let subitemIndex = index;"
            [item]="subitem"
            [hasActiveSibling]="hasActiveSubmenu"
            [insideInlineMenu]="insideInlineMenu"
            (click)="onSubmenuItemClicked(subitemIndex)" />
    </div>
</div>
