<div
    class="card entity-widget__card tw-rounded tw-shadow dark:tw-rounded-none"
    [ngClass]="widgetCardStyles"
    [class.entity-widget__card--drag-over]="hasDragOver && hasFileHandler">

    <div class="card-block">
        <h3
            *ngIf="title && !disableTitle && type !== 'combined'"
            class="tw-text-lg tw-uppercase tw-mb-5 tw-text-black dark:tw-text-base dark:tw-text-old-black">{{ title }}</h3>
        <h5 *ngIf="subTitle && !disableTitle && type !== 'combined'" class="card-subtitle">{{subTitle}}</h5>

        <div class="card-block__container" [hidden]="isLoadingData">
            <ng-template #widgetContent></ng-template>
        </div>

        <div *ngIf="isLoadingData" [ngStyle]="{ display: 'flex', flex: 1, 'align-items': 'center', 'justify-content': 'center' }">
            <app-spinner type="small-boxes"></app-spinner>
        </div>
        <div *ngIf="infoText" class="widget-info-text">{{infoText}}</div>
    </div>
</div>
