export const filterStructure = {
    structure: {
        common: {
            areas: [
                {
                    sections: [
                        {
                            columns: [
                                {
                                    controlGroups: [
                                        {
                                            title: 'Index',
                                            controls: [
                                                {
                                                    disabled: false,
                                                    options: {},
                                                    path: 'index',
                                                    styleHints: [],
                                                    type: 'dropdownSelect',
                                                    values: 'index',
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    controlGroups: [
                                        {
                                            title: 'Ansicht',
                                            controls: [
                                                {
                                                    disabled: false,
                                                    options: {},
                                                    path: 'view',
                                                    styleHints: [],
                                                    type: 'dropdownSelect',
                                                    values: 'view',
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    controlGroups: [
                                        {
                                            title: 'Volatilitäten nach',
                                            controls: [
                                                {
                                                    disabled: false,
                                                    options: {
                                                        conditionPath: 'view',
                                                        conditionValues: [
                                                            'volatility',
                                                        ],
                                                    },
                                                    path: 'volaType',
                                                    styleHints: [],
                                                    type: 'dropdownSelect',
                                                    values: 'volaType',
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    controlGroups: [
                                        {
                                            title: 'Basispreis',
                                            controls: [
                                                {
                                                    disabled: false,
                                                    options: {
                                                        conditionPath: 'volaType',
                                                        conditionValues: [
                                                            'moneyness',
                                                        ],
                                                    },
                                                    path: 'moneyness',
                                                    styleHints: [],
                                                    type: 'dropdownSelect',
                                                    values: 'moneyness',
                                                },
                                            ],
                                        },
                                        {
                                            title: 'Laufzeiten',
                                            controls: [
                                                {
                                                    disabled: false,
                                                    options: {
                                                        conditionPath: 'volaType',
                                                        conditionValues: [
                                                            'ttm',
                                                        ],
                                                    },
                                                    path: 'ttm',
                                                    styleHints: [],
                                                    type: 'dropdownSelect',
                                                    values: 'ttm',
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    },
    values: {
        index: [
            {
                id: 'DAX_30',
                label: 'DAX 40',
            },
            {
                id: 'EURO_STOXX_50',
                label: 'EURO STOXX 50',
            },
            {
                id: 'MDAX',
                label: 'MDAX',
            },
        ],
        view: [
            {
                id: 'dividends',
                label: 'Dividenden',
            },
            {
                id: 'volatility',
                label: 'Volatilitäten',
            },
        ],
        volaType: [
            {
                id: 'ttm',
                label: 'Laufzeit',
            },
            {
                id: 'moneyness',
                label: 'Basispreis',
            },
        ],
        ttm: [
            {
                id: 'MONTHS_3',
                label: '3 Monate',
            },
            {
                id: 'MONTHS_6',
                label: '6 Monate',
            },
            {
                id: 'MONTHS_9',
                label: '9 Monate',
            },
            {
                id: 'MONTHS_12',
                label: '1 Jahr',
            },
            {
                id: 'MONTHS_15',
                label: '15 Monate',
            },
            {
                id: 'MONTHS_18',
                label: '18 Monate',
            },
            {
                id: 'MONTHS_24',
                label: '2 Jahre',
            },
        ],
        moneyness: [
            {
                id: '0',
                label: '70%',
            },
            {
                id: '1',
                label: '80%',
            },
            {
                id: '2',
                label: '90%',
            },
            {
                id: '3',
                label: '100%',
            },
            {
                id: '4',
                label: '110%',
            },
            {
                id: '5',
                label: '120%',
            },
            {
                id: '6',
                label: '130%',
            },
        ],
    },
} as any;
