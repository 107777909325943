export function rateLimit<F extends (...args) => any>(func: F, interval: number = 500, rate: number = 5, action?: (...args) => any): F {
    let numberOfCalls = 0;
    let rateIntervalStartTime = 0;

    return function(this: any, ...args: any[]) {
        const currentTime = new Date().getMilliseconds();
        const timeDiff = currentTime - rateIntervalStartTime;

        numberOfCalls++;

        if (timeDiff > interval && numberOfCalls <= rate) {
            rateIntervalStartTime = currentTime;
            numberOfCalls = 0;
        } else if (timeDiff <= interval && numberOfCalls >= rate) {
            numberOfCalls = 0;

            if (action) {
                return action();
            }
        }

        return func.apply(this, args);
    } as any as F;
}
