/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 4/25/17.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../services/configuration.service';
import { LogglyLoggerService } from '../services/loggly-logger.service';
import { BaseApi } from './base.api';
import { GetTokenResponse } from './models/auth.model';

/**
 * API abstraction layer for the Auth API
 */
@Injectable({
    providedIn: 'root',
})
export class AuthApi extends BaseApi {
    constructor(http: HttpClient, loggerService: LogglyLoggerService, private appConfigService: ConfigurationService) {
        super(appConfigService.configuration.api.duse.baseUrl, http, loggerService);
    }

    /**
     * Get a token (optionally with refresh token) from the Auth API by means of user credentials
     * @param username
     * @param password
     * @param refreshToken
     * @returns {Observable<GetTokenResponse>}
     */
    public getTokenUsingCredentials(username: string, password: string, refreshToken = true): Observable<GetTokenResponse> {
        const headers = {};

        if (
            this.appConfigService.configuration.environment.organizationId &&
            this.appConfigService.configuration.environment.organizationId.length
        ) {
            headers['x-dericon-org'] = this.appConfigService.configuration.environment.organizationId;
        }

        return this.get('auth/token', {
            params: {
                includeRefreshToken: JSON.stringify(refreshToken),
            },
            headers,
            basicAuth: {
                username,
                password,
            },
        });
    }

    public getTokenUsingRedirectToken(redirectToken: string): Observable<GetTokenResponse> {
        return this.get('auth/token/redirect', {
            params: {
                redirectToken,
            },
        });
    }

    public getRedirectTokenUsingRefreshToken(refreshToken: string): Observable<string> {
        return this.post('auth/token/redirect', refreshToken, {responseType: 'text'});
    }

    /**
     * Get a token from the Auth API by means of refresh token
     * @param refreshToken
     * @returns {Observable<GetTokenResponse>}
     */
    public getTokenUsingRefreshToken(refreshToken: string): Observable<GetTokenResponse> {
        return this.get('auth/token/refresh', {
            params: {
                refreshtoken: refreshToken,
            },
        });
    }

    /**
     * Validate a given token against the Auth API
     * @param token
     * @returns {Observable<GetTokenResponse>}
     */
    public validateToken(token: string): Observable<GetTokenResponse> {
        return this.get('auth/token/validate', {
            params: {
                token,
            },
        });
    }
}
