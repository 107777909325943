import { Action } from '@ngrx/store';
import {
    AddOfferRequesFailureAction, ChangeOfferResponseAssigneeAction, ChangeOfferResponseAssigneeFailureAction, ChangeOfferResponseAssigneeSuccessAction, DeclineOfferRequesFailureAction,
    LoadRfqIssuerQuoteFailureAction,
    LoadRfqIssuerQuoteSuccessAction, LoadRfqIssuerResolvedQuotesFailureAction, LoadRfqIssuerResolvedQuotesSuccessAction, LoadRfqIssuerTableStructureFailedAction,
    LoadRfqIssuerTableStructureSucceededAction, LoadRfqIssuerTableValuesFailedAction, LoadRfqIssuerTableValuesSucceededAction,
    RfqIssuerActionTypes,
} from '../actions/rfq-issuer.actions';
import { RfqIssuerState } from '../state/rfq-issuer.state';
import { mapValues } from 'lodash';
import { OfferResponse } from '../../../shared/product-designer-shared/models/product-designer.model';

export const initialState: RfqIssuerState = {
    tableStructure: null,
    tableValues: null,
    currentQuoteDataAndStructure: null,
    currentQuoteResolvedQuotes: null,

    loadingTableStructure: false,
    loadingTableValues: false,
    loadingAddOfferRequest: false,
    loadingQuote: false,
    loadingDeclineOfferRequest: false,
    loadingResolvedQuotes: false,
    loadingChangeOfferResponseAssigneeRequest: false,
    loadingBatchOfferRequest: false,

    errorLoadingQuote: null,
    errorAddOfferRequest: null,
    errorDeclineOfferRequest: null,
    errorLoadingTableValues: null,
    errorLoadingTableStructure: null,
    errorLoadingResolvedQuotes: null,
    errorChangeOfferResponseAssigneeRequest: null,
    errorBatchOfferRequest: null,

    successAddOfferRequest: null,
    successDeclineOfferRequest: null,
    successChangeOfferResponseAssigneeRequest: null,
    successBatchOfferRequest: null,
};

export function reducer(state: RfqIssuerState = initialState, action: Action): RfqIssuerState {
    switch (action.type) {
        case RfqIssuerActionTypes.LOAD_RFQ_ISSUER_TABLE_STRUCTURE: {
            return {
                ...state,
                loadingTableStructure: true,
            };
        }

        case RfqIssuerActionTypes.LOAD_RFQ_ISSUER_TABLE_STRUCTURE_SUCCEEDED: {
            const loadRfqIssuerTableStructureSucceededAction = action as LoadRfqIssuerTableStructureSucceededAction;

            return {
                ...state,
                loadingTableStructure: false,
                tableStructure: loadRfqIssuerTableStructureSucceededAction.payload.structure,
            };
        }

        case RfqIssuerActionTypes.LOAD_RFQ_ISSUER_TABLE_STRUCTURE_FAILED: {
            const loadRfqIssuerTableStructureFailedAction = action as LoadRfqIssuerTableStructureFailedAction;

            return {
                ...state,
                loadingTableStructure: false,
                errorLoadingTableStructure: loadRfqIssuerTableStructureFailedAction.payload.error,
            };
        }

        case RfqIssuerActionTypes.LOAD_RFQ_ISSUER_TABLE_VALUES: {
            return {
                ...state,
                loadingTableValues: true,
            };
        }

        case RfqIssuerActionTypes.LOAD_RFQ_ISSUER_TABLE_VALUES_SUCCEEDED: {
            const loadRfqIssuerTableValuesSucceededAction = action as LoadRfqIssuerTableValuesSucceededAction;

            return {
                ...state,
                loadingTableValues: false,
                tableValues: loadRfqIssuerTableValuesSucceededAction.payload.entities,
            };
        }

        case RfqIssuerActionTypes.LOAD_RFQ_ISSUER_TABLE_VALUES_FAILED: {
            const loadRfqIssuerTableValuesFailedAction = action as LoadRfqIssuerTableValuesFailedAction;

            return {
                ...state,
                loadingTableValues: false,
                errorLoadingTableStructure: loadRfqIssuerTableValuesFailedAction.payload.error,
            };
        }

        case RfqIssuerActionTypes.LOAD_RFQ_ISSUER_QUOTE: {
            return {
                ...state,
                loadingQuote: true,
                errorLoadingQuote: null,
            };
        }

        case RfqIssuerActionTypes.RESET_RFQ_ISSUER_QUOTE: {
            return {
                ...state,
                currentQuoteDataAndStructure: null,

                loadingAddOfferRequest: false,
                loadingDeclineOfferRequest: false,
                loadingResolvedQuotes: false,
                loadingChangeOfferResponseAssigneeRequest: false,
                loadingBatchOfferRequest: false,

                errorAddOfferRequest: null,
                errorDeclineOfferRequest: null,
                errorLoadingResolvedQuotes: null,
                errorChangeOfferResponseAssigneeRequest: null,
                errorBatchOfferRequest: null,

                successAddOfferRequest: null,
                successDeclineOfferRequest: null,
                successChangeOfferResponseAssigneeRequest: null,
                successBatchOfferRequest: null,
            };
        }

        case RfqIssuerActionTypes.LOAD_RFQ_ISSUER_QUOTE_SUCCEEDED: {
            const loadRfqIssuerQuoteSucceededAction = action as LoadRfqIssuerQuoteSuccessAction;

            return {
                ...state,
                currentQuoteDataAndStructure: loadRfqIssuerQuoteSucceededAction.payload.data,
                loadingQuote: false,
                errorLoadingQuote: null,
            };
        }

        case RfqIssuerActionTypes.LOAD_RFQ_ISSUER_QUOTE_FAILED: {
            const loadRfqIssuerQuoteFailedAction = action as LoadRfqIssuerQuoteFailureAction;

            return {
                ...state,
                loadingQuote: false,
                errorLoadingQuote: loadRfqIssuerQuoteFailedAction.payload.error,
            };
        }

        case RfqIssuerActionTypes.LOAD_RFQ_ISSUER_RESOLVED_QUOTES: {
            return {
                ...state,
                loadingResolvedQuotes: true,
                errorLoadingResolvedQuotes: null,
            };
        }

        case RfqIssuerActionTypes.LOAD_RFQ_ISSUER_RESOLVED_QUOTES_SUCCEEDED: {
            const loadRfqIssuerResolvedQuotesSucceededAction = action as LoadRfqIssuerResolvedQuotesSuccessAction;

            return {
                ...state,
                currentQuoteResolvedQuotes: loadRfqIssuerResolvedQuotesSucceededAction.payload.quotes,
                loadingResolvedQuotes: false,
                errorLoadingResolvedQuotes: null,
            };
        }

        case RfqIssuerActionTypes.LOAD_RFQ_ISSUER_RESOLVED_QUOTES_FAILED: {
            const loadRfqIssuerResolvedQuotesFailedAction = action as LoadRfqIssuerResolvedQuotesFailureAction;

            return {
                ...state,
                loadingResolvedQuotes: false,
                errorLoadingResolvedQuotes: loadRfqIssuerResolvedQuotesFailedAction.payload.error,
            };
        }

        case RfqIssuerActionTypes.ADD_OFFER_REQUEST: {
            return {
                ...state,
                loadingAddOfferRequest: true,
                errorAddOfferRequest: null,
                successAddOfferRequest: null,
                successDeclineOfferRequest: null,
            };
        }

        case RfqIssuerActionTypes.ADD_OFFER_REQUEST_SUCCESS: {
            return {
                ...state,
                loadingAddOfferRequest: false,
                errorAddOfferRequest: null,
                successAddOfferRequest: true,
            };
        }

        case RfqIssuerActionTypes.ADD_OFFER_REQUEST_FAILURE: {
            const addOfferRequestFailureAction = action as AddOfferRequesFailureAction;
            return {
                ...state,
                loadingAddOfferRequest: false,
                errorAddOfferRequest: addOfferRequestFailureAction.payload.error,
                successAddOfferRequest: false,
            };
        }

        case RfqIssuerActionTypes.BATCH_OFFER_REQUEST: {
            return {
                ...state,
                loadingBatchOfferRequest: true,
                errorBatchOfferRequest: null,
                successBatchOfferRequest: null,
            };
        }

        case RfqIssuerActionTypes.BATCH_OFFER_REQUEST_SUCCESS: {
            return {
                ...state,
                loadingBatchOfferRequest: false,
                errorBatchOfferRequest: null,
                successBatchOfferRequest: true,
            };
        }

        case RfqIssuerActionTypes.BATCH_OFFER_REQUEST_FAILURE: {
            return {
                ...state,
                loadingBatchOfferRequest: false,
                errorBatchOfferRequest: null,
                successBatchOfferRequest: false,
            };
        }
        case RfqIssuerActionTypes.DECLINE_OFFER_REQUEST: {
            return {
                ...state,
                loadingDeclineOfferRequest: true,
                errorDeclineOfferRequest: null,
                successDeclineOfferRequest: null,
                successAddOfferRequest: null,
            };
        }

        case RfqIssuerActionTypes.DECLINE_OFFER_REQUEST_SUCCESS: {
            return {
                ...state,
                loadingDeclineOfferRequest: false,
                errorDeclineOfferRequest: null,
                successDeclineOfferRequest: true,
            };
        }

        case RfqIssuerActionTypes.DECLINE_OFFER_REQUEST_FAILURE: {
            const declineOfferRequestFailureAction = action as DeclineOfferRequesFailureAction;
            return {
                ...state,
                loadingDeclineOfferRequest: false,
                errorDeclineOfferRequest: declineOfferRequestFailureAction.payload.error,
                successDeclineOfferRequest: false,
                loadingAddOfferRequest: false,
            };
        }

        case RfqIssuerActionTypes.CHANGE_OFFER_RESPONSE_ASSIGNEE_REQUEST: {
            return {
                ...state,
                loadingChangeOfferResponseAssigneeRequest: true,
                errorChangeOfferResponseAssigneeRequest: null,
                successChangeOfferResponseAssigneeRequest: null,
            };
        }

        case RfqIssuerActionTypes.CHANGE_OFFER_RESPONSE_ASSIGNEE_REQUEST_SUCCESS: {
            const changeOfferResponseAssigneeSuccessAction = action as ChangeOfferResponseAssigneeSuccessAction;

            let additionalUpdate = {};
            let didResponseUpdate = false;
            const updatedResponses = mapValues(state.currentQuoteDataAndStructure.quote.responses, (responses: OfferResponse[]) => {
                return responses.map((response) => {
                    if (response.id.responseId === changeOfferResponseAssigneeSuccessAction.payload.data.id.responseId) {
                        didResponseUpdate = true;

                        return {
                            ...response,
                            assignee: changeOfferResponseAssigneeSuccessAction.payload.data.assigneeId,
                        };
                    }

                    return response;
                });
            });

            if (didResponseUpdate) {
                additionalUpdate = {
                    currentQuoteDataAndStructure: {
                        ...state.currentQuoteDataAndStructure,
                        quote: {
                            ...state.currentQuoteDataAndStructure.quote,
                            responses: {
                                ...updatedResponses,
                            },
                        },
                    },
                };
            }

            return {
                ...state,
                loadingChangeOfferResponseAssigneeRequest: false,
                errorChangeOfferResponseAssigneeRequest: null,
                successChangeOfferResponseAssigneeRequest: true,
                ...additionalUpdate,
            };
        }

        case RfqIssuerActionTypes.CHANGE_OFFER_RESPONSE_ASSIGNEE_REQUEST_FAILURE: {
            const changeOfferResponseAssigneeFailureAction = action as ChangeOfferResponseAssigneeFailureAction;

            return {
                ...state,
                loadingChangeOfferResponseAssigneeRequest: false,
                errorChangeOfferResponseAssigneeRequest: changeOfferResponseAssigneeFailureAction.payload.error,
                successChangeOfferResponseAssigneeRequest: false,
            };
        }

        default:
            return state;
    }
}
