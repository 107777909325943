import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { Entity } from '../../models/entities.model';
import { EntityTableCellComponent } from '../entity-table-component-cell/entity-table-component-cell.component';

import { get } from 'lodash';
import { Router, RouterLink } from '@angular/router';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'app-entity-table-factsheet-cell',
    templateUrl: './entity-table-factsheet-cell.component.html',
    standalone: true,
    imports: [CommonModule, RouterLink, ClipboardModule]
})
export class EntityTableFactsheetCellComponent implements OnInit, OnChanges, EntityTableCellComponent {

    @Input() public product: Entity;
    @Input() public options: any;
    @Input() public path: string;

    public get factsheetLink(): string {
        return this.document.location.origin + this.router.createUrlTree(this.factsheetPath);
    }

    public get factsheetPath(): any[] {
        return ['/app', {'outlets': {'overlay': ['product-details', this.id]}}];
    }

    public label: string;
    public id: string;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private router: Router,
        private toastrService: ToastrService,
        private translotoService: TranslocoService
    ) { }

    public ngOnInit() { }

    public ngOnChanges() {
        this.label = get(this.product, this.path);
        this.id = get(this.product, this.options.idPath);
    }

    public onFactsheetLinkCopied(event: boolean) {
        if (event) {
            this.toastrService.show(
                this.translotoService.translate('factsheet-cell.copiedToClipboardMessage')
            );
        }
    }
}
