import { get } from 'lodash';
import { Entity, EntitySortSetting } from '../../../../shared/models/entities.model';

/**
 * Sort a list of products according to the specified sort settings
 * @param {Entity[]} products
 * @param {EntitySortSetting} sort
 * @returns {Entity[]}
 */
export function sortEntities(products: Entity[], sort: EntitySortSetting) {
    if (sort) {
        const stringSort = products.some(
            (product) => typeof get(product, sort.path) === 'string'
        );

        return [...products].sort((prod1, prod2) => {
            const prod1Val = get(prod1, sort.path, '') as any;
            const prod2Val = get(prod2, sort.path, '') as any;

            if (stringSort) {
                const val1 = prod1Val === null ? '' : prod1Val as string;
                const val2 = prod2Val === null ? '' : prod2Val as string;
                return val1.localeCompare(val2) * ((sort.order === 'DESC') ? -1 : 1);
            } else {
                return (prod1Val - prod2Val) * ((sort.order === 'DESC') ? -1 : 1);
            }
        });
    } else {
        return products;
    }
}
