<form [formGroup]="widgetForm">
    <div class="card-form__section">
        <h4 class="card-headline">Empfehlung</h4>
        <div class="form-group">
            <app-recommendation-selector formControlName="type"></app-recommendation-selector>
        </div>

        <h4 class="card-headline">Datum</h4>
        <div class="form-group">
            <app-date-input [placeholder]="'general.date.placeholder' | transloco" formControlName="targetPricePublishingDate"></app-date-input>
        </div>

        <h4 class="card-headline">Kurswert</h4>
        <div class="form-group">
            <app-number-input class="form-control" [placeholder]="'Kurswert'" formControlName="targetPriceValue"></app-number-input>
        </div>

        <h4 class="card-headline">Währung</h4>
        <div class="form-group">
            <input type="text" class="form-control" [placeholder]="'Währung'" formControlName="targetPriceCurrency" />
        </div>
    </div>
</form>

<button
    type="button"
    chroma-button
    (click)="onClick()">Speichern</button>
