import { Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormControlConfig, FormControlSelectOption, FormValues } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';
import { NumberInputComponent } from '../number-input/number-input.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-filter-double-dropdown-input',
    templateUrl: './filter-double-dropdown-input.component.html',
    styleUrls: ['./filter-double-dropdown-input.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, MatTooltipModule, NgFor, NumberInputComponent]
})
export class FilterDoubleDropdownInputComponent implements OnInit, OnChanges, OnDestroy, SingleValueFilterControl<any> {

    @HostBinding('class.double-dropdown-input') public doubleDropdownInputClass = true;

    @Input() public config: FormControlConfig<FilterDoubleDropdownInputOptions>;

    @Input() public values: any;

    @Input() public disabled = false;

    public onChange: (value: any) => void;

    public onTouched: () => void;

    public form: UntypedFormGroup;

    public valueChangesSubscription: Subscription;

    get operatorValues(): FormControlSelectOption[] {
        return (this.values && this.config && this.config.options && this.config.options.paths)
            ? this.values[this.config.options.paths.operator] : [];
    }

    get horizonValues(): FormControlSelectOption[] {
        return (this.values && this.config && this.config.options && this.config.options.paths)
            ? this.values[this.config.options.paths.horizon] : [];
    }

    constructor(private fb: UntypedFormBuilder) {
    }

    public ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.config && this.config) {
            this._updateForm();
        }
        if ((changes.config || changes.values) && this.values) {
            this._updateDefaultValues();
        }
    }

    public ngOnDestroy() {
        if (this.valueChangesSubscription) { this.valueChangesSubscription.unsubscribe(); }
    }

    public writeValue(values: FormValues): void {
        if (values) {
            this.form.patchValue(values, {emitEvent: false});
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = (value) => {
            if (fn) {
                fn(value);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * Checks if the filter is valid
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this.form.valid) ? null : {
            doubleDropdownInputError: 'Invalid filter state specified',
        };
    }

    public setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    private _updateForm() {
        if (this.valueChangesSubscription) { this.valueChangesSubscription.unsubscribe(); }
        const fields = {};

        fields[
            (this.config.options && this.config.options.paths && this.config.options.paths.operator)
                ? this.config.options.paths.operator
                : 'operator'
            ] = [null];

        fields[
            (this.config.options && this.config.options.paths && this.config.options.paths.input)
                ? this.config.options.paths.input
                : 'input'
            ] = [''];

        if (this.config.options && this.config.options.displaySecondaryDropdown) {
            fields[
                (this.config.options && this.config.options.paths && this.config.options.paths.horizon)
                    ? this.config.options.paths.horizon
                    : 'horizon'
                ] = [];
        }

        const oldValue = (this.form) ? this.form.value : undefined;
        this.form = this.fb.group(fields);
        if (oldValue) { this.form.patchValue(oldValue); }
        this.valueChangesSubscription = this.form.valueChanges.subscribe((value) => this.onChange(value));
    }

    private _updateDefaultValues() {
        if (this._hasPaths() && this.config.options.displaySecondaryDropdown) {
            const horizonControl = this.form.get(this.config.options.paths.horizon);
            if (horizonControl.value === undefined || horizonControl.value === null) {
                this.form.patchValue({
                    [this.config.options.paths.horizon]: this.horizonValues[0].id,
                });
            }
        }
    }

    private _hasPaths() {
        return this.config && this.config.options && this.config.options.paths;
    }

}

export interface FilterDoubleDropdownInputOptions {
    label: string;
    helpText?: string;
    unit?: string;
    sampleInput: string;
    displaySecondaryDropdown: boolean;
    paths: {
        input: string;
        horizon?: string;
        operator: string;
    };
}
