<ngx-charts-chart
    [view]="[width, height]"
    [showLegend]="legend"
    [legendOptions]="legendOptions"
    [activeEntries]="activeEntries"
    [animations]="animations"
    (legendLabelClick)="onClick($event)"
    (legendLabelActivate)="onActivate($event)"
    (legendLabelDeactivate)="onDeactivate($event)"
>
    <svg:defs>
        <svg:clipPath [attr.id]="clipPathId">
            <svg:rect
                [attr.width]="dims.width + 10"
                [attr.height]="dims.height + 10"
                [attr.transform]="'translate(-5, -5)'"
            />
        </svg:clipPath>
    </svg:defs>
    <svg:g [attr.transform]="transform" class="line-chart chart">
        <svg:g
            app-charts-x-axis
            *ngIf="xAxis"
            [minimal]="minimal"
            [xScale]="xScale"
            [dims]="dims"
            [showGridLines]="showGridLines"
            [showLabel]="showXAxisLabel"
            [labelText]="xAxisLabel"
            [trimTicks]="trimXAxisTicks"
            [rotateTicks]="rotateXAxisTicks"
            [maxTickLength]="maxXAxisTickLength"
            [tickFormatting]="xAxisTickFormatting"
            [ticks]="xAxisTicks"
            (dimensionsChanged)="updateXAxisHeight($event)"
        ></svg:g>
        <svg:g
            app-charts-y-axis
            *ngIf="yAxis"
            [minimal]="minimal"
            [yScale]="yScale"
            [dims]="dims"
            [showGridLines]="showGridLines"
            [showLabel]="showYAxisLabel"
            [labelText]="yAxisLabel"
            [trimTicks]="trimYAxisTicks"
            [maxTickLength]="maxYAxisTickLength"
            [tickFormatting]="yAxisTickFormatting"
            [ticks]="yAxisTicks"
            [referenceLines]="referenceLines"
            [showRefLines]="showRefLines"
            [showRefLabels]="showRefLabels"
            (dimensionsChanged)="updateYAxisWidth($event)"
        ></svg:g>
        <svg:g [attr.clip-path]="clipPath">
            <svg:g *ngFor="let series of results; trackBy: trackBy" [@animationState]="'active'">
                <svg:g
                    ngx-charts-line-series
                    [xScale]="xScale"
                    [yScale]="yScale"
                    [colors]="colors"
                    [data]="series"
                    [activeEntries]="activeEntries"
                    [scaleType]="scaleType"
                    [curve]="curve"
                    [rangeFillOpacity]="rangeFillOpacity"
                    [hasRange]="hasRange"
                    [animations]="animations"
                />
            </svg:g>
            <svg:g *ngIf="!tooltipDisabled" (mouseleave)="hideCircles()">
                <svg:g
                    ngx-charts-tooltip-area
                    [dims]="dims"
                    [xSet]="xSet"
                    [xScale]="xScale"
                    [yScale]="yScale"
                    [results]="results"
                    [colors]="colors"
                    [tooltipDisabled]="tooltipDisabled"
                    [tooltipTemplate]="seriesTooltipTemplate"
                    (hover)="updateHoveredVertical($event)"
                />
                <svg:g *ngFor="let series of results">
                    <svg:g
                        ngx-charts-circle-series
                        [xScale]="xScale"
                        [yScale]="yScale"
                        [colors]="colors"
                        [data]="series"
                        [scaleType]="scaleType"
                        [visibleValue]="hoveredVertical"
                        [activeEntries]="activeEntries"
                        [tooltipDisabled]="tooltipDisabled"
                        [tooltipTemplate]="tooltipTemplate"
                        (select)="onClick($event)"
                        (activate)="onActivate($event)"
                        (deactivate)="onDeactivate($event)"
                    />
                </svg:g>
            </svg:g>
        </svg:g>
    </svg:g>
    <svg:g
        ngx-charts-timeline
        *ngIf="timeline && scaleType !== 'ordinal'"
        [attr.transform]="timelineTransform"
        [results]="results"
        [view]="[timelineWidth, height]"
        [height]="timelineHeight"
        [scheme]="scheme"
        [customColors]="customColors"
        [scaleType]="scaleType"
        [legend]="legend"
        (onDomainChange)="updateDomain($event)"
    >
        <svg:g *ngFor="let series of results; trackBy: trackBy">
            <svg:g
                ngx-charts-line-series
                [xScale]="timelineXScale"
                [yScale]="timelineYScale"
                [colors]="colors"
                [data]="series"
                [scaleType]="scaleType"
                [curve]="curve"
                [hasRange]="hasRange"
                [animations]="animations"
            />
        </svg:g>
    </svg:g>
</ngx-charts-chart>
