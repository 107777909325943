import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiOptions } from '../../../../core/api/base.api';
import { DerifinApi } from '../../../../core/api/derifin.api';
import { ApiResponse } from '../../../../core/api/models/api.model';
import { AuthStoreService } from '../../../../core/services/auth-store.service';
import { ConfigurationService } from '../../../../core/services/configuration.service';
import { LogglyLoggerService } from '../../../../core/services/loggly-logger.service';
import { WatchlistData, WatchlistStructures } from '../models/watchlist.model';
import { ShortListResponse, WatchListDataResponse } from './models/watchlist.model';
import { transformShortlistResponse, transformWatchlistDataResponse, transformWatchlistStructures } from './watchlist.transform';
import { set } from 'lodash';

/**
 * API abstraction layer for the Shortlist API
 */
@Injectable()
export class ShortListApi extends DerifinApi {

    constructor(http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, appConfigService: ConfigurationService) {
        super('m/shortlist', appConfigService.configuration.environment.id, http, authStoreService, loggerService, appConfigService);
    }

    /**
     * Get the shortlist structures
     * @param {string} id
     * @returns {Observable<WatchlistStructures>}
     */
    public getShortListStructures(id: string): Observable<WatchlistStructures> {
        return this.get(`${id}/structure`).pipe(
            map((response: ApiResponse<WatchlistStructures>) => {
                return {
                    structures: transformWatchlistStructures(id, response),
                    actions: response.data.actions,
                    title: response.data.title,
                };
            }),
        );
    }

    /**
     * Get the shortlist data / shortlist products
     * @param {string} id
     * @returns {Observable<any>}
     */
    public getShortListData(id: string): Observable<WatchlistData> {
        return this.get(`${id}/products`).pipe(
            map((response: ApiResponse<WatchListDataResponse>) => transformWatchlistDataResponse(id, response)),
        );
    }

    public getShortList(id: string, tmc?: string): Observable<ShortListResponse> {
        const options: ApiOptions = {};

        if (tmc) {
            options.params = {
                tmc,
            };
        }
        return this.get(id, options).pipe(
            map((response: ApiResponse<ShortListResponse>) => transformShortlistResponse(response.data)),
            /* map( (response) => {
                set(response, 'structure.layers.first.collapsible', false);
                set(response, 'structure.layers.first.collapsedByDefault', false);

                set(response, 'structure.layers.second.collapsible', true);
                set(response, 'structure.layers.second.collapsedByDefault', false);

                return response;
            }) */
        );
    }
}
