import { Action } from '@ngrx/store';
import { AppError } from '../../../../../core/errors/base.errors';
import { FormValues } from '../../../../../core/models/form.model';
import { type } from '../../../../../core/store/util';
import { ProductActivation, ProductNotes } from '../../models/product-shared.model';

export const ProductSharedActionTypes = {
    UPDATE_PRODUCT_ACTIVATION: type('PRODUCT_SHARED:UPDATE_PRODUCT_ACTIVATION'),
    UPDATE_PRODUCT_ACTIVATION_SUCCEEDED: type('PRODUCT_SHARED:UPDATE_PRODUCT_ACTIVATION_SUCCEEDED'),
    UPDATE_PRODUCT_ACTIVATION_FAILED: type('PRODUCT_SHARED:UPDATE_PRODUCT_ACTIVATION_FAILED'),

    UPDATE_PRODUCT_NOTES: type('PRODUCT_SHARED:UPDATE_PRODUCT_NOTES'),
    UPDATE_PRODUCT_NOTES_SUCCEEDED: type('PRODUCT_SHARED:UPDATE_PRODUCT_NOTES_SUCCEEDED'),
    UPDATE_PRODUCT_NOTES_FAILED: type('PRODUCT_SHARED:UPDATE_PRODUCT_NOTES_FAILED'),

    UPDATE_PRODUCT_DECORFIN_DATA: type('PRODUCT_SHARED:UPDATE_PRODUCT_DECORFIN_DATA'),
    UPDATE_PRODUCT_DECORFIN_DATA_SUCCEEDED: type('PRODUCT_SHARED:UPDATE_PRODUCT_DECORFIN_DATA_SUCCEEDED'),
    UPDATE_PRODUCT_DECORFIN_DATA_FAILED: type('PRODUCT_SHARED:UPDATE_PRODUCT_DECORFIN_DATA_FAILED'),

    UPDATE_PRODUCT_DERICON_RECOMMEND: type('PRODUCT_SHARED:UPDATE_PRODUCT_DERICON_RECOMMEND'),
    UPDATE_PRODUCT_DERICON_RECOMMEND_SUCCEEDED: type('PRODUCT_SHARED:UPDATE_PRODUCT_DERICON_RECOMMEND_SUCCEEDED'),
    UPDATE_PRODUCT_DERICON_RECOMMEND_FAILED: type('PRODUCT_SHARED:UPDATE_PRODUCT_DERICON_RECOMMEND_FAILED'),
};

export interface UpdateProductDecorfinDataAction extends Action {
    payload: {
        productDq: string;
        sessionId: string;
        brokerDecorfinId: string;
        data: FormValues;
        brokerDecorfinDataMode: string;
    };
}

export function updateProductDecorfinData(sessionId: string, productDq: string, brokerDecorfinId: string, data: FormValues, brokerDecorfinDataMode: string): UpdateProductDecorfinDataAction {
    return {
        type: ProductSharedActionTypes.UPDATE_PRODUCT_DECORFIN_DATA,
        payload: {
            sessionId,
            productDq,
            brokerDecorfinId,
            data,
            brokerDecorfinDataMode
        }
    };
}

export interface UpdateProductDecorfinDataSucceededAction extends Action {
    payload: object;
}

export function updateProductDecorfinDataSucceeded(): UpdateProductDecorfinDataSucceededAction {
    return {
        type: ProductSharedActionTypes.UPDATE_PRODUCT_DECORFIN_DATA_SUCCEEDED,
        payload: {

        },
    };
}

export interface UpdateProductDecorfinDataFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function updateProductDecorfinDataFailed(error: AppError): UpdateProductDecorfinDataFailedAction {
    return {
        type: ProductSharedActionTypes.UPDATE_PRODUCT_DECORFIN_DATA_FAILED,
        payload: {
            error,
        },
    };
}

/** UpdateProductDericonRecommend **/
export interface UpdateProductDericonRecommendAction extends Action {
    payload: {
        dq: string;
        instrumentType: string;
        data: FormValues;
    };
}

export function updateProductDericonRecommend(dq: string, instrumentType: string, data: FormValues): UpdateProductDericonRecommendAction {
    return {
        type: ProductSharedActionTypes.UPDATE_PRODUCT_DERICON_RECOMMEND,
        payload: {
            dq,
            instrumentType,
            data,
        },
    };
}

export interface UpdateProductDericonRecommendSucceededAction extends Action {
    payload: object;
}

export function updateProductDericonRecommendSucceeded(): UpdateProductDericonRecommendSucceededAction {
    return {
        type: ProductSharedActionTypes.UPDATE_PRODUCT_DERICON_RECOMMEND_SUCCEEDED,
        payload: {

        },
    };
}

export interface UpdateProductDericonRecommendFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function updateProductDericonRecommendFailed(error: AppError): UpdateProductDericonRecommendFailedAction {
    return {
        type: ProductSharedActionTypes.UPDATE_PRODUCT_DERICON_RECOMMEND_FAILED,
        payload: {
            error,
        },
    };
}

/** UpdateProductActivationAction **/

export interface UpdateProductActivationAction extends Action {
    payload: {
        productDq: string;
        dataPath: string;
        sessionId: string;
        issueSurcharge: string,
        activated: boolean,
        price: string
    };
}

export function updateProductActivation(sessionId: string,
                                        productDq: string,
                                        dataPath: string,
                                        issueSurcharge: string,
                                        activated: boolean,
                                        price: string): UpdateProductActivationAction {
    return {
        type: ProductSharedActionTypes.UPDATE_PRODUCT_ACTIVATION,
        payload: {
            sessionId,
            productDq,
            dataPath,
            issueSurcharge,
            activated,
            price
        }
    };
}

/** UpdateProductActivationSucceededAction **/

export interface UpdateProductActivationSucceededAction extends Action {
    payload: {
        productDq: string;
        dataPath: string;
        sessionId: string;
        activation: ProductActivation;
    };
}

export function updateProductActivationSucceeded(
    sessionId: string,
    productDq: string,
    dataPath: string,
    activation: ProductActivation
): UpdateProductActivationSucceededAction {
    return {
        type: ProductSharedActionTypes.UPDATE_PRODUCT_ACTIVATION_SUCCEEDED,
        payload: {
            productDq,
            dataPath,
            sessionId,
            activation
        }
    };
}

/** UpdateProductActivationFailedAction **/

export interface UpdateProductActivationFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function updateProductActivationFailed(error: AppError): UpdateProductActivationFailedAction {
    return {
        type: ProductSharedActionTypes.UPDATE_PRODUCT_ACTIVATION_FAILED,
        payload: {
            error,
        },
    };
}

/** UpdateProductNotesAction **/

export interface UpdateProductNotesAction extends Action {
    payload: {
        productDq: string;
        dataPath: string;
        sessionId: string;
        comment: string;
        promoted: boolean;
    };
}

export function updateProductNotes(sessionId: string, productDq: string,
                                   dataPath: string,
                                   comment: string, promoted: boolean): UpdateProductNotesAction {
    return {
        type: ProductSharedActionTypes.UPDATE_PRODUCT_NOTES,
        payload: {
            productDq,
            dataPath,
            sessionId,
            comment,
            promoted
        }
    };
}

/** UpdateProductNotesSucceededAction **/

export interface UpdateProductNotesSucceededAction extends Action {
    payload: {
        productDq: string;
        dataPath: string;
        sessionId: string;
        notes: ProductNotes;
    };
}

export function updateProductNotesSucceeded(
    sessionId: string,
    productDq: string,
    dataPath: string,
    notes: ProductNotes
): UpdateProductNotesSucceededAction {
    return {
        type: ProductSharedActionTypes.UPDATE_PRODUCT_NOTES_SUCCEEDED,
        payload: {
            productDq,
            dataPath,
            sessionId,
            notes
        }
    };
}

/** UpdateProductNotesFailedAction **/

export interface UpdateProductNotesFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function updateProductNotesFailed(error: AppError): UpdateProductNotesFailedAction {
    return {
        type: ProductSharedActionTypes.UPDATE_PRODUCT_NOTES_FAILED,
        payload: {
            error,
        },
    };
}
