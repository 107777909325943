<div class="row" [class.flex-row-reverse]="options.imagePosition === 'RIGHT'"
                 [class.flex-column]="options.imagePosition === 'TOP'"
                 [class.flex-column-reverse]="options.imagePosition === 'BOTTOM'">
    <div class="col image-news-widget__image-container">
        <div [class]="'image-news-widget__image image-news-widget__image--' + (options.imagePosition || 'LEFT')" [style.backgroundImage]="'url(' + image + ')'" [ngStyle]="imageStyle"></div>
    </div>
    <div class="col image-news-widget__content-container">
        <h3 class="card-title">{{ options.title }}</h3>
        <app-markdown class="card-text" [text]="text" (action)="onActionClicked($event)"></app-markdown>
    </div>
</div>
