import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppError } from '../../../../core/errors/base.errors';
import { FormValues } from '../../../../core/models/form.model';
import { ProductSharedService } from '../../../../routes/shared/product-shared/services/product-shared.service';
import { EntityWidgetDecorfinDataOptions } from '../../../models/widgets.model';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf, AsyncPipe } from '@angular/common';
import { EntityWidgetDecorfinDataFormComponent } from '../entity-widget-decorfin-data-form/entity-widget-decorfin-data-form.component';
import { ButtonComponent } from 'chroma-ui';

@Component({
    selector: 'app-entity-widget-decorfin-data',
    templateUrl: './entity-widget-decorfin-data.component.html',
    styleUrls: ['./entity-widget-decorfin-data.component.scss'],
    standalone: true,
    imports: [EntityWidgetDecorfinDataFormComponent, NgIf, AsyncPipe, TranslocoModule, ButtonComponent]
})
export class EntityWidgetDecorfinDataComponent implements OnInit, OnChanges, EntityWidgetContentComponent {

    @Input() public entityId: string;
    @Input() public width: number;

    @Input() public options: EntityWidgetDecorfinDataOptions;
    @Input() public data: any;

    @Input() public formValues: FormValues;

    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();

    public formData: FormValues = {};
    public updatedFormData: FormValues = {};
    public decorfinDataUpdateError$: Observable<AppError>;
    public decorfinDataUpdateInProgress$: Observable<boolean>;

    constructor(private readonly productSharedService: ProductSharedService) {
    }

    public ngOnInit() {
        this.decorfinDataUpdateError$ = this.productSharedService.getProductDecorfinDataUpdateError();
        this.decorfinDataUpdateInProgress$ = this.productSharedService.getProductDecorfinDataUpdated().pipe(
            map((val) => val === false),
        );
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            this.formData = {
                ...this.formData,
                ...this.data,
            };
        }
    }

    public onDecorfinDataChanged(data: FormValues): void {
        this.updatedFormData = data;
    }

    public onSave(): void {
        this.action.emit({
            type: 'save-decorfin-data',
            payload: {
                brokerDecorfinId: this.options.brokerDecorfinId,
                brokerDecorfinDataMode: this.options.mode,
                data: this.updatedFormData
            }
        });
    }
}
