<div class="mr-2 date-input__input-container">
    <input
        #input
        class="form-control"
        [matDatepicker]="datepicker"
        [formControl]="dateControl"
        (click)="onOpenDatepicker()"
        (keydown)="onInputKeyDown()"
        [placeholder]="placeholder ? placeholder : ''"
        [disabled]="disabled"
        [matDatepickerFilter]="filter"/>
</div>

<div class="mr-2">
    <app-icon
        class="date-input__calendar-icon"
        style="display:flex;"
        icon="derifin:kalender"
        (click)="onOpenDatepicker()">
    </app-icon>
</div>

<app-icon
    class="reset-icon"
    icon="derifin:reset"
    (click)="onResetClicked()">
</app-icon>

<mat-datepicker #datepicker [calendarHeaderComponent]="customHeader" (opened)="onDatepickerOpened($event)"></mat-datepicker>
