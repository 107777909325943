import {
    TopUnderlyingsDataResponse,
    TopUnderlyingsFilter
} from '../../shared/widgets/legacy/entity-widget-top-underlyings/entity-widget-top-underlyings.component';
import { Injectable } from '@angular/core';
import { DerifinApi } from './derifin.api';
import { HttpClient } from '@angular/common/http';
import { AuthStoreService } from '../services/auth-store.service';
import { LogglyLoggerService } from '../services/loggly-logger.service';
import { ConfigurationService } from '../services/configuration.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class DerilyticsApi extends DerifinApi {
    constructor(http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, configService: ConfigurationService) {
        super('m/derilytics', configService.configuration.environment.id, http, authStoreService, loggerService, configService);
    }

    public buildTopUnderlyings(filter: TopUnderlyingsFilter): Observable<TopUnderlyingsDataResponse> {
        return this.post('build', filter).pipe(
            map((res) => res.data),
        );
    }
}
