/**
 * Created by Alex Klein <alex@rocketloop.de> on 10/17/17.
 */

import { Action } from '@ngrx/store';
import {
    AddProductToSpecificBWListListAction,
    BWListActionTypes,
    LoadBWListStructureSucceededAction,
    LoadSpecificSelectedFilterSucceededAction,
    LoadSpecificSelectedListSucceededAction,
    LoadSpecificStructureSucceededAction,
    LoadSpecificValuesSucceededAction,
    RemoveProductFromSpecificBWListListAction,
    UpdateSpecificBWListListSucceededAction,
    UpdateSpecificFilterSucceededAction,
} from '../actions/bwlist.actions';
import { BWListState } from '../state/bwlist.state';

export const initialState: BWListState = {
    actions: null,
    sections: null,
    filterStructures: {},
    filterValues: {},
    tableStructures: {},
    tableValues: {},
    lists: {},
    filters: {},
    topicToProductsMapping: {},
    bwlistSaved: null,
    filterSaved: null,
};

/**
 * The reducer responsible for the @link{WatchlistState}
 * @param state
 * @param action
 * @returns {any}
 */
export function reducer(state: BWListState = initialState, action: Action): BWListState {
    switch (action.type) {
        case BWListActionTypes.LOAD_BWLIST_STRUCTURE:
            return {
                ...state,
                actions: null,
                sections: null,
            };

        case BWListActionTypes.LOAD_BWLIST_STRUCTURE_SUCCEEDED:
            const loadBWListStructureSucceededAction = action as LoadBWListStructureSucceededAction;
            return {
                ...state,
                actions: loadBWListStructureSucceededAction.payload.actions,
                sections: loadBWListStructureSucceededAction.payload.sections,
            };
        case BWListActionTypes.LOAD_SPECIFIC_STRUCTURE_SUCCEEDED:
            const loadSpecificBWListFilterStructureSucceededAction = action as LoadSpecificStructureSucceededAction;
            return {
                ...state,
                filterStructures: {
                    ...state.filterStructures,
                    [loadSpecificBWListFilterStructureSucceededAction.payload.topic]:
                    loadSpecificBWListFilterStructureSucceededAction.payload.structure.filter,
                },
                tableStructures: {
                    ...state.tableStructures,
                    [loadSpecificBWListFilterStructureSucceededAction.payload.topic]:
                    loadSpecificBWListFilterStructureSucceededAction.payload.structure.table,
                },
            };
        case BWListActionTypes.LOAD_SPECIFIC_VALUES_SUCCEEDED:
            const loadSpecificBWListFilterValuesSucceededAction = action as LoadSpecificValuesSucceededAction;
            return {
                ...state,
                filterValues: {
                    ...state.filterValues,
                    [loadSpecificBWListFilterValuesSucceededAction.payload.topic]:
                    loadSpecificBWListFilterValuesSucceededAction.payload.values.filter,
                },
                tableValues: (loadSpecificBWListFilterValuesSucceededAction.payload.values.table) ?
                    loadSpecificBWListFilterValuesSucceededAction.payload.values.table.reduce(
                        (products, product) => {
                            products[product.id] = {
                                ...products[product.id],
                                ...product,
                            };
                            return products;
                        }, {
                            ...state.tableValues,
                        }) : state.tableValues,
                topicToProductsMapping: (loadSpecificBWListFilterValuesSucceededAction.payload.values.table) ? {
                    ...state.topicToProductsMapping,
                    [loadSpecificBWListFilterValuesSucceededAction.payload.topic]:
                        loadSpecificBWListFilterValuesSucceededAction.payload.values.table.map((entity) => entity.id),
                } : state.topicToProductsMapping,
            };
        case BWListActionTypes.LOAD_SPECIFIC_SELECTED_LIST_SUCCEEDED:
            const loadSpecificBWListListSucceededAction = action as LoadSpecificSelectedListSucceededAction;
            return {
                ...state,
                lists: {
                    ...state.lists,
                    [loadSpecificBWListListSucceededAction.payload.topic]: loadSpecificBWListListSucceededAction.payload.list.ids,
                },
            };
        case BWListActionTypes.LOAD_SPECIFIC_SELECTED_FILTER_SUCCEEDED:
            const loadSpecificSelectedFilterSucceededAction = action as LoadSpecificSelectedFilterSucceededAction;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [loadSpecificSelectedFilterSucceededAction.payload.topic]: loadSpecificSelectedFilterSucceededAction.payload.value,
                },
            };
        case BWListActionTypes.UPDATE_SPECIFIC_LIST:
            return {
                ...state,
                bwlistSaved: false,
            };
        case BWListActionTypes.UPDATE_SPECIFIC_FILTER:
            return {
                ...state,
                filterSaved: false,
            };
        case BWListActionTypes.UPDATE_SPECIFIC_BWLIST_LIST_SUCCEEDED:
            const updateSpecificBWListListSucceededAction = action as UpdateSpecificBWListListSucceededAction;
            return {
                ...state,
                lists: {
                    ...state.lists,
                    [updateSpecificBWListListSucceededAction.payload.topic]: updateSpecificBWListListSucceededAction.payload.list.ids,
                },
                bwlistSaved: true,
            };
        case BWListActionTypes.UPDATE_SPECIFIC_FILTER_SUCCEEDED:
            const updateSpecificFilterSucceededAction = action as UpdateSpecificFilterSucceededAction;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [updateSpecificFilterSucceededAction.payload.topic]: updateSpecificFilterSucceededAction.payload.value,
                },
                filterSaved: true,
            };
        case BWListActionTypes.ADD_PRODUCT_TO_SPECIFIC_BWLIST_LIST:
            const addProductToSpecificBWListListAction = action as AddProductToSpecificBWListListAction;
            return {
                ...state,
                lists: {
                    ...state.lists,
                    [addProductToSpecificBWListListAction.payload.topic]: (state.lists[addProductToSpecificBWListListAction.payload.topic])
                        ? [
                            ...state.lists[addProductToSpecificBWListListAction.payload.topic],
                            addProductToSpecificBWListListAction.payload.productId,
                        ]
                        : [addProductToSpecificBWListListAction.payload.productId],
                },
            };
        case BWListActionTypes.REMOVE_PRODUCT_TO_SPECIFIC_BWLIST_LIST:
            const removeProductFromSpecificBWListListAction = action as RemoveProductFromSpecificBWListListAction;
            return {
                ...state,
                lists: {
                    ...state.lists,
                    [removeProductFromSpecificBWListListAction.payload.topic]:
                        state.lists[removeProductFromSpecificBWListListAction.payload.topic]
                            .filter((id) => id !== removeProductFromSpecificBWListListAction.payload.productId),
                },
            };
        default:
            return state;
    }
}
