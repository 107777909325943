import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AppState } from '../../../../core/store/state/index.state';
import { GenericActionStructure } from '../../../../shared/components/entity-actions/entity-actions.component';
import { truthy } from '../../../../shared/helpers/general.helper';
import { Entity, EntityTableConfig } from '../../../../shared/models/entities.model';
import {
    CreateWatchlistRequest,
    Watchlist, WatchlistImportProduct, WatchlistImportValidationResult,
    WatchlistLoadingState,
    WatchlistProductGroup,
    WatchlistsGroup,
    WatchlistShareTarget, WatchlistTab,
} from '../models/watchlist.model';
import {
    addProductsToWatchlist,
    clearProductsAddedToWatchlist,
    clearProductsRemovedFromWatchlist,
    clearWatchlistCreated,
    clearWatchlistDeleted,
    createWatchlist,
    deleteWatchlist, executeWatchlistImport,
    loadWatchlist,
    loadWatchlistData, loadWatchlistEquivalenceData,
    loadWatchlists,
    loadWatchlistsGroup,
    loadWatchlistStructures, loadWatchlistTabStructures,
    loadWatchlistValues,
    patchWatchlist, performTmcCheck,
    removeProductsFromWatchlist,
    updateWatchlist,
    validateProductsForWatchlistImport,
} from '../store/actions/watchlist.actions';
import { AppError } from '../../../../core/errors/base.errors';
import { has } from 'lodash';

@Injectable()
export class WatchlistService {

    constructor(private readonly store: Store<AppState>) {
    }

    /** Selectors **/

    /**
     * Get the watchlists
     * @returns {Observable<TargetMarketCriteria>}
     */
    public getWatchlists(): Observable<Watchlist[]> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.watchlists),
            map((watchlists) => Object.keys(watchlists).map((id) => watchlists[id])),
            filter(truthy),
            map((watchlists) => {
                return watchlists.filter(truthy).sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
            }),
        );
    }

    public getWatchlistsGroup(): Observable<WatchlistsGroup> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.watchlistsGroup),
        );
    }

    /**
     * Get all watchlists the user can edit
     * @returns {Observable<Watchlist[]>}
     */
    public getEditableWatchlists(): Observable<Watchlist[]> {
        return this.getWatchlists().pipe(
            map((watchlists) => watchlists.filter((watchlist) => watchlist.editable)),
        );
    }

    /**
     * Get watchlist
     * @param {string} watchlistId
     * @returns {Observable<Watchlist>}
     */
    public getWatchlist(watchlistId: string): Observable<Watchlist> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.watchlists[watchlistId]),
        );
    }

    public getWatchlistLoadingState(watchlistId: string): Observable<WatchlistLoadingState> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.loadingState[watchlistId]),
        );
    }

    public getWatchlistImportValidation(watchlistId: string): Observable<WatchlistImportValidationResult> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.watchlistsImportValidation[watchlistId]),
        );
    }

    public getWatchlistImportValidationError(watchlistId: string): Observable<AppError> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.watchlistsImportValidationError[watchlistId]),
        );
    }

    public getWatchlistImportError(watchlistId: string): Observable<AppError> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.watchlistsImportError[watchlistId]),
        );
    }

    public getWatchlistImportSuccess(watchlistId: string): Observable<boolean> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.watchlistsImportSuccess[watchlistId]),
        );
    }

    /**
     * Get the watchlists
     * @returns {Observable<TargetMarketCriteria>}
     */
    public getShareableTargets(): Observable<WatchlistShareTarget[]> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.shareableTargets),
        );
    }

    /**
     * Get product group ids for watchlist
     * @param {string} watchListId
     * @returns {Observable<string[]>}
     */
    public getProductGroupIdsForWatchList(watchListId: string): Observable<string[]> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.watchlistToProductGroupMapping[watchListId]),
        );
    }

    /**
     * Get product group
     * @param {string} productGroupId
     * @returns {Observable<WatchlistProductGroup>}
     */
    public getProductGroup(productGroupId: string): Observable<WatchlistProductGroup> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.productGroups[productGroupId]),
        );
    }

    /**
     * Get equivalence check product group
     * @param {string} productGroupId
     * @returns {Observable<WatchlistProductGroup>}
     */
    public getEquivalenceProductGroup(productGroupId: string): Observable<WatchlistProductGroup> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.equivalenceProductGroups[productGroupId]),
        );
    }

    public getEquivalenceProductsForProductGroup(watchListId: string, productGroupId: string): Observable<Entity[]> {
        return this.store.pipe(
            select((state: AppState) => {
                return state.watchlist.equivalenceProducts[watchListId] && state.watchlist.equivalenceProducts[watchListId][productGroupId];
            }),
        )
    }

    public getEquivalenceProductsSize(watchListId: string): Observable<number> {
        return this.store.pipe(
            select((state: AppState) => {
                if(has(state.watchlist.equivalenceProducts, watchListId)){
                    return Object.keys(state.watchlist.equivalenceProducts[watchListId]).reduce((acc, current) => {
                        return acc + state.watchlist.equivalenceProducts[watchListId][current].length;
                    }, 0);
                }

                return 0;
            }),
        );
    }

    public getEquivalenceProductsForWatchlist(watchListId: string): Observable<Entity[]> {
        return this.store.pipe(
            select((state: AppState) => {
                if(has(state.watchlist.equivalenceProducts, watchListId)){
                    return Object.keys(state.watchlist.equivalenceProducts[watchListId]).reduce((acc, current) => {
                        acc.push(...state.watchlist.equivalenceProducts[watchListId][current])

                        return acc;
                    }, []);
                }

                return [];
            }),
        );
    }

    public getEquivalenceResultId(watchListId: string): Observable<string> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.equivalenceResultMap[watchListId]),
        );
    }

    public getEquivalenceCheckError(watchListId: string): Observable<AppError> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.equivalenceError[watchListId]),
        );
    }


    public getTmcProductsForProductGroup(watchListId: string, productGroupId: string): Observable<Entity[]> {
        return this.store.pipe(
            select((state: AppState) => {
                return state.watchlist.tmcCheck.products[watchListId] && state.watchlist.tmcCheck.products[watchListId][productGroupId];
            }),
        )
    }

    public getTmcProductsSize(watchListId: string): Observable<number> {
        return this.store.pipe(
            select((state: AppState) => {
                if(has(state.watchlist.tmcCheck.products, watchListId)){
                    return Object.keys(state.watchlist.tmcCheck.products[watchListId]).reduce((acc, current) => {
                        return acc + state.watchlist.tmcCheck.products[watchListId][current].length;
                    }, 0);
                }

                return 0;
            }),
        );
    }

    public getTmcProductsForWatchlist(watchListId: string): Observable<Entity[]> {
        return this.store.pipe(
            select((state: AppState) => {
                if(has(state.watchlist.tmcCheck.products, watchListId)){
                    return Object.keys(state.watchlist.tmcCheck.products[watchListId]).reduce((acc, current) => {
                        acc.push(...state.watchlist.tmcCheck.products[watchListId][current])

                        return acc;
                    }, []);
                }

                return [];
            }),
        );
    }

    public getTmcResultId(watchListId: string): Observable<string> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.tmcCheck.resultMap[watchListId]),
        );
    }

    public getTmcCheckError(watchlistId: string): Observable<AppError> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.tmcCheck.error[watchlistId]),
        );
    }

    // productGroupToProductsMapping

    /**
     * Get product ids for product group
     * @param {string} groupId
     * @returns {Observable<string[]>}
     */
    public getProductIdsForProductGroup(groupId: string): Observable<string[]> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.productGroupToProductsMapping[groupId]),
        );
    }

    /**
     * Get the product
     * @param {string} productId
     * @returns {Observable<Entity>}
     */
    public getProduct(productId: string): Observable<Entity> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.products[productId]),
        );
    }

    /**
     * Get the prodducts groups for watchlist
     * @param {string} watchlistId
     * @returns {Observable<WatchlistProductGroup[]>}
     */
    public getProductGroupsForWatchlist(watchlistId: string): Observable<WatchlistProductGroup[]> {
        return this.getProductGroupIdsForWatchList(watchlistId).pipe(
            switchMap((ids) => {
                if (ids && ids.length > 0) {
                    return combineLatest(ids.map((id) => this.getProductGroup(id)));
                } else {
                    return of([]);
                }
            }),
        );
    }

    public getTabsForWatchlist(watchlistId: string): Observable<WatchlistTab[]> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.watchlistsTabs[watchlistId]),
        );
    }

    /**
     * Get products for product group
     * @param productGroupId
     * @returns {Observable<Entity[]>}
     */
    public getProductsForProductGroup(productGroupId): Observable<Entity[]> {
        return this.getProductIdsForProductGroup(productGroupId).pipe(
            switchMap((ids) => {
                if (ids !== undefined && ids && ids.length > 0) {
                    return combineLatest(ids.map((id) => this.getProduct(id)));
                } else {
                    return of([]);
                }
            }),
        );
    }

    /**
     * Get whether the watchlist was created
     * @returns {Observable<boolean>}
     */
    public wasWatchlistCreated(): Observable<boolean> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.createdWatchlist),
        );
    }

    /**
     * Get whether the watchlist was created
     * @returns {Observable<boolean>}
     */
    public getLastCreatedWatchlistId(): Observable<string> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.lastCreatedWatchlistId),
        );
    }

    /**
     * Get whether the watchlist was deleted
     * @returns {Observable<boolean>}
     */
    public wasWatchlistDeleted(): Observable<boolean> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.deletedWatchlist),
        );
    }

    /**
     * Get the watch list actions
     * @returns {Observable<GenericActionStructure[]>}
     */
    public getWatchlistOverlayActions(): Observable<GenericActionStructure[]> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.actions),
        );
    }

    /**
     * Get whether the watchlist was created
     * @returns {Observable<boolean>}
     */
    public wereProductsAddedToWatchlist(): Observable<boolean> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.productsWereAdded),
        );
    }

    public wereProductsRemovedFromWatchlist(): Observable<boolean> {
        return this.store.pipe(
            select((state: AppState) => state.watchlist.productsWereRemoved),
        );
    }

    /** Actions **/

    /**
     * Loads the watchlists
     */
    public loadWatchlists() {
        this.store.dispatch(loadWatchlists());
    }

    public loadWatchlistsGroup(moduleId: string) {
        this.store.dispatch(loadWatchlistsGroup(moduleId));
    }

    /**
     * Loads the watchlists
     */
    public loadWatchlist(watchlistId: string) {
        this.store.dispatch(loadWatchlist(watchlistId));
    }

    public loadWatchlistEquivalenceData(watchListId: string, deriBwIds: Array<string>): void {
        this.store.dispatch(loadWatchlistEquivalenceData(watchListId, deriBwIds));
    }

    public performWatchlistTMCData(watchListId: string) {
        this.store.dispatch(performTmcCheck(watchListId));
    }

    /**
     * Loads the watchlists values
     */
    public loadWatchlistValues() {
        this.store.dispatch(loadWatchlistValues());
    }

    /**
     * Create watchlists
     */
    public createWatchlist(request: CreateWatchlistRequest) {
        this.store.dispatch(createWatchlist(request));
    }

    /**
     * Update watchlists
     */
    public updateWatchlist(watchlistId: string, data: any) {
        this.store.dispatch(updateWatchlist(watchlistId, data));
    }

    /**
     * Patch watchlists
     */
    public patchWatchlist(watchlistId: string, data: any) {
        this.store.dispatch(patchWatchlist(watchlistId, data));
    }

    /**
     * Delete watchlists
     */
    public deleteWatchlist(watchlistId: string) {
        this.store.dispatch(deleteWatchlist(watchlistId));
    }

    /**
     * Load watchlist structures
     * @param {string} watchlistId
     */
    public loadWatchListStructures(watchlistId: string) {
        this.store.dispatch(loadWatchlistStructures(watchlistId));
    }

    /**
     * Load watchlist tab structures
     * @param {string} watchlistId
     */
    public loadWatchlistTabStructures(watchlistId: string) {
        this.store.dispatch(loadWatchlistTabStructures(watchlistId));
    }

    /**
     * Load watchlist data
     * @param {string} watchlistId
     */
    public loadWatchlistData(watchlistId: string) {
        this.store.dispatch(loadWatchlistData(watchlistId));
    }

    /**
     * Clear watchlist created state
     */
    public clearWatchlistCreated() {
        this.store.dispatch(clearWatchlistCreated());
    }

    /**
     * Clear watchlist deleted state
     */
    public clearWatchlistDeleted() {
        this.store.dispatch(clearWatchlistDeleted());
    }

    /**
     * Remove product from watchlist
     * @param {string} watchlistId
     * @param {string[]} productIds
     */
    public removeProductFromWatchlist(watchlistId: string, productIds: string[]) {
        this.store.dispatch(removeProductsFromWatchlist(watchlistId, productIds));
    }

    /**
     * Add Products to watchlist
     * @param {string} watchlistId
     * @param {string[]} produtcIds
     */
    public addProductsToWatchlist(watchlistId: string, produtcIds: string[]) {
        this.store.dispatch(addProductsToWatchlist(watchlistId, produtcIds));
    }

    /**
     * Clear products added state
     */
    public clearProductsAddedToWatchlist() {
        this.store.dispatch(clearProductsAddedToWatchlist());
    }

    public clearProductsRemovedFromWatchlist() {
        this.store.dispatch(clearProductsRemovedFromWatchlist());
    }

    public validateProductsForWatchlistImport(watchlistId: string, productIds: string[]) {
        this.store.dispatch(validateProductsForWatchlistImport(watchlistId, productIds));
    }

    public executeWatchlistImport(watchlistId: string, products: WatchlistImportProduct[], reset: boolean) {
        this.store.dispatch(executeWatchlistImport(watchlistId, products, reset));
    }
}
