<div class="custom-controls-stacked">
    <label
        *ngFor="let value of values"
        class="custom-control"
        [class.custom-switch]="true"
    >
        <input
                [name]="value?.id"
                type="checkbox"
                class="custom-control-input"
                [checked]="value?.enabled"
                (change)="onCheckboxChange($event, value)">

        <span class="custom-control-indicator"></span>
        <span class="custom-control-description">{{value?.label}}</span>
    </label>
</div>
