/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 4/26/17.
 */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { merge, Observable, ObservableInput, of } from 'rxjs';
import { catchError, delay, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { loadTargetMarketCriteriaStructure } from '../../../routes/target-market/store/actions/target-market.actions';
import { getParamValueQueryString } from '../../../shared/helpers/general.helper';
import { AuthUserApi } from '../../api/auth-user.api';
import { AuthApi } from '../../api/auth.api';
import { GetTokenResponse } from '../../api/models/auth.model';
import { AuthErrors } from '../../errors/auth.errors';
import { AppError } from '../../errors/base.errors';
import {
    clearLocalStorage,
    KEY_ACCESS_TOKEN,
    KEY_REFRESH_TOKEN,
    writeToLocalStorage,
} from '../../helpers/local-storage.helper';
import { AuthStoreService } from '../../services/auth-store.service';
import {
    AuthActionTypes,
    authUserLoaded,
    loadAuthUser,
    LoadAuthUserAction,
    loadAuthUserFailed,
    signedIn,
    SignedInAction,
    SignInAction,
    signInFailed,
    signInRedirectFailureAction, signInRedirectSuccessAction,
    signInRedirectToken,
    SignInRedirectTokenAction,
    signOut,
    SignOutAction,
    tokenRefreshed,
    TokenRefreshedAction,
    ValidateTokenAction,
} from '../actions/auth.action';
import { loadConfig } from '../actions/config.action';
import * as RouterActions from '../actions/router.action';
import { UserActions } from '../actions/user.actions';

/**
 * Effect class for auth effects
 */
@Injectable()
export class AuthEffects {
    public isRefreshingToken = false;

    /** Effect Declarations **/

    
    public signIn$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActionTypes.SIGN_IN),
        switchMap((action: SignInAction) => this.onSignIn(action)),
    ));

    
    public signedIn$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActionTypes.SIGNED_IN),
        switchMap((action: SignedInAction) => this.onSignedIn(action)),
    ));

    
    public signInViewRedirectToken$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActionTypes.SIGN_IN_REDIRECT_TOKEN),
        switchMap((action: SignInRedirectTokenAction) => this.onSignInViaRedirectToken(action)),
    ));

    
    public loadAuthUser$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActionTypes.LOAD_AUTH_USER),
        switchMap((action: LoadAuthUserAction | SignedInAction) => this.onLoadAuthUser(action)),
    ));

    
    public signOut$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActionTypes.SIGN_OUT),
        switchMap((action: SignOutAction) => this.onSignOut(action)),
    ));

    
    public validateToken$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActionTypes.VALIDATE_TOKEN),
        switchMap((action: ValidateTokenAction) => this.onValidateToken(action)),
    ));

    
    public refreshToken$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActionTypes.REFRESH_TOKEN),
            mergeMap(() => this.onRefreshToken())
        )
    });

    
    public tokenRefreshed$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActionTypes.TOKEN_REFRESHED),
        tap((action: TokenRefreshedAction) => this.onTokenRefreshed(action))), {dispatch: false});

    
    public initializeStore$ = createEffect(() => this.actions$.pipe(
        ofType(ROOT_EFFECTS_INIT, AuthActionTypes.STORE_INIT_AFTER_REFRESH_TOKEN),
        switchMap((action) => {
            const redirectToken = getParamValueQueryString('redirectToken');

            if (redirectToken) {
                return merge(
                    of(signInRedirectToken(redirectToken, location.pathname)),
                    this.onInitializeStore()).pipe(delay(100),
                );
            }

            return merge(this.onInitializeStore()).pipe(delay(100));
        }),
    ));
    
    public resetAccessToken$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActionTypes.RESET_ACCESS_TOKEN),
        tap((action) => this.onResetAccessToken(action)),
    ), {dispatch: false});

    
    public resetRefreshToken$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActionTypes.RESET_REFRESH_TOKEN),
        tap((action) => this.onResetRefreshToken(action)),
    ), {dispatch: false});

    constructor(
        private actions$: Actions,
        private authApi: AuthApi,
        private authUserApi: AuthUserApi,
        private authStoreService: AuthStoreService
    ) {
    }

    /** Effect Handler **/

    /**
     * Effect handler called upon the SignInAction
     *
     * This effect handler calls the corresponding API method to acquire a token in exchange for the user's credentials
     * that are passed along in the SignInAction
     * @param action
     * @returns {Observable<Action>}
     */
    public onSignIn(action: SignInAction): Observable<Action> {
        let routerPath = '/app';
        if (action.payload.returnUrl) {
            routerPath = atob(action.payload.returnUrl);
        }

        return this.authApi.getTokenUsingCredentials(action.payload.username, action.payload.password).pipe(
            mergeMap((getTokenResponse: GetTokenResponse) => {
                return [tokenRefreshed(getTokenResponse), signedIn(), new RouterActions.Go({path: routerPath})];
            }),
            catchError((err: any) =>
                of(
                    signInFailed({
                        errorCode: AuthErrors.BAD_CREDENTIALS,
                        httpError: true,
                        statusCode: err.status,
                    }),
                ),
            ),
        );
    }

    /**
     * Effect handler called upon the SignedInAction
     *
     * This effect handler calls the corresponding API method to acquire a token in exchange for the user's credentials
     * that are passed along in the SignInAction
     * @param action
     * @returns {Observable<Action>}
     */
    public onSignedIn(action: SignedInAction): Observable<Action> {
        return of<Action>(loadAuthUser(), loadConfig(), loadTargetMarketCriteriaStructure());
    }

    /**
     * Effect handler called upon the LoadAuthUserAction and the SignedInAction
     *
     * This effect handler gets the currently authenticated user from the corresponding API method in response to
     * a successful login or token refresh
     * @param action
     * @returns {Observable<Action>}
     */
    public onLoadAuthUser(action: LoadAuthUserAction | SignedInAction): Observable<Action> {
        return this.authUserApi.getAuthenticatedUser().pipe(
            map((authUser) => authUserLoaded(authUser)),
            catchError((err: AppError) => of(loadAuthUserFailed(err))),
        );
    }

    /**
     * Effect handler called upon the SignOutAction
     *
     * This effect handler redirects the user to the landing page upon signing out
     * @param action
     * @returns {Observable<Action>}
     */
    public onSignOut(action: SignOutAction): Observable<Action> {
        clearLocalStorage();

        let redirectPath = '/';
        if (action.payload.redirectToLogin) {
            redirectPath = '/login';
        }

        return of(
            new RouterActions.Go({
                path: [redirectPath],
                query: {
                    returnUrl: action.payload.returnUrl ? btoa(action.payload.returnUrl) : undefined,
                },
            }),
        );
    }

    /**
     * Effect handler called upon the ValidateTokenAction
     *
     * This effect handler calls the API Method to validate the current access token
     * @param action
     * @returns {Observable<Action>}
     */
    public onValidateToken(action: ValidateTokenAction): Observable<Action> {
        return this.authStoreService.getAccessToken().pipe(
            take(1),
            switchMap((token) => this.authApi.validateToken(token)),
            map((getTokenResponse) => tokenRefreshed(getTokenResponse)),
        );
    }

    /**
     * Effect handler called upon the RefreshTokenAction
     *
     * This effect handler calls the API Method to refresh the current access token
     * @param action
     * @returns {Observable<Action>}
     */
    public onRefreshToken(): ObservableInput<Action> {
        if (this.isRefreshingToken) {
            return [];
        } else {
            this.isRefreshingToken = true;
            return this.authStoreService.getRefreshToken().pipe(
                take(1),
                switchMap((token) => this.authApi.getTokenUsingRefreshToken(token)),
                mergeMap((getTokenResponse) => [tokenRefreshed(getTokenResponse)]),
                catchError((err) => of(signOut()))
            );
        }
    }

    /**
     * Effect handler called upon the TokenRefreshedAction
     *
     * This effect handler stores the new access token and refresh token to the local storage if set
     * @param action
     */
    public onTokenRefreshed(action: TokenRefreshedAction): void {
        this.isRefreshingToken = false;
        if (action.payload.tokenResponse.token) {
            writeToLocalStorage(KEY_ACCESS_TOKEN, action.payload.tokenResponse.token);
        }
        if (action.payload.tokenResponse.refreshToken) {
            writeToLocalStorage(KEY_REFRESH_TOKEN, action.payload.tokenResponse.refreshToken);
        }
    }

    /**
     * Effect handler called upon the Dispatcher.INIT action
     *
     * This effect handler dispatches the LoadAuthUserAction and LoadConfigAction if a valid access token is found
     * @returns {Observable<Action>}
     */
    public onInitializeStore(): Observable<Action> {
        return this.authStoreService.isRefreshTokenStillValid().pipe(
            take(1),
            filter((isValid) => isValid),
            switchMap(() => 
                of(
                    loadAuthUser(),
                    loadConfig(),
                    UserActions.loadShortcuts(),
                    loadTargetMarketCriteriaStructure()
                )
            )
        );
    }

    public onSignInViaRedirectToken(action: SignInRedirectTokenAction) {
        let routerPath = '/app';
        if (action.payload.returnUrl) {
            routerPath = action.payload.returnUrl;
        }

        return this.authApi.getTokenUsingRedirectToken(action.payload.redirectToken)
            .pipe(
                mergeMap((getTokenResponse: GetTokenResponse) => {
                    if (getTokenResponse.token) {
                        writeToLocalStorage(KEY_ACCESS_TOKEN, getTokenResponse.token);
                        writeToLocalStorage(KEY_REFRESH_TOKEN, getTokenResponse.token);
                    }

                    return [signInRedirectSuccessAction(getTokenResponse), signedIn(), new RouterActions.Go({path: routerPath})];
                }),
                catchError((err: AppError) => of(signInRedirectFailureAction(err))),
            );
    }

    private onResetAccessToken(action: Action) {
        writeToLocalStorage(KEY_ACCESS_TOKEN, '');
    }

    private onResetRefreshToken(action: Action) {
        writeToLocalStorage(KEY_REFRESH_TOKEN, '');
    }
}
