import { style } from '@angular/animations';
import { Directive, ElementRef, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { uniq } from 'lodash';
import { EntityTableStyleDecoratorService, StyleDecoratorResult } from '../../core/services/entity-table-style-decorator.service';
import { truthy } from '../helpers/general.helper';
import { Entity } from '../models/entities.model';

@Directive({
    /* tslint:disable:component-selector */
    selector: '[appEntityTableCellStyleDecorator]',
    standalone: true
})
export class EntityTableCellStyleDecoratorDirective implements OnInit, OnChanges {

    @Input() public styleDecorator: string;

    @Input() public styleDecoratorOptions: any;

    @Input() public entity: Entity;

    @Input() public pathToStyleDecorator: string;

    @Input() public classPrefix = 'entity-table-cell';

    @HostBinding('style') public cellStyle: SafeStyle;

    constructor(
        private styleDecoraterService: EntityTableStyleDecoratorService,
        private el: ElementRef,
        private sanitizer: DomSanitizer,
    ) {

    }

    public ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.styleDecorator || changes.styleDecoratorOptions || changes.entity || changes.path) {
            this._updateStyle();
        }
    }

    private _updateStyle() {
        if (this.styleDecorator) {
            const styleObj: StyleDecoratorResult = this.styleDecoraterService.applyDecorator(
                this.styleDecorator,
                this.entity,
                this.pathToStyleDecorator,
                this.styleDecoratorOptions,
            );

            // inject styles as calculated by the style decorator
            let styleStr = '';

            if (styleObj.styles) {
                styleStr = Object.keys(styleObj.styles).map((k) => k + ': ' + styleObj.styles[k]).join('; ');
            }

            this.cellStyle = this.sanitizer.bypassSecurityTrustStyle(styleStr);

            // inject new classnames and remove unneeded ones
            if (styleObj.classNames) {
                const externalClasses = this.el.nativeElement.className.split(' ')
                    .filter((cls) => {
                        const cleanedClass = cls.replace(this.classPrefix + '--', '');

                        return !styleObj.classNames.hasOwnProperty(cleanedClass) || styleObj.classNames[cleanedClass];
                    });

                const decoratorClasses = Object.keys(styleObj.classNames)
                    .filter((cls) => styleObj.classNames[cls])
                    .map((cls) => `${this.classPrefix}--${cls}`);

                // ## Important: ##
                //
                // *Do not* use host binding for class name, since we generally have have two directives that register a
                // class binding per table-cell. That causes issues, where one directive evaluates faster than another.
                // Resulting in directives overriding the changes performed by each other.
                this.el.nativeElement.className = uniq([...externalClasses, decoratorClasses].filter(truthy)).join(' ');
            }
        }
    }
}
