import { Action } from '@ngrx/store';
import { AppError } from '../../errors/base.errors';
import { Data } from '../../models/data.model';
import { type } from '../util';

export const StringsActionTypes = {
    LOAD_STRINGS: type('STRINGS:LOAD_STRINGS'),
    LOAD_STRINGS_SUCCESS: type('STRINGS:LOAD_STRINGS_SUCCESS'),
    LOAD_STRINGS_FAILED: type('STRINGS:LOAD_STRINGS_FAILED'),
};

/** LoadStringsAction **/

export interface LoadStringsAction extends Action {
    payload: {
        path: string;
    };
}

export function loadStrings(path: string): LoadStringsAction {
    return {
        type: StringsActionTypes.LOAD_STRINGS,
        payload: {
            path,
        },
    };
}

/** LoadStringSuccessAction **/

export interface LoadStringSuccessAction extends Action {
    payload: {
        strings: Data;
        path: string;
    };
}

export function loadStringsSuccess(strings: Data, path: string): LoadStringSuccessAction {
    return {
        type: StringsActionTypes.LOAD_STRINGS_SUCCESS,
        payload: {
            strings,
            path,
        },
    };
}

/** LoadStringsFailedAction **/

export interface LoadStringsFailedAction extends Action {
    payload: {
        error: AppError;
        path: string;
    };
}

export function loadStringsFailed(error: AppError, path: string): LoadStringsFailedAction {
    return {
        type: StringsActionTypes.LOAD_STRINGS_FAILED,
        payload: {
            error,
            path
        },
    };
}
