<div class="form-group">
    <label for="maturityRule" class="input-label">{{ 'filters.maturityRule.rule' | transloco }}</label>
    <select class="form-control" id="maturityRule" [ngModel]="currentValue.rule" (ngModelChange)="onMaturityRuleChanged($event)" [disabled]="disabled">
        <option [value]="null" disabled style="display: none;">Keine Auswahl</option>
        <option *ngFor="let value of values" [value]="value.rule.id">{{value.rule.label}}</option>
    </select>
</div>

<div *ngIf="currentValue.rule" class="form-group">
    <label for="numMaturityDays" class="input-label">{{ 'filters.maturityRule.day' | transloco }}</label>
    <select class="form-control" id="numMaturityDays" [ngModel]="currentValue.numMaturityDays" (ngModelChange)="onNumMaturityDaysChanged($event)" [disabled]="disabled">
        <option [value]="null" disabled style="display: none;">Keine Auswahl</option>
        <option *ngFor="let value of availableMaturityDays" [value]="value.id">{{value.label}}</option>
    </select>
</div>
