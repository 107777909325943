import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Entity } from '../../../models/entities.model';
import { EntityWidgetOptions } from '../../../models/widgets.model';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { EntityWidgetTopTradesItemComponent } from './entity-widget-top-trades-item.component';
import { FormsModule } from '@angular/forms';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';

const STORAGE_KEY = '$__derifinTopTradesWidget';

@Component({
    selector: 'app-entity-widget-top-trades',
    templateUrl: './entity-widget-top-trades.component.html',
    styleUrls: ['./entity-widget-top-trades.component.scss'],
    standalone: true,
    imports: [MatButtonToggleModule, NgFor, NgIf, FormsModule, EntityWidgetTopTradesItemComponent, AsyncPipe]
})
export class EntityWidgetTopTradesComponent implements OnInit, OnChanges, OnDestroy, EntityWidgetContentComponent {
    public static disableTitle = false;

    @HostBinding('class.entity-widget-top-trades') public topTradesWidgetClass = true;

    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public options: EntityWidgetOptions & {
        groups: TopTradesFilter[],
        provisioned: boolean,
        unprovisioned: boolean,
        showProvisionedFilter: boolean,
    };

    @Input() public data: TopTradesItem[] = [];
    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();

    public defaultFilter = {
        id: 'ALL',
        label: 'Alle',
        types: 'ALL',
    };

    public filters: TopTradesFilter[] = [
        this.defaultFilter,
        {
            id: 'QTD0',
            label: 'Discountzertifikate',
            types: ['QTD0'],
        }, {
            id: 'QTR0',
            label: 'Aktienanleihen',
            types: ['QTR0'],
        },
    ];

    public activeFilter$: BehaviorSubject<TopTradesFilter> = new BehaviorSubject(this.defaultFilter);
    public activeFilter: TopTradesFilter = this.defaultFilter;

    public sortedData$: BehaviorSubject<TopTradesItem[]> = new BehaviorSubject([]);
    public filteredData$: Observable<TopTradesItem[]>;

    public dataSubscription: Subscription;
    public showProvisioned$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public showProvisioned = false;
    public enableProvisionToggle = true;

    public reconcileItemWithFilter(item: TopTradesItem, filter: TopTradesFilter) {
        if (!filter || filter.types === 'ALL' || !filter.types) {
            return true;
        }

        for (const type of filter.types) {
            const itemType = item.type;

            if (itemType === type) {
                return true;
            }
        }

        return false;
    }

    constructor() {
        this.filteredData$ = combineLatest([
            this.activeFilter$,
            this.sortedData$,
            this.showProvisioned$,
        ]).pipe(
            map(([activeFilter, sortedData, showProvisioned]) => {
                return sortedData.filter((value) => {
                    return this.reconcileItemWithFilter(value, activeFilter) && (showProvisioned && this.enableProvisionToggle ? value.provisioned : true);
                });
            }),
        );

        this.dataSubscription = this.activeFilter$.subscribe((filter) => this.activeFilter = filter);
        this.dataSubscription.add(this.showProvisioned$.subscribe((provisioned) => this.showProvisioned = provisioned));
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && this.data) {
            this.sortedData$.next(
                this.data
                    .filter((a) => a.volume !== null && a.volume !== undefined)
                    .sort((a, b) => {
                        return b.volume - a.volume;
                    }),
            );
        }

        if (changes.options && this.options) {
            this.filters = this.options.groups || this.filters;
            this.filters = this.filters.map((filter) => {
                if (!filter.id) {
                    return {
                        ...filter,
                        id: filter.label.replace(/\W/g, '_').toUpperCase(),
                    };
                }
                return filter;
            });

            this.enableProvisionToggle = this.options.showProvisionedFilter;
            this.activeFilter$.next(this.filters[0]);
        }
    }

    public ngOnInit() {
        this.readFilterFromStorage();
    }

    public ngOnDestroy(): void {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }

    public onActiveFilterChange(event: MatButtonToggleChange) {
        this.activeFilter$.next(event.value);

        this.persistFilterToStorage();
    }

    public showProvisionedChanged(event: boolean) {
        this.showProvisioned$.next(event);

        this.persistFilterToStorage();
    }

    public persistFilterToStorage() {
        const persistData = {
            filter: this.activeFilter$.getValue(),
            showProvisioned: this.showProvisioned$.getValue(),
        };

        localStorage.setItem(STORAGE_KEY, JSON.stringify(persistData));
    }

    public readFilterFromStorage() {
        const storageData = localStorage.getItem(STORAGE_KEY);

        if (storageData) {
            try {
                const parsedData = JSON.parse(storageData);

                if (parsedData.filter) {
                    this.activeFilter$.next(this.filters.find((f) => f.id === parsedData.filter.id) || this.defaultFilter);
                }

                this.showProvisioned$.next(!!parsedData.showProvisioned);
            } catch (e) {
                localStorage.removeItem(STORAGE_KEY);
            }
        }
    }
}

export interface TopTradesItem {
    dq: string;
    wkn: string;
    isin: string;
    name: string;
    dericonName: string;
    volume: number;
    typeName: string;
    type: string;
    provisioned: boolean;
    full: Entity;
    rows: string[];
}

export interface GroupedTopTradeItems {
    [key: string]: TopTradesItem[];
}

export interface TopTradesFilter {
    id: string;
    label: string;
    types: string[] | string;
}
