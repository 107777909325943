<td *ngIf="config.selectable && multiIndex === 0" [attr.rowspan]="multiRowCount">
    <chroma-checkbox
        [checked]="selected"
        [stackable]="true"
        (change)="onSelectionChange()" />
</td>

<ng-container *ngFor="let column of config.columns; let last = last;">
    <ng-container *ngIf="column.decorator === 'composite'; else singleDecorator">
        <td appEntityTableCellStyleDecorator
            [pathToStyleDecorator]="column.pathToStyleDecorator"
            [styleDecorator]="column.styleDecorator"
            [styleDecoratorOptions]="column.styleDecoratorOptions"
            [entity]="entity"
            class="entity-table-cell entity-table-cell--composite tw-text-sm tw-py-2.5 tw-pl-0 tw-pr-5"
            [class.entity-table-cell--composite-horizontal]="column.decoratorOptions?.horizontal"
            *ngIf="isArrayValuePathInComposite(column) || multiIndex === 0"
            [attr.rowspan]="!isArrayValuePathInComposite(column) && multiIndex === 0 ? multiRowCount : 1"
        >
            <ng-container *ngFor="let line of column.decoratorOptions?.lines; let last = last;">
                <div *ngIf="line.decorator === 'component'; else regularCell" appEntityTableComponentCell
                     [component]="line.decoratorOptions?.component" [decoratorOptions]="line.decoratorOptions"
                     [componentOptions]="line.decoratorOptions?.componentOptions" [entity]="entity" [path]="getArrayValuePath(line.path)"
                     [multiIndex]="multiIndex"
                     (event)="onComponentEvent($event)"></div>
                <ng-template #regularCell>
                    <div appEntityTableCell [multiIndex]="multiIndex" [decorator]="line.decorator" [decoratorOptions]="line.decoratorOptions"
                         [entity]="entity" [path]="getArrayValuePath(line.path)"></div>
                </ng-template>
                <ng-container *ngIf="column.decoratorOptions.separator && !last">{{column.decoratorOptions.separator}}
                </ng-container>
            </ng-container>
        </td>
    </ng-container>
    <ng-template #singleDecorator>
        <td *ngIf="column.decorator === 'component' && (isArrayValuePath(column.path) || multiIndex === 0); else regularCell" appEntityTableComponentCell
            [component]="column.decoratorOptions?.component" [decoratorOptions]="column.decoratorOptions" [multiIndex]="multiIndex"
            [componentOptions]="column.decoratorOptions?.componentOptions" [entity]="entity" [path]="getArrayValuePath(column.path)"
            [attr.rowspan]="!isArrayValuePath(column.path) && multiIndex === 0 ? multiRowCount : 1"
            class="tw-text-sm tw-py-2.5 tw-pl-0 tw-pr-5"
            (event)="onComponentEvent($event)"></td>
        <ng-template #regularCell>
            <td appEntityTableCell appEntityTableCellStyleDecorator
                class="tw-text-black tw-text-sm tw-py-2.5 tw-pl-0 tw-pr-5 dark:tw-text-old-black"
                [multiIndex]="multiIndex"
                [decorator]="column.decorator"
                [decoratorOptions]="column.decoratorOptions"
                [pathToStyleHints]="column.pathToStyleHints"
                [pathToStyleDecorator]="column.pathToStyleDecorator"
                [styleDecorator]="column.styleDecorator"
                [styleDecoratorOptions]="column.styleDecoratorOptions"
                [entity]="entity"
                [path]="getArrayValuePath(column.path)"
                *ngIf="isArrayValuePath(column.path) || multiIndex === 0"
                [attr.rowspan]="!isArrayValuePath(column.path) && multiIndex === 0 ? multiRowCount : 1"></td>
        </ng-template>
    </ng-template>
</ng-container>
<td *ngIf="config.removable">
    <a href="#" title="Entfernen" (click)="onRemoveClicked($event)" class="entity-table__remove-button">
        <app-icon class="derifin-icon" icon="derifin:schliessen"></app-icon>
    </a>
</td>
