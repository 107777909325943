import { get, PropertyPath, isArrayLike, isEmpty } from 'lodash';
import { EntityWidgetConfig } from '../models/widgets.model';

export function truthy<T>(value: T) {
    return !!value;
}

export function falsy<T>(value: T) {
    return !!!value;
}

export function getParamValueQueryString(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const booleanToString = (val) => val ? 'yes' : 'no';
export const stringToBoolean = (val) => val === 'yes' || val === 'true' || val === '1';

export function isEmptyValue(value: any) {
    if (value === null || value === undefined) {
        return true;
    }
    if (isArrayLike(value)) {
        return isEmpty(value);
    }

    return false;
}

export function getAny<T>(object: any, ...paths: PropertyPath[]): T {
    for (const path of paths) {
        const val = get(object, path);

        if (val) {
            return val;
        }
    }

    return null;
}

export function getMany<T>(object: any, ...paths: PropertyPath[]): T[] {
    const result = [];

    for (const path of paths) {
        const val = get(object, path);
        result.push(val);
    }

    return result;
}

export function getAdditionalDataPathForWidget<T>(basePath: string, widget: EntityWidgetConfig, entityId: string = 'DEFAULT'): T | any {
    const path = getAny<T>(widget, 'path', 'options.path');

    if (path) {
        return path;
    } else {
        return [basePath, widget.type, entityId].join('.');
    }
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) {
        return target;
    }
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, {[key]: {}});
                }
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, {[key]: source[key]});
            }
        }
    }

    return mergeDeep(target, ...sources);
}

export function* range(start, end) {
    yield start;
    if (start === end) {
        return;
    }
    yield* range(start + 1, end);
}

export function replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
}
