/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 07/11/2017.
 */

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { AppState } from '../../../core/store/state/index.state';
import { truthy } from '../../../shared/helpers/general.helper';
import { Entity } from '../../../shared/models/entities.model';
import { TabConfig } from '../../../shared/models/tabs.model';
import { EntityWidgetConfig } from '../../../shared/models/widgets.model';

import {
    addProductsToSelection,
    addProductToSelection,
    clearSelection,
    loadTabData,
    loadTabsStructure,
    loadTabStructure,
    removeProductFromSelection,
    removeProductsFromSelection,
    setCurrentTabId,
} from '../store/actions/product-selection.actions';

@Injectable()
export class ProductSelectionService {

    constructor(private store: Store<AppState>) {

    }

    /** Selectors **/

    /**
     * Get the currently selected product ids form the store
     * @returns {Observable<string[]>}
     */
    public getCurrentlySelectedProductIds(): Observable<string[]> {
        return this.store.pipe(
            select((state: AppState) => state.productSelection.currentSelection),
        );
    }

    /**
     * Get tabs structure
     * @returns {Observable<TabConfig[]>}
     */
    public getTabsStructure(): Observable<TabConfig[]> {
        return this.store.pipe(
            select((state: AppState) => state.productSelection.tabs),
        );
    }

    /**
     * Get the speicifc tab structure for the given tab id
     * @param {string} tabId
     * @returns {Observable<EntityWidgetConfig[]>}
     */
    public getTabStructure(tabId: string): Observable<EntityWidgetConfig[]> {
        return this.store.pipe(
            select((state: AppState) => state.productSelection.tabsStructure[tabId]),
        );
    }

    /**
     * Get the specific tab values for the given tab id
     * @param {string} tabId
     * @returns {Observable<Entity[]>}
     */
    public getTabValues(tabId: string): Observable<Entity[]> {
        return this.store.pipe(
            select((state: AppState) => state.productSelection.tabsData[tabId]),
        );
    }

    /**
     * Get the currently selected tab id
     * @returns {Observable<string>}
     */
    public getCurrentSelectedTabId(): Observable<string> {
        return this.store.pipe(
            select((state: AppState) => state.productSelection.currentSelectedTabId),
        );
    }

    /**
     * Get the number of currently selected products form the store
     * @returns {Observable<number>}
     */
    public getNumberOfSelectedProducts(): Observable<number> {
        return this.getCurrentlySelectedProductIds().pipe(
            map((ids) => ids.length),
        );
    }

    /** Actions **/

    /**
     * Add the specified product to the current selection
     * @param productId
     */
    public addProductToSelection(productId: string) {
        this.store.dispatch(addProductToSelection(productId));
    }

    /**
     * Remove the specified product from the current selection
     * @param productId
     */
    public removeProductFromSelection(productId: string) {
        this.store.dispatch(removeProductFromSelection(productId));
    }

    /**
     * Add the specified products to the current selection
     * @param productIds
     */
    public addProductsToSelection(productIds: string[]) {
        this.store.dispatch(addProductsToSelection(productIds));
    }

    /**
     * Add the specified products to the current selection
     * @param productIds
     */
    public removeProductsFromSelection(productIds: string[]) {
        this.store.dispatch(removeProductsFromSelection(productIds));
    }

    /**
     * Load the fields for the product selection
     */
    public loadTabsStructure() {
        this.getCurrentlySelectedProductIds().pipe(
            take(1),
        ).subscribe((ids) => {
            if (ids) {
                this.store.dispatch(loadTabsStructure(ids));
            }
        });
    }

    /**
     * Load the tab structure for the currently selected tab id
     */
    public loadTabStructure() {
        this.getCurrentSelectedTabId().pipe(
            filter(truthy),
            take(1),
        ).subscribe((tabId) => {
            this.store.dispatch(loadTabStructure(tabId));
        });
    }

    /**
     * Load the tab data for a specific tab id and the selected dqs
     */
    public loadTabData() {
        combineLatest([
            this.getCurrentSelectedTabId().pipe(filter(truthy)),
            this.getCurrentlySelectedProductIds().pipe(filter(truthy)),
        ]).pipe(
            take(1),
        ).subscribe(([tabId, dqs]) => {
            this.store.dispatch(loadTabData(tabId, dqs));
        });
    }

    /**
     * Clear the current selection
     */
    public clearSelection() {
        this.store.dispatch(clearSelection());
    }

    /**
     * Set the currently selected tab id
     * @param {string} tabId
     */
    public setCurrentTabId(tabId: string) {
        this.store.dispatch(setCurrentTabId(tabId));
    }

}
