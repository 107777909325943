import { Action } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { Data } from '../../../../core/models/data.model';
import { type } from '../../../../core/store/util';
import { EntityWidgetConfig } from '../../../../shared/models/widgets.model';
import { DashboardStructure } from '../../models/dashboard.model';

export const DashboardActionTypes = {
    LOAD_DASHBOARD_STRUCTURE: type('DASHBOARD:LOAD_DASHBOARD_STRUCTURE'),
    LOAD_DASHBOARD_STRUCTURE_SUCCEEDED: type('DASHBOARD:LOAD_DASHBOARD_STRUCTURE_SUCCEEDED'),
    LOAD_DASHBOARD_STRUCTURE_FAILED: type('DASHBOARD:LOAD_DASHBOARD_STRUCTURE_FAILED'),

    LOAD_DASHBOARD_DATA: type('DASHBOARD:LOAD_DASHBOARD_DATA'),
    LOAD_DASHBOARD_DATA_SUCCEEDED: type('DASHBOARD:LOAD_DASHBOARD_DATA_SUCCEEDED'),
    LOAD_DASHBOARD_DATA_FAILED: type('DASHBOARD:LOAD_DASHBOARD_DATA_FAILED'),

    LOAD_DASHBOARD_UPDATED_DATA: type('DASHBOARD:LOAD_DASHBOARD_UPDATED_DATA'),
    LOAD_DASHBOARD_UPDATED_DATA_SUCCEEDED: type('DASHBOARD:LOAD_DASHBOARD_UPDATED_DATA_SUCCEEDED'),
    LOAD_DASHBOARD_UPDATED_DATA_FAILED: type('DASHBOARD:LOAD_DASHBOARD_UPDATED_DATA_FAILED'),

    POST_LOAD_DASHBOARD_DATA: type('DASHBOARD:POST_LOAD_DASHBOARD_DATA'),
    POST_LOAD_DASHBOARD_DATA_SUCCEEDED: type('DASHBOARD:POST_LOAD_DASHBOARD_DATA_SUCCEEDED'),
    POST_LOAD_DASHBOARD_DATA_FAILED: type('DASHBOARD:POST_LOAD_DASHBOARD_DATA_FAILED'),
};

/** LoadDPCInitializeStructureAction **/

export interface LoadDashboardStructureAction extends Action {
    payload: {
        dashboardId: string;
    };
}

export function loadDashboardStructure(dashboardId: string): LoadDashboardStructureAction {
    return {
        type: DashboardActionTypes.LOAD_DASHBOARD_STRUCTURE,
        payload: {
            dashboardId,
        },
    };
}

/** LoadDashboardStructureSucceededAction **/

export interface LoadDashboardStructureSucceededAction extends Action {
    payload: {
        structure: DashboardStructure,
    };
}

export function loadDashboardStructureSucceeded(structure: DashboardStructure): LoadDashboardStructureSucceededAction {
    return {
        type: DashboardActionTypes.LOAD_DASHBOARD_STRUCTURE_SUCCEEDED,
        payload: {
            structure,
        },
    };
}

/** LoadDashboardStructureFailedAction **/

export interface LoadDashboardStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadDashboardStructureFailed(error: AppError): LoadDashboardStructureFailedAction {
    return {
        type: DashboardActionTypes.LOAD_DASHBOARD_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadDPCInitializeStructureAction **/

export interface LoadDashboardDataAction extends Action {
    payload: {
        dashboardId: string;
    };
}

export function loadDashboardData(dashboardId: string): LoadDashboardDataAction {
    return {
        type: DashboardActionTypes.LOAD_DASHBOARD_DATA,
        payload: {
            dashboardId,
        },
    };
}

/** LoadDashboardDataSucceededAction **/

export interface LoadDashboardDataSucceededAction extends Action {
    payload: {
        data: Data;
        dashboardId: string;
    };
}

export function loadDashboardDataSucceeded(data: Data, dashboardId: string): LoadDashboardDataSucceededAction {
    return {
        type: DashboardActionTypes.LOAD_DASHBOARD_DATA_SUCCEEDED,
        payload: {
            data,
            dashboardId,
        },
    };
}

/** LoadDashboardDataFailedAction **/

export interface LoadDashboardDataFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadDashboardDataFailed(error: AppError): LoadDashboardDataFailedAction {
    return {
        type: DashboardActionTypes.LOAD_DASHBOARD_DATA_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadDashboardUpdatedDataAction **/

export interface LoadDashboardUpdatedDataAction extends Action {
    payload: {
        dashboardId: string;
    };
}

export function loadDashboardUpdatedData(dashboardId: string): LoadDashboardUpdatedDataAction {
    return {
        type: DashboardActionTypes.LOAD_DASHBOARD_UPDATED_DATA,
        payload: {
            dashboardId,
        },
    };
}

/** LoadDashboardUpdatedDataSucceededAction **/

export interface LoadDashboardUpdatedDataSucceededAction extends Action {
    payload: {
        data: Data;
        dashboardId: string;
    };
}

export function loadDashboardUpdatedDataSucceeded(data: Data, dashboardId: string): LoadDashboardUpdatedDataSucceededAction {
    return {
        type: DashboardActionTypes.LOAD_DASHBOARD_UPDATED_DATA_SUCCEEDED,
        payload: {
            data,
            dashboardId,
        },
    };
}

/** LoadDashboardUpdatedDataFailedAction **/

export interface LoadDashboardUpdatedDataFailedAction extends Action {
    payload: {
        error: AppError;
        dashboardId: string;
    };
}

export function loadDashboardUpdatedDataFailed(error: AppError, dashboardId: string): LoadDashboardUpdatedDataFailedAction {
    return {
        type: DashboardActionTypes.LOAD_DASHBOARD_UPDATED_DATA_FAILED,
        payload: {
            error,
            dashboardId,
        },
    };
}

/** PostLoadDashboardDataAction **/
export interface PostLoadDashboardDataAction extends Action {
    payload: {
        data: Data;
        structure: EntityWidgetConfig;
        dashboardId: string;
        providerId: string;
    };
}

export function postLoadDashboardData(dashboardId: string, providerId: string, data: Data, structure: EntityWidgetConfig): PostLoadDashboardDataAction {
    return {
        type: DashboardActionTypes.POST_LOAD_DASHBOARD_DATA,
        payload: {
            dashboardId,
            providerId,
            data,
            structure,
        },
    };
}

/** PostLoadDashboardDataSucceededAction **/
export interface PostLoadDashboardDataSucceededAction extends Action {
    payload: {
        data: Data;
        structure: EntityWidgetConfig;
        dashboardId: string;
        providerId: string;
    };
}

export function postLoadDashboardSucceededData(dashboardId: string, providerId: string, data: Data, structure: EntityWidgetConfig): PostLoadDashboardDataSucceededAction {
    return {
        type: DashboardActionTypes.POST_LOAD_DASHBOARD_DATA_SUCCEEDED,
        payload: {
            dashboardId,
            providerId,
            data,
            structure,
        },
    };
}

/** PostLoadDashboardDataFailedAction **/
export interface PostLoadDashboardDataFailedAction extends Action {
    payload: {
        error: AppError;
        dashboardId: string;
        providerId: string;
    };
}

export function postLoadDashboardFailedData(dashboardId: string, providerId: string, error: AppError): PostLoadDashboardDataFailedAction {
    return {
        type: DashboardActionTypes.POST_LOAD_DASHBOARD_DATA_FAILED,
        payload: {
            dashboardId,
            providerId,
            error,
        },
    };
}
