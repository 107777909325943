import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SearchState } from "../state/search.state";

export const selectSearch = createFeatureSelector<SearchState>('search');

export const selectTerm = createSelector(
    selectSearch,
    (state: SearchState) => state.term
);

export const selectProducts = createSelector(
    selectSearch,
    (state: SearchState) => state.products
);

export const selectSearching = createSelector(
    selectSearch,
    (state: SearchState) => state.searching
);

export const selectOverlayOpen = createSelector(
    selectSearch,
    (state: SearchState) => state.overlayOpen
);

export const selectNotifyOnProductActivation = createSelector(
    selectSearch,
    (state: SearchState) => state.notifyOnProductActivation
);
