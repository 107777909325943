<div class="wms-dialog-inner popup-blocked-dialog tw-bg-primary-bg tw-text-black dark:tw-text-white">
    <nav class="navbar navbar-toggleable-md header-bar navbar-inverse header-bar tw-py-4">
        <div class="3xl:tw-container tw-w-full tw-mx-auto tw-px-8 tw-flex">
            <i class="mr-auto"></i>
            <div class="navbar-nav">
                <a (click)="onCloseClick($event)" class="nav-link icon-link close-modal-button" href="#">
                    <app-icon class="derifin-icon" icon="derifin:schliessen"></app-icon>
                </a>
            </div>
        </div>
    </nav>
    
    
    <div class="modal-body">
        <h1 class="modal-form__title">{{ 'modals.createPopupBlockedDialog.title' | transloco }}</h1>
    
        <p>{{ 'modals.createPopupBlockedDialog.content' | transloco }}</p>
    
        <div class="modal-actions">
            <a class="modal-actions__action" href="#" (click)="onCloseClick($event)">
                {{ 'modals.createPopupBlockedDialog.close' | transloco }}
            </a>
            <a class="modal-actions__action" href="#" (click)="onDownload($event)">
                {{ 'modals.createPopupBlockedDialog.download' | transloco }}
            </a>
        </div>
    </div>
</div>
