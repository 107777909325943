<h4 class="card-headline">{{config.options.title}}</h4>
<div class="custom-controls-stacked" [formGroup]="filterForm">
    <label class="custom-control custom-radio">
        <input id="tradeDateTypeMonth"
               name="type" type="radio"
               formControlName="type"
               value="month"
               class="custom-control-input">
        <span class="custom-control-indicator"></span>
        <span class="custom-control-description">Monat</span>
    </label>
    <div *ngIf="filterForm.get('type').value === 'month'" class="custom-control-sub-control" formGroupName="month">
        <div class="form-row row form-group-spacing d-flex">
            <div class="col-7">
                <select class="form-control" formControlName="month">
                    <option value="1">Januar</option>
                    <option value="2">Februar</option>
                    <option value="3">März</option>
                    <option value="4">April</option>
                    <option value="5">Mai</option>
                    <option value="6">Juni</option>
                    <option value="7">Juli</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">Oktober</option>
                    <option value="11">November</option>
                    <option value="12">Dezember</option>
                </select>
            </div>
            <div class="col-5">
                <select class="form-control" formControlName="year">
                    <option *ngFor="let year of availableYears" [value]="year">{{year}}</option>
                </select>
            </div>
        </div>
    </div>

    <label class="custom-control custom-radio">
        <input id="tradeDateTypeRange"
               name="type" type="radio"
               formControlName="type"
               class="custom-control-input"
               value="range">
        <span class="custom-control-indicator"></span>
        <span class="custom-control-description">Zeitraum</span>
    </label>
    <div *ngIf="filterForm.get('type').value === 'range'" class="form-group-sub-control" formGroupName="range">
        <div class="row form-group-spacing d-flex">
            <div class="col-3">
                <label class="col-form-label">Von</label>
            </div>
            <div class="col-9">
                <app-date-input formControlName="from" format="DD.MM.YYYY" placeholder="dd.mm.yyyy"></app-date-input>
            </div>
        </div>
        <div class="form-row row form-group-spacing d-flex">
            <div class="col-3">
                <label class="col-form-label">Bis</label>
            </div>
            <div class="col-9">
                <app-date-input formControlName="to" format="DD.MM.YYYY" placeholder="dd.mm.yyyy"></app-date-input>
            </div>

        </div>
    </div>
</div>
