/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 7/4//17.
 */

import { Action, combineReducers } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { EntitiesState } from '../../../../core/store/state/entities.state';
import { StringMappingState } from '../../../../core/store/state/mapping.state';
import { QuerySet } from '../../../../shared/models/entities.model';
import {
    CreateQuerySetAction,
    CreateQuerySetFailedAction,
    DeleteQuerySetFailedAction,
    LoadQuerySetCountFailedAction,
    QuerySetActionTypes,
    QuerySetCountLoadedAction,
    QuerySetCreatedAction,
} from '../actions/query-set.actions';
import { QuerySetState } from '../state/query-set.state';

export const initialState: QuerySetState = {
    entities: {},
    productTypeMapping: {},
    loadingQuerySet: false,
    error: null,
};

/**
 * The reducer responsible for the entities of the @link{QuerySetState}
 * @param state
 * @param action
 * @returns {EntitiesState<QuerySet>}
 */
export function querySetEntitiesReducer(
    state: EntitiesState<QuerySet> = initialState.entities,
    action: Action,
): EntitiesState<QuerySet> {
    switch (action.type) {
        case QuerySetActionTypes.QUERY_SET_CREATED:
            const querySetCreatedAction = action as QuerySetCreatedAction;
            return {
                ...state,
                [querySetCreatedAction.payload.querySet.id]: querySetCreatedAction.payload.querySet,
            };

        case QuerySetActionTypes.QUERY_SET_COUNT_LOADED: {
            const querySetCountLoadedAction = action as QuerySetCountLoadedAction;
            return {
                ...state,
                [querySetCountLoadedAction.payload.querySetId]: {
                    ...state[querySetCountLoadedAction.payload.querySetId],
                    count: querySetCountLoadedAction.payload.count,
                },
            };
        }

        default:
            return state;
    }
}

/**
 * The reducer responsible for the product type mapping of the @link{QuerySetState}
 * @param state
 * @param action
 * @returns {StringMappingState<string>}
 */
export function querySetProductTypeMappingReducer(
    state: StringMappingState<string> = initialState.productTypeMapping,
    action: Action,
): StringMappingState<string> {
    switch (action.type) {
        case QuerySetActionTypes.QUERY_SET_CREATED:
            const querySetCreatedAction = action as QuerySetCreatedAction;
            return {
                ...state,
                [querySetCreatedAction.payload.productTypeId]: querySetCreatedAction.payload.querySet.id,
            };

        default:
            return state;
    }
}

/**
 * The reducer responsible for the loading query set flag of the @link{QuerySetState}
 * @param state
 * @param action
 * @returns {boolean}
 */
export function loadingQuerySetReducer(state: boolean = initialState.loadingQuerySet, action: Action): boolean {
    switch (action.type) {
        case QuerySetActionTypes.CREATE_QUERY_SET:
            const createQuerySetAction = action as CreateQuerySetAction;
            return !createQuerySetAction.payload.resort;

        case QuerySetActionTypes.CREATE_QUERY_SET_FAILED:
        case QuerySetActionTypes.QUERY_SET_CREATED:
            return false;

        default:
            return state;
    }
}

export function errorReducer(state: AppError = initialState.error, action: Action): AppError {
    switch (action.type) {
        case QuerySetActionTypes.QUERY_SET_CREATED:
        case QuerySetActionTypes.QUERY_SET_DELETED:
        case QuerySetActionTypes.QUERY_SET_COUNT_LOADED:
            return null;

        case QuerySetActionTypes.CREATE_QUERY_SET_FAILED:
        case QuerySetActionTypes.DELETE_QUERY_SET_FAILED:
        case QuerySetActionTypes.LOAD_QUERY_SET_COUNT_FAILED:
            const errorAction = action as CreateQuerySetFailedAction | DeleteQuerySetFailedAction | LoadQuerySetCountFailedAction;

            return {
                ...errorAction.payload.error,
            };

        default:
            return state;
    }
}

/**
 * The reducer responsible for the @link{QuerySetState}
 * @type {ActionReducer<QuerySetState>}
 */
export const querySetReducer = combineReducers({
    entities: querySetEntitiesReducer,
    productTypeMapping: querySetProductTypeMappingReducer,
    loadingQuerySet: loadingQuerySetReducer,
    error: errorReducer,
});
