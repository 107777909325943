/**
 * Created by Alex Klein <alex@rocketloop.de> on 03/19/18.
 */

import { Action } from '@ngrx/store';
import {
    DashboardActionTypes,
    LoadDashboardDataSucceededAction,
    LoadDashboardStructureSucceededAction,
    LoadDashboardUpdatedDataSucceededAction,
    PostLoadDashboardDataAction, PostLoadDashboardDataFailedAction,
    PostLoadDashboardDataSucceededAction,
} from '../actions/dashboard.actions';
import { DashboardState, PostLoadingState } from '../state/dashboard.state';
import { get, set } from 'lodash/fp';

export const initialState: DashboardState = {
    data: null,
    structure: null,
    postLoading: {},
    postLoadingCount: {},
};

/**
 * The reducer responsible for the @link{DashboardState}
 * @param state
 * @param action
 * @returns {any}
 */
export function reducer(state: DashboardState = initialState, action: Action): DashboardState {
    switch (action.type) {

        case DashboardActionTypes.LOAD_DASHBOARD_STRUCTURE_SUCCEEDED: {
            const loadDashboardStructureSucceeded = action as LoadDashboardStructureSucceededAction;
            return {
                ...state,
                structure: loadDashboardStructureSucceeded.payload.structure,
            };
        }

        case DashboardActionTypes.LOAD_DASHBOARD_DATA_SUCCEEDED: {
            const loadDashboardDataSucceeded = action as LoadDashboardDataSucceededAction;
            return {
                ...state,
                data: loadDashboardDataSucceeded.payload.data,
            };
        }

        case DashboardActionTypes.LOAD_DASHBOARD_UPDATED_DATA_SUCCEEDED: {
            const loadDashboardUpdatedDataSucceeded = action as LoadDashboardUpdatedDataSucceededAction;
            const updateData = loadDashboardUpdatedDataSucceeded.payload.data;
            const updateProperties = ['path', 'options.path', 'options.text', 'options.title'];
            let newData = state.data;

            state.structure.widgets.filter((s) => !!s.options.refreshable).forEach((widget) =>
                updateProperties.forEach((k) => {
                    const uppath = get(k, widget);
                    const updata = get(uppath, updateData);

                    if (updata) {
                        newData = set(uppath, updata, newData);
                    }
                })
            );

            return {
                ...state,
                data: newData
            };
        }

        case DashboardActionTypes.POST_LOAD_DASHBOARD_DATA: {
            const postLoadDashboardData = action as PostLoadDashboardDataAction;
            const postLoadingData: PostLoadingState = state.postLoading[postLoadDashboardData.payload.providerId];

            const loadingState: PostLoadingState = {
                ...(postLoadingData ? postLoadingData : { data: null, error: null, count: 0 }),
                state: true,
            };

            const postLoadingCount = state.postLoadingCount[postLoadDashboardData.payload.structure.type] || 0;

            return {
                ...state,
                postLoading: {
                    ...(state.postLoading || {}),
                    [postLoadDashboardData.payload.providerId]: loadingState,
                },
                postLoadingCount: {
                    ...(state.postLoadingCount || {}),
                    [postLoadDashboardData.payload.structure.type]: postLoadingCount,
                },
            };
        }

        case DashboardActionTypes.POST_LOAD_DASHBOARD_DATA_SUCCEEDED: {
            const postLoadDashboardDataSucceeded = action as PostLoadDashboardDataSucceededAction;
            const postLoadingDataSuccess: PostLoadingState = state.postLoading[postLoadDashboardDataSucceeded.payload.providerId];
            const upath = postLoadDashboardDataSucceeded.payload.structure.path || postLoadDashboardDataSucceeded.payload.structure.options.path;
            const udata = get(upath, postLoadDashboardDataSucceeded.payload.data);

            const loadingStateSucess: PostLoadingState = {
                ...(postLoadingDataSuccess ? postLoadingDataSuccess : { error: null, data: null }),
                state: false,
                count: (postLoadingDataSuccess ? postLoadingDataSuccess.count + 1 : 1)
            };

            const postLoadingCount = state.postLoadingCount[postLoadDashboardDataSucceeded.payload.structure.type] || 0;

            return {
                ...state,
                postLoading: {
                    ...(state.postLoading || {}),
                    [postLoadDashboardDataSucceeded.payload.providerId]: loadingStateSucess
                },
                data: set(upath, udata, state.data),
                postLoadingCount: {
                    ...(state.postLoadingCount || {}),
                    [postLoadDashboardDataSucceeded.payload.structure.type]: postLoadingCount + 1
                }
            };
        }
        case DashboardActionTypes.POST_LOAD_DASHBOARD_DATA_FAILED: {
            const postLoadDashboardDataFailed = action as PostLoadDashboardDataFailedAction;
            const postLoadingDataFailed: PostLoadingState = state.postLoading[postLoadDashboardDataFailed.payload.providerId];

            const loadingStateFailed: PostLoadingState = {
                ...(postLoadingDataFailed ? postLoadingDataFailed : { data: null, count: 0 }),
                state: false,
                error: postLoadDashboardDataFailed.payload.error,
            };

            return {
                ...state,
                postLoading: {
                    ...(state.postLoading || {}),
                    [postLoadDashboardDataFailed.payload.providerId]: loadingStateFailed,
                },
            };
        }
        default:
            return state;
    }
}
