import { Component, Input, OnInit } from '@angular/core';
import { EntityTableColumn } from '../../models/entities.model';
import { EntityTableCellComponent } from '../entity-table-cell/entity-table-cell.component';
import { NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { EntityTableDecoratorService } from '../../../core/services/entity-table-decorator.service';

@Component({
    selector: 'app-entity-details-table',
    templateUrl: './entity-details-table.component.html',
    styleUrls: ['./entity-details-table.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, EntityTableCellComponent]
})
export class EntityDetailsTableComponent implements OnInit {

    @Input() public entity: any;

    @Input() public fields: EntityTableColumn[];

    constructor(public entityTableDecorator: EntityTableDecoratorService) { }

    public ngOnInit() {
    }

    public isSeparatorRow(field: EntityTableColumn) {
        return field.decorator && field.decorator === 'separator';
    }
}
