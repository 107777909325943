<div [formGroup]="form" class="form-group row input-row">
    <div class="col double-dropdown-input__label label-selection">
        {{ config?.options?.label }}
        <div *ngIf="config?.options?.helpText" class="double-dropdown-input__info" [matTooltip]="config?.options?.helpText"></div>
    </div>
    <div class="operator-selection">
        <div class="app-filter-radio-button-group">
            <mat-button-toggle-group [formControlName]="paths.OPERATOR">
                <mat-button-toggle *ngFor="let value of operatorValues" [value]="value.id" disableRipple="true">
                    {{ value.label }}
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
    <div class="value-selection">
        <div class="value-input" [class.has-danger]="!disabled && !form.get(paths.INPUT)?.valid && form.get(paths.INPUT)?.touched">
            <app-number-input class="form-control" [formControlName]="paths.INPUT"></app-number-input>
        </div>
        <div class="value-description">
            <span *ngIf="config?.options?.unit" class="double-dropdown-input__unit">
                {{ config?.options?.unit }}
            </span>
            <span *ngIf="config?.options?.sampleInput" class="double-dropdown-input__sample">
                {{ config?.options?.sampleInput }}
            </span>
        </div>
    </div>
</div>
