// Holidays lookup table
import { Moment } from 'moment';

const holidays = {
    '1-0':   true,
    '24-11': true,
    '25-11': true,
    '26-11': true,
    '1-4':   true,
    '3-9':   true,
    '31-11': true,
};

export const isMomentHoliday = (date: Moment) => {
    const month = date.month();
    const day = date.date();

    return !!holidays[`${day}-${month}`];
};

export const isMomentWeekend = (date: Moment) => {
    return [0, 6].includes(date.day());
};

export const holidayDateFilter = (date: Moment) => {
    return date && !isMomentWeekend(date) && !isMomentHoliday(date);
}
