import {Action} from '@ngrx/store';
import {AppError} from '../../../../core/errors/base.errors';
import {type} from '../../../../core/store/util';
import {FileListGroup} from '../../models/file-list-administration.model';

export const FileListAdministrationActionTypes = {
    LOAD_DATA_REQUEST: type('FILE_LIST_ADMINISTRATION:LOAD_DATA_REQUEST'),
    LOAD_DATA_SUCCESS: type('FILE_LIST_ADMINISTRATION:LOAD_DATA_SUCCESS'),
    LOAD_DATA_FAILURE: type('FILE_LIST_ADMINISTRATION:LOAD_DATA_FAILURE'),

    SAVE_DATA_REQUEST: type('FILE_LIST_ADMINISTRATION:SAVE_DATA_REQUEST'),
    SAVE_DATA_SUCCESS: type('FILE_LIST_ADMINISTRATION:SAVE_DATA_SUCCESS'),
    SAVE_DATA_FAILURE: type('FILE_LIST_ADMINISTRATION:SAVE_DATA_FAILURE'),
};

/** Data and Structure actions **/
export interface LoadDataRequestAction extends Action {
    payload: {
        moduleId: string;
    };
}

export function loadDataRequest(moduleId: string): LoadDataRequestAction {
    return {
        type: FileListAdministrationActionTypes.LOAD_DATA_REQUEST,
        payload: {
            moduleId
        }
    };
}

export interface LoadDataSuccessAction extends Action {
    payload: {
        data: FileListGroup;
    };
}

export function loadDataSuccess(data: FileListGroup): LoadDataSuccessAction {
    return {
        type: FileListAdministrationActionTypes.LOAD_DATA_SUCCESS,
        payload: {
            data,
        },
    };
}

export interface LoadDataFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadDataFailure(error: AppError): LoadDataFailureAction {
    return {
        type: FileListAdministrationActionTypes.LOAD_DATA_FAILURE,
        payload: {
            error,
        },
    };

}


/** Save data actions **/
export interface SaveDataRequestAction extends Action {
    payload: {
        moduleId: string;
        data: FileListGroup;
    };
}

export function saveDataRequest(moduleId: string, data: FileListGroup): SaveDataRequestAction {
    return {
        type: FileListAdministrationActionTypes.SAVE_DATA_REQUEST,
        payload: {
            moduleId,
            data,
        },
    };
}

export interface SaveDataSuccessAction extends Action {
    payload: {
        data: FileListGroup;
    };
}

export function saveDataSuccess(data: FileListGroup): SaveDataSuccessAction {
    return {
        type: FileListAdministrationActionTypes.SAVE_DATA_SUCCESS,
        payload: {
            data,
        },
    };
}

export interface SaveDataFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function saveDataFailure(error: AppError): SaveDataFailureAction {
    return {
        type: FileListAdministrationActionTypes.SAVE_DATA_FAILURE,
        payload: {
            error,
        },
    };
}
