import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dqToIsin',
    standalone: true
})
export class DqToIsinPipe implements PipeTransform {
    public transform(value: string): string {
        if (value.substr(0, 4) === 'QP00') {
            return value.substring(4);
        } else {
            return value;
        }
    }
}
