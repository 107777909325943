import {
    AfterViewChecked,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { grayscaleIE10 } from '../../../core/helpers/colors.helper';
import { EntityWidgetTableOptions } from '../../../routes/product-details/models/product-details-structure.model';
import { Entity } from '../../models/entities.model';
import { MarkdownToHtmlPipe } from '../../pipes/markdown-to-html.pipe';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-markdown',
    templateUrl: './markdown.component.html',
    styleUrls: ['./markdown.component.scss'],
    standalone: true,
    imports: [NgClass, MarkdownToHtmlPipe]
})
export class MarkdownComponent implements OnInit, OnChanges, AfterViewChecked {

    @Input() public text: string;
    @Input() public readMode = false;
    @Input() public columns = false;
    
    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter<{ type: string, payload: any }>();

    public isDirty = false;

    constructor(
        private elRef: ElementRef<HTMLElement>,
        private renderer: Renderer2
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.text) {
            this.isDirty = true;
        }
    }

    public ngOnInit() {

    }

    public ngAfterViewChecked() {
        if (this.isDirty) {
            this._hookupEntityTables();
            this._hookupLinks();
            this.isDirty = false;
        }
    }

    private _hookupEntityTables() {
        const divs = this.elRef.nativeElement.querySelectorAll<HTMLDivElement>('div[data-entity-table]');

        divs.forEach((div) => {
            const entityWidgetTableElement = document.createElement('entity-widget-table-element');
            const json = JSON.parse(atob(div.dataset.entityTable)) as { config: EntityWidgetTableOptions, data: Entity[] };

            entityWidgetTableElement.options = json.config;
            entityWidgetTableElement.data = json.data;

            this.renderer.appendChild(div, entityWidgetTableElement);
        });
    }

    /**
     * Overwrites the links starting with an $ with internal click actions
     * @private
     */
    private _hookupLinks() {
        const links = this.elRef.nativeElement.querySelectorAll('a');

        Array.from(links).forEach((a) => {
            if (!a || !a.hasAttribute('href')) { return; }

            const href = a.getAttribute('href').replace('unsafe:', '');
            if (href.charAt(0) === '$') {
                a.addEventListener('click', (e) => {
                    e.preventDefault();
                    const url = href.slice(1);
                    if (url.indexOf(':') >= 0) {
                        const splittedUrl = url.split(':');
                        this.onActionClicked(splittedUrl[0], JSON.parse(atob(splittedUrl[1])));
                    } else {
                        this.onActionClicked(url, {});
                    }
                });
            }
        });
    }

    /**
     * Called when an action was clicked
     * @param {string} actionType
     * @param payload
     */
    public onActionClicked(actionType: string, payload: any) {
        this.action.next({
            type: actionType,
            payload,
        });
    }

}
