import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-widget-grid',
    templateUrl: './widget-grid.component.html',
    styleUrls: ['./widget-grid.component.scss'],
    standalone: true
})
export class WidgetGridComponent implements OnInit {

    constructor() {
    }

    public ngOnInit() {
    }

}
