import { Injectable } from '@angular/core';
import { DerifinApi } from './derifin.api';
import { HttpClient } from '@angular/common/http';
import { AuthStoreService } from '../services/auth-store.service';
import { LogglyLoggerService } from '../services/loggly-logger.service';
import { ConfigurationService } from '../services/configuration.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringObject } from '../models/generic.model';

@Injectable({providedIn: 'root'})
export class EquivalenceApi extends DerifinApi {

    constructor(http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, configService: ConfigurationService) {
        super('product', configService.configuration.environment.id, http, authStoreService, loggerService, configService);
    }

    public checkEquivalence(
        entityId: string,
        deriBwIds: Array<string>,
        label: string,
        watchlistId?: string
    ): Observable<any> {
        return this.post('equivalence-check', {
            entityId,
            deriBwIds,
            label,
            watchlistId
        }).pipe(
            map((res) => res.data)
        );
    }

    public updateEquivalenceComments(
        productDq: string,
        comments: StringObject
    ): Observable<void> {
        return this.put('equivalence-check/comments', {
            productDq,
            comments
        });
    }
}
