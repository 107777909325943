<div class="tw-grid tw-grid-cols-4 2xl:tw-grid-cols-6 tw-gap-2.5">
    <ng-container *ngFor="let button of options.buttons">
        <ng-container *ngIf="button.external">
            <a
                type="button"
                [href]="button.target"
                target="_blank"
                [class]="buttonStyle({ 'appearance': button.style})">
                <ng-template *ngTemplateOutlet="linkInner"></ng-template>
            </a>
        </ng-container>
        <ng-container *ngIf="!button.external">
            <a
                type="button"
                [routerLink]="routerLinkPath({ type: button.type, id: button.target})"
                [class]="buttonStyle({ 'appearance': button.style})">
                <ng-template *ngTemplateOutlet="linkInner"></ng-template>
            </a>
        </ng-container>
        <ng-template #linkInner>
            <span [class.group-hover:tw-text-white]="button.style === 'OUTLINE'">{{ button.label }}</span>
            <span
                class="material-symbols-rounded"
                [ngClass]="{'tw-text-primary-base group-hover:tw-text-white' : button.style === 'OUTLINE'}">
                <ng-container *ngIf="button.external">open_in_new</ng-container>
                <ng-container *ngIf="!button.external">arrow_circle_right</ng-container>
            </span>
        </ng-template>
    </ng-container>
</div>
