import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { get, uniq } from 'lodash';
import { EntityTableDecoratorService } from '../../../core/services/entity-table-decorator.service';
import { EntityTableStyleDecoratorService } from '../../../core/services/entity-table-style-decorator.service';
import { truthy } from '../../helpers/general.helper';
import { Entity } from '../../models/entities.model';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[appEntityTableCell]',
    templateUrl: './entity-table-cell.component.html',
    styleUrls: ['./entity-table-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class EntityTableCellComponent implements OnInit, OnChanges {
    @Input() public decorator: string;
    @Input() public decoratorOptions: any;
    @Input() public styleDecorator: string;
    @Input() public styleDecoratorOptions: any;
    @Input() public entity: Entity;
    @Input() public path: string;
    @Input() public pathToStyleHints: string;
    @Input() public pathToStyleDecorator: string;
    @Input() public classPrefix = 'entity-table-cell';
    @Input() public multiIndex = 0;

    @HostBinding('class')
    get classNames() {
        const externalClasses = this.el.nativeElement.className.split(' ');
        const decoratorClass = (this.decorator) ? `${this.classPrefix}--${this.decorator}` : '';
        const styleHintClasses = (this.decoratorOptions && this.decoratorOptions.styleHints) ? this.decoratorOptions.styleHints.map(
            (styleHint) => `${this.classPrefix}--${styleHint}`,
        ) : [];

        const cellStyleHints: string[] = this.pathToStyleHints ? get(this.entity, this.pathToStyleHints) : [];

        return uniq([
            ...externalClasses,
            this.classPrefix,
            decoratorClass,
            ...styleHintClasses,
            ...cellStyleHints,
        ].filter(truthy)).join(' ');
    }

    @HostBinding('innerHtml') public content: string;

    constructor(
        private cd: ChangeDetectorRef,
        private decoraterService: EntityTableDecoratorService,
        private el: ElementRef,
    ) {

    }

    public ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.decorator || changes.decoratorOptions || changes.entity || changes.path) {
            this._updateContent();
            this.cd.markForCheck();
        }
    }

    private _updateContent() {
        this.content = this.decoraterService.applyDecorator(
            this.decorator,
            this.entity,
            this.path,
            this.decoratorOptions,
            this.multiIndex,
        );
    }

}
