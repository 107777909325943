import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    HostBinding,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import * as moment from 'moment';
import { throttleTime } from 'rxjs/operators';
import { CalendarHeaderComponent } from '../calendar-header/calendar-header.component';
import { IconComponent } from '../icon/icon.component';

@Component({
    selector: 'app-date-input',
    templateUrl: './date-input.component.html',
    styleUrls: ['./date-input.component.scss'],
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateInputComponent),
            multi: true,
        }, {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DateInputComponent),
            multi: true,
        }],
    standalone: true,
    imports: [MatDatepickerModule, FormsModule, ReactiveFormsModule, IconComponent]
})
export class DateInputComponent implements OnInit, ControlValueAccessor, Validator {
    public customHeader = CalendarHeaderComponent;

    @HostBinding('class.date-input') public dateInputClass = true;
    @Input() public placeholder: string;

    @Input() set excludeDays(days: string[]) {
        this._excludeDays = days.map((day) => moment(day));
    }

    public _excludeDays: moment.Moment[] = [];

    @Input() public disabled: boolean;
    @Input() public format: string;
    @Input() public dateFilter: (date: moment.Moment) => boolean;
    @Input() public removeOffset = false;

    @ViewChild('datepicker', { static: true }) public datepicker: MatDatepicker<moment.Moment>;
    @ViewChild('input', { static: true }) public input: ElementRef<HTMLInputElement>;

    @Input() public disableChangeEventOnInit = false;

    @HostBinding('class.date-input--full-width')
    @Input() public fullWidth = false;

    public lastValidValue = null;
    public datepickerInputValue: moment.Moment;
    public dateControl = new UntypedFormControl(moment());

    constructor(private readonly cd: ChangeDetectorRef) {}

    public ngOnInit() {
        if (!this.disableChangeEventOnInit) {
            setTimeout(() => {
                this.onInputChanged();
            }, 0);
        }

        this.dateControl.valueChanges.subscribe((value) => {
            this.onInputChanged();
        });
    }

    public onChange() {}
    public onTouched() {}

    public writeValue(isoDate: string): void {
        let parsedDate = moment(isoDate, this.format ? this.format : undefined);

        if (parsedDate && !parsedDate.isValid()) {
            parsedDate = null;
        }

        this.dateControl.setValue(parsedDate, { emitEvent: false });
        this.lastValidValue = parsedDate;
    }

    public convertValueToString(value: moment.Moment) {
        if (this.format) {
            return value.format(this.format);
        } else {
            return value.toISOString(!this.removeOffset);
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            const currentValue = this.dateControl.value;
            this.lastValidValue = currentValue;

            if (fn) {
                fn((currentValue) ? this.convertValueToString(currentValue) : null);
            }
        };
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public onInputChanged() {
        const validationResult = this.validate(null);

        if (!validationResult) {
            this.onChange();
        } else {
            this.dateControl.setValue(this.lastValidValue);
        }
    }

    public onInputKeyDown() {
        if (this.datepicker.opened) {
            this.datepicker.close();
        }
    }

    public validate(c: AbstractControl): ValidationErrors | null {
        if (this.dateControl) {
            if (this.dateControl.valid && !this.isValueExcluded()) {
                return null;
            } else {
                return {
                    dateInput: 'Invalid date specified.',
                };
            }
        } else {
            return null;
        }
    }

    public onOpenDatepicker() {
        if (!this.disabled) {
            this.datepicker.open();
        }
    }

    public onDatepickerOpened(event) {
        setTimeout(() => {
            this.input.nativeElement.focus();
        }, 0);
    }

    public onResetClicked(): void {
        this.dateControl.setValue(null);
    }

    public filter = (date: moment.Moment) => {
        let result = true;

        if (this?._excludeDays) {
            result = !this._excludeDays.reduce((excluded, day) => {
                return excluded || date.isSame(day, 'day');
            }, false);
        }

        if (this.dateFilter && result) {
            result = this.dateFilter(date);
        }

        return result;
    }

    private isValueExcluded(): boolean {
        const currentValue = this.dateControl.value;

        return this._excludeDays.reduce((excluded, day) => {
            return excluded || currentValue.isSame(day, 'day');
        }, false);
    }
}
