import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormControlConfig, FormControlSelectOption } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';
import { ObjectMaker } from '../../../core/helpers/form.helper';
import { NgIf, NgFor } from '@angular/common';

/**
 * Filter radio group component that is repsonsible for a radio group filter. It includes one or more radio buttons.
 */
@Component({
    selector: 'app-filter-radio-group',
    templateUrl: './filter-radio-group.component.html',
    styleUrls: ['./filter-radio-group.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor]
})
export class FilterRadioGroupComponent implements OnInit, SingleValueFilterControl<string> {

    @Input() public config: FormControlConfig;

    @Input() public values: FormControlSelectOption[];

    @Input() public disabled = false;

    @Input() public visibleInput = true;

    public _value: any;

    public onChange: () => void;

    public onTouched: () => void;

    constructor() {
    }

    public ngOnInit() {
    }

    /**
     * Writes the value of the radio group
     * @param {string} value
     */
    public writeValue(value: any): void {
        // tslint:disable-next-line:triple-equals
        if (this._value != value) {
            this._value = value;
        }
    }

    /**
     * Checks if the filter value us valid
     * @param {string} value
     * @returns {boolean}
     * @private
     */
    protected _isValidValue(value: any) {
        if (this.values) {
            return this.values.reduce((hasValue, validValue) => {
                // tslint:disable-next-line:triple-equals
                return value == validValue.id || hasValue;
            }, false);
        } else {
            return true;
        }

    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this._value);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this._isValidValue(this._value)) ? null : ObjectMaker.toJson('radioGroupError');
    }

    /**
     * Called when a radio state was changed
     * @param e
     * @param value
     */
    public onRadioChange(e: Event, value: FormControlSelectOption) {
        e.preventDefault();
        const target = e.target as HTMLInputElement;
        // tslint:disable-next-line:triple-equals
        if (this._value != value.id && this.onChange) {
            this._value = value.id;
            this.onChange();
        }
    }

}
