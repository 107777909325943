import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EntityTableCellComponent } from '../entity-table-component-cell/entity-table-component-cell.component';
import { Entity } from '../../models/entities.model';
import { get } from 'lodash';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-entity-table-equivalent-products-cell',
    templateUrl: './entity-table-equivalent-products-cell.component.html',
    styleUrls: ['./entity-table-equivalent-products-cell.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, TranslocoLocaleModule]
})
export class EntityTableEquivalentProductsCellComponent implements OnInit, OnChanges, EntityTableCellComponent {

    @HostBinding('class.entity-table-equivalent-products-cell') public textareaEntityTableCellClass = true;

    @Input() public product: Entity;
    @Input() public options: EquivalenceCellOptions;
    @Input() public path: string;

    @Output() public event: EventEmitter<any> = new EventEmitter();

    public currentValue: string = null;
    public data: Entity[] = [];

    constructor() { }

    public ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.product || changes.path) {
            this.data = get(this.product, this.path, []);
        }
    }

    public getWkn(product) {
        return get(product, get(this.options, 'paths.wkn'), '');
    }

    public getIsin(product) {
        return get(product, get(this.options, 'paths.isin'), '');
    }

    public getName(product) {
        return get(product, get(this.options, 'paths.name'), '');
    }

    public getCost(product) {
        return get(product, get(this.options, 'paths.cost'), '');
    }
}

interface EquivalenceCellOptions {
    paths: {
        wkn: string;
        isin: string;
        name: string;
        cost: string;
    }
}
