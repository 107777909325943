/**
 * Created by Alex Klein <alex@rocketloop.de> on 9/7/17.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MultiProductReportRequestData, ReportRequestData, TmcReportRequestData } from '../services/async-link.service';
import { AuthStoreService } from '../services/auth-store.service';
import { ConfigurationService } from '../services/configuration.service';
import { LogglyLoggerService } from '../services/loggly-logger.service';
import { DerifinApi } from './derifin.api';
import { ApiResponse } from './models/api.model';
import { AsyncLinkResponseData } from './models/download.model';
import { Dictionary } from '@ngrx/entity';
import { MultiRowComments } from '../../shared/components/entity-table-multi-row-comment/entity-table-multi-row-comment.interface';
import { StringObject } from '../models/generic.model';
import { DynamicChartRequestData } from '../../shared/models/dynamic-chart.model';

/**
 * API abstraction layer for the Async Link API
 * AsyncLink
 */
@Injectable()
export class AsyncLinkApi extends DerifinApi {
    constructor(http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, appConfigService: ConfigurationService) {
        super('', appConfigService.configuration.environment.id, http, authStoreService, loggerService, appConfigService);
    }

    /**
     * Get the one pager for the specified product ids from the Download API.
     * The type defines the entity type/instrument type of the product(s).
     */
    public getOnePager(
        productDqs: string[],
        type: string,
        targetMarketCriteria?: string,
        context?: string,
    ): Observable<AsyncLinkResponseData> {
        const params: any = {};

        if (targetMarketCriteria) {
            params.tmc = targetMarketCriteria;
        }

        return this.post('download/onepager', {dqs: productDqs, type, context}, {params}).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    public getPdf(link: string): Observable<any> {
        return this.http.get(link).pipe(map((response: Response) => response.blob()));
    }

    /**
     * Get the excel sheet for the products
     */
    public getProductsExcelSheet(dqs: string[], context: string): Observable<AsyncLinkResponseData> {
        let request: Observable<any>;

        if (!dqs || !Array.isArray(dqs) || !dqs.length) {
            request = this.get('/m/product/download-excel-all');
        } else {
            request = this.post('/m/product/download-excel', {
                dqs, context
            });
        }

        return request.pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    /**
     * Get the excel sheet for the products
     */
    public getWatchlistExcelSheet(
        watchlistId: string,
        dqs: string[],
        context: string
    ): Observable<AsyncLinkResponseData> {
        return this.post('/m/watchlist/download-excel', { watchlistId, dqs, context }).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    /**
     * Get the excel sheet for the users in the user admin
     */
    public getUsersExcelSheet(users: string[]): Observable<AsyncLinkResponseData> {
        return this.post('wms/user/download-excel', [...users]).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    public getUsersExcelAllSheet() {
        return this.get('wms/user/download-excel-all').pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    public getShortlistExport(id, dqs?: string[]): Observable<AsyncLinkResponseData> {
        let selectedProducts = [];

        if (dqs) {
            selectedProducts = [...dqs];
        }

        return this.post(`m/shortlist/${id}/download`, selectedProducts).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    public getShortlistGroupedExport(id: string): Observable<AsyncLinkResponseData> {
        return this.post(`m/shortlist/${id}/download-excel/grouped/instrumentType`, {}).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    public getRFQRequestExport(moduleId: string, quoteId: string, redirectToken: string) {
        return this.post(`m/product-designer/${moduleId}/quote/export-request`, { quoteId, redirectToken }).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    public getRFQResultExport(moduleId: string, quoteId: string, redirectToken: string) {
        return this.post(`m/product-designer/${moduleId}/quote/export-result`, { quoteId, redirectToken }).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    public getRFQMultiExcelExport(moduleId: string, quoteId: string) {
        return this.get(`m/product-designer/${moduleId}/quote/${quoteId}/export-multi`).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    /**
     * Get the excel sheet for the trades
     */
    public getTradesExcelSheet(dqs: string[]): Observable<AsyncLinkResponseData> {
        return this.post('download/trade', {dqs}).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    /**
     * Get the excel all sheet for the trades
     */
    public getTradesExcelAllSheet(sessionId: string): Observable<AsyncLinkResponseData> {
        return this.post('download/trade', {sessionId}).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    /**
     * Get the excel sheet for the assets
     */
    public getAssetsExcelSheet(sessionId: string, dqs: string[]): Observable<AsyncLinkResponseData> {
        return this.post('download/asset', {sessionId, dqs}).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    /**
     * Get the excel all sheet for the assets
     */
    public getAssetsExcelAllSheet(sessionId: string): Observable<AsyncLinkResponseData> {
        return this.post('download/asset', {sessionId}).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    /**
     * Get the report for the given config
     */
    public getTmcCheckReport(data: TmcReportRequestData): Observable<AsyncLinkResponseData> {
        return this.post('download/tmc-check-report', data).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    /**
     * Get the tmc export for the given watchlist id and comments
     */
    public getTmcExportReport(watchlistId: string, comments: Dictionary<string>): Observable<AsyncLinkResponseData> {
        return this.post(`m/watchlist/${watchlistId}/target-market-criteria-check/export`, comments).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    /**
     * Get the report for the given config
     */
    public getReport(data: ReportRequestData): Observable<AsyncLinkResponseData> {
        return this.post('download/report', data).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    /**
     * Get the multi product report for the given config
     */
    public getMultiProductReport(data: MultiProductReportRequestData): Observable<AsyncLinkResponseData> {
        return this.post('download/multi-product-report', data).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    /**
     * Get the attachment for the given id
     */
    public getAttachment(attachmentId: string): Observable<AsyncLinkResponseData> {
        return this.get(`/m/attachment/${attachmentId}`).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    /**
     * Get the external product link for a given productDq and linkType
     */
    public getExternalProductLink(productDq: string, linkType: string, tmc?: string): Observable<AsyncLinkResponseData> {
        const params: any = {};
        if (tmc) {
            params.tmc = tmc;
        }

        return this.get(`m/product/external/${productDq}/${linkType}`, {params}).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    public getBWListHistory(module: string, timestamp: Date, instrumentTypeRestrictions: string[]) {
        return this.post(`export/m/universe/${module}/excel`, {
            timestamp,
            instrumentTypeRestrictions,
        }).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    public getEquivalenceExport(
        watchlistId: string,
        resultId: string,
        comments: MultiRowComments
    ): Observable<AsyncLinkResponseData> {
        return this.post(`m/watchlist/${watchlistId}/equivalence-check/${resultId}/export`, comments).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    public getEquivalenceCheckExport(
        productDq: string,
        equivalenceCheckId: string,
        comments: StringObject
    ): Observable<AsyncLinkResponseData> {
        return this.post('product/equivalence-check/export', {
            productDq,
            equivalenceCheckId,
            comments
        }).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data),
        );
    }

    public getProductCompareExport(
        dqs: Array<string>,
        filter: DynamicChartRequestData
    ): Observable<AsyncLinkResponseData> {
        return this.post('download/product-compare', {
            dqs,
            chartBuilderRequest: filter
        }).pipe(
            map((response: ApiResponse<AsyncLinkResponseData>) => response.data)
        );
    }
}
