import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { Watchlist } from '../../../../routes/shared/watchlist-shared/models/watchlist.model';
import { WatchlistService } from '../../../../routes/shared/watchlist-shared/services/watchlist.service';
import { truthy } from '../../../helpers/general.helper';
import { AddToWatchlistDialogData } from './add-to-watchlist-dialog-data.interface';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { IconComponent } from '../../icon/icon.component';

@Component({
    selector: 'app-add-to-watchlist-dialog',
    templateUrl: './add-to-watchlist-dialog.component.html',
    standalone: true,
    imports: [IconComponent, NgIf, FormsModule, ReactiveFormsModule, NgFor, AsyncPipe, TranslocoModule]
})
export class AddToWatchlistDialogComponent implements OnInit {
    public selectWatchlistForm: UntypedFormGroup;
    public productsAdded: boolean;
    public watchLists$: Observable<Watchlist[]>;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: AddToWatchlistDialogData,
        private dialogRef: MatDialogRef<AddToWatchlistDialogComponent>,
        private fb: UntypedFormBuilder,
        private watchlistService: WatchlistService,
        private router: Router,
    ) {
        this.selectWatchlistForm = this.fb.group({
            name: ['', Validators.required],
        });
        this.watchLists$ = this.watchlistService.getEditableWatchlists();
    }

    public ngOnInit(): void {
        this.watchlistService.wereProductsAddedToWatchlist().pipe(
            filter(truthy),
            take(1),
        ).subscribe(() => {
            this.productsAdded = true;
            this.watchlistService.clearProductsAddedToWatchlist();
        });

        this.watchlistService.loadWatchlists();
    }

    public onCloseClick(e: MouseEvent): void {
        e.preventDefault();

        this.dialogRef.close();
    }

    public onSubmit(e: Event): void {
        e.preventDefault();

        this.watchlistService.addProductsToWatchlist(
            this.selectWatchlistForm.get('name').value, 
            this.data.selectedProductIds
        );
    }

    public linkToWatchlist(e: MouseEvent): void {
        e.preventDefault();

        this.router.navigate([
            '/app', 
            {
                outlets: {
                    overlay: ['watchlist-details', this.selectWatchlistForm.get('name').value]
                }
            }
        ]);
        this.dialogRef.close();
    }

}
