import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as IssueMessageActions from '../../../core/store/actions/issue-message.action';
import * as IssueMessageSelectors from '../../../core/store/selectors/issue-message.selectors';
import { IssueMessage } from '../../../core/store/state/issue-message.state';
import { IconComponent } from '../icon/icon.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-issue-message',
    templateUrl: './issue-message.component.html',
    styleUrls: ['./issue-message.component.scss'],
    standalone: true,
    imports: [NgIf, IconComponent, AsyncPipe]
})
export class IssueMessageComponent implements OnInit {

  public issueMessage$: Observable<IssueMessage>

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.issueMessage$ = this.store.select(IssueMessageSelectors.selectIssueMessage);
  }

  hideIssueMessage(): void {
    this.store.dispatch(IssueMessageActions.hideIssueMessage());
  }

}
