import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GenericError, HttpError } from '../errors/base.errors';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class LogglyLoggerService {

    private loggly: any = new LogglyTracker();

    constructor(private appConfigService: ConfigurationService) {
        if (environment.production && appConfigService.configuration.logging.logglyEnabled) {
            this.loggly.push({
                logglyKey: appConfigService.configuration.logging.logglyKey,
                sendConsoleErrors: true,
                tag: 'javascript-logs',
                useDomainProxy: true
            });
        }
    }

    public log(error: GenericError | HttpError) {
        if (environment.production && this.appConfigService.configuration.logging.logglyEnabled && this.loggly) {
            this.loggly.push({
                application: 'Derifin3',
                environment: this.appConfigService.configuration.logging.logglyEnvironment ?
                    this.appConfigService.configuration.logging.logglyEnvironment : 'production',
                d3environment: this.appConfigService.configuration.environment.id,
                ...error
            });
        }
    }
}
