/**
 * Created by Alex Klein <florian@rocketloop.de> on 10/17/2017.
 */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { GetModuleDataResponse, GetProductSelectionResponse } from '../../api/models/risk-manager.model';
import { RiskManagerApi } from '../../api/risk-manager.api';
import { RiskManagerErrors } from '../../errors/risk-manager.errors';
import { RiskManagerModuleWatcher } from '../../models/risk-manager.model';
import {
    LoadFactSheetContextAction,
    loadFactSheetContextFailed,
    loadFactSheetContextSucceeded,
    LoadFactSheetContextSucceededAction,
    LoadListModuleContextAction,
    loadListModuleContextFailed,
    loadListModuleContextSucceeded,
    LoadListModuleContextSucceededAction,
    loadModuleData,
    LoadModuleDataAction,
    loadModuleDataFailed,
    loadModuleDataSucceeded,
    LoadProductSelectionAction,
    loadProductSelectionSucceeded,
    LoadSingleModuleContextAction,
    loadSingleModuleContextFailed,
    loadSingleModuleContextSucceeded,
    LoadSingleModuleContextSucceededAction,
    LoadWatchlistContextAction,
    loadWatchlistContextFailed,
    loadWatchlistContextSucceeded,
    LoadWatchlistModuleDataAction, loadWatchlistModuleDataFailed, loadWatchlistModuleDataSucceeded,
    RiskManagerActionTypes,
    SaveWatcherAction,
    saveWatcherFailed, SaveWatchersAction, saveWatchersFailed, saveWatchersSucceeded,
    saveWatcherSucceeded,
} from '../actions/risk-manager.actions';

/**
 * Effect class for bwlist effects
 */
@Injectable()
export class RiskManagerEffects {
    /** Effect Declarations **/

    
    public loadFactSheetContext$ = createEffect(() => this.actions$.pipe(
        ofType(RiskManagerActionTypes.LOAD_FACT_SHEET_CONTEXT),
        switchMap((action: LoadFactSheetContextAction) => this.loadFactSheetContext(action)),
    ));

    
    public loadFactSheetContextSucceeded$ = createEffect(() => this.actions$.pipe(
        ofType(RiskManagerActionTypes.LOAD_FACT_SHEET_CONTEXT_SUCCEEDED),
        switchMap((action: LoadFactSheetContextSucceededAction) => this.loadFactSheetContextSucceeded(action)),
    ));

    
    public loadListModuleContext$ = createEffect(() => this.actions$.pipe(
        ofType(RiskManagerActionTypes.LOAD_LIST_MODULE_CONTEXT),
        switchMap((action: LoadListModuleContextAction) => this.loadListModuleContext(action)),
    ));

    
    public loadListModuleContextSucceeded$ = createEffect(() => this.actions$.pipe(
        ofType(RiskManagerActionTypes.LOAD_LIST_MODULE_CONTEXT_SUCCEEDED),
        switchMap((action: LoadListModuleContextSucceededAction) => this.loadListModuleContextSucceeded(action)),
    ));

    
    public loadSingleModuleContext$ = createEffect(() => this.actions$.pipe(
        ofType(RiskManagerActionTypes.LOAD_SINGLE_MODULE_CONTEXT),
        switchMap((action: LoadSingleModuleContextAction) => this.loadSingleModuleContext(action)),
    ));

    
    public loadSingleModuleContextSucceeded$ = createEffect(() => this.actions$.pipe(
        ofType(RiskManagerActionTypes.LOAD_SINGLE_MODULE_CONTEXT_SUCCEEDED),
        switchMap((action: LoadSingleModuleContextSucceededAction) => this.loadSingleModuleContextSucceeded(action)),
    ));

    
    public loadModuleData$ = createEffect(() => this.actions$.pipe(
        ofType(RiskManagerActionTypes.LOAD_MODULE_DATA),
        switchMap((action: LoadModuleDataAction) => this.loadModuleData(action)),
    ));

    
    public loadWatchlistContext$ = createEffect(() => this.actions$.pipe(
        ofType(RiskManagerActionTypes.LOAD_WATCHLIST_CONTEXT),
        switchMap((action: LoadWatchlistContextAction) => this.loadWatchlistContext(action)),
    ));

    
    public loadWatchlistData$ = createEffect(() => this.actions$.pipe(
        ofType(RiskManagerActionTypes.LOAD_WATCHLIST_MODULE_DATA),
        switchMap((action: LoadWatchlistModuleDataAction) => this.loadWatchlistData(action)),
    ));

    
    public saveWatcher$ = createEffect(() => this.actions$.pipe(
        ofType(RiskManagerActionTypes.SAVE_WATCHER),
        switchMap((action: SaveWatcherAction) => this.saveWatcher(action)),
    ));

    
    public saveWatchers$ = createEffect(() => this.actions$.pipe(
        ofType(RiskManagerActionTypes.SAVE_WATCHERS),
        switchMap((action: SaveWatchersAction) => this.saveWatchers(action)),
    ));

    
    public loadProductSelection$ = createEffect(() => this.actions$.pipe(
        ofType(RiskManagerActionTypes.LOAD_PRODUCT_SELECTION),
        switchMap((action: LoadProductSelectionAction) => this.loadProductSelection(action)),
    ));

    constructor(private readonly actions$: Actions, private readonly riskManagerApi: RiskManagerApi) {
    }

    /** Effect Handler **/

    /**
     * Effect handler called upon the LoadListModuleContextAction
     *
     * This effect handler calls the corresponding API method to load the list module context
     * @param action
     * @returns {Observable<Action>}
     */
    public loadFactSheetContext(action: LoadFactSheetContextAction): Observable<Action> {
        return this.riskManagerApi.getFactSheetContext(action.payload.productDq).pipe(
            map((context) => loadFactSheetContextSucceeded(action.payload.moduleId, context)),
            catchError((err) =>
                of(
                    loadFactSheetContextFailed({
                        errorCode: RiskManagerErrors.UNKNOWN,
                        httpError: true,
                        statusCode: err.status,
                    }),
                ),
            ),
        );
    }

    /**
     * Effect handler called upon the LoadFactSheetContextSucceededAction
     *
     * This effect handler dispatches the load module data action
     * @param action
     * @returns {Observable<Action>}
     */
    public loadFactSheetContextSucceeded(action: LoadFactSheetContextSucceededAction): Observable<Action> {
        return of(loadModuleData(action.payload.moduleId, action.payload.context));
    }

    /**
     * Effect handler called upon the LoadListModuleContextAction
     *
     * This effect handler calls the corresponding API method to load the list module context
     * @param action
     * @returns {Observable<Action>}
     */
    public loadListModuleContext(action: LoadListModuleContextAction): Observable<Action> {
        return this.riskManagerApi.getListModuleContext(action.payload.moduleId).pipe(
            map((context) => loadListModuleContextSucceeded(action.payload.moduleId, context)),
            catchError((err) =>
                of(
                    loadListModuleContextFailed({
                        errorCode: RiskManagerErrors.UNKNOWN,
                        httpError: true,
                        statusCode: err.status,
                    }),
                ),
            ),
        );
    }

    /**
     * Effect handler called upon the LoadListModuleContextSucceededAction
     *
     * This effect handler dispatches the load module data action
     * @param action
     * @returns {Observable<Action>}
     */
    public loadListModuleContextSucceeded(action: LoadListModuleContextSucceededAction): Observable<Action> {
        return of(loadModuleData(action.payload.moduleId, action.payload.context));
    }

    /**
     * Effect handler called upon the LoadSingleModuleContextAction
     *
     * This effect handler calls the corresponding API method to load the single module context
     * @param action
     * @returns {Observable<Action>}
     */
    public loadSingleModuleContext(action: LoadSingleModuleContextAction): Observable<Action> {
        return this.riskManagerApi.getSingleModuleContext(action.payload.moduleId, action.payload.productDq).pipe(
            map((context) => loadSingleModuleContextSucceeded(action.payload.moduleId, context)),
            catchError((err) =>
                of(
                    loadSingleModuleContextFailed({
                        errorCode: RiskManagerErrors.UNKNOWN,
                        httpError: true,
                        statusCode: err.status,
                    }),
                ),
            ),
        );
    }

    /**
     * Effect handler called upon the LoadSingleModuleContextSucceededAction
     *
     * This effect handler dispatches the load module data action
     * @param action
     * @returns {Observable<Action>}
     */
    public loadSingleModuleContextSucceeded(action: LoadSingleModuleContextSucceededAction): Observable<Action> {
        return of(loadModuleData(action.payload.moduleId, action.payload.context));
    }

    /**
     * Effect handler called upon the LoadModuleDataAction
     *
     * This effect handler calls the corresponding API method to load the module data
     * @param action
     * @returns {Observable<Action>}
     */
    public loadModuleData(action: LoadModuleDataAction): Observable<Action> {
        return this.riskManagerApi.getLoadModuleData(action.payload.moduleId, action.payload.context).pipe(
            map((data: GetModuleDataResponse) => loadModuleDataSucceeded(action.payload.moduleId, data)),
            catchError((err) =>
                of(
                    loadModuleDataFailed({
                        errorCode: RiskManagerErrors.UNKNOWN,
                        httpError: true,
                        statusCode: err.status,
                    }),
                ),
            ),
        );
    }

    /**
     * Effect handler called upon the SaveWatcherAction
     *
     * This effect handler calls the corresponding API method to save the watcher
     * @param action
     * @returns {Observable<Action>}
     */
    public saveWatcher(action: SaveWatcherAction): Observable<Action> {
        return this.riskManagerApi.saveWatcher(action.payload.context, action.payload.watcher).pipe(
            map((data: RiskManagerModuleWatcher) => saveWatcherSucceeded(action.payload.moduleId, data)),
            catchError((err) =>
                of(
                    saveWatcherFailed({
                        errorCode: RiskManagerErrors.UNKNOWN,
                        httpError: true,
                        statusCode: err.status,
                    }),
                ),
            ),
        );
    }

    /**
     * Effect handler called upon the SaveWatchersAction
     *
     * This effect handler calls the corresponding API method to save multiple watchers
     * @param action
     * @returns {Observable<Action>}
     */
    public saveWatchers(action: SaveWatchersAction): Observable<Action> {
        return this.riskManagerApi.saveWatchers(action.payload.moduleId, action.payload.data).pipe(
            map((data: RiskManagerModuleWatcher[]) => saveWatchersSucceeded(action.payload.moduleId, data)),
            catchError((err) =>
                of(
                    saveWatchersFailed({
                        errorCode: RiskManagerErrors.UNKNOWN,
                        httpError: true,
                        statusCode: err.status,
                    }),
                ),
            ),
        );
    }

    /**
     * Effect handler called upon the LoadProductSelectionAction
     *
     * This effect handler calls the corresponding API method to load the product selection for the module
     * @param action
     * @returns {Observable<Action>}
     */
    public loadProductSelection(action: LoadProductSelectionAction): Observable<Action> {
        return this.riskManagerApi.getProductSelection(action.payload.moduleId).pipe(
            map((data: GetProductSelectionResponse) => loadProductSelectionSucceeded(action.payload.moduleId, data)),
            catchError((err) =>
                of(
                    saveWatcherFailed({
                        errorCode: RiskManagerErrors.UNKNOWN,
                        httpError: true,
                        statusCode: err.status,
                    }),
                ),
            ),
        );
    }

    private loadWatchlistContext(action: LoadWatchlistContextAction) {
        return this.riskManagerApi.getWatchlistContext(action.payload.watchlistId).pipe(
            map((context) => loadWatchlistContextSucceeded(action.payload.moduleId, action.payload.watchlistId, context)),
            catchError((err) =>
                of(
                    loadWatchlistContextFailed(action.payload.moduleId, action.payload.watchlistId, {
                        errorCode: RiskManagerErrors.UNKNOWN,
                        httpError: true,
                        statusCode: err.status,
                    }),
                ),
            ),
        );
    }

    private loadWatchlistData(action: LoadWatchlistModuleDataAction) {
        return this.riskManagerApi.getLoadModuleData(action.payload.moduleId, action.payload.context).pipe(
            map((data) => loadWatchlistModuleDataSucceeded(action.payload.context, data)),
            catchError((err) =>
                of(
                    loadWatchlistModuleDataFailed(action.payload.context, {
                        errorCode: RiskManagerErrors.UNKNOWN,
                        httpError: true,
                        statusCode: err.status,
                    }),
                ),
            ),
        );
    }
}
