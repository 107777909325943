/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 7/4//17.
 */

import { combineReducers } from '@ngrx/store';
import { assetsModuleAssetReducer } from './asset.reducer';
import { assetsModuleFilterReducer } from './filter.reducer';
import { assetsModuleMetaReducer } from './meta.reducer';
import { assetsModuleQuerySetReducer } from './query-set.reducer';

/**
 * The reducer responsible for the @link{TradeState}
 * @type {ActionReducer<TradeState>}
 */
export const assetsModuleReducer = combineReducers({
    asset: assetsModuleAssetReducer,
    querySet: assetsModuleQuerySetReducer,
    filter: assetsModuleFilterReducer,
    meta: assetsModuleMetaReducer,
});
