import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { map } from 'rxjs/operators';
import { FormValues } from '../../../../core/models/form.model';
import { EntityWidgetDecorfinDataOptions, EntityWidgetFormOptions, EntityWidgetOptions } from '../../../models/widgets.model';
import { EntityEvent } from '../../../components/entity-table/entity-table.component';
import { EntityWidgetContentComponent, EntityWidgetWithValidation } from '../entity-widget/entity-widget.component';
import { FilterComponent } from '../../../components/filter/filter.component';


@Component({
    selector: 'app-entity-widget-form',
    templateUrl: './entity-widget-form.component.html',
    styleUrls: ['./entity-widget-form.component.scss'],
    standalone: true,
    imports: [FilterComponent]
})
export class EntityWidgetFormComponent implements OnInit, OnChanges, EntityWidgetContentComponent, EntityWidgetWithValidation, AfterViewInit {

    @Input() public entityId: string;
    @Input() public width: number;

    @Input() public options: EntityWidgetFormOptions;
    @Input() public data: any;

    @Input() public formValues: FormValues;

    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();
    @Output() public componentEvent: EventEmitter<EntityEvent> = new EventEmitter();

    @ViewChild('widgetForm', {static: true})
    public widgetForm: FilterComponent;

    public formData: FormValues = {};

    constructor(private cdf: ChangeDetectorRef) {
    }

    public ngOnInit() {

    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.data) {
            this.formData = {
                ...this.formData,
                ...this.data,
            };
        }

        if ((changes.data && changes.data.isFirstChange()) || (changes.values && changes.values.isFirstChange())) {
            this.emitFormValidationState();

            setTimeout(() => {
                this.cdf.detectChanges();
            }, 100);
        }
    }

    public ngAfterViewInit() {
        this.emitFormValidationState();
    }

    public emitFormValidationState(value: boolean = this.formValidation()) {
        this.componentEvent.emit({
            event: {
                type: 'form-validation',
                payload: {
                    value,
                },
            },
            id: this.options.id || this.options.path,
        });
    }

    public formValidation(): boolean {
        return this.widgetForm.valid;
    }

    public onFormValueChange(values: FormValues) {
        this.componentEvent.emit({
            event: {
                type: 'form-change',
                payload: values,
            },
            id: null,
        });

        this.emitFormValidationState();
    }

    public forceFormValidation() {
        if (this.widgetForm && this.widgetForm.filterForm) {
            this.widgetForm.filterForm.markAllAsTouched();
            this.widgetForm.filterForm.updateValueAndValidity();
        }
    }
}
