import {animate, style, transition, trigger} from '@angular/animations';
import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { BubbleSeriesComponent, PlacementTypes, StyleTypes, ChartCommonModule, TooltipModule } from '@swimlane/ngx-charts';
import { NgFor } from '@angular/common';

// tslint:disable:component-selector
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'g[app-charts-selectable-bubble-series]',
    template: `
    <svg:g *ngFor="let circle of circles; trackBy: trackBy">
      <svg:g [attr.transform]="circle.transform">
        <svg:g ngx-charts-circle
          [@animationState]="'active'"
          class="circle"
          [cx]="0"
          [cy]="0"
          [r]="circle.radius"
          [fill]="circle.color"
          [style.opacity]="circle.opacity"
          [class.active]="circle.isActive"
          [class.selected]="isCircleSelected(circle)"
          [pointerEvents]="'all'"
          [data]="circle.data"
          [classNames]="circle.classNames"
          (select)="onClick($event)"
          (activate)="activateCircle(circle)"
          (deactivate)="deactivateCircle(circle)"
          ngx-tooltip
          [tooltipDisabled]="false"
          [tooltipPlacement]="tooltipPlacement.Top"
          [tooltipType]="tooltipType.tooltip"
          [tooltipTitle]="tooltipTemplate ? undefined : getTooltipText(circle)"
          [tooltipTemplate]="tooltipTemplate"
          [tooltipContext]="circle.data"
        />
      </svg:g>
    </svg:g>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('animationState', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'scale(0)',
                }),
                animate(250, style({ opacity: 1, transform: 'scale(1)' })),
            ]),
        ]),
    ],
    standalone: true,
    imports: [NgFor, ChartCommonModule, TooltipModule]
})
export class ChartBubbleSeriesSelectableComponent extends BubbleSeriesComponent {
    @Input() public selectedEntries: any[];
    @ContentChild('tooltipTemplate', { static: true }) public tooltipTemplate: TemplateRef<any>;

    public tooltipPlacement = PlacementTypes;
    public tooltipType = StyleTypes;

    public isCircleSelected(circle): boolean {
        if (!this.selectedEntries) { return false; }

        const item = this.selectedEntries.find((d) => {
            return circle.data.name === d.value.name;
        });

        return item !== undefined;
    }

    public getCircles(): any[] {
        const circles = super.getCircles();

        return circles.map((circle, i) => ({
            ...circle,
            data: {
                ...circle.data,
                extra: this.data.series[i].extra,
            },
        }));
    }

    public trackBy(index, circle): string {
        return `${circle.data.series} ${circle.data.name}`;
    }
}
