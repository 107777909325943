/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 7/4//17.
 */

import { combineReducers } from '@ngrx/store';
import { tradesModuleFilterReducer } from './filter.reducer';
import { tradesModuleMetaReducer } from './meta.reducer';
import { tradesModuleQuerySetReducer } from './query-set.reducer';
import { tradesModuleTradeReducer } from './trade.reducer';

/**
 * The reducer responsible for the @link{TradeState}
 * @type {ActionReducer<TradeState>}
 */
export const tradesModuleReducer = combineReducers({
    trade: tradesModuleTradeReducer,
    querySet: tradesModuleQuerySetReducer,
    filter: tradesModuleFilterReducer,
    meta: tradesModuleMetaReducer,
});
