<div class="row">
    <div class="col-12">
        <div class="form-group">
            <app-multi-select-dropdown
                placeholder="Please choose"
                [items]="availableItems"
                [active]="selectedItems"
                [disabled]="disabled"></app-multi-select-dropdown>
        </div>
    </div>

    <ng-container *ngIf="!currentValue.underlying || currentValue.underlying === 'custom'">
        <div class="col-6">
            <section class="card-form__section">
                <h4 class="card-headline">ISIN</h4>
                <div class="form-group">
                    <input class="form-control" (ngModelChange)="onInputChange($event, 'isin')" [ngModel]="currentValue.isin"/>
                </div>
            </section>
        </div>
        <div class="col-6">
            <section class="card-form__section">
                <h4 class="card-headline">WKN</h4>
                <div class="form-group">
                    <input class="form-control" (ngModelChange)="onInputChange($event, 'dq')" [ngModel]="currentValue.dq"/>
                </div>
            </section>
        </div>

        <div class="col-6">
            <section class="card-form__section">
                <h4 class="card-headline">Name</h4>
                <div class="form-group">
                    <input class="form-control" (ngModelChange)="onInputChange($event, 'name')" [ngModel]="currentValue.name"/>
                </div>
            </section>
        </div>
    </ng-container>

    <div class="col-6" *ngIf="hasMultipleUnderlying">
        <section class="card-form__section">
            <h4 class="card-headline">Weight<!--Gewichtung--></h4>
            <div class="form-group">
                <input type="number" class="form-control" (ngModelChange)="onInputChange($event, 'weight')" [ngModel]="currentValue.weight"/>
            </div>
        </section>
    </div>
</div>
