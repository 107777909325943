import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {get} from 'lodash';
import {Observable, Subscription} from 'rxjs';
import {EntityActionsActivationTriggersService} from '../../../core/services/entity-actions-activation-triggers.service';
import {Entity} from '../../models/entities.model';
import {GenericActionStructure} from '../entity-actions/entity-actions.component';
import {EntityTableCellComponent} from '../entity-table-component-cell/entity-table-component-cell.component';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-entity-table-action-cell',
    templateUrl: './entity-table-action-cell.component.html',
    styleUrls: ['./entity-table-action-cell.component.scss'],
    standalone: true,
    imports: [NgIf, TranslocoModule]
})
export class EntityTableActionCellComponent implements OnInit, OnChanges, EntityTableCellComponent, OnDestroy {

    @HostBinding('class.entity-table-action-cell') public recommendationEntityTableCellClass = true;

    @Input() public product: Entity;
    @Input() public options: EntityTableActionCellOptions;
    @Input() public path: string;

    @Output() public event: EventEmitter<any> = new EventEmitter();

    public activationTriggers$: Observable<any[]>;
    public triggerSubscription: Subscription;
    public currentlyActiveTriggers: any[];

    constructor(private entityActionsActivationTriggersService: EntityActionsActivationTriggersService, private cd: ChangeDetectorRef) {
        this.activationTriggers$ = this.entityActionsActivationTriggersService.activeTriggers$;
    }

    public ngOnInit() {
        this.triggerSubscription = this.activationTriggers$.subscribe((value) => {
            this.currentlyActiveTriggers = [...value];

            this.cd.detectChanges();
        });
    }

    public ngOnChanges(changes: SimpleChanges) {

    }

    public isFadeTriggeredForAction(action: GenericActionStructure) {
        if (!action || !action.fadeTriggers || !action.fadeTriggers.length) {
            return true;
        }

        return action.fadeTriggers.reduce((res, current) => {
            return res && this.currentlyActiveTriggers.includes(current);
        }, true);
    }


    public isVisibilityTriggeredForAction(action: GenericActionStructure) {
        if (action.visibilityIndicatorPath) {
            return get(this.product, action.visibilityIndicatorPath, false);
        }

        return true;
    }

    public isConditionValid() {
        if (!this.options || !this.options.action.conditionPath || !this.options.action.conditionValues) {
            return true;
        }

        const conditionValues = [].concat(this.options.action.conditionValues);
        const value = get(this.product, this.options.action.conditionPath);

        return conditionValues.includes(value);
    }

    public onActionClicked(e: Event) {
        e.preventDefault();
        const event: any = {
            type: this.options.action.type,
        };
        if (this.options && this.options.action && this.options.action.linkType) {
            event.payload = {
                linkType: this.options.action.linkType,
            };
        }
        this.event.next(event);
    }

    public ngOnDestroy(): void {
        if (this.triggerSubscription) {
            this.triggerSubscription.unsubscribe();
        }
    }

}

export interface EntityTableActionCellOptions {
    action: GenericActionStructure;
}
