import { FormValidationRuleOptions } from '../models/form.model';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ObjectMaker } from '../helpers/form.helper';

export function endsWithValidator(configModel: FormValidationRuleOptions): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        if(configModel && control.value && configModel.value){
            const endString = String(control.value).substr(control.value.length - configModel.value.length, configModel.value.length);

            if (endString != configModel.value){
                return ObjectMaker.toJson("endsWith", configModel, [control.value, configModel.value]);
            }
        }

        return ObjectMaker.null();
    }
}
