<div class="custom-controls-stacked">
    <label class="custom-control custom-radio" *ngIf="shouldDisplayControl('any')">
        <input (click)="onMaturityTypeClicked($event, 'any')" id="radioF1" name="radio"
               type="radio"
               class="custom-control-input"
               value="any"
               [checked]="value?.type === 'any'" [disabled]="disabled">
        <span class="custom-control-indicator"></span>
        <span class="custom-control-description">{{values?.types[0]?.label}}</span>
    </label>
    <label class="custom-control custom-radio" *ngIf="shouldDisplayControl('range')">
        <input (click)="onMaturityTypeClicked($event, 'range')" id="radioF2"
               name="radio" type="radio"
               class="custom-control-input"
               value="range"
               [checked]="value?.type === 'range'" [disabled]="disabled">
        <span class="custom-control-indicator"></span>
        <span class="custom-control-description">{{values?.types[1]?.label}}</span>
    </label>
    <div *ngIf="value?.type === 'range'"
         class="form-group-sub-control">
        <div class="form-group-spacing row">
            <label class="col-1 col-form-label">Von</label>
            <div class="col">
                <app-date-input #rangeFrom placeholder="dd.mm.yyyy" [ngModel]="value.params.min"
                                [disabled]="disabled"
                                (ngModelChange)="onRangeFromChanged($event)"></app-date-input>
            </div>
        </div>
        <div class="form-group-spacing row">
            <label class="col-1 col-form-label">Bis</label>
            <div class="col">
                <app-date-input #rangeTo placeholder="dd.mm.yyyy" [ngModel]="value.params.max"
                                [disabled]="disabled"
                                (ngModelChange)="onRangeToChanged($event)"></app-date-input>
            </div>

        </div>
    </div>
    <label class="custom-control custom-radio" *ngIf="shouldDisplayControl('interval')">
        <input (click)="onMaturityTypeClicked($event, 'interval')" id="radioF3"
               name="radio" type="radio"
               value="interval"
               class="custom-control-input"
               [checked]="value?.type === 'interval'" [disabled]="disabled">
        <span class="custom-control-indicator"></span>
        <span class="custom-control-description">{{values?.types[2]?.label}}</span>
    </label>
    <div *ngIf="value?.type === 'interval'"
         class="custom-controls-stacked custom-control-sub-control">
        <label class="custom-control custom-radio" *ngFor="let type of values?.intervals">
            <input id="radioInterval1" name="radioInterval" type="radio"
                   class="custom-control-input" (change)="onMaturityFilterIntervalChangedClicked($event, type.id)"
                   [checked]="value.params.interval === 0" [disabled]="disabled">
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">{{type?.label}}</span>
        </label>
    </div>
    <label class="custom-control custom-radio" *ngIf="shouldDisplayControl('quarter')">
        <input (click)="onMaturityTypeClicked($event, 'quarter')" id="radioF4"
               name="radio" type="radio"
               value="quarter"
               class="custom-control-input"
               [checked]="value?.type === 'quarter'" [disabled]="disabled">
        <span class="custom-control-indicator"></span>
        <span class="custom-control-description">{{values?.types[3]?.label}}</span>
    </label>
    <div *ngIf="value?.type === 'quarter'"
         class="custom-controls-stacked custom-control-sub-control">
        <label class="custom-control custom-radio" *ngFor="let type of values?.quarters">
            <input id="radioQuarter1" name="radioQuarter" type="radio"
                   class="custom-control-input" (change)="onMaturityFilterQuarterChangedClicked($event, type.id)"
                   [checked]="value.params.quarter === type?.id" [disabled]="disabled">
            <span class="custom-control-indicator"></span>
            <span class="custom-control-description">{{type?.label}}</span>
        </label>
    </div>
</div>
