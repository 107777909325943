import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { get } from 'lodash';
import { loadStrings } from '../store/actions/strings.action';
import { AppState } from '../store/state/index.state';

const STRINGS_PATH_CACHE = [];

@Injectable()
export class StringsService {
    constructor(private store: Store<AppState>) {
    }

    public getStringsByPath(path: string) {
        return this.store.pipe(
            select((state) => get(state.strings.data, path)),
        );
    }

    public getStringLoading() {
        return this.store.pipe(
            select((state) => state.strings.loading),
        );
    }

    public loadStringsByPath(path: string, force = false) {
        if (STRINGS_PATH_CACHE.includes(path) && !force) {
            return;
        }

        this.store.dispatch(loadStrings(path));
        STRINGS_PATH_CACHE.push(path);
    }
}
