import { Highlightable } from '@angular/cdk/a11y';
import { Component, ElementRef, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ActionType } from '../search.component';
import { RouterLink } from '@angular/router';
import { NgIf, NgClass, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'app-search-result',
    templateUrl: './search-result.component.html',
    styleUrls: ['./search-result.component.scss'],
    standalone: true,
    imports: [NgIf, RouterLink, NgClass, NgTemplateOutlet]
})
export class SearchResultComponent implements Highlightable {

  @Input() public actionType: ActionType = 'link';
  @Input() public link: Array<any>;
  @Input() public id: string;

  @Output() public selectId = new EventEmitter<string>()

  @HostBinding('style.scroll-margin-top') scrollMarginTop = '3.125rem';

  public focus = false;

  constructor(public elementRef: ElementRef<Element>) { }

  public setActiveStyles(): void {
    this.focus = true;
  }

  public setInactiveStyles(): void {
    this.focus = false;
  }
}
