<div class="wms-dialog-inner widget-dialog tw-bg-primary-bg">
    <div class="container">
        <div class="modal-body">
            <nav class="navbar-toggleable-md header-bar navbar-inverse header-bar">
                <div class="3xl:tw-container tw-w-full tw-mx-auto tw-flex">
                    <h1 class="modal-form__title mr-auto tw-text-black dark:tw-text-white">{{ title }}</h1>
                    <div class="navbar-nav">
                        <a (click)="onCloseClick($event)" class="nav-link icon-link close-modal-button" href="#">
                            <app-icon class="derifin-icon" icon="derifin:schliessen"></app-icon>
                        </a>
                    </div>
                </div>
            </nav>
            
            <h2 class="modal-form__subtitle tw-text-black dark:tw-text-white">{{ description }}</h2>

            <div *ngFor="let product of products" class="widget-dialog__products">
                <app-entity-widget-container
                    [structure]="widgetConfigs" 
                    [data]="product" 
                    [columns]="structure.productSectionConfiguration.layoutColumns"
                    [responsive]="false"></app-entity-widget-container>
            </div>
        </div>
    </div>
    
    <div class="modal-footer">
        <div class="modal-actions">
            <div class="container">
                <div class="d-flex justify-content-end">
                    <div *ngFor="let action of actions">
                        <button
                            type="button"
                            chroma-button
                            (click)="onAction(action.id)">{{ action.label }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</div>