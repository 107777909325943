import { Environment } from "./environment.type";

export const environment: Environment = {
    production: false,
    theme: 'abnamro',
    chromaUI: true,
    coloredBg: {
        login: true
    }
};
