import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { EntityDataModule } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';

import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { INIT, INITIAL_REDUCERS, INITIAL_STATE, REDUCER_FACTORY, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { KEY_ACCESS_TOKEN, KEY_REFRESH_TOKEN, readFromLocalStorage } from './core/helpers/local-storage.helper';
import { AsyncLinkEffects } from './core/store/effects/async-link.effects';
import { AuthEffects } from './core/store/effects/auth.effects';
import { ConfigEffects } from './core/store/effects/config.effects';
import { RiskManagerEffects } from './core/store/effects/risk-manager.effects';
import { RouterEffects } from './core/store/effects/router.effects';
import { StringsEffects } from './core/store/effects/strings.effects';
import { UploadEffects } from './core/store/effects/upload.effects';
import { AdditionalWidgetsDataEffects } from './core/store/effects/widgets-data.effects';
import { ROOT_REDUCERS, rootReducerFactory, metaReducers } from './core/store/reducers';
import { entityConfig } from './entity-metadata';

import { ProductSharedEffects } from './routes/shared/product-shared/store/effects/product-shared.effects';
import { WatchlistsEffects } from './routes/shared/watchlist-shared/store/effects/watchlists.effects';
import { TargetMarketEffects } from './routes/target-market/store/effects/target-market.effects';
import { SharedModule } from './shared/shared.module';
import { IssueMessageEffects } from './core/store/effects/issue-message.effects';
import { AdEffects } from './core/store/effects/ad.effects';
import { CustomerSelectionEffects } from './routes/customer-selection/store/effects/customer-selection.effects';
import { TranslocoRootModule } from './transloco-root.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SearchEffects } from './core/store/effects/search.effects';
import { UserEffects } from './core/store/effects/user.effects';
import { environment } from '../environments/environment';
import { AuthInterceptor } from './core/http-interceptors/auth.interceptor';

export function initialStateFactory(initialReducer, reducerFactory) {
    let initialState = reducerFactory(initialReducer)(undefined, {type: INIT});

    if (!initialState.auth.accessToken) {
        initialState = {
            ...initialState,
            auth: {
                ...initialState.auth,
                accessToken: readFromLocalStorage<string>(KEY_ACCESS_TOKEN),
            },
        };
    }

    if (!initialState.auth.refreshToken) {
        initialState = {
            ...initialState,
            auth: {
                ...initialState.auth,
                refreshToken: readFromLocalStorage<string>(KEY_REFRESH_TOKEN),
            },
        };
    }

    return initialState;
}

registerLocaleData(localeDe, 'de');

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        TranslocoRootModule,
        MatIconModule,
        StoreModule.forRoot(ROOT_REDUCERS, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false
            }
        }),
        StoreRouterConnectingModule.forRoot(),
        EffectsModule.forRoot([
            AuthEffects,
            ConfigEffects,
            StringsEffects,
            AsyncLinkEffects,
            RouterEffects,
            TargetMarketEffects,
            CustomerSelectionEffects,
            WatchlistsEffects,
            UploadEffects,
            ProductSharedEffects,
            RiskManagerEffects,
            AdditionalWidgetsDataEffects,
            IssueMessageEffects,
            AdEffects,
            SearchEffects,
            UserEffects
        ]),
        EntityDataModule.forRoot(entityConfig),
        CoreModule,
        AppRoutingModule,
        NgxPageScrollCoreModule.forRoot(),
        NgxPageScrollModule,
        StoreDevtoolsModule.instrument({
            maxAge: 50,
            logOnly: environment.production,
            trace: !environment.production
        }),
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-center',
            timeOut: 5000,
            closeButton: true,
            tapToDismiss: false
        }),
        SharedModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        {
            provide: INITIAL_STATE,
            useFactory: initialStateFactory,
            deps: [INITIAL_REDUCERS, REDUCER_FACTORY]
        },
        {
            provide: ROOT_REDUCERS,
            useFactory: rootReducerFactory
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
