/**
 * Created by Alex Klein <alex@rocketloop.de> on 5/16//17.
 */

import { Action } from '@ngrx/store';
import {
    FilterActionTypes,
    FilterAvailableValuesLoadedAction,
    FilterInitialValuesLoadedAction,
    FilterStructureLoadedAction,
} from '../actions/filter.actions';
import { TradeFilterState } from '../state/trades.state';

export const PRODUCT_TYPE_ID_DEMO = 'IPlHIUAtDmjt3uQGpgh8bMS5yaPlfTM4';

export const initialState: TradeFilterState = {
    structure: {},
    availableValues: {},
    initialValues: {},
};

/**
 * The reducer responsible for the @link{FilterState}
 * @param state
 * @param action
 * @returns {any}
 */
export function tradesModuleFilterReducer(state: TradeFilterState = initialState, action: Action): TradeFilterState {

    switch (action.type) {

        /**
         * Handle product filter fields loaded action
         */
        case FilterActionTypes.FILTER_STRUCTURE_LOADED:
            const filterStructureLoadedAction = action as FilterStructureLoadedAction;
            return {
                ...state,
                structure: {
                    ...state.structure,
                    [filterStructureLoadedAction.payload.productTypeId]: filterStructureLoadedAction.payload.config,
                },
            };

        /**
         * Handle product filter available values loaded action
         */
        case FilterActionTypes.FILTER_AVAILABLE_VALUES_LOADED:
            const filterAvailableValuesLoadedAction = action as FilterAvailableValuesLoadedAction;
            return {
                ...state,
                availableValues: {
                    ...state.availableValues,
                    [filterAvailableValuesLoadedAction.payload.productTypeId]: filterAvailableValuesLoadedAction.payload.availableValues,
                },
            };

        /**
         * Handle product filter initial values loaded action
         */
        case FilterActionTypes.FILTER_INITIAL_VALUES_LOADED:
            const filterInitialValuesLoadedAction = action as FilterInitialValuesLoadedAction;
            return {
                ...state,
                initialValues: {
                    ...state.availableValues,
                    [filterInitialValuesLoadedAction.payload.productTypeId]: filterInitialValuesLoadedAction.payload.initialValues,
                },
            };

        default:
            return state;
    }
}
