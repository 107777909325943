import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { get } from 'lodash';
import { EntityWidgetAlertOptions } from '../../../models/widgets.model';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-entity-widget-alert',
    templateUrl: './entity-widget-alert.component.html',
    styleUrls: ['./entity-widget-alert.component.scss'],
    standalone: true,
    imports: [NgClass]
})
export class EntityWidgetAlertComponent implements OnInit, OnChanges, EntityWidgetContentComponent {
    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public options: EntityWidgetAlertOptions;
    @Input() public data: any;
    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();

    public alertType: 'info' | 'success' | 'warning' | 'danger' = 'info';
    public text = '';

    constructor() {

    }

    public ngOnChanges(changes: SimpleChanges) {
        this.updateComponent();
    }

    public ngOnInit() {
        this.updateComponent();
    }

    public updateComponent() {
        if (this.options.styleHints.includes('info')) {
            this.alertType = 'info';
        } else if (this.options.styleHints.includes('success')) {
            this.alertType = 'success';
        } else if (this.options.styleHints.includes('warning')) {
            this.alertType = 'warning';
        } else if (this.options.styleHints.includes('danger')) {
            this.alertType = 'danger';
        }

        this.text = get<string>(this.data, this.options.informationTextPath, '');
    }
}
