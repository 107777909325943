import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { get } from 'lodash';
import removeMarkdown from 'remove-markdown';
import { EntityWidgetOptions } from '../../../models/widgets.model';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { TranslocoModule } from '@ngneat/transloco';
import { IconComponent } from '../../../components/icon/icon.component';
import { MarkdownComponent } from '../../../components/markdown/markdown.component';

@Component({
    selector: 'app-entity-widget-copy-paste',
    templateUrl: './entity-widget-text-copy-paste.component.html',
    styleUrls: ['./entity-widget-text-copy-paste.component.scss'],
    animations: [
        trigger('showSuccessIcon', [
            state('hidden', style({
                opacity: 0,
            })),
            state('shown', style({
                opacity: 1,
            })),
            transition('hidden => shown', [
                animate('0.5s'),
            ]),
            transition('shown => hidden', [
                animate('0.3s'),
            ]),
        ]),
    ],
    standalone: true,
    imports: [MarkdownComponent, IconComponent, TranslocoModule]
})
export class EntityWidgetTextCopyPasteComponent implements OnInit, OnChanges, EntityWidgetContentComponent {

    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public options: EntityWidgetOptions;
    @Input() public data: string[];
    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();

    public showCopySuccessIcon = false;
    public text: string;

    constructor() {

    }

    public ngOnInit() {

    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.data && this.options) {
            this.text = get(this.data, this.options.text);
            this.showCopySuccessIcon = false;
        }
    }

    public onActionClicked(action: { type: string; payload: any }) {
        this.action.next(action);
    }

    public onCopyClipboardClick(event: any) {
        event.preventDefault();

        navigator.clipboard.writeText(removeMarkdown(this.text)).then(() => {
            this.showCopySuccessIcon = true;

            setTimeout(() => {
                this.showCopySuccessIcon = false;
            }, 500);
        });
    }

}
