import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddToWatchlistDialogComponent } from '../add-to-watchlist-dialog/add-to-watchlist-dialog.component';
import { ReadModeDialogData } from './read-mode-dialog.interface';
import { MarkdownComponent } from '../../markdown/markdown.component';
import { IconComponent } from '../../icon/icon.component';

@Component({
    selector: 'app-read-mode-dialog',
    templateUrl: './read-mode-dialog.component.html',
    styleUrls: ['./read-mode-dialog.component.scss'],
    standalone: true,
    imports: [IconComponent, MarkdownComponent]
})
export class ReadModeDialogComponent implements OnInit {

  public title: string;
  public text: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ReadModeDialogData,
    private dialogRef: MatDialogRef<AddToWatchlistDialogComponent>
  ) { }

  public ngOnInit(): void {
    this.title = this.data.title;
    this.text = this.data.text;
  }

  public onCloseClick(): void {
    this.dialogRef.close();
  }

}
