import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { pickBy } from "lodash";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { StringObject } from "../models/generic.model";
import { AuthStoreService } from "../services/auth-store.service";
import { ConfigurationService } from "../services/configuration.service";
import { LogglyLoggerService } from "../services/loggly-logger.service";
import { AdResponse } from "../store/state/ad.state";
import { AuthenticatedApi } from "./authenticated.api";

@Injectable()
export class AdApi extends AuthenticatedApi {
    
    constructor(
        public http: HttpClient,
        public authStoreService: AuthStoreService,
        public loggerService: LogglyLoggerService,
        public appConfigService: ConfigurationService
    ) {
        super('', http, authStoreService, loggerService);
    }

    public getAd(user: string, context: string, routeParameter?: StringObject): Observable<AdResponse> {
        const environment = this.appConfigService.configuration.environment.id;
        const organization = this.appConfigService.configuration.environment.organizationId;
        const params = pickBy(
            { 
                environment, organization, user,
                pageContext: context,
                ...routeParameter
            },
            value => value !== undefined
        );

        return this.get('ads', { params }).pipe(
            map((response) => response)
        );
    }
}
