import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { includes, isEqual } from 'lodash';

import { FormControlConfig, FormControlSelectOption, FormControlSelectOptions } from '../../../core/models/form.model';
import { SelectItem } from '../../models/widgets.model';
import { MultiValueFilterControl } from '../filter-control';
import { MultiSelectDropdownComponent } from '../../dropdowns/multi-select-dropdown/multi-select-dropdown.component';
import { NgIf, NgFor } from '@angular/common';

interface FormControlSelectOptionsMap {
    [id: string]: FormControlSelectOption;
}

/**
 * Filter underlying selector component is responsible for the underlying filter that includes asset class, underyling
 * group, research and the underlying
 */
@Component({
    selector: 'app-filter-underlying-selector',
    templateUrl: './filter-underlying-selector.component.html',
    styleUrls: ['./filter-underlying-selector.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, MultiSelectDropdownComponent]
})
export class FilterUnderlyingSelectorComponent implements OnInit, MultiValueFilterControl<any> {
    @Input() public config: FormControlConfig;
    @Input()
    set values(values: FormControlSelectOptions) {
        this._values = values;
        this._underlyingMap = values?.underlying ? values.underlying.reduce((map, underlyingOption) => {
            map[underlyingOption.id] = underlyingOption;
            return map;
        }, {} as FormControlSelectOptionsMap) : {} as FormControlSelectOptionsMap;
    }

    @Input() public disabled = false;

    get values() {
        return this._values;
    }

    private _values: FormControlSelectOptions;
    private _underlyingMap: FormControlSelectOptionsMap;

    public _value: UnderlyingFilterControl = {
        underlying: {},
        settlement: {},
        group: {},
        research: {},
    };

    public onChange: () => void;
    public onTouched: () => void;

    constructor() {
    }

    public ngOnInit() {
    }

    /**
     * Returns the available items that can be selected
     * @returns {{id: string, text: string}[]}
     */
    get availableItems(): { id: string; text: string }[] {
        return (this.values) ? this.values.underlying.map((value) => {
            return {
                id: value.id,
                text: value.label,
            };
        }).filter((item) => this.filterUnderlyingItems(item)) : [];
    }

    /**
     * Returns the count of available items that can be selected
     */
    get availableItemCount(): number {
        return this.availableItems.filter((item) => this.filterUnderlyingItems(item)).length;
    }

    /**
     * Returns the selected items
     */
    get selectedItems(): { id: string; text: string }[] {
        return this.values ? this.values.underlying
            .map((value) => {
                return {
                    id: value.id,
                    text: value.label,
                };
            })
            .filter((value) => {
                return this._value.underlying[value.id];
            }) : [];
    }

    get filterUnderlyingItems(): (item: any) => boolean {
        return (item) => {
            const option: any = this._underlyingMap[item.id];

            return (!this.isResearchSelected() || this._value.research[option.research])
                && (!this.isGroupSelected() || this.hasMatchingGroup(option.groups));
        };
    }

    /**
     * Returns if the settlement is selected or not
     * @returns {boolean}
     */
    protected isSettlementSelected(): boolean {
        return Object.keys(this._value.settlement).filter((key) => this._value.settlement[key]).length > 0;
    }

    /**
     * Return if research is selected or not
     * @returns {boolean}
     */
    protected isResearchSelected(): boolean {
        return Object.keys(this._value.research).filter((key) => this._value.research[key]).length > 0;
    }

    /**
     * Returns if group is selected or not
     * @returns {boolean}
     */
    protected isGroupSelected(): boolean {
        return Object.keys(this._value.group).filter((key) => this._value.group[key]).length > 0;
    }

    /**
     * Returns true if one required group (all groups in `this._value.group` which are `true`)
     * is contained in the groups list.
     * @param groups List of groups.
     * @returns {boolean}
     */
    protected hasMatchingGroup(groups: string[]): boolean {
        if (!groups) {
            return false;
        }
        return Object
            .keys(this._value.group)
            .filter((key) => this._value.group[key])
            .some((key) => includes(groups, key));
    }

    /**
     * Writes the values for the settlement, group and research class
     * @param values
     */
    public writeValue(values: any): void {
        if (values) {
            const transformedSettlementValues = values.settlement ? values.settlement.reduce((vals, value) => {
                vals[value] = true;
                return vals;
            }, {}) : {};

            if (!isEqual(this._value.settlement, transformedSettlementValues)) {
                this._value.settlement = transformedSettlementValues;
            }

            const transformedGroupValues = values.group ? values.group.reduce((vals, value) => {
                vals[value] = true;
                return vals;
            }, {}) : {};

            if (!isEqual(this._value.group, transformedGroupValues)) {
                this._value.group = transformedGroupValues;
            }

            const transformedResearchValues = values.research ? values.research.reduce((vals, value) => {
                vals[value] = true;
                return vals;
            }, {}) : {};

            if (!isEqual(this._value.research, transformedResearchValues)) {
                this._value.research = transformedResearchValues;
            }

            const transformedUnderlyingValues = values.underlying ? values.underlying.reduce((vals, value) => {
                vals[value] = true;
                return vals;
            }, {}) : {};

            if (!isEqual(this._value.underlying, transformedUnderlyingValues)) {
                this._value.underlying = transformedUnderlyingValues;
            }
        }
    }

    /**
     * Checks if the filter control is valid
     * @param {UnderlyingFilterControl} value
     * @returns {boolean}
     * @private
     */
    protected _isValidValue(value: UnderlyingFilterControl) {
        if (this.values) {
            Object.keys(this.values).map((key) => {
                return this.values[key].reduce((hasValue, validValue) => {
                    return value === validValue.id || hasValue;
                }, false);
            });
            return true;
        } else {
            return false;
        }

    }

    /**
     *
     * @param fn
     */
    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn({
                    settlement: Object.keys(this._value.settlement).filter((value) => this._value.settlement[value]),
                    group: Object.keys(this._value.group).filter((value) => this._value.group[value]),
                    research: Object.keys(this._value.research).filter((value) => this._value.research[value]),
                    underlying: Object.keys(this._value.underlying).filter((value) => this._value.underlying[value]),
                });
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Checks if the filter is valid
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this._isValidValue(this._value)) ? null : {
            underlyingSelectorError: 'Invalid value specified',
        };
    }

    /**
     * Called when the settle checkbox state has changed
     * @param e
     * @param value
     */
    public onSettlementCheckboxChange(e: Event, value: FormControlSelectOption) {
        e.preventDefault();

        this._value = {
            ...this._value,
            settlement: {
                ...this._value.settlement,
                [value.id]: !this._value.settlement[value.id],
            },

        };

        this.onChange();
    }

    /**
     * Called when the group checkbox state has changed
     * @param e
     * @param value
     */
    public onGroupCheckboxChange(e: Event, value: FormControlSelectOption) {
        e.preventDefault();

        this._value = {
            ...this._value,
            group: {
                ...this._value.group,
                [value.id]: !this._value.group[value.id],
            },

        };

        this.onChange();
    }

    /**
     * Called when the research checkbox state has changed
     * @param e
     * @param value
     */
    public onResearchCheckboxChange(e: Event, value: FormControlSelectOption) {
        e.preventDefault();

        this._value = {
            ...this._value,
            research: {
                ...this._value.research,
                [value.id]: !this._value.research[value.id],
            },

        };

        this.onChange();
    }

    /**
     * Called when the underlying selection changed
     */
    public onSelectionChange(items: SelectItem[]) {
        if (items) {
            items = !Array.isArray(items) ? [items] : items;
        } else {
            items = [];
        }

        this._value.underlying = items?.reduce((values, item) => {
            values[item.id] = true;
            return values;
        }, {});

        this.onChange();
    }

}

export interface UnderlyingFilterControl {
    underlying: { [value: string]: boolean };
    settlement: { [value: string]: boolean };
    group: { [value: string]: boolean };
    research: { [value: string]: boolean };
}
