import { Component, Input, OnInit } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ConfigurationService } from '../../../../core/services/configuration.service';
import { ImageReferenceResolverService } from '../../../../core/services/image-reference-resolver.service';
import { Ad } from '../../../../core/store/state/ad.state';
import { CommonModule } from '@angular/common';
import * as AuthSelectors from '../../../../core/store/selectors/auth.selectors';
import * as RouterSelectors from '../../../../core/store/selectors/router.selectors';

@Component({
    selector: 'app-ad-content',
    templateUrl: './ad-content.component.html',
    styleUrls: ['./ad-content.component.scss'],
    standalone: true,
    imports: [CommonModule]
})
export class AdContentComponent implements OnInit {

  @Input() ad: Ad;

  public environment: string;
  public organization: string;
  
  public authUserId$: Observable<string>;
  public pageContext$: Observable<string>;

  constructor(
    private store: Store,
    private router: Router,
    private appConfigService: ConfigurationService,
    private imageResolver: ImageReferenceResolverService
  ) { }

  public ngOnInit(): void {
    this.environment = this.appConfigService.configuration.environment.id;
    this.organization = this.appConfigService.configuration.environment.organizationId;

    this.authUserId$ = this.store.select(AuthSelectors.selectAuthUserId);
    this.pageContext$ = this.store.select(RouterSelectors.selectContext);
  }

  public resolveImage(fileId: string): string {
    return this.imageResolver.resolveRemoteImagePath(fileId);
  }

  public link(
    link: string,
    environment: string,
    organization: string,
    user: string
  ): UrlTree {
    return this.router.createUrlTree(['link', link], {
      queryParams: {
        environment,
        organization, 
        user
      }
    });
  }

}
