/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 7/4//17.
 */

import { Action, combineReducers } from '@ngrx/store';
import { merge } from 'lodash';
import { AppError } from '../../../../core/errors/base.errors';
import { EntitiesState } from '../../../../core/store/state/entities.state';
import { StringMappingListState } from '../../../../core/store/state/mapping.state';
import { Entity } from '../../../../shared/models/entities.model';
import { LoadProductsForQuerySetFailedAction, ProductActionTypes, ProductsForQuerySetLoadedAction, UpdatedProductsDataLoadedAction } from '../actions/product.actions';
import { ProductState } from '../state/products.state';

export const initialState: ProductState = {
    entities: {},
    querySetMapping: {},
    error: null,
    loadingUpdatedProductData: false,
    loadingProducts: false,
};

/**
 * The reducer responsible for the entities part of the @link{ProductState}
 * @param state
 * @param action
 * @returns {EntitiesState<ProductState>}
 */
export function productEntitiesReducer(
    state: EntitiesState<Entity> = initialState.entities,
    action: Action,
): EntitiesState<Entity> {
    switch (action.type) {
        case ProductActionTypes.CLEAR_PRODUCTS:
            return {
                ...initialState.entities,
            };

        case ProductActionTypes.PRODUCTS_FOR_QUERY_SET_LOADED:
            const productsForQuerySetLoadedAction = action as ProductsForQuerySetLoadedAction;

            return productsForQuerySetLoadedAction.payload.products.reduce((products, product) => {
                products[product.id] = product;
                return products;
            }, {...state});

        case ProductActionTypes.UPDATED_PRODUCTS_DATA_LOADED:
            const updatedProductsDataLoadedAction = action as UpdatedProductsDataLoadedAction;
            return updatedProductsDataLoadedAction.payload.data.reduce((products, data) => {
                if (products[data.id]) {
                    products[data.id] = merge({}, products[data.id], data);
                }
                return products;
            }, {...state});

        default:
            return state;
    }
}

/**
 * The reducer responsible for the entities part of the @link{ProductState}
 * @param state
 * @param action
 * @returns {EntitiesState<ProductState>}
 */
export function productQuerySetMappingReducer(
    state: StringMappingListState<string> = initialState.querySetMapping,
    action: Action,
): StringMappingListState<string> {
    switch (action.type) {
        case ProductActionTypes.CLEAR_PRODUCTS:
            return {
                ...initialState.querySetMapping,
            };

        case ProductActionTypes.PRODUCTS_FOR_QUERY_SET_LOADED:
            const productsForQuerySetLoadedAction = action as ProductsForQuerySetLoadedAction;
            const loadedProducts = productsForQuerySetLoadedAction.payload.products.map((product) => product.id);
            const newMapping = (state[productsForQuerySetLoadedAction.payload.querySetId]) ? [
                ...state[productsForQuerySetLoadedAction.payload.querySetId],
                ...loadedProducts,
            ] : loadedProducts;

            return {
                ...state,
                [productsForQuerySetLoadedAction.payload.querySetId]: newMapping,
            };

        default:
            return state;
    }
}

/**
 *
 * @param state
 * @param action
 */
export function errorReducer(
    state: AppError = initialState.error,
    action: Action,
): AppError {
    switch (action.type) {
        case ProductActionTypes.PRODUCTS_FOR_QUERY_SET_LOADED:
            return null;

        // case ProductActionTypes.LOAD_UPDATED_PRODUCTS_DATA_FAILED:
        case ProductActionTypes.LOAD_PRODUCTS_FOR_QUERY_SET_FAILED:
            const errorAction = action as LoadProductsForQuerySetFailedAction;

            return {
                ...errorAction.payload.error,
            };

        default:
            return state;
    }
}

export function loadingProductsReducer(
    state: boolean = initialState.loadingProducts,
    action: Action,
): boolean {
    switch (action.type) {
        case ProductActionTypes.LOAD_PRODUCTS_FOR_QUERY_SET:
            return true;

        case ProductActionTypes.PRODUCTS_FOR_QUERY_SET_LOADED:
        case ProductActionTypes.LOAD_PRODUCTS_FOR_QUERY_SET_FAILED:
            return false;

        default:
            return state;
    }
}

export function loadingUpdatedProductDataReducer(
    state: boolean = initialState.loadingUpdatedProductData,
    action: Action,
): boolean {
    switch (action.type) {
        case ProductActionTypes.LOAD_UPDATED_PRODUCTS_DATA:
            return true;

        case ProductActionTypes.UPDATED_PRODUCTS_DATA_LOADED:
        case ProductActionTypes.LOAD_UPDATED_PRODUCTS_DATA_FAILED:
            return false;

        default:
            return state;
    }
}

/**
 * The reducer responsible for the @link{ProductState}
 * @type {ActionReducer<ProductState>}
 */
export const productReducer = combineReducers({
    entities: productEntitiesReducer,
    querySetMapping: productQuerySetMappingReducer,
    error: errorReducer,
    loadingProducts: loadingProductsReducer,
    loadingUpdatedProductData: loadingUpdatedProductDataReducer,
});
