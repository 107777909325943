import { createFeatureSelector, createSelector } from "@ngrx/store";
import { includes } from "lodash";
import { ConfigState } from "../state/config.state";

export const selectConfig = createFeatureSelector<ConfigState>('config');

export const selectUiFeatureFlags = createSelector(
    selectConfig,
    (state: ConfigState) => state.uiFeatureFlags
);

export const selectPassCriteria = createSelector(
    selectUiFeatureFlags,
    (uiFeatureFlags: string[]) => includes(uiFeatureFlags, 'PASS_CUSTOMER_TMC_TO_PRODUCT_QUERY') 
);

export const selectShowAdvertising = createSelector(
    selectUiFeatureFlags,
    (uiFeatureFlags: string[]) => includes(uiFeatureFlags, 'SHOW_ADVERTISING') 
);

export const selectTmcSelectable = createSelector(
    selectConfig,
    (state: ConfigState) => state.tmcSelectable  
);
