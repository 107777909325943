import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BarHorizontal2DComponent, BarOrientation, Orientation, ChartCommonModule, AxesModule, BarChartModule } from '@swimlane/ngx-charts';
import { scaleBand } from 'd3-scale';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-dynamic-chart-horizontal',
    templateUrl: './dynamic-chart-horizontal.component.html',
    styleUrls: ['./dynamic-chart-horizontal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('animationState', [
            transition(':leave', [
                style({
                    opacity: 1,
                    transform: '*',
                }),
                animate(500, style({ opacity: 0, transform: 'scale(0)' })),
            ]),
        ]),
    ],
    standalone: true,
    imports: [ChartCommonModule, NgIf, AxesModule, NgFor, BarChartModule]
})
export class DynamicChartHorizontalComponent extends BarHorizontal2DComponent {
    @Input()
    public minNumberOfGroups = -1;

    @Input()
    public flipYAxis = false;

    public orient = BarOrientation;

    public update() {
        super.update();
        this.yAxisTickFormatting = (tick: any) => {
            if (tick && tick.startsWith('__FILLER__')) {
                return '';
            }

            return tick;
        };
    }

    public getGroupScale(): any {
        const groupDomain = [
            ...this.groupDomain,
        ];

        if (this.groupDomain.length < this.minNumberOfGroups) {
            for (let i = 0; i < Math.abs(this.minNumberOfGroups - this.groupDomain.length); i++) {
                groupDomain.push('__FILLER__' + i);
            }
        }

        const spacing = groupDomain.length / (this.dims.height / this.groupPadding + 1);

        return scaleBand()
            .rangeRound(this.flipYAxis ? [this.dims.height, 0] : [0, this.dims.height])
            .paddingInner(spacing)
            .paddingOuter(spacing / 2)
            .domain(groupDomain);
    }
}
