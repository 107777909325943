import {Directive, InjectionToken } from '@angular/core';

export const CHROMA_SUFFIX = new InjectionToken<ChromaSuffix>('ChromaSuffix');

@Directive({
  selector: '[chromaSuffix]',
  standalone: true,
  providers: [{provide: CHROMA_SUFFIX, useExisting: ChromaSuffix}],
})
export class ChromaSuffix { }
