import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-filter-matrix-pricing-variance',
    templateUrl: './filter-matrix-pricing-variance.component.html',
    styleUrls: ['./filter-matrix-pricing-variance.component.scss'],
    standalone: true
})
export class FilterMatrixPricingVarianceComponent implements OnInit {

  constructor() { }

  public ngOnInit() {
  }

}
