<div class="custom-controls-stacked">
    <label
        *ngFor="let value of values"
        class="custom-control"
        [class.custom-checkbox]="!config.options.switches"
        [class.custom-switch]="config.options.switches">

        <input
            [name]="config.path"
            type="checkbox"
            class="custom-control-input"
            [checked]="isCheckboxChecked(value)"
            [disabled]="disabled"
            (change)="onCheckboxChange($event, value)">

        <span class="custom-control-indicator"></span>
        <span class="custom-control-description">
            <ng-container *ngIf="value.items && value.items.length > 1; else simpleItem">
                <span style="float: left">{{value?.label}}</span>
                <select class="form-control" [ngStyle]="{
                    'display': 'inline-block',
                    'width': 'auto',
                    'position': 'relative',
                    'margin-top': '-0.4rem',
                    'margin-left': '0.5rem'
                }" [ngModel]="getSelectedItemForValue(value)" (ngModelChange)="onSingleSelectChange($event, value)" [disabled]="disabled">
                     <option [value]="item.id" *ngFor="let item of value.items">{{item.label}}</option>
                </select>
            </ng-container>
            <ng-template #simpleItem>
                {{value?.label}} ({{value?.items[0]?.label}})
            </ng-template>
        </span>
    </label>
</div>
