import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from '../../../core/models/menu.model';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-submenu-item',
    templateUrl: './submenu-item.component.html',
    standalone: true,
    imports: [CommonModule, RouterModule]
})
export class SubmenuItemComponent implements OnInit {

    @Input() public item: MenuItem;
    @Input() public hasActiveSibling = false;
    @Input() public insideInlineMenu = false;

    constructor() { }

    public ngOnInit() { }
}
