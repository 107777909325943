/**
 * Created by Alex Klein <alex@rocketloop.de> on 10/17/17.
 */

import { Action } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { FormStructureConfig, FormValues } from '../../../../core/models/form.model';
import { type } from '../../../../core/store/util';
import { Entity, EntityTableConfig } from '../../../../shared/models/entities.model';
import { Recommendation } from '../../models/recommendation.model';

export const RecommendationsActionTypes = {

    LOAD_RECOMMENDATION_FILTER_STRUCTURE: type('RECOMMENDATION:LOAD_RECOMMENDATION_FILTER_STRUCTURE'),
    LOAD_RECOMMENDATION_FILTER_STRUCTURE_SUCCEEDED: type('RECOMMENDATION:LOAD_RECOMMENDATION_FILTER_STRUCTURE_SUCCEEDED'),
    LOAD_RECOMMENDATION_FILTER_STRUCTURE_FAILED: type('RECOMMENDATION:LOAD_RECOMMENDATION_FILTER_STRUCTURE_FAILED'),

    LOAD_RECOMMENDATION_FILTER_VALUES: type('RECOMMENDATION:LOAD_RECOMMENDATION_FILTER_VALUES'),
    LOAD_RECOMMENDATION_FILTER_VALUES_SUCCEEDED: type('RECOMMENDATION:LOAD_RECOMMENDATION_FILTER_VALUES_SUCCEEDED'),
    LOAD_RECOMMENDATION_FILTER_VALUES_FAILED: type('RECOMMENDATION:LOAD_RECOMMENDATION_FILTER_VALUES_FAILED'),

    LOAD_RECOMMENDATION_TABLE_STRUCTURE: type('RECOMMENDATION:LOAD_RECOMMENDATION_TABLE_STRUCTURE'),
    LOAD_RECOMMENDATION_TABLE_STRUCTURE_SUCCEEDED: type('RECOMMENDATION:LOAD_RECOMMENDATION_TABLE_STRUCTURE_SUCCEEDED'),
    LOAD_RECOMMENDATION_TABLE_STRUCTURE_FAILED: type('RECOMMENDATION:LOAD_RECOMMENDATION_TABLE_STRUCTURE_FAILED'),

    LOAD_RECOMMENDATION_TABLE_VALUES: type('RECOMMENDATION:LOAD_RECOMMENDATION_TABLE_VALUES'),
    LOAD_RECOMMENDATION_TABLE_VALUES_SUCCEEDED: type('RECOMMENDATION:LOAD_RECOMMENDATION_TABLE_VALUES_SUCCEEDED'),
    LOAD_RECOMMENDATION_TABLE_VALUES_FAILED: type('RECOMMENDATION:LOAD_RECOMMENDATION_TABLE_VALUES_FAILED'),

    LOAD_RECOMMENDATIONS: type('RECOMMENDATION:LOAD_RECOMMENDATIONS'),
    LOAD_RECOMMENDATIONS_SUCCEEDED: type('RECOMMENDATION:LOAD_RECOMMENDATIONS_SUCCEEDED'),
    LOAD_RECOMMENDATIONS_FAILED: type('RECOMMENDATION:LOAD_RECOMMENDATIONS_FAILED'),

    UPDATE_RECOMMENDATION_TYPE: type('RECOMMENDATION:UPDATE_RECOMMENDATION_TYPE'),

    UPDATE_RECOMMENDATIONS: type('RECOMMENDATION:UPDATE_RECOMMENDATIONS'),
    UPDATE_RECOMMENDATIONS_SUCCEEDED: type('RECOMMENDATION:UPDATE_RECOMMENDATIONS_SUCCEEDED'),
    UPDATE_RECOMMENDATIONS_FAILED: type('RECOMMENDATION:UPDATE_RECOMMENDATIONS_FAILED'),

};

/* LoadRecommendationFilterStructureAction **/

export interface LoadRecommendationFilterStructureAction extends Action {
    payload?: void;
}

export function loadRecommendationFilterStructure(): LoadRecommendationFilterStructureAction {
    return {
        type: RecommendationsActionTypes.LOAD_RECOMMENDATION_FILTER_STRUCTURE,
    };
}

/* LoadRecommendationFilterStructureSucceededAction **/

export interface LoadRecommendationFilterStructureSucceededAction extends Action {
    payload: {
        structure: FormStructureConfig;
    };
}

export function loadRecommendationFilterStructureSucceeded(
    structure: FormStructureConfig,
): LoadRecommendationFilterStructureSucceededAction {
    return {
        type: RecommendationsActionTypes.LOAD_RECOMMENDATION_FILTER_STRUCTURE_SUCCEEDED,
        payload: {
            structure,
        },
    };
}

/* LoadRecommendationFilterStructureFailedAction **/

export interface LoadRecommendationFilterStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadRecommendationFilterStructureFailed(error: AppError): LoadRecommendationFilterStructureFailedAction {
    return {
        type: RecommendationsActionTypes.LOAD_RECOMMENDATION_FILTER_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/* LoadRecommendationFilterStructureAction **/

export interface LoadRecommendationFilterValuesAction extends Action {
    payload?: void;
}

export function loadRecommendationFilterValues(): LoadRecommendationFilterValuesAction {
    return {
        type: RecommendationsActionTypes.LOAD_RECOMMENDATION_FILTER_VALUES,
    };
}

/* LoadRecommendationFilterValuesSucceededAction **/

export interface LoadRecommendationFilterValuesSucceededAction extends Action {
    payload: {
        values: FormValues;
    };
}

export function loadRecommendationFilterValuesSucceeded(values: FormValues): LoadRecommendationFilterValuesSucceededAction {
    return {
        type: RecommendationsActionTypes.LOAD_RECOMMENDATION_FILTER_VALUES_SUCCEEDED,
        payload: {
            values,
        },
    };
}

/* LoadRecommendationFilterValuesFailedAction **/

export interface LoadRecommendationFilterValuesFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadRecommendationFilterValuesFailed(error: AppError): LoadRecommendationFilterValuesFailedAction {
    return {
        type: RecommendationsActionTypes.LOAD_RECOMMENDATION_FILTER_VALUES_FAILED,
        payload: {
            error,
        },
    };
}

/* LoadRecommendationTableStructureAction **/

export interface LoadRecommendationTableStructureAction extends Action {
    payload?: void;
}

export function loadRecommendationTableStructure(): LoadRecommendationTableStructureAction {
    return {
        type: RecommendationsActionTypes.LOAD_RECOMMENDATION_TABLE_STRUCTURE,
    };
}

/* LoadRecommendationTableStructureSucceededAction **/

export interface LoadRecommendationTableStructureSucceededAction extends Action {
    payload: {
        structure: EntityTableConfig;
    };
}

export function loadRecommendationTableStructureSucceeded(structure: EntityTableConfig): LoadRecommendationTableStructureSucceededAction {
    return {
        type: RecommendationsActionTypes.LOAD_RECOMMENDATION_TABLE_STRUCTURE_SUCCEEDED,
        payload: {
            structure,
        },
    };
}

/* LoadRecommendationTableStructureFailedAction **/

export interface LoadRecommendationTableStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadRecommendationTableStructureFailed(error: AppError): LoadRecommendationTableStructureFailedAction {
    return {
        type: RecommendationsActionTypes.LOAD_RECOMMENDATION_TABLE_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/* LoadRecommendationTableValuesAction **/

export interface LoadRecommendationTableValuesAction extends Action {
    payload?: void;
}

export function loadRecommendationTableValues(): LoadRecommendationTableValuesAction {
    return {
        type: RecommendationsActionTypes.LOAD_RECOMMENDATION_TABLE_VALUES,
    };
}

/* LoadRecommendationTableValuesSucceededAction **/

export interface LoadRecommendationTableValuesSucceededAction extends Action {
    payload: {
        entities: Entity[];
    };
}

export function loadRecommendationTableValuesSucceeded(entities: Entity[]): LoadRecommendationTableValuesSucceededAction {
    return {
        type: RecommendationsActionTypes.LOAD_RECOMMENDATION_TABLE_VALUES_SUCCEEDED,
        payload: {
            entities,
        },
    };
}

/* LoadRecommendationTableValuesFailedAction **/

export interface LoadRecommendationTableValuesFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadRecommendationTableValuesFailed(error: AppError): LoadRecommendationTableValuesFailedAction {
    return {
        type: RecommendationsActionTypes.LOAD_RECOMMENDATION_TABLE_VALUES_FAILED,
        payload: {
            error,
        },
    };
}

/* LoadRecommendationAction **/

export interface LoadRecommendationAction extends Action {
    payload?: void;
}

export function loadRecommendation(): LoadRecommendationAction {
    return {
        type: RecommendationsActionTypes.LOAD_RECOMMENDATIONS,
    };
}

/* LoadRecommendationSucceededAction **/

export interface LoadRecommendationSucceededAction extends Action {
    payload: {
        recommendations: Recommendation[];
    };
}

export function loadRecommendationSucceeded(recommendations: Recommendation[]): LoadRecommendationSucceededAction {
    return {
        type: RecommendationsActionTypes.LOAD_RECOMMENDATIONS_SUCCEEDED,
        payload: {
            recommendations,
        },
    };
}

/* LoadRecommendationFailedAction **/

export interface LoadRecommendationFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadRecommendationFailed(error: AppError): LoadRecommendationFailedAction {
    return {
        type: RecommendationsActionTypes.LOAD_RECOMMENDATIONS_FAILED,
        payload: {
            error,
        },
    };
}

/* UpdateRecommendationsListAction **/

export interface UpdateRecommendationsListAction extends Action {
    payload: {
        recommendations: Recommendation[];
    };
}

export function updateRecommendationsList(recommendations: Recommendation[]): UpdateRecommendationsListAction {
    return {
        type: RecommendationsActionTypes.UPDATE_RECOMMENDATIONS, payload: {
            recommendations,
        },
    };
}

/* UpdateRecommendationsListSucceededAction **/

export interface UpdateRecommendationsListSucceededAction extends Action {
    payload: object;
}

export function updateRecommendationsListSucceeded(): UpdateRecommendationsListSucceededAction {
    return {
        type: RecommendationsActionTypes.UPDATE_RECOMMENDATIONS_SUCCEEDED,
        payload: {},
    };
}

/* UpdateRecommendationsListFailedAction **/

export interface UpdateRecommendationsListFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function updateRecommendationsListFailed(error: AppError): UpdateRecommendationsListFailedAction {
    return {
        type: RecommendationsActionTypes.UPDATE_RECOMMENDATIONS_FAILED,
        payload: {
            error,
        },
    };
}

/* StoreRecommendationChangesAction **/

export interface UpdateRecommendationTypeAction extends Action {
    payload: {
        id: string;
        status: string;
    };
}

export function updateRecommendationType(id: string, status: string): UpdateRecommendationTypeAction {
    return {
        type: RecommendationsActionTypes.UPDATE_RECOMMENDATION_TYPE,
        payload: {
            id,
            status,
        },
    };
}
