import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, ValidationErrors, FormsModule } from '@angular/forms';
import { FormControlConfig, FormControlOptions } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';
import { get } from 'lodash';
import { TranslocoService, TranslocoModule } from '@ngneat/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MaxValidationDirective } from '../../directives/max-validation.directive';
import { MinValidationDirective } from '../../directives/min-validation.directive';

@Component({
    selector: 'app-filter-labeled-number-input',
    templateUrl: './filter-labeled-number-input.component.html',
    styleUrls: ['./filter-labeled-number-input.component.scss'],
    standalone: true,
    imports: [FormsModule, MinValidationDirective, MaxValidationDirective, MatTooltipModule, TranslocoModule]
})
export class FilterLabeledNumberInputComponent implements OnInit, SingleValueFilterControl<number | string>, OnChanges {

    @Input() public config: FormControlConfig<LabelledNumberInputOptions>;

    @Input() public values: any;

    @Input() public disabled = false;

    @Output() public blurControl: EventEmitter<void> = new EventEmitter();

    public value: any;

    public label: string;

    public unit: string;

    public onChange: () => void;

    public onTouched: () => void;

    constructor() { }

    public ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.config || changes.values) {
            this._updateLabelAndUnit();
        }
    }

    public writeValue(value: number): void {
        if (this.value !== value) {
            this.value = value;
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this.value);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (!this.values || (!this.values.min || this.value >= this.values.min) && (!this.values.max || this.value <= this.values.max))
            ? null : {
                labelledNumberInput: 'Invalid value specified.',
            };
    }

    /**
     * Called when the selection changed
     * @param value
     */
    public onInputChange(value: string) {
        const prepValue = value.replace(',', '.');
        const newValue = (prepValue.match(/^\d+(\.\d*)?$/)) ? parseFloat(prepValue) : (value) ? value : null;

        console.log("NEW_VALUE", newValue, "---", value, "----", prepValue);
        if (this.value !== newValue && this.onChange) {
            this.value = this.config.options.isPercent ? (+newValue / 100) : newValue;
            this.onChange();
        }
    }

    private _updateLabelAndUnit() {
        this.label = (this.values && this.config && this.config.options && this.config.options.label)
            ? get(this.values, this.config.options.label)
            : '';

        this.unit = (this.values && this.config && this.config.options && this.config.options.unit)
            ? get(this.values, this.config.options.unit)
            : '';
    }

}

export interface LabelledNumberInputOptions extends FormControlOptions {
    label: string;
    unit: string;
    placeholder?: string;
    labelCols?: number;
    additionalClasses?: string;
    isPercent?: boolean;
}
