import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, forwardRef, HostBinding, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, FormsModule } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { MaxValidationDirective } from '../../directives/max-validation.directive';
import { MinValidationDirective } from '../../directives/min-validation.directive';

@Component({
    selector: 'app-number-input',
    templateUrl: './number-input.component.html',
    styleUrls: ['./number-input.component.scss'],
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumberInputComponent),
            multi: true,
        }, {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => NumberInputComponent),
            multi: true,
        }],
    standalone: true,
    imports: [FormsModule, MinValidationDirective, MaxValidationDirective]
})
export class NumberInputComponent implements OnInit, ControlValueAccessor, Validator {

    @HostBinding('class.number-input')
    @Input()
    public numberInputClass = true;

    @Input()
    public formControlClass = false;

    @Input() public placeholder: string;

    @Input() public min: number;

    @Input() public max: number;

    @Output() public blurControl: EventEmitter<void> = new EventEmitter();

    @Input() public percent = false;

    @Input() public isInteger = false;

    @Input() public tabindex = 0;

    public onChange: () => void;

    public onTouched: () => void;

    public value: number | string;

    public displayValue: string;

    public disabled: boolean;

    constructor(
        private decimalPipe: DecimalPipe,
        private translocoService: TranslocoService
    ) { }

    public ngOnInit() {
    }

    public writeValue(value: number): void {
        if (this.value !== value) {
            this.value = value;

            if (!this.displayValue) {
                this._updateDisplayValue();
            }
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this.value);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (!isNaN(+this.value) && (!this.min || this.value >= this.min) && (!this.max || this.value <= this.max))
            ? null : {
                numberInput: 'Invalid value specified.',
            };
    }

    public setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    /**
     * Called when the selection changed
     * @param value
     */
    public onInputChange(value: string) {
        this.displayValue = value;
        let prepValue = value;

        if (value.match(/(\d+\.)+\d+,\d+/)) {
            prepValue = prepValue.replace(/\./g, '');
        }

        prepValue = prepValue.replace(/,/g, '.');
        let newValue = (prepValue.match(/^\d+(\.\d+)/)) ? parseFloat(prepValue) : (value) ? value : null;

        if (newValue !== null && this.percent) {
            newValue = +newValue / 100;
        }

        if (this.value !== newValue && this.onChange) {
            this.value = newValue;
            this.onChange();
        }
    }

    /**
     * Called when the user removes focus from the field
     */
    public onBlur() {
        this._updateDisplayValue();
    }

    private _updateDisplayValue() {
        if (this.percent) {
            this.displayValue = this.decimalPipe.transform(parseFloat(`${this.value}`) * 100, this.isInteger ? '.0-0' : '.2-2', this.translocoService.getActiveLang());
        } else {
            this.displayValue = this.decimalPipe.transform(parseFloat(`${this.value}`), this.isInteger ? '.0-0' : '.2-2', this.translocoService.getActiveLang());
        }
    }

}
