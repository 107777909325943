import {
    ChangeDetectionStrategy,
    Component,
    Input, ViewChild,
} from '@angular/core';
import { YAxisComponent, AxesModule } from '@swimlane/ngx-charts';
import { DynamicChartYAxisTicksComponent } from './y-axis-ticks.component';
import { NgIf } from '@angular/common';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'g[app-charts-y-axis]',
    template: `
    <svg:g [attr.class]="yAxisClassName" [attr.transform]="transform">
      <svg:g
        app-charts-y-axis-ticks
        *ngIf="yScale"
        [minimal]="minimal"
        [trimTicks]="trimTicks"
        [maxTickLength]="maxTickLength"
        [tickFormatting]="tickFormatting"
        [tickArguments]="tickArguments"
        [tickValues]="ticks"
        [tickStroke]="tickStroke"
        [scale]="yScale"
        [orient]="yOrient"
        [showGridLines]="showGridLines"
        [gridLineWidth]="dims.width"
        [referenceLines]="referenceLines"
        [showRefLines]="showRefLines"
        [showRefLabels]="showRefLabels"
        [height]="dims.height"
        (dimensionsChanged)="emitTicksWidth($event)"
      />
      <svg:g
        ngx-charts-axis-label
        *ngIf="showLabel"
        [label]="labelText"
        [offset]="labelOffset"
        [orient]="yOrient"
        [height]="dims.height"
        [width]="dims.width"
      ></svg:g>
    </svg:g>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, DynamicChartYAxisTicksComponent, AxesModule]
})
export class DynamicChartYAxisComponent extends YAxisComponent {
    @Input()
    public minimal = false;

    @ViewChild(DynamicChartYAxisTicksComponent) public ticksComponent: DynamicChartYAxisTicksComponent;
}
