import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors, Validators, FormsModule } from '@angular/forms';
import { FormControlConfig, FormControlSelectOption } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';
import { DecimalPipe, PercentPipe, NgIf } from '@angular/common';
import { ObjectMaker } from '../../../core/helpers/form.helper';
import { uniqueId } from 'lodash';

/**
 * Single select component that is responsible for the text input filter
 */
@Component({
    selector: 'app-filter-text-input',
    templateUrl: './filter-text-input.component.html',
    styleUrls: ['./filter-text-input.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule]
})
export class FilterTextInputComponent implements OnInit, SingleValueFilterControl<string> {

    @Input() public config: FormControlConfig;

    @Input() public values: FormControlSelectOption[];

    @Input() public disabled = false;
    public onChange: () => void;
    public onTouched: () => void;
    public displayValue: string;

    constructor(private decimalPipe: DecimalPipe, private percentPipe: PercentPipe, private cdf: ChangeDetectorRef) {
    }

    public _value: string | number;

    get value() {
        if (this.isPercentValue()) {
            return this.toInt(+this._value * 100);
        } else if (this.isNumberValue()) {
            return +this._value;
        }

        return this._value;
    }

    public ngOnInit() {
    }

    public writeValue(value: string): void {
        if (this._value !== value) {
            this._value = value;
            this._updateDisplayValue();
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this._value);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        if (this.isNumberValue() && !this.config.options.optional) {
            return isNaN(+this._value) || this._value === null || this._value === undefined || !('' + this._value).length
                ? ObjectMaker.toJson('textInputNumberError')
                : null;
        }

        return null;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public isPercentValue() {
        if (this.config && this.config.options) {
            return this.config.options.inputType === 'percent';
        }

        return false;
    }

    public isNumberValue() {
        if (this.config && this.config.options) {
            return this.config.options.inputType === 'number' || this.isPercentValue() || this.isIntegerValue();
        }

        return false;
    }

    public isIntegerValue() {
        if (this.config && this.config.options) {
            return this.config.options.inputType === 'integer';
        }

        return false;
    }

    public isNullableType(): boolean {
        return ['text', 'textarea'].includes(this.config?.options?.inputType);
    }

    public storeEmptyInputAsNull(): boolean {
        return this.config?.options?.storeEmptyInputAsNull === true;
    }

    /**
     * Called when the selection changed
     * @param value
     */
    public onInputChange(value: string): void {
        let prepValue = value;
        let newValue: any = value;

        this.displayValue = value;

        if (this.isNumberValue()) {
            prepValue = prepValue.replace(',', '.');
            newValue = (prepValue.match(/^\d+(\.\d*)?$/))
                ? (
                    this.isIntegerValue() ? parseInt(prepValue, 10) : parseFloat(prepValue)
                )
                : (
                    (value) ? value : null
                );

            if (newValue !== null && this.isPercentValue()) {
                newValue = +newValue / 100;
            }

            if (newValue !== null && this.isIntegerValue()) {
                newValue = this.toInt(+newValue);
            }
        } else if (this.isNullableType()) {
            if (newValue === '' && this.storeEmptyInputAsNull()) {
                newValue = null;
            }
        }

        this._value = newValue;

        if (this.onChange) {
            this.onChange();
        }
    }

    public toInt(num: number) {
        return Math.floor(num);
    }

    /**
     * Called when the user removes focus from the field
     */
    public onBlur() {
        this._updateDisplayValue();
    }

    private _updateDisplayValue() {
        if (this.isNumberValue() && isNaN(this._value as any)) {
            this.displayValue = '';
            return;
        }

        if (this.isPercentValue()) {
            this.displayValue = this.decimalPipe.transform(parseFloat(`${this._value}`) * 100, this.config.options.isInteger ? '.0-0' : '.2-3');
        } else if (this.isIntegerValue()) {
            this.displayValue = '' + parseInt(`${this._value}`, 10);
        } else if (this.isNumberValue()) {
            this.displayValue = this.decimalPipe.transform(parseFloat(`${this._value}`), this.config.options.isInteger ? '.0-0' : '.2-3');
        } else {
            this.displayValue = `${this._value || ''}`;
        }
    }
}
