<ng-template #defaultTooltip>
</ng-template>


<ng-container *ngIf="config">
    <ng-container [ngSwitch]="config.chartType">
        <div echarts
             [options]="echartsOptions"
             [loading]="loading"
             [style.minHeight]="((chartView && chartView[1]) || 0) + 'px'"
             [ngStyle]="{'height': 'calc(100% - ' + offsetHeight + 'px)'}"
             (chartInit)="onChartInit($event)"
             (chartRendered)="onChartRendered($event)"
        ></div>
        <!--<app-dynamic-chart-line
            *ngSwitchCase="'line'"
            [results]="transformedData"
            [customColors]="colors"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="config.options.showLegend"
            [legendPosition]="'bottom'"
            [showXAxisLabel]="config.options.xAxisLabel"
            [showYAxisLabel]="config.options.yAxisLabel"
            [xAxisLabel]="config.options.xAxisLabel"
            [yAxisLabel]="config.options.yAxisLabel"
            [xAxisTickFormatting]="(config.options.xAxisTickFormat) ? formatXAxisTick : undefined"
            [yAxisTickFormatting]="(config.options.yAxisTickFormat) ? formatYAxisTick : undefined"
            [xAxisTicks]="xAxisTicks"
            [yAxisTicks]="config.options.yAxisTicks"
            [autoScale]="false"
            [xScaleMin]="xAxisMin"
            [xScaleMax]="xAxisMax"
            [yScaleMin]="config.options.yAxisMin"
            [yScaleMax]="config.options.yAxisMax"
            [roundDomains]="true"
            [referenceLines]="referenceLines"
            [view]="chartView"
            [timeline]="config.options.timeline || false"
            [showRefLines]="config.options.showRefLines || false"
            [showRefLabels]="config.options.showRefLabels || false"
            [minimal]="config.options.minimal || false">
        </app-dynamic-chart-line>-->
        <!--<ngx-charts-bar-vertical-2d
            *ngSwitchCase="'bar'"
            [results]="transformedData"
            [customColors]="colors"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="config.options.showLegend"
            [showXAxisLabel]="config.options.xAxisLabel"
            [showYAxisLabel]="config.options.yAxisLabel"
            [xAxisLabel]="config.options.xAxisLabel"
            [yAxisLabel]="config.options.yAxisLabel"
            [xAxisTickFormatting]="(config.options.xAxisTickFormat) ? formatXAxisTick : undefined"
            [yAxisTickFormatting]="(config.options.yAxisTickFormat) ? formatYAxisTick : undefined"
            [xAxisTicks]="xAxisTicks"
            [yAxisTicks]="config.options.yAxisTicks"
            [yScaleMax]="config.options.yAxisMax"
            [roundDomains]="true"
            [roundEdges]="false"
            [showDataLabel]="true"
            [dataLabelFormatting]="(config.options.yAxisTickFormat) ? formatYAxisTick : undefined">
        </ngx-charts-bar-vertical-2d>
        <app-dynamic-chart-horizontal
            *ngSwitchCase="'horizontal-bar'"
            [view]="chartView"
            [results]="transformedData"
            [customColors]="colors"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="config.options.showLegend"
            [showXAxisLabel]="config.options.xAxisLabel"
            [showYAxisLabel]="config.options.yAxisLabel"
            [xAxisLabel]="config.options.xAxisLabel"
            [yAxisLabel]="config.options.yAxisLabel"
            [xAxisTickFormatting]="(config.options.xAxisTickFormat) ? formatXAxisTick : undefined"
            [yAxisTickFormatting]="(config.options.yAxisTickFormat) ? formatYAxisTick : undefined"
            [xAxisTicks]="xAxisTicks"
            [yAxisTicks]="config.options.yAxisTicks"
            [xScaleMax]="xAxisMax"
            [roundDomains]="true"
            [roundEdges]="false"
            [showDataLabel]="true"
            [minNumberOfGroups]="(config.options.minNumberOfGroups) ? config.options.minNumberOfGroups : -1"
            [flipYAxis]="!!config.options.flipYAxis"
            [dataLabelFormatting]="(config.options.xAxisTickFormat) ? formatXAxisTick : undefined">
        </app-dynamic-chart-horizontal>
        <ngx-charts-bubble-chart
            *ngSwitchCase="'bubble'"
            [results]="transformedData"
            [customColors]="colors"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="config.options.showLegend"
            [showXAxisLabel]="!!config.options.xAxisLabel"
            [showYAxisLabel]="!!config.options.yAxisLabel"
            [xAxisLabel]="config.options.xAxisLabel"
            [yAxisLabel]="config.options.yAxisLabel"
            [xAxisTickFormatting]="(config.options.xAxisTickFormat) ? formatXAxisTick : undefined"
            [yAxisTickFormatting]="(config.options.yAxisTickFormat) ? formatYAxisTick : undefined"
            [xAxisTicks]="xAxisTicks"
            [maxRadius]="config.options.maxRadius || 10"
            [minRadius]="config.options.minRadius || 3"
            [yAxisTicks]="config.options.yAxisTicks"
            [autoScale]="config.options.autoScale || false"
            [xScaleMin]="xAxisMin"
            [xScaleMax]="xAxisMax"
            [yScaleMin]="config.options.yAxisMin"
            [yScaleMax]="config.options.yAxisMax"
            [roundDomains]="true"
            (select)="onChartSelect($event)">
            <ng-template #tooltipTemplate let-model="model">
                <ng-template [ngTemplateOutlet]="tooltipTemplateRef"
                             [ngTemplateOutletContext]="{ model: model }"></ng-template>
            </ng-template>
        </ngx-charts-bubble-chart>
        <ngx-charts-pie-chart
            *ngSwitchCase="'pie'"
            [results]="transformedData"
            [customColors]="colors"
            [legend]="config.options.showLegend"
            [legendPosition]="'bottom'"
            [labelFormatting]="pieChartLabelFormatting.bind(this)"
            [trimLabels]="false"
            [labels]="true">
        </ngx-charts-pie-chart>-->
    </ng-container>
</ng-container>
