import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { clearTokensLocalStorage } from './app/core/helpers/local-storage.helper';
import { getParamValueQueryString, stringToBoolean } from './app/shared/helpers/general.helper';
import { ConfigurationWindow } from './declarations';
import { environment } from './environments/environment';

let env = 'default';

/*
 * DERICON-3583: Handle edge case when calling a direct link from an email.
 *               Outlook/etc. seem to transform '//' to '/' which breaks our overlay outlet routing.
 */
const overlayIndex = window.location.href.indexOf('/overlay:');
if (overlayIndex !== -1) {
    const charPrev = window.location.href.charAt(overlayIndex - 1);

    if (charPrev !== '/') {
        window.location.replace(window.location.href.replace('/overlay:', '//overlay:'));
    }
}

const redirectToken = getParamValueQueryString('redirectToken');

if (redirectToken) {
    clearTokensLocalStorage();
}

if (environment.production) {
    enableProdMode();

    env = window.location.host;
}

const disableTranslations = getParamValueQueryString('disableTranslations') || sessionStorage.getItem('disableTranslations');
if (stringToBoolean(disableTranslations)) {
    (window as unknown as ConfigurationWindow).disableTranslations = true;
    
    sessionStorage.setItem('disableTranslations', 'true');
} else {
    (window as unknown as ConfigurationWindow).disableTranslations = false;

    sessionStorage.removeItem('disableTranslations');
}

fetch(`assets/config/demo.pb-suite.de.json`)
.then((response) => response.json())
.then((json) => {
    (window as unknown as ConfigurationWindow).configuration = json;

    return platformBrowserDynamic().bootstrapModule(AppModule);
})
.catch((err) => console.error(err));
