import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { NgFor, NgStyle } from '@angular/common';

@Component({
    selector: 'app-chart-legend',
    templateUrl: './chart-legend.component.html',
    styleUrls: ['./chart-legend.component.scss'],
    standalone: true,
    imports: [NgFor, NgStyle]
})
export class ChartLegendComponent implements OnInit {

    @HostBinding('class.chart-legend') public chartLegendClass = true;

    @Input() public entries: LegendEntry[];

    constructor() {
    }

    public ngOnInit() {
    }

}

export interface LegendEntry {
    label: string;
    color: string;
}
