import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { get } from 'lodash';
import { ImageOptions } from '../../../models/widgets.model';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';

@Component({
    selector: 'app-image-widget',
    templateUrl: './image-widget.component.html',
    standalone: true
})
export class ImageWidgetComponent implements OnInit, OnChanges, EntityWidgetContentComponent {

    @HostBinding('class.image-widget') public imageWidgetClass = true;

    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public options: ImageOptions;
    @Input() public data: any;
    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();

    public image: string;

    constructor() {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.data && this.options) {
            this.image = get(this.data, this.options.image);
        }
    }

    public ngOnInit() {
    }

}
