<div class="multi-select-dropdown">
    <ng-select
        [items]="items"
        [bindLabel]="'text'"
        [trackByFn]="trackById"
        [multiple]="true"
        [readonly]="disabled"
        [clearSearchOnAdd]="true"
        [clearable]="false"
        [clearOnBackspace]="false"
        [searchable]="true"
        [placeholder]="placeholder"
        [notFoundText]="noMatches"
        [(ngModel)]="_active">
        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear"></ng-template>
    </ng-select>

    <div class="ui-select-matches mt-2" *ngIf="!hideSelections">
        <div class="ui-select-match">
            <div *ngFor="let item of _active; trackBy: trackById">
            <span class="ui-select-match-item" [class.ui-selected-match-item--disabled]="!_filter(item)" tabindex="-1">
                <a class="close" (click)="onRemoveClick(item, $event)">&times;</a>
                <span>{{item.text}}</span>
            </span>
            </div>
        </div>
    </div>
</div>
