import { IconName } from '@dericon/wms-icons';
import { AdvancedCondition, FormColumnConfig, FormStructureConfig, FormStructureWithoutAreasConfig, FormValues } from '../../../../core/models/form.model';
import { GenericActionStructure } from '../../../../shared/components/entity-actions/entity-actions.component';
import { DynamicChartConfigOptions, DynamicChartData } from '../../../../shared/models/dynamic-chart.model';
import { Entity, EntityTableConfig } from '../../../../shared/models/entities.model';
import { UserEntity } from '../../../admin/routes/user-manager/api/user-manager.model';
import { ComponentToDataTypeMap, ComponentType } from './product-designer-components.model';

export enum EditorMode {
    EDITING,
    REQUESTING,
    RESPONSE,
    VIEWING,
    OFFER,
    SURVEY,
}

export enum PrintMode {
    FULL,
    NO_RESPONSES
}

export interface ProductComponentFormStructure {
    columns: FormColumnConfig[];
}

export enum ConditionErrorType {
    CARDINALITY_LOW,
    CARDINALITY_HIGH,
}

export interface ConditionError {
    type: ConditionErrorType;
    componentName: string;
    minOccurance: number;
    maxOccurance: number;
}

export interface RequiredCondition {
    match: 'SOME' | 'ALL';
    type: 'VALUE' | 'HAS_COMPONENT';
    typeMethod: 'NOT_IN' | 'IN' | 'EXISTS' | 'EQ' | 'UEQ' | 'LT' | 'GT' | 'LEQ' | 'GEQ';
    conditionValues: string[];
    location: {
        originPath?: string; // <AREA>/<COMPONENT>/my.great.data.path
        targetPath?: string; // <AREA>/<COMPONENT>/my.great.data.path
    };
}

export interface ConditionalCardinality {
    conditions: AdvancedCondition[];
    minOccurance: number;
    maxOccurance: number;
}

export interface TransformationComponent {
    type: string;
    dataMap: { [key: string]: string };
}

export interface TransformationGenerator {
    type: string;
    params: { [key: string]: any };
}

export interface TransformationConfig {
    enabled: boolean;
    targetComponents?: ProductComponentStructure[];
    componentName?: string;
    components?: TransformationComponent[];
    generators?: TransformationGenerator[];
    applicableSolveTargets: Array<string>;
}

export interface ProductComponentStructure {
    title: string;
    name: string;
    hideTitle?: boolean;
    hideTitleInSummary?: boolean;
    hide?: boolean;
    icon?: string;
    minOccurance: number;
    maxOccurance: number;
    conditionalCardinality?: ConditionalCardinality[];
    width?: number;
    undeletable?: boolean;
    undraggable?: boolean;
    form: FormStructureWithoutAreasConfig;
    initial?: FormValues;
    hideView?: string[];
    transformationConfig?: TransformationConfig;
    transformation?: TransformationConfig;
    fieldRestrictions?: {
        componentType: string;
        displayConfiguration?: {
            availableModes?: string[];
            showGraph?: boolean;
        };
        fields: ComponentRestrictionsField[];
    };
    __group?: ProductGroupStructure;
}

export interface ProductComponentStructureWithMeta {
    structure: ProductComponentStructure;
    hasEmptyPathControl: boolean;
    allPaths: string[];
}

export interface ComponentRestrictionsField {
    name: string;
    type: string;
    optional: boolean;
    hidden: boolean;
}

export interface ProductGroupStructure {
    title: string;
    translateTitle?: boolean;
    name: string;
    hideActions?: boolean;
    hideView?: string[];
    components: ProductComponentStructure[];
}

export interface ProductStructure {
    productType: string;
    viewOnly?: boolean;
    solveTargets: { solveTarget: string; name: string }[];
    offerTypes: { offerType: string; name: string }[];
    multiRequestTypeConfigurations: {
        multiRequestColumnControls: MultiRequestFieldStructure[];
    };
    productDisplayConfiguration?: {
        stepByStep?: boolean;
        buttonsAtBottom?: boolean;
        progressIndicator?: boolean;
        graphOnLeft?: boolean;
    };
    groups: ProductGroupStructure[];
}

export interface ProductComponent extends FormValues {
    type: string; // needs to match the name of one existing ProductComponentStructure
    // data: Entity;
    // [key:string]: any;
}

export interface Product {
    [key: string]: ProductComponent[];
}

export interface ProductDecomposeResultTarget {
    structure: ProductComponentStructure;
    values?: ProductComponent[];
    value?: ProductComponent;
    selectionValues: FormValues;
}

export interface ProductDecomposeResult {
    source: ProductComponent;
    target: ProductDecomposeResultTarget[];
}

export interface ProductDecomposeEvent {
    group: ProductGroupStructure;
    result: ProductDecomposeResult;
    origin: {
        component: ProductComponent;
        structure: ProductComponentStructure;
        index: number;
    };
}

export interface ProductDecomposeDictValue {
    indices: number[];
    target: ProductDecomposeResultTarget;
    event:  ProductDecomposeEvent;
}

export interface ProductGroupComponentAction extends GenericActionStructure {
    group: string;
    component: string;
    initial: FormValues;
    icon: IconName;
}

export interface UnderlyingsGraphDataAndOptions {
    data: DynamicChartData;
    options: DynamicChartConfigOptions;
}

export interface ProductType {
    title: string;
    description: string;
    icon: IconName;
    path: string;
    button: string;
    id: string;
}

export interface InitialValuesResponse {
    id: string;
    organizationId: string;
    requestParameters: ProductRequestParameters;
    createdBy: string;
}

export interface ProductRequestParameters {
    createdAt?: string;
    offerType?: string;
    awaitUntil?: string;
    solveTarget?: string;
    productType?: string;
    components?: ProductComponent[];
    awaitResponseUntil?: string;
    issuers?: string[];
    changed?: boolean;
    notes?: string[];
    expectedSalesVolume?: number;
    secureCondition?: number;
    varianceInformationDTO?: VarianceInformation;
    varianceInformation?: VarianceInformation;
    matrixPricing?: string;
    matrixPricingSteps?: number;
    matrixPricingValue?: number;
    matrixPricingData?: VarianceEntryDTO;
}

export interface VarianceInformation {
    variances: VarianceEntryDTO[];
}

export interface VarianceEntryDTO {
    totalSteps: number;
    value: number;
    type: VarianceType | string;
}

export enum VarianceType {
    NO = 'NO',
    NONE = 'NONE',
    BARRIER = 'BARRIER',
    COUPON = 'COUPON',
    PRICE = 'PRICE',
}

export interface ProductRequest {
    createdAt?: string;
    offerType?: string;
    solveTarget?: string;
    matrixPricing?: string;
    matrixPricingSteps?: number;
    matrixPricingValue?: number;
    answerDate?: string;
    answerTime?: string;
    emittent?: string[];
    expectedSalesVolume?: number;
    conditionsSecurity?: number;
    comments?: string;
}

export interface OfferResponse<V = VarianceCombination> {
    requestId?: string;
    responseId?: string;
    issuer: string;
    assignee?: string;
    contact?: {
        name: string;
        email: string;
        phone: string;
    };
    offers: number[] | string[];

    comment?: string;
    completed?: boolean;
    editable?: boolean;
    createdAt?: string;
    data?: any;
    errors?: any;
    id?: { responseId: string, issuerId: string };
    issuerInformation?: { organizationId: string, issuerType: 'AUTO' | 'MANUAL', externalQuoteId?: string };
    initiated?: boolean;
    isin?: string;
    note?: string;
    state?: OfferResponseState;
    userId?: null;
    variance?: V;
    offerMode?: string;
    productIssued?: {
        activated?: boolean;
        submitted?: boolean;
        note?: string;
        isin?: string;
    };
}

export type OfferMultiResponse = OfferResponse<ComponentDifferenceEntry[]>;

export interface OfferResponseBase {
    quoteId: string;
    id?: { responseId: string, issuerId: string };
}

export interface OfferResponseCreate extends OfferResponseBase {
    comment: string;
    solveTargetValue: any;
}

export interface BatchOfferResponseCreate extends OfferResponseBase {
    offers: any[];
}

export interface OfferResponseDecline extends OfferResponseCreate {
    [key: string]: any;
}

export interface OfferResponseDeclineData extends OfferResponseBase {
    note: string;
}

export interface OfferResponseAcceptData extends OfferResponseBase {
    note: string;
    isin: string;
}

export interface OfferResponseNoteUpdateData extends  OfferResponseBase {
    note: string;
}

export interface OfferResponseChangeAssignee {
    id: { responseId: string, issuerId: string };
    assigneeId: string;
}

export enum OfferResponseState {
    INITIATED = 'INITIATED',
    VALIDATION_ERROR = 'VALIDATION_ERROR',
    ISSUER_DECLINED = 'ISSUER_DECLINED',
    QUOTE_OFFERED = 'QUOTE_OFFERED',
    NO_REPLY = 'NO_REPLY',
    FAILED = 'FAILED',
    COMPLETED_WITH_ERROR = 'COMPLETED_WITH_ERROR',

    QUOTE_ACCEPTED = 'QUOTE_ACCEPTED',
    QUOTE_DECLINED = 'QUOTE_DECLINED',
}

export interface VarianceCombination {
    step: number;
    componentDiff: ComponentDifferenceEntry[];
}

export interface ComponentDifferenceEntry {
    type: ComponentType;
    componentType?: ComponentType;
    field?: string;
    path?: string;
    value: any;
}

export type ProductOffers = OfferResponse[];

export interface ProductLifecycle {
    createdAt: string;
    initiatedBy: string;
    state: LifecycleStateType;
}

export interface ProductLifecycleState {
    currentState: ProductLifecycle;
    history: ProductLifecycle[];
}

export enum LifecycleStateType {
    CREATED = 'CREATED', // request is created but not started
    OPEN_FOR_RESPONSES = 'OPEN_FOR_RESPONSES', // request is created and started, in this state it is open for receiving responses
    QUOTE_RESOLVED = 'QUOTE_RESOLVED', // all responses are received, end state
    DECLINED = 'DECLINED', // user creates request, but PM declines it before it is started, end state
    EXPIRED = 'EXPIRED', // awaitUntil date has passed and request is moved to expired state, even if not all responses are received, end state
    CLOSED = 'CLOSED', // request can be stopped at any point by PM from OPEN_FOR_RESPONSES state, end state
    FINISHED = 'FINISHED',
}

export interface ProductResponse<V = VarianceCombination> {
    id: string;
    createdBy: string;
    organizationId: string;
    lifecycle: ProductLifecycleState;
    inFinalState: boolean;
    requestDetails: ProductRequestParameters;
    resolved: boolean;
    responses: { [key: string]: OfferResponse<V>[] };
    enriched?: {
        productType: { name: string };
        offerType: { name: string };
        solveTarget: { name: string };
        createdBy?: { firstname: string; lastname: string; email: string; };
        [key: string]: any;
    };
}

export interface ProductIssuerOrganization {
    organizationId: string;
    imageUrl?: string;
    imageId?: string;
    text: string;
    issuers?: ProductIssuerNew[];
}

export interface ProductIssuerOrganizationValidated extends ProductIssuerOrganization {
    autoIssuerValid?: boolean;
    autoIssuerReason?: ProductValidationError;
    autoIssuer?: ProductIssuerNew;
    autoIssuerIndex?: number;
}

export interface ProductIssuerNew {
    id: string;
    text?: string;
    estimatedWaitingResponseTimeSec?: number;
    issuerType: 'AUTO' | 'MANUAL';
    organizationId?: string;
    providerType?: string;
    imageUrl?: string;
    contact?: ProductIssuerContact;
    contactsByUser?: { [key: string]: ProductIssuerContact };
}

export interface ProductIssuerWithOrganization extends ProductIssuerNew {
    organizationId: string;
    imageUrl?: string;
    text: string;
}

export interface ProductIssuerContact {
    name: string;
    email: string;
    phone?: string;
    phoneNumber?: string;
    userId?: string;
    defaultContact?: boolean;
}

export interface ValidatedProductIssuer extends ProductIssuerNew {
    enabled: boolean;
    reason?: string;
    valid?: boolean;
}

export interface ProductValidation {
    validProviders: string[];
    providerSpecificErrors: {
        [key: string]: ProductValidationError[];
    };
}

export interface ProductValidationError {
    code: string;
    message: string;
    context: {
        component?: keyof ComponentToDataTypeMap;
        field?: string;
        provider?: string;
        product?: string;
    };
}

export interface ProductIssuer {
    id: string;
    label: string;
    icon: string;
    contact: {
        name: string;
        email: string;
        phone: string;
    };
    enabled: boolean;
    reason?: string;
}

export interface MatrixPricingAvailabilities {
    values: FormValues;
    availableVariances: string[];
}

export interface IssuerData {
    basePoints: number;
    organizationName: string;
    contacts: ProductIssuerContact[];
}

export interface IssuerQuoteStructureAndData {
    structure: ProductStructure;
    values: FormValues;
    quote: ProductResponse;
    issuerData: IssuerData;
    multiRequestStructure: MultiRequestFieldStructure[];
}

export interface MultiRequestFieldStructure {
    label: string;
    valueDecorator: string;
    decoratorOptions: any;
    componentType: string;
    path: string;
    inputType: string;
    options: any;
    values: string;
    styleHints: string[];
}

export interface ResolvedIssuerQuote {
    text: string;
    offer: any;
    offerTime: string;
    automatic: boolean;
}

export interface AnonymizedIssuerResponse {
    text: string;
    offer: any;
    offerTime: string;
    automatic: boolean;
}

export interface SurveyResponse {
    offer: number;
    issuer: string;
    user: UserEntity;
    expectedSalesVolume: number;
    comments: string;
    input: any;
    timestamp: string;
}

export interface SurveyData {
    id: string;
    timestamp: string;
    productId: string;
    productData: Product;
    productRequest: ProductRequestParameters;
    productType: string;
    offers: OfferResponse[];
    participants: Entity[];
    surveyResponses: SurveyResponse[];
}

export interface ProductDesignerHistoryStructures {
    filter: { common: FormStructureConfig };
    table: EntityTableConfig;
}

export const LifecycleIconsMap: { [key: string]: IconName } = {
    [LifecycleStateType.CREATED]: 'grade',
    [LifecycleStateType.OPEN_FOR_RESPONSES]: 'inbox',
    [LifecycleStateType.QUOTE_RESOLVED]: 'playlist-add-check',
    [LifecycleStateType.CLOSED]: 'close-circle-outline',
    [LifecycleStateType.DECLINED]: 'cancel',
    [LifecycleStateType.EXPIRED]: 'date-range',
    [LifecycleStateType.FINISHED]: 'check',
};

export const userGroups = [
    {
        id: 'advisor',
        name: 'Consultant',
    },
    {
        id: 'assetManager',
        name: 'Trustee',
    },
    {
        id: 'productManager',
        name: 'Product manager',
    },
    {
        id: '79216c44bca64b04a41625c208447ec1',
        name: 'My new test group 1',
    },
    {
        id: 'b731e1e0d3004adcaf02a2e9198c1416',
        name: 'My new test group 2',
    },
    {
        id: '93c86755206d4611b9ac45d69a13d979',
        name: 'Test group',
    },
];

export const productRequestValues = {
    offerTypes: [{id: 'TRADEABLE', label: 'tradeable' /*'handelbar'*/}, {
        id: 'INDICATIVE',
        label: 'indicative (all)', /*'indikativ'*/
    }/*, {id: 'INDICATIVE_AUTO', label: 'indicative (automatic)'}*/],
    matrixPricings: [{id: 'NO', label: 'No matrix pricing'}, {id: 'BARRIER', label: 'Barrier'}, {id: 'COUPON', label: 'Coupon'}, {id: 'PRICE', label: 'Price'}],
    matrixPricingVariance: {
        stepLabel: 'Number of steps',
        stepUnit: '',
        valueLabel: 'Variance value',
        valueUnit: '%',
    },
    emittents: [
        {
            id: '5ef211405897def1709124de',
            label: 'FAKE-BNP',
            icon: 'bnp.png',
            contact: {name: 'Jan Welling', email: 'jan.welling@bnpparibas.com', phone: '+49 69 7193 8049'},
        },
        {
            id: 'BNP',
            label: 'BNP Paribas',
            icon: 'bnp.png',
            contact: {name: 'Jan Welling', email: 'jan.welling@bnpparibas.com', phone: '+49 69 7193 8049'},
        },
        {
            id: 'CITIGROUP',
            label: 'Citigroup',
            icon: 'citigroup.png',
            contact: {name: 'Christine Romar', email: 'christine.romar@citi.com', phone: '+49 69 1366 3979'},
        },
        {
            id: 'CREDIT_SUISSE',
            label: 'Credit Suisse',
            icon: 'credit-suisse.png',
            contact: {name: 'Bodo Gauer', email: 'bodo.gauer@credit-suisse.com', phone: '+49 69 7538 2166'},
        },
        {
            id: 'DEUTSCHE_BANK',
            label: 'Deutsche Bank',
            icon: 'deutsche-bank.png',
            contact: {name: 'Rinol Hasaj', email: 'rinol.hasaj@db.com', phone: '+49 69 910 30878'},
        },
        {
            id: 'DZBANK',
            label: 'DZ Bank',
            icon: 'dz-bank.png',
            contact: {name: 'Tobias Gabel', email: 'tobias.gabel@dzbank.de', phone: '+49 69 7447 90377'},
        },
        {
            id: 'GOLDMAN_SACHS',
            label: 'Goldman Sachs',
            icon: 'goldman_sachs.png',
            contact: {name: 'Phillipp Loehrhoff', email: 'Philipp.Loehrhoff@gs.com', phone: '+44 20 7051 1483'},
        },
        {
            id: 'HSBC',
            label: 'HSBC',
            icon: 'hsbc.png',
            contact: {name: 'Christian Köker', email: 'christian.koeker@hsbctrinkaus.de', phone: '+49 211 910 306 9'},
        },
        {
            id: 'JPMORGAN',
            label: 'JP Morgan',
            icon: 'jp-morgan.png',
            contact: {name: 'Marcel Biere', email: 'marcel.x.biere@jpmorgan.com', phone: '+44 20 71 34 11 34'},
        },
        {
            id: 'LEONTEQ_SECURITIES',
            label: 'Leonteq Securities',
            icon: 'leonteq-securities.svg',
            contact: {name: 'Björn Geidel', email: 'bjoern.geidel@leonteq.com', phone: '+49 69 970 979 903'},
        },
        {
            id: 'NATIXIS',
            label: 'Natixis',
            icon: 'natixis.png',
            contact: {name: 'Farsin Hosseini', email: 'farsin.hosseini@natixis.com', phone: '+49 69 26484 4421'},
        },
        {
            id: 'NORD_LBGIROZENTRALE',
            label: 'NordLB Girozentrale',
            icon: 'nord-lb.png',
            contact: {name: 'John Doe', email: 'john.doe@mail.com', phone: '+49 3253 83543663'},
        },
        {
            id: 'SOC_GEN',
            label: 'SocGen',
            icon: 'societe-generale.png',
            contact: {name: 'Frank Schuster', email: 'frank.schuster@sgcib.com', phone: '+49 69 7174 263'},
        },
        {
            id: 'UBS',
            label: 'UBS',
            icon: 'ubs.svg',
            contact: {name: 'Jürgen Rother', email: 'juergen.rother@ubs.com', phone: '+49 69 1369 7627'},
        },
        {
            id: 'UNI_CREDIT_HVB',
            label: 'UniCredit / HVB',
            icon: 'hvb.png',
            contact: {name: 'Nikolaus Barth', email: 'nikolaus.barth@unicreditgroup.de', phone: '+49 89 378 140 62'},
        },
        {
            id: 'VONTOBE',
            label: 'Vontobel',
            icon: 'vontobel.png',
            contact: {name: 'Andreas Blumenstein', email: 'andreas.blumenstein@vontobel.de', phone: '+49 69 695 996 3230'},
        },
    ],
};
