import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { DerichartApi } from '../../api/derichart.api';
import { RiskManagerErrors } from '../../errors/risk-manager.errors';
import {
    checkEquivalenceFailure,
    CheckEquivalenceRequestAction, checkEquivalenceSuccess,
    dynamicChartLoadGraphDataFailure,
    DynamicChartLoadGraphDataRequestAction,
    dynamicChartLoadGraphDataSuccess,
    topUnderlyingsLoadDataFailure,
    TopUnderlyingsLoadDataRequestAction,
    topUnderlyingsLoadDataSuccess,
    WidgetDataActionTypes
} from '../actions/widgets-data.action';
import { BaseErrors } from '../../errors/base.errors';
import { DerilyticsApi } from '../../api/derilytics.api';
import { EquivalenceApi } from '../../api/equivalence.api';
import * as WidgetsDataActions from '../actions/widgets-data.action';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AdditionalWidgetsDataEffects {
    
    public dynamicChartLoadGraphData$ = createEffect(() => this.actions$.pipe(
        ofType(WidgetDataActionTypes.DYNAMIC_CHART_LOAD_GRAPH_DATA_REQUEST),
        switchMap((action: DynamicChartLoadGraphDataRequestAction) => this.dynamicChartLoadGraphData(action)),
    ));

    
    public topUnderlyingsLoadData$ = createEffect(() => this.actions$.pipe(
        ofType(WidgetDataActionTypes.TOP_UNDERLYINGS_LOAD_DATA_REQUEST),
        switchMap((action: TopUnderlyingsLoadDataRequestAction) => this.topUnderlyingsLoadData(action))
    ));

    
    public checkEquivalence$ = createEffect(() => this.actions$.pipe(
        ofType(WidgetDataActionTypes.CHECK_EQUIVALENCE_REQUEST),
        switchMap((action: CheckEquivalenceRequestAction) => this.checkEquivalence(action))
    ))

    constructor(
        private actions$: Actions,
        private derichartApi: DerichartApi,
        private derilyticsApi: DerilyticsApi,
        private equivalenceApi: EquivalenceApi,
        private translocoService: TranslocoService,
        private toastrService: ToastrService
    ) { }

    private dynamicChartLoadGraphData(action: DynamicChartLoadGraphDataRequestAction): Observable<Action> {
        return this.derichartApi.buildChart(action.payload.request).pipe(
            map((data) => dynamicChartLoadGraphDataSuccess(data, action.payload.request, action.payload.path)),
            catchError((err) =>
                of(
                    dynamicChartLoadGraphDataFailure({
                        errorCode: RiskManagerErrors.UNKNOWN,
                        httpError: true,
                        statusCode: err.status,
                    }, action.payload.request, action.payload.path),
                ),
            ),
        );
    }

    private topUnderlyingsLoadData(action: TopUnderlyingsLoadDataRequestAction): Observable<Action> {
        return this.derilyticsApi.buildTopUnderlyings(action.payload.filter).pipe(
            map((data) => topUnderlyingsLoadDataSuccess(data, action.payload.filter, action.payload.path)),
            catchError((err) =>
                of(
                    topUnderlyingsLoadDataFailure({
                        errorCode: BaseErrors.GENERIC,
                        httpError: true,
                        statusCode: err.status,
                    }, action.payload.filter, action.payload.path),
                ),
            ),
        );
    }

    private checkEquivalence(action: CheckEquivalenceRequestAction): Observable<Action> {
        return this.equivalenceApi.checkEquivalence(
            action.payload.entityId,
            action.payload.deriBwIds,
            action.payload.label,
            action.payload.watchlistId
        ).pipe(
            map((data) => checkEquivalenceSuccess(data, action.payload.entityId, action.payload.path)),
            catchError((err) =>
                of(
                    checkEquivalenceFailure({
                        errorCode: BaseErrors.GENERIC,
                        httpError: true,
                        statusCode: err.status,
                    }, action.payload.entityId, action.payload.path),
                ),
            ),
        )
    }

    public updateEquivalenceComments$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(WidgetsDataActions.updateEquivalenceComments),
            switchMap((action) => 
                this.equivalenceApi.updateEquivalenceComments(
                    action.productDq,
                    action.comments
                ).pipe(
                    tap(() => this.toastrService.show(
                        this.translocoService.translate('widgets.equivalenceCheck.commentsSaved')
                    ))
                )
            )
        );
    }, { dispatch: false });
}
