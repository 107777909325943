import { Component, EventEmitter, forwardRef, HostBinding, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { MaxValidationDirective } from '../../directives/max-validation.directive';
import { MinValidationDirective } from '../../directives/min-validation.directive';

@Component({
    selector: 'app-percent-input',
    templateUrl: './percent-input.component.html',
    styleUrls: ['./percent-input.component.scss'],
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PercentInputComponent),
            multi: true,
        }, {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => PercentInputComponent),
            multi: true,
        }],
    standalone: true,
    imports: [FormsModule, MinValidationDirective, MaxValidationDirective, NgIf]
})
export class PercentInputComponent implements OnInit {
    @Input() public placeholder: string;
    @Input() public min: number;
    @Input() public max: number;
    @Output() public blurControl: EventEmitter<void> = new EventEmitter();

    public onChange: () => void;
    public onTouched: () => void;
    public value: number | string;
    public disabled: boolean;

    @HostBinding('class.percent-input--component')
    public inputContainerClass = true;

    @HostBinding('class.percent-input--prefix')
    @Input()
    public showPrefix = false;

    @Input() public prefixText = null;

    constructor() {
    }

    public ngOnInit() {
    }

    public writeValue(value: number): void {
        if (this.value !== value) {
            this.value = value;
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this.value);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (!isNaN(+this.value) && (!this.min || this.value >= this.min) && (!this.max || this.value <= this.max))
            ? null : {
                numberInput: 'Invalid value specified.',
            };
    }

    public setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    public onInputChange(value: number) {
        if (this.value !== value && this.onChange) {
            this.value = value;
            this.onChange();
        }
    }
}
