import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { get } from 'lodash';
import { ReadModeDialogComponent } from '../../../components/dialogs/read-mode-dialog/read-mode-dialog.component';
import { ReadModeDialogData } from '../../../components/dialogs/read-mode-dialog/read-mode-dialog.interface';
import { MarkdownWidgetOptions, TitleWithPath } from '../../../models/widgets.model';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MarkdownComponent } from '../../../components/markdown/markdown.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-markdown-widget',
    templateUrl: './markdown-widget.component.html',
    styleUrls: ['./markdown-widget.component.scss'],
    standalone: true,
    imports: [NgClass, MarkdownComponent, NgIf, TranslocoModule]
})
export class MarkdownWidgetComponent implements OnInit, OnChanges, EntityWidgetContentComponent {

    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public options: MarkdownWidgetOptions;
    @Input() public data: any;
    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();

    public text: string;

    public isDirty = false;

    constructor(
        private elRef: ElementRef,
        private dialog: MatDialog
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.data && this.options) {
            this.text = get(this.data, this.options.text);
        }
    }

    public ngOnInit() {

    }

    public onActionClicked(action: { type: string; payload: any }) {
        this.action.next(action);
    }

    public onReadMode(): void {
        this.dialog.open(ReadModeDialogComponent, {
            data: {
                title: (this.options.title as TitleWithPath).default,
                text: this.text
            } as ReadModeDialogData,
            panelClass: ['wms-dialog', 'inverse'],
            scrollStrategy: new NoopScrollStrategy
        });
    }
}
