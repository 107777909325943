import { FormValidationRuleOptions } from '../models/form.model';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ObjectMaker } from '../helpers/form.helper';
import { Validators } from '@angular/forms';

export function wrappedNative(validatorName: string, pure = false): ValidatorFn {
    if (!Validators[validatorName]) throw new Error(`Validator '${validatorName}' does not exists in native implementation`);
    const validator = Validators[validatorName];

    return function nativeValidatorFactory(configModel: FormValidationRuleOptions): ValidatorFn {
        let nativeValidator = validator;

        if (!pure && configModel) {
            nativeValidator = validator(configModel.value);
        }

        return (control: AbstractControl): { [key: string]: any } => {
            const validationResult = nativeValidator(control);

            if (configModel && validationResult) {
                if (!configModel.value) {
                    return ObjectMaker.null();
                }

                if (!configModel.message && !configModel.messageKey) {
                    configModel.message = validationResult[validatorName] || null;
                }

                return ObjectMaker.toJson(validatorName, configModel, [control.value, configModel.value]);
            }

            return ObjectMaker.null();
        };
    };
}
