import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { UploadFile, UploadHandler, UploadQueue } from '../models/upload.model';
import { addFileToUploadQueue, createUploadQueue } from '../store/actions/upload.action';
import { AppState } from '../store/state/index.state';

@Injectable()
export class UploadService {
    constructor(private readonly store: Store<AppState>) {
    }

    /** Selectors **/

    /**
     * Get the upload queue for the given id
     * @param {string} id
     * @returns {Observable<UploadQueue>}
     */
    public getUploadQueue(id: string): Observable<UploadQueue> {
        return this.store.pipe(
            select((state: AppState) => state.upload.queues[id]),
        );
    }

    /**
     * Get the upload file for the given id
     * @param {string} id
     * @returns {Observable<UploadFile>}
     */
    public getUploadFile(id: string): Observable<UploadFile> {
        return this.store.pipe(
            select((state: AppState) => state.upload.files[id]),
        );
    }

    /**
     * Get the file ids for the upload queue for the given id
     * @param {string} queueId
     * @returns {Observable<string[]>}
     */
    public getFileIdsForUploadQueue(queueId: string): Observable<string[]> {
        return this.store.pipe(
            select((state: AppState) => state.upload.queueToFilesMapping[queueId]),
        );
    }

    /**
     * Get the list of @link{UploadFile}s for the @link{UploadQueue} for the given id
     * @param {string} queueId
     * @returns {Observable<UploadFile[]>}
     */
    public getFilesForUploadQueue(queueId: string): Observable<UploadFile[]> {
        return this.getFileIdsForUploadQueue(queueId).pipe(
            switchMap((ids) => {
                if (!ids || ids.length === 0) {
                    return of([]);
                }
                return combineLatest(ids.map((id) => this.getUploadFile(id)));
            }),
        );
    }

    /** Actions **/

    /**
     * Create a new upload queue
     * @param {string} id
     * @param {UploadHandler} uploadHandler
     */
    public createUploadQueue(id: string, uploadHandler: UploadHandler) {
        this.store.dispatch(createUploadQueue(id, uploadHandler));
    }

    /**
     * Add a file to the upload queue identified by the given queueId
     * @param {string} queueId
     * @param {File} file
     * @param context
     */
    public addFileToUploadQueue(queueId: string, file: File, context?: any) {
        const uploadFile: UploadFile = {
            id: `${file.name}_${Date.now()}`,
            file,
            label: file.name,
            progress: 0,
            context,
        };

        this.store.dispatch(addFileToUploadQueue(queueId, uploadFile));
    }
}
