import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ValidationErrors, FormsModule } from '@angular/forms';
import { FormControlConfig, FormControlSelectOption } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';
import { TranslocoModule } from '@ngneat/transloco';

/**
 * Single select component that is repsonsible for a select filter
 */
@Component({
    selector: 'app-filter-quick-search',
    templateUrl: './filter-text-search.component.html',
    styleUrls: ['./filter-text-search.component.scss'],
    standalone: true,
    imports: [FormsModule, TranslocoModule]
})
export class FilterTextSearchComponent implements OnInit, SingleValueFilterControl<string> {

    @ViewChild('quicksearch', { read: ElementRef, static: true }) public elemRef: ElementRef;

    @Input() public config: FormControlConfig;

    @Input() public values: FormControlSelectOption[];

    @Input() public disabled = false;

    public _value: string;

    public onChange: () => void;

    public onTouched: () => void;

    constructor() {
    }

    public ngOnInit() {
    }

    public writeValue(value: string): void {
        if (this._value !== value) {
            this._value = value;
        }
    }

    protected _isValidValue(value: string) {
        if (this.values) {
            return this.values.reduce((hasValue, validValue) => {
                return value === validValue.id || hasValue;
            }, false);
        } else {
            return false;
        }

    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this._value);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this._isValidValue(this._value)) ? null : {
            quickSearchError: 'Invalid value specified',
        };
    }

    /**
     * Called when the selection changed
     * @param value
     */
    public onInputChange(value: string) {
        if (this._value !== value && this.onChange) {
            this._value = value;
            this.onChange();
        }
    }

    /**
     * Called when textfield is focused
     */
    public selectAll() {
        this.elemRef.nativeElement.select();
    }

}
