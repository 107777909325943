import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'debug',
    pure: false,
    standalone: true
})
export class DebugPipe implements PipeTransform {

    transform(value: any, ...exclude: string[]): any {
        return JSON.stringify(value, this.getCircularReplacer(exclude), 4);
    }

    private getCircularReplacer = (exclude: string[]) => {
        const seen = new WeakSet();
        return (key, value) => {
            if(exclude && exclude.includes(key)){
                return;
            }
            if (typeof value === 'object' && value !== null) {
                if (seen.has(value)) {
                    return;
                }
                seen.add(value);
            }
            return value;
        };
    };
}
