import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DerifinApi } from '../../../../core/api/derifin.api';
import { ApiResponse } from '../../../../core/api/models/api.model';
import { AuthStoreService } from '../../../../core/services/auth-store.service';
import { ConfigurationService } from '../../../../core/services/configuration.service';
import { LogglyLoggerService } from '../../../../core/services/loggly-logger.service';
import { WatchlistsGroup } from '../models/watchlist.model';

/**
 * API abstraction layer for the Watchlist API
 */
@Injectable()
export class WatchlistGroupApi extends DerifinApi {

    constructor(http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, appConfigService: ConfigurationService) {
        super('m/watchlist-group', appConfigService.configuration.environment.id, http, authStoreService, loggerService, appConfigService);
    }

    public getWatchlistsGroup(moduleId: string): Observable<WatchlistsGroup> {
        return this.get(`${moduleId}/overview`).pipe(
            map((response: ApiResponse<WatchlistsGroup>) => response.data),
        );
    }
}
