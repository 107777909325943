import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

import { isObject, pickBy } from 'lodash';
import { FormControlConfig, FormControlSelectOption } from '../../../core/models/form.model';
import { SelectItem } from '../../models/widgets.model';
import { MultiValueFilterControl } from '../filter-control';
import { MultiSelectDropdownComponent } from '../../dropdowns/multi-select-dropdown/multi-select-dropdown.component';
import { NgIf, NgFor } from '@angular/common';

/**
 * Filter multi select component is responsible for multi select filter where the user can select one or more items
 * that are displayed below the select
 */
@Component({
    selector: 'app-filter-multi-select',
    templateUrl: './filter-multi-select.component.html',
    styleUrls: ['./filter-multi-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, MultiSelectDropdownComponent, NgFor]
})
export class FilterMultiSelectComponent implements OnInit, OnChanges, MultiValueFilterControl<string> {
    @Input() public config: FormControlConfig;
    @Input() public values: FormControlSelectOption[];
    @Input() public disabled = false;

    @Input() public visibleInput = true;

    public _values: { [value: string]: boolean } = {};

    public _selectedItems: SelectItem[] = [];
    public _availableItems: SelectItem[] = [];
    public onChange: () => void;
    public onTouched: () => void;

    public updateAvailableItems(values: FormControlSelectOption[]) {
        this._availableItems = values ? values.map((value) => {
            return {
                id: value.id,
                text: value.label,
            };
        }) : [];

        this.cdf.detectChanges();
    }

    public updateSelectedItems(values: FormControlSelectOption[]) {
        this._selectedItems = values ? values
            .map((value) => {
                return {
                    id: value.id,
                    text: value.label,
                };
            })
            .filter((value) => {
                return this._values[value.id];
            }) : [];

        this.cdf.detectChanges();
    }

    constructor(private cdf: ChangeDetectorRef) {
    }

    public ngOnInit() {
    }

    /**
     * Writes the values for the multi select
     * @param {string[]} values
     */
    public writeValue(values: string[]): void {
        if (!values) return;

        if (values) {
            if (!Array.isArray(values)) {
                values = [values];
            }

            const transformedValues = values ? values.reduce((vals, value) => {
                vals[value] = true;
                return vals;
            }, {}) : {};

            // if (!isEqual(this._values, transformedValues)) {
            // NOTE(alex): check if this causes issues
            this._values = transformedValues;
            this.updateSelectedItems(this.values);
            // }
        }
    }

    /**
     * Checks if the filter value is valid
     * @param {string} value
     * @returns {boolean}
     * @private
     */
    protected _isValidValue(value: string) {
        if (this.values) {
            return this.values.reduce((hasValue, validValue) => {
                return value === validValue.id || hasValue;
            }, false);
        } else {
            return false;
        }

    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                const values = Object.keys(this._values).filter((value) => this._values[value]);
                fn(values);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Validates the filter control
     *
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        if (this._values && isObject(this._values)) {
            const invalidValues = Object.keys(this._values).map((value) => {
                return {
                    value,
                    valid: this._isValidValue(value),
                };
            }).filter((v) => !v.valid);

            if (invalidValues.length > 0) {
                return {
                    multiSelectError: {
                        message: 'Invalid values specified.',
                        invalidValues,
                    },
                };
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    /**
     * Called when the filter selection has changed
     * @param {SelectItem[]} items
     */
    public onSelectionChange(items: SelectItem[]) {
        if (items) {
            this._values = items.reduce((values, item) => {
                values[item.id] = true;
                return values;
            }, {});

            this.onChange();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.values && this.values) {

            this._values = pickBy(this._values, (value, key) =>
                this.values.map((value) => value.id).includes(key)
            );

            this.updateAvailableItems(this.values);
            this.updateSelectedItems(this.values);

            this.onChange();
        }
    }

}
