import { createFeatureSelector, createSelector } from "@ngrx/store";
import { TokenUser } from "../../api/models/me.model";
import { AuthState } from "../state/auth.state";

export const selectAuth = createFeatureSelector<AuthState>('auth');

export const selectAuthUser = createSelector(
    selectAuth,
    (state: AuthState) => state.authUser
);

export const selectAuthUserId = createSelector(
    selectAuthUser,
    (authUser: TokenUser) => authUser?.id
);
