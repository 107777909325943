import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { EntityWidgetAdditionalDataChannel, EntityWidgetEquivalenceCheck } from '../../../models/widgets.model';
import { FormControlSelectEquivalence, FormValues } from '../../../../core/models/form.model';
import { FormArray, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WidgetDataActionTypes } from '../../../../core/store/actions/widgets-data.action';
import { MultiRowCommentArrayItem, MultiRowCommentForm } from '../../../components/entity-table-multi-row-comment/entity-table-multi-row-comment.interface';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { StringObject } from '../../../../core/models/generic.model';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterLink } from '@angular/router';
import { SpinnerComponent } from '../../../components/spinner/spinner.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { ButtonComponent } from 'chroma-ui';

@Component({
    selector: 'app-entity-widget-equivalence-check',
    templateUrl: './entity-widget-equivalence-check.component.html',
    styleUrls: ['./entity-widget-equivalence-check.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor, SpinnerComponent, RouterLink, AsyncPipe, TranslocoModule, TranslocoLocaleModule, ButtonComponent]
})
export class EntityWidgetEquivalenceCheckComponent implements OnInit, OnChanges, OnDestroy, EntityWidgetContentComponent {

    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public options: EntityWidgetEquivalenceCheck;
    @Input() public data: any;
    @Input() public formValues: FormValues;
    @Input()
    public set additionalData(additionalData: EntityWidgetAdditionalDataChannel<EquivalenceCheckData>) {
        this.additionalData$.next(additionalData);
    }

    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();

    public additionalData$ = new BehaviorSubject<EntityWidgetAdditionalDataChannel<EquivalenceCheckData>>(null);

    public widgetForm: UntypedFormGroup;
    public dropDownValues: FormControlSelectEquivalence[] = [];
    
    public commentsForm = new FormGroup<MultiRowCommentForm>({
        comments: new FormArray<FormGroup<MultiRowCommentArrayItem>>([])
    });

    private get comments(): FormArray<FormGroup<MultiRowCommentArrayItem>> {
        return this.commentsForm.controls.comments;
    }

    private commentsInput: StringObject = {};

    private subscriptions: Array<Subscription> = [];

    constructor(private fb: UntypedFormBuilder) {
        this.widgetForm = fb.group({
            bwlist: [''],
        });
    }

    public ngOnInit() {
        this.updateDropDownValues();

        this.commentsForm.valueChanges.subscribe((values) =>
            this.commentsInput = Object.assign({}, 
                ...values.comments.map((comment) => comment)
            )
        );

        this.subscriptions.push(
            this.additionalData$.pipe(
                filter((additionalData) => !!additionalData?.data),
                filter((additionalData) => !additionalData.loading),
                filter((additionalData) => additionalData.data.products.length > 0),
                map((additionalData) => additionalData.data.products)
            ).subscribe((products) =>
                products.forEach((product) =>
                    this.comments.push(
                        new FormGroup<MultiRowCommentArrayItem>({
                            [product.dq]: new FormControl<string>(product.comment)
                        })
                    )
                )
            )
        );
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.options) {
            this.updateDropDownValues();
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }

    private updateDropDownValues() {
        if (this.options && this.options.dropdownValues) {
            this.dropDownValues = this.options.dropdownValues.map((v) => {
                return {
                    ...v,
                    id: v.deriBwIds.join('_'),
                };
            });
        }
    }

    public onSubmit(): void {
        const value = this.widgetForm.value && this.widgetForm.value.bwlist;
        const selectValue = this.dropDownValues.find((v) => v.id === value);

        this.action.emit({
            type: WidgetDataActionTypes.CHECK_EQUIVALENCE,
            payload: selectValue
        });

        this.comments.clear();
    }

    public onSaveComments(): void {
        this.action.emit({
            type: 'equivalence-check-save-comments',
            payload: {
                comments: this.commentsInput
            }
        });
    }

    public onClickPDFExport(): void {
        this.additionalData$.pipe(
            take(1)
        ).subscribe((additionalData) =>
            this.action.emit({
                type: 'equivalence-check-export',
                payload: {
                    equivalenceCheckId: additionalData.data.resultId,
                    comments: this.commentsInput
                }
            })
        );
    }

    public isCommentsSavingEnabled(additionalData: EntityWidgetAdditionalDataChannel<EquivalenceCheckData>): boolean {
        return additionalData?.data?.products.length && !additionalData.loading;
    }

    public isPDFExportEnabled(additionalData: EntityWidgetAdditionalDataChannel<EquivalenceCheckData>): boolean {
        return additionalData?.data?.products.length >= 0 && !additionalData.loading;
    }
}

export interface EquivalenceCheckData {
    products: EquivalenceCheckDataProduct[];
    resultId: string;
}

export interface EquivalenceCheckDataProduct {
    dq: string;
    isin: string;
    name: string;
    wkn: string;
    costs: number;
    comment: string;
}
