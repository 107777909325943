import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, PRIMARY_OUTLET, Router, RouterOutlet } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { filter, startWith, switchMap, take } from 'rxjs/operators';
import { truthy } from '../../shared/helpers/general.helper';

import { getFirstMenuItemLink, MenuItemModuleType } from '../helpers/menu.helper';
import { Menu } from '../models/menu.model';
import { MenuService } from '../services/menu.service';
import { UiService } from '../services/ui.service';
import { AuthState } from '../store/state/auth.state';
import { AppState } from '../store/state/index.state';
import { ScrollTopButtonComponent } from '../components/scroll-top-button/scroll-top-button.component';
import { MainMenuComponent } from '../components/main-menu/main-menu.component';
import { InlineMenuComponent } from '../../shared/components/inline-menu/inline-menu.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { IssueMessageComponent } from '../../shared/components/issue-message/issue-message.component';
import { TopBarComponent } from '../components/top-bar/top-bar.component';

/**
 * Component that holds the admin layout
 */
@Component({
    selector: 'app-admin-layout',
    templateUrl: 'admin-layout.component.html',
    animations: [
        trigger('menuState', [
            state('void', style({
                opacity: 0,
            })),
            state('visible', style({
                opacity: 1,
            })),
            transition('* => *', [animate(150)]),
        ]),
    ],
    standalone: true,
    imports: [TopBarComponent, IssueMessageComponent, RouterOutlet, NgIf, InlineMenuComponent, MainMenuComponent, ScrollTopButtonComponent, AsyncPipe]
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
    @HostBinding('class.layout') public layoutClass = true;
    @HostBinding('class.default-layout') public defaultLayoutClass = true;
    @HostBinding('class.small-layout') public smallLayoutClass = true;
    @HostBinding('class.compact-layout') public compactLayoutClass = true;
    /**
     * Observable of the menu visibility state
     */
    public menuVisible$: Observable<boolean>;

    /**
     * Menu configuration
     */
    public menu$: Observable<Menu>;

    public currentMenuId$: BehaviorSubject<{ type: string; id: string }>;
    public inlineMenuConfig$: Observable<Menu>;
    private mainSubscription: Subscription;

    constructor(
        private uiService: UiService,
        private menuService: MenuService,
        private router: Router,
        private route: ActivatedRoute,
        private store: Store<AppState>,
    ) {
        this.menu$ = this.menuService.getMenu();
        this.menuVisible$ = this.uiService.isMenuVisible();

        this.smallLayoutClass = true;
        this.compactLayoutClass = true;

        this.currentMenuId$ = new BehaviorSubject(null);
        this.inlineMenuConfig$ = this.currentMenuId$.pipe(
            switchMap((item) => {
                if (!item) {
                    return of({ items: [] });
                }

                return this.menuService.getSubmenuForMenuTarget({id: item.id, type: item.type});
            }),
        );
    }

    public extractMenuIdsFromRoute(route: ActivatedRouteSnapshot) {
        const result = [];

        if (route.data && route.data.menuModuleId) {
            const moduleId = {id: route.data.menuModuleId, type: route.data.menuModuleIdType || MenuItemModuleType.STATIC};

            if (route.data.menuModuleIdType === MenuItemModuleType.DYNAMIC) {
                moduleId.id = route.paramMap.get(moduleId.id);
                moduleId.type = MenuItemModuleType.DYNAMIC;
            }

            result.push(moduleId);
        }

        if (route.children) {
            for (const childRoute of route.children) {
                result.push(...this.extractMenuIdsFromRoute(childRoute));
            }
        }

        return result;
    }

    public ngOnInit() {
        combineLatest([
            this.router.events.pipe(
                filter((event) => event instanceof NavigationEnd),
                startWith(null as NavigationEnd),
            ),
            this.menuService.getMenuConfig().pipe(filter(truthy)),
        ]).pipe(
            take(1),
        ).subscribe(([_, menuConfig]) => {
            if (this.route.firstChild.outlet !== PRIMARY_OUTLET) {
                const firstMenuItemLink = getFirstMenuItemLink(menuConfig);
                this.router.navigate(firstMenuItemLink);
            }
        });

        this.mainSubscription = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            startWith(null as NavigationEnd),
        ).subscribe((_) => {
            if (this.route.firstChild.outlet === PRIMARY_OUTLET) {
                const extractedMenuIds = this.extractMenuIdsFromRoute(this.route.snapshot.firstChild);
                let currentMenuId = null;

                if (extractedMenuIds && extractedMenuIds.length) {
                    currentMenuId = extractedMenuIds[extractedMenuIds.length - 1];
                }

                this.currentMenuId$.next(currentMenuId);
            }
        });

        this.mainSubscription.add(this.store.pipe(
            select('auth'),
        ).subscribe((authState: AuthState) => {
            /* Note(alex): Disable 4K Mode setting
            this.smallLayoutClass = get(authState, 'authUser.appData.smallMode', false);
            this.compactLayoutClass = this.smallLayoutClass; */
        }));
    }

    public ngOnDestroy(): void {
        if (this.mainSubscription) {
            this.mainSubscription.unsubscribe();
        }
    }
}
