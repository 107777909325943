import { AsyncPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({
    name: 'maybeAsync', pure: false,
    standalone: true
})
export class MaybeAsyncPipe extends AsyncPipe implements PipeTransform {
    public transform<T>(obj: null): null;
    public transform<T>(obj: undefined): undefined;
    public transform<T>(obj: string): any;
    public transform<T>(obj: Observable<T> | Promise<T>): T | null;
    public transform(obj: Observable<any> | Promise<any> | string | null | undefined): any {
        if (typeof obj === 'string' || obj instanceof String) {
            return obj;
        }
        return super.transform(obj as Observable<any>);
    }
}
