/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 5/5/17.
 */

import { Action } from '@ngrx/store';
import { AppError } from '../../errors/base.errors';
import { Config } from '../../models/config.model';
import { type } from '../util';
import { Configuration } from '../../../shared/models/configuration';

export const ConfigActionTypes = {
    LOAD_CONFIG: type('CONFIG:LOAD_CONFIG'),
    CONFIG_LOADED: type('CONFIG:CONFIG_LOADED'),
    LOAD_CONFIG_FAILED: type('CONFIG:LOAD_CONFIG_FAILED'),
};

/** LoadConfigAction **/

export interface LoadConfigAction extends Action {
    payload?: void;
}

export function loadConfig(): LoadConfigAction {
    return {
        type: ConfigActionTypes.LOAD_CONFIG,
    };
}

/** ConfigLoadedAction **/

export interface ConfigLoadedAction extends Action {
    payload: {
        config: Config;
        envConfig: Configuration;
    };
}

export function configLoaded(config: Config, envConfig: Configuration): ConfigLoadedAction {
    return {
        type: ConfigActionTypes.CONFIG_LOADED,
        payload: {
            config,
            envConfig
        },
    };
}

/** LoadConfigFailedAction **/

export interface LoadConfigFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadConfigFailed(error: AppError): LoadConfigFailedAction {
    return {
        type: ConfigActionTypes.LOAD_CONFIG_FAILED,
        payload: {
            error,
        },
    };
}
