import { type } from '../../../../../../core/store/util';
import { Action } from '@ngrx/store';
import { Portfolio, PortfolioValues } from '../../models/portfolio-manager.model';
import { AppError } from '../../../../../../core/errors/base.errors';

export const PortfolioManagerActions = {
    LOAD_FORM_VALUES_REQUEST: type('PFM:LOAD_FORM_VALUES_REQUEST'),
    LOAD_FORM_VALUES_SUCCESS: type('PFM:LOAD_FORM_VALUES_SUCCESS'),
    LOAD_FORM_VALUES_FAILURE: type('PFM:LOAD_FORM_VALUES_FAILURE'),

    LOAD_PORTFOLIO_DATA_REQUEST: type('PFM:LOAD_PORTFOLIO_DATA_REQUEST'),
    LOAD_PORTFOLIO_DATA_SUCCESS: type('PFM:LOAD_PORTFOLIO_DATA_SUCCESS'),
    LOAD_PORTFOLIO_DATA_FAILURE: type('PFM:LOAD_PORTFOLIO_DATA_FAILURE'),

    UPDATE_PORTFOLIO_DATA_REQUEST: type('PFM:UPDATE_PORTFOLIO_DATA_REQUEST'),
    UPDATE_PORTFOLIO_DATA_SUCCESS: type('PFM:UPDATE_PORTFOLIO_DATA_SUCCESS'),
    UPDATE_PORTFOLIO_DATA_FAILURE: type('PFM:UPDATE_PORTFOLIO_DATA_FAILURE'),
};

export interface LoadFormValuesRequestAction extends Action {
    payload: {
        moduleId: string;
    };
}

export function loadFormValuesRequest(moduleId: string): LoadFormValuesRequestAction {
    return {
        type: PortfolioManagerActions.LOAD_FORM_VALUES_REQUEST,
        payload: {
            moduleId,
        },
    };
}

export interface LoadFormValuesSuccessAction extends Action {
    payload: {
        values: PortfolioValues;
    };
}

export function loadFormValuesSuccess(values: PortfolioValues): LoadFormValuesSuccessAction {
    return {
        type: PortfolioManagerActions.LOAD_FORM_VALUES_SUCCESS,
        payload: {
            values,
        },
    };
}

export interface LoadFormValuesFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadFormValuesFailure(error: AppError): LoadFormValuesFailureAction {
    return {
        type: PortfolioManagerActions.LOAD_FORM_VALUES_FAILURE,
        payload: {
            error,
        },
    };
}

export interface LoadPortfolioDataRequestAction extends Action {
    payload: {
        moduleId: string;
        targetPortfolio: string;
    };
}

export function loadPortfolioDataRequest(moduleId: string, targetPortfolio: string): LoadPortfolioDataRequestAction {
    return {
        type: PortfolioManagerActions.LOAD_PORTFOLIO_DATA_REQUEST,
        payload: {
            moduleId,
            targetPortfolio,
        },
    };
}

export interface LoadPortfolioDataSuccessAction extends Action {
    payload: {
        data: Portfolio;
    };
}

export function loadPortfolioDataSuccess(data: Portfolio): LoadPortfolioDataSuccessAction {
    return {
        type: PortfolioManagerActions.LOAD_PORTFOLIO_DATA_SUCCESS,
        payload: {
            data,
        },
    };
}

export interface LoadPortfolioDataFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadPortfolioDataFailure(error: AppError): LoadPortfolioDataFailureAction {
    return {
        type: PortfolioManagerActions.LOAD_PORTFOLIO_DATA_FAILURE,
        payload: {
            error,
        },
    };
}

export interface UpdatePortfolioDataRequestAction extends Action {
    payload: {
        moduleId: string;
        targetPortfolio: string;
        portfolio: Portfolio,
    };
}

export function updatePortfolioDataRequest(moduleId: string, targetPortfolio: string, portfolio: Portfolio): UpdatePortfolioDataRequestAction {
    return {
        type: PortfolioManagerActions.UPDATE_PORTFOLIO_DATA_REQUEST,
        payload: {
            moduleId,
            targetPortfolio,
            portfolio,
        },
    };
}

export interface UpdatePortfolioDataSuccessAction extends Action {
    payload: {
        portfolio: Portfolio;
    };
}

export function updatePortfolioDataSuccess(portfolio: Portfolio): UpdatePortfolioDataSuccessAction {
    return {
        type: PortfolioManagerActions.UPDATE_PORTFOLIO_DATA_SUCCESS,
        payload: {
            portfolio,
        },
    };
}

export interface UpdatePortfolioDataFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function updatePortfolioDataFailure(error: AppError): UpdatePortfolioDataFailureAction {
    return {
        type: PortfolioManagerActions.UPDATE_PORTFOLIO_DATA_FAILURE,
        payload: {
            error,
        },
    };
}
