import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, switchMap } from "rxjs/operators";
import { UserProfileApi } from "../../api/user-profile.api";
import { UserApi } from "../../api/user.api";
import { UserActions } from "../actions/user.actions";

@Injectable()
export class UserEffects {

    constructor(
        private actions$: Actions,
        private userApi: UserApi,
        private userProfileApi: UserProfileApi
    ) { }

    public loadShortcutsGroups$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UserActions.loadShortcuts),
            switchMap(() => 
                this.userApi.getShortcuts().pipe(
                    map((shortcuts) => 
                        UserActions.setShortcuts({ shortcuts })
                    )
                )
            )
        );
    });

    public loadUserProfile$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UserActions.loadProfile),
            switchMap(() =>
                this.userProfileApi.getProfile().pipe(
                    map((profile) =>
                        UserActions.setProfile({ profile })
                    )
                )
            )
        );
    });
}

