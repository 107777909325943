import { Component, Input, OnInit } from '@angular/core';
import { ApiError } from './ApiError';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.css'],
    standalone: true,
    imports: [NgFor]
})
export class ErrorComponent implements OnInit {

    @Input() public error: ApiError;

    public errorList: string[] = [];

    constructor() {
    }

    public structureError() {
        const keys = Object.keys(this.error);

        for (const i in keys) {
            if (this.error[keys[i]] instanceof Array) {
                this.error[keys[i]].forEach((err) => {
                    this.errorList.push(err);
                });
            }
        }
    }

    public ngOnInit() {
        this.structureError();
    }

}
