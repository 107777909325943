import { Action } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { FormValues } from '../../../../core/models/form.model';
import { type } from '../../../../core/store/util';
import { ProductDetailsFormStructure } from '../../models/product-details-structure.model';

export const ProductFormActions = {
    LOAD_PRODUCT_FORM_STRUCTURE_REQUEST: type('DETAILS_FORM:LOAD_PRODUCT_FORM_STRUCTURE_REQUEST'),
    LOAD_PRODUCT_FORM_STRUCTURE_SUCCESS: type('DETAILS_FORM:LOAD_PRODUCT_FORM_STRUCTURE_SUCCESS'),
    LOAD_PRODUCT_FORM_STRUCTURE_FAILURE: type('DETAILS_FORM:LOAD_PRODUCT_FORM_STRUCTURE_FAILURE'),

    LOAD_PRODUCT_FORM_VALUES_REQUEST: type('DETAILS_FORM:LOAD_PRODUCT_FORM_VALUES_REQUEST'),
    LOAD_PRODUCT_FORM_VALUES_SUCCESS: type('DETAILS_FORM:LOAD_PRODUCT_FORM_VALUES_SUCCESS'),
    LOAD_PRODUCT_FORM_VALUES_FAILURE: type('DETAILS_FORM:LOAD_PRODUCT_FORM_VALUES_FAILURE'),

    LOAD_PRODUCT_FORM_INITIAL_REQUEST: type('DETAILS_FORM:LOAD_PRODUCT_FORM_INITIAL_REQUEST'),
    LOAD_PRODUCT_FORM_INITIAL_SUCCESS: type('DETAILS_FORM:LOAD_PRODUCT_FORM_INITIAL_SUCCESS'),
    LOAD_PRODUCT_FORM_INITIAL_FAILURE: type('DETAILS_FORM:LOAD_PRODUCT_FORM_INITIAL_FAILURE'),

    UPDATE_PRODUCT_FORM_DATA: type('DETAILS_FORM:UPDATE_PRODUCT_FORM_DATA'),

    SAVE_PRODUCT_FORM_DATA_REQUEST: type('DETAILS_FORM:SAVE_PRODUCT_FORM_DATA_REQUEST'),
    SAVE_PRODUCT_FORM_DATA_SUCCESS: type('DETAILS_FORM:SAVE_PRODUCT_FORM_DATA_SUCCESS'),
    SAVE_PRODUCT_FORM_DATA_FAILURE: type('DETAILS_FORM:SAVE_PRODUCT_FORM_DATA_FAILURE'),

    DELETE_PRODUCT_FORM_DATA_REQUEST: type('DETAILS_FORM:DELETE_PRODUCT_FORM_DATA_REQUEST'),
    DELETE_PRODUCT_FORM_DATA_SUCCESS: type('DETAILS_FORM:DELETE_PRODUCT_FORM_DATA_SUCCESS'),
    DELETE_PRODUCT_FORM_DATA_FAILURE: type('DETAILS_FORM:DELETE_PRODUCT_FORM_DATA_FAILURE'),
};

export interface LoadProductFormStructureRequestAction extends Action {
    payload: {
        sessionId: string;
        tabId: string;
    };
}

export function loadProductFormStructureReqeust(sessionId: string, tabId: string): LoadProductFormStructureRequestAction {
    return {
        type: ProductFormActions.LOAD_PRODUCT_FORM_STRUCTURE_REQUEST,
        payload: {
            sessionId,
            tabId
        }
    };
}

export interface LoadProductFormStructureSuccessAction extends Action {
    payload: {
        structure: ProductDetailsFormStructure;
        sessionId: string;
        tabId: string;
    };
}

export function loadProductFormStructureSuccess(sessionId: string, tabId: string, structure: ProductDetailsFormStructure): LoadProductFormStructureSuccessAction {
    return {
        type: ProductFormActions.LOAD_PRODUCT_FORM_STRUCTURE_SUCCESS,
        payload: {
            sessionId,
            structure,
            tabId
        }
    };
}

export interface LoadProductFormStructureFailureAction extends Action {
    payload: {
        error: AppError;
        sessionId: string;
    };
}

export function loadProductFormStructureFailure(sessionId: string, error: AppError): LoadProductFormStructureFailureAction {
    return {
        type: ProductFormActions.LOAD_PRODUCT_FORM_STRUCTURE_FAILURE,
        payload: {
            sessionId,
            error,
        },
    };
}

export interface LoadProductFormValuesRequestAction extends Action {
    payload: {
        sessionId: string;
        tabId: string;
    };
}

export function loadProductFormValuesRequest(sessionId: string, tabId: string): LoadProductFormValuesRequestAction {
    return {
        type: ProductFormActions.LOAD_PRODUCT_FORM_VALUES_REQUEST,
        payload: {
            sessionId,
            tabId,
        },
    };
}

export interface LoadProductFormValuesSuccessAction extends Action {
    payload: {
        sessionId: string;
        values: FormValues;
    };
}

export function loadProductFormValuesSuccess(sessionId: string, values: FormValues): LoadProductFormValuesSuccessAction {
    return {
        type: ProductFormActions.LOAD_PRODUCT_FORM_VALUES_SUCCESS,
        payload: {
            sessionId,
            values,
        },
    };
}

export interface LoadProductFormValuesFailureAction extends Action {
    payload: {
        sessionId: string;
        error: AppError;
    };
}

export function loadProductFormValuesFailure(sessionId: string, error: AppError): LoadProductFormValuesFailureAction {
    return {
        type: ProductFormActions.LOAD_PRODUCT_FORM_VALUES_FAILURE,
        payload: {
            sessionId,
            error,
        },
    };
}

export interface LoadProductFormInitialRequestAction extends Action {
    payload: {
        sessionId: string;
        tabId: string;
    };
}

export function loadProductFormInitialRequest(sessionId: string, tabId: string): LoadProductFormInitialRequestAction {
    return {
        type: ProductFormActions.LOAD_PRODUCT_FORM_INITIAL_REQUEST,
        payload: {
            sessionId,
            tabId,
        },
    };
}

export interface LoadProductFormInitialSuccessAction extends Action {
    payload: {
        sessionId: string;
        values: FormValues;
    };
}

export function loadProductFormInitialSuccess(sessionId: string, values: FormValues): LoadProductFormInitialSuccessAction {
    return {
        type: ProductFormActions.LOAD_PRODUCT_FORM_INITIAL_SUCCESS,
        payload: {
            sessionId,
            values,
        },
    };
}

export interface LoadProductFormInitialFailureAction extends Action {
    payload: {
        sessionId: string;
        error: AppError;
    };
}

export function loadProductFormInitialFailure(sessionId: string, error: AppError): LoadProductFormInitialFailureAction {
    return {
        type: ProductFormActions.LOAD_PRODUCT_FORM_INITIAL_FAILURE,
        payload: {
            sessionId,
            error,
        },
    };
}

export interface SaveProductFormDataRequestAction extends Action {
    payload: {
        sessionId: string;
        contentType: string;
        dq: string;
        data: FormValues;
        tabId: string;
    };
}

export function saveProductFormDataRequest(sessionId: string, dq: string, contentType: string, data: FormValues, tabId: string): SaveProductFormDataRequestAction {
    return {
        type: ProductFormActions.SAVE_PRODUCT_FORM_DATA_REQUEST,
        payload: {
            dq,
            contentType,
            data,
            sessionId,
            tabId,
        },
    };
}

export interface SaveProductFormDataSuccessAction extends Action {
    payload: {
        dq: string;
        sessionId: string;
        tabId: string;
        contentType: string;
        message?: string;
        state?: string;
    };
}

export function saveProductFormDataSuccess(dq: string, sessionId: string, tabId: string, contentType: string, message?: string, state?: string): SaveProductFormDataSuccessAction {
    return {
        type: ProductFormActions.SAVE_PRODUCT_FORM_DATA_SUCCESS,
        payload: {
            dq,
            sessionId,
            message,
            tabId,
            contentType,
            state,
        },
    };
}

export interface SaveProductFormDataFailureAction extends Action {
    payload: {
        dq: string;
        error: AppError,
        message?: string;
        state?: string;
    };
}

export function saveProductFormDataFailure(dq: string, error: AppError, message?: string, state?: string): SaveProductFormDataFailureAction {
    return {
        type: ProductFormActions.SAVE_PRODUCT_FORM_DATA_FAILURE,
        payload: {
            dq,
            error,
            message,
            state,
        },
    };
}

export interface DeleteProductFormDataRequestAction extends Action {
    payload: {
        sessionId: string;
        contentType: string;
        dq: string;
        tabId: string;
    };
}

export function deleteProductFormDataRequest(sessionId: string, dq: string, contentType: string, tabId: string): DeleteProductFormDataRequestAction {
    return {
        type: ProductFormActions.DELETE_PRODUCT_FORM_DATA_REQUEST,
        payload: {
            dq,
            contentType,
            sessionId,
            tabId,
        },
    };
}

export interface DeleteProductFormDataSuccessAction extends Action {
    payload: {
        dq: string;
        sessionId: string;
        tabId: string;
        contentType: string;
        message?: string;
        state?: string;
    };
}

export function deleteProductFormDataSuccess(dq: string, sessionId: string, contentType: string, tabId: string, message?: string, state?: string): DeleteProductFormDataSuccessAction {
    return {
        type: ProductFormActions.DELETE_PRODUCT_FORM_DATA_SUCCESS,
        payload: {
            dq,
            message,
            contentType,
            tabId,
            sessionId,
            state,
        },
    };
}

export interface DeleteProductFormDataFailureAction extends Action {
    payload: {
        dq: string;
        error: AppError,
        message?: string;
        state?: string;
    };
}

export function deleteProductFormDataFailure(dq: string, error: AppError, message?: string, state?: string): DeleteProductFormDataFailureAction {
    return {
        type: ProductFormActions.DELETE_PRODUCT_FORM_DATA_FAILURE,
        payload: {
            dq,
            error,
            message,
            state,
        },
    };
}

export interface UpdateProductFormData extends Action {
    payload: {
        sessionId: string;
        data: FormValues;
    };
}

export function updateProductFormData(sessionId: string, data: FormValues): UpdateProductFormData {
    return {
        type: ProductFormActions.UPDATE_PRODUCT_FORM_DATA,
        payload: {
            sessionId,
            data,
        },
    };
}
