import {Action} from '@ngrx/store';
import {AppError} from '../../../../../core/errors/base.errors';
import {Data} from '../../../../../core/models/data.model';
import {type} from '../../../../../core/store/util';
import {GenericActionStructure} from '../../../../../shared/components/entity-actions/entity-actions.component';
import {ShortListStructures, WatchlistData, WatchlistProductGroup} from '../../models/watchlist.model';
import {
    LoadWatchlistDataAction, LoadWatchlistDataFailedAction, LoadWatchlistDataSucceededAction,
    LoadWatchlistStructuresAction,
    LoadWatchlistStructuresFailedAction,
    LoadWatchlistStructuresSucceededAction,
} from './watchlist.actions';

export const ShortlistActionTypes = {
    LOAD_SHORTLIST_STRUCTURES: type('SHORTLIST:LOAD_SHORTLIST_STRUCTURES'),
    LOAD_SHORTLIST_STRUCTURES_SUCCEEDED: type('SHORTLIST:LOAD_SHORTLIST_STRUCTURES_SUCCEEDED'),
    LOAD_SHORTLIST_STRUCTURES_FAILED: type('SHORTLIST:LOAD_SHORTLIST_STRUCTURES_FAILED'),

    LOAD_SHORTLIST_DATA: type('SHORTLIST:LOAD_SHORTLIST_DATA'),
    LOAD_SHORTLIST_DATA_SUCCEEDED: type('SHORTLIST:LOAD_SHORTLIST_DATA_SUCCEEDED'),
    LOAD_SHORTLIST_DATA_FAILED: type('SHORTLIST:LOAD_SHORTLIST_DATA_FAILED'),

    LOAD_SHORTLIST: type('SHORTLIST:LOAD_SHORTLIST'),
    LOAD_SHORTLIST_SUCCEEDED: type('SHORTLIST:LOAD_SHORTLIST_SUCCEEDED'),
    LOAD_SHORTLIST_FAILED: type('SHORTLIST:LOAD_SHORTLIST_FAILED'),
};

///

export interface LoadShortlistAction extends Action {
    payload: {
        shortlistId: string;
        tmc?: string;
    };
}

export function loadShortlist(shortlistId: string, tmc?: string): LoadShortlistAction {
    return {
        type: ShortlistActionTypes.LOAD_SHORTLIST,
        payload: {
            shortlistId,
            tmc,
        },
    };
}

export interface LoadShortlistSucceededAction extends Action {
    payload: {
        data: Data;
        structure: ShortListStructures;
        shortlistId: string
    };
}

export function loadShortlistSucceeded(data: Data, structure: ShortListStructures, shortlistId: string): LoadShortlistSucceededAction {
    return {
        type: ShortlistActionTypes.LOAD_SHORTLIST_SUCCEEDED,
        payload: {
            data,
            structure,
            shortlistId,
        },
    };
}

export interface LoadShortlistFailedAction extends Action {
    payload: {
        error: AppError,
        shortlistId: string,
    };
}

export function loadShortlistFailed(error: AppError, shortlistId: string): LoadShortlistFailedAction {
    return {
        type: ShortlistActionTypes.LOAD_SHORTLIST_FAILED,
        payload: {
            error,
            shortlistId,
        },
    };
}

/**
 * LoadWatchlistStructuresAction
 * @returns {Action}
 */
export function loadShortlistStructures(watchlistId: string): LoadWatchlistStructuresAction {
    return {
        type: ShortlistActionTypes.LOAD_SHORTLIST_STRUCTURES,
        payload: {
            watchlistId,
        },
    };
}

/**
 * LoadWatchlistStructuresSucceededAction
 * @returns {Action}
 */
export function loadShortlistStructuresSucceeded(watchlistId: string, actions: GenericActionStructure[],
                                                 structures: WatchlistProductGroup[]): LoadWatchlistStructuresSucceededAction {
    return {
        type: ShortlistActionTypes.LOAD_SHORTLIST_STRUCTURES_SUCCEEDED,
        payload: {
            watchlistId,
            actions,
            structures,
        },
    };
}

export function loadShortlistStructuresFailed(error: AppError): LoadWatchlistStructuresFailedAction {
    return {
        type: ShortlistActionTypes.LOAD_SHORTLIST_STRUCTURES_FAILED,
        payload: {
            error,
        },
    };
}

/**
 * LoadShortlistDataAction
 * @returns {Action}
 */
export function loadShortlistData(watchlistId: string): LoadWatchlistDataAction {
    return {
        type: ShortlistActionTypes.LOAD_SHORTLIST_DATA,
        payload: {
            watchlistId,
        },
    };
}

/**
 * LoadShortlistDataSucceededAction
 * @returns {Action}
 */
export function loadShortlistDataSucceeded(data: WatchlistData, watchlistId: string): LoadWatchlistDataSucceededAction {
    return {
        type: ShortlistActionTypes.LOAD_SHORTLIST_DATA_SUCCEEDED,
        payload: {
            data,
            watchlistId,
        },
    };
}

export function loadShortlistDataFailed(error: AppError): LoadWatchlistDataFailedAction {
    return {
        type: ShortlistActionTypes.LOAD_SHORTLIST_DATA_FAILED,
        payload: {
            error,
        },
    };
}
