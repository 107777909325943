import { Component, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { Subscription } from 'rxjs';
import { FormControlConfig, FormControlSelectOption, FormValues } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';
import { IconComponent } from '../icon/icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-ilter-radio-group-horizontal',
    templateUrl: './filter-radio-group-horizontal.component.html',
    styleUrls: ['./filter-radio-group-horizontal.component.scss'],
    standalone: true,
    imports: [NgIf, MatTooltipModule, FormsModule, ReactiveFormsModule, MatButtonToggleModule, NgFor, IconComponent]
})
export class FilterRadioGroupHorizontalComponent implements OnInit, OnChanges, OnDestroy, SingleValueFilterControl<string> {
    @HostBinding('class.labelled-radio-button-group-input') public doubleDropdownInputClass = true;

    @Input() public config: FormControlConfig<FilterLabelledRadioButtonGroupInputOptions>;

    @Input() public values: any;

    @Input() public disabled = false;

    @Input() public visibleInput = true;

    public onChange: (value: any) => void;

    public onTouched: () => void;

    public form: UntypedFormGroup;

    public valueChangesSubscription: Subscription;
    public valuePath = KEY_INPUT_VALUE;

    get inputValues(): FormControlSelectOption[] {
        return this.values || [];
    }

    constructor(private fb: UntypedFormBuilder) {
    }

    public ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.config && this.config) {
            this._updateForm();
        }
        if ((changes.config || changes.values) && this.values) {
            this._updateDefaultValues();
        }
    }

    public ngOnDestroy() {
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }
    }

    public writeValue(value: string): void {
        if (value) {
            this.form.patchValue({[KEY_INPUT_VALUE]: value}, {emitEvent: false});
        } else {
            this.form.patchValue({[KEY_INPUT_VALUE]: null}, {emitEvent: false});
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = (value) => {
            if (fn) {
                fn(value[KEY_INPUT_VALUE]);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * Checks if the filter is valid
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this.form.valid) ? null : {
            doubleDropdownInputError: 'Invalid filter state specified',
        };
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.disable({ emitEvent: false });
        } else {
            this.form.enable({ emitEvent: false });
        }
    }

    private _updateForm() {
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }

        const fields = {};
        fields[KEY_INPUT_VALUE] = [];

        const oldValue = (this.form) ? this.form.value : undefined;
        this.form = this.fb.group(fields);
        if (oldValue) {
            this.form.patchValue(oldValue);
        }
        this.valueChangesSubscription = this.form.valueChanges.subscribe((value) => {
            this.onChange(value);
        });
    }

    private _updateDefaultValues() {
        if (this.config && this.config.options) {
            const control = this.form.get(KEY_INPUT_VALUE);
            if ((control.value === undefined || control.value === null) && this.config.options.preselectFirst) {
                const value = {
                    [KEY_INPUT_VALUE]: this.inputValues[0].id,
                };

                this.form.patchValue(value);
            }
        }
    }

    public resetButtonGroup(event: MouseEvent) {
        event.preventDefault();

        this.form.patchValue({
            [KEY_INPUT_VALUE]: null,
        });
    }
}

const KEY_INPUT_VALUE = 'value';

export interface FilterLabelledRadioButtonGroupInputOptions {
    label: string;
    helpText?: string;
    preselectFirst: boolean;
    displayLessThanSelection: boolean;
}
