import { Component, Input } from '@angular/core';
import { ButtonAppearance, ButtonBase, buttonStyle } from './button-base';

@Component({
    selector: 'button[chroma-button], a[chroma-button]',
    templateUrl: './button.html',
    standalone: true
})
export class ButtonComponent extends ButtonBase {

    constructor() {
        super('standard');
    }

    @Input() set appearance(value: ButtonAppearance) {
        this._appearance = value;
        this.hostClasses = buttonStyle({ [this._appearance]: 'standard', size: this._size });
    }

}
