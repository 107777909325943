/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 2/21/17.
 */

import { Action, createAction, props } from '@ngrx/store';
import { type } from '../util';

export const ActionTypes = {
    OPEN_MENU: type('UI:OPEN_MENU'),
    CLOSE_MENU: type('UI:CLOSE_MENU'),

    SET_OVERLAY_OPEN: type('UI:SET_OVERLAY_OPEN'),
    SET_OVERLAY_CLOSED: type('UI:SET_OVERLAY_CLOSED'),

    ADD_TO_OVERLAY_NAVIGATION_STACK: type('UI:ADD_TO_OVERLAY_NAVIGATION_STACK'),
    POP_OVERLAY_NAVIGATION_STACK: type('UI:POP_OVERLAY_NAVIGATION_STACK'),
    CLEAR_OVERLAY_NAVIGATION_STACK: type('UI:CLEAR_OVERLAY_NAVIGATION_STACK'),
};

export const setPageVisibility = createAction(
    '[UI] Set Page Visibility',
    props<{ visible: boolean }>()
);

/** Menu Actions **/

/**
 * Create an OPEN_MENU action
 * @returns {Action}
 */
export function openMenu(): Action {
    return {
        type: ActionTypes.OPEN_MENU,
    };
}

/**
 * Create an CLOSE_MENU action
 * @returns {Action}
 */
export function closeMenu(): Action {
    return {
        type: ActionTypes.CLOSE_MENU,
    };
}

/** SetOverlayOpenAction **/

export function setOverlayOpen(): Action {
    return {
        type: ActionTypes.SET_OVERLAY_OPEN,
    };
}

/** SetOverlayClosedAction **/

export function setOverlayClosed(): Action {
    return {
        type: ActionTypes.SET_OVERLAY_CLOSED,
    };
}

/** AddToOverlayNavigationStackAction **/

export interface AddToOverlayNavigationStackAction extends Action {
    payload: {
        url: string;
    };
}

export function addToOverlayNavigationStack(url: string): AddToOverlayNavigationStackAction {
    return {
        type: ActionTypes.ADD_TO_OVERLAY_NAVIGATION_STACK,
        payload: {
            url,
        },
    };
}

/** PopOverlayNavigationStackAction **/

export interface PopOverlayNavigationStackAction extends Action {
    payload?: void;
}

export function popOverlayNavigationStack(): PopOverlayNavigationStackAction {
    return {
        type: ActionTypes.POP_OVERLAY_NAVIGATION_STACK,
    };
}

/** ClearOverlayNavigationStackAction **/

export interface ClearOverlayNavigationStackAction extends Action {
    payload?: void;
}

export function clearOverlayNavigationStack(): ClearOverlayNavigationStackAction {
    return {
        type: ActionTypes.CLEAR_OVERLAY_NAVIGATION_STACK,
    };
}
