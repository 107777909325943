<app-range-filter [label]="config.options.label"
                  [minLabel]="config.options.minLabel"
                  [maxLabel]="config.options.maxLabel"
                  [min]="value.min"
                  [max]="value.max"
                  [enabled]="value.enabled"
                  [unit]="config.options.unit"
                  [disabled]="disabled"
                  (maxValue)="onMaxValueChanged($event)"
                  (minValue)="onMinValueChanged($event)"
                  (enabledValue)="onEnabledChanged($event)">
</app-range-filter>
