<a
      chroma-button
      appearance="text"
      class="tw-relative"
      [routerLink]="['/app', { outlets: {'overlay': ['product-selection']}}]">
      <span class="material-symbols-rounded tw-text-xl tw-leading-6" icon-left>collections_bookmark</span>
      <span>{{ 'general.selection' | transloco }}</span>
      <ng-container *ngIf="shelvedProductsCount$ | async as count">
            <span class="tw-absolute tw-top-0 tw-left-[28px] tw-w-5 tw-h-5 tw-text-xs tw-text-center tw-inline-flex tw-items-center tw-justify-center tw-text-white tw-bg-primary-base tw-rounded-full">{{ count > 99 ? 99 : count }}</span>
      </ng-container>
</a>
