<a
    *ngIf="actionType === 'link'"
    [routerLink]="link"
    class="list-group-item list-group-item-action"
    [ngClass]="{'focus' : focus}">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container> 
</a>
<button
    *ngIf="actionType === 'select'"
    type="button"
    class="list-group-item list-group-item-action"
    [ngClass]="{'focus' : focus}"
    (click)="selectId.next(id)">
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container> 
</button>

<ng-template #contentTpl><ng-content></ng-content></ng-template>
