import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { get, isArray, without } from 'lodash';
import { Subscription } from 'rxjs';
import { FormControlSelectOption, FormValues } from '../../../../core/models/form.model';
import { EntityEvent } from '../../../components/entity-table/entity-table.component';
import { EntityWidgetBasketsAdminOptions } from '../../../models/widgets.model';
import { EntityWidgetContentComponent, EntityWidgetWithValidation } from '../entity-widget/entity-widget.component';
import { EntityWidgetBasketsAdminButtonGroupComponent } from './entity-widget-baskets-admin-button-group.component';
import { NgIf, NgFor } from '@angular/common';
import { CheckboxComponent } from 'chroma-ui';

@Component({
    selector: 'app-entity-widget-baskets-admin',
    templateUrl: './entity-widget-baskets-admin.component.html',
    styleUrls: ['./entity-widget-baskets-admin.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgFor, EntityWidgetBasketsAdminButtonGroupComponent, CheckboxComponent]
})
export class EntityWidgetBasketsAdminComponent implements OnInit, OnChanges, OnDestroy, EntityWidgetContentComponent, EntityWidgetWithValidation {
    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public options: EntityWidgetBasketsAdminOptions;
    @Input() public data: BasketsAdminData;
    @Input() public formValues: FormValues;

    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();
    @Output() public componentEvent: EventEmitter<EntityEvent> = new EventEmitter();

    public form: UntypedFormGroup;
    private value: FormValues = {};
    public portfolios: FormControlSelectOption[] = [];
    public clients: FormControlSelectOption[] = [];
    public buttons: FormControlSelectOption[] = [];
    public defaultButton: FormControlSelectOption;

    public predefinedLegend: FormControlSelectOption[] = [
        {
            label: 'PA',
            description: 'Das Produkt ist ein Partnerangebot',
        },
    ];

    public legend: FormControlSelectOption[] = [];
    public inputDataTransformed = null;
    public formCreated = false;
    private subscription: Subscription;

    constructor(private fb: UntypedFormBuilder, private cdf: ChangeDetectorRef) {
        this.form = this.fb.group({
            items: this.fb.array([]),
        });
    }

    public ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.options || changes.formValues) {
            this._updateValues();
            this._updateForm();
        }

        if (changes.data) {
            if (this.subscription) {
                this.subscription.unsubscribe();
            }

            this._reconcileInputDataToForm();

            this.subscription = this.form.valueChanges.subscribe((value) => {
                this.data.productBasketMemberships = this.productBasketMemberships;
                this.data.partnerProductsMemberships = this.partnerProductsMemberships;

                this.emitFormValidationState();
            });
        }
    }

    public ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    private _reconcileInputDataToForm() {
        if (!this.data || !this.data.productBasketMemberships) {
            return;
        }

        const pitems = {};

        this.data.productBasketMemberships.forEach((m) => {
            pitems[m.customerSegment] = pitems[m.customerSegment] || {
                client: m.customerSegment,
                portfolios: [],
                pa: null,
            };

            pitems[m.customerSegment].portfolios.push({
                portfolio: m.targetPortfolio,
                value: m.membership,
            });
        });

        this.data.partnerProductsMemberships.forEach((p) => {
            if (pitems[p.customerSegment]) {
                pitems[p.customerSegment].pa = p.membership;
            }
        });

        this.inputDataTransformed = Object.values(pitems);

        if (this.formCreated) {
            setTimeout(() => {
                this.form.patchValue({items: this.inputDataTransformed});
                this.cdf.detectChanges();
            }, 20);
        }
    }

    private _updateValues() {
        if (!this.options || !this.formValues || !this.options.values) {
            return;
        }

        const values: BasketsAdminValues = get(this.formValues, this.options.values);

        if (values) {
            this.portfolios = [
                ...values.targetPortfolios,
            ];

            this.clients = [
                ...values.customerSegments,
            ];

            this.defaultButton = values.productTypeSelection.find((b) => b.id === 'NONE');
            this.buttons = [
                ...without(values.productTypeSelection, this.defaultButton),
            ];

            this.legend = [
                ...values.productTypeSelection,
                ...this.predefinedLegend,
            ];
        }
    }

    private _updateForm() {
        if (this.itemsList) {
            while (this.itemsList.controls.length) {
                this.itemsList.removeAt(0);
            }
        }

        const items = this.clients.map((c) => {
            return this.fb.group({
                client: [c.id],
                portfolios: this.fb.array(this.portfolios.map((p) => {
                    return this.fb.group({
                        portfolio: [p.id],
                        value: ['NONE'],
                    });
                })),
                pa: [false],
            });
        });

        items.forEach((item) => {
            this.itemsList.push(item);
        });

        this.formCreated = true;
        if (this.inputDataTransformed) {
            setTimeout(() => {
                this.form.patchValue({items: this.inputDataTransformed});
                this.inputDataTransformed = null;
            }, 10);
        }
    }

    public getLabelForClientId(id: string) {
        const client = this.clients.find((c) => c.id === id);

        if (client) {
            return client.label;
        }

        return '-';
    }

    public get itemsList() {
        return this.form.get('items') as UntypedFormArray;
    }

    public getItemControls(item: AbstractControl, sub: string) {
        return (item.get(sub) as UntypedFormArray).controls;
    }

    public get productBasketMemberships(): ProductBasketMembership[] {
        if (isArray(this.form.value?.items)) {
            return this.form.value?.items.flatMap((item) => {
                if (isArray(item.portfolios)) {
                    return item.portfolios.map((portfolio) => {
                        return {
                            customerSegment: item.client,
                            membership: portfolio.value,
                            targetPortfolio: portfolio.portfolio,
                        } as ProductBasketMembership;
                    });
                } else {
                    return [];
                }
            });
        } else {
            return [];
        }
    }

    public get partnerProductsMemberships(): PartnerProductsMembership[] {
        if (isArray(this.form.value?.items)) {
            return this.form.value.items.map((item) => {
                return {
                    customerSegment: item.client,
                    membership: item.pa,
                } as PartnerProductsMembership;
            });
        } else {
            return [];
        }
    }

    public get isValid(): boolean {
        return isArray(this.form.value?.items)
            && this.form.value.items.some((productValue) => {
                return productValue.pa
                    || productValue.portfolios.some((portfolio) => portfolio.value !== 'NONE');
            });
    }

    public onClickFormSave(event: MouseEvent) {
        event.preventDefault();

        if (this.isValid) {
            const submitData: BasketsAdminData = {
                partnerProductsMemberships: this.partnerProductsMemberships,
                productBasketMemberships: this.productBasketMemberships,
            };

            this.action.emit({
                type: 'save-admin-baskets-data',
                payload: {
                    data: submitData,
                },
            });
        }
    }

    public formValidation() {
        return this.isValid;
    }

    public forceFormValidation() {
    }

    public emitFormValidationState(value: boolean = this.formValidation()) {
        this.componentEvent.emit({
            event: {
                type: 'form-validation',
                payload: {
                    value,
                },
            },
            id: this.options.path,
        });
    }
}

export interface BasketsAdminData {
    partnerProductsMemberships: PartnerProductsMembership[];
    productBasketMemberships: ProductBasketMembership[];
}

export interface ProductBasketMembership {
    customerSegment: string;
    membership: string;
    targetPortfolio: string;
}

export interface PartnerProductsMembership {
    customerSegment: string;
    membership: boolean;
}

export interface BasketsAdminValues {
    customerSegments: FormControlSelectOption[];
    targetPortfolios: FormControlSelectOption[];
    productTypeSelection: FormControlSelectOption[];
}
