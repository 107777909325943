/**
 * Helper module with helper methods for easier access to local storage
 */

export const KEY_ACCESS_TOKEN = 'accessToken';
export const KEY_REFRESH_TOKEN = 'refreshToken';

/**
 * Writes some value to the local storage
 * @param key
 * @param value
 */
export function writeToLocalStorage(key: string, value: any) {
    if (value) {
        value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
}

/**
 * Retrieves a value from the local storage
 * @param key
 * @returns {any}
 */
export function readFromLocalStorage<T>(key: string): T {
    const value: string = localStorage.getItem(key);

    if (value && value !== undefined && value !== null) {
        return JSON.parse(value) as T;
    }

    return null;
}

export function clearTokensLocalStorage() {
    localStorage.removeItem(KEY_ACCESS_TOKEN);
    localStorage.removeItem(KEY_REFRESH_TOKEN);
}

/**
 * Clears the local storage
 */
export function clearLocalStorage() {
    localStorage.clear();
}
