import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[appAutofocus]',
    standalone: true
})
export class AutofocusDirective implements AfterViewInit {

    @Input() public appAutofocus = true;

    constructor(private element: ElementRef) {
    }

    public ngAfterViewInit() {
        if (this.appAutofocus) {
            this.element.nativeElement.focus({
                preventScroll: true
            });
        }
    }

}
