/**
 * Created by Alex Klein <alex@rocketloop.de> on 5/5/17.
 */
import { ApiResponse } from '../../../core/api/models/api.model';
import { FormValues } from '../../../core/models/form.model';
import { Entity, EntityTableConfig, QuerySet, UpdatedData } from '../../../shared/models/entities.model';
import { FilterStructureConfig } from '../models/filters.model';
import {
    CreateProductQueryResponseData,
    CreateProductQueryResponseMeta,
    GetProductFilterAvailableValuesResponseData,
    GetProductFilterInitialValuesResponseData,
    GetProductFilterStructureResponseData,
    ProductResponseData,
} from './models/products.model';

export function transformProductFilterStructure(
    structureResponse: ApiResponse<GetProductFilterStructureResponseData>,
): FilterStructureConfig {
    return {
        common: structureResponse.data.common,
        specific: structureResponse.data.specific,
    };
}

export function transformProductFilterAvailableValues(
    availableValuesResponse: ApiResponse<GetProductFilterAvailableValuesResponseData>,
): FormValues {
    return availableValuesResponse.data;
}

export function transformProductFilterInitialValues(
    initialValuesResponse: ApiResponse<GetProductFilterInitialValuesResponseData>,
): FormValues {
    return initialValuesResponse.data;
}

export function transformCreateProductQuerySetResponse(
    createProductQuerySetResponse: ApiResponse<CreateProductQueryResponseData, CreateProductQueryResponseMeta>,
): QuerySet {
    return {
        id: createProductQuerySetResponse.data.id,
        structure: transformProductTableConfig(createProductQuerySetResponse.data.structure),
        count: createProductQuerySetResponse.meta.count,
    };
}

export function transformProductTableConfig(config: EntityTableConfig): EntityTableConfig {
    return {
        ...config,
    };
}

export function transformProductsResponse(productsResponse: ApiResponse<ProductResponseData[]>): Entity[] {
    return productsResponse.data.map(transformProductResponseData);
}

export function transformProductResponseData(productResponseData: ProductResponseData): Entity {
    const product = {
        ...productResponseData,
        id: productResponseData._id,
    };
    delete product._id;
    return product;
}

export function transformUpdatedProductsData(data: any): UpdatedData {
    const id = data._id;
    delete data._id;
    return {
        id,
        ...data,
    };
}
