import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { ProductDecorfinApi } from './api/product-decorfin.api';
import { ProductRecommendApi } from './api/product-recommend.api';
import { ProductSharedApi } from './api/product-shared.api';
import { ProductSelectionIndicatorComponent } from './components/product-selection-indicator/product-selection-indicator.component';
import { ProductSharedService } from './services/product-shared.service';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        ProductSelectionIndicatorComponent
    ],
    exports: [
        ProductSelectionIndicatorComponent,
    ],
    providers: [ProductSharedApi, ProductRecommendApi, ProductDecorfinApi, ProductSharedService]
})
export class ProductSharedModule {
}
