import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormControlConfig, FormControlSelectOption } from '../../../core/models/form.model';
import { MultiValueFilterControl } from '../filter-control';

import { isEqual } from 'lodash';
import { NgIf, NgFor } from '@angular/common';

/**
 * FilterCheckboxGroup is responsible for checkboxes. In includes one or more checkboxes.
 */
@Component({
    selector: 'app-filter-checkbox-group',
    templateUrl: './filter-checkbox-group.component.html',
    styleUrls: ['./filter-checkbox-group.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor]
})
export class FilterCheckboxGroupComponent implements OnInit, MultiValueFilterControl<string> {

    @Input() public config: FormControlConfig;

    @Input() public values: FormControlSelectOption[];

    @Input() public disabled = false;

    @Input() public visibleInput = true;

    public _values: { [value: string]: boolean };

    public onChange: () => void;

    public onTouched: () => void;

    constructor() {
    }

    public ngOnInit() {
    }

    /**
     * Writes the value for the checkbox group
     * @param {string[]} values
     */
    public writeValue(values: string[]): void {
        if (!values) { values = []; }
        const transformedValues = values.reduce((vals, value) => {
            vals[value] = true;
            return vals;
        }, {});
        if (!isEqual(this._values, transformedValues)) {
            this._values = transformedValues;
        }
    }

    /**
     * Checks if the filter value is valid
     * @param {string} value
     * @returns {boolean}
     * @private
     */
    protected _isValidValue(value: string) {
        return (this.values) ? this.values.reduce((hasValue, validValue) => {
            return value === validValue.id || hasValue;
        }, false) : false;
    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                const values = Object.keys(this._values).filter((value) => this._values[value]);
                fn(values);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        const invalidValues = Object.keys(this._values).map((value) => {
            return {
                value,
                valid: this._isValidValue(value),
            };
        }).filter((v) => !v.valid);
        if (invalidValues.length > 0) {
            return {
                checkboxGroupError: {
                    message: 'Invalid values specified.',
                    invalidValues,
                },
            };
        } else {
            return null;
        }
    }

    /**
     * Called when the checkbox state has changed
     * @param e
     * @param value
     */
    public onCheckboxChange(e: Event, value: FormControlSelectOption) {
        e.preventDefault();
        this._values[value.id] = !this._values[value.id];
        this.onChange();
    }

    public isObjectEmpty(value): boolean {
        return Object.keys(value).length === 0;
    }

}
