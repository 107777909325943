import { DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class LocaleFormatterService {
    private locale: string;

    constructor(
        private readonly datePipe: DatePipe,
        private readonly decimalPipe: DecimalPipe,
        private readonly percentPipe: PercentPipe,
        private translocoService: TranslocoService
    ) {
        this.locale = this.translocoService.getActiveLang();
    }

    public use(locale: string) {
        this.locale = locale;
    }

    public currentLocale() {
        return this.locale;
    }

    public transformDate(value: any, format?, locale?: string): string {
        return this.datePipe.transform(value, format, null, locale ? locale : this.locale);
    }

    public transformDecimal(value: any, format): string {
        return this.decimalPipe.transform(value, format, this.locale);
    }

    public transformPercent(value: any, format): string {
        return this.percentPipe.transform(value / 100, format, this.locale);
    }

    public transformPercentNormal(value: any, format): string {
        return this.percentPipe.transform(value, format, this.locale);
    }

}
