import { Action } from '@ngrx/store';
import { ProductDocumentLinkModel } from '../../api/models/product-document-link.model';

export enum ProductDocumentActionTypes {
    PRODUCT_DOCUMENT_UPLOAD_REQUEST = 'PRODUCT_DOCUMENT:PRODUCT_DOCUMENT_UPLOAD_REQUEST',
    PRODUCT_DOCUMENT_UPLOAD_SUCCESS = 'PRODUCT_DOCUMENT:PRODUCT_DOCUMENT_UPLOAD_SUCCESS',
    PRODUCT_DOCUMENT_UPLOAD_FAILURE = 'PRODUCT_DOCUMENT:PRODUCT_DOCUMENT_UPLOAD_FAILURE',
    PRODUCT_DOCUMENT_DELETE_REQUEST = 'PRODUCT_DOCUMENT:PRODUCT_DOCUMENT_DELETE_REQUEST',
    PRODUCT_DOCUMENT_DELETE_SUCCESS = 'PRODUCT_DOCUMENT:PRODUCT_DOCUMENT_DELETE_SUCCESS',
    PRODUCT_DOCUMENT_DELETE_FAILURE = 'PRODUCT_DOCUMENT:PRODUCT_DOCUMENT_DELETE_FAILURE'
}


export interface ProductDocumentUploadRequestAction extends Action {
    payload: {
        sessionId: string,
        path: string;
        productDq: string;
        file: File;
        publishDate: string;
        linkType: string;
    };
}

export function uploadProductDocument(
    sessionId: string,
    path: string,
    productDq: string,
    file: File,
    publishDate: string,
    linkType: string
): ProductDocumentUploadRequestAction {
    return {
        type: ProductDocumentActionTypes.PRODUCT_DOCUMENT_UPLOAD_REQUEST,
        payload: {
            sessionId,
            path,
            productDq,
            file,
            publishDate,
            linkType
        },
    };
}


export interface ProductDocumentDeleteRequestAction extends Action {
    payload: {
        sessionId: string,
        path: string;
        productDq: string;
        linkType: string;
    };
}

export function deleteProductDocument(
    sessionId: string,
    path: string,
    productDq: string,
    linkType: string
): ProductDocumentDeleteRequestAction {
    return {
        type: ProductDocumentActionTypes.PRODUCT_DOCUMENT_DELETE_REQUEST,
        payload: {
            sessionId,
            path,
            productDq,
            linkType
        },
    };
}


export interface ProductDocumentUploadSuccessAction extends Action {
    payload: {
        sessionId: string,
        path: string;
        response: ProductDocumentLinkModel;
    };
}

export function uploadProductDocumentSuccess(sessionId: string, path: string, response: any): ProductDocumentUploadSuccessAction {
    return {
        type: ProductDocumentActionTypes.PRODUCT_DOCUMENT_UPLOAD_SUCCESS,
        payload: {
            sessionId,
            path,
            response,
        },
    };
}


export interface ProductDocumentDeleteSuccessAction extends Action {
    payload: {
        sessionId: string,
        path: string;
    };
}

export function deleteProductDocumentSuccess(sessionId: string, path: string): ProductDocumentDeleteSuccessAction {
    return {
        type: ProductDocumentActionTypes.PRODUCT_DOCUMENT_DELETE_SUCCESS,
        payload: {
            sessionId,
            path
        },
    };
}

export interface ProductDocumentUploadFailureAction extends Action {
    payload: {
        response: any;
    };
}

export function uploadProductDocumentFailure(response: any): ProductDocumentUploadFailureAction {
    return {
        type: ProductDocumentActionTypes.PRODUCT_DOCUMENT_UPLOAD_FAILURE,
        payload: {
            response,
        },
    };
}

export interface ProductDocumentDeleteFailureAction extends Action {
    payload: {
        response: any;
    };
}

export function deleteProdcutDocumentFailure(response: any): ProductDocumentDeleteFailureAction {
    return {
        type: ProductDocumentActionTypes.PRODUCT_DOCUMENT_DELETE_FAILURE,
        payload: {
            response,
        },
    };
}
