import { TranslocoService } from '@ngneat/transloco';
import { Entity, EntityTableConfig } from '../../../../../shared/models/entities.model';

export const COLUMN_MONTHS = [ 'MONTHS_3', 'MONTHS_6', 'MONTHS_9', 'MONTHS_12', 'MONTHS_15', 'MONTHS_18', 'MONTHS_24' ];

export function getTranslatedColumnMonths(translocoService: TranslocoService): string[] {
    return COLUMN_MONTHS.reduce((acc, current) => {
        acc.push(translocoService.translate(`market-analysis.basic.monthColumns.${current}`));
        return acc;
    }, []);
}

export const tableBaseConfig = {
    entityType: 'MARKET_ANALYSIS',
    heading: '',
    selectable: false,
    sort: {
        clientSort: true,
        order: 'ASC',
        path: 'name',
    },
    pathToId: '_id',
    id: null,
    layoutMode: 'not-fixed',
    columns: [
        {
            label: 'ISIN',
            info: null,
            path: 'isin',
            decorator: 'component',
            decoratorOptions: {
                componentOptions: {idPath: 'dq'},
                component: 'factsheet',
                styleHints: [],
            },
            styleDecorator: null,
            styleDecoratorOptions: null,
            sortPath: 'isin',
            weight: null,
            pathToStyleHints: null,
            pathToStyleDecorator: null,
        }, {
            label: 'Name',
            info: null,
            path: 'name',
            decorator: 'text',
            decoratorOptions: null,
            styleDecorator: null,
            styleDecoratorOptions: null,
            sortPath: 'name',
            weight: null,
            pathToStyleHints: null,
            pathToStyleDecorator: null,
        },
    ],
} as EntityTableConfig;

export const volatilityMatrixButton = {
    label: '',
    info: null,
    path: null,
    decorator: 'component',
    decoratorOptions: {
        componentOptions: {
            action: {
                label: 'Vola Matrix',
                type: 'view-vola-matrix',
            },
        },
        component: 'action',
    },
    sortPath: null,
    initialOrder: null,
    weight: null,
    pathToStyleHints: null,
};

export const generateVolatilityTTMTableConfig = (filter: any, data: Entity[], translocoService: TranslocoService) => {
    const month = filter.ttm || 'MONTHS_12';

    return {
        ...tableBaseConfig,

        columns: [
            ...tableBaseConfig.columns,

            ...['70%', '80%', '90%', '100%', '110%', '120%', '130%'].map((p, i) => ({
                    label: p,
                    info: null,
                    path: `impliedVolatility.${month}[${i}]`,
                    decorator: 'percent',
                    decoratorOptions: {
                        format: '1.2-2',
                        styleHints: [
                            'right-aligned',
                        ],
                    },
                    styleDecorator: 'heatmap',
                    styleDecoratorOptions: null,
                    sortPath: `impliedVolatility.${month}[${i}]`,
                    weight: null,
                    pathToStyleHints: null,
                    pathToStyleDecorator: `impliedVolatility.${month}[${i}]`,
                }),
            ),

            ...[volatilityMatrixButton],
        ],
    } as EntityTableConfig;
};

export const generateVolatiltyMoneynessTableConfig = (filter: any, data: Entity[], translocoService: TranslocoService) => {
    const moneyness = filter.moneyness || '3';

    const translatedColumns = getTranslatedColumnMonths(translocoService);

    return {
        ...tableBaseConfig,

        columns: [
            ...tableBaseConfig.columns,

            ...translatedColumns.map((p, i) => {
                const month = COLUMN_MONTHS[i];

                return {
                    label: p,
                    info: null,
                    path: `impliedVolatility.${month}[${moneyness}]`,
                    decorator: 'percent',
                    decoratorOptions: {
                        format: '1.2-2',
                        styleHints: [
                            'right-aligned',
                        ],
                    },
                    styleDecorator: 'heatmap',
                    styleDecoratorOptions: null,
                    sortPath: `impliedVolatility.${month}[${moneyness}]`,
                    weight: null,
                    pathToStyleHints: null,
                    pathToStyleDecorator: `impliedVolatility.${month}[${moneyness}]`,
                };
            }),

            ...[volatilityMatrixButton],
        ],
    } as EntityTableConfig;
};

export const generateDividendsTableConfig = (filter: any, data: Entity, translocoService: TranslocoService) => {
    if (!data || !data.length) { return {}; }

    const yearColumns = data.filter((entity) => entity.dividends)
        .reduce((prev, curr) => prev.length > curr.dividends.length ? prev : curr.dividends, [])
        .reduce((acc, c, index) => {
            const current = {
                ...c,
            };

            const year = new Date(current.date).getFullYear();

            current.year = year;
            current.index = index;
            acc[year] = current;

            return acc;
        }, {});

    const years = Object.keys(yearColumns).sort((a: any, b: any) => a - b);

    return {
        ...tableBaseConfig,

        columns: [
            ...tableBaseConfig.columns,
            ...years.map((p: any, i) => {
                const idx = parseInt(p, 10);
                const col = yearColumns[idx];

                return {
                    label: p,
                    info: null,
                    path: null,
                    decorator: 'composite',
                    decoratorOptions: {
                        lines: [
                            {
                                label: null,
                                info: null,
                                path: `dividends.${col.index}.dividend`,
                                decorator: 'percent',
                                decoratorOptions: {
                                    format: '1.2-2',
                                    styleHints: [
                                        'right-aligned',
                                    ],
                                },
                                sortPath: `dividends.${col.index}.dividend`,
                                initialOrder: null,
                                weight: null,
                                pathToStyleHints: null,
                            },
                            {
                                label: null,
                                info: null,
                                path: `dividends.${col.index}.date`,
                                decorator: 'date',
                                decoratorOptions: {
                                    styleHints: [
                                        'right-aligned',
                                        'muted',
                                        'small',
                                    ],
                                },
                                sortPath: null,
                                initialOrder: null,
                                weight: null,
                                pathToStyleHints: null,
                            },
                        ],
                        styleHints: [
                            'right-aligned',
                        ],
                    },
                    styleDecorator: 'heatmap',
                    styleDecoratorOptions: {
                        hueMean: 4.0,
                        hueC: -0.8,
                        normalize: false,
                    },
                    sortPath: `dividends.${col.index}.dividend`,
                    weight: null,
                    pathToStyleHints: null,
                    pathToStyleDecorator: `dividends.${col.index}.dividend`,
            };
        })],
    } as EntityTableConfig;
};

export const generateVolatilityMatrixTableConfig = (translocoService: TranslocoService): EntityTableConfig => {
    const translatedColumns = getTranslatedColumnMonths(translocoService);

    return {
        entityType: 'MARKET_ANALYSIS',
        heading: '',
        selectable: false,
        sort: {
            clientSort: true,
            order: 'ASC',
            path: 'ttm',
        },
        pathToId: null,
        id: null,
        layoutMode: 'not-fixed',
        columns: [
            {
                label: 'Basispreis',
                info: null,
                path: 'ttm',
                decorator: 'percent',
                decoratorOptions: {
                    format: '1.0-2',
                    styleHints: [
                        'left-aligned',
                    ],
                },
                styleDecorator: null,
                styleDecoratorOptions: null,
                sortPath: 'ttm',
                weight: null,
                pathToStyleHints: null,
                pathToStyleDecorator: null,
            },

            ...translatedColumns.map((p, i) => ({
                    label: p,
                    info: null,
                    path: `moneyness[${i}]`,
                    decorator: 'percent',
                    decoratorOptions: {
                        format: '1.2-2',
                        styleHints: [
                            'right-aligned',
                        ],
                    },
                    styleDecorator: 'heatmap',
                    styleDecoratorOptions: null,
                    sortPath: `moneyness[${i}]`,
                    weight: null,
                    pathToStyleHints: null,
                    pathToStyleDecorator: `moneyness[${i}]`,
                }),
            ),
        ],
    } as EntityTableConfig;
};
