<div class="form-group row">
    <div class="col-6 d-flex">
        <select
            class="custom-select"
            [ngModel]="isDatePickerEnabled ? 'CUSTOM' : currentValue[config?.options?.monthPath]"
            (ngModelChange)="onChangeMonth($event)">
            <option *ngFor="let value of dropdownValues" [value]="value.id">{{value.label}}</option>
            <option [value]="'CUSTOM'">{{ 'filters.maturityQuickSelector.monthSelectCustom' | transloco }}</option>
        </select>
    </div>
    <div class="col-6 d-flex">
        <ng-container *ngIf="isDatePickerEnabled; else: previewDate">
            <app-date-input [ngModel]="currentValue[config.options?.datePath]"
                            (ngModelChange)="onDateChanged($event)"
                            #input="ngModel"
                            [disabled]="disabled"
                            [dateFilter]="holidayDateFilter">
            </app-date-input>
        </ng-container>

        <ng-template #previewDate>
            <div class="block-badge">
                <div class="block-badge__label">
                    {{ 'filters.maturityQuickSelector.calcMonthLabel' | transloco }}
                </div>
                <div class="block-badge__view">
                    <span>{{ dateValue | translocoDate: { dateStyle: 'short' } }}</span>
                </div>
                <div class="block-badge__actions">
                    <a class="icon-label-button" href="#" (click)="onClickEditDate($event)">
                        <i class="icon-button">
                            <app-icon class="icon-button__icon" icon="material:pencil"></app-icon>
                        </i>
                    </a>
                </div>
            </div>
        </ng-template>
    </div>
</div>
