import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Entity } from '../../../models/entities.model';
import { EntityWidgetSheetOptions } from '../../../models/widgets.model';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { EntityDetailsTableComponent } from '../../../components/entity-details-table/entity-details-table.component';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-entity-widget-sheet',
    templateUrl: './entity-widget-sheet.component.html',
    styleUrls: ['./entity-widget-sheet.component.scss'],
    standalone: true,
    imports: [NgFor, EntityDetailsTableComponent]
})
export class EntityWidgetSheetComponent implements OnInit, OnChanges, EntityWidgetContentComponent {

    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public options: EntityWidgetSheetOptions;
    @Input() public data: Entity;
    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();

    constructor() {
    }

    get numberOfCols() {
        return (this.options.columns) ? this.options.columns : 1;
    }

    get cols() {
        return Array(this.numberOfCols).fill(0).map((x, i) => i);
    }

    public ngOnChanges(changes: SimpleChanges): void {

    }

    public ngOnInit() {
    }

    public getStructureChunkForCol(col: number) {
        if (this.options && this.options.fields) {
            const chunkSize = Math.ceil(this.options.fields.length / this.numberOfCols);
            return this.options.fields.slice(chunkSize * col, chunkSize * (col + 1));
        } else {
            return [];
        }
    }

}
