import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { TabConfig } from '../../models/tabs.model';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-tab-bar',
    templateUrl: './tab-bar.component.html',
    styleUrls: ['./tab-bar.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, TranslocoModule]
})
export class TabBarComponent implements OnInit {
    @HostBinding('class.nav') public navClass = true;
    @HostBinding('class.nav-tabs') public navTabsClass = true;

    @Input() public tabs: TabConfig[];
    @Input() public selectedTab: string;
    @Input() public translateLabels: boolean = false;
    @Input() public productType: string = null;
    @Output() public tabSelected: EventEmitter<string> = new EventEmitter<string>();

    constructor() { }

    public ngOnInit() {
    }

    public onTabSelected(e: Event, tabId: string) {
        e.preventDefault();
        this.tabSelected.next(tabId);
    }

}
