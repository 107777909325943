import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormValues } from '../../../../core/models/form.model';
import { EntityWidgetOptions } from '../../../models/widgets.model';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { TranslocoModule } from '@ngneat/transloco';
import { NumberInputComponent } from '../../../components/number-input/number-input.component';
import { DateInputComponent } from '../../../components/date-input/date-input.component';
import { RecommendationSelectorComponent } from '../../../components/recommendation-selector/recommendation-selector.component';
import { ButtonComponent } from 'chroma-ui';

export interface DericonRecommentWidgetInputData {
    price: number;
    date: string;
    type: string;
}

@Component({
    selector: 'app-entity-widget-dericon-recommend',
    templateUrl: './entity-widget-dericon-recommend.component.html',
    styleUrls: ['./entity-widget-dericon-recommend.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, RecommendationSelectorComponent, DateInputComponent, NumberInputComponent, TranslocoModule, ButtonComponent]
})
export class EntityWidgetDericonRecommendComponent implements OnInit, OnChanges, EntityWidgetContentComponent {
    @Input() public entityId: string;
    @Input() public width: number;

    @Input() public options: EntityWidgetOptions;
    @Input() public data: DericonRecommentWidgetInputData;

    @Input() public formValues: FormValues;

    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();

    public widgetForm: UntypedFormGroup;

    constructor(private fb: UntypedFormBuilder) {
        this.widgetForm = fb.group({
            targetPriceValue: [''],
            targetPricePublishingDate: [''],
            type: [''],
            targetPriceCurrency: ['']
        });
    }

    public ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && this.data) {
            this.widgetForm.patchValue(this.data, {emitEvent: false});
        }
    }

    public onClick(): void {
        this.action.emit({
            type: 'save-dericon-recommend-data',
            payload: {
                data: {
                    ...(this.data || {}),
                    ...this.widgetForm.value
                }
            }
        });
    }
}
