/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 5/2/17.
 */
import { Action } from '@ngrx/store';

export const BaseErrors = {
    UNKNOWN: 'base.unknown',
    GENERIC: 'base.generic',
};

export interface AppError {
    errorCode: string;
    httpError?: boolean;
    statusCode?: number;
    originalError?: any;
    initiator?: Action;
}

export interface GenericError extends Error {
    type: 'generic';
    name: string;
    message: string;
    severity: string;
    stack?: string;
}
export interface HttpError extends Error {
    type: 'http';
    name: string;
    message: string;
    statusCode: number;
    url: string;
    request: {
        headers: any;
        body?: any;
    };
    response: {
        headers: any;
        body?: any;
    };
    stack?: string;
    severity: string;
}
