import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TargetMarketCriteria } from '../../../routes/target-market/models/target-market-criteria.model';
import { TargetMarketService } from '../../../routes/target-market/services/target-market.service';
import { FilterCheckboxComponent } from '../filter-checkbox/filter-checkbox.component';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf } from '@angular/common';

/**
 * FilterCheckboxTmc renders a single checkbox for target market criteria activation in filters.
 * It distinguishes itself from a simple checkbox by being aware of the current target market data.
 */
@Component({
    selector: 'app-filter-checkbox',
    templateUrl: './filter-checkbox-tmc.component.html',
    styleUrls: ['./filter-checkbox-tmc.component.scss'],
    standalone: true,
    imports: [NgIf, TranslocoModule]
})
export class FilterCheckboxTmcComponent extends FilterCheckboxComponent implements OnInit, OnDestroy {
    public targetMarketCriteriaEnganged: Observable<boolean>;
    public isTargetMarketEnganged = false;
    public showWarning = false;

    public dataSubscription: Subscription;

    constructor(private targetMarketService: TargetMarketService) {
        super();

        this.targetMarketCriteriaEnganged = targetMarketService.isTargetMarketCriteriaEngaged();
    }

    public ngOnInit() {
        super.ngOnInit();

        this.dataSubscription = this.targetMarketCriteriaEnganged.subscribe(
            (value) => {
                this.isTargetMarketEnganged = value;

                if (this.showWarning && value) {
                    this.showWarning = false;
                } else if (!value && this._value) {
                    this.showWarning = true;
                    this._value = false;
                    this.onChange();
                }
            },
        );
    }

    public ngOnDestroy() {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }

    /**
     * Called when the checkbox state has changed
     * @param {Event} e
     */
    public onCheckboxChange(e: any) {
        e.preventDefault();

        const tempValue = !this._value;

        if (!this.isTargetMarketEnganged && tempValue) {
            this.showWarning = true;
            e.srcElement.checked = false;
            this._value = false;
        } else {
            this._value = tempValue;
        }

        this.onChange();
    }
}
