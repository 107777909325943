import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ValidationErrors, FormsModule } from '@angular/forms';
import { FormControlConfig, FormControlSelectOption } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';

import { isEqual } from 'lodash';
import { TranslocoModule } from '@ngneat/transloco';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-filter-maturity-rule-selector',
    templateUrl: './filter-maturity-rule-selector.component.html',
    styleUrls: ['./filter-maturity-rule-selector.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor, NgIf, TranslocoModule]
})
export class FilterMaturityRuleSelectorComponent implements OnInit, SingleValueFilterControl<MaturityRule>, OnChanges {

    @Input() public config: FormControlConfig;

    @Input() public values: MaturityRuleValue[];

    @Input() public disabled = false;

    public currentValue: MaturityRule = {
        rule: null,
        numMaturityDays: null,
    };

    public availableMaturityDays: FormControlSelectOption[] = [];

    public onChange: () => void;

    public onTouched: () => void;

    constructor() {
    }

    public ngOnInit() {
    }

    public writeValue(value: MaturityRule): void {
        if (!isEqual(this.currentValue, value)) {
            this.currentValue = (value) ? value : {
                rule: null,
                numMaturityDays: null,
            };
            this._updateAvailableMaturityDays();
        }
    }

    protected _isValidValue(value: MaturityRule) {
        if (this.values && value) {
            const matchingRule = this.values.filter((v) => {
                return v.rule.id === value.rule;
            }).pop();

            if (matchingRule) {
                const matchingDay = matchingRule.numMaturityDays.filter((numDays) => {
                    return numDays.id === value.numMaturityDays;
                }).pop();

                return Boolean(matchingDay);
            } else {
                return false;
            }
        } else {
            return false;
        }

    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this.currentValue);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this._isValidValue(this.currentValue)) ? null : {
            maturityRuleSelector: 'Invalid value specified',
        };
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.values) {
            this.currentValue = (this._isValidValue(this.currentValue)) ? this.currentValue : {
                rule: null,
                numMaturityDays: null,
            };
            this._updateAvailableMaturityDays();
        }
    }

    public onMaturityRuleChanged(value: string) {
        if (this.currentValue.rule !== value) {
            const newValue = {
                ...this.currentValue,
                rule: value,
            };

            this.currentValue = newValue;
            this._updateAvailableMaturityDays();
            if (this.onChange) { this.onChange(); }
        }
    }

    public onNumMaturityDaysChanged(value: string) {
        if (this.currentValue.numMaturityDays !== value) {
            const newValue = {
                ...this.currentValue,
                numMaturityDays: value,
            };

            this.currentValue = newValue;
            if (this.onChange) { this.onChange(); }
        }
    }

    protected _updateAvailableMaturityDays() {
        this.availableMaturityDays = [];

        if (this.currentValue && this.currentValue.rule) {
            const matchingRule = this.values.filter((v) => {
                return v.rule.id === this.currentValue.rule;
            }).pop();

            if (matchingRule) {
                this.availableMaturityDays = matchingRule.numMaturityDays;
            }
        }
    }

}

export interface MaturityRule {
    rule: string;
    numMaturityDays: string;
}

export interface MaturityRuleValue {
    rule: FormControlSelectOption;
    numMaturityDays: FormControlSelectOption[];
}
