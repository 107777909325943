<div class="tw-absolute tw-w-full">
    <div class="tw-border-b tw-border-neutral-200 tw-bg-white dark:tw-border-transparent">
        <div class="3xl:tw-container tw-w-full tw-mx-auto tw-px-8">
            <div class="tw-py-2.5 dark:tw-py-3.5">
                <div class="tw-min-h-[38px] tw-flex tw-items-center focus-visible:tw-outline-3 focus-visible:tw-outline-primary-focus">
                    <img class="tw-h-logo focus-visible:tw-outline" [src]="logoImgUrl" />
                </div>
            </div>
        </div>
    </div>
</div>

<router-outlet></router-outlet>
