/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 07/11/2017.
 */

import { Action, createAction, props } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { type } from '../../../../core/store/util';
import { Entity } from '../../../../shared/models/entities.model';
import { EntityWidgetConfig } from '../../../../shared/models/widgets.model';
import { UnderlyingInfoBoxStructure } from '../../../dpc/api/models/dpc.model';

export const ProductSelectionActionTypes = {
    ADD_PRODUCT_TO_SELECTION: type('PRODUCT_SELECTION:ADD_PRODUCT_TO_SELECTION'),
    ADD_PRODUCTS_TO_SELECTION: type('PRODUCT_SELECTION:ADD_PRODUCTS_TO_SELECTION'),
    REMOVE_PRODUCT_FROM_SELECTION: type('PRODUCT_SELECTION:REMOVE_PRODUCT_FROM_SELECTION'),
    REMOVE_PRODUCTS_FROM_SELECTION: type('PRODUCT_SELECTION:REMOVE_PRODUCTS_FROM_SELECTION'),

    CLEAR_SELECTION: type('PRODUCT_SELECTION:CLEAR_SELECTION'),

    SET_CURRENT_TAB_ID: type('PRODUCT_SELECTION:SET_CURRENT_TAB_ID'),

    LOAD_TABS_STRUCTURE: type('PRODUCT_SELECTION:LOAD_TABS_STRUCTURE'),
    LOAD_TABS_STRUCTURE_SUCCEEDED: type('PRODUCT_SELECTION:LOAD_TABS_STRUCTURE_SUCCEEDED'),
    LOAD_TABS_STRUCTURE_FAILED: type('PRODUCT_SELECTION:LOAD_TABS_STRUCTURE_FAILED'),

    LOAD_TAB_STRUCTURE: type('PRODUCT_SELECTION:LOAD_TAB_STRUCTURE'),
    LOAD_TAB_STRUCTURE_SUCCEEDED: type('PRODUCT_SELECTION:LOAD_TAB_STRUCTURE_SUCCEEDED'),
    LOAD_TAB_STRUCTURE_FAILED: type('PRODUCT_SELECTION:LOAD_TAB_STRUCTURE_FAILED'),

    LOAD_TAB_DATA: type('PRODUCT_SELECTION:LOAD_TAB_DATA'),
    LOAD_TAB_DATA_SUCCEEDED: type('PRODUCT_SELECTION:LOAD_TAB_DATA_SUCCEEDED'),
    LOAD_TAB_DATA_FAILED: type('PRODUCT_SELECTION:LOAD_TAB_DATA_FAILED'),

};

/** AddProductToSelectionAction **/

export interface AddProductToSelectionAction extends Action {
    payload: {
        productId: string;
    };
}

export function addProductToSelection(productId: string): AddProductToSelectionAction {
    return {
        type: ProductSelectionActionTypes.ADD_PRODUCT_TO_SELECTION,
        payload: {
            productId,
        },
    };
}

/** AddProductsToSelectionAction **/

export interface AddProductsToSelectionAction extends Action {
    payload: {
        productIds: string[];
    };
}

export function addProductsToSelection(productIds: string[]): AddProductsToSelectionAction {
    return {
        type: ProductSelectionActionTypes.ADD_PRODUCTS_TO_SELECTION,
        payload: {
            productIds,
        },
    };
}

/** RemoveProductFromSelectionAction **/

export interface RemoveProductFromSelectionAction extends Action {
    payload: {
        productId: string;
    };
}

export function removeProductFromSelection(productId: string): RemoveProductFromSelectionAction {
    return {
        type: ProductSelectionActionTypes.REMOVE_PRODUCT_FROM_SELECTION,
        payload: {
            productId,
        },
    };
}

/** RemoveProductsFromSelectionAction **/

export interface RemoveProductsFromSelectionAction extends Action {
    payload: {
        productIds: string[];
    };
}

export function removeProductsFromSelection(productIds: string[]): RemoveProductsFromSelectionAction {
    return {
        type: ProductSelectionActionTypes.REMOVE_PRODUCTS_FROM_SELECTION,
        payload: {
            productIds,
        },
    };
}

/** ClearSelectionAction **/

export interface ClearSelectionAction extends Action {
    payload: object;
}

export function clearSelection(): ClearSelectionAction {
    return {
        type: ProductSelectionActionTypes.CLEAR_SELECTION,
        payload: {},
    };
}

export const addProductIdsToShelf = createAction(
    '[Product Selection] Add Product Ids To Shelf',
    props<{ ids: Array<string> }>()
);

export const removeProductIdsFromShelf = createAction(
    '[Product Selection] Remove Product Ids From Shelf',
    props<{ ids: Array<string> }>()
);

// NEW

/** SetCurrentTabIdAction **/

export interface SetCurrentTabIdAction extends Action {
    payload: {
        tabId: string;
    };
}

export function setCurrentTabId(tabId: string): SetCurrentTabIdAction {
    return {
        type: ProductSelectionActionTypes.SET_CURRENT_TAB_ID,
        payload: {
            tabId,
        },
    };
}

/** LoadTabsStructureAction **/

export interface LoadTabsStructureAction extends Action {
    payload: {
        dqs: string[],
    };
}

export function loadTabsStructure(dqs: string[]): LoadTabsStructureAction {
    return {
        type: ProductSelectionActionTypes.LOAD_TABS_STRUCTURE,
        payload: {
            dqs,
        },
    };
}

/** LoadTabsStructureSucceededAction **/

export interface LoadTabsStructureSucceededAction extends Action {
    payload: {
        structure: UnderlyingInfoBoxStructure,
    };
}

export function loadTabsStructureSucceeded(structure: UnderlyingInfoBoxStructure): LoadTabsStructureSucceededAction {
    return {
        type: ProductSelectionActionTypes.LOAD_TABS_STRUCTURE_SUCCEEDED,
        payload: {
            structure,
        },
    };
}

/** LoadTabsStructureFailedAction **/

export interface LoadTabsStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadTabsStructureFailed(error: AppError): LoadTabsStructureFailedAction {
    return {
        type: ProductSelectionActionTypes.LOAD_TABS_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadTabStructureAction **/

export interface LoadTabStructureAction extends Action {
    payload: {
        tabId: string;
    };
}

export function loadTabStructure(tabId: string): LoadTabStructureAction {
    return {
        type: ProductSelectionActionTypes.LOAD_TAB_STRUCTURE,
        payload: {
            tabId,
        },
    };
}

/** LoadTabStructureSucceededAction **/

export interface LoadTabStructureSucceededAction extends Action {
    payload: {
        tabId: string;
        structure: EntityWidgetConfig[]
    };
}

export function loadTabStructureSucceeded(tabId: string, structure: EntityWidgetConfig[]): LoadTabStructureSucceededAction {
    return {
        type: ProductSelectionActionTypes.LOAD_TAB_STRUCTURE_SUCCEEDED,
        payload: {
            tabId,
            structure,
        },
    };
}

/** LoadTabStructureFailedAction **/

export interface LoadTabStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadTabStructureFailed(error: AppError): LoadTabStructureFailedAction {
    return {
        type: ProductSelectionActionTypes.LOAD_TAB_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadTabDataAction **/

export interface LoadTabDataAction extends Action {
    payload: {
        tabId: string;
        dqs: string[];
    };
}

export function loadTabData(tabId: string, dqs: string[]): LoadTabDataAction {
    return {
        type: ProductSelectionActionTypes.LOAD_TAB_DATA,
        payload: {
            tabId,
            dqs,
        },
    };
}

/** LoadTabDataSucceededAction **/

export interface LoadTabDataSucceededAction extends Action {
    payload: {
        tabId: string,
        values: Entity[];
    };
}

export function loadTabDataSucceeded(tabId: string, values: Entity[]): LoadTabDataSucceededAction {
    return {
        type: ProductSelectionActionTypes.LOAD_TAB_DATA_SUCCEEDED,
        payload: {
            tabId,
            values,
        },
    };
}

/** LoadTabDataFailedAction **/

export interface LoadTabDataFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadTabDataFailed(error: AppError): LoadTabDataFailedAction {
    return {
        type: ProductSelectionActionTypes.LOAD_TAB_DATA_FAILED,
        payload: {
            error,
        },
    };
}
