<div class="row header-row">
    <div class="col-12">
        <div class="app-filter-radio-button-group">
            <div class="working-days">
                <div class="working-days-label">
                    {{ 'widgets.topUnderlyings.workingDaysLabel' | transloco }}
                </div>

                <mat-button-toggle-group [value]="(filter$ | async).workingDays" (change)="setWorkingDaysFilter($event)">
                    <mat-button-toggle
                        *ngFor="let option of workingDayOptions$ | async"
                        [value]="option.value">{{option.label}}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div class="provisioned">
                <div class="provisioned-label">
                    {{ 'widgets.topUnderlyings.provisionedLabel' | transloco }}
                </div>

                <mat-button-toggle-group [value]="(filter$ | async).provisioned" (change)="setProvisionedFilter($event)">
                    <mat-button-toggle value="PROVISIONED">{{ 'widgets.topUnderlyings.provisioned' | transloco }}</mat-button-toggle>
                    <mat-button-toggle value="UNPROVISIONED">{{ 'widgets.topUnderlyings.unprovisioned' | transloco }}</mat-button-toggle>
                    <mat-button-toggle value="ALL">{{ 'widgets.topUnderlyings.all' | transloco }}</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
    </div>
</div>
<div class="row table-row">
    <div class="col-12">
        <app-entity-table
            [config]="tableConfig$ | async"
            [entities]="data$ | async"
            [isLoading]="loading$ | async"
            [emptyMessage]="'widgets.topUnderlyings.emptyMessage' | transloco">
        </app-entity-table>
    </div>
</div>
