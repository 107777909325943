import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ShortcutType } from "../../api/models/user.model";
import { UserState } from "../state/user.state";
import * as SearchSelectors from "./search.selectors";

export const selectUser = createFeatureSelector<UserState>('user');

export const selectShortcuts = createSelector(
    selectUser,
    SearchSelectors.selectTerm,
    (state: UserState, term: string) =>
        Object.assign({}, ...Object.keys(state.shortcuts).map((type: ShortcutType) => ({
            [type]: state.shortcuts[type].filter((shortcut) =>
                shortcut.searchText.toLowerCase().includes(term.toLowerCase())
            )
        })))
);

export const selectUserProfile = createSelector(
    selectUser,
    (state: UserState) => state.profile
);
