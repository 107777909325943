<app-entity-widget-container
    [inverse]="false"
    [responsive]="true"
    [compact]="false"
    [entityId]="entityId"
    [columns]="1"
    [structure]="options.widgets"
    [data]="data"
    [values]="formValues"
    (action)="onAction($event)"
    (componentEvent)="onComponentEvent($event)"></app-entity-widget-container>
