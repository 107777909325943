import { Action } from '@ngrx/store';
import { PortfolioManagerState } from '../state/portfolio-manager.state';
import {
    LoadFormValuesFailureAction,
    LoadFormValuesSuccessAction, LoadPortfolioDataFailureAction,
    LoadPortfolioDataSuccessAction,
    PortfolioManagerActions, UpdatePortfolioDataFailureAction, UpdatePortfolioDataSuccessAction
} from '../actions/portfolio-manager.actions';

export const initialState: PortfolioManagerState = {
    currentPortfolio: null,
    formValues: null,
    loadingFormValues: false,
    loadingFormValuesError: null,
    loadingPortfolio: false,
    loadingPortfolioError: null,
    updatingPortfolio: false,
    updatingPortfolioError: null,
    updatingPortfolioSuccess: null,
};

export function reducer(state: PortfolioManagerState = initialState,
                        action: Action): PortfolioManagerState {
    switch (action.type) {
        /** LOAD FORM VALUES **/
        case PortfolioManagerActions.LOAD_FORM_VALUES_REQUEST: {
            return {
                ...state,
                loadingFormValues: true,
                loadingFormValuesError: null,
            };
        }

        case PortfolioManagerActions.LOAD_FORM_VALUES_SUCCESS: {
            const loadFormValuesSuccessAction = action as LoadFormValuesSuccessAction;

            return {
                ...state,
                formValues: loadFormValuesSuccessAction.payload.values,
                loadingFormValues: false,
            };
        }

        case PortfolioManagerActions.LOAD_FORM_VALUES_FAILURE: {
            const loadFormValuesFailureAction = action as LoadFormValuesFailureAction;

            return {
                ...state,
                loadingFormValuesError: loadFormValuesFailureAction.payload.error,
                loadingFormValues: false,
            };
        }

        /** LOAD PORTFOLIO DATA **/
        case PortfolioManagerActions.LOAD_PORTFOLIO_DATA_REQUEST: {
            return {
                ...state,
                loadingPortfolio: true,
                loadingPortfolioError: null,
            };
        }

        case PortfolioManagerActions.LOAD_PORTFOLIO_DATA_SUCCESS: {
            const loadPortfolioDataSuccessAction = action as LoadPortfolioDataSuccessAction;

            return {
                ...state,
                loadingPortfolio: false,
                currentPortfolio: loadPortfolioDataSuccessAction.payload.data,
                updatingPortfolio: false,
                updatingPortfolioError: null,
                updatingPortfolioSuccess: null,
            };
        }

        case PortfolioManagerActions.LOAD_PORTFOLIO_DATA_FAILURE: {
            const loadPortfolioDataFailureAction = action as LoadPortfolioDataFailureAction;

            return {
                ...state,
                loadingPortfolio: false,
                loadingPortfolioError: loadPortfolioDataFailureAction.payload.error,
            };
        }

        /** UPDATE PORTFOLIO DATA **/
        case PortfolioManagerActions.UPDATE_PORTFOLIO_DATA_REQUEST: {
            return {
                ...state,
                updatingPortfolio: true,
                updatingPortfolioError: null,
                updatingPortfolioSuccess: null,
            };
        }

        case PortfolioManagerActions.UPDATE_PORTFOLIO_DATA_SUCCESS: {
            const updatePortfolioDataSuccessAction = action as UpdatePortfolioDataSuccessAction;

            return {
                ...state,
                updatingPortfolio: false,
                updatingPortfolioError: null,
                updatingPortfolioSuccess: true,
                currentPortfolio: updatePortfolioDataSuccessAction.payload.portfolio,
            };
        }

        case PortfolioManagerActions.UPDATE_PORTFOLIO_DATA_FAILURE: {
            const updatePortfolioDataFailureAction = action as UpdatePortfolioDataFailureAction;

            return {
                ...state,
                updatingPortfolio: false,
                updatingPortfolioError: updatePortfolioDataFailureAction.payload.error,
                updatingPortfolioSuccess: false,
            };
        }

    }

    return state;
}
