import { Component } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';
import { RouterLink, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-landing-layout',
    templateUrl: 'landing-layout.component.html',
    standalone: true,
    imports: [CommonModule, RouterLink, RouterOutlet]
})
export class LandingLayoutComponent {

    public logoImgUrl: string;

    constructor(private configurationService: ConfigurationService) {
        this.logoImgUrl = this.configurationService.getLogoImageUrl();
    }
}
