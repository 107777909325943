<div *ngIf="tabs.length > 1" class="tab-bar-container nav nav-tabs">
    <div *ngFor="let tabConfig of tabs" class="nav-item">
        <a
            class="nav-link"
            [class.active]="tabConfig.id === selectedTab"
            href="#"
            (click)="onTabSelected($event, tabConfig.id)">
            <ng-container *ngIf="translateLabels; else noTranslation">{{ tabConfig.label | transloco:{productType: productType} }}</ng-container>
            <ng-template #noTranslation>{{ tabConfig.label }}</ng-template>
        </a>
    </div>
</div>
