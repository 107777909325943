import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogConfig, MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ContentLoaderModule } from '@ngneat/content-loader';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPaginationModule } from 'ngx-pagination';
import { ConfigurationService } from '../core/services/configuration.service';
import { SliderComponent } from './components/slider/slider.component';
import { MarketAnalysisSharedModule } from '../routes/shared/market-analysis-shared/market-analysis-shared.module';
import { WatchlistSharedModule } from '../routes/shared/watchlist-shared/watchlist-shared.module';
import { AddToWatchlistDialogComponent } from './components/dialogs/add-to-watchlist-dialog/add-to-watchlist-dialog.component';
import { AdvisorBasketsChartComponent } from './components/advisor-baskets-chart/advisor-baskets-chart.component';
import { CalendarHeaderComponent } from './components/calendar-header/calendar-header.component';
import { ChartBubbleSelectableComponent } from './components/chart-bubble-selectable/chart-bubble-selectable.component';
import { ChartBubbleSeriesSelectableComponent } from './components/chart-bubble-selectable/chart-bubble-series-selectable.component';
import { ChartLegendComponent } from './components/chart-legend/chart-legend.component';
import { CheckboxInputComponent } from './components/checkbox-input/checkbox-input.component';
import { ContentsAuthorAvatarComponent } from './components/contents-author-avatar/contents-author-avatar.component';
import { LoadingDialogComponent } from './components/dialogs/loading-dialog/loading-dialog.component';
import { CreateWatchlistDialogComponent } from './components/dialogs/create-watchlist-dialog/create-watchlist-dialog.component';
import { CustomPaginationControlsComponent } from './components/custom-pagination-controls/custom-pagination-controls.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { DynamicChartHorizontalComponent } from './components/dynamic-chart-horizontal/dynamic-chart-horizontal.component';
import { DynamicChartLineComponent } from './components/dynamic-chart-line/dynamic-chart-line.component';
import { DynamicChartXAxisTicksComponent } from './components/dynamic-chart-line/x-axis-ticks.components';
import { DynamicChartXAxisComponent } from './components/dynamic-chart-line/x-axis.components';
import { DynamicChartYAxisTicksComponent } from './components/dynamic-chart-line/y-axis-ticks.component';
import { DynamicChartYAxisComponent } from './components/dynamic-chart-line/y-axis.component';
import { DynamicChartNewComponent } from './components/dynamic-chart-new/dynamic-chart-new.component';
import { DynamicChartComponent } from './components/dynamic-chart/dynamic-chart.component';
import {
    ENITIY_TABLE_SELECTBOX_CELL,
    ENTITY_TABLE_ACTION_CELL,
    ENTITY_TABLE_COMBINED_MULTI_ROW,
    ENTITY_TABLE_EQUIVALENT_PRODUCTS,
    ENTITY_TABLE_FACTSHEET_CELL,
    ENTITY_TABLE_IMAGE_CELL,
    ENTITY_TABLE_TEXTAREA,
    ENTITY_TABLE_TEXT_FIELD,
    WIDGET_TYPE_ALERT,
    WIDGET_TYPE_BASKETS_ADMIN,
    WIDGET_TYPE_COMBINED,
    WIDGET_TYPE_DECORFIN_DATA,
    WIDGET_TYPE_DERICON_RECOMMEND,
    WIDGET_TYPE_EQUIVALENCE_CHECK,
    WIDGET_TYPE_FOCUS_LIST,
    WIDGET_TYPE_FOCUSLIST_CHANGE,
    WIDGET_TYPE_FORM,
    WIDGET_TYPE_GRAPH,
    WIDGET_TYPE_GRAPH_EXTENDED,
    WIDGET_TYPE_IMAGE,
    WIDGET_TYPE_IMAGE_MARKDOWN,
    WIDGET_TYPE_LATEST_ISSUED_PRODUCTS,
    WIDGET_TYPE_MARKDOWN,
    WIDGET_TYPE_MARKET_DATA,
    WIDGET_TYPE_MARKET_INDICATOR,
    WIDGET_TYPE_MERCURY,
    WIDGET_TYPE_MISCHPRODUKTE_ANTEILE,
    WIDGET_TYPE_PARAGRAPH,
    WIDGET_TYPE_PRODUCT_PROMOTION,
    WIDGET_TYPE_PRODUCT_PROMOTION_EXPRESS,
    WIDGET_TYPE_SHEET,
    WIDGET_TYPE_TABLE,
    WIDGET_TYPE_TEXT_COPY_PASTE,
    WIDGET_TYPE_TOP_TRADES,
    WIDGET_TYPE_TOP_UNDERLYINGS,
    WIDGET_TYPE_WATCHLIST_SELECT,
    ENTITY_TABLE_COMBINED_MULTI_ROW_COMMENT,
    WIDGET_TYPE_QUICKLINK,
} from './components/dynamic-components';
import { EntityActionsComponent } from './components/entity-actions/entity-actions.component';
import { EntityDetailsTableComponent } from './components/entity-details-table/entity-details-table.component';
import { EntityHighlightComponent } from './components/entity-highlight/entity-highlight.component';
import { EntityHighlightsComponent } from './components/entity-highlights/entity-highlights.component';
import { EntityTableActionCellComponent } from './components/entity-table-action-cell/entity-table-action-cell.component';
import { EntityTableCellComponent } from './components/entity-table-cell/entity-table-cell.component';
import { EntityTableComponentCellComponent } from './components/entity-table-component-cell/entity-table-component-cell.component';
import { EntityTableEquivalentProductsCellComponent } from './components/entity-table-equivalent-products-cell/entity-table-equivalent-products-cell.component';
import { EntityTableFactsheetCellComponent } from './components/entity-table-factsheet-cell/entity-table-factsheet-cell.component';
import { EntityTableImageCellComponent } from './components/entity-table-image-cell/entity-table-image-cell.component';
import { EntityTableMultiRowComponent } from './components/entity-table-multi-row/entity-table-multi-row.component';
import { EntityTableRowComponent } from './components/entity-table-row/entity-table-row.component';
import { EntityTableSelectboxCellComponent } from './components/entity-table-selectbox-cell/entity-table-selectbox-cell.component';
import { EntityTableTextareaCellComponent } from './components/entity-table-textarea-cell/entity-table-textarea-cell.component';
import { EntityTableComponent } from './components/entity-table/entity-table.component';
import { FilterAssetsClassSelectionComponent } from './components/filter-assets-class-selection/filter-assets-class-selection.component';
import { FilterBarComponent } from './components/filter-bar/filter-bar.component';
import { FilterCheckboxGroupComponent } from './components/filter-checkbox-group/filter-checkbox-group.component';
import { FilterCheckboxSelectGroupComponent } from './components/filter-checkbox-select-group/filter-checkbox-select-group.component';
import { FilterCheckboxTmcComponent } from './components/filter-checkbox-tmc/filter-checkbox-tmc.component';
import { FilterCheckboxComponent } from './components/filter-checkbox/filter-checkbox.component';
import { FilterControlContainerComponent } from './components/filter-control-container/filter-control-container.component';
import { FilterDateInputComponent } from './components/filter-date-input/filter-date-input.component';
import { FilterDoubleDropdownInputComponent } from './components/filter-double-dropdown-input/filter-double-dropdown-input.component';
import { FilterFundFigureInputComponent } from './components/filter-fund-figure-input/filter-fund-figure-input.component';
import { FilterLabeledDateInputComponent } from './components/filter-labeled-date-input/filter-labeled-date-input.component';
import { FilterLabeledNumberInputComponent } from './components/filter-labeled-number-input/filter-labeled-number-input.component';
import { FilterLabelledNumberComparisonInputComponent } from './components/filter-labelled-number-comparison-input/filter-labelled-number-comparison-input.component';
import { FilterMatrixPricingVarianceComponent } from './components/filter-matrix-pricing-variance/filter-matrix-pricing-variance.component';
import { FilterMaturityDateQuickComponent } from './components/filter-maturity-date-quick/filter-maturity-date-quick.component';
import { FilterMaturityDateComponent } from './components/filter-maturity-date/filter-maturity-date.component';
import { FilterMaturityRuleSelectorComponent } from './components/filter-maturity-rule-selector/filter-maturity-rule-selector.component';
import { FilterMaturityComponent } from './components/filter-maturity/filter-maturity.component';
import { FilterMultiSelectCellsComponent } from './components/filter-multi-select-cells/filter-multi-select-cells.component';
import { FilterMultiSelectComponent } from './components/filter-multi-select/filter-multi-select.component';
import { FilterRadioButtonGroupComponent } from './components/filter-radio-button-group/filter-radio-button-group.component';
import { FilterRadioGroupHorizontalComponent } from './components/filter-radio-group-horizontal/filter-radio-group-horizontal.component';
import { FilterRadioGroupComponent } from './components/filter-radio-group/filter-radio-group.component';
import { FilterRangeSelectComponent } from './components/filter-range-select/filter-range-select.component';
import { FilterSelectCellsComponent } from './components/filter-select-cells/filter-select-cells.component';
import { FilterSingleSelectComponent } from './components/filter-single-select/filter-single-select.component';
import { FilterSwitchableDropdownInputComponent } from './components/filter-switchable-dropdown-input/filter-switchable-dropdown-input.component';
import { FilterTextInputComponent } from './components/filter-text-input/filter-text-input.component';
import { FilterTextSearchComponent } from './components/filter-text-search/filter-text-search.component';
import { FilterUnderlyingDetailedComponent } from './components/filter-underlying-detailed/filter-underlying-detailed.component';
import { FilterUnderlyingSelectorComponent } from './components/filter-underlying-selector/filter-underlying-selector.component';
import { FilterComponent } from './components/filter/filter.component';
import { RangeFilterComponent } from './components/forms/range-filter/range-filter.component';
import { IconComponent } from './components/icon/icon.component';
import { InlineMenuComponent } from './components/inline-menu/inline-menu.component';
import { MarkdownComponent } from './components/markdown/markdown.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { NumberInputComponent } from './components/number-input/number-input.component';
import { PercentInputComponent } from './components/percent-input/percent-input.component';
import { RecommendationSelectorComponent } from './components/recommendation-selector/recommendation-selector.component';
import { RiskManagerGeneralSettingsComponent } from './components/risk-manager-module-general-settings/risk-manager-general-settings.component';
import { SimpleCellSelectionComponent } from './components/simple-cell-selection/simple-cell-selection.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SubmenuItemComponent } from './components/submenu-item/submenu-item.component';
import { TabBarComponent } from './components/tab-bar/tab-bar.component';
import { TimeInputComponent } from './components/time-input/time-input.component';
import { WidgetGridComponent } from './components/widget-grid/widget-grid.component';

import { EntityTableCellStyleDecoratorDirective } from './directives/entity-table-cell-style-decorator.directive';

import { ErrorComponent } from './error/error.component';
import { ColumnLabelPipe } from './pipes/column-label.pipe';
import { DateNoOffsetPipe } from './pipes/date-no-offset.pipe';
import { DistanceToNowPipe } from './pipes/distance-to-now.pipe';
import { DqToIsinPipe } from './pipes/dq-to-isin.pipe';
import { JoinPipe } from './pipes/join.pipe';
import { MarkdownToHtmlPipe } from './pipes/markdown-to-html.pipe';
import { MaybeAsyncPipe } from './pipes/maybe-async.pipe';
import { Nl2BrPipe } from './pipes/nl2br.pipe';
import { ReplaceLineBreaks } from './pipes/replace-line-breaks.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { EntityWidgetAlertComponent } from './widgets/legacy/entity-widget-alert/entity-widget-alert.component';
import { EntityWidgetBasketsAdminButtonGroupComponent } from './widgets/legacy/entity-widget-baskets-admin/entity-widget-baskets-admin-button-group.component';
import { EntityWidgetBasketsAdminComponent } from './widgets/legacy/entity-widget-baskets-admin/entity-widget-baskets-admin.component';
import { EntityWidgetCombinedComponent } from './widgets/legacy/entity-widget-combined/entity-widget-combined.component';
import { EntityWidgetContainerComponent } from './widgets/legacy/entity-widget-container/entity-widget-container.component';
import { EntityWidgetDecorfinDataFormComponent } from './widgets/legacy/entity-widget-decorfin-data-form/entity-widget-decorfin-data-form.component';
import { EntityWidgetDecorfinDataComponent } from './widgets/legacy/entity-widget-decorfin-data/entity-widget-decorfin-data.component';
import { EntityWidgetDericonRecommendComponent } from './widgets/legacy/entity-widget-dericon-recommend/entity-widget-dericon-recommend.component';
import { EntityWidgetEquivalenceCheckComponent } from './widgets/legacy/entity-widget-equivalence-check/entity-widget-equivalence-check.component';
import { EntityWidgetFocuslistChangeComponent } from './widgets/legacy/entity-widget-focuslist-change/entity-widget-focuslist-change.component';
import { EntityWidgetFormComponent } from './widgets/legacy/entity-widget-form/entity-widget-form.component';
import { EntityWidgetGraphExtendedComponent } from './widgets/legacy/entity-widget-graph-extended/entity-widget-graph-extended.component';
import { EntityWidgetGraphComponent } from './widgets/legacy/entity-widget-graph/entity-widget-graph.component';
import { EntityWidgetLatestIssuedProductsItemComponent } from './widgets/legacy/entity-widget-latest-issued-products/entity-widget-latest-issued-products-item.component';
import { EntityWidgetLatestIssuedProductsComponent } from './widgets/legacy/entity-widget-latest-issued-products/entity-widget-latest-issued-products.component';
import { EntityWidgetMarketDataComponent } from './widgets/legacy/entity-widget-market-data/entity-widget-market-data.component';
import { EntityWidgetMarketIndicatorComponent } from './widgets/legacy/entity-widget-market-indicator/entity-widget-market-indicator.component';
import { EntityWidgetMercuryComponent } from './widgets/legacy/entity-widget-mercury/entity-widget-mercury.component';
import { EntityWidgetMischproduktAnteileComponent } from './widgets/legacy/entity-widget-mischprodukt-anteile/entity-widget-mischprodukt-anteile.component';
import { EntityWidgetParagraphComponent } from './widgets/legacy/entity-widget-paragraph/entity-widget-paragraph.component';
import { EntityWidgetProductPromotionExpressComponent } from './widgets/legacy/entity-widget-product-promotion-express/entity-widget-product-promotion-express.component';
import { EntityWidgetProdutPromotionComponent } from './widgets/legacy/entity-widget-product-promotion/entity-widget-product-promotion.component';
import { EntityWidgetSheetComponent } from './widgets/legacy/entity-widget-sheet/entity-widget-sheet.component';
import { EntityWidgetTableComponent } from './widgets/legacy/entity-widget-table/entity-widget-table.component';
import { EntityWidgetTextCopyPasteComponent } from './widgets/legacy/entity-widget-text-copy-paste/entity-widget-text-copy-paste.component';
import { EntityWidgetTopTradesItemComponent } from './widgets/legacy/entity-widget-top-trades/entity-widget-top-trades-item.component';
import { EntityWidgetTopTradesComponent } from './widgets/legacy/entity-widget-top-trades/entity-widget-top-trades.component';
import { EntityWidgetTopUnderlyingsComponent } from './widgets/legacy/entity-widget-top-underlyings/entity-widget-top-underlyings.component';
import { EntityWidgetWatchlistSelectComponent } from './widgets/legacy/entity-widget-watchlist-select/entity-widget-watchlist-select.component';
import { EntityWidgetComponent } from './widgets/legacy/entity-widget/entity-widget.component';
import { FocusListWidgetItemComponent } from './widgets/legacy/focus-list-widget/focus-list-widget-item/focus-list-widget-item.component';
import { FocusListWidgetComponent } from './widgets/legacy/focus-list-widget/focus-list-widget.component';
import { ImageMarkdownWidgetComponent } from './widgets/legacy/image-news-widget/image-markdown-widget.component';
import { ImageWidgetComponent } from './widgets/legacy/image-widget/image-widget.component';
import { MarkdownWidgetComponent } from './widgets/legacy/markdown-widget/markdown-widget.component';
import { DebugPipe } from './pipes/debug.pipe';
import { NgxEchartsModule } from 'ngx-echarts';
import { NoUiSliderComponent } from './components/no-ui-slider/no-ui-slider.component';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog/confirm-dialog.component';
import { PopupBlockedDialogComponent } from './components/dialogs/popup-blocked-dialog/popup-blocked-dialog.component';
import { ErrorDialogComponent } from './components/dialogs/error-dialog/error-dialog.component';
import { EntityTableTextFieldCellComponent } from './components/entity-table-text-field-cell/entity-table-text-field-cell.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { TargetMarketService } from '../routes/target-market/services/target-market.service';
import { IssueMessageComponent } from './components/issue-message/issue-message.component';
import { IssueMessageApi } from '../core/api/issue-message.api';
import { AdApi } from '../core/api/ad.api';
import { AdForDefaultLayoutComponent } from './components/ad/ad-for-default-layout/ad-for-default-layout.component';
import { AdForOverlayComponent } from './components/ad/ad-for-overlay/ad-for-overlay.component';
import { AdContentComponent } from './components/ad/ad-content/ad-content.component';
import { ContactPartnersComponent } from './components/contact-partners/contact-partners.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ReadModeDialogComponent } from './components/dialogs/read-mode-dialog/read-mode-dialog.component';
import { AvatarConfig, AvatarModule, AvatarSource, defaultColors } from 'ngx-avatars';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { EntityTableMultiRowCommentComponent } from './components/entity-table-multi-row-comment/entity-table-multi-row-comment.component';
import { SearchComponent } from '../core/components/search/search.component';
import { SearchResultComponent } from '../core/components/search/search-result/search-result.component';
import { EntityWidgetQuicklinkComponent } from './widgets/entity-widget-quicklink/entity-widget-quicklink.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,
        TranslocoLocaleModule,
        RouterModule,
        MatIconModule,
        MatDatepickerModule,
        MatMomentDateModule,
        InfiniteScrollModule,
        WatchlistSharedModule,
        MarketAnalysisSharedModule,
        NgxPaginationModule,
        NgxChartsModule,
        MatButtonToggleModule,
        MatTooltipModule,
        ContentLoaderModule.forRoot(),
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
        {
            ngModule: AvatarModule,
            providers: [
                {
                    provide: new InjectionToken<AvatarConfig>('avatar.config'),
                    useFactory: (config: ConfigurationService) => {
                        return {
                            sourcePriorityOrder: [
                                AvatarSource.VALUE,
                                AvatarSource.CUSTOM,
                                AvatarSource.INITIALS,
                                AvatarSource.GRAVATAR,
                            ],
                            colors: config.configuration.environment.chartColors.bubble || defaultColors,
                        };
                    },
                    deps: [ConfigurationService],
                },
            ],
        },
        MatDialogModule,
        OverlayModule,
        // CDK Modules
        CdkAccordionModule,
        ErrorComponent,
        InlineMenuComponent,
        MenuItemComponent,
        SubmenuItemComponent,
        RangeFilterComponent,
        ChartLegendComponent,
        ChartBubbleSelectableComponent,
        ChartBubbleSeriesSelectableComponent,
        ContentsAuthorAvatarComponent,
        EntityTableComponent,
        EntityTableCellComponent,
        EntityTableCellStyleDecoratorDirective,
        EntityTableComponentCellComponent,
        EntityTableRowComponent,
        EntityTableFactsheetCellComponent,
        EntityTableSelectboxCellComponent,
        EntityTableImageCellComponent,
        EntityDetailsTableComponent,
        FilterComponent,
        FilterControlContainerComponent,
        FilterRadioGroupComponent,
        FilterRadioButtonGroupComponent,
        FilterCheckboxGroupComponent,
        FilterCheckboxSelectGroupComponent,
        FilterCheckboxComponent,
        FilterCheckboxTmcComponent,
        FilterSingleSelectComponent,
        FilterUnderlyingDetailedComponent,
        FilterSelectCellsComponent,
        FilterMultiSelectCellsComponent,
        FilterMultiSelectComponent,
        FilterRangeSelectComponent,
        FilterMaturityComponent,
        FilterTextSearchComponent,
        FilterTextInputComponent,
        FilterMaturityRuleSelectorComponent,
        EntityActionsComponent,
        CustomPaginationControlsComponent,
        SafeHtmlPipe,
        MaybeAsyncPipe,
        MarkdownToHtmlPipe,
        ColumnLabelPipe,
        JoinPipe,
        TimeAgoPipe,
        TruncatePipe,
        DistanceToNowPipe,
        Nl2BrPipe,
        SpinnerComponent,
        EntityTableActionCellComponent,
        DateInputComponent,
        EntityHighlightsComponent,
        EntityHighlightComponent,
        FilterMaturityDateComponent,
        FilterLabeledNumberInputComponent,
        FilterLabeledDateInputComponent,
        FilterDateInputComponent,
        FilterUnderlyingSelectorComponent,
        TabBarComponent,
        WidgetGridComponent,
        // Widgets
        EntityWidgetContainerComponent,
        EntityWidgetComponent,
        EntityWidgetParagraphComponent,
        EntityWidgetTableComponent,
        EntityWidgetSheetComponent,
        EntityWidgetTextCopyPasteComponent,
        MarkdownWidgetComponent,
        ImageMarkdownWidgetComponent,
        FocusListWidgetComponent,
        EntityWidgetProductPromotionExpressComponent,
        ImageWidgetComponent,
        EntityWidgetWatchlistSelectComponent,
        EntityWidgetDericonRecommendComponent,
        EntityWidgetProdutPromotionComponent,
        EntityWidgetMarketIndicatorComponent,
        FilterBarComponent,
        FilterDoubleDropdownInputComponent,
        FilterSwitchableDropdownInputComponent,
        NumberInputComponent,
        MarkdownComponent,
        DynamicChartComponent,
        DynamicChartNewComponent,
        AdvisorBasketsChartComponent,
        EntityWidgetGraphComponent,
        RiskManagerGeneralSettingsComponent,
        EntityWidgetAlertComponent,
        IconComponent,
        CalendarHeaderComponent,
        FilterMatrixPricingVarianceComponent,
        EntityWidgetDecorfinDataComponent,
        EntityWidgetDecorfinDataFormComponent,
        EntityTableImageCellComponent,
        FocusListWidgetItemComponent,
        DqToIsinPipe,
        EntityWidgetTopTradesComponent,
        EntityWidgetTopTradesItemComponent,
        EntityWidgetTopUnderlyingsComponent,
        EntityWidgetLatestIssuedProductsComponent,
        EntityWidgetLatestIssuedProductsItemComponent,
        EntityWidgetFocuslistChangeComponent,
        EntityWidgetMarketDataComponent,
        FilterRadioGroupHorizontalComponent,
        FilterAssetsClassSelectionComponent,
        FilterFundFigureInputComponent,
        FilterLabelledNumberComparisonInputComponent,
        EntityWidgetMarketDataComponent,
        EntityWidgetGraphExtendedComponent,
        SimpleCellSelectionComponent,
        EntityWidgetCombinedComponent,
        DynamicChartHorizontalComponent,
        EntityWidgetMercuryComponent,
        DynamicChartLineComponent,
        DynamicChartYAxisTicksComponent,
        DynamicChartYAxisComponent,
        DynamicChartXAxisComponent,
        DynamicChartXAxisTicksComponent,
        FilterCheckboxSelectGroupComponent,
        DynamicChartNewComponent,
        ReplaceLineBreaks,
        RecommendationSelectorComponent,
        PercentInputComponent,
        EntityWidgetFormComponent,
        TimeInputComponent,
        EntityWidgetBasketsAdminComponent,
        EntityWidgetBasketsAdminButtonGroupComponent,
        CheckboxInputComponent,
        FilterMaturityDateQuickComponent,
        EntityWidgetEquivalenceCheckComponent,
        EntityTableTextareaCellComponent,
        EntityTableTextFieldCellComponent,
        EntityTableEquivalentProductsCellComponent,
        DateNoOffsetPipe,
        EntityTableMultiRowComponent,
        EntityTableMultiRowCommentComponent,
        DebugPipe,
        EntityWidgetMischproduktAnteileComponent,
        SliderComponent,
        NoUiSliderComponent,
        IssueMessageComponent,
        AdForDefaultLayoutComponent,
        AdForOverlayComponent,
        AdContentComponent,
        ContactPartnersComponent,
        NotFoundComponent,
        SearchComponent,
        SearchResultComponent,
        // Dialogs
        ConfirmDialogComponent,
        PopupBlockedDialogComponent,
        ErrorDialogComponent,
        CreateWatchlistDialogComponent,
        LoadingDialogComponent,
        AddToWatchlistDialogComponent,
        ReadModeDialogComponent
    ],
    exports: [
        MatIconModule,
        MatButtonToggleModule,
        TranslocoModule,
        TranslocoLocaleModule,
        ErrorComponent,
        InlineMenuComponent,
        MenuItemComponent,
        SubmenuItemComponent,
        RangeFilterComponent,
        CheckboxInputComponent,
        InfiniteScrollModule,
        ContentsAuthorAvatarComponent,
        ChartLegendComponent,
        CustomPaginationControlsComponent,
        SpinnerComponent,
        EntityTableComponent,
        EntityTableCellComponent,
        EntityTableCellStyleDecoratorDirective,
        EntityTableComponentCellComponent,
        EntityTableRowComponent,
        EntityTableFactsheetCellComponent,
        EntityTableSelectboxCellComponent,
        EntityTableImageCellComponent,
        EntityTableMultiRowComponent,
        EntityTableMultiRowCommentComponent,
        EntityDetailsTableComponent,
        EntityWidgetMarketIndicatorComponent,
        EntityWidgetMarketDataComponent,
        EntityWidgetMercuryComponent,
        EntityWidgetFormComponent,
        EntityWidgetBasketsAdminComponent,
        EntityWidgetBasketsAdminButtonGroupComponent,
        EntityWidgetEquivalenceCheckComponent,
        FilterComponent,
        FilterControlContainerComponent,
        FilterRadioGroupComponent,
        FilterRadioButtonGroupComponent,
        FilterCheckboxGroupComponent,
        FilterCheckboxSelectGroupComponent,
        FilterCheckboxComponent,
        FilterCheckboxTmcComponent,
        FilterSingleSelectComponent,
        FilterUnderlyingDetailedComponent,
        FilterSelectCellsComponent,
        FilterMultiSelectCellsComponent,
        FilterMultiSelectComponent,
        FilterRangeSelectComponent,
        FilterTextSearchComponent,
        FilterMaturityRuleSelectorComponent,
        FilterMaturityDateComponent,
        FilterLabeledNumberInputComponent,
        FilterLabeledDateInputComponent,
        FilterRadioGroupHorizontalComponent,
        FilterDateInputComponent,
        FilterUnderlyingSelectorComponent,
        FilterSwitchableDropdownInputComponent,
        FilterTextInputComponent,
        FilterAssetsClassSelectionComponent,
        FilterFundFigureInputComponent,
        FilterLabelledNumberComparisonInputComponent,
        FilterMaturityDateQuickComponent,
        EntityActionsComponent,
        WatchlistSharedModule,
        NgxPaginationModule,
        DateInputComponent,
        EntityHighlightsComponent,
        EntityHighlightComponent,
        ColumnLabelPipe,
        TabBarComponent,
        MarkdownToHtmlPipe,
        JoinPipe,
        TimeAgoPipe,
        Nl2BrPipe,
        DebugPipe,
        ReplaceLineBreaks,
        RecommendationSelectorComponent,
        EntityWidgetContainerComponent,
        EntityWidgetComponent,
        EntityWidgetParagraphComponent,
        EntityWidgetTableComponent,
        EntityWidgetSheetComponent,
        EntityWidgetTextCopyPasteComponent,
        EntityWidgetWatchlistSelectComponent,
        EntityWidgetFocuslistChangeComponent,
        EntityWidgetTopTradesComponent,
        EntityWidgetLatestIssuedProductsComponent,
        EntityWidgetCombinedComponent,
        EntityWidgetDericonRecommendComponent,
        MarkdownWidgetComponent,
        ImageMarkdownWidgetComponent,
        FocusListWidgetComponent,
        EntityWidgetProductPromotionExpressComponent,
        ImageWidgetComponent,
        EntityWidgetProdutPromotionComponent,
        EntityWidgetDecorfinDataComponent,
        FilterBarComponent,
        MarkdownComponent,
        AdvisorBasketsChartComponent,
        DynamicChartComponent,
        DynamicChartNewComponent,
        DynamicChartYAxisTicksComponent,
        DynamicChartYAxisComponent,
        DynamicChartXAxisComponent,
        DynamicChartXAxisTicksComponent,
        ChartBubbleSelectableComponent,
        ChartBubbleSeriesSelectableComponent,
        RiskManagerGeneralSettingsComponent,
        IconComponent,
        TimeInputComponent,
        FilterDoubleDropdownInputComponent,
        SearchComponent,
        SearchResultComponent,
        ContentLoaderModule,
        DqToIsinPipe,
        SafeHtmlPipe,
        TruncatePipe,
        DateNoOffsetPipe,
        DistanceToNowPipe,
        NumberInputComponent,
        MaybeAsyncPipe,
        PercentInputComponent,
        MatDialogModule,
        OverlayModule,
        SliderComponent,
        IssueMessageComponent,
        AdForDefaultLayoutComponent,
        AdForOverlayComponent,
        AdContentComponent,
        ContactPartnersComponent,
        // Dialogs
        ConfirmDialogComponent,
        PopupBlockedDialogComponent,
        ErrorDialogComponent,
        CreateWatchlistDialogComponent,
        LoadingDialogComponent,
        AddToWatchlistDialogComponent,
        ReadModeDialogComponent,
        // CDK Modules
        CdkAccordionModule
    ],
    providers: [
        TargetMarketService,
        IssueMessageApi,
        AdApi
    ]
})
export class SharedModule {

    public static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                {
                    provide: MAT_DIALOG_DEFAULT_OPTIONS,
                    useValue: {
                        panelClass: 'wms-dialog',
                        width: '100%',
                        maxWidth: '100%',
                        height: '100%',
                    } as MatDialogConfig
                },

                {provide: WIDGET_TYPE_COMBINED, useValue: EntityWidgetCombinedComponent},
                {provide: WIDGET_TYPE_MARKDOWN, useValue: MarkdownWidgetComponent},
                {provide: WIDGET_TYPE_IMAGE_MARKDOWN, useValue: ImageMarkdownWidgetComponent},
                {provide: WIDGET_TYPE_FOCUS_LIST, useValue: FocusListWidgetComponent},
                { provide: WIDGET_TYPE_QUICKLINK, useValue: EntityWidgetQuicklinkComponent },
                {provide: WIDGET_TYPE_IMAGE, useValue: ImageWidgetComponent},
                {provide: WIDGET_TYPE_PRODUCT_PROMOTION, useValue: EntityWidgetProdutPromotionComponent},
                {provide: WIDGET_TYPE_PRODUCT_PROMOTION_EXPRESS, useValue: EntityWidgetProductPromotionExpressComponent},
                {provide: WIDGET_TYPE_DERICON_RECOMMEND, useValue: EntityWidgetDericonRecommendComponent},
                {provide: WIDGET_TYPE_PARAGRAPH, useValue: EntityWidgetParagraphComponent},
                {provide: WIDGET_TYPE_TABLE, useValue: EntityWidgetTableComponent},
                {provide: WIDGET_TYPE_SHEET, useValue: EntityWidgetSheetComponent},
                {provide: WIDGET_TYPE_GRAPH, useValue: EntityWidgetGraphComponent},
                {provide: WIDGET_TYPE_GRAPH_EXTENDED, useValue: EntityWidgetGraphExtendedComponent},
                {provide: WIDGET_TYPE_DECORFIN_DATA, useValue: EntityWidgetDecorfinDataComponent},
                {provide: WIDGET_TYPE_FORM, useValue: EntityWidgetFormComponent},
                {provide: WIDGET_TYPE_WATCHLIST_SELECT, useValue: EntityWidgetWatchlistSelectComponent},
                {provide: WIDGET_TYPE_MERCURY, useValue: EntityWidgetMercuryComponent},
                {provide: WIDGET_TYPE_ALERT, useValue: EntityWidgetAlertComponent},
                {provide: WIDGET_TYPE_TEXT_COPY_PASTE, useValue: EntityWidgetTextCopyPasteComponent},
                {provide: WIDGET_TYPE_MARKET_INDICATOR, useValue: EntityWidgetMarketIndicatorComponent},
                {provide: WIDGET_TYPE_MARKET_DATA, useValue: EntityWidgetMarketDataComponent},
                {provide: WIDGET_TYPE_TOP_TRADES, useValue: EntityWidgetTopTradesComponent},
                {provide: WIDGET_TYPE_LATEST_ISSUED_PRODUCTS, useValue: EntityWidgetLatestIssuedProductsComponent},
                {provide: WIDGET_TYPE_TOP_UNDERLYINGS, useValue: EntityWidgetTopUnderlyingsComponent},
                {provide: WIDGET_TYPE_FOCUSLIST_CHANGE, useValue: EntityWidgetFocuslistChangeComponent},
                {provide: WIDGET_TYPE_BASKETS_ADMIN, useValue: EntityWidgetBasketsAdminComponent},
                {provide: WIDGET_TYPE_EQUIVALENCE_CHECK, useValue: EntityWidgetEquivalenceCheckComponent},
                {provide: WIDGET_TYPE_MISCHPRODUKTE_ANTEILE, useValue: EntityWidgetMischproduktAnteileComponent},
                {provide: ENTITY_TABLE_FACTSHEET_CELL, useValue: EntityTableFactsheetCellComponent},
                {provide: ENTITY_TABLE_ACTION_CELL, useValue: EntityTableActionCellComponent},
                {provide: ENITIY_TABLE_SELECTBOX_CELL, useValue: EntityTableSelectboxCellComponent},
                {provide: ENTITY_TABLE_IMAGE_CELL, useValue: EntityTableImageCellComponent},
                {provide: ENTITY_TABLE_TEXTAREA, useValue: EntityTableTextareaCellComponent},
                {provide: ENTITY_TABLE_TEXT_FIELD, useValue: EntityTableTextFieldCellComponent},
                {provide: ENTITY_TABLE_EQUIVALENT_PRODUCTS, useValue: EntityTableEquivalentProductsCellComponent},
                {provide: ENTITY_TABLE_COMBINED_MULTI_ROW, useValue: EntityTableMultiRowComponent},
                { provide: ENTITY_TABLE_COMBINED_MULTI_ROW_COMMENT, useValue: EntityTableMultiRowCommentComponent }
            ]
        };
    }
}
