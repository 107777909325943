import {Action} from '@ngrx/store';
import { AppError } from '../../../../../../core/errors/base.errors';
import {
    AddUserManagerUsersAction,
    AddUserManagerUsersFailedAction,
    AddUserManagerUsersSucceededAction,
    BatchDeleteUserManagerUsersAction,
    BatchDeleteUserManagerUsersFailedAction,
    BatchDeleteUserManagerUsersSucceededAction,
    DeleteUserManagerUserAction,
    DeleteUserManagerUserFailedAction,
    DeleteUserManagerUserSucceededAction,
    LoadUserManagerDataFailedAction,
    LoadUserManagerDataSucceededAction,
    LoadUserManagerFilterStructureSucceededAction,
    LoadUserManagerFilterValuesSucceededAction,
    LoadUserManagerStructureFailedAction,
    LoadUserManagerStructureSucceededAction,
    ResendUserActivationAction,
    ResendUserActivationFailedAction,
    ResendUserActivationSucceededAction,
    UpdateUserManagerUserAction,
    UpdateUserManagerUserFailedAction,
    UpdateUserManagerUserSucceededAction,
    UserManagerActionTypes,
} from '../actions/user-manager.actions';
import {UserManagerState} from '../state/user-manager.state';

export const initialState: UserManagerState = {
    tableStructure: null,
    tableValues: null,
    newlyCreatedUsers: [],

    loadingTableStructure: false,
    loadingTableValues: false,
    errorTableValues: null,
    errorTableStructure: null,

    currentUpdateUser: null,
    errorUpdateUser: null,
    loadingUpdateUser: false,

    currentDeleteUser: null,
    errorDeleteUser: null,
    loadingDeleteUser: false,

    batchDeleteUsers: null,
    loadingBatchDeleteUser: false,
    errorBatchDeleteUser: null,

    batchAddUsers: null,
    loadingBatchAddUsers: false,
    errorBatchAddUsers: null,

    loadingUserActivation: false,
    errorUserActivation: null,
    userActivation: null,

    filterStructure: null,
    filterValues: null,
};

export function reducer(state: UserManagerState = initialState,
                        action: Action): UserManagerState {
    switch (action.type) {
        case UserManagerActionTypes.LOAD_USER_MANAGER_DATA:
            return {
                ...state,
                loadingTableValues: true,
            };

        case UserManagerActionTypes.LOAD_USER_MANAGER_DATA_SUCCEEDED:
            const loadUserManagerDataSucceededAction = action as LoadUserManagerDataSucceededAction;

            return {
                ...state,
                loadingTableValues: false,
                tableValues: loadUserManagerDataSucceededAction.payload.data,
            };

        case UserManagerActionTypes.LOAD_USER_MANAGER_DATA_FAILED:
            const loadUserManagerDataFailedAction = action as LoadUserManagerDataFailedAction;

            return {
                ...state,
                loadingTableValues: false,
                errorTableValues: loadUserManagerDataFailedAction.payload.error,
            };

        case UserManagerActionTypes.LOAD_USER_MANAGER_STRUCTURE:
            return {
                ...state,
                loadingTableStructure: true,
            };

        case UserManagerActionTypes.LOAD_USER_MANAGER_STRUCTURE_SUCCEEDED:
            const loadUserManagerStructureSucceededAction = action as LoadUserManagerStructureSucceededAction;

            return {
                ...state,
                loadingTableStructure: false,
                tableStructure: loadUserManagerStructureSucceededAction.payload.structure,
            };

        case UserManagerActionTypes.LOAD_USER_MANAGER_STRUCTURE_FAILED:
            const loadUserManagerStructureFailedAction = action as LoadUserManagerStructureFailedAction;

            return {
                ...state,
                loadingTableStructure: false,
                errorTableStructure: loadUserManagerStructureFailedAction.payload.error,
            };

        case UserManagerActionTypes.UPDATE_USER_MANAGER_USER:
            const updateUserManagerUserAction = action as UpdateUserManagerUserAction;

            return {
                ...state,
                currentUpdateUser: {
                    userid: updateUserManagerUserAction.payload.userid,
                    entity: updateUserManagerUserAction.payload.entity,
                },
                loadingUpdateUser: true,
                errorUpdateUser: null,
            };

        case UserManagerActionTypes.UPDATE_USER_MANAGER_USER_SUCCEEDED:
            const updateUserManagerUserSucceededAction = action as UpdateUserManagerUserSucceededAction;

            return {
                ...state,
                tableValues: state.tableValues ? state.tableValues.map((v) => {
                    if (v.id !== state.currentUpdateUser.userid) { return v; }

                    return {
                        ...v,
                        role: state.currentUpdateUser.entity,
                    };
                }) : null,
                loadingUpdateUser: false,
                errorUpdateUser: null,
            };

        case UserManagerActionTypes.UPDATE_USER_MANAGER_USER_FAILED:
            const updateUserManagerUserFailedAction = action as UpdateUserManagerUserFailedAction;

            return {
                ...state,
                errorUpdateUser: updateUserManagerUserFailedAction.payload.error,
                loadingUpdateUser: false,
            };

        case UserManagerActionTypes.DELETE_USER_MANAGER_USER:
            const deleteUserManagerUserAction = action as DeleteUserManagerUserAction;

            return {
                ...state,
                currentDeleteUser: {
                    userid: deleteUserManagerUserAction.payload.userid,
                },
                loadingDeleteUser: true,
                errorDeleteUser: null,
            };

        case UserManagerActionTypes.DELETE_USER_MANAGER_USER_SUCCEEDED:
            const deleteUserManagerUserSucceededAction = action as DeleteUserManagerUserSucceededAction;

            return {
                ...state,
                tableValues: state.tableValues ?
                    state.tableValues.filter((v) => v.id !== state.currentDeleteUser.userid) : null,
                loadingDeleteUser: false,
                errorDeleteUser: null,
            };

        case UserManagerActionTypes.DELETE_USER_MANAGER_USER_FAILED:
            const deleteUserManagerUserFailedAction = action as DeleteUserManagerUserFailedAction;

            return {
                ...state,
                loadingDeleteUser: false,
                currentDeleteUser: null,
                errorDeleteUser: deleteUserManagerUserFailedAction.payload.error,
            };

        case UserManagerActionTypes.BATCH_DELETE_USER_MANAGER_USERS:
            const batchDeleteUserManagerUsersAction = action as BatchDeleteUserManagerUsersAction;

            return {
                ...state,
                batchDeleteUsers: batchDeleteUserManagerUsersAction.payload.users,
                loadingBatchDeleteUser: true,
                errorBatchDeleteUser: null,
            };

        case UserManagerActionTypes.BATCH_DELETE_USER_MANAGER_USERS_SUCCEEDED:
            const batchDeleteUserManagerUsersSucceededAction = action as BatchDeleteUserManagerUsersSucceededAction;
            const deletedUsers = [...state.batchDeleteUsers];

            return {
                ...state,
                tableValues: state.tableValues ? state.tableValues.filter((user) => deletedUsers.indexOf(user.id) === -1) : null,
                loadingBatchDeleteUser: false,
                errorBatchDeleteUser: null,
            };

        case UserManagerActionTypes.BATCH_DELETE_USER_MANAGER_USERS_FAILED:
            const batchDeleteUserManagerUsersFailedAction = action as BatchDeleteUserManagerUsersFailedAction;

            return {
                ...state,
                batchDeleteUsers: null,
                loadingBatchDeleteUser: false,
                errorBatchDeleteUser: batchDeleteUserManagerUsersFailedAction.payload.error,
            };

        case UserManagerActionTypes.LOAD_USER_MANAGER_FILTER_VALUES_SUCCEEDED:
            const loadUserManagerFilterValuesSucceededAction = action as LoadUserManagerFilterValuesSucceededAction;

            return {
                ...state,
                filterValues: loadUserManagerFilterValuesSucceededAction.payload.values,
            };

        case UserManagerActionTypes.LOAD_USER_MANAGER_FILTER_STRUCTURE_SUCCEEDED:
            const loadUserManagerFilterStructureSucceededAction = action as LoadUserManagerFilterStructureSucceededAction;

            return {
                ...state,
                filterStructure: loadUserManagerFilterStructureSucceededAction.payload.structure,
            };

        case UserManagerActionTypes.ADD_USER_MANAGER_USERS:
            const addUserManagerUsersAction = action as AddUserManagerUsersAction;

            return {
                ...state,
                batchAddUsers: addUserManagerUsersAction.payload.users,
                loadingBatchAddUsers: true,
                errorBatchAddUsers: null,
            };

        case UserManagerActionTypes.ADD_USER_MANAGER_USERS_SUCCEEDED:
            const addUserManagerUsersSucceededAction = action as AddUserManagerUsersSucceededAction;

            return {
                ...state,
                newlyCreatedUsers: [...addUserManagerUsersSucceededAction.payload.users.createdUsers, ...addUserManagerUsersSucceededAction.payload.users.updatedUsers],
                loadingBatchAddUsers: false,
                errorBatchAddUsers: null,
            };

        case UserManagerActionTypes.ADD_USER_MANAGER_USERS_FAILED:
            const addUserManagerUsersFailedAction = action as AddUserManagerUsersFailedAction;

            return {
                ...state,
                batchAddUsers: null,
                loadingBatchAddUsers: false,
                errorBatchAddUsers: addUserManagerUsersFailedAction.payload.error,
            };

        case UserManagerActionTypes.RESEND_USER_ACTIVATION:
            const resendUserActivationAction = action as ResendUserActivationAction;

            return {
                ...state,
                userActivation: { userid: resendUserActivationAction.payload.userid },
                loadingUserActivation: true,
                errorUserActivation: null,
            };

        case UserManagerActionTypes.RESEND_USER_ACTIVATION_SUCCEEDED:
            const resendUserActivationSucceededAction = action as ResendUserActivationSucceededAction;

            return {
                ...state,
                loadingUserActivation: false,
                errorUserActivation: null,
            };

        case UserManagerActionTypes.RESEND_USER_ACTIVATION_FAILED:
            const resendUserActivationFailedAction = action as ResendUserActivationFailedAction;

            return {
                ...state,
                loadingUserActivation: false,
                errorUserActivation: resendUserActivationFailedAction.payload.error,
                userActivation: null,
            };
    }

    return state;
}

/*
loadingUserActivation: false,
    errorUserActivation: null,
    userActivation: null,
 */
