import { NgClass } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { cva } from 'cva';

let nextUniqueId = 0;

@Component({
  selector: 'chroma-checkbox',
  standalone: true,
  templateUrl: './checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckboxComponent,
      multi: true
    }
  ],
  styles: [`
    .tw-form-checkbox:checked {
      background-image: url("./icons/check-small.svg");
      background-size: 20px;
    }

    .tw-form-checkbox:indeterminate {
      background-image: url("./icons/check-indeterminate-small.svg");
    }
  `],
  imports: [NgClass]
})
export class CheckboxComponent implements ControlValueAccessor {

  public inputStyle = cva([
    'tw-form-checkbox',
    'tw-h-4', 'tw-w-4',
    'tw-rounded-sm',
    'tw-border-control-base',
    'tw-text-primary-base',
    'checked:tw-border-transparent',
    'focus:tw-border-transparent',
    'focus:tw-ring',
    'focus:tw-ring-offset-0',
    'focus:tw-ring-primary-focus',
    'tw-cursor-pointer',
    'disabled:tw-cursor-not-allowed',
    'disabled:tw-opacity-60'
  ]);

  public labelStyle = cva([
    'tw-mb-0'
  ], {
    variants: {
      disabled: {
        true: 'tw-cursor-not-allowed tw-opacity-60',
        false: 'tw-cursor-pointer'
      }
    }
  });

  constructor(protected _changeDetectorRef: ChangeDetectorRef) {
    this._uniqueId = ++nextUniqueId;
  }

  @Input() checked = false;

  @Input() indeterminate = false;
  
  @Input() disabled = false;

  @Input() stackable = true;

  @Output() readonly change: EventEmitter<void> = new EventEmitter<void>();

  private _uniqueId: number;

  public get inputId(): string {
    return `checkbox-${this._uniqueId}-input`;
  }

  private _onTouched: () => any = () => {};

  private _controlValueAccessorChangeFn: (value: any) => void = () => {};

  public writeValue(value: any): void {
    this.checked = !!value;
  }

  public registerOnChange(fn: (value: any) => void): void {
    this._controlValueAccessorChangeFn = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public onInputClick(): void {
    if (!this.disabled) {
      this.checked = !this.checked;
      this._controlValueAccessorChangeFn(this.checked);
      this.change.emit();
    }
  }

  public onInteractionEvent(event: Event): void {
    event.stopPropagation();
  }

  public onBlur(): void {
    Promise.resolve().then(() => {
      this._onTouched();
      this._changeDetectorRef.markForCheck();
    });
  }
}
