import { ActionReducer } from "@ngrx/store";
import { localStorageSync } from "ngrx-store-localstorage";
import { AuthActionTypes } from "../actions/auth.action";
import { AppState } from "../state/index.state";

export function localStorageSyncReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
    return (state, action) => {
        if (action.type === AuthActionTypes.SIGN_OUT) {
            state = { ...state,
                targetMarket: undefined,
                customerSelection: undefined
            };
        }

        return localStorageSync({
            keys: [
                'targetMarket',
                'customerSelection',
                'issueMessage'
            ],
            rehydrate: true
        })(reducer)(state, action);
    };
}
