import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EntityTableCellComponent, EntityTableComponentCellComponent } from '../entity-table-component-cell/entity-table-component-cell.component';
import { Entity, EntityTableColumn, EntityTableConfig } from '../../models/entities.model';
import { get, set } from 'lodash';
import { EntityTableCellComponent as EntityTableCellComponent_1 } from '../entity-table-cell/entity-table-cell.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-entity-table-multi-row',
    templateUrl: './entity-table-multi-row.component.html',
    styleUrls: ['./entity-table-multi-row.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf, EntityTableComponentCellComponent, EntityTableCellComponent_1]
})
export class EntityTableMultiRowComponent implements OnInit, OnChanges, EntityTableCellComponent {

    @HostBinding('class.entity-table-multi-row-cell') public MultiRowEntityTableCellClass = true;

    @Input() public product: Entity;
    @Input() public options: {
        fieldSeparator: string;
        fields: EntityTableColumn[];
    };
    @Input() public decoratorOptions: any;
    @Input() public path: string;

    @Output() public event: EventEmitter<any> = new EventEmitter();

    public get lines() {
        return get(this.product, this.path, []);
    }

    constructor() {
    }

    public ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges) {

    }

    onComponentEvent($event: any) {

    }

    showLabelForField(field: EntityTableColumn) {
        if(field.decoratorOptions && field.decoratorOptions.styleHints) {
            return field.decoratorOptions.styleHints.indexOf('show-label') !== -1;
        }

        return false;
    }
}
