import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppError } from '../../../../core/errors/base.errors';
import { FormValues } from '../../../../core/models/form.model';
import { AppState } from '../../../../core/store/state/index.state';
import { updateProductActivation, updateProductDecorfinData, updateProductNotes, updateProductDericonRecommend } from '../store/actions/product-shared.actions';

@Injectable()
export class ProductSharedService {

    constructor(private store: Store<AppState>) {
    }

    /**
     * Update the product activation for the specified product type id
     * @param sessionId
     * @param {string} productDq
     * @param {string} dataPath
     * @param {string} issueSurcharge
     * @param {boolean} activated
     */
    public updateProductActivation(
        sessionId: string,
        productDq: string,
        dataPath: string,
        issueSurcharge: string,
        activated: boolean,
        price: string,
    ) {
        this.store.dispatch(updateProductActivation(
            sessionId,
            productDq,
            dataPath,
            issueSurcharge,
            activated,
            price,
        ));
    }

    /**
     * Update the product notes for the specified product type id
     * @param sessionId
     * @param {string} productDq
     * @param {string} dataPath
     * @param {string} comment
     * @param {boolean} promoted
     */
    public updateProductNotes(
        sessionId: string,
        productDq: string,
        dataPath: string,
        comment: string,
        promoted: boolean
    ) {
        this.store.dispatch(updateProductNotes(sessionId, productDq, dataPath, comment, promoted));
    }

    /**
     * Get the product note updated status
     * @returns {Observable<Entity>}
     */
    public getProductNotesUpdated(): Observable<boolean> {
        return this.store.pipe(
            select((state: AppState) => state.productShared.productNoteUpdated),
        );
    }

    /**
     * Get the product activation updated status
     * @returns {Observable<Entity>}
     */
    public getProductActivationUpdated(): Observable<boolean> {
        return this.store.pipe(
            select((state: AppState) => state.productShared.productActivationUpdated),
        );

    }

    public getProductDecorfinDataUpdated(): Observable<boolean> {
        return this.store.pipe(
            select((state: AppState) => state.productShared.productDecorfinDataUpdated),
        );
    }

    public getProductDecorfinDataUpdateError(): Observable<AppError> {
        return this.store.pipe(
            select((state: AppState) => state.productShared.productDecorfinDataUpdatingError),
        );
    }

    public getProductDericonRecommendUpdated(): Observable<boolean> {
        return this.store.pipe(
            select((state: AppState) => state.productShared.productDericonRecommendUpdated),
        );
    }

    public getProductDericonRecommendUpdateError(): Observable<AppError> {
        return this.store.pipe(
            select((state: AppState) => state.productShared.productDericonRecommendUpdatingError),
        );
    }

    public updateProductDecorfinData(sessionId: string, productDq: string, brokerDecorfinId: string, data: FormValues, brokerDecorfinDataMode: string) {
        this.store.dispatch(updateProductDecorfinData(sessionId, productDq, brokerDecorfinId, data, brokerDecorfinDataMode));
    }

    public updateProductDericonRecommend(dq: string, instrumentType: string, data: FormValues) {
        this.store.dispatch(updateProductDericonRecommend(dq, instrumentType, data));
    }
}
