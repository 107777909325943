import { Action } from '@ngrx/store';

import * as uiActions from '../actions/ui.action';

import { AddToOverlayNavigationStackAction } from '../actions/ui.action';
import { UiState } from '../state/ui.state';

export const initialState: UiState = {
    pageVisible: true,
    menuVisible: false,
    overlayOpen: false,
    overlayNavigationStack: []
};

/**
 * The reducer responsible for the @link{UiState}
 * @param state
 * @param action
 * @returns {any}
 */
export function reducer(state: UiState = initialState, action: Action): UiState {
    switch (action.type) {

        /**
         * Set the menu to visible
         */
        case uiActions.ActionTypes.OPEN_MENU:
            return {
                ...state,
                menuVisible: true,
            };

        /**
         * Set the menu to not visible
         */
        case uiActions.ActionTypes.CLOSE_MENU:
            return {
                ...state,
                menuVisible: false,
            };

        /**
         * Set the overlay open state to true
         */
        case uiActions.ActionTypes.SET_OVERLAY_OPEN:
            return {
                ...state,
                overlayOpen: true,
            };

        /**
         * Set the overlay open state to false
         */
        case uiActions.ActionTypes.SET_OVERLAY_CLOSED:
            return {
                ...state,
                overlayOpen: false,
            };

        /**
         * Add the given url to the overlay navigation stack
         */
        case uiActions.ActionTypes.ADD_TO_OVERLAY_NAVIGATION_STACK:
            const addToOverlayNavigationStackAction = action as AddToOverlayNavigationStackAction;
            const newStack = [
                ...state.overlayNavigationStack,
            ];

            const topStackItem = state.overlayNavigationStack.slice(-1).pop();
            if (!topStackItem || topStackItem.split('?')[0] !== addToOverlayNavigationStackAction.payload.url.split('?')[0]) {
                newStack.push(addToOverlayNavigationStackAction.payload.url);
            } else {
                newStack[newStack.length - 1] = addToOverlayNavigationStackAction.payload.url;
            }

            return {
                ...state,
                overlayNavigationStack: newStack,
            };

        /**
         * Pop the last url from the overlay navigation stack
         */
        case uiActions.ActionTypes.POP_OVERLAY_NAVIGATION_STACK:
            return {
                ...state,
                overlayNavigationStack: state.overlayNavigationStack.slice(0, -1),
            };

        /**
         * Clear the overlay navigation stack
         */
        case uiActions.ActionTypes.CLEAR_OVERLAY_NAVIGATION_STACK:
            return {
                ...state,
                overlayNavigationStack: [],
            };

        default:
            return state;
    }
}
