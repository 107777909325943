import { Action } from '@ngrx/store';
import { AsyncLinkResponseData } from '../../../../core/api/models/download.model';
import { AppError } from '../../../../core/errors/base.errors';
import { type } from '../../../../core/store/util';
import { ProductsExportFormInitial, ProductsExportFormValues, ProductsExportRequestData } from '../../models/products-export.model';

export const ProductsExportActions = {
    EXECUTE_EXPORT_REQUEST: type('PEA:EXECUTE_EXPORT_REQUEST'),
    EXECUTE_EXPORT_SUCCESS: type('PEA:EXECUTE_EXPORT_SUCCESS'),
    EXECUTE_EXPORT_FAILURE: type('PEA:EXECUTE_EXPORT_FAILURE'),

    LOAD_FORM_VALUES_REQUEST: type('PEA:LOAD_FORM_VALUES_REQUEST'),
    LOAD_FORM_VALUES_SUCCESS: type('PEA:LOAD_FORM_VALUES_SUCCESS'),
    LOAD_FORM_VALUES_FAILURE: type('PEA:LOAD_FORM_VALUES_FAILURE'),

    LOAD_FORM_INITIAL_REQUEST: type('PEA:LOAD_FORM_INITIAL_REQUEST'),
    LOAD_FORM_INITIAL_SUCCESS: type('PEA:LOAD_FORM_INITIAL_SUCCESS'),
    LOAD_FORM_INITIAL_FAILURE: type('PEA:LOAD_FORM_INITIAL_FAILURE'),
};

/** EXPORT ACTION **/
export interface ExecuteExportRequestAction extends Action {
    payload: {
        data: ProductsExportRequestData;
        moduleId: string;
    };
}

export function executeExportRequest(moduleId: string, data: ProductsExportRequestData): ExecuteExportRequestAction {
    return {
        type: ProductsExportActions.EXECUTE_EXPORT_REQUEST,
        payload: {
            moduleId,
            data,
        },
    };
}

export interface ExecuteExportSuccessAction extends Action {
    payload?: {
        result: AsyncLinkResponseData;
    };
}

export function executeExportSuccess(result: AsyncLinkResponseData): ExecuteExportSuccessAction {
    return {
        type: ProductsExportActions.EXECUTE_EXPORT_SUCCESS,
        payload: {
            result,
        },
    };
}

export interface ExecuteExportFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function executeExportFailure(error: AppError): ExecuteExportFailureAction {
    return {
        type: ProductsExportActions.EXECUTE_EXPORT_FAILURE,
        payload: {
            error,
        },
    };
}

/** FORM VALUES ACTION **/
export interface LoadFormValuesRequestAction extends Action {
    payload: {
        moduleId: string;
    };
}

export function loadFormValuesRequest(moduleId: string): LoadFormValuesRequestAction {
    return {
        type: ProductsExportActions.LOAD_FORM_VALUES_REQUEST,
        payload: {
            moduleId,
        },
    };
}

export interface LoadFormValuesSuccessAction extends Action {
    payload: {
        values: ProductsExportFormValues,
    };
}

export function loadFormValuesSuccess(values: ProductsExportFormValues): LoadFormValuesSuccessAction {
    return {
        type: ProductsExportActions.LOAD_FORM_VALUES_SUCCESS,
        payload: {
            values,
        },
    };
}

export interface LoadFormValuesFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadFormValuesFailure(error: AppError): LoadFormValuesFailureAction {
    return {
        type: ProductsExportActions.LOAD_FORM_VALUES_FAILURE,
        payload: {
            error,
        },
    };
}

/** FORM INITIAL ACTION **/
export interface LoadFormInitialRequestAction extends Action {
    payload: {
        moduleId: string;
    };
}

export function loadFormInitialRequest(moduleId: string): LoadFormInitialRequestAction {
    return {
        type: ProductsExportActions.LOAD_FORM_INITIAL_REQUEST,
        payload: {
            moduleId,
        },
    };
}

export interface LoadFormInitialSuccessAction extends Action {
    payload: {
        data: ProductsExportFormInitial;
    };
}

export function loadFormInitialSuccess(data: ProductsExportFormInitial): LoadFormInitialSuccessAction {
    return {
        type: ProductsExportActions.LOAD_FORM_INITIAL_SUCCESS,
        payload: {
            data,
        },
    };
}

export interface LoadFormInitialFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadFormInitialFailure(error: AppError): LoadFormInitialFailureAction {
    return {
        type: ProductsExportActions.LOAD_FORM_INITIAL_FAILURE,
        payload: {
            error,
        },
    };
}
