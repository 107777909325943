import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';
import { ShortListApi } from './api/short-list.api';
import { WatchlistGroupApi } from './api/watchlist-group.api';
import { WatchlistApi } from './api/watchlist.api';
import { ShortlistService } from './services/shortlist.service';
import { WatchlistService } from './services/watchlist.service';

@NgModule({
    imports: [
        CommonModule,
        CommonModule,
        RouterModule,
    ],
    declarations: [],
    providers: [WatchlistService, WatchlistApi, WatchlistGroupApi, ShortListApi, ShortlistService],
})
export class WatchlistSharedModule {
}
