import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DynamicChartRequestData, ExtendedDynamicChartDataResponse } from '../../shared/models/dynamic-chart.model';
import { AuthStoreService } from '../services/auth-store.service';
import { ConfigurationService } from '../services/configuration.service';
import { LogglyLoggerService } from '../services/loggly-logger.service';
import { DerifinApi } from './derifin.api';
import { Observable } from 'rxjs';

@Injectable()
export class DerichartApi extends DerifinApi {
    constructor(http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, configService: ConfigurationService) {
        super('m/chart', configService.configuration.environment.id, http, authStoreService, loggerService, configService);
    }

    public buildChart(body: DynamicChartRequestData): Observable<ExtendedDynamicChartDataResponse> {
        return this.post('build', {...body}).pipe(
            map((res) => res.data),
        );
    }
}
