import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef, ViewEncapsulation } from '@angular/core';
import { BubbleChartComponent, ChartCommonModule, AxesModule } from '@swimlane/ngx-charts';
import { ChartBubbleSeriesSelectableComponent } from './chart-bubble-series-selectable.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-charts-selectable-bubble-chart',
    template: `
        <ngx-charts-chart
            [view]="[width, height]"
            [showLegend]="legend"
            [activeEntries]="activeEntries"
            [legendOptions]="legendOptions"
            [animations]="animations"
            (legendLabelClick)="onClick($event)"
            (legendLabelActivate)="onActivate($event)"
            (legendLabelDeactivate)="onDeactivate($event)">
            <svg:defs>
                <svg:clipPath [attr.id]="clipPathId">
                    <svg:rect
                        [attr.width]="dims.width + 10"
                        [attr.height]="dims.height + 10"
                        [attr.transform]="'translate(-5, -5)'"/>
                </svg:clipPath>
            </svg:defs>
            <svg:g [attr.transform]="transform" class="bubble-chart chart">
                <svg:g ngx-charts-x-axis
                       *ngIf="xAxis"
                       [showGridLines]="showGridLines"
                       [dims]="dims"
                       [xScale]="xScale"
                       [showLabel]="showXAxisLabel"
                       [labelText]="xAxisLabel"
                       [tickFormatting]="xAxisTickFormatting"
                       [ticks]="xAxisTicks"
                       (dimensionsChanged)="updateXAxisHeight($event)"/>
                <svg:g ngx-charts-y-axis
                       *ngIf="yAxis"
                       [showGridLines]="showGridLines"
                       [yScale]="yScale"
                       [dims]="dims"
                       [showLabel]="showYAxisLabel"
                       [labelText]="yAxisLabel"
                       [tickFormatting]="yAxisTickFormatting"
                       [ticks]="yAxisTicks"
                       (dimensionsChanged)="updateYAxisWidth($event)"/>
                <svg:rect
                    class="bubble-chart-area"
                    x="0"
                    y="0"
                    [attr.width]="dims.width"
                    [attr.height]="dims.height"
                    style="fill: rgb(255, 0, 0); opacity: 0; cursor: 'auto';"
                    (mouseenter)="deactivateAll()"
                />
                <svg:g [attr.clip-path]="clipPath">
                    <svg:g *ngFor="let series of data; trackBy:trackBy" [@animationState]="'active'">
                        <svg:g app-charts-selectable-bubble-series
                               [xScale]="xScale"
                               [yScale]="yScale"
                               [rScale]="rScale"
                               [xScaleType]="xScaleType"
                               [yScaleType]="yScaleType"
                               [xAxisLabel]="xAxisLabel"
                               [yAxisLabel]="yAxisLabel"
                               [colors]="colors"
                               [data]="series"
                               [activeEntries]="activeEntries"
                               [selectedEntries]="selectedEntries"
                               [tooltipDisabled]="false"
                               [tooltipTemplate]="tooltipTemplate"
                               (select)="onClick($event, series)"
                               (activate)="onActivate($event)"
                               (deactivate)="onDeactivate($event)"/>
                    </svg:g>
                </svg:g>
            </svg:g>
        </ngx-charts-chart>
    `,
    styleUrls: ['./chart-bubble-selectable.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('animationState', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'scale(0)',
                }),
                animate(250, style({ opacity: 1, transform: 'scale(1)' })),
            ]),
        ]),
    ],
    standalone: true,
    imports: [ChartCommonModule, NgIf, AxesModule, NgFor, ChartBubbleSeriesSelectableComponent]
})
export class ChartBubbleSelectableComponent extends BubbleChartComponent {
    @Input() public selectedEntries: any[];

    public trackBy(index, item): string {
        return item.name;
    }
}
