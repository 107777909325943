import {Action} from '@ngrx/store';
import {FileListAdministrationActionTypes, LoadDataSuccessAction, SaveDataSuccessAction} from '../actions/file-list-administration.actions';
import {FileListAdministrationState} from '../state/file-list-administration.state';

export const initialState: FileListAdministrationState = {
    data: null,

    dataSavedSuccessfully: null,

    errorData: null,
    errorSaveData: null,

};

export function reducer(state: FileListAdministrationState = initialState, action: Action): FileListAdministrationState {
    switch (action.type) {
        case FileListAdministrationActionTypes.LOAD_DATA_REQUEST:
            return {
                ...state,
                data: null,
                dataSavedSuccessfully: null,
                errorData: null,
                errorSaveData: null,
            };
        case FileListAdministrationActionTypes.LOAD_DATA_SUCCESS:
            const loadDataSuccessAction = action as LoadDataSuccessAction;
            return {
                ...state,
                data: loadDataSuccessAction.payload.data,
                dataSavedSuccessfully: null,
                errorData: null,
                errorSaveData: null,
            };
        case FileListAdministrationActionTypes.SAVE_DATA_REQUEST:
            return {
                ...state,
                dataSavedSuccessfully: null,
                errorData: null,
                errorSaveData: null,
            };
        case FileListAdministrationActionTypes.SAVE_DATA_SUCCESS:
            const saveDataSuccessAction = action as SaveDataSuccessAction;
            return {
                ...state,
                data: saveDataSuccessAction.payload.data,
                dataSavedSuccessfully: true,
                errorData: null,
                errorSaveData: null,
            };
        default:
            return state;
    }
}
