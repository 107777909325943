<form [formGroup]="filterForm" *ngIf="filterForm">
    <div class="risk-manager-general-settings">
        <h3 class="risk-manager-general-settings__heading" *ngIf="controls && controls.length > 0">
            {{ 'riskManager.generalSettings' | transloco }}
        </h3>
        <div class="risk-manager-general-settings__content">
            <div class="risk-manager-general-settings__entry" *ngFor="let entry of controls">
                <span class="risk-manager-general-settings__entry-title">{{entry.title}}</span>
                <div class="risk-manager-general-settings__entry-content">
                    <div class="form-group">
                        <app-filter-control-container
                            [config]="entry.control"
                            [disableControl]="entry.control.disabled"
                            [values]="{}"
                            [formControlName]="entry.control.path"></app-filter-control-container>
                    </div>
                    <span class="risk-manager-general-settings__entry-description">{{entry.description}}</span>
                </div>
            </div>
            <div class="risk-manager-general-settings__entry">
                <span class="risk-manager-general-settings__entry-title"></span>
                <div class="risk-manager-general-settings__entry-content">
                    <button
                        type="button"
                        class="btn btn-primary risk-manager-general-settings__action"
                        (click)="onSaveWatcher($event)">
                        Speichern &rsaquo;
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>

