import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router, RouterLinkActive, RouterLink } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, map, startWith, tap } from 'rxjs/operators';
import { MenuItem } from '../../../core/models/menu.model';
import { SubmenuItemComponent } from '../submenu-item/submenu-item.component';
import { IconComponent } from '../icon/icon.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    animations: [
        trigger('submenuState', [
            state('void', style({
                height: 0,
                opacity: 0
            })),
            state('subMenu', style({
                height: '*',
                marginTop: '20px',
                opacity: 1
            })),
            transition('void <=> *', animate('250ms ease-out'))
        ])
    ],
    standalone: true,
    imports: [CommonModule, RouterLinkActive, RouterLink, IconComponent, SubmenuItemComponent]
})
export class MenuItemComponent implements OnInit, OnDestroy {

    @Input() public item: MenuItem;
    @Input() public active: boolean;
    @Input() public insideInlineMenu = false;
    @Input() public currentDepth: boolean;

    @Output() public submenuItemClicked: EventEmitter<number> = new EventEmitter();
    @Output() public menuItemClick: EventEmitter<MouseEvent> = new EventEmitter();

    public hasActiveSubmenu$: Observable<boolean>;
    public hasActiveSubmenu = false;
    private subscription: Subscription;

    constructor(private router: Router) {
        this.hasActiveSubmenu$ = this.router.events.pipe(
            filter((s) => s instanceof NavigationEnd),
            startWith(null as NavigationEnd),
            map(() => {
                return this.item.hasSubmenu && this.item.children && this.item.children.reduce((activeSubmenu, item) => {
                    return activeSubmenu || (item.link && this.router.isActive(item.link.join('/'), false));
                }, false);
            }),
            tap((active) => {
                this.hasActiveSubmenu = active;
            })
        );
    }

    public ngOnInit() {
        this.subscription = this.hasActiveSubmenu$.subscribe();
    }

    public onSubmenuItemClicked(subitemIndex: number): void {
        this.submenuItemClicked.next(subitemIndex);
    }

    public handleMenuClick(): void {
        this.menuItemClick.next();
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
