import { createReducer, on } from "@ngrx/store";
import { sortBy } from "lodash";
import { InstrumentType, NotifyOnProductActivationState } from "../../models/search.model";
import * as SearchActions from "../actions/search.actions";
import { SearchState } from "../state/search.state";

export const initialState: SearchState = {
    term: '',
    products: [],
    searching: false,
    overlayOpen: false,
    notifyOnProductActivation: NotifyOnProductActivationState.Request
};

export const reducer = createReducer(initialState,
    on(SearchActions.loadSolrResults, (state, { term }): SearchState =>
        ({ ...state, term, searching: true })
    ),
    on(SearchActions.prepareSolrResults, (state, { results }): SearchState => 
        ({ ...state,
            products: sortBy(
                results.grouped.instrumentType.groups.map((group) => ({
                    instrumentType: group.groupValue,
                    results: group.doclist.docs.map((result) => ({
                        ...result,
                        link: ['/app', {outlets: {overlay: ['product-details', result.id]}}]
                    }))
                })),
                (group) => Object.values(InstrumentType).indexOf(group.instrumentType)
            ),
            searching: false,
            overlayOpen: true,
            notifyOnProductActivation: NotifyOnProductActivationState.Request
        })
    ),
    on(SearchActions.loadSolrFundResults, (state, { term }): SearchState =>
        ({ ...state, term: term.split(' ').join('+'), searching: true })
    ),
    on(SearchActions.prepareSolrFundResults, (state, { results, dqs }): SearchState =>
        ({ ...state,
            products: results.response.docs.length ? [
                {
                    instrumentType: InstrumentType.Fund,
                    results: results.response.docs.filter((doc) => 
                        !dqs.includes(doc.id)
                    )
                }
            ] : [],
            searching: false,
            overlayOpen: true
        })
    ),
    on(SearchActions.resetSearch, (state): SearchState =>
        ({ ...state, ...initialState })
    ),
    on(SearchActions.openOverlay, (state): SearchState =>
        ({ ...state, overlayOpen: true })
    ),
    on(SearchActions.closeOverlay, (state): SearchState =>
        ({ ...state, overlayOpen: false })
    ),
    on(SearchActions.notifyOnProductActivation, (state): SearchState =>
        ({ ...state, notifyOnProductActivation: NotifyOnProductActivationState.Loading })
    ),
    on(SearchActions.notifyOnProductActivationRequested, (state): SearchState =>
        ({ ...state, notifyOnProductActivation: NotifyOnProductActivationState.Requested })
    )
);
