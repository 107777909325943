<ng-container *ngIf="pageContext$ | async">
    <div class="card banners" *ngIf="ad">
        <ng-container *ngIf="ad.banner">
            <ng-container *ngIf="ad.banner.fileType === 'IMAGE'">
                <a
                    *ngIf="(authUserId$ | async) as userId"
                    class="tw-flex tw-justify-center"
                    [href]="link(ad.targetLinkUrl, environment, organization, userId)"
                    target="_blank">
                    <img [alt]="ad.banner.alternativeText" [src]="resolveImage(ad.banner.fileId)">
                </a>
            </ng-container>
        </ng-container>
    </div>    
</ng-container>
