/**
 * Created by Alex Klein <florian@rocketloop.de> on 08/14/17.
 */

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DerifinApi } from '../../../../core/api/derifin.api';
import { ApiResponse } from '../../../../core/api/models/api.model';
import { FormValues } from '../../../../core/models/form.model';
import { AuthStoreService } from '../../../../core/services/auth-store.service';
import { ConfigurationService } from '../../../../core/services/configuration.service';
import { LogglyLoggerService } from '../../../../core/services/loggly-logger.service';
import { ProductActivation, ProductNotes } from '../models/product-shared.model';

/**
 * API abstraction layer for the product API
 */
@Injectable()
export class ProductSharedApi extends DerifinApi {

    constructor(http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, appConfigService: ConfigurationService) {
        super('m/product', appConfigService.configuration.environment.id, http, authStoreService, loggerService, appConfigService);
    }

    /**
     * Update the product activation for specified product type
     * @param productDq
     * @param issueSurcharge
     * @param activated
     * @param price
     * @returns {Observable<ProductActivation>}
     */
    public updateProductActivation(productDq: string, issueSurcharge: string, activated: boolean, price: string): Observable<ProductActivation> {
        return this.put(`primary/activation/${productDq}`, {
            issueSurcharge,
            activated,
            price
        }).pipe(
            map((response: ApiResponse<ProductActivation>) => response.data),
        );
    }

    /**
     * Update the product activation for specified product type
     * @param productDq
     * @param comment
     * @param promoted
     * @returns {Observable<ProductActivation>}
     */
    public updateProductNotes(productDq: string, comment: string, promoted: boolean): Observable<ProductNotes> {
        return this.put(`product/${productDq}/note`, {
            comment,
            promoted,
        }).pipe(
            map((response: ApiResponse<ProductNotes>) => response.data),
        );
    }
}
