import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Entity } from '../../models/entities.model';
import { EntityTableCellComponent } from '../entity-table-component-cell/entity-table-component-cell.component';
import { get, set } from 'lodash';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-entity-table-text-field-cell',
    templateUrl: './entity-table-text-field-cell.component.html',
    styleUrls: ['./entity-table-text-field-cell.component.scss'],
    standalone: true,
    imports: [FormsModule]
})
export class EntityTableTextFieldCellComponent implements OnInit, OnChanges, EntityTableCellComponent {

    @HostBinding('class.entity-table-text-field-cell') public textFieldEntityTableCellClass = true;

    @Input() public product: Entity;
    @Input() public options: any;
    @Input() public path: string;

    @Output() public event: EventEmitter<any> = new EventEmitter();

    public currentValue: string = null;

    constructor() { }

    ngOnInit(): void {
        this.currentValue = get(this.product, this.path);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.product || changes.path) {
          this.currentValue = get(this.product, this.path);
        }
    }

    onClickSelect(event): void {
        event.stopImmediatePropagation();
        event.stopPropagation();
    }

    onTextFieldChange(value: any): void {
      const event = {
          type: this.options.type,
          payload: value,
      };

      this.currentValue = value;
      set(this.product, this.path, value);

      this.event.next(event);
  }
}
