<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'boxes'">
        <div class="spinner-wrapper">
            <div *ngFor="let iteration of range(8)" class="spinner__box"></div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'small-boxes'">
        <div class="spinner-wrapper">
            <div *ngFor="let iteration of range(4)" class="spinner__box"></div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'circle-box'">
        <div class="spinner--circle__box">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'text'">
        Loading...
    </ng-container>
</ng-container>


