import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { truthy } from '../../shared/helpers/general.helper';

import {
    createMenuFromMenuItemConfig, findMenuItemForTargetType,
    findParentMenuItemForMenuItemTargetId,
    findParentMenuItemForMenuItemTargetType, findParentMenuItemForMenuTarget
} from '../helpers/menu.helper';
import { MenuItemConfig, MenuItemTargetConfig } from '../models/config.model';
import { Menu } from '../models/menu.model';
import { AppState } from '../store/state/index.state';

/**
 * Service responsible for UI state and interactions
 */
@Injectable()
export class MenuService {
    constructor(private store: Store<AppState>) {
    }

    /**
     * Check whether the selection of tmc is possible
     * @returns {Observable<boolean>}
     */
    public isTmcSelectable(): Observable<boolean> {
        return this.store.pipe(
            select((state: AppState) => state.config.tmcSelectable),
        );
    }

    /**
     * Get the menu config
     * @returns {Observable<MenuItemConfig>}
     */
    public getMenuConfig(): Observable<MenuItemConfig> {
        return this.store.pipe(
            select((state: AppState) => state.config.menu),
        );
    }

    /**
     * Get the menu visibility
     * @returns {Observable<boolean>}
     */
    public getMenu(): Observable<Menu> {
        return this.getMenuConfig().pipe(map((item) => createMenuFromMenuItemConfig(item)));
    }

    public getSubmenuForTargetType(...targetTypes: string[]): Observable<Menu> {
        return this.store.pipe(
            select((state: AppState) => state.config.menu),
            filter(truthy),
            map((root) => {
                for (const targetType of targetTypes) {
                    const menu = findParentMenuItemForMenuItemTargetType(targetType, root);

                    if (menu) {
                        return menu;
                    }
                }
                return null;
            }),
            map((item) => createMenuFromMenuItemConfig(item)),
        );
    }

    public getSubmenuForTargetId(targetId: string): Observable<Menu> {
        return this.store.pipe(
            select((state: AppState) => state.config.menu),
            filter(truthy),
            map((root) => findParentMenuItemForMenuItemTargetId(targetId, root)),
            map((item) => createMenuFromMenuItemConfig(item)),
        );
    }

    public getMenuConfigForTargetType(targetTypes: string[]): Observable<MenuItemConfig> {
        return this.store.pipe(
            select((state: AppState) => state.config.menu),
            filter(truthy),
            map((root) => findMenuItemForTargetType(targetTypes, root)),
        )
    }

    public getSubmenuForMenuTarget(menuTarget: MenuItemTargetConfig): Observable<Menu> {
        return this.store.pipe(
            select((state: AppState) => state.config.menu),
            filter(truthy),
            map((root) => findParentMenuItemForMenuTarget(menuTarget, root)),
            map((item) => createMenuFromMenuItemConfig(item)),
        );
    }
}
