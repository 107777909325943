<div class="tw-flex tw-bg-primary-bg tw-fixed tw-inset-0 tw-z-1000">
    <div class="tw-absolute tw-w-full">
        <div class="3xl:tw-container tw-w-full tw-mx-auto tw-px-8 tw-flex tw-items-center">
            <div class="tw-py-2.5 dark:tw-py-3.5 tw-w-full tw-flex tw-justify-between tw-items-center">
                <div class="tw-min-h-[38px] tw-h-logo"></div>
                <button
                    type="button"
                    class="tw-block tw-w-9.5 tw-h-9.5 tw-overflow-hidden tw-rounded tw-text-black hover:tw-text-primary-base focus-visible:tw-outline focus-visible:tw-outline-3 focus-visible:tw-outline-primary-focus dark:tw-text-white dark:hover:tw-opacity-60"
                    (click)="onCloseClick()">
                    <span class="material-symbols-rounded tw-font-light tw-text-5xl tw-relative tw-bottom-[4px]">close</span>
                </button>
            </div>
        </div>
    </div>
    <div class="tw-flex tw-flex-auto tw-flex-col tw-justify-center tw-items-center">
        <div
            class="tw-flex tw-justify-center tw-gap-x-2 2xl:tw-gap-x-5"
            *ngFor="let row of rows; let rowIndex = index;"
            [@rowState]="(currentDepth && rowIndex + 1) === 1 ? 'stale' : 'expanded'">
            <app-menu-item
                *ngFor="let item of row; let itemIndex = index;"
                [item]="item"
                [active]="isInCurrentPath(itemIndex, rowIndex)"
                [currentDepth]="currentDepth === rowIndex + 1"
                (menuItemClick)="onItemClick(itemIndex, rowIndex)" />
        </div>
    </div>
</div>
