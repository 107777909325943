import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SolrFundResultsResponse, SolrResultsResponse } from "../models/search.model";

@Injectable({ providedIn: 'root' })
export class SearchApi {

    constructor(public http: HttpClient) { }

    public getSolrResults(term: string): Observable<SolrResultsResponse> {
        return this.http.get<SolrResultsResponse>(`search/${term}`).pipe(
            map((response) => response)
        );
    }

    public getSolrFundResults(term: string): Observable<SolrFundResultsResponse> {
        return this.http.get<SolrFundResultsResponse>(`fund-search/text:${term}`).pipe(
            map((response) => response)
        );
    }
}
