/**
 * Created by Alex Klein <alex@rocketloop.de> on 12/11/2017.
 */

import { Action } from '@ngrx/store';
import { type } from '../../../../core/store/util';

export const WatchlistDetailsActionTypes = {
    ADD_WATCHLIST_PRODUCT_TO_SELECTION: type('WATCHLIST_DETAILS:ADD_WATCHLIST_PRODUCT_TO_SELECTION'),
    REMOVE_WATCHLIST_PRODUCT_FROM_SELECTION: type('WATCHLIST_DETAILS:REMOVE_WATCHLIST_PRODUCT_FROM_SELECTION'),

    ADD_MANY_WATCHLIST_PRODUCTS_TO_SELECTION: type('WATCHLIST_DETAILS:ADD_MANY_WATCHLIST_PRODUCTS_TO_SELECTION'),
    REMOVE_MANY_WATCHLIST_PRODUCTS_FROM_SELECTION: type('WATCHLIST_DETAILS:REMOVE_MANY_WATCHLIST_PRODUCTS_FROM_SELECTION'),

    REMOVE_ALL_WATCHLIST_PRODUCTS_FROM_SELECTION: type('REMOVE_ALL_WATCHLIST_PRODUCTS_FROM_SELECTION'),
};

export interface AddWatchlistProductToSelectionAction extends Action {
    payload: {
        watchlistId: string;
        entityType: string;
        productId: string;
    };
}

export function addWatchlistProductToSelectionAction(
    watchlistId: string,
    entityType: string,
    productId: string,
): AddWatchlistProductToSelectionAction {
    return {
        type: WatchlistDetailsActionTypes.ADD_WATCHLIST_PRODUCT_TO_SELECTION,
        payload: {
            watchlistId,
            entityType,
            productId,
        },
    };
}

export interface RemoveWatchlistProductFromSelectionAction extends Action {
    payload: {
        watchlistId: string;
        entityType: string;
        productId: string;
    };
}

export function removeWatchlistProductFromSelection(
    watchlistId: string,
    entityType: string,
    productId: string,
): RemoveWatchlistProductFromSelectionAction {
    return {
        type: WatchlistDetailsActionTypes.REMOVE_WATCHLIST_PRODUCT_FROM_SELECTION,
        payload: {
            watchlistId,
            entityType,
            productId,
        },
    };
}

export interface AddManyWatchlistProductsToSelectionAction extends Action {
    payload: {
        watchlistId: string;
        entityType: string;
        productIds: string[];
    };
}

export function addManyWatchlistProductsToSelection(
    watchlistId: string,
    entityType: string,
    productIds: string[],
): AddManyWatchlistProductsToSelectionAction {
    return {
        type: WatchlistDetailsActionTypes.ADD_MANY_WATCHLIST_PRODUCTS_TO_SELECTION,
        payload: {
            watchlistId,
            entityType,
            productIds,
        },
    };
}

export interface RemoveManyWatchlistProductsFromSelectionAction extends Action {
    payload: {
        watchlistId: string;
        entityType: string;
        productIds?: string[];
    };
}

export function removeManyWatchlistProductsFromSelection(
    watchlistId: string,
    entityType: string,
    productIds?: string[],
): RemoveManyWatchlistProductsFromSelectionAction {
    return {
        type: WatchlistDetailsActionTypes.REMOVE_MANY_WATCHLIST_PRODUCTS_FROM_SELECTION,
        payload: {
            watchlistId,
            entityType,
            productIds,
        },
    };
}

export interface RemoveAllWatchlistProductsFromSelectionAction extends Action {
    payload: {
        watchlistId: string,
    };
}

export function removeAllWatchlistProductsFromSelection(watchlistId: string): RemoveAllWatchlistProductsFromSelectionAction {
    return {
        type: WatchlistDetailsActionTypes.REMOVE_ALL_WATCHLIST_PRODUCTS_FROM_SELECTION,
        payload: {
            watchlistId,
        },
    };
}
