import { Action, createAction, props } from '@ngrx/store';
import { AppError } from '../../errors/base.errors';
import { AdditionalWidgetData } from '../state/widgets-data.state';
import { type } from '../util';
import { DynamicChartRequestData, ExtendedDynamicChartDataResponse } from '../../../shared/models/dynamic-chart.model';
import {
    TopUnderlyingsDataResponse,
    TopUnderlyingsFilter
} from '../../../shared/widgets/legacy/entity-widget-top-underlyings/entity-widget-top-underlyings.component';
import { StringObject } from '../../models/generic.model';

export const WidgetDataActionTypes = {
    UPDATE_WIDGETS_ADDITIONAL_DATA: type('WIDGET_DATA:UPDATE_ADDITIONAL_DATA'),

    DYNAMIC_CHART_LOAD_GRAPH_DATA: type('WIDGET_DATA:DYNAMIC_CHART_LOAD_GRAPH_DATA'),
    DYNAMIC_CHART_LOAD_GRAPH_DATA_REQUEST: type('WIDGET_DATA:DYNAMIC_CHART_LOAD_GRAPH_DATA_REQUEST'),
    DYNAMIC_CHART_LOAD_GRAPH_DATA_SUCCESS: type('WIDGET_DATA:DYNAMIC_CHART_LOAD_GRAPH_DATA_SUCCESS'),
    DYNAMIC_CHART_LOAD_GRAPH_DATA_FAILURE: type('WIDGET_DATA:DYNAMIC_CHART_LOAD_GRAPH_DATA_FAILURE'),

    TOP_UNDERLYINGS_LOAD_DATA: type('WIDGET_DATA:TOP_UNDERLYINGS_LOAD_DATA'),
    TOP_UNDERLYINGS_LOAD_DATA_REQUEST: type('WIDGET_DATA:TOP_UNDERLYINGS_LOAD_DATA_REQUEST'),
    TOP_UNDERLYINGS_LOAD_DATA_SUCCESS: type('WIDGET_DATA:TOP_UNDERLYINGS_LOAD_DATA_SUCCESS'),
    TOP_UNDERLYINGS_LOAD_DATA_FAILURE: type('WIDGET_DATA:TOP_UNDERLYINGS_LOAD_DATA_FAILURE'),

    CHECK_EQUIVALENCE: type('WIDGET_DATA:CHECK_EQUIVALENCE'),
    CHECK_EQUIVALENCE_REQUEST: type('WIDGET_DATA:CHECK_EQUIVALENCE_REQUEST'),
    CHECK_EQUIVALENCE_SUCCESS: type('WIDGET_DATA:CHECK_EQUIVALENCE_SUCCESS'),
    CHECK_EQUIVALENCE_FAILURE: type('WIDGET_DATA:CHECK_EQUIVALENCE_FAILURE'),
};

export interface UpdateWidgetsAdditionalDataAction extends Action {
    payload: {
        data: AdditionalWidgetData;
        path: string;
    };
}

export function updateWidgetAdditionalData(data: AdditionalWidgetData, path: string): UpdateWidgetsAdditionalDataAction {
    return {
        type: WidgetDataActionTypes.UPDATE_WIDGETS_ADDITIONAL_DATA,
        payload: {
            data,
            path,
        },
    };
}

export interface DynamicChartLoadGraphDataRequestAction extends Action {
    payload: {
        request: DynamicChartRequestData;
        path: string;
    };
}

export function dynamicChartLoadGraphDataRequest(request: DynamicChartRequestData, path: string): DynamicChartLoadGraphDataRequestAction {
    return {
        type: WidgetDataActionTypes.DYNAMIC_CHART_LOAD_GRAPH_DATA_REQUEST,
        payload: {
            request,
            path,
        },
    };
}

export interface DynamicChartLoadGraphDataSuccessAction extends Action {
    payload: {
        request: any;
        path: string;
        data: ExtendedDynamicChartDataResponse;
    };
}

export function dynamicChartLoadGraphDataSuccess(data: ExtendedDynamicChartDataResponse, request: any, path: string): DynamicChartLoadGraphDataSuccessAction {
    return {
        type: WidgetDataActionTypes.DYNAMIC_CHART_LOAD_GRAPH_DATA_SUCCESS,
        payload: {
            request,
            path,
            data,
        },
    };
}

export interface DynamicChartLoadGraphDataFailureAction extends Action {
    payload: {
        request: any;
        path: string;
        error: AppError;
    };
}

export function dynamicChartLoadGraphDataFailure(error: AppError, request: any, path: string): DynamicChartLoadGraphDataFailureAction {
    return {
        type: WidgetDataActionTypes.DYNAMIC_CHART_LOAD_GRAPH_DATA_FAILURE,
        payload: {
            request,
            path,
            error,
        },
    };
}

// ###################

export interface TopUnderlyingsLoadDataRequestAction extends Action {
    payload: {
        filter: TopUnderlyingsFilter;
        path: string;
    };
}

export function topUnderlyingsLoadDataRequest(filter: TopUnderlyingsFilter, path: string): TopUnderlyingsLoadDataRequestAction {
    return {
        type: WidgetDataActionTypes.TOP_UNDERLYINGS_LOAD_DATA_REQUEST,
        payload: {
            filter,
            path
        },
    };
}

export interface TopUnderlyingsLoadDataSuccessAction extends Action {
    payload: {
        filter: TopUnderlyingsFilter;
        path: string;
        data: TopUnderlyingsDataResponse;
    };
}

export function topUnderlyingsLoadDataSuccess(data: TopUnderlyingsDataResponse, filter: TopUnderlyingsFilter, path: string): TopUnderlyingsLoadDataSuccessAction {
    return {
        type: WidgetDataActionTypes.TOP_UNDERLYINGS_LOAD_DATA_SUCCESS,
        payload: {
            filter,
            path,
            data,
        },
    };
}

export interface TopUnderlyingsLoadDataFailureAction extends Action {
    payload: {
        filter: TopUnderlyingsFilter;
        path: string;
        error: AppError;
    };
}

export function topUnderlyingsLoadDataFailure(error: AppError, filter: TopUnderlyingsFilter, path: string): TopUnderlyingsLoadDataFailureAction {
    return {
        type: WidgetDataActionTypes.TOP_UNDERLYINGS_LOAD_DATA_FAILURE,
        payload: {
            filter,
            path,
            error,
        },
    };
}

// ######################


export interface CheckEquivalenceRequestAction extends Action {
    payload: {
        deriBwIds: string[];
        path: string;
        entityId: string;
        label: string;
        watchlistId: string;
    }
}

export function checkEquivalenceRequest(
    deriBwIds: string[],
    entityId: string,
    path: string,
    label: string,
    watchlistId: string
): CheckEquivalenceRequestAction {
    return {
        type: WidgetDataActionTypes.CHECK_EQUIVALENCE_REQUEST,
        payload: {
            deriBwIds,
            path,
            entityId,
            label,
            watchlistId
        }
    };
}


export interface CheckEquivalenceSuccessAction extends Action {
    payload: {
        path: string;
        data: any;
        entityId: string;
    }
}

export function checkEquivalenceSuccess(data: any, entityId: string, path: string): CheckEquivalenceSuccessAction {
    return {
        type: WidgetDataActionTypes.CHECK_EQUIVALENCE_SUCCESS,
        payload: {
            data,
            path,
            entityId,
        }
    };
}

export interface CheckEquivalenceFailureAction extends Action {
    payload: {
        path: string;
        entityId: string;
        error: AppError;
    }
}


export function checkEquivalenceFailure(error: AppError, entityId: string, path: string): CheckEquivalenceFailureAction {
    return {
        type: WidgetDataActionTypes.CHECK_EQUIVALENCE_FAILURE,
        payload: {
            error,
            path,
            entityId,
        }
    };
}

export const updateEquivalenceComments = createAction(
    '[Widget Data] Update Equivalence Comments',
    props<{
        productDq: string,
        comments: StringObject
    }>()
);
