import { Directive, ElementRef, HostBinding, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { grayscaleIE10 } from '../../core/helpers/colors.helper';
import { ImageReferenceResolverService } from '../../core/services/image-reference-resolver.service';

@Directive({
    selector: '[appGrayscale]',
    standalone: true
})
export class GrayscaleDirective implements OnChanges, OnInit {

    @Input() public imageUrl: string;
    @Input() public asBackground = false;
    @Input() public defaultGrayscale = false;
    @Input() public hoverSwitch = true;

    public currentImage: string;

    public imageColor: string;
    public imageBW: string;

    @HostBinding('class.grayscale') get grayscale() { return this.defaultGrayscale; }
    @HostBinding('class.disable-hover') get switchOnHover() { return this.hoverSwitch; }
    @HostBinding('class.grayscale--on-hover') get grayScaleOnHover() {
        return !this.defaultGrayscale && this.hoverSwitch;
    }

    @HostBinding('style.background-image')
    get bgImage() {
        return this.asBackground ? this.domSanitizer.bypassSecurityTrustStyle(`url(${this.currentImage})`) : '';
    }

    constructor(
        private el: ElementRef,
        private imageReferenceResolverService: ImageReferenceResolverService,
        private domSanitizer: DomSanitizer
    ) { }

    public updateImageSource() {
        if (!this.asBackground && this.currentImage) {
            this.el.nativeElement.src = this.currentImage;
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.imageUrl) {
            this.setupImages();
        }
    }

    public ngOnInit() {
        if (this.imageUrl) { this.setupImages(); }
    }

    public setupImages() {
        this.imageColor = this.imageUrl;
        this.imageBW = this.imageUrl;
        this.currentImage = this.imageUrl;

        this.updateImageSource();
    }

    @HostListener('mouseover')
    public onMouseEnter() {
        if (this.hoverSwitch) {
            this.currentImage = this.defaultGrayscale ? this.imageColor : this.imageBW;
            this.updateImageSource();
        }
    }

    @HostListener('mouseout')
    public onMouseLeave() {
        if (this.hoverSwitch) {
            this.currentImage = this.defaultGrayscale ? this.imageBW : this.imageColor;
            this.updateImageSource();
        }
    }

}
