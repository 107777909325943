import { createAction, props } from "@ngrx/store";
import { AppError } from "../../../../core/errors/base.errors";
import { FormValues } from "../../../../core/models/form.model";
import { Audit } from "../state/audits.state";

export const loadAuditsList = createAction(
    '[Audits] Load Audits List',
    props<{ moduleId: string }>()
);

export const setAuditsList = createAction(
    '[Audits] Set Audits List',
    props<{ list: Array<Audit> }>()
);

export const exportAudit = createAction(
    '[Audits] Export Audit',
    props<{ auditId: string, formValues: FormValues }>()
);

export const exportAuditSuccess = createAction(
    '[Audits] Export Audit Success',
    props<{ link: string }>()
);

export const exportAuditFailure = createAction(
    '[Audits] Export Audit Failure',
    props<{
        payload: {
            error: AppError
    }}>()
);
