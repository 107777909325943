import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EntityTableCellComponent } from '../entity-table-component-cell/entity-table-component-cell.component';
import { Entity } from '../../models/entities.model';
import { get, set } from 'lodash';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-entity-table-textarea-cell',
    templateUrl: './entity-table-textarea-cell.component.html',
    styleUrls: ['./entity-table-textarea-cell.component.scss'],
    standalone: true,
    imports: [FormsModule]
})
export class EntityTableTextareaCellComponent implements OnInit, OnChanges, EntityTableCellComponent {

    @HostBinding('class.entity-table-textarea-cell') public textareaEntityTableCellClass = true;

    @Input() public product: Entity;
    @Input() public options: any;
    @Input() public path: string;

    @Output() public event: EventEmitter<any> = new EventEmitter();

    public currentValue: string = null;

    constructor() {

    }

    public ngOnInit() {
        this.currentValue = get(this.product, this.path);

        const event = {
            type: this.options.type,
            payload: this.currentValue,
        };

        this.event.next(event);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.product || changes.path) {
            this.currentValue = get(this.product, this.path);
        }
    }

    public onClickSelect(event) {
        event.stopImmediatePropagation();
        event.stopPropagation();
    }

    public onTextAreaChange(value: any) {
        const event = {
            type: this.options.type,
            payload: value,
        };

        this.currentValue = value;
        set(this.product, this.path, value);

        this.event.next(event);
    }
}
