<div
    appGrayscale
    [asBackground]="true"
    [imageUrl]="backgroundImage"
    [hoverSwitch]="colorMode === 'BW'"
    [defaultGrayscale]="colorMode === 'BW'"
    class="focus-list-widget__background">

    <div class="focus-list-widget__overlay"></div>
</div>

<div class="focus-list-widget__text" [innerHTML]="item.title | replaceLineBreaks"></div>
