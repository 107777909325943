import { createReducer, on } from "@ngrx/store";
import * as AuditsActions from "../actions/audits.actions";
import { AuditsState } from "../state/audits.state";

export const initialState: AuditsState  = {
    list: []
}

export const reducer = createReducer(initialState,
    on(AuditsActions.loadAuditsList, (state, { moduleId }): AuditsState =>  
        ({ ...state, moduleId })
    ),
    on(AuditsActions.setAuditsList, (state, { list }): AuditsState => 
        ({ ...state, list })
    )
);
