import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { filter, map, tap } from "rxjs/operators";
import { ScrollService } from "../../../../core/services/scroll.service";
import { TargetMarketActionTypes } from "../../../target-market/store/actions/target-market.actions";
import * as CustomerSelectionActions from "../actions/customer-selection.actions";
import * as CustomerSelectionSelectors from "../selectors/customer-selection.selectors";
import { CustomerTabConfig } from "../state/customer-selection.state";
import { ConfigActionTypes } from "../../../../core/store/actions/config.action";
import * as TargetMarketSelectors from "../../../target-market/store/selectors/target-market.selectors";
import * as ConfigSelectors from "../../../../core/store/selectors/config.selectors";

@Injectable()
export class CustomerSelectionEffects {

    loadTabList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                ConfigActionTypes.CONFIG_LOADED,
                TargetMarketActionTypes.LOAD_TARGET_MARKET_CRITERIA_STRUCTURE_SUCCEEDED
            ),
            concatLatestFrom(() => [
                this.store.select(ConfigSelectors.selectTmcSelectable).pipe(
                    filter((tmcSelectable) => tmcSelectable)
                ),
                this.store.select(TargetMarketSelectors.selectStructure).pipe(
                    filter((structure) => !!structure)
                )
            ]),
            map(([,, structure]) => {
                const tabList: CustomerTabConfig[] = [];

                if (structure.customer) {
                    tabList.push({ id: 'hal', label: 'targetMarket.customers'});
                }
                
                if (structure.custom) {
                    tabList.push({ id: 'own', label: 'targetMarket.ownCustomers' });

                    if (structure.custom.hasReferenceCriteria) {
                        tabList.push({ id: 'reference', label: 'targetMarket.referenceCustomers'});
                    }
                }
                
                return tabList;
            }),
            map((list: CustomerTabConfig[]) => CustomerSelectionActions.loadTabList({ list }))
        )
    });

    setTabSelected$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                CustomerSelectionActions.loadTabList,
                TargetMarketActionTypes.CLEAR_TEMP_TARGET_MARKET_CRITERIA
            ),
            concatLatestFrom(() => [
                this.store.select(CustomerSelectionSelectors.selectTabList),
                this.store.select(CustomerSelectionSelectors.selectCustomerTypeParam)
            ]),
            map(([, tabList, param]) => param ? param : tabList[0].id),
            map((selected) => CustomerSelectionActions.setTabSelected({ selected }))
        )
    });

    scrollToTop$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                TargetMarketActionTypes.ACTIVATE_TARGET_MARKET_CUSTOMER,
                TargetMarketActionTypes.ACTIVATE_TARGET_MARKET_CUSTOM_CUSTOMER,
                TargetMarketActionTypes.ACTIVATE_TARGET_MARKET_REFERENCE_CUSTOMER
            ),
            tap(() => this.scrollService.scroll(0))
        )
    }, { dispatch: false });

    constructor(
        private actions$: Actions,
        private store: Store,
        private scrollService: ScrollService
    ) { }
}
