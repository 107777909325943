import { AbstractControl, ValidatorFn } from '@angular/forms';
import { FormValidationRule, FormValidationRuleOptions } from '../models/form.model';
import { ObjectMaker } from '../helpers/form.helper';

export function startsWithValidator(configModel: FormValidationRuleOptions): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        if(configModel && configModel.value && control.value) {
            const startString = String(control.value).substr(0, configModel.value.length);

            if (startString != configModel.value){
                return ObjectMaker.toJson("startsWith", configModel, [control.value, configModel.value]);
            }
        }


        return ObjectMaker.null();
    }
}
