import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';

@Component({
    selector: 'app-checkbox-input',
    templateUrl: './checkbox-input.component.html',
    styleUrls: ['./checkbox-input.component.scss'],
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxInputComponent),
            multi: true,
        }, {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CheckboxInputComponent),
            multi: true,
        }],
    standalone: true
})
export class CheckboxInputComponent implements OnInit {
    @Input()
    public showSwitch = false;

    @Input()
    public label = '';

    public onChange: () => void;
    public onTouched: () => void;
    public value: boolean;
    public disabled: boolean;

    public ngOnInit() {
    }

    public writeValue(value: boolean): void {
        if (this.value !== value) {
            this.value = value;
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this.value);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return null;
    }

    public setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    public onCheckboxChange(event: Event) {
        event.preventDefault();
        this.value = !this.value;
        this.onChange();
    }
}
