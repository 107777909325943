import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isEqual } from 'lodash';
import { FormControlConfig, FormControlSelectOption } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';
import { MaxValidationDirective } from '../../directives/max-validation.directive';
import { MinValidationDirective } from '../../directives/min-validation.directive';
import { RangeFilterComponent } from '../forms/range-filter/range-filter.component';

/**
 * Filter range select component that is responsible for the range filter. Range filter includes an enabled status, min
 * value and max value
 */
@Component({
    selector: 'app-filter-range-select',
    templateUrl: './filter-range-select.component.html',
    styleUrls: ['./filter-range-select.component.scss'],
    standalone: true,
    imports: [RangeFilterComponent, MinValidationDirective, MaxValidationDirective]
})
export class FilterRangeSelectComponent implements OnInit, SingleValueFilterControl<any> {

    @Input() public config: FormControlConfig;

    @Input() public values: FormControlSelectOption[];

    @Input() public disabled = false;

    private _value: any;

    get value(): any {
        return this._value;
    }

    public onChange: () => void;

    public onTouched: () => void;

    constructor() {
    }

    public ngOnInit() {

    }

    /**
     * Writes the values of the range select filter
     * @param value
     */
    public writeValue(value: any): void {
        if (!value) {
            this._value = {enabled: false};
        } else if (!isEqual(this._value, value)) {
            this._value = value;
        }
    }

    /**
     * Checks if the specified value is valid
     * @param value
     * @returns {boolean}
     * @private
     */
    protected _isValidValue(value: any) {
        if (value) {
            return value.min <= value.max;
        } else {
            return true;
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this._value);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this._isValidValue(this._value)) ? null : {
            rangeFilterError: 'Invalid value specified',
        };
    }

    /**
     * Called when the max value changed
     * @param maxValue
     */
    public onMaxValueChanged(maxValue) {
        if (this._value.max !== maxValue && this.onChange) {
            this._value.max = maxValue;
            this.onChange();
        }
    }

    /**
     * Called when the min value changed
     * @param minValue
     */
    public onMinValueChanged(minValue) {
        if (this._value.min !== minValue && this.onChange) {
            this._value.min = minValue;
            this.onChange();
        }
    }

    /**
     * Called when the enabled status of the switcher changed
     * @param enabled
     */
    public onEnabledChanged(enabled) {
        if (this._value.enabled !== enabled && this.onChange) {
            this._value.enabled = enabled;
            this.onChange();
        }
    }

}
