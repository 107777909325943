<div
    class="tw-grid tw-py-2.5 dark:tw-py-3.5"
    [class.tw-grid-cols-[1fr_1fr]]="(displaySearch$ | async) === false"
    [class.tw-grid-cols-[1fr_1fr_1fr]]="(displaySearch$ | async) === true">
    <div class="tw-flex tw-justify-start tw-items-center">
        <a class="tw-min-h-[38px] tw-flex tw-items-center focus-visible:tw-outline-3 focus-visible:tw-outline-primary-focus" [routerLink]="logoUrl$ | async">
            <img class="tw-h-logo focus-visible:tw-outline" [src]="logoImgUrl"/>
        </a>
    </div>
    <ng-container *ngIf="displaySearch$ | async">
        <app-search
            class="tw-flex tw-justify-center tw-items-center"
            [showGroupName]="true"
            [displayShortcuts]="true"
            [products]="products$ | async"
            [shortcuts]="shortcuts$ | async"
            (termSearch)="onTermSearch($event)">
        </app-search>
    </ng-container>
    <div class="tw-flex tw-justify-end tw-items-center tw-divide-x tw-divide-neutral-400 dark:tw-divide-old-gray-500">
        <ng-container *ngIf="canSelectCustomer$ | async">
            <div class="tw-pr-1.25">
                <a
                    chroma-button
                    appearance="text"
                    [routerLink]="activatedLink$ | async">
                    <span class="material-symbols-rounded tw-text-xl tw-leading-6" icon-left>contacts</span>
                    <span>{{ (activatedLabel$ | async) | transloco: (activatedLabelParams$ | async) }}</span>
                </a>
            </div>
        </ng-container>

        <div class="tw-px-1.25">
            <app-product-selection-indicator />
        </div>

        <div class="tw-px-5">
            <div class="tw-cursor-pointer">
                <button
                    type="button"
                    chroma-button
                    size="icon"
                    cdkOverlayOrigin
                    #trigger="cdkOverlayOrigin"
                    [class.tw-pointer-events-none]="profileMenuIsOpen"
                    (click)="onOverlayChange()">
                    <span class="material-symbols-rounded tw-text-xl tw-leading-6">account_circle</span>
                </button>
            </div>
        </div>

        <div class="tw-pl-5">
            <button
                type="button"
                class="tw-block tw-w-9.5 tw-h-9.5 tw-overflow-hidden tw-rounded hover:tw-text-primary-base focus-visible:tw-outline focus-visible:tw-outline-3 focus-visible:tw-outline-primary-focus"
                (click)="onOpenMenu()">
                <span class="material-symbols-rounded tw-font-light tw-text-5xl tw-relative tw-bottom-[4px]">menu</span>
            </button>
        </div>
    </div>
</div>

<ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="profileMenuIsOpen"
    [cdkConnectedOverlayPositions]="overlayPositions"
    (overlayOutsideClick)="onOverlayChange()"
    (overlayKeydown)="onOverlayChange()">
    <div class="tw-w-[420px] tw-bg-white tw-border tw-border-solid tw-border-neutral-100 tw-py-7.5 tw-px-5 tw-rounded tw-shadow-soft dark:tw-border-old-gray-200 dark:tw-rounded-none dark:tw-shadow-none">
        <div class="tw-flex tw-flex-col tw-gap-y-7.5">
            <ng-container *ngIf="(userProfile$ | async) as userProfile">
                <div class="tw-flex tw-gap-x-2.5 tw-items-center">
                    <ng-container *ngIf="userProfile.profilePictureID">
                        <img class="tw-h-10 tw-w-10 tw-rounded-full" [src]="resolveDerifilesImage(userProfile.profilePictureID)">
                    </ng-container>
                    <ng-container *ngIf="!userProfile.profilePictureID">
                        <span class="tw-inline-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-bg-primary-base">
                            <span class="tw-text-base tw-leading-none tw-text-white">{{ userProfile.firstname.charAt(0) }}{{ userProfile.lastname.charAt(0) }}</span>
                        </span>
                    </ng-container>
                    <span class="tw-text-lg tw-font-semibold tw-text-primary-base">{{ userProfile.firstname }} {{ userProfile.lastname }}</span>
                </div>
                <div class="tw-flex tw-flex-col tw-gap-y-3.75">
                    <div class="tw-text-sm tw-flex tw-flex-col tw-gap-y-1.25">
                        <span class="tw-text-neutral-400 dark:tw-text-old-gray-500">Benutzername</span>
                        <span class="tw-text-black dark:tw-text-black-old">{{ userProfile.username }}</span>
                    </div>
                    <div class="tw-text-sm tw-flex tw-flex-col tw-gap-y-1.25">
                        <span class="tw-text-neutral-400 dark:tw-text-old-gray-500">E-Mail</span>
                        <span class="tw-text-black">{{ userProfile.email }}</span>
                    </div>
                </div>
            </ng-container>
            <div class="tw-flex tw-justify-between">
                <a
                    type="button"
                    chroma-button
                    appearance="outline"
                    [href]="dericonIdLink"
                    target="_blank">Profileinstellungen</a>
                <button
                    type="button"
                    chroma-button
                    (click)="onSignOut()">
                    <span>Logout</span>
                    <span class="material-symbols-rounded tw-text-xl tw-leading-6" icon-right>logout</span>
                </button>
            </div>
        </div>
    </div>
</ng-template>