/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 09/29/2017.
 */
import { HttpClient } from '@angular/common/http';
import { AuthStoreService } from '../services/auth-store.service';
import { ConfigurationService } from '../services/configuration.service';
import { LogglyLoggerService } from '../services/loggly-logger.service';
import { AuthenticatedApi } from './authenticated.api';

/**
 * API abstraction layer for all Derifin APIs
 */
export class DerifinApi extends AuthenticatedApi {
    constructor(baseName: string, env: string, http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, appConfigService: ConfigurationService) {
        const basePath = [appConfigService.configuration.api.derifin.baseUrl];

        if (env) {
            basePath.push('env');
            basePath.push(env);
        }

        if (baseName) {
            basePath.push(baseName);
        }

        super(basePath.join('/'), http, authStoreService, loggerService);
    }
}
