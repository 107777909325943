import { Component, Input, OnInit } from '@angular/core';
import { Entity, EntityTableColumn } from '../../models/entities.model';
import { EntityTableCellComponent } from '../entity-table-cell/entity-table-cell.component';

@Component({
    selector: 'app-entity-highlight',
    templateUrl: './entity-highlight.component.html',
    styleUrls: ['./entity-highlight.component.scss'],
    standalone: true,
    imports: [EntityTableCellComponent]
})
export class EntityHighlightComponent implements OnInit {

    @Input() public field: EntityTableColumn;

    @Input() public entity: Entity;

    constructor() {
    }

    public ngOnInit() {
    }

}
