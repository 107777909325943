import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { EntityActionsActivationTriggersService } from '../../../core/services/entity-actions-activation-triggers.service';
import { get } from 'lodash';
import { Icon, IconName } from '@dericon/wms-icons';
import { TranslocoModule } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from 'chroma-ui';
import { ActionDecorators } from './entity-actions.type';

@Component({
    selector: 'app-entity-actions',
    templateUrl: './entity-actions.component.html',
    standalone: true,
    imports: [CommonModule, TranslocoModule, ButtonComponent]
})
export class EntityActionsComponent implements OnDestroy {

    @Input() public genericActionsStructure: GenericActionStructure[];
    @Input() public data: any;
    @Input() public actionDecorators: ActionDecorators = {}; 
    @Input() disableActions: boolean;

    @Output() public actionClicked: EventEmitter<GenericActionStructure> = new EventEmitter();

    public activationTriggers$: Observable<string[]>;
    public currentlyActiveTriggers: string[] = [];

    public triggerSubscription: Subscription;

    constructor(private entityActionsActivationTriggersService: EntityActionsActivationTriggersService) {
        this.activationTriggers$ = this.entityActionsActivationTriggersService.activeTriggers$;

        this.triggerSubscription = this.activationTriggers$.subscribe((value) => {
            this.currentlyActiveTriggers = [...value];
        });
    }

    public onActionClicked(action: GenericActionStructure): void {
        this.actionClicked.next(action);
    }

    public isVisibilityTriggeredForAction(action: GenericActionStructure) {
        if (action.visibilityIndicatorPath) {
            return get(this.data, action.visibilityIndicatorPath, false);
        }

        if (!action.visibilityTriggers || !action.visibilityTriggers.length) {
            return true;
        }

        return action.visibilityTriggers.reduce((res, current) => {
            return res && this.currentlyActiveTriggers.includes(current);
        }, true);
    }

    public isFadeTriggeredForAction(action: GenericActionStructure) {
        if (!action.fadeTriggers || !action.fadeTriggers.length) {
            return true;
        }

        return action.fadeTriggers.reduce((res, current) => {
            return res && this.currentlyActiveTriggers.includes(current);
        }, true);
    }

    public isFadeOutTriggeredForAction(action: GenericActionStructure) {
        if (!action.fadeOutTriggers || !action.fadeOutTriggers.length) {
            return false;
        }

        return action.fadeOutTriggers.reduce((res, current) => {
            return !(!res && !this.currentlyActiveTriggers.includes(current));
        }, false);
    }

    public isHideTriggeredForAction(action: GenericActionStructure) {
        if (!action.hideTriggers || !action.hideTriggers.length) {
            return false;
        }

        return action.hideTriggers.reduce((res, current) => {
            return !(!res && !this.currentlyActiveTriggers.includes(current));
        }, false);
    }

    public ngOnDestroy(): void {
        if (this.triggerSubscription) {
            this.triggerSubscription.unsubscribe();
        }
    }
}

export interface GenericActionStructure {
    label: string;
    translateLabel?: boolean;
    type: string;
    pathToId?: string;
    link?: string;
    linkType?: string;
    context?: string;
    visibilityTriggers?: string[];
    visibilityIndicatorPath?: string;
    conditionPath?: string;
    conditionValues?: any[];
    fadeTriggers?: string[];
    fadeOutTriggers?: string[];
    hideTriggers?: string[];
    icon?: IconName;
}
