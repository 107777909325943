import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IconComponent } from '../../../shared/components/icon/icon.component';

@Component({
    selector: 'app-action-payload-dialog',
    templateUrl: './action-payload-dialog.component.html',
    standalone: true,
    imports: [IconComponent]
})
export class ActionPayloadDialogComponent {
    @ViewChild('textareaPayload', {static: true})
    public textareaPayload: ElementRef<HTMLTextAreaElement>;

    public stringPayload = JSON.stringify({
        payload1: 'data1',
        payload2: 'data2',
    }, undefined, 4);

    constructor(
        private dialogRef: MatDialogRef<ActionPayloadDialogComponent>
        ) {
    }

    public onCloseClick(e: MouseEvent): void {
        e.preventDefault();
        this.dialogRef.close({ confirmed: false });
    }

    public onOK(): void {
        try {
            const value = JSON.parse(this.textareaPayload.nativeElement.value);
            this.dialogRef.close({payload: value});
        } catch (exception) {
            alert(exception);
        }
    }
}
