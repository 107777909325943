import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuService } from '../../services/menu.service';
import { UiService } from '../../services/ui.service';
import { MenuComponent } from '../menu/menu.component';
import { MenuItemComponent } from '../../../shared/components/menu-item/menu-item.component';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../../../shared/components/icon/icon.component';
import { NavigationSkipped, NavigationStart, Router, RouterLink } from '@angular/router';

/**
 * Component that represents the main menu including animations
 */
@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    animations: [
        trigger('rowState', [
            state('void', style({
                height: 0,
                opacity: 0
            })),
            state('stale', style({
                height: '*',
                opacity: 1
            })),
            state('expanded', style({
                height: '*',
                opacity: 1,
                marginTop: '100px'
            })),
            transition('void <=> *', animate('250ms ease-out'))
        ])
    ],
    standalone: true,
    imports: [CommonModule, RouterLink, IconComponent, MenuItemComponent]
})
export class MainMenuComponent extends MenuComponent implements OnInit, OnDestroy {

    private subscriptions: Subscription[] = [];

    constructor(
        private uiService: UiService,
        private router: Router
    ) {
        super();
    }

    public ngOnInit(): void {
        this.subscriptions.push(
            this.router.events.pipe(
                filter((event) =>
                    event instanceof NavigationStart ||
                    event instanceof NavigationSkipped &&
                    event.url === this.router.url
                )
            ).subscribe(() =>
                this.uiService.closeMenu()
            )
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }

    @HostListener('window:keyup', ['$event'])
    public onHotKey(e: KeyboardEvent) {
        if (e.key === 'Escape' || e.key === 'Esc') {
            this.uiService.closeMenu();
        }
    }

    public onCloseClick(): void {
        this.uiService.closeMenu();
    }

    public onItemSelected(itemIndex: number, rowIndex: number): boolean {
        const handled = super.onItemSelected(itemIndex, rowIndex);

        if (!handled) {
            this.uiService.closeMenu();
        }

        return true;
    }
}
