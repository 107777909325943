import { Action } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { FormValues } from '../../../../core/models/form.model';
import { type } from '../../../../core/store/util';
import { Entity, EntityTableConfig } from '../../../../shared/models/entities.model';
import {
    BatchOfferResponseCreate,
    IssuerQuoteStructureAndData, OfferResponseChangeAssignee, OfferResponseCreate, OfferResponseDecline,
    ProductResponse,
    ProductStructure, ResolvedIssuerQuote,
} from '../../../shared/product-designer-shared/models/product-designer.model';

export const RfqIssuerActionTypes = {
    LOAD_RFQ_ISSUER_TABLE_STRUCTURE: type('RFQ_ISSUER:LOAD_RFQ_ISSUER_TABLE_STRUCTURE'),
    LOAD_RFQ_ISSUER_TABLE_STRUCTURE_SUCCEEDED: type('RfqIssuer:LOAD_RFQ_ISSUER_TABLE_STRUCTURE_SUCCEEDED'),
    LOAD_RFQ_ISSUER_TABLE_STRUCTURE_FAILED: type('RFQ_ISSUER:LOAD_RFQ_ISSUER_TABLE_STRUCTURE_FAILED'),

    LOAD_RFQ_ISSUER_TABLE_VALUES: type('RFQ_ISSUER:LOAD_RFQ_ISSUER_TABLE_VALUES'),
    LOAD_RFQ_ISSUER_TABLE_VALUES_SUCCEEDED: type('RFQ_ISSUER:LOAD_RFQ_ISSUER_TABLE_VALUES_SUCCEEDED'),
    LOAD_RFQ_ISSUER_TABLE_VALUES_FAILED: type('RFQ_ISSUER:LOAD_RFQ_ISSUER_TABLE_VALUES_FAILED'),

    LOAD_RFQ_ISSUER_QUOTE: type('RFQ_ISSUER:LOAD_RFQ_ISSUER_QUOTE'),
    LOAD_RFQ_ISSUER_QUOTE_SUCCEEDED: type('RFQ_ISSUER:LOAD_RFQ_ISSUER_QUOTE_SUCCEEDED'),
    LOAD_RFQ_ISSUER_QUOTE_FAILED: type('RFQ_ISSUER:LOAD_RFQ_ISSUER_QUOTE_FAILED'),

    RESET_RFQ_ISSUER_QUOTE: type('RFQ_ISSUER:RESET_RFQ_ISSUER_QUOTE'),

    LOAD_RFQ_ISSUER_PRODUCT_STRUCTURE: type('RFQ_ISSUER:LOAD_RFQ_ISSUER_PRODUCT_STRUCTURE'),
    LOAD_RFQ_ISSUER_PRODUCT_STRUCTURE_SUCCEEDED: type('RFQ_ISSUER:LOAD_RFQ_ISSUER_PRODUCT_STRUCTURE_SUCCEEDED'),
    LOAD_RFQ_ISSUER_PRODUCT_STRUCTURE_FAILED: type('RFQ_ISSUER:LOAD_RFQ_ISSUER_PRODUCT_STRUCTURE_FAILED'),

    LOAD_RFQ_ISSUER_PRODUCT_VALUES: type('RFQ_ISSUER:LOAD_RFQ_ISSUER_PRODUCT_VALUES'),
    LOAD_RFQ_ISSUER_PRODUCT_VALUES_SUCCEEDED: type('RFQ_ISSUER:LOAD_RFQ_ISSUER_PRODUCT_VALUES_SUCCEEDED'),
    LOAD_RFQ_ISSUER_PRODUCT_VALUES_FAILED: type('RFQ_ISSUER:LOAD_RFQ_ISSUER_PRODUCT_VALUES_FAILED'),

    LOAD_RFQ_ISSUER_ALL_ISSUERS: type('RFQ_ISSUER:LOAD_RFQ_ISSUER_ALL_ISSUERS'),
    LOAD_RFQ_ISSUER_ALL_ISSUERS_SUCCEEDED: type('RFQ_ISSUER:LOAD_RFQ_ISSUER_ALL_ISSUERS_SUCCEEDED'),
    LOAD_RFQ_ISSUER_ALL_ISSUERS_FAILED: type('RFQ_ISSUER:LOAD_RFQ_ISSUER_ALL_ISSUERS_FAILED'),

    LOAD_RFQ_ISSUER_RESOLVED_QUOTES: type('RFQ_ISSUER:LOAD_RFQ_RESOLVED_QUOTES'),
    LOAD_RFQ_ISSUER_RESOLVED_QUOTES_SUCCEEDED: type('RFQ_ISSUER:LOAD_RFQ_RESOLVED_QUOTES_SUCCEEDED'),
    LOAD_RFQ_ISSUER_RESOLVED_QUOTES_FAILED: type('RFQ_ISSUER:LOAD_RFQ_RESOLVED_QUOTES_FAILED'),

    ADD_OFFER_REQUEST: type('RFQ_ISSUER:ADD_OFFER_REQUEST'),
    ADD_OFFER_REQUEST_SUCCESS: type('RFQ_ISSUER:ADD_OFFER_REQUEST_SUCCESS'),
    ADD_OFFER_REQUEST_FAILURE: type('RFQ_ISSUER:ADD_OFFER_REQUEST_FAILURE'),

    DECLINE_OFFER_REQUEST: type('RFQ_ISSUER:DECLINE_OFFER_REQUEST'),
    DECLINE_OFFER_REQUEST_SUCCESS: type('RFQ_ISSUER:DECLINE_OFFER_REQUEST_SUCCESS'),
    DECLINE_OFFER_REQUEST_FAILURE: type('RFQ_ISSUER:DECLINE_OFFER_REQUEST_FAILURE'),

    BATCH_OFFER_REQUEST: type('RFQ_ISSUER:BATCH_OFFER_REQUEST'),
    BATCH_OFFER_REQUEST_SUCCESS: type('RFQ_ISSUER:BATCH_OFFER_REQUEST_SUCCESS'),
    BATCH_OFFER_REQUEST_FAILURE: type('RFQ_ISSUER:BATCH_OFFER_REQUEST_FAILURE'),

    CHANGE_OFFER_RESPONSE_ASSIGNEE_REQUEST: type('RFQ_ISSUER:CHANGE_OFFER_RESPONSE_ASSIGNEE_REQUEST'),
    CHANGE_OFFER_RESPONSE_ASSIGNEE_REQUEST_SUCCESS: type('RFQ_ISSUER:CHANGE_OFFER_RESPONSE_ASSIGNEE_REQUEST_SUCCESS'),
    CHANGE_OFFER_RESPONSE_ASSIGNEE_REQUEST_FAILURE: type('RFQ_ISSUER:CHANGE_OFFER_RESPONSE_ASSIGNEE_REQUEST_FAILURE'),
};

/* LoadRfqIssuerTableStructureAction **/

export interface LoadRfqIssuerTableStructureAction extends Action {
    payload?: void;
}

export function loadRfqIssuerTableStructure(): LoadRfqIssuerTableStructureAction {
    return {
        type: RfqIssuerActionTypes.LOAD_RFQ_ISSUER_TABLE_STRUCTURE,
    };
}

/* LoadRfqIssuerTableStructureSucceededAction **/

export interface LoadRfqIssuerTableStructureSucceededAction extends Action {
    payload: {
        structure: EntityTableConfig;
    };
}

export function loadRfqIssuerTableStructureSucceeded(structure: EntityTableConfig): LoadRfqIssuerTableStructureSucceededAction {
    return {
        type: RfqIssuerActionTypes.LOAD_RFQ_ISSUER_TABLE_STRUCTURE_SUCCEEDED,
        payload: {
            structure,
        },
    };
}

/* LoadRfqIssuerTableStructureFailedAction **/

export interface LoadRfqIssuerTableStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadRfqIssuerTableStructureFailed(error: AppError): LoadRfqIssuerTableStructureFailedAction {
    return {
        type: RfqIssuerActionTypes.LOAD_RFQ_ISSUER_TABLE_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/* LoadRfqIssuerTableValuesAction **/

export interface LoadRfqIssuerTableValuesAction extends Action {
    payload?: void;
}

export function loadRfqIssuerTableValues(): LoadRfqIssuerTableValuesAction {
    return {
        type: RfqIssuerActionTypes.LOAD_RFQ_ISSUER_TABLE_VALUES,
    };
}

/* LoadRfqIssuerTableValuesSucceededAction **/

export interface LoadRfqIssuerTableValuesSucceededAction extends Action {
    payload: {
        entities: Entity[];
    };
}

export function loadRfqIssuerTableValuesSucceeded(entities: Entity[]): LoadRfqIssuerTableValuesSucceededAction {
    return {
        type: RfqIssuerActionTypes.LOAD_RFQ_ISSUER_TABLE_VALUES_SUCCEEDED,
        payload: {
            entities,
        },
    };
}

/* LoadRfqIssuerTableValuesFailedAction **/

export interface LoadRfqIssuerTableValuesFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadRfqIssuerTableValuesFailed(error: AppError): LoadRfqIssuerTableValuesFailedAction {
    return {
        type: RfqIssuerActionTypes.LOAD_RFQ_ISSUER_TABLE_VALUES_FAILED,
        payload: {
            error,
        },
    };
}

//

export interface ResetRfqIssuerQuote extends Action {
    payload?: object;
}

export function resetRfqIssuerQuote(): ResetRfqIssuerQuote {
    return {
        type: RfqIssuerActionTypes.RESET_RFQ_ISSUER_QUOTE,
    };
}

//

export interface LoadRfqIssuerQuote extends Action {
    payload: {
        productId: string;
    };
}

export function loadRfqIssuerQuote(productId: string): LoadRfqIssuerQuote {
    return {
        type: RfqIssuerActionTypes.LOAD_RFQ_ISSUER_QUOTE,
        payload: {
            productId,
        },
    };
}

export interface LoadRfqIssuerQuoteSuccessAction extends Action {
    payload: {
        data: IssuerQuoteStructureAndData,
    };
}

export function loadRfqIssuerQuoteSuccess(data: IssuerQuoteStructureAndData): LoadRfqIssuerQuoteSuccessAction {
    return {
        type: RfqIssuerActionTypes.LOAD_RFQ_ISSUER_QUOTE_SUCCEEDED,
        payload: {
            data,
        },
    };
}

export interface LoadRfqIssuerQuoteFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadRfqIssuerQuoteFailure(error: AppError): LoadRfqIssuerQuoteFailureAction {
    return {
        type: RfqIssuerActionTypes.LOAD_RFQ_ISSUER_QUOTE_FAILED,
        payload: {
            error,
        },
    };
}

//

export interface LoadRfqIssuerResolvedQuotesAction extends Action {
    payload: {
        productId: string;
    };
}

export function loadRfqIssuerResolvedQuotes(productId: string): LoadRfqIssuerResolvedQuotesAction {
    return {
        type: RfqIssuerActionTypes.LOAD_RFQ_ISSUER_RESOLVED_QUOTES,
        payload: {
            productId,
        },
    };
}

export interface LoadRfqIssuerResolvedQuotesSuccessAction extends Action {
    payload: {
        quotes: ResolvedIssuerQuote[],
    };
}

export function loadRfqIssuerResolvedQuotesSuccess(quotes: ResolvedIssuerQuote[]): LoadRfqIssuerResolvedQuotesSuccessAction {
    return {
        type: RfqIssuerActionTypes.LOAD_RFQ_ISSUER_RESOLVED_QUOTES_SUCCEEDED,
        payload: {
            quotes,
        },
    };
}

export interface LoadRfqIssuerResolvedQuotesFailureAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadRfqIssuerResolvedQuotesFailure(error: AppError): LoadRfqIssuerResolvedQuotesFailureAction {
    return {
        type: RfqIssuerActionTypes.LOAD_RFQ_ISSUER_RESOLVED_QUOTES_FAILED,
        payload: {
            error,
        },
    };
}

//

export interface AddOfferRequestAction extends Action {
    payload: {
        data: OfferResponseCreate;
    };
}

export function addOfferRequest(data: OfferResponseCreate): AddOfferRequestAction {
    return {
        type: RfqIssuerActionTypes.ADD_OFFER_REQUEST,
        payload: {
            data,
        },
    };
}

export interface AddOfferRequestSuccessAction extends Action {
    payload?: void;
}

export function addOfferRequestSuccess(): AddOfferRequestSuccessAction {
    return {
        type: RfqIssuerActionTypes.ADD_OFFER_REQUEST_SUCCESS,
    };
}

export interface AddOfferRequesFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function addOfferRequestFailure(error: AppError): AddOfferRequesFailureAction {
    return {
        type: RfqIssuerActionTypes.ADD_OFFER_REQUEST_FAILURE,
        payload: {
            error,
        },
    };
}

//

export interface DeclineOfferRequestAction extends Action {
    payload: {
        data: OfferResponseDecline;
    };
}

export function declineOfferRequest(data: OfferResponseDecline): DeclineOfferRequestAction {
    return {
        type: RfqIssuerActionTypes.DECLINE_OFFER_REQUEST,
        payload: {
            data,
        },
    };
}

export interface DeclineOfferRequestSuccessAction extends Action {
    payload?: void;
}

export function declineOfferRequestSuccess(): DeclineOfferRequestSuccessAction {
    return {
        type: RfqIssuerActionTypes.DECLINE_OFFER_REQUEST_SUCCESS,
    };
}

export interface DeclineOfferRequesFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function declineOfferRequestFailure(error: AppError): DeclineOfferRequesFailureAction {
    return {
        type: RfqIssuerActionTypes.DECLINE_OFFER_REQUEST_FAILURE,
        payload: {
            error,
        },
    };
}

//

export interface BatchOfferRequestAction extends Action {
    payload: {
        data: BatchOfferResponseCreate;
    };
}

export function batchOfferRequest(data: BatchOfferResponseCreate): BatchOfferRequestAction {
    return {
        type: RfqIssuerActionTypes.BATCH_OFFER_REQUEST,
        payload: {
            data,
        },
    };
}

export interface BatchOfferRequestSuccessAction extends Action {
    payload: {
        quoteId: string;
    };
}

export function batchOfferRequestSuccess(quoteId: string): BatchOfferRequestSuccessAction {
    return {
        type: RfqIssuerActionTypes.BATCH_OFFER_REQUEST_SUCCESS,
        payload: {
            quoteId
        }
    };
}

export interface BatchOfferRequestFailureAction extends Action {
    payload: {
        error: AppError;
        quoteId: string;
    };
}

export function batchOfferRequestFailure(error: AppError, quoteId: string): BatchOfferRequestFailureAction {
    return {
        type: RfqIssuerActionTypes.BATCH_OFFER_REQUEST_FAILURE,
        payload: {
            error,
            quoteId,
        },
    };
}

//

export interface ChangeOfferResponseAssigneeAction extends Action {
    payload: {
        data: OfferResponseChangeAssignee;
        quoteId: string;
    };
}

export function changeOfferRequestResponseAssignee(quoteId: string, data: OfferResponseChangeAssignee): ChangeOfferResponseAssigneeAction {
    return {
        type: RfqIssuerActionTypes.CHANGE_OFFER_RESPONSE_ASSIGNEE_REQUEST,
        payload: {
            data,
            quoteId,
        },
    };
}

export interface ChangeOfferResponseAssigneeSuccessAction extends Action {
    payload: {
        quoteId: string,
        data: OfferResponseChangeAssignee,
    };
}

export function changeOfferRequestResponseAssigneeSuccess(quoteId: string, data: OfferResponseChangeAssignee): ChangeOfferResponseAssigneeSuccessAction {
    return {
        type: RfqIssuerActionTypes.CHANGE_OFFER_RESPONSE_ASSIGNEE_REQUEST_SUCCESS,
        payload: {
            data,
            quoteId,
        },
    };
}

export interface ChangeOfferResponseAssigneeFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function changeOfferRequestResponseAssigneeFailure(error: AppError): ChangeOfferResponseAssigneeFailureAction {
    return {
        type: RfqIssuerActionTypes.CHANGE_OFFER_RESPONSE_ASSIGNEE_REQUEST_FAILURE,
        payload: {
            error,
        },
    };
}

//
