import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {combineLatest, Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {Data} from '../../../../core/models/data.model';
import {AppState} from '../../../../core/store/state/index.state';
import {Entity} from '../../../../shared/models/entities.model';
import {ShortListStructures} from '../models/watchlist.model';
import {loadShortlist, loadShortlistData, loadShortlistStructures} from '../store/actions/shortlist.actions';
import {loadWatchlistData, loadWatchlistStructures} from '../store/actions/watchlist.actions';

@Injectable()
export class ShortlistService {

    constructor(private readonly store: Store<AppState>) {
    }

    /**
     * Load shortlist structures
     * @param {string} watchlistId
     */
    public loadShortlistStructures(watchlistId: string) {
        this.store.dispatch(loadShortlistStructures(watchlistId));
    }

    /**
     * Load shortlist data
     * @param {string} watchlistId
     */
    public loadShortlisttData(watchlistId: string) {
        this.store.dispatch(loadShortlistData(watchlistId));
    }

    public getShortlistById(shortlistId: string): Observable<{ data: Data, structure: ShortListStructures }> {
        return this.store.select((state: AppState) => state.watchlist.shortlists[shortlistId]);
    }
    public loadShortlist(shortlistId: string, tmc?: string) {
        this.store.dispatch(loadShortlist(shortlistId, tmc));
    }
}
