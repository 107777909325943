import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { throttle } from 'helpful-decorators';
import { get, values } from 'lodash';
import { NoUiSliderComponent } from '../../../../shared/components/no-ui-slider/no-ui-slider.component';
import { UpdatableOptions } from '../../../../shared/components/no-ui-slider/no-ui-slider.interface';
import { NoUiFormatter } from '../../../../shared/components/no-ui-slider/no-ui.formater';
import { FormValues } from '../../../../core/models/form.model';
import { LocaleFormatterService } from '../../../../core/services/locale-formatter.service';
import { EntityEvent } from '../../../components/entity-table/entity-table.component';
import { replaceAll } from '../../../helpers/general.helper';
import { PortfolioChartData } from '../../../models/components.model';
import { EntityWidgetMischproduktAnteileOptions } from '../../../models/widgets.model';
import { EntityWidgetContainerComponent } from '../entity-widget-container/entity-widget-container.component';
import { EntityWidgetContentComponent, EntityWidgetWithValidation } from '../entity-widget/entity-widget.component';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { TranslocoModule } from '@ngneat/transloco';
import { NoUiSliderComponent as NoUiSliderComponent_1 } from '../../../components/no-ui-slider/no-ui-slider.component';
import { FormsModule } from '@angular/forms';
import { MaxValidationDirective } from '../../../directives/max-validation.directive';
import { MinValidationDirective } from '../../../directives/min-validation.directive';
import { NumberInputComponent } from '../../../components/number-input/number-input.component';
import { IconComponent } from '../../../components/icon/icon.component';
import { NgIf, NgFor } from '@angular/common';
import { AdvisorBasketsChartComponent } from '../../../components/advisor-baskets-chart/advisor-baskets-chart.component';
import { ButtonComponent } from 'chroma-ui';

@Component({
    selector: 'app-entity-widget-mischprodukt-anteile',
    templateUrl: './entity-widget-mischprodukt-anteile.component.html',
    styleUrls: ['./entity-widget-mischprodukt-anteile.component.scss'],
    standalone: true,
    imports: [AdvisorBasketsChartComponent, NgIf, IconComponent, NgFor, NumberInputComponent, MinValidationDirective, MaxValidationDirective, FormsModule, NoUiSliderComponent_1, TranslocoModule, TranslocoLocaleModule, ButtonComponent]
})
export class EntityWidgetMischproduktAnteileComponent implements OnInit, OnChanges, OnDestroy, EntityWidgetContentComponent, AfterViewInit, EntityWidgetWithValidation {

    @Input() public entityId: string;
    @Input() public width: number;

    @Input() public options: EntityWidgetMischproduktAnteileOptions;
    @Input() public data: MischproduktAnteileData[];

    @Input() public formValues: FormValues;

    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();
    @Output() public componentEvent: EventEmitter<EntityEvent> = new EventEmitter();

    @ViewChildren(NoUiSliderComponent)
    public sliderComponents: QueryList<NoUiSliderComponent>;

    public editable = true;
    public granularStepActive = false;
    public showInputConfirm = true;
    public emittedFromInput = false;

    get currentLocale() {
        return this.localeFormatter.currentLocale();
    }

    public formatter: NoUiFormatter = {
        to: (value: number) => {
            return this.localeFormatter.transformDecimal(value, '.2-2') + '%';
        },

        from: (value: string) => {
            const numberStr = replaceAll(replaceAll(value, '%', ''), ',', '.');
            return parseFloat(numberStr);
        },
    };

    public formData: FormValues = {};

    public sliderData: Dictionary<number> = {};
    public chartData: PortfolioChartData[] = [];

    private emitedFromInside = false;

    get anteileValues(): MischproduktAnteileItem[] {
        return get(this.formValues, this.options.values, []);
    }

    get anteileSum(): number {
        return values(this.sliderData).reduce((acc, current) => acc + current, 0);
    }

    constructor(
        public widgetContainer: EntityWidgetContainerComponent, /* NOTE(alex): Remove when editable path is added */
        private ngZone: NgZone,
        private localeFormatter: LocaleFormatterService,
        private cdf: ChangeDetectorRef,
    ) {
    }

    public ngAfterViewInit() {
        this.updateSliderOptions({step: 1});
    }

    public ngOnInit() {

    }

    public ngOnDestroy(): void {
        this.emitFormValidationState(true);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if ((changes.data || changes.formValues) && this.formValues) {
            const anteileValues = this.anteileValues;
            const chartData = [];
            let showInputConfirm = true;
            let confirmChanged = false;

            if ((!this.emitedFromInside || (changes.data && changes.data.isFirstChange())) && this.data) {
                this.sliderData = this.data.reduce((out, current) => {
                    if (current.wert && showInputConfirm) {
                        showInputConfirm = false;
                        confirmChanged = true;
                    }
                    out[current.typ] = current.wert;
                    return out;
                }, {});
            }

            if (showInputConfirm !== this.showInputConfirm && confirmChanged) {
                this.showInputConfirm = showInputConfirm;
            }

            anteileValues.forEach((v) => {
                if (!this.emitedFromInside || (changes.values && changes.values.isFirstChange())) {
                    this.sliderData[v.id] = this.sliderData[v.id] || 0;
                }

                chartData.push({
                    name: v.label,
                    value: this.sliderData[v.id],
                });
            });

            if (this.emitedFromInside) {
                this.granularStepActive = false;
            }

            this.chartData = chartData;
            this.emitedFromInside = false;
            this.emittedFromInput = false;

            if ((changes.data && changes.data.isFirstChange()) || (changes.values && changes.values.isFirstChange())) {
                this.emitFormValidationState();

                setTimeout(() => {
                    this.cdf.detectChanges();
                }, 100);
            }
        }

        if (changes.options && this.options) {
            const multiAssetFractionsFromKVG = get(this.options, 'multiAssetFractionsFromKVG', null);
            this.editable = !get(get(this.widgetContainer, 'data'), multiAssetFractionsFromKVG, false);
        }
    }

    @throttle(30)
    public onChangeAnteilValue(value: any, type: string) {
        this.emitedFromInside = true;
        this.emitFormChange();
    }

    public onChangeAnteilValueInput(value: any, type: string, slider: NoUiSliderComponent): void {
        this.sliderData[+type] = value;
        this.emitedFromInside = true;
        this.emittedFromInput = true;

        if (!this.granularStepActive) {
            this.granularStepActive = true;
            this.onStartSliderChange(slider);
        }

        this.emitFormChange();
    }

    public emitFormChange() {
        this.componentEvent.emit({
            event: {
                type: 'form-change',
                payload: Object.keys(this.sliderData).map((key) => {
                    return {
                        wert: this.sliderData[+key],
                        typ: +key,
                    };
                }),
            },
            id: this.options.path,
        });

        this.emitFormValidationState();
    }

    public emitFormValidationState(value: boolean = this.formValidation()) {
        this.componentEvent.emit({
            event: {
                type: 'form-validation',
                payload: {
                    value,
                },
            },
            id: this.options.path,
        });
    }

    @HostListener('window:keydown', ['$event'])
    public keyDownEvent(event: KeyboardEvent) {
        if (event.shiftKey && !this.granularStepActive) {
            this.granularStepActive = true;
        }
    }

    @HostListener('window:keyup', ['$event'])
    public keyUpEvent(event: KeyboardEvent) {
        if (!event.shiftKey && this.granularStepActive) {
            this.granularStepActive = false;
        }
    }

    public updateSliderOptions(options: UpdatableOptions, component?: NoUiSliderComponent): void {
        this.ngZone.runOutsideAngular(() => {
            setTimeout(() => {
                if (component) {
                    component.slider.updateOptions(options, true);
                } else {
                    this.sliderComponents.forEach((cmp) => {
                        cmp.slider.updateOptions(options, true);
                    });
                }
            });
        });
    }

    public onStartSliderChange(cmp: NoUiSliderComponent): void {
        this.updateSliderOptions({
            step: this.granularStepActive ? 0 : 1,
        }, cmp);
    }

    public formValidation(): boolean {
        return this.anteileSum === 100 || this.showInputConfirm;
    }

    public onClickConfirm(): void {
        this.showInputConfirm = false;
        this.emitFormValidationState();
    }

    public onClickReset(): void {
        this.showInputConfirm = true;

        this.componentEvent.emit({
            event: {
                type: 'form-change',
                payload: [],
            },
            id: this.options.path,
        });

        this.emitFormValidationState();
    }
}

interface MischproduktAnteileItem {
    id: string;
    label: string;
}

interface MischproduktAnteileData {
    typ: number; // id
    wert: number; // value (%)
}
