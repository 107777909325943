import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ProductSelectionState } from "../state/product-selection.state";

export const selectProductSelection = createFeatureSelector<ProductSelectionState>('productSelection');

export const selectShelvedProductIds = createSelector(
    selectProductSelection,
    (state: ProductSelectionState) => state.shelvedProductIds
);

export const selectShelvedProductCount = createSelector(
    selectShelvedProductIds,
    (ids: Array<string>) => ids.length
);
