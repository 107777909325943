import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthApi } from '../../../../core/api/auth.api';
import { AuthStoreService } from '../../../../core/services/auth-store.service';
import { ConfigurationService } from '../../../../core/services/configuration.service';
import { ResizeService } from '../../../../core/services/resize.service';
import { WidgetDynamicChartData } from '../../../../core/store/state/widgets-data.state';
import { MercuryContent, MercuryGroup } from '../../../models/mercury.model';
import { EntityWidgetAdditionalDataChannel, EntityWidgetOptions } from '../../../models/widgets.model';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { DistanceToNowPipe } from '../../../pipes/distance-to-now.pipe';
import { TruncatePipe } from '../../../pipes/truncate.pipe';
import { IconComponent } from '../../../components/icon/icon.component';
import { ContentsAuthorAvatarComponent } from '../../../components/contents-author-avatar/contents-author-avatar.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-entity-widget-mercury',
    templateUrl: './entity-widget-mercury.component.html',
    styleUrls: ['./entity-widget-mercury.component.scss'],
    standalone: true,
    imports: [NgIf, MatButtonToggleModule, NgFor, ContentsAuthorAvatarComponent, IconComponent, AsyncPipe, TruncatePipe, DistanceToNowPipe]
})
export class EntityWidgetMercuryComponent implements OnInit, OnChanges, EntityWidgetContentComponent, OnDestroy {
    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public options: EntityWidgetOptions;
    @Input() public data: { results: MecuryInputData[] } = {results: []};
    @Input() public additionalData: EntityWidgetAdditionalDataChannel<WidgetDynamicChartData>;
    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();
    @ViewChild('groupingContainer', {static: true})
    public groupingContainerRef: ElementRef;

    public maxScrollHeight = 0;
    public data$: BehaviorSubject<MercuryData> = new BehaviorSubject(null);
    public filteredData$: Observable<MercuryContent[]>;
    public activeGroupingId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public disableAvatars = true;
    public maxLengthBodyText = 120;
    private refreshToken$: Observable<string>;
    private mercuryUrl$: Observable<string>;
    private mercuryUrl: string;

    constructor(
        private authApi: AuthApi,
        private authStoreService: AuthStoreService,
        private configuration: ConfigurationService,
        private elRef: ElementRef,
        private resizeService: ResizeService) {

        this.refreshToken$ = this.authStoreService.getRefreshToken();

        this.filteredData$ = combineLatest([
            this.data$,
            this.activeGroupingId$,
        ]).pipe(
            map(([data, activeGroupId]) => {
                let realActiveGroupId = activeGroupId;

                if (!data) {
                    return [];
                } else if (
                    data.groupings && data.groupings.length && (
                        !activeGroupId || !data.groupings.find((g) => g.id === activeGroupId)
                    )) {
                    realActiveGroupId = data.groupings[0].id;
                }

                if (realActiveGroupId !== activeGroupId) {
                    this.activeGroupingId$.next(realActiveGroupId);
                }

                if (!realActiveGroupId || !data.contents || !data.contents[realActiveGroupId]) {
                    return [];
                }

                return data.contents[realActiveGroupId];
            }),
        );

        this.mercuryUrl$ = this.configuration.getMercuryEnvironmentUrl();
    }

    public ngOnInit() {
        this.resizeService.addResizeEventListener(this.elRef.nativeElement.parentElement, (elem) => {
            this.maxScrollHeight = elem.offsetHeight - 5 - this.groupingContainerRef.nativeElement.offsetHeight;
        });
    }

    public ngOnDestroy() {
        this.resizeService.removeResizeEventListener(this.elRef.nativeElement.parentElement);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && this.data) {
            this.transformInputData();
        }

        if (changes.options && this.options) {
            if (this.options.hasOwnProperty('disableAvatars')) {
                this.disableAvatars = !!this.options.disableAvatars;
            }

            if (this.options.hasOwnProperty('maxLengthBodyText')) {
                this.maxLengthBodyText = parseInt(this.options.maxLengthBodyText, 10);
            }
        }
    }

    public transformInputData() {
        const groupings: MercuryGroup[] = [];

        const activitySorter = (a, b) => {
            const aDate = new Date((a.commentsCount && a.lastReply) || a.updatedAt);
            const bDate = new Date((b.commentsCount && b.lastReply) || b.updatedAt);

            return bDate.getTime() - aDate.getTime();
        };

        const contents: {
            [key: string]: MercuryContent[];
        } = {ALL: []};

        this.data.results.forEach((line) => {
            groupings.push(line.navItem);

            contents[line.navItem.id] = contents[line.navItem.id] || [];
            const navContents = line.contents.map((content) => ({
                ...content,
                _groupId: line.navItem.id,
            })).sort(activitySorter);

            contents[line.navItem.id].push(...navContents);
        });

        this.data$.next({groupings, contents});
    }

    public onActiveGroupingChange(event: MatButtonToggleChange) {
        this.activeGroupingId$.next(event.value);
    }

    public onClickItem(event: MouseEvent, item: MercuryContent) {
        event.preventDefault();

        this.refreshToken$.pipe(
            take(1),
        ).subscribe((refreshToken) => {
            this.mercuryUrl$.pipe(
                take(1),
            ).subscribe((url) => {
                let mercuryUrl = url || this.configuration.configuration.api.mercury.baseUrl;

                if (!mercuryUrl) {
                    console.error('No mercury URL configured for environment.');
                    return false;
                } else {
                    if (mercuryUrl.endsWith('/')) {
                        mercuryUrl = mercuryUrl.substring(0, mercuryUrl.length - 1);
                    }

                    this.authApi.getRedirectTokenUsingRefreshToken(refreshToken).subscribe((token) => {
                        window.open(`${mercuryUrl}/app/feeds?grouping=${item.navId}&content=${item.id}&redirectToken=${token}`, '_blank');
                    });
                }
            });
        });
    }
}

interface MercuryData {
    groupings: MercuryGroup[];
    contents: {
        [key: string]: MercuryContent[];
    };
}

interface MecuryInputData {
    navItem: MercuryGroup;
    contents: MercuryContent[];
}
