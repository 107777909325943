import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as RouterSelectors from "../../../../core/store/selectors/router.selectors";
import { CustomerSelectionState, CustomerType, CustomerTypeList } from "../state/customer-selection.state";

export const selectCustomerSelection = createFeatureSelector<CustomerSelectionState>('customerSelection');

export const selectActivatedCustomer = createSelector(
    selectCustomerSelection,
    (state: CustomerSelectionState) => state.activated.customer
);

export const selectActivatedLabel = createSelector(
    selectCustomerSelection,
    (state: CustomerSelectionState) => state.activated.label
);

export const selectActivatedLabelParams = createSelector(
    selectCustomerSelection,
    (state: CustomerSelectionState) => state.activated.labelParams
);

export const selectActivatedLink = createSelector(
    selectCustomerSelection,
    (state: CustomerSelectionState) => state.activated.link
);

export const selectTabSelected = createSelector(
    selectCustomerSelection,
    (state: CustomerSelectionState) => state.tab.selected
);

export const selectTabList = createSelector(
    selectCustomerSelection,
    (state: CustomerSelectionState) => state.tab.list
);

export const selectTabLink = createSelector(
    selectCustomerSelection,
    (state: CustomerSelectionState) => state.tab.link
);

export const selectCustomerTypeParam = createSelector(
    RouterSelectors.selectOutletRoute('overlay'),
    (route) => {
        const customerType = route?.firstChild.children[0]?.routeConfig.path as CustomerType;

        if (Object.keys(CustomerTypeList).includes(customerType)) {
            return customerType
        } else {
            return undefined;
        }
    } 
);
