import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
  TranslocoMissingHandler,
  HashMap,
  TranslocoMissingHandlerData,
  TRANSLOCO_MISSING_HANDLER,
  TranslocoService
} from '@ngneat/transloco';
import { Injectable, Injector, NgModule } from '@angular/core';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat'
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {

  constructor(private http: HttpClient) { }

  public getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

@Injectable({ providedIn: 'root' })
export class TranslocoCustomMissingHandler implements TranslocoMissingHandler {

  constructor(private injector: Injector) { }

  public handle(key: string, data: TranslocoMissingHandlerData, params?: HashMap<any>) {

    const translation = this.injector.get(TranslocoService);

    if (params) {
      if (params['Default']) {
        return params['Default'];
      }
        
      const productType = params['productType'];

      if (productType && key.endsWith(productType)) {
        return translation.translate(key.substring(0, key.length - productType.length - 1) + '.DEFAULT', { productType });
      } else if (productType && key.endsWith('DEFAULT')) {
        return translation.translate( key.substring(0, key.length - 'DEFAULT'.length - 1));
      }
    }

    return key;
  }
}

const numbersFormatConfig = {
  minimumIntegerDigits: 1,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};

@NgModule({
  imports: [
    TranslocoLocaleModule.forRoot({
      langToLocaleMapping: {
        de: 'de-DE',
        en: 'en-GB'
      },
      localeToCurrencyMapping: {
        'de-DE': 'EUR',
        'en-GB': 'EUR'
      },
      localeConfig: {
        global: {
          decimal: numbersFormatConfig,
          percent: numbersFormatConfig
        }
      }
    }),
    TranslocoMessageFormatModule.forRoot()
  ],
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['de', 'en'],
        defaultLang: 'de',
        reRenderOnLangChange: true,
        prodMode: environment.production
      })
    },
    {
      provide: TRANSLOCO_LOADER,
      useClass: TranslocoHttpLoader
    },
    {
      provide: TRANSLOCO_MISSING_HANDLER,
      useClass: TranslocoCustomMissingHandler
    }
  ]
})
export class TranslocoRootModule {}
