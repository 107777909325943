import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'app-recommendation-selector',
    templateUrl: './recommendation-selector.component.html',
    styleUrls: ['./recommendation-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RecommendationSelectorComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [TranslocoModule]
})
export class RecommendationSelectorComponent implements OnInit, ControlValueAccessor {

    @Input() public disabled = false;

    public status: string;

    constructor() {
    }

    public onChange = (status: string) => {};

    public onTouched = () => {};

    public ngOnInit() {
    }

    public onTypeSelected(e: Event, type: string) {
        e.preventDefault();

        if (!this.disabled) {
            this.writeValue(type);
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public writeValue(status: string): void {
        this.status = status;
        this.onChange(status);
    }
}
