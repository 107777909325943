import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupBlockedDialogComponent } from '../../shared/components/dialogs/popup-blocked-dialog/popup-blocked-dialog.component';


@Injectable()
export class PopupService {
    constructor(private dialog: MatDialog) {
    }

    private static isPopupBlockerActive(): boolean {
        const popups = [];
        let result = false;

        const popupConfig = 'height=1,width=1,left=-100,top=-100';
        for (let i = 0; i < 2; i++) {
            popups.push(window.open('about:blank', '_blank', popupConfig));
        }

        const win = popups[popups.length - 1];
        if (!win
            || win.closed
            || typeof win.closed === 'undefined'
            || win.outerHeight === 0
            || win.outerWidth === 0
        ) {
            result = true;
        } else {
            result = false;
        }

        popups.forEach((p) => p && p.close());

        return result;
    }

    public open(url: string, openNewWindow = false): void {
        setTimeout(() => {
            if (PopupService.isPopupBlockerActive()) {
                this.dialog.open(PopupBlockedDialogComponent, {
                    data: {
                        url,
                        openNewWindow
                    }
                });
            } else {
                window.open(url, openNewWindow ? '_blank' : '');
            }
        }, 300);
    }
}
