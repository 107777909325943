import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { map, mergeMap } from "rxjs/operators";
import { IssueMessageApi } from "../../api/issue-message.api";
import * as IssueMessageActions from "../actions/issue-message.action";
import * as IssueMessageSelectors from "../selectors/issue-message.selectors";

@Injectable()
export class IssueMessageEffects {

    public getIssueMessage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(IssueMessageActions.getIssueMessage),
            concatLatestFrom(() => this.store.select(IssueMessageSelectors.selectIssueMessage)),
            mergeMap(([_, state]) => this.issueMessageApi.getIssueMessage().pipe(
                map((response) => 
                    IssueMessageActions.setIssueMessage({ 
                        message: response.data,
                        updatedAt: response.meta.updatedAt,
                        hidden:
                            state.hidden &&
                            state.updatedAt === response.meta.updatedAt ||
                            !response.data
                }) 
            ))
        ))
    });

    constructor(
        private actions$: Actions,
        private store: Store,
        private issueMessageApi: IssueMessageApi
    ) { }
}