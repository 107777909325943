import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { MatCalendarHeader } from '@angular/material/datepicker';
import * as moment from 'moment';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-calendar-header',
    templateUrl: './calendar-header.component.html',
    styleUrls: ['./calendar-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatButtonModule, TranslocoModule]
})
export class CalendarHeaderComponent<D> extends MatCalendarHeader<D> {
    public todayClicked(): void {
        this.calendar.selected = (moment() as any);
        this.calendar.selectedChange.emit(this.calendar.selected as D);
    }
}
