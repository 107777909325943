import { Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { get } from 'lodash';
import { Entity } from '../../models/entities.model';
import { GenericActionStructure } from '../entity-actions/entity-actions.component';
import { EntityTableCellComponent } from '../entity-table-component-cell/entity-table-component-cell.component';
import { NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-entity-table-selectbox-cell',
    templateUrl: './entity-table-selectbox-cell.component.html',
    styleUrls: ['./entity-table-selectbox-cell.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor]
})
export class EntityTableSelectboxCellComponent implements OnInit, OnChanges, EntityTableCellComponent {

    @HostBinding('class.entity-table-selectbox-cell') public recommendationEntityTableCellClass = true;

    @Input() public product: Entity;
    @Input() public options: EntityTableSelectboxCellOptions;
    @Input() public path: string;

    @Output() public event: EventEmitter<any> = new EventEmitter();

    public currentValue: string = null;

    constructor() {

    }

    public ngOnInit() {
        this.currentValue = get(this.product, this.path);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.product || changes.path) {
            this.currentValue = get(this.product, this.path);
        }
    }

    public onSingleSelectChange(value) {
        const event = {
            type: this.options.type,
            payload: value,
        };

        this.currentValue = value;

        this.event.next(event);
    }

    public onClickSelect(event) {
        event.stopImmediatePropagation();
        event.stopPropagation();
    }
}

export interface EntityTableSelectboxCellOptions {
    items: EntityTableSelectboxItem[];
    type: string;
    translateTexts?: boolean;
}

export interface EntityTableSelectboxItem {
    id: string;
    name: string;
}
