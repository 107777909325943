/**
 * Created by Alex Klein <alex@rocketloop.de>
 */

import {Action} from '@ngrx/store';
import {AppError} from '../../../../core/errors/base.errors';
import {CarouselItem} from '../../models/carousel-item.model';
import {LoginConfig} from '../../models/login-config.model';

export enum LandingActionTypes {
    LANDING_PAGE_DATA_REQUEST = 'LANDING:LANDING_PAGE_DATA_REQUEST',
    LANDING_PAGE_DATA_SUCCESS = 'LANDING:LANDING_PAGE_DATA_SUCCESS',
    LANDING_PAGE_DATA_FAILURE = 'LANDING:LANDING_PAGE_DATA_FAILURE',
}

export class LandingPageDataRequest implements Action {
    public readonly type = LandingActionTypes.LANDING_PAGE_DATA_REQUEST;
}

export interface LandingPageDataSuccessPayload {
    carouselItems: CarouselItem[];
    contactInfos: string[];
    loginConfig: LoginConfig;
}

export class LandingPageDataSuccess implements Action {
    public readonly type = LandingActionTypes.LANDING_PAGE_DATA_SUCCESS;

    constructor(public payload: LandingPageDataSuccessPayload) {
    }
}

export class LandingPageDataFailure implements Action {
    public readonly type = LandingActionTypes.LANDING_PAGE_DATA_FAILURE;

    constructor(public payload: AppError) {
    }
}

export type LandingActionsUnion = LandingPageDataRequest | LandingPageDataSuccess | LandingPageDataFailure;
