export interface NoUiFormatter {
  to(value: number): string;
  from(value: string): number;
}

export class DefaultFormatter implements NoUiFormatter {
  to(value: number): string {
    return String(parseFloat(parseFloat(String(value)).toFixed(2)));
  }
  
  from(value: string): number {
    return parseFloat(value);
  }
}
