<div class="row">
    <div class="col-6">
        <section class="card-form__section" *ngIf="values?.group">
            <h4 class="card-headline">{{config.options.title.underlyingGroup}}</h4>
            <div class="custom-controls-stacked">
                <label *ngFor="let value of values?.group" class="custom-control custom-switch">
                    <input
                        type="checkbox" class="custom-control-input"
                        (change)="onGroupCheckboxChange($event, value)"
                        [checked]="_value.group[value.id]" [disabled]="disabled">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">{{value.label}}</span>
                </label>
            </div>
        </section>
    </div>
    <div class="col-6">
        <section class="card-form__section" *ngIf="values?.settlement">
            <h4 class="card-headline">{{config.options.title.settlement}}</h4>
            <div class="custom-controls-stacked">
                <label *ngFor="let value of values?.settlement" class="custom-control custom-switch">
                    <input
                        type="checkbox" class="custom-control-input"
                        (change)="onSettlementCheckboxChange($event, value)"
                        [checked]="_value.settlement[value.id]" [disabled]="disabled">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">{{value.label}}</span>
                </label>
            </div>
        </section>
    </div>
</div>
<div class="row">
    <div class="col-6" *ngIf="config.options.displayResearch && values?.research">
        <section class="card-form__section">
            <h4 class="card-headline">{{config.options.title.research}}</h4>
            <div class="custom-controls-stacked">
                <label *ngFor="let value of values?.research" class="custom-control custom-switch">
                    <input
                        type="checkbox" class="custom-control-input"
                        (change)="onResearchCheckboxChange($event, value)"
                        [checked]="_value.research[value.id]" [disabled]="disabled">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">{{value.label}}</span>
                </label>
            </div>
        </section>
    </div>
    <div class="col-6">
        <section class="card-form__section">
            <h4 class="card-headline">{{config.options.title.underlying}}
                <span class="head-line-number">{{availableItemCount}}</span>
            </h4>
            <app-multi-select-dropdown
                [placeholder]="'Bitte wählen'"
                [items]="availableItems"
                [active]="selectedItems"
                [disabled]="disabled"
                [filter]="filterUnderlyingItems"
                (data)="onSelectionChange($event)"></app-multi-select-dropdown>
        </section>
    </div>
</div>


