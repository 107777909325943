import { Component, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { get } from 'lodash';
import { EntityWidgetConfig } from '../../../shared/models/widgets.model';
import { Data } from '../../models/data.model';
import { PopupService } from '../../services/popup.service';
import { ActionDescription, WidgetDescription, WidgetDialogData } from './widget-dialog-data.interface';
import { EntityWidgetContainerComponent } from '../../../shared/widgets/legacy/entity-widget-container/entity-widget-container.component';
import { NgFor } from '@angular/common';
import { IconComponent } from '../../../shared/components/icon/icon.component';
import { ButtonComponent } from 'chroma-ui';


@Component({
    selector: 'app-widget-dialog',
    templateUrl: './widget-dialog.component.html',
    standalone: true,
    imports: [IconComponent, NgFor, EntityWidgetContainerComponent, ButtonComponent]
})
export class WidgetDialogComponent {
    private link: string;

    @Output() public title: string;
    @Output() public description: string;
    @Output() public widgets: WidgetDescription[];
    @Output() public actions: ActionDescription[];

    public structure: WidgetDialogData['structure']; 
    public values: WidgetDialogData['values'];
    public products: Data[];
    public widgetConfigs: EntityWidgetConfig[];


    constructor(
        @Inject(MAT_DIALOG_DATA) private data: WidgetDialogData,
        private dialogRef: MatDialogRef<WidgetDialogComponent>,
        private popupService: PopupService,
    ) {
        this.structure = this.data.structure;
        this.values = this.data.values;

        const productsPath = get(this.structure, 'productsPath');
        const titlePath = get(this.structure, 'titlePath');
        const descriptionPath = get(this.structure, 'descriptionPath');

        this.link = this.data.link;
        this.products = get(this.values, productsPath);
        this.title = get(this.values, titlePath);
        this.description = get(this.values, descriptionPath);
        this.widgetConfigs = get(this.structure, 'productSectionConfiguration.widgets', []);

        this.actions = get(this.structure, 'actionSectionConfiguration.actions', [])
            .map((action) => {
                return {
                    id: action.type,
                    label: action.label,
                } as ActionDescription;
            });
    }

    public getDataForWidget(data: Data, path: string): Data {
        if (path) {
            return get(data, path);
        } else {
            return data;
        }
    }

    public getTitleForWidget(config: EntityWidgetConfig, data: Data): string {
        const title = get(config, 'options.title');

        if (typeof title === 'string') {
            return title;
        } else {
            const path = get(title, 'path');
            const def = get(title, 'default');

            if (path) {
                return get(data, path, def);
            } else {
                return def;
            }
        }
    }

    public onAction(id: string): void {
        if (id === 'CANCEL') {
            this.onCloseClick();
        } else if (id === 'CONFIRM') {
            this.onConfirmClick();
        }
    }

    public onCloseClick(e?: MouseEvent): void {
        e?.preventDefault();

        this.dialogRef.close({confirmed: false});
    }

    public onConfirmClick(): void {
        this.dialogRef.close({confirmed: true});
        this.popupService.open(this.link);
    }

    public onHandleWidgetAction(action: { type: string; payload: any }): void {
        switch (action.type) {
            default:
                console.log('widget action with type ' + action.type + ' could not be handled');
        }
    }
}
