import { Injectable } from '@angular/core';
import { Translation, TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { unflatten } from 'flat';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ConfigurationWindow } from '../../../../declarations';
import { StringsApi } from '../../api/strings.api';
import { AppError } from '../../errors/base.errors';
import { Data } from '../../models/data.model';
import { LoadStringsAction, loadStringsFailed, loadStringsSuccess, StringsActionTypes } from '../actions/strings.action';

@Injectable()
export class StringsEffects {

    
    public loadStrings$ = createEffect(() => this.actions$.pipe(
        ofType(StringsActionTypes.LOAD_STRINGS),
        mergeMap((action: LoadStringsAction) => this.onLoadStrings(action)),
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly stringsApi: StringsApi,
        private translocoService: TranslocoService
    ) { }

    private onLoadStrings(action: LoadStringsAction) {
        return this.stringsApi.getStrings(action.payload.path).pipe(
            tap((strings: Data) => {
                if ((window as unknown as ConfigurationWindow).disableTranslations) {
                    return;
                }

                let newTranslation: Translation = {};

                if (action.payload.path && action.payload.path.length) {
                    const transformedObj = { ...unflatten<Data, object>(strings, { overwrite: true }) };

                    if (action.payload.path === 'designer') {
                        // @ts-ignore
                        newTranslation.productDesigner = transformedObj.designer;
                    } else {
                        newTranslation = transformedObj;
                    }
                } else {
                    newTranslation = { UNKNOWN_PATH: strings };
                }
                
                this.translocoService.setTranslation(
                    newTranslation,
                    this.translocoService.getActiveLang()
                );
            }),
            map((strings: Data) => loadStringsSuccess(strings, action.payload.path)),
            catchError((err: AppError) => of(loadStringsFailed(err, action.payload.path))),
        );
    }
}
