import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, ValidationErrors, FormsModule } from '@angular/forms';

import { get } from 'lodash';
import { FormControlConfig, FormControlOptions } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';
import { DateInputComponent } from '../date-input/date-input.component';

@Component({
    selector: 'app-filter-labeled-date-input',
    templateUrl: './filter-labeled-date-input.component.html',
    styleUrls: ['./filter-labeled-date-input.component.scss'],
    standalone: true,
    imports: [DateInputComponent, FormsModule]
})
export class FilterLabeledDateInputComponent implements OnInit, SingleValueFilterControl<string>, OnChanges {

    @Input() public config: FormControlConfig<LabelledDateInputOptions>;

    @Input() public values: any;

    @Input() public disabled = false;

    @Output() public blurControl: EventEmitter<void> = new EventEmitter();

    @ViewChild('input', {static: true}) public dateInput: AbstractControl;

    public value: string;

    public label: string;

    public onChange: () => void;

    public onTouched: () => void;

    constructor() {
    }

    public ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.config) {
            this._updateLabel();
        }
    }

    public writeValue(value: string): void {
        if (this.value !== value) {
            this.value = value;
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this.value);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this.dateInput.valid)
            ? null : {
                labelledDateInput: 'Invalid value specified.',
            };
    }

    /**
     * Called when the date is changed
     * @param value
     */
    public onDateChanged(value: string) {
        if (this.value !== value && this.onChange) {
            this.value = value;
            this.onChange();
        }
    }

    private _updateLabel() {
        this.label = (this.values && this.config && this.config.options && this.config.options.label)
            ? get(this.values, this.config.options.label)
            : '';
    }

}

export interface LabelledDateInputOptions extends FormControlOptions {
    label: string;
}
