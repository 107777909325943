import { getRouterSelectors, RouterReducerState } from "@ngrx/router-store";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { reduce } from "lodash";
import { StringObject } from "../../models/generic.model";

export const selectRouter = createFeatureSelector<RouterReducerState>('router');

export const selectRouterState = createSelector(
  selectRouter,
  (router) => router && router.state
);

export const selectRootRoute = createSelector(
  selectRouterState,
  (routerState) => routerState && routerState.root
);

export const selectRootPath = createSelector(
  selectRootRoute,
  (rootRoute) => rootRoute.firstChild.routeConfig.path
);

export const selectOutletRoutes = createSelector(
  selectRootRoute, (rootRoute) => {

  const outletRoutes = ['overlay', 'slide-down'];

  let children = rootRoute ? rootRoute.children : [];
  let route = rootRoute;

  while(children.length === 1) {
    route = route.firstChild;
    children = route.children;
  }

  return children
    .filter((route) => outletRoutes.includes(route.outlet));
});

export const selectOutletRoute = (outlet: string)  => createSelector(
  selectOutletRoutes,
  (children) => reduce(
    children.filter((route) => route.outlet === outlet),
    route => route
  )?.firstChild
);

export const selectOverlayRouteData = createSelector(
  selectOutletRoute('overlay'),
  (route) => route?.data
);

export const selectOverlayRouteParam = createSelector(
  selectOutletRoute('overlay'),
  (route) => route?.firstChild.url[0]?.path
);

export const { selectCurrentRoute, selectRouteData } = getRouterSelectors();

export const selectContext = createSelector(
  selectRouteData,
  (routeData) => routeData.context as string
);

export const selectOverlayContext = createSelector(
  selectOverlayRouteData,
  (overlayRouteData) => overlayRouteData?.context as string
);

export const selectCastFrom = createSelector(
  selectRouteData,
  (routeData) => routeData.castFrom
);

export const selectCastTo = createSelector(
  selectRouteData,
  (routeData) => routeData.castTo
);

export const selectCastFromValue = createSelector(
  selectCurrentRoute,
  selectCastFrom,
  (currentRoute, castFrom) => currentRoute.params[castFrom]
);

export const selectCastedParameter = createSelector(
  selectCastFromValue,
  selectCastTo,
  (castFromValue, castTo) => (
    castFromValue ?
    { [castTo] : castFromValue } : {} as StringObject
  )
);
