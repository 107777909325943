<ng-container *ngIf="genericActionsStructure?.length > 0">
    <div class="tw-flex tw-gap-5 tw-py-4 tw-flex-wrap">
        <ng-container *ngFor="let action of genericActionsStructure">
            <button
                type="button"
                chroma-button
                [appearance]="actionDecorators[action.type]?.appearance ?? 'outline'"
                [disabled]="disableActions || !isFadeTriggeredForAction(action) || isFadeOutTriggeredForAction(action)"
                (click)="onActionClicked(action)">
                <span *ngIf="action.translateLabel">{{ action.label | transloco }}</span>
                <span *ngIf="!action.translateLabel">{{ action.label }}</span>
                <ng-container *ngIf="actionDecorators[action.type]?.icon">
                    <span class="material-symbols-rounded tw-text-xl tw-leading-6">{{ actionDecorators[action.type].icon }}</span>
                </ng-container>
            </button>
        </ng-container>
    </div>
</ng-container>
