import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ValidationErrors, FormsModule } from '@angular/forms';
import { FormControlConfig, FormControlSelectOption } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';
import { Product } from '../../../routes/shared/product-designer-shared/models/product-designer.model';
import { getDataByComponent } from '../../../routes/shared/product-designer-shared/helpers/product-designer.helpers';
import { ComponentType, UnderlyingFilterValue } from '../../../routes/shared/product-designer-shared/models/product-designer-components.model';
import { NgIf } from '@angular/common';
import { MultiSelectDropdownComponent } from '../../dropdowns/multi-select-dropdown/multi-select-dropdown.component';

@Component({
    selector: 'app-filter-underlying-detailed',
    templateUrl: './filter-underlying-detailed.component.html',
    styleUrls: ['./filter-underlying-detailed.component.scss'],
    standalone: true,
    imports: [MultiSelectDropdownComponent, NgIf, FormsModule]
})
export class FilterUnderlyingDetailedComponent implements OnInit, SingleValueFilterControl<any>, OnChanges {
    @Input() public config: FormControlConfig;
    @Input() public values: FormControlSelectOption[];
    @Input() public disabled = false;
    @Input() public fullProductData: Product;

    public currentValue: UnderlyingFilterValue = {
        underlying: 'custom',
        isin: '',
        dq: '',
        weight: 1,
        name: '',
    };

    public onChange: () => void;

    public onTouched: () => void;

    get hasMultipleUnderlying(): boolean {
        const data = getDataByComponent(this.fullProductData, ComponentType.COMBINED_WEIGHTED_UNDERLYING);
        return data.length > 1;
    }

    constructor() {
    }

    public ngOnInit() {
    }

    public writeValue(value: UnderlyingFilterValue): void {
        if (this.currentValue !== value) {
            this.currentValue = {...this.currentValue, ...value};
        }
    }

    protected _isValidValue(value: UnderlyingFilterValue) {
        return true;
    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this.currentValue);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    get selectedItems() {
        return (this.availableItems) ? this.availableItems.filter((v) => v.id === this.currentValue.underlying) : [];
    }

    get availableItems(): { id: string; text: string }[] {
        const items = (this.values) ? this.values.map((value) => {
            return {
                id: value.id,
                text: value.label,
            };
        }) : [];

        return [
            {
                id: 'custom',
                text: 'Benutzerdefiniert',
            },
            ...items,
        ];

    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this._isValidValue(this.currentValue)) ? null : {
            singleSelectError: 'Invalid value specified',
        };
    }

    /**
     * Called when the selection changed
     */
    public onSingleSelectChange(value: {id: string, text: string}) {
        if (this.currentValue.underlying !== value.id) {
            this.currentValue.underlying = value.id;
            if (this.onChange) { this.onChange(); }
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.values) {
            const currentValueStillValid = this.values
                ? this.values.filter((value) => value.id === this.currentValue.underlying).length > 0 :
                false;

            this.currentValue.underlying = currentValueStillValid
                ? this.currentValue.underlying
                : null;
        }
    }

    public onInputChange(value: string, key: string) {
        if (this.currentValue[key] !== value && this.onChange) {
            this.currentValue[key] = value;
            this.onChange();
        }
    }

}
