<div class="wms-dialog-inner tw-bg-primary-bg">
    <nav class="navbar navbar-toggleable-md header-bar navbar-inverse header-bar tw-py-4">
        <div class="3xl:tw-container tw-w-full tw-mx-auto tw-px-8 tw-flex">
            <i class="mr-auto"></i>
            <div class="navbar-nav">
                <a (click)="onCloseClick($event)" class="nav-link icon-link close-modal-button" href="#">
                    <app-icon class="derifin-icon" icon="derifin:schliessen"></app-icon>
                </a>
            </div>
        </div>
    </nav>


    <div class="modal-body" style="width: 960px;">
        <div style="display: flex; flex-direction: column; align-items: stretch; gap: 0.5rem">
            <label>Payload</label>
            <textarea rows="20" #textareaPayload [value]="stringPayload"></textarea>
        </div>
        <div style="padding-top: 2rem; display: flex; gap: 1rem">
            <button  (click)="onOK()" cdkFocusInitial>OK</button>
        </div>
    </div>
</div>