<div [formGroup]="form" style="margin-bottom: -1.225rem">
    <section class="card-form__section" style="margin-bottom: 0" *ngIf="assetsClassValues">
        <h4 class="card-headline">Anlageklasse</h4>
        <div class="custom-controls-stacked">
            <app-filter-control-container
                [config]="assetsControlConfig"
                [values]="assetsClassValues"
                formControlName="assetClass"
            ></app-filter-control-container>
        </div>
    </section>

    <section class="card-form__section" *ngIf="investmentFocusValues">
        <h4 class="card-headline">Anlage-Fokus</h4>
        <div class="custom-controls-stacked">
            <app-filter-control-container
                [config]="investmentFocusControlConfig"
                [values]="investmentFocusValues"
                formControlName="investmentFocus"
            ></app-filter-control-container>
        </div>
    </section>
</div>
