import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { PopupBlockedDialogData } from './popup-blocked-dialog-data.interface';
import { TranslocoModule } from '@ngneat/transloco';
import { IconComponent } from '../../icon/icon.component';

@Component({
    selector: 'app-popup-blocked-dialog',
    templateUrl: './popup-blocked-dialog.component.html',
    standalone: true,
    imports: [IconComponent, TranslocoModule]
})
export class PopupBlockedDialogComponent {
  public url: string;
  public openNewWindow: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: PopupBlockedDialogData,
    private dialogRef: MatDialogRef<PopupBlockedDialogComponent>
  ) {
    this.url = this.data.url;
    this.openNewWindow = this.data.openNewWindow;
  }

  public onCloseClick(e: MouseEvent): void {
    e.preventDefault();
    this.dialogRef.close({confirmed: false});
  }

  public onDownload(e: MouseEvent): void {
    e.preventDefault();
    let isOpen: boolean;

    if (this.openNewWindow) {
        const win = window.open(this.url, '_blank');
        isOpen = !!win;
    } else {
        const win = window.open(this.url);
        isOpen = !!win;
    }

    if (isOpen) {
        this.dialogRef.close({confirmed: true});
    }
  }
}
