import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostBinding, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { get } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, tap } from 'rxjs/operators';
import { ScrollService } from '../../services/scroll.service';

import { UiService } from '../../services/ui.service';
import { AdForOverlayComponent } from '../../../shared/components/ad/ad-for-overlay/ad-for-overlay.component';
import { IssueMessageComponent } from '../../../shared/components/issue-message/issue-message.component';
import { NgClass, NgIf, AsyncPipe } from '@angular/common';
import { CdkScrollable } from '@angular/cdk/scrolling';
import * as UiSelectors from '../../store/selectors/ui.selectors';

@Component({
    selector: 'app-overlay',
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.scss'],
    standalone: true,
    imports: [CdkScrollable, NgClass, IssueMessageComponent, NgIf, AdForOverlayComponent, RouterOutlet, AsyncPipe]
})
export class OverlayComponent implements OnInit, OnDestroy, AfterViewInit {
    @HostBinding('class.overlay') public overlayClass = true;
    @HostBinding('class.overlay--active') public overlayActiveClass = false;

    @ViewChild('overlayContent')
    public overlayContent: ElementRef<HTMLDivElement>;

    public isActive$: Observable<boolean>;
    public outletNavigation$: Observable<string>;

    public defaultClasses = {
        overlay__content: true,
    };

    public dynamicClass = {
        ...this.defaultClasses,
    };

    private componentSubscription: Subscription;
    private overlayShouldScrollSubscription: Subscription;
    private subscriptions: Subscription[] = [];

    public overlayOpen$: Observable<boolean>;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly cdr: ChangeDetectorRef,
        private readonly uiService: UiService,
        private readonly scrollService: ScrollService,
        private readonly renderer: Renderer2,
        private readonly store: Store
    ) {
        this.overlayOpen$ = this.store.select(UiSelectors.selectOverlayOpen);

        this.isActive$ = this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            startWith(null as NavigationEnd),
            map(() => !!this.route.firstChild),
            tap(() => {
                const overlayClass = get(this, 'route.firstChild.snapshot.url[0].path', null);

                if (overlayClass) {
                    this.dynamicClass = {...this.defaultClasses, [overlayClass]: true};
                }

                this.scrollService.emitOverlayScroll(0);
            }),
        );

        this.outletNavigation$ = this.isActive$.pipe(
            map((isActive) => (isActive ? this.router.url : null)),
        );
    }

    public ngOnInit() {
        this.componentSubscription = this.isActive$.pipe(
            distinctUntilChanged()
        ).subscribe((isActive) => {
            this.overlayActiveClass = isActive;
            if (isActive) {
                this.uiService.setOverlayOpen();
            } else {
                this.uiService.setOverlayClosed();
            }
        });

        this.componentSubscription.add(
            this.outletNavigation$.subscribe((url) => {
                if (url) {
                    this.uiService.addUrlToOverlayNavigationStack(url);
                } else {
                    this.uiService.clearOverlayNavigationStack();
                }
            }),
        );
    }

    public ngAfterViewInit(): void {
        this.overlayShouldScrollSubscription = this.scrollService.overlayShouldScroll$
            .asObservable()
            .subscribe((position) => {
                this.overlayContent.nativeElement.scroll({
                    top: position,
                    behavior: 'smooth',
                });
            });

        if (!this.overlayContent.nativeElement.style.transform) {
            this.renderer.setStyle(this.overlayContent.nativeElement, 'transform', 'none');
        }

        this.subscriptions.push(
            this.router.events.pipe(
                filter((event) => event instanceof NavigationStart)
            ).subscribe((event: NavigationStart) => {
                if (event.url.includes('overlay')) {
                    this.renderer.setStyle(this.overlayContent.nativeElement, 'transform', 'none');
                } else {
                    this.renderer.removeStyle(this.overlayContent.nativeElement, 'transform');
                }
            })
        );
    }

    public ngOnDestroy(): void {
        this.componentSubscription.unsubscribe();
        this.overlayShouldScrollSubscription.unsubscribe();
        this.subscriptions.forEach((subscription) =>
            subscription.unsubscribe()
        );
    }

    public onScroll(event: Event) {
        const position = (event.target as HTMLDivElement).scrollTop;
        this.scrollService.emitOverlayScroll(position);
    }
}
