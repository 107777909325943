import { Pipe, PipeTransform } from '@angular/core';
import { truncate } from 'lodash';

@Pipe({
    name: 'truncate',
    standalone: true
})
export class TruncatePipe implements PipeTransform {

    public transform(value: string, args: any[]): string {
        if (!value) { return ''; }

        const limit = args.length > 0 ? parseInt(args[0], 10) : 30;
        const trail = args.length > 1 ? args[1] : '...';

        return truncate(value.trim(), {
            length: limit,
            separator: /,?\.* +/,
            omission: trail,
        });
        // return value.length > limit ? value.substring(0, limit) + trail : value;
    }
}
