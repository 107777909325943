import { Dictionary } from '@ngrx/entity';
import { Action } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { FormStructureConfig } from '../../../../core/models/form.model';
import { type } from '../../../../core/store/util';
import { EntityTableConfig } from '../../../../shared/models/entities.model';
import { WatchlistProductGroup } from '../../../shared/watchlist-shared/models/watchlist.model';
import { ProductBasketsValues, TableDataResponse } from '../../models/advisor-product-baskets.model';

export const AdvisorProductBasketsActions = {
    LOAD_TABLE_AND_FILTER_STRUCTURE_REQUEST: type('APB:LOAD_TABLE_AND_FILTER_STRUCTURE_REQUEST'),
    LOAD_TABLE_AND_FILTER_STRUCTURE_SUCCESS: type('APB:LOAD_TABLE_AND_FILTER_STRUCTURE_SUCCESS'),
    LOAD_TABLE_AND_FILTER_STRUCTURE_FAILURE: type('APB:LOAD_TABLE_AND_FILTER_STRUCTURE_FAILURE'),

    LOAD_FILTER_VALUES_REQUEST: type('APB:LOAD_FILTER_VALUES_REQUEST'),
    LOAD_FILTER_VALUES_SUCCESS: type('APB:LOAD_FILTER_VALUES_SUCCESS'),
    LOAD_FILTER_VALUES_FAILURE: type('APB:LOAD_FILTER_VALUES_FAILURE'),

    LOAD_TABLE_DATA_REQUEST: type('APB:LOAD_TABLE_DATA_REQUEST'),
    LOAD_TABLE_DATA_SUCCESS: type('APB:LOAD_TABLE_DATA_SUCCESS'),
    LOAD_TABLE_DATA_FAILURE: type('APB:LOAD_TABLE_DATA_FAILURE'),
};

export interface LoadTableAndFilterStructureRequestAction extends Action {
    payload: {
        moduleId: string;
    };
}

export function loadTableAndFilterStructureRequest(moduleId: string): LoadTableAndFilterStructureRequestAction {
    return {
        type: AdvisorProductBasketsActions.LOAD_TABLE_AND_FILTER_STRUCTURE_REQUEST,
        payload: {
            moduleId,
        },
    };
}

export interface LoadTableAndFilterStructureSuccessAction extends Action {
    payload: {
        tables: Dictionary<WatchlistProductGroup>;
        filter: FormStructureConfig;
    };
}

export function loadTableAndFilterStructureSuccess(tables: Dictionary<WatchlistProductGroup>, filter: FormStructureConfig): LoadTableAndFilterStructureSuccessAction {
    return {
        type: AdvisorProductBasketsActions.LOAD_TABLE_AND_FILTER_STRUCTURE_SUCCESS,
        payload: {
            tables,
            filter,
        },
    };
}

export interface LoadTableAndFilterStructureFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadTableAndFilterStructureFailure(error: AppError): LoadTableAndFilterStructureFailureAction {
    return {
        type: AdvisorProductBasketsActions.LOAD_TABLE_AND_FILTER_STRUCTURE_FAILURE,
        payload: {
            error,
        },
    };
}

export interface LoadFilterValuesRequestAction extends Action {
    payload: {
        moduleId: string;
    };
}

export function loadFilterValuesRequest(moduleId: string): LoadFilterValuesRequestAction {
    return {
        type: AdvisorProductBasketsActions.LOAD_FILTER_VALUES_REQUEST,
        payload: {
            moduleId,
        },
    };
}

export interface LoadFilterValuesSuccessAction extends Action {
    payload: {
        values: ProductBasketsValues;
    };
}

export function loadFilterValuesSuccess(values: ProductBasketsValues): LoadFilterValuesSuccessAction {
    return {
        type: AdvisorProductBasketsActions.LOAD_FILTER_VALUES_SUCCESS,
        payload: {
            values,
        },
    };
}

export interface LoadFilterValuesFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadFilterValuesFailure(error: AppError): LoadFilterValuesFailureAction {
    return {
        type: AdvisorProductBasketsActions.LOAD_FILTER_VALUES_FAILURE,
        payload: {
            error,
        },
    };
}

export interface LoadTableDataRequestAction extends Action {
    payload: {
        moduleId: string;
        customerSegment: string;
        targetPortfolio: string;
        partnerOffer: boolean;
    };
}

export function loadTableDataRequest(moduleId: string, customerSegment: string, targetPortfolio: string, partnerOffer: boolean): LoadTableDataRequestAction {
    return {
        type: AdvisorProductBasketsActions.LOAD_TABLE_DATA_REQUEST,
        payload: {
            moduleId,
            customerSegment,
            targetPortfolio,
            partnerOffer,
        },
    };
}

export interface LoadTableDataSuccessAction extends Action {
    payload: {
        data: TableDataResponse,
    };
}

export function loadTableDataSuccess(data: TableDataResponse): LoadTableDataSuccessAction {
    return {
        type: AdvisorProductBasketsActions.LOAD_TABLE_DATA_SUCCESS,
        payload: {
            data,
        },
    };
}

export interface LoadTableDataFailureAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadTableDataFailure(error: AppError): LoadTableDataFailureAction {
    return {
        type: AdvisorProductBasketsActions.LOAD_TABLE_DATA_FAILURE,
        payload: {
            error,
        },
    };
}
