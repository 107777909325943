import { Action } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { type } from '../../../../core/store/util';
import { Entity, EntityTableConfig } from '../../../../shared/models/entities.model';
import { DataRequestResponse } from '../../../shared/market-analysis-shared/api/model/market-analysis.model';

export const MarketAnalysisActionType = {
    LOAD_MARKET_ANALYSIS_STRUCTURE: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_STRUCTURE'),
    LOAD_MARKET_ANALYSIS_STRUCTURE_SUCCEEDED: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_STRUCTURE_SUCCEEDED'),
    LOAD_MARKET_ANALYSIS_STRUCTURE_FAILED: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_STRUCTURE_FAILED'),

    LOAD_MARKET_ANALYSIS_DATA: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_DATA'),
    LOAD_MARKET_ANALYSIS_DATA_SUCCEEDED: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_DATA_SUCCEEDED'),
    LOAD_MARKET_ANALYSIS_DATA_FAILED: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_DATA_FAILED'),

    LOAD_MARKET_ANALYSIS_DATA_STRUCTURE: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_DATA_STRUCTURE'),
    LOAD_MARKET_ANALYSIS_DATA_STRUCTURE_SUCCEEDED: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_DATA_STRUCTURE_SUCCEEDED'),
    LOAD_MARKET_ANALYSIS_DATA_STRUCTURE_FAILED: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_DATA_STRUCTURE_FAILED'),
};

/** LoadMarketAnalysisStructureAction **/

export interface LoadMarketAnalysisStructureAction extends Action {
    payload?: void;
}

export function loadMarketAnalysisStructure(): LoadMarketAnalysisStructureAction {
    return {
        type: MarketAnalysisActionType.LOAD_MARKET_ANALYSIS_STRUCTURE,
    };
}

/** LoadMarketAnalysisStructureSucceededAction **/

export interface LoadMarketAnalysisStructureSucceededAction extends Action {
    payload: {
        structure: DataRequestResponse,
    };
}

export function loadMarketAnalysisStructureSucceeded(structure: DataRequestResponse): LoadMarketAnalysisStructureSucceededAction {
    return {
        type: MarketAnalysisActionType.LOAD_MARKET_ANALYSIS_STRUCTURE_SUCCEEDED,
        payload: {
            structure,
        },
    };
}

/** LoadMarketAnalysisStructureFailedAction **/

export interface LoadMarketAnalysisStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadMarketAnalysisStructureFailed(error: AppError): LoadMarketAnalysisStructureFailedAction {
    return {
        type: MarketAnalysisActionType.LOAD_MARKET_ANALYSIS_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadMarketAnalysisDataAction **/

export interface LoadMarketAnalysisDataAction extends Action {
    payload: {
        limit: number;
        offset: number;
        filter: any;
        configId: string;
    };
}

export function loadMarketAnalysisData(configId: string, filter: any, limit: number, offset: number): LoadMarketAnalysisDataAction {
    return {
        type: MarketAnalysisActionType.LOAD_MARKET_ANALYSIS_DATA,
        payload: {
            filter,
            limit,
            offset,
            configId,
        },
    };
}

/** LoadMarketAnalysisDataSucceededAction **/

export interface LoadMarketAnalysisDataSucceededAction extends Action {
    payload: {
        clear: boolean;
        data: Entity[];
    };
}

export function loadMarketAnalysisDataSucceeded(data: Entity[], clear?: boolean): LoadMarketAnalysisDataSucceededAction {
    return {
        type: MarketAnalysisActionType.LOAD_MARKET_ANALYSIS_DATA_SUCCEEDED,
        payload: {
            clear,
            data,
        },
    };
}

/** LoadMarketAnalysisDataFailedAction **/

export interface LoadMarketAnalysisDataFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadMarketAnalysisDataFailed(error: AppError): LoadMarketAnalysisDataFailedAction {
     return {
         type: MarketAnalysisActionType.LOAD_MARKET_ANALYSIS_DATA_FAILED,
         payload: {
             error,
         },
     };
}

/** LoadMarketAnalysisDataStructureAction **/

export interface LoadMarketAnalysisDataStructureAction extends Action {
    payload: {
        filter: any;
        data: Entity[];
    };
}

export function loadMarketAnalysisDataStructure(filter: any, data: Entity[]): LoadMarketAnalysisDataStructureAction {
    return {
        type: MarketAnalysisActionType.LOAD_MARKET_ANALYSIS_DATA_STRUCTURE,
        payload: {
            filter,
            data,
        },
    };
}

/** LoadMarketAnalysisDataStructureSucceededAction **/

export interface LoadMarketAnalysisDataStructureSucceededAction extends Action {
    payload: {
        structure: EntityTableConfig,
    };
}

export function loadMarketAnalysisDataStructureSucceeded(structure: EntityTableConfig): LoadMarketAnalysisDataStructureSucceededAction {
    return {
        type: MarketAnalysisActionType.LOAD_MARKET_ANALYSIS_DATA_STRUCTURE_SUCCEEDED,
        payload: {
            structure,
        },
    };
}

/** LoadMarketAnalysisDataStructureFailedAction **/

export interface LoadMarketAnalysisDataStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadMarketAnalysisDataStructureFailed(error: AppError): LoadMarketAnalysisDataStructureFailedAction {
    return {
        type: MarketAnalysisActionType.LOAD_MARKET_ANALYSIS_DATA_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}
