import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { MercuryContentAuthor } from '../../models/mercury.model';
import { AvatarModule } from 'ngx-avatars';
import { NgIf } from '@angular/common';

const USER_TO_IMAGE_MAP = {};

@Component({
    selector: 'app-contents-author-avatar',
    templateUrl: './contents-author-avatar.component.html',
    styleUrls: ['./contents-author-avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, AvatarModule]
})
export class ContentsAuthorAvatarComponent implements OnInit, OnChanges {
    @Input()
    public author: MercuryContentAuthor;

    @ViewChild('template', {static: true}) public template;

    @Input()
    public size = 50;

    constructor(
        private viewContainerRef: ViewContainerRef,
        private cdf: ChangeDetectorRef,
    ) {
    }

    public ngOnInit(): void {
        this.viewContainerRef.createEmbeddedView(this.template);
    }

    get avatarSrc() {
        if (this.author.userId && USER_TO_IMAGE_MAP.hasOwnProperty(this.author.userId)) {
            return USER_TO_IMAGE_MAP[this.author.userId];
        }

        return null;
    }

    get name() {
        let name = '';

        if (this.author.firstName) {
            name += this.author.firstName + ' ';
        }

        if (this.author.lastName) {
            name += this.author.lastName;
        }

        if (this.author.username && name === '') {
            name = this.author.username;
        }

        return name;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.author || changes.size) {
            this.cdf.detectChanges();
        }
    }
}
