import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScrollService } from '../../services/scroll.service';
import { AsyncPipe } from '@angular/common';
import { IconComponent } from '../../../shared/components/icon/icon.component';

@Component({
    selector: 'app-scroll-top-button',
    templateUrl: './scroll-top-button.component.html',
    styleUrls: ['./scroll-top-button.component.scss'],
    standalone: true,
    imports: [IconComponent, AsyncPipe]
})
export class ScrollTopButtonComponent implements OnInit {
    @Input() public topPosToStartShowing = 250;
    public isButtonVisible$: Observable<boolean>;

    constructor(private scrollService: ScrollService) {
    }

    public gotoTop(e) {
        e.preventDefault();
        this.scrollService.scroll(0);
    }

    public ngOnInit() {
        this.isButtonVisible$ = this.scrollService.combinedScroll$.pipe(
            map((position) => position >= this.topPosToStartShowing),
        );
    }
}
