import { Action } from '@ngrx/store';
import { AppError } from '../../../../../../core/errors/base.errors';
import { FormValues } from '../../../../../../core/models/form.model';
import { type } from '../../../../../../core/store/util';
import { Entity, EntityTableConfig } from '../../../../../../shared/models/entities.model';
import { FilterStructureConfig } from '../../../../../assets/models/filters.model';
import { UserEntity } from '../../api/user-manager.model';

export const UserManagerActionTypes = {
    LOAD_USER_MANAGER_DATA: type('USER_MANAGER:LOAD_USER_MANAGER_DATA'),
    LOAD_USER_MANAGER_DATA_SUCCEEDED: type('USER_MANAGER:LOAD_USER_MANAGER_DATA_SUCCEEDED'),
    LOAD_USER_MANAGER_DATA_FAILED: type('USER_MANAGER:LOAD_USER_MANAGER_DATA_FAILED'),

    LOAD_USER_MANAGER_STRUCTURE: type('USER_MANAGER:LOAD_USER_MANAGER_STRUCTURE'),
    LOAD_USER_MANAGER_STRUCTURE_SUCCEEDED: type('USER_MANAGER:LOAD_USER_MANAGER_STRUCTURE_SUCCEEDED'),
    LOAD_USER_MANAGER_STRUCTURE_FAILED: type('USER_MANAGER:LOAD_USER_MANAGER_STRUCTURE_FAILED'),

    DELETE_USER_MANAGER_USER: type('USER_MANAGER:DELETE_USER_MANAGER_USER'),
    DELETE_USER_MANAGER_USER_SUCCEEDED: type('USER_MANAGER:DELETE_USER_MANAGER_USER_SUCCEEDED'),
    DELETE_USER_MANAGER_USER_FAILED: type('USER_MANAGER:DELETE_USER_MANAGER_USER_FAILED'),

    UPDATE_USER_MANAGER_USER: type('USER_MANAGER:UPDATE_USER_MANAGER_USER'),
    UPDATE_USER_MANAGER_USER_SUCCEEDED: type('USER_MANAGER:UPDATE_USER_MANAGER_USER_SUCCEEDED'),
    UPDATE_USER_MANAGER_USER_FAILED: type('USER_MANAGER:UPDATE_USER_MANAGER_USER_FAILED'),

    LOAD_USER_MANAGER_FILTER_STRUCTURE: type('USER_MANAGER:LOAD_USER_MANAGER_FILTER_STRUCTURE'),
    LOAD_USER_MANAGER_FILTER_STRUCTURE_SUCCEEDED: type('USER_MANAGER:LOAD_USER_MANAGER_FILTER_STRUCTURE_SUCCEEDED'),
    LOAD_USER_MANAGER_FILTER_STRUCTURE_FAILED: type('USER_MANAGER:LOAD_USER_MANAGER_FILTER_STRUCTURE_FAILED'),

    LOAD_USER_MANAGER_FILTER_VALUES: type('USER_MANAGER:LOAD_USER_MANAGER_FILTER_VALUES'),
    LOAD_USER_MANAGER_FILTER_VALUES_SUCCEEDED: type('USER_MANAGER:LOAD_USER_MANAGER_FILTER_VALUES_SUCCEEDED'),
    LOAD_USER_MANAGER_FILTER_VALUES_FAILED: type('USER_MANAGER:LOAD_USER_MANAGER_FILTER_VALUES_FAILED'),

    ADD_USER_MANAGER_USERS: type('USER_MANAGER:ADD_USER_MANAGER_USERS'),
    ADD_USER_MANAGER_USERS_SUCCEEDED: type('USER_MANAGER:ADD_USER_MANAGER_USERS_SUCCEEDED'),
    ADD_USER_MANAGER_USERS_FAILED: type('USER_MANAGER:ADD_USER_MANAGER_USERS_FAILED'),

    RESEND_USER_ACTIVATION: type('USER_MANAGER:RESEND_USER_ACTIVATION'),
    RESEND_USER_ACTIVATION_SUCCEEDED: type('USER_MANAGER:RESEND_USER_ACTIVATION_SUCCEEDED'),
    RESEND_USER_ACTIVATION_FAILED: type('USER_MANAGER:RESEND_USER_ACTIVATION_FAILED'),

    BATCH_DELETE_USER_MANAGER_USERS: type('USER_MANAGER:BATCH_DELETE_USER_MANAGER_USERS'),
    BATCH_DELETE_USER_MANAGER_USERS_SUCCEEDED: type('USER_MANAGER:BATCH_DELETE_USER_MANAGER_USERS_SUCCEEDED'),
    BATCH_DELETE_USER_MANAGER_USERS_FAILED: type('USER_MANAGER:BATCH_DELETE_USER_MANAGER_USERS_FAILED'),
};

/** LoadUserManagerDataAction **/
export interface LoadUserManagerDataAction extends Action {
    payload: {
        filter: any;
    };
}

export function loadUserManagerData(filter: any): LoadUserManagerDataAction {
    return {
        type: UserManagerActionTypes.LOAD_USER_MANAGER_DATA,
        payload: {
            filter,
        },
    };
}

/** LoadUserManagerDataSucceededAction **/
export interface LoadUserManagerDataSucceededAction extends Action {
    payload: {
        clear: boolean;
        data: Entity[];
    };
}

export function loadUserManagerDataSucceeded(data: Entity[], clear?: boolean): LoadUserManagerDataSucceededAction {
    return {
        type: UserManagerActionTypes.LOAD_USER_MANAGER_DATA_SUCCEEDED,
        payload: {
            clear,
            data,
        },
    };
}

/** LoadUserManagerDataFailedAction **/
export interface LoadUserManagerDataFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadUserManagerDataFailed(error: AppError): LoadUserManagerDataFailedAction {
    return {
        type: UserManagerActionTypes.LOAD_USER_MANAGER_DATA_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadUserManagerStructureAction **/
export interface LoadUserManagerStructureAction extends Action {
    payload?: void;
}

export function loadUserManagerStructure(): LoadUserManagerStructureAction {
    return {
        type: UserManagerActionTypes.LOAD_USER_MANAGER_STRUCTURE,
    };
}

/** LoadUserManagerStructureSucceededAction **/
export interface LoadUserManagerStructureSucceededAction extends Action {
    payload: {
        structure: EntityTableConfig,
    };
}

export function loadUserManagerStructureSucceeded(structure: EntityTableConfig): LoadUserManagerStructureSucceededAction {
    return {
        type: UserManagerActionTypes.LOAD_USER_MANAGER_STRUCTURE_SUCCEEDED,
        payload: {
            structure,
        },
    };
}

/** LoadUserManagerStructureFailedAction **/
export interface LoadUserManagerStructureFailedAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadUserManagerStructureFailed(error: AppError): LoadUserManagerStructureFailedAction {
    return {
        type: UserManagerActionTypes.LOAD_USER_MANAGER_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/** DELETE ACTIONS **/
export interface DeleteUserManagerUserAction extends Action {
    payload: {
        userid: string;
    };
}

export function deleteUserManagerUser(userid: string): DeleteUserManagerUserAction {
    return {
        type: UserManagerActionTypes.DELETE_USER_MANAGER_USER,
        payload: {
            userid,
        },
    };
}

export interface DeleteUserManagerUserSucceededAction extends Action {
    payload?: void;
}

export function deleteUserManagerUserSucceeded(): DeleteUserManagerUserSucceededAction {
    return {
        type: UserManagerActionTypes.DELETE_USER_MANAGER_USER_SUCCEEDED,
    };
}

export interface DeleteUserManagerUserFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function deleteUserManagerUserFailed(error: AppError): DeleteUserManagerUserFailedAction {
    return {
        type: UserManagerActionTypes.DELETE_USER_MANAGER_USER_FAILED,
        payload: {
            error,
        },
    };
}

/** UPDATE ROLE **/
export interface UpdateUserManagerUserAction extends Action {
    payload: {
        userid: string;
        entity: Entity;
    };
}

export function updateUserManagerUser(userid: string, entity: Entity): UpdateUserManagerUserAction {
    return {
        type: UserManagerActionTypes.UPDATE_USER_MANAGER_USER,
        payload: {
            userid,
            entity,
        },
    };
}

export interface UpdateUserManagerUserSucceededAction extends Action {
    payload?: void;
}

export function updateUserManagerUserSucceeded(): UpdateUserManagerUserSucceededAction {
    return {
        type: UserManagerActionTypes.UPDATE_USER_MANAGER_USER_SUCCEEDED,
    };
}

export interface UpdateUserManagerUserFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function updateUserManagerUserFailed(error: AppError): UpdateUserManagerUserFailedAction {
    return {
        type: UserManagerActionTypes.UPDATE_USER_MANAGER_USER_FAILED,
        payload: {
            error,
        },
    };
}

/** LOAD USER MANAGER FILTER STRUCTURE **/
export interface LoadUserManagerFilterStructureAction extends Action {
    payload?: void;
}

export function loadUserManagerFilterStructure(): LoadUserManagerFilterStructureAction {
    return {
        type: UserManagerActionTypes.LOAD_USER_MANAGER_FILTER_STRUCTURE,
    };
}

export interface LoadUserManagerFilterStructureSucceededAction extends Action {
    payload: {
        structure: FilterStructureConfig,
    };
}

export function loadUserManagerFilterStructureSucceeded(structure: FilterStructureConfig): LoadUserManagerFilterStructureSucceededAction {
    return {
        type: UserManagerActionTypes.LOAD_USER_MANAGER_FILTER_STRUCTURE_SUCCEEDED,
        payload: {
            structure,
        },
    };
}

export interface LoadUserManagerFilterStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadUserManagerFilterStructureFailed(error: AppError) {
    return {
        type: UserManagerActionTypes.LOAD_USER_MANAGER_FILTER_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/** LOAD USER MANAGER FILTER VALUES **/
export interface LoadUserManagerFilterValuesAction extends Action {
    payload?: void;
}

export function loadUserManagerFilterValues(): LoadUserManagerFilterValuesAction {
    return {
        type: UserManagerActionTypes.LOAD_USER_MANAGER_FILTER_VALUES,
    };
}

export interface LoadUserManagerFilterValuesSucceededAction extends Action {
    payload: {
        values: FormValues,
    };
}

export function loadUserManagerFilterValuesSucceeded(values: FormValues): LoadUserManagerFilterValuesSucceededAction {
    return {
        type: UserManagerActionTypes.LOAD_USER_MANAGER_FILTER_VALUES_SUCCEEDED,
        payload: {
            values,
        },
    };
}

export interface LoadUserManagerFilterValuesFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadUserManagerFilterValuesFailed(error: AppError): LoadUserManagerFilterValuesFailedAction {
    return {
        type: UserManagerActionTypes.LOAD_USER_MANAGER_FILTER_VALUES_FAILED,
        payload: {
            error,
        },
    };
}

/** ADD USER MANAGER USERS **/
export interface AddUserManagerUsersAction extends Action {
    payload: {
        users: UserEntity[];
    };
}

export function addUserManagerUsers(users: UserEntity[]): AddUserManagerUsersAction {
    return {
        type: UserManagerActionTypes.ADD_USER_MANAGER_USERS,
        payload: {
            users,
        },
    };
}

export interface AddUserManagerUsersSucceededAction extends Action {
    payload: {
        users: {
            createdUsers: string[],
            ignoredUsers: string[],
            updatedUsers: string[],
        };
    };
}

export function addUserManagerUsersSucceeded(users: any): AddUserManagerUsersSucceededAction {
    return {
        type: UserManagerActionTypes.ADD_USER_MANAGER_USERS_SUCCEEDED,
        payload: {
            users,
        },
    };
}

export interface AddUserManagerUsersFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function addUserManagerUsersFailed(error: AppError): AddUserManagerUsersFailedAction {
    return {
        type: UserManagerActionTypes.ADD_USER_MANAGER_USERS_FAILED,
        payload: {
            error,
        },
    };
}

/** BATCH DELETE USERS **/
export interface BatchDeleteUserManagerUsersAction extends Action {
    payload: {
        users: string[];
    };
}

export function batchDeleteUserManagerUsers(users: string[]): BatchDeleteUserManagerUsersAction {
    return {
        type: UserManagerActionTypes.BATCH_DELETE_USER_MANAGER_USERS,
        payload: {
            users,
        },
    };
}

export interface BatchDeleteUserManagerUsersSucceededAction extends Action {
    payload?: void;
}

export function batchDeleteUserManagerUsersSucceeded(): BatchDeleteUserManagerUsersSucceededAction {
    return {
        type: UserManagerActionTypes.BATCH_DELETE_USER_MANAGER_USERS_SUCCEEDED,
    };
}

export interface BatchDeleteUserManagerUsersFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function batchDeleteUserManagerUsersFailed(error: AppError): BatchDeleteUserManagerUsersFailedAction {
    return {
        type: UserManagerActionTypes.BATCH_DELETE_USER_MANAGER_USERS_FAILED,
        payload: {
            error,
        },
    };
}

/** RESEND USER ACTIVATION **/
export interface ResendUserActivationAction extends Action {
    payload: {
        userid: string;
    };
}

export function resendUserActivation(userid: string): ResendUserActivationAction {
    return {
        type: UserManagerActionTypes.RESEND_USER_ACTIVATION,
        payload: {
            userid,
        },
    };
}

export interface ResendUserActivationSucceededAction extends Action {
    payload?: void;
}

export function resendUserActivationSucceeded(): ResendUserActivationSucceededAction {
    return {
        type: UserManagerActionTypes.RESEND_USER_ACTIVATION_SUCCEEDED,
    };
}

export interface ResendUserActivationFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function resendUserActivationFailed(error: AppError): ResendUserActivationFailedAction {
    return {
        type: UserManagerActionTypes.RESEND_USER_ACTIVATION_FAILED,
        payload: {
            error,
        },
    };
}
