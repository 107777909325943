import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, Validators } from '@angular/forms';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[max]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MaxValidationDirective, multi: true }],
    standalone: true
})
export class MaxValidationDirective implements Validator {

    @Input() public max: number;

    public validate(control: AbstractControl): { [key: string]: any } {
        return Validators.max(this.max)(control);
    }

}
