import { createAction, props } from "@ngrx/store";
import { SolrFundResultsResponse, SolrResultsResponse } from "../../models/search.model";

export const loadSolrResults = createAction(
    '[Search] Load Solr Results',
    props<{ term: string }>()
);

export const prepareSolrResults = createAction(
    '[Search] Prepare Solr Results',
    props<{ results: SolrResultsResponse }>()
);

export const loadSolrFundResults = createAction(
    '[Funds Search] Load Solr Results',
    props<{
        term: string;
        dqs: Array<string>;
    }>()
);

export const prepareSolrFundResults = createAction(
    '[Funds Search] Prepare Solr Results',
    props<{
        results: SolrFundResultsResponse;
        dqs: Array<string>;
    }>()
);

export const resetSearch = createAction(
    '[Search] Reset Search'
);

export const openOverlay = createAction(
    '[Search] Open Overlay'
);

export const closeOverlay = createAction(
    '[Search] Close Overlay'
);

export const notifyOnProductActivation =  createAction(
    '[Search] Notify On Product Activation'
);

export const notifyOnProductActivationRequested =  createAction(
    '[Search] Notify On Product Activation Requested'
);
