import { Action } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { FormStructureConfig, FormValues } from '../../../../core/models/form.model';
import { type } from '../../../../core/store/util';
import { Entity } from '../../../../shared/models/entities.model';
import { DataRequestResponse, MarketAnalysisProductsData } from '../../../shared/market-analysis-shared/api/model/market-analysis.model';

export const MarketAnalysisProductsActionType = {
    LOAD_MARKET_ANALYSIS_PRODUCTS_FILTER: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_PRODUCTS_FILTER'),
    LOAD_MARKET_ANALYSIS_PRODUCTS_FILTER_SUCCEEDED: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_PRODUCTS_FILTER_SUCCEEDED'),
    LOAD_MARKET_ANALYSIS_PRODUCTS_FILTER_FAILED: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_PRODUCTS_FILTER_FAILED'),

    LOAD_MARKET_ANALYSIS_PRODUCTS_DATA: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_PRODUCTS_DATA'),
    LOAD_MARKET_ANALYSIS_PRODUCTS_DATA_SUCCEEDED: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_PRODUCTS_DATA_SUCCEEDED'),
    LOAD_MARKET_ANALYSIS_PRODUCTS_DATA_FAILED: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_PRODUCTS_DATA_FAILED'),

    LOAD_MARKET_ANALYSIS_PRODUCTS_DYNAMIC_DATA: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_PRODUCTS_DYNAMIC_DATA'),
    LOAD_MARKET_ANALYSIS_PRODUCTS_DYNAMIC_DATA_SUCCEEDED: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_PRODUCTS_DYNAMIC_DATA_SUCCEEDED'),
    LOAD_MARKET_ANALYSIS_PRODUCTS_DYNAMIC_DATA_FAILED: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_PRODUCTS_DYNAMIC_DATA_FAILED'),

    LOAD_MARKET_ANALYSIS_PRODUCTS_DYNAMIC_STRUCTURE: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_PRODUCTS_DYNAMIC_STRUCTURE'),
    // tslint:disable-next-line
    LOAD_MARKET_ANALYSIS_PRODUCTS_DYNAMIC_STRUCTURE_SUCCEEDED: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_PRODUCTS_DYNAMIC_STRUCTURE_SUCCEEDED'),
    // tslint:disable-next-line
    LOAD_MARKET_ANALYSIS_PRODUCTS_DYNAMIC_STRUCTURE_FAILED: type('MARKET_ANALYSIS:LOAD_MARKET_ANALYSIS_PRODUCTS_DYNAMIC_STRUCTURE_FAILED'),
};

/** LoadMarketAnalysisProductsFilterActions **/

export interface LoadMarketAnalysisProductsFilterAction extends Action {
    payload: {
        configId: string,
    };
}

export function loadMarketAnalysisProductsFilter(configId: string): LoadMarketAnalysisProductsFilterAction {
     return {
         type: MarketAnalysisProductsActionType.LOAD_MARKET_ANALYSIS_PRODUCTS_FILTER,
         payload: {
             configId,
         },
     };
}

/** LoadMarketAnalysisProductsFilterSucceededAction **/

export interface LoadMarketAnalysisProductsFilterSucceededAction extends Action {
    payload: {
        structure: DataRequestResponse,
    };
}

export function loadMarketAnalysisProductsFilterSucceeded(structure: DataRequestResponse): LoadMarketAnalysisProductsFilterSucceededAction {
    return {
        type: MarketAnalysisProductsActionType.LOAD_MARKET_ANALYSIS_PRODUCTS_FILTER_SUCCEEDED,
        payload: {
            structure,
        },
    };
}

/** LoadMarketAnalysisProductsFilterFailedAction **/

export interface LoadMarketAnalysisProductsFilterFailedAction extends  Action {
    payload: {
        error: AppError,
    };
}

export function loadMarketAnalysisProductsFilterFailed(error: AppError): LoadMarketAnalysisProductsFilterFailedAction {
    return {
        type: MarketAnalysisProductsActionType.LOAD_MARKET_ANALYSIS_PRODUCTS_FILTER_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadMarketAnalysisProductsDataAction **/

export interface LoadMarketAnalysisProductsDataAction extends Action {
    payload: {
        limit: number;
        offset: number;
        filter: any;
        configId: string;
    };
}

export function loadMarketAnalysisProductsData(configId: string, filter: any, limit: number, offset: number): LoadMarketAnalysisProductsDataAction {
    return {
        type: MarketAnalysisProductsActionType.LOAD_MARKET_ANALYSIS_PRODUCTS_DATA,
        payload: {
            filter,
            limit,
            offset,
            configId,
        },
    };
}

/** LoadMarketAnalysisProductsDataSucceededAction **/

export interface LoadMarketAnalysisProductsDataSucceededAction extends Action {
    payload: {
        data: MarketAnalysisProductsData,
        clear: boolean,
    };
}

export function loadMarketAnalysisProductsDataSucceeded(
    data: MarketAnalysisProductsData,
    clear?: boolean,
): LoadMarketAnalysisProductsDataSucceededAction {
    return {
        type: MarketAnalysisProductsActionType.LOAD_MARKET_ANALYSIS_PRODUCTS_DATA_SUCCEEDED,
        payload: {
            data,
            clear,
        },
    };
}

/** LoadMarketAnalysisProductsDataFailedAction **/

export interface LoadMarketAnalysisProductsDataFailedAction extends Action {
    payload: {
        error: AppError,
    };
}

export function loadMarketAnalysisProductsDataFailed(error: AppError): LoadMarketAnalysisProductsDataFailedAction {
    return {
        type: MarketAnalysisProductsActionType.LOAD_MARKET_ANALYSIS_PRODUCTS_DATA_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadMarketAnalysisProductsDynamicStructureAction **/
export interface LoadMarketAnalysisProductsDynamicStructureAction extends Action {
    payload: {
        productFilter: any,
    };
}

export function loadMarketAnalysisProductsDynamicStructure(productFilter: any): LoadMarketAnalysisProductsDynamicStructureAction {
    return {
        type: MarketAnalysisProductsActionType.LOAD_MARKET_ANALYSIS_PRODUCTS_DYNAMIC_STRUCTURE,
        payload: {
            productFilter,
        },
    };
}

/** LoadMarketAnalysisProductsDynamicStructureSucceededAction **/
export interface LoadMarketAnalysisProductsDynamicStructureSucceededAction extends Action {
    payload: {
        structure: FormStructureConfig,
    };
}

export function loadMarketAnalysisProductsDynamicStructureSucceeded(
    structure: FormStructureConfig,
): LoadMarketAnalysisProductsDynamicStructureSucceededAction {
    return {
        type: MarketAnalysisProductsActionType.LOAD_MARKET_ANALYSIS_PRODUCTS_DYNAMIC_STRUCTURE_SUCCEEDED,
        payload: {
            structure,
        },
    };
}

/** LoadMarketAnalysisProductsDynamicDataAction **/
export interface LoadMarketAnalysisProductsDynamicDataAction extends Action {
    payload: {
        filter: any,
        productFilter: any,
        data: Entity[],
    };
}

export function loadMarketAnalysisProductsDynamicData(
    filter: any,
    productFilter: any,
    data: Entity[],
): LoadMarketAnalysisProductsDynamicDataAction {
    return {
        type: MarketAnalysisProductsActionType.LOAD_MARKET_ANALYSIS_PRODUCTS_DYNAMIC_DATA,
        payload: {
            filter,
            productFilter,
            data,
        },
    };
}

/** LoadMarketAnalysisProductsDynamicDataSucceededAction **/
export interface LoadMarketAnalysisProductsDynamicDataSucceededAction extends Action {
    payload: {
        data: Entity[],
        values: FormValues,
        initialValues: FormValues,
    };
}

export function loadMarketAnalysisProductsDynamicDataSucceeded(
    data: Entity[],
    values: FormValues,
    initialValues: FormValues,
): LoadMarketAnalysisProductsDynamicDataSucceededAction {
    return {
        type: MarketAnalysisProductsActionType.LOAD_MARKET_ANALYSIS_PRODUCTS_DYNAMIC_DATA_SUCCEEDED,
        payload: {
            data,
            values,
            initialValues,
        },
    };
}
