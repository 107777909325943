import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormControlConfig, FormControlSelectOption } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';

import { isEqual } from 'lodash';
import { CheckboxComponent } from 'chroma-ui';

/**
 * FilterCheckbox is responsible for single checkboxe.
 */
@Component({
    selector: 'app-filter-checkbox',
    templateUrl: './filter-checkbox.component.html',
    styleUrls: ['./filter-checkbox.component.scss'],
    standalone: true,
    imports: [CheckboxComponent]
})
export class FilterCheckboxComponent implements OnInit, SingleValueFilterControl<boolean> {

    @Input() public config: FormControlConfig;

    @Input() public values: FormControlSelectOption;

    @Input() public disabled: boolean;

    public _value: boolean;

    public onChange: () => void;

    public onTouched: () => void;

    constructor() {
    }

    public ngOnInit() {
    }

    /**
     * Writes the value for the checkbox
     * @param {boolean} value
     */
    public writeValue(value: boolean): void {
        if (!value) { value = false; }
        if (!isEqual(this._value, value)) {
            this._value = value;
        }
    }

    public validate(c: AbstractControl): ValidationErrors | any {
        return null;
    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this._value);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Called when the checkbox state has changed
     * @param {Event} e
     */
    public onCheckboxChange(e?: Event) {
        this._value = !this._value;
        this.onChange();
    }

}
