import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { PaginationInstance, PaginationService } from 'ngx-pagination';
import { Subscription } from 'rxjs';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-custom-pagination-controls',
    templateUrl: './custom-pagination-controls.component.html',
    styleUrls: ['./custom-pagination-controls.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf]
})
export class CustomPaginationControlsComponent implements OnInit, OnChanges, OnDestroy {

    @HostBinding('class.custom-pagination-controls') public customPaginationControlsClass = true;

    @Input() public init = false;

    @Input() public id: string;

    @Input() public itemsPerPageOptions: number[] = [];

    @Output() public pageChange: EventEmitter<number> = new EventEmitter();

    @Output() public itemsPerPageChange: EventEmitter<number> = new EventEmitter();

    public instance: PaginationInstance;

    private changeSub: Subscription;

    constructor(private service: PaginationService,
                private changeDetectorRef: ChangeDetectorRef) {

    }

    get pageStart() {
        if (this.instance) {
            return (this.instance.currentPage - 1) * this.instance.itemsPerPage + 1;
        } else {
            return 0;
        }
    }

    get pageEnd() {
        if (this.instance) {
            return Math.min((this.instance.currentPage) * this.instance.itemsPerPage, this.instance.totalItems);
        } else {
            return 0;
        }
    }

    public ngOnInit() {
        if (!this.id) { this.id = this.service.defaultId(); }
        this.changeSub = this.service.change
            .subscribe((id) => {
                if (this.id === id) {
                    this.instance = this.service.getInstance(this.id);
                    this.checkConsistency();
                    this.changeDetectorRef.markForCheck();
                    this.changeDetectorRef.detectChanges();
                }
            });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.id || changes.init) {
            if (!this.id) { this.id = this.service.defaultId(); }
            this.instance = this.service.getInstance(this.id);
        }
    }

    public ngOnDestroy() {
        this.changeSub.unsubscribe();
    }

    public onNextPage() {
        this.pageChange.next(this.outOfBoundCorrection(this.instance.currentPage + 1));
    }

    public onPreviousPage() {
        this.pageChange.next(this.outOfBoundCorrection(this.instance.currentPage - 1));
    }

    public onItemsPerPageClicked(e: Event, itemsPerPage: number) {
        e.preventDefault();
        this.itemsPerPageChange.next(itemsPerPage);
    }

    /**
     * Checks that the given page is within bounds for the current page range.
     * If not, return a correct value for page, or the given value if OK.
     */
    private outOfBoundCorrection(page: number): number {
        const totalPages = Math.ceil(this.instance.totalItems / this.instance.itemsPerPage);
        if (totalPages < page && 0 < totalPages) {
            return totalPages;
        } else if (this.instance.currentPage < 1) {
            return 1;
        }

        return page;
    }

    private checkConsistency() {
        const correctedPage = this.outOfBoundCorrection(this.instance.currentPage);
        if (correctedPage !== this.instance.currentPage) {
            setTimeout(() => {
                this.pageChange.next(correctedPage);
            });
        }
    }

}
