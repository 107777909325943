<ng-container *ngIf="!hasNoChartData; else noDataMessage">
    <app-dynamic-chart [useParentWidth]="true" [config]="options" [data]="data"></app-dynamic-chart>
</ng-container>

<ng-template #noDataMessage>
    <div class="dynamic-chart__empty-container">
        <div class="dynamic-chart__empty-container__message">
            Für diese Auswertung liegen uns derzeit keine Daten vor.
        </div>
    </div>
</ng-template>
