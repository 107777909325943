/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 4/6/17.
 */

/**
 * Returns the computed style of a given HTMLElement
 *
 * Adapted from
 * https://github.com/ng2-ui/utils/blob/614e9e15b0575ebd8ce04ea3b81396429a74dbfd/src/dom-functions/computed-style.ts
 */
export function computedStyle(element: HTMLElement, styleProp: string): string {

    let value: any;
    const defaultView: any = (element.ownerDocument || document).defaultView;
    // W3C standard way:
    if (defaultView && defaultView.getComputedStyle) {
        // sanitize property name to css notation
        // (hypen separated words eg. font-Size)
        styleProp = styleProp.replace(/([A-Z])/g, '-$1').toLowerCase();
        return defaultView.getComputedStyle(element, null).getPropertyValue(styleProp);
        // @ts-ignore
    } else if (element.currentStyle) { // IE
        // sanitize property name to camelCase
        // tslint:disable-next-line:only-arrow-functions
        styleProp = styleProp.replace(/\-(\w)/g, function(str, letter) {
            return letter.toUpperCase();
        });
        // @ts-ignore
        value = element.currentStyle[styleProp];
        // convert other units to pixels on IE
        if (/^\d+(em|pt|%|ex)?$/i.test(value)) {
            const oldLeft = element.style.left;
            // @ts-ignore
            const oldRsLeft = element.runtimeStyle.left;
            // @ts-ignore
            element.runtimeStyle.left = element.currentStyle.left;
            element.style.left = value || 0;
            // @ts-ignore
            value = element.style.pixelLeft + 'px';
            element.style.left = oldLeft;
            // @ts-ignore
            element.runtimeStyle.left = oldRsLeft;
            return value;
        }
        return value;
    }
}
