import { Action } from '@ngrx/store';
import {
    ExecuteExportFailureAction,
    ExecuteExportSuccessAction, LoadFormInitialFailureAction,
    LoadFormInitialSuccessAction,
    LoadFormValuesFailureAction,
    LoadFormValuesSuccessAction,
    ProductsExportActions
} from '../actions/products-export.actions';
import { ProductsExportState } from '../state/products-export.state';

export const initialState: ProductsExportState = {
    productTypes: null,
    formValues: null,
    formInitial: null,
    errorExport: null,
    loadingExport: false,
    successExport: null,
    exportResult: null,

    errorInitial: null,
    errorValues: null,
    loadingInitial: null,
    loadingValues: null,
};

export function reducer(state: ProductsExportState = initialState, action: Action): ProductsExportState {
    switch (action.type) {
        case ProductsExportActions.EXECUTE_EXPORT_REQUEST: {
            return {
                ...state,
                successExport: null,
                errorExport: null,
                loadingExport: true,
            };
        }

        case ProductsExportActions.EXECUTE_EXPORT_SUCCESS: {
            const exportSuccessAction = action as ExecuteExportSuccessAction;

            return {
                ...state,
                successExport: true,
                errorExport: null,
                loadingExport: false,
                exportResult: exportSuccessAction.payload.result,
            };
        }

        case ProductsExportActions.EXECUTE_EXPORT_FAILURE: {
            const exportFailureAction = action as ExecuteExportFailureAction;

            return {
                ...state,
                successExport: false,
                errorExport: exportFailureAction.payload.error,
                loadingExport: false,
            };
        }

        /** Values actions **/
        case ProductsExportActions.LOAD_FORM_VALUES_REQUEST: {
            return {
                ...state,
                loadingValues: true,
                errorValues: null,
            };
        }

        case ProductsExportActions.LOAD_FORM_VALUES_SUCCESS: {
            const formValuesSuccessAction = action as LoadFormValuesSuccessAction;

            return {
                ...state,
                loadingValues: false,
                formValues: formValuesSuccessAction.payload.values,
            };
        }

        case ProductsExportActions.LOAD_FORM_VALUES_FAILURE: {
            const formValuesFailureAction = action as LoadFormValuesFailureAction;

            return {
                ...state,
                loadingValues: false,
                errorValues: formValuesFailureAction.payload.error,
            };
        }

        /** Initial actions **/
        case ProductsExportActions.LOAD_FORM_INITIAL_REQUEST: {
            return {
                ...state,
                loadingInitial: true,
                errorInitial: null,
            };
        }

        case ProductsExportActions.LOAD_FORM_INITIAL_SUCCESS: {
            const formInitialSuccessAction = action as LoadFormInitialSuccessAction;

            console.log("OKOKO", formInitialSuccessAction);
            return {
                ...state,
                loadingInitial: false,
                formInitial: formInitialSuccessAction.payload.data,
            };
        }

        case ProductsExportActions.LOAD_FORM_INITIAL_FAILURE: {
            const formInitialFailureAction = action as LoadFormInitialFailureAction;

            return {
                ...state,
                loadingInitial: false,
                errorInitial: formInitialFailureAction.payload.error,
            };
        }
    }
    return state;
}
