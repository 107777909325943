/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 7/4//17.
 */

import { Action } from '@ngrx/store';
import {
    ProductSharedActionTypes,
    UpdateProductDecorfinDataAction,
    UpdateProductDecorfinDataFailedAction,
} from '../actions/product-shared.actions';
import { ProductSharedState } from '../state/product-shared.state';

export const initialState: ProductSharedState = {
    productActivationUpdated: null,
    productNoteUpdated: null,
    productDecorfinDataUpdated: null,
    productDecorfinDataUpdatingError: null,
    productDericonRecommendUpdated: null,
    productDericonRecommendUpdatingError: null,
};

/**
 * The reducer responsible for ProductSharedState
 * @param state
 * @param action
 * @returns {EntitiesState<ProductSharedState>}
 */
export function productSharedReducer(state: ProductSharedState = initialState,
                                     action: Action): ProductSharedState {

    switch (action.type) {

        case ProductSharedActionTypes.UPDATE_PRODUCT_NOTES:
            return {
                ...state,
                productNoteUpdated: null,
            };

        case ProductSharedActionTypes.UPDATE_PRODUCT_NOTES_SUCCEEDED:
            return {
                ...state,
                productNoteUpdated: true,
            };

        case ProductSharedActionTypes.UPDATE_PRODUCT_ACTIVATION:
            return {
                ...state,
                productActivationUpdated: null,
            };

        case ProductSharedActionTypes.UPDATE_PRODUCT_ACTIVATION_SUCCEEDED:
            return {
                ...state,
                productActivationUpdated: true,
            };

        case ProductSharedActionTypes.UPDATE_PRODUCT_DERICON_RECOMMEND_SUCCEEDED:
            return {
                ...state,
                productDericonRecommendUpdated: true,
                productDericonRecommendUpdatingError: null,
            };

        case ProductSharedActionTypes.UPDATE_PRODUCT_DERICON_RECOMMEND:
            return {
                ...state,
                productDecorfinDataUpdated: false,
                productDecorfinDataUpdatingError: null,
            };

        case ProductSharedActionTypes.UPDATE_PRODUCT_DERICON_RECOMMEND_FAILED:
            const updateProductDericonRecommendFailedAction = action as UpdateProductDecorfinDataFailedAction;

            return {
                ...state,
                productDericonRecommendUpdated: true,
                productDericonRecommendUpdatingError: updateProductDericonRecommendFailedAction.payload.error,
            };

        case ProductSharedActionTypes.UPDATE_PRODUCT_DECORFIN_DATA:
            return {
                ...state,
                productDecorfinDataUpdated: null,
                productDecorfinDataUpdatingError: null,
            };

        case ProductSharedActionTypes.UPDATE_PRODUCT_DECORFIN_DATA_SUCCEEDED:
            return {
                ...state,
                productDecorfinDataUpdated: true,
            };

        case ProductSharedActionTypes.UPDATE_PRODUCT_DECORFIN_DATA_FAILED:
            const updateProductDecorfinDataFailedAction = action as UpdateProductDecorfinDataFailedAction;

            return {
                ...state,
                productDecorfinDataUpdated: null,
                productDecorfinDataUpdatingError: updateProductDecorfinDataFailedAction.payload.error,
            };

        default:
            return state;
    }
}
