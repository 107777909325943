<app-entity-widget-decorfin-data-form
    [structureGroups]="options?.filterControls"
    [overrideValues]="formData"
    [availableValues]="formValues"
    [disabled]="false"
    (valueChange)="onDecorfinDataChanged($event)"
></app-entity-widget-decorfin-data-form>

<div class="alert alert-danger" *ngIf="decorfinDataUpdateError$ | async">
    {{ 'products.productDecorfinDataUpdated.error' | transloco }}
</div>

<button 
    type="button"
    chroma-button
    (click)="onSave()">Speichern</button>
