import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'app-filter-bar',
    templateUrl: './filter-bar.component.html',
    styleUrls: ['./filter-bar.component.scss'],
    standalone: true,
    imports: [TranslocoModule]
})
export class FilterBarComponent implements OnInit {
    @HostBinding('class')
    get componentClass() {
        return 'card card-inverse card-primary filter-bar';
    }

    @Input() public isSpecificFilterAvailable = true;
    @Output() public toggleSpecificFilter: EventEmitter<void> = new EventEmitter<void>();
    @Output() public applyFilter: EventEmitter<void> = new EventEmitter<void>();
    @Output() public resetFilter: EventEmitter<void> = new EventEmitter<void>();

    constructor() {
    }

    public ngOnInit() {
    }

    public onToggleSpecificFilterClick(e: Event) {
        e.preventDefault();
        this.toggleSpecificFilter.next();
        return false;
    }

    public onApplyFilterClick(e: Event) {
        e.preventDefault();
        this.applyFilter.next();
        return false;
    }

    public onResetFilterClick(e: Event) {
        e.preventDefault();
        this.resetFilter.next();
        return false;
    }

}
