/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 7/4//17.
 */

import { Action, combineReducers } from '@ngrx/store';
import { EntitiesState } from '../../../../core/store/state/entities.state';
import { StringMappingListState } from '../../../../core/store/state/mapping.state';
import { Entity } from '../../../../shared/models/entities.model';
import { AssetActionTypes, AssetsForQuerySetLoadedAction } from '../actions/assets.actions';
import { AssetState } from '../state/assets.state';

export const initialState: AssetState = {
    entities: {},
    querySetMapping: {},
};

/**
 * The reducer responsible for the entities part of the @link{AssetState}
 * @param state
 * @param action
 * @returns {EntitiesState<AssetState>}
 */
export function assetEntitiesReducer(state: EntitiesState<Entity> = initialState.entities, action: Action): EntitiesState<Entity> {
    switch (action.type) {

        case AssetActionTypes.ASSETS_FOR_QUERY_SET_LOADED:
            const assetsForQuerySetLoadedAction = action as AssetsForQuerySetLoadedAction;
            return assetsForQuerySetLoadedAction.payload.assets.reduce((assets, asset) => {
                assets[asset.id] = asset;
                return assets;
            }, {...state});

        default:
            return state;
    }
}

/**
 * The reducer responsible for the entities part of the @link{AssetState}
 * @param state
 * @param action
 * @returns {EntitiesState<AssetState>}
 */
export function assetQuerySetMappingReducer(
    state: StringMappingListState<string> = initialState.querySetMapping,
    action: Action,
): StringMappingListState<string> {

    switch (action.type) {

        case AssetActionTypes.ASSETS_FOR_QUERY_SET_LOADED:
            const assetsForQuerySetLoadedAction = action as AssetsForQuerySetLoadedAction;
            const loadedAssets = assetsForQuerySetLoadedAction.payload.assets.map((asset) => asset.id);
            const newMapping = (state[assetsForQuerySetLoadedAction.payload.querySetId]) ? [
                ...state[assetsForQuerySetLoadedAction.payload.querySetId],
                ...loadedAssets,
            ] : loadedAssets;
            return {
                ...state,
                [assetsForQuerySetLoadedAction.payload.querySetId]: newMapping,
            };

        default:
            return state;
    }
}

/**
 * The reducer responsible for the @link{AssetState}
 * @type {ActionReducer<AssetState>}
 */
export const assetsModuleAssetReducer = combineReducers({
    entities: assetEntitiesReducer,
    querySetMapping: assetQuerySetMappingReducer,
});
