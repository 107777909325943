import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { MatButtonToggleChange, MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormControlConfig, FormControlSelectOption } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgFor } from '@angular/common';

/**
 * Filter radio group component that is repsonsible for a radio group filter. It includes one or more radio buttons.
 */
@Component({
    selector: 'app-filter-radio-button-group',
    templateUrl: './filter-radio-button-group.component.html',
    styleUrls: ['./filter-radio-button-group.component.scss'],
    standalone: true,
    imports: [MatButtonToggleModule, NgFor, MatTooltipModule]
})
export class FilterRadioButtonGroupComponent implements OnInit, SingleValueFilterControl<string> {

    @Input() public config: FormControlConfig;

    @Input() public values: FormControlSelectOption[];

    @Input() public disabled = false;

    public _value: string;

    public onChange: () => void;

    public onTouched: () => void;

    @HostBinding('class.app-filter-radio-button-group')
    public hostClass = true;

    constructor() {
    }

    public ngOnInit() {
    }

    /**
     * Writes the value of the radio group
     * @param {string} value
     */
    public writeValue(value: string): void {
        if (this._value !== value) {
            this._value = value;
        }
    }

    /**
     * Checks if the filter value us valid
     * @param {string} value
     * @returns {boolean}
     * @private
     */
    protected _isValidValue(value: string) {
        if (this.values) {
            return this.values.reduce((hasValue, validValue) => {
                return value === validValue.id || hasValue;
            }, false);
        } else {
            return false;
        }

    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this._value);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this._isValidValue(this._value)) ? null : {
            radioGroupError: 'Invalid value specified',
        };
    }

    /**
     * Called when a radio state was changed
     * @param e
     * @param value
     */
    public onValueChange(e: MatButtonToggleChange) {
        if (this._value !== e.value && this.onChange) {
            this._value = e.value;
            this.onChange();
        }
    }

}
