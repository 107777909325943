import { get, has } from 'lodash';
import { FormValues } from '../models/form.model';

export function getEntityId(entity: FormValues) {
    const potentialIdProperties = ['id', '_id', 'dq'];

    for (const property of potentialIdProperties) {
        if (has(entity, property)) {
            return get(entity, property);
        }
    }

    return null;
}
