import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'timeAgo',
    standalone: true
})
export class TimeAgoPipe implements PipeTransform {

    public transform(value: string, locale: string = 'de'): string {
        let date = moment(value);

        if (!date.isValid()) {
            date = moment();
        }

        // If the date is in the future, set it to the current date and reduce it a few seconds.
        const now = moment();
        if (date.isSameOrAfter(now)) {
            date = now;
            date.subtract(2, 's');
        }

        date.locale(locale);
        return date.fromNow();
    }

}
