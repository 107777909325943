import { Action, combineReducers } from '@ngrx/store';
import { FormStructureConfig, FormValues } from '../../../../core/models/form.model';
import { Entity, EntityTableConfig } from '../../../../shared/models/entities.model';
import {
    DeleteProductSuccessAction,
    LoadFormStructureFailureAction, LoadFormStructureSuccessAction, LoadFormValuesFailureAction, LoadFormValuesSuccessAction,
    LoadTableDataFailureAction, LoadTableDataSuccessAction,
    LoadTableStructureFailureAction, LoadTableStructureSuccessAction,
    OtherProductsActionTypes, SaveFormDataFailureAction, SaveFormDataSuccessAction, DeleteProductFailureAction
} from '../actions/other-products.actions';
import { OtherProductsMetaState, OtherProductsState } from '../state/other-products.state';
import { getEntityId } from '../../../../core/helpers/entities.helper';
import { act } from '@ngrx/effects';

export const initialState: OtherProductsState = {
    tableData: null,
    tableStructure: null,
    formStructure: null,
    formValues: null,

    meta: {
        loadingData: false,
        loadingStructure: false,
        loadingFormStructure: false,
        loadingFormValues: false,
        loadingSaveForm: false,
        successSaveForm: null,
        loadingDeleteProduct: false,
        successDeleteProduct: null,

        errorData: null,
        errorFormStructure: null,
        errorFormValues: null,
        errorStructure: null,
        errorSaveForm: null,
        errorDeleteProduct: null,
    },
};

export function tableDataReducer(state: Entity[] = initialState.tableData, action: Action): Entity[] {
    switch (action.type) {
        case OtherProductsActionTypes.LOAD_DATA_AND_STRUCTURE_REQUEST:
        case OtherProductsActionTypes.LOAD_TABLE_DATA_REQUEST:
        case OtherProductsActionTypes.LOAD_TABLE_DATA_FAILURE:
            return null;

        case OtherProductsActionTypes.LOAD_TABLE_DATA_SUCCESS:
            const loadTableDataSuccessAction = action as LoadTableDataSuccessAction;
            return loadTableDataSuccessAction.payload.data;

        case OtherProductsActionTypes.SAVE_FORM_DATA_SUCCESS: {
            const saveFormDataSuccessAction = action as SaveFormDataSuccessAction;
            const indexInState = state.findIndex((item) => getEntityId(item) === getEntityId(saveFormDataSuccessAction.payload.data));
            if (indexInState !== -1) {
                const entities = [...state];
                entities.splice(indexInState, 1, saveFormDataSuccessAction.payload.data);

                return entities;
            } else {
                return [
                    saveFormDataSuccessAction.payload.data,
                    ...state,
                ];
            }
        }
        case OtherProductsActionTypes.DELETE_PRODUCT_SUCCESS: {
            const DeleteProductSuccessAction = action as DeleteProductSuccessAction;

        }
        default:
            return state;
    }
}

export function tableStructureReducer(state: EntityTableConfig = initialState.tableStructure, action: Action): EntityTableConfig {
    switch (action.type) {
        case OtherProductsActionTypes.LOAD_DATA_AND_STRUCTURE_REQUEST:
        case OtherProductsActionTypes.LOAD_TABLE_STRUCTURE_REQUEST:
        case OtherProductsActionTypes.LOAD_TABLE_STRUCTURE_FAILURE:
            return null;

        case OtherProductsActionTypes.LOAD_TABLE_STRUCTURE_SUCCESS:
            const loadTableStructureSuccessAction = action as LoadTableStructureSuccessAction;
            return loadTableStructureSuccessAction.payload.structure;

        default:
            return state;
    }
}

export function formStructureReducer(state: FormStructureConfig = initialState.formStructure, action: Action): FormStructureConfig {
    switch (action.type) {
        case OtherProductsActionTypes.LOAD_DATA_AND_STRUCTURE_REQUEST:
        case OtherProductsActionTypes.LOAD_FORM_STRUCTURE_REQUEST:
        case OtherProductsActionTypes.LOAD_FORM_STRUCTURE_FAILURE:
            return null;

        case OtherProductsActionTypes.LOAD_FORM_STRUCTURE_SUCCESS:
            const loadFormStructureSuccessAction = action as LoadFormStructureSuccessAction;
            return loadFormStructureSuccessAction.payload.structure;

        default:
            return state;
    }
}

export function formValuesReducer(state: FormValues = initialState.formValues, action: Action): FormValues {
    switch (action.type) {
        case OtherProductsActionTypes.LOAD_DATA_AND_STRUCTURE_REQUEST:
        case OtherProductsActionTypes.LOAD_FORM_VALUES_REQUEST:
        case OtherProductsActionTypes.LOAD_FORM_VALUES_FAILURE:
            return null;

        case OtherProductsActionTypes.LOAD_FORM_VALUES_SUCCESS:
            const loadFormValuesSuccessAction = action as LoadFormValuesSuccessAction;
            return loadFormValuesSuccessAction.payload.values;

        default:
            return state;
    }
}

export function metaReducer(state: OtherProductsMetaState = initialState.meta, action: Action): OtherProductsMetaState {
    switch (action.type) {
        case OtherProductsActionTypes.LOAD_DATA_AND_STRUCTURE_REQUEST: {
            return {
                ...state,
                loadingFormStructure: true,
                errorFormStructure: null,

                loadingStructure: true,
                errorStructure: null,

                loadingData: true,
                errorData: null,

                loadingFormValues: true,
                errorFormValues: null,

                loadingDeleteProduct: false,
                errorDeleteProduct: null,
                successDeleteProduct: null
            };
        }

        case OtherProductsActionTypes.LOAD_FORM_STRUCTURE_REQUEST:
            return {
                ...state,
                loadingFormStructure: true,
                errorFormStructure: null,
            };

        case OtherProductsActionTypes.LOAD_FORM_STRUCTURE_SUCCESS:
        case OtherProductsActionTypes.LOAD_FORM_STRUCTURE_FAILURE:
            const loadFormStructureFailureAction = action as LoadFormStructureFailureAction;

            return {
                ...state,
                loadingFormStructure: false,
                errorFormStructure: loadFormStructureFailureAction.payload.error || null,
            };

        case OtherProductsActionTypes.LOAD_FORM_VALUES_REQUEST:
            return {
                ...state,
                loadingFormValues: true,
                errorFormValues: null,
            };

        case OtherProductsActionTypes.LOAD_FORM_VALUES_SUCCESS:
        case OtherProductsActionTypes.LOAD_FORM_VALUES_FAILURE:
            const loadFormValuesFailureAction = action as LoadFormValuesFailureAction;

            return {
                ...state,
                loadingFormValues: false,
                errorFormValues: loadFormValuesFailureAction.payload.error || null,
            };

        case OtherProductsActionTypes.LOAD_TABLE_DATA_REQUEST:
            return {
                ...state,
                loadingData: true,
                errorData: null,
            };

        case OtherProductsActionTypes.LOAD_TABLE_DATA_SUCCESS:
        case OtherProductsActionTypes.LOAD_TABLE_DATA_FAILURE:
            const loadTableDataFailureAction = action as LoadTableDataFailureAction;

            return {
                ...state,
                loadingData: false,
                errorData: loadTableDataFailureAction.payload.error || null,
            };

        case OtherProductsActionTypes.LOAD_TABLE_STRUCTURE_REQUEST:
            return {
                ...state,
                loadingStructure: true,
                errorStructure: null,
            };

        case OtherProductsActionTypes.LOAD_TABLE_STRUCTURE_SUCCESS:
        case OtherProductsActionTypes.LOAD_TABLE_STRUCTURE_FAILURE:
            const loadTableStructureFailureAction = action as LoadTableStructureFailureAction;

            return {
                ...state,
                loadingStructure: false,
                errorStructure: loadTableStructureFailureAction.payload.error || null,
            };

        case OtherProductsActionTypes.SAVE_FORM_DATA_REQUEST:
            return {
                ...state,
                loadingSaveForm: true,
                errorSaveForm: null,
                successSaveForm: null,
            };

        case OtherProductsActionTypes.SAVE_FORM_DATA_SUCCESS:
        case OtherProductsActionTypes.SAVE_FORM_DATA_FAILURE:
            const saveFormDataFailureAction = action as SaveFormDataFailureAction;

            return {
                ...state,
                loadingSaveForm: false,
                errorSaveForm: saveFormDataFailureAction.payload.error || null,
                successSaveForm: !saveFormDataFailureAction.payload.error,
            };
        case OtherProductsActionTypes.DELETE_PRODUCT_REQUEST:
            return {
                ...state,
                loadingDeleteProduct: true,
                successDeleteProduct: null,
                errorDeleteProduct: null,
            }
        case OtherProductsActionTypes.DELETE_PRODUCT_SUCCESS:
        case OtherProductsActionTypes.DELETE_PRODUCT_FAILURE:
            const deleteProductFailureAction = action as DeleteProductFailureAction;

            return {
                ...state,
                loadingDeleteProduct: false,
                errorDeleteProduct: deleteProductFailureAction.payload.error || null,
                successDeleteProduct: !deleteProductFailureAction.payload.error,
            };
        default:
            return state;
    }
}

export const reducer = combineReducers<OtherProductsState>({
    tableData: tableDataReducer,
    tableStructure: tableStructureReducer,
    formStructure: formStructureReducer,
    formValues: formValuesReducer,
    meta: metaReducer,
});
