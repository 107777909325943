import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from "@angular/core";
import { cva } from "cva";

export const optionStyle = cva([
    'tw-leading-5.5',
    'tw-flex',
    'tw-py-1', 'tw-px-3.5',
    'tw-cursor-pointer',
    'hover:tw-bg-primary-hover-option'
]);

export class ChromaOptionSelectionChange {
    constructor(
      public source: ChromaOption,
      public isUserInput = false,
    ) {}
}

@Component({
    selector: 'chroma-option',
    templateUrl: './option.html',
    standalone: true,
    host: {
        '[class.tw-bg-primary-hover-option]': 'selected',
        '(click)': '_selectViaInteraction()'
    }
})
export class ChromaOption {

    @HostBinding('class')
    hostClasses = optionStyle();

    private _selected = false;

    get selected(): boolean {
        return this._selected;
    }

    @Input() value: any;

    @Output() readonly selectionChange = new EventEmitter<ChromaOptionSelectionChange>();

    @ViewChild('text', {static: true}) _text: ElementRef<HTMLElement> | undefined;

    get viewValue(): string {
        return (this._text?.nativeElement.textContent || '').trim();
    }

    select(): void {
        if (!this._selected) {
            this._selected = true;
        }
    }

    deselect(): void {
        if (this._selected) {
            this._selected = false;
        }
    }

    _selectViaInteraction(): void {
        this._selected =  !this._selected;
        this._emitSelectionChangeEvent(true);
    }

    private _emitSelectionChangeEvent(isUserInput = false): void {
        this.selectionChange.emit(new ChromaOptionSelectionChange(this, isUserInput));
    }
}
