import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { Shortcuts, UserProfile } from "../../api/models/user.model";

export const UserActions = createActionGroup({
    source: 'User',
    events: {
        'Load Shortcuts': emptyProps(),
        'Set Shortcuts': props<{ shortcuts: Shortcuts }>(),
        'Load Profile': emptyProps(),
        'Set Profile': props<{ profile: UserProfile }>()
    }
});
