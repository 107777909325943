import { DatePipe, DecimalPipe, NgIf, NgFor } from '@angular/common';
import {
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { EChartsOption } from 'echarts';
import { clone, isEqual, max, min, pick } from 'lodash';
import * as moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { ConfigurationService } from '../../../core/services/configuration.service';
import { DynamicChartTimeFrame, DynamicChartTimeFrameOption, ExtendedDynamicChartAxisConfig, ExtendedDynamicChartData, ExtendedDynamicChartOptions, ExtendedDynamicChartReferenceLine, ExtendedDynamicChartType, } from '../../models/dynamic-chart.model';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DynamicChartComponent } from '../dynamic-chart/dynamic-chart.component';

const LINE_CHART_MAX_HEIGHT = 400;
const LINE_CHART_MIN_HEIGHT = 150;

const mapChartTypeToNgxCharts = {
    TIME_SERIES: 'line',
    NUMBER_TIME_SERIES: 'line',
};

@Component({
    selector: 'app-dynamic-chart-new',
    templateUrl: './dynamic-chart-new.component.html',
    styleUrls: ['./dynamic-chart-new.component.scss'],
    standalone: true,
    imports: [DynamicChartComponent, NgIf, MatButtonToggleModule, NgFor]
})
export class DynamicChartNewComponent implements OnInit, OnChanges, OnDestroy {
    @ContentChild('tooltipTemplate') public tooltipTemplateRef: TemplateRef<any>;
    @HostBinding('class.dynamic-chart-new') public dynamicChartClass = true;

    @ViewChild('timeFrames') timeFrames: ElementRef<HTMLElement>;

    @Input() public chartType: ExtendedDynamicChartType = 'TIME_SERIES';
    @Input() public options: ExtendedDynamicChartOptions;
    @Input() public data: ExtendedDynamicChartData;
    @Input() public referenceLines: ExtendedDynamicChartReferenceLine[];
    @Input() public useParentWidth = false;
    @Input() public maxChartHeight = LINE_CHART_MAX_HEIGHT;
    @Input() public initialTimeFrame: DynamicChartTimeFrame;
    @Input() public timeFrameOptions: Array<DynamicChartTimeFrameOption> = [];

    @Output() public selectControl: EventEmitter<any> = new EventEmitter();
    @Output() public colorsChange: EventEmitter<any> = new EventEmitter();
    @Output() public selectTimeFrame = new EventEmitter<DynamicChartTimeFrame>()

    public colorScheme: string[];
    public colors: { name: string, value: string }[];
    public mappedChartType = 'line';

    public xAxisTicks: string[] | number[] | Date[];
    public xAxisMin: string[] | number[] | Date[];
    public xAxisMax: string[] | number[] | Date[];
    public yAxisMin: number;
    public yAxisMax: number;

    public elementWidth = 250;
    public elementHeight = 250;
    public parentHeight = 0;

    public transformedData: any[];
    public transformedAggregation: any;

    public resize$: Observable<Event>;
    public afterViewChecked$: Subject<void>;
    public subscription = new Subscription();
    public chartView: number[];
    public xAxisTicksOriginal: string[] | number[] | Date[];
    public chartConfig: EChartsOption = {};

    constructor(
        protected chartElement: ElementRef,
        private datePipe: DatePipe,
        private decimalPipe: DecimalPipe,
        private appConfigService: ConfigurationService,
        private translocoService: TranslocoService
    ) {
        this.colorScheme = this.appConfigService.configuration.environment.chartColors.default ?? [];
    }

    get currentLang() {
        return this.translocoService.getActiveLang();
    }

    @HostBinding('class.dynamic-chart--legend-below') get legendBelow() {
        return this.options && this.options && this.options.legend && this.options.legend.position === 'BOTTOM';
    }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public ngOnInit() {

    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.chartType && this.chartType) {
            this.mappedChartType = mapChartTypeToNgxCharts[this.chartType];
        }

        if ((changes.config || changes.data || changes.chartType) && this.options && this.data) {
            this._onUpdateData();
        }
    }

    /**
     * Formatting function for the X axis ticks
     * @param value
     * @return {string}
     */
    public formatXAxisTick = (value: any, tooltip: boolean): string => this._formatAxisTick(value, this.options.xAxis, tooltip);

    /**
     * Formatting function for the Y axis ticks
     * @param value
     * @return {string}
     */
    public formatYAxisTick = (value: any, tooltip: boolean): string => this._formatAxisTick(value, this.options.yAxis, tooltip);

    public onChartSelect(item) {
        this.selectControl.emit(item);
    }

    public onSelectControl(event: any) {
        this.selectControl.emit(event);
    }

    public isTimeFrameSelected(timeFrame: DynamicChartTimeFrame): boolean {
        return isEqual(timeFrame, this.initialTimeFrame);
    }

    public onTimeFrameChange(timeFrame: DynamicChartTimeFrame) {
        this.selectTimeFrame.next(timeFrame);
    }

    private _formatAxisTick(value, axis: ExtendedDynamicChartAxisConfig, tooltip = false): string {
        switch (axis.type) {
            case 'NUMBER':
                return this.decimalPipe.transform(value, axis.formatter, this.currentLang);

            case 'DATE':
                if (tooltip) {
                    return this.datePipe.transform(value, 'fullDate', null, this.currentLang);
                }
                return this.datePipe.transform(value, axis.formatter, null, this.currentLang);

            default:
                return value.toString();
        }
    }

    private _getAxisMaxMinValue(axis: ExtendedDynamicChartAxisConfig, returnMax = false) {
        if (axis.boundaries.mode === 'FIXED') {
            const value = returnMax ? axis.boundaries.max : axis.boundaries.min;
            if (axis.type === 'DATE') {
                return this.transformDataToDate(value, axis);
            }

            return value;
        } else {
            const ticks = this._getTransformedAxisTicks(axis);
            if (ticks) {
                return returnMax ? max(ticks) : min(ticks);
            }
        }

        return undefined;
    }

    private _getTransformedAxisTicks(axis: ExtendedDynamicChartAxisConfig) {
        if (axis && axis.ticks) {
            return (axis.ticks as any[]).map((t) => this.transformDataToDate(t, this.options.xAxis));
        }

        return undefined;
    }

    private _onUpdateChartOptions() {
        this.xAxisTicks = this._getTransformedAxisTicks(this.options.xAxis);

        this.xAxisTicksOriginal = this.xAxisTicks ? clone(this.xAxisTicks) : undefined;

        this.xAxisMin = this._getAxisMaxMinValue(this.options.xAxis);
        this.xAxisMax = this._getAxisMaxMinValue(this.options.xAxis, true);

        this.yAxisMin = this._getAxisMaxMinValue(this.options.yAxis);
        this.yAxisMax = this._getAxisMaxMinValue(this.options.yAxis, true);

        this.chartConfig = {
            title: {
                show: this.transformedData.length === 0,
                textStyle: {
                    color: 'grey',
                    fontSize: 20
                },
                text: 'Keine Daten',
                left: 'center',
                top: 'center'
            },
            grid: {
                top: '5%',
                left: '5%',
                right: '8%',
                height: '75%',
                containLabel: true,
            },
            xAxis: {
                type: this.options.xAxis.type === 'DATE' ? 'time' : 'value',
                axisLabel: {
                    rotate: 40,
                    margin: 10,
                    hideOverlap: true,
                    formatter: (value, index) => {
                        return this._formatAxisTick(value, this.options.xAxis);
                    },
                    scale: true,
                    boundaryGap: false,
                    axisLine: {onZero: false},
                    splitLine: {show: true},
                    min: this.xAxisMin,
                    max: this.xAxisMax,
                    axisPointer: {
                        z: 100
                    },
                } as any,
            },
            yAxis: {
                type: 'value',
                min: this.yAxisMin,
                max: this.yAxisMax,
                axisLabel: this.options.yAxis.axisLabel
            }
        };
    }

    /**
     * Called when the graph data has changed
     * @private
     */
    private _onUpdateData() {
        if (this.mappedChartType === 'line') {
            this.transformedData = this.data.seriesList.map((series) => {
                const meta = pick(series, 'type', 'yValueFormatter', 'yValueLabel');
                const data = {
                    name: series.label,
                    showSymbol: false,
                    type: 'line',
                    emphasis: {
                        focus: 'series',
                        areaStyle: {}
                    },
                    encode: {
                        x: 0,
                        y: 1
                    },
                    meta,
                    data: series.values.map((value) => {
                        return [value.x, value.y, value.yRaw, meta];
                    }),
                    ...(
                        this.options.showRefLines && this.referenceLines ? {
                            markLine: {
                                data: this.referenceLines.map((line) => {
                                    return {
                                        name: line.name,
                                        yAxis: line.value,
                                        label: {
                                            formatter: '{b}',
                                            position: 'insideEndTop'
                                        }
                                    };
                                })
                            }
                        } : {}
                    ),
                };

                return data;
            });
        }

        this.colors = this.data.seriesList.map((series, i) => {
            return {
                name: series.label,
                value: this.colorScheme[Math.min(i, this.data.seriesList.length)],
            };
        });

        this.colorsChange.emit(this.colors);

        this._onUpdateChartOptions();
    }

    /**
     * Transform the data to a date (if it is a valid iso date)
     * @param data
     * @param axis
     * @returns {any}
     */
    private transformDataToDate(data: any, axis: ExtendedDynamicChartAxisConfig): any {
        if (axis.type !== 'DATE') {
            return data;
        }

        if (moment(data, moment.ISO_8601, true).isValid()) {
            return new Date(data);
        } else {
            return data;
        }
    }
}
