<app-markdown [text]="text" (action)="onActionClicked($event)"></app-markdown>

<p>
    <a
        href="#"
        (click)="onCopyClipboardClick($event)">{{ 'widgets.textCopyPaste.copyToClipboard' | transloco }}</a>
    <app-icon
        class="widget__icon--clipboard-success"
        icon="derifin:checkmark-plain"
        [@showSuccessIcon]="showCopySuccessIcon ? 'shown' : 'hidden'"
    ></app-icon>
</p>
