import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { WatchlistService } from '../../../../routes/shared/watchlist-shared/services/watchlist.service';
import { truthy } from '../../../helpers/general.helper';
import { CreateWatchlistDialogData } from './create-watchlist-dialog-data.interface';
import { TranslocoModule } from '@ngneat/transloco';
import { AutofocusDirective } from '../../../directives/autofocus.directive';
import { IconComponent } from '../../icon/icon.component';

@Component({
    selector: 'app-create-watchlist-dialog',
    templateUrl: './create-watchlist-dialog.component.html',
    standalone: true,
    imports: [IconComponent, FormsModule, ReactiveFormsModule, AutofocusDirective, TranslocoModule]
})
export class CreateWatchlistDialogComponent implements OnInit {
    public createWatchlistForm: UntypedFormGroup;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: CreateWatchlistDialogData,
        private dialogRef: MatDialogRef<CreateWatchlistDialogComponent>,
        private fb: UntypedFormBuilder,
        private watchlistService: WatchlistService,
        private router: Router,
    ) {
        this.createWatchlistForm = this.fb.group({
            name: ['', Validators.required],
        });
    }

    public ngOnInit(): void {
        this.watchlistService.wasWatchlistCreated().pipe(
            filter(truthy),
            take(1),
        ).subscribe(() => {
            this.watchlistService.clearWatchlistCreated();

            this.watchlistService.getLastCreatedWatchlistId().pipe(
                filter(truthy),
                take(1),
            ).subscribe((id) => {
                this.router.navigate(['/app', {outlets: {overlay: ['watchlist-details', id]}}]);
                this.dialogRef.close();
            });
        });
    }

    public onCloseClick(e: MouseEvent): void {
        e.preventDefault();
        this.dialogRef.close();
    }

    public onSubmit(e: Event): void {
        e.preventDefault();

        this.watchlistService.createWatchlist({
            ...this.createWatchlistForm.value,
            dqs: (this.data.selectedProductIds) ? this.data.selectedProductIds : [],
            shares: [],
        });
    }

}
