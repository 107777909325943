import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ConfigurationService } from '../../../core/services/configuration.service';
import { PortfolioChartData } from '../../models/components.model';
import { NgxEchartsModule } from 'ngx-echarts';

@Component({
    selector: 'app-advisor-baskets-chart',
    templateUrl: './advisor-baskets-chart.component.html',
    styleUrls: ['./advisor-baskets-chart.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgxEchartsModule]
})
export class AdvisorBasketsChartComponent implements OnInit, OnChanges {
    @Input()
    public size = [400, 400];
    @Input()
    public data: PortfolioChartData[] = [];
    @Input()
    public margin = 20;

    @Input()
    public grayscale = false;

    @ViewChild('chart', { static: true })
    public figure: ElementRef;

    @Input()
    disableTransitions = false;

    public options: EChartsOption = {};

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && this.data || changes.grayscale) {
            let colorScheme;

            if (this.configurationService.configuration.environment.chartColors.pie) {
                colorScheme = this.configurationService.configuration.environment.chartColors.pie;
            } else if (this.configurationService.configuration.environment.chartColors.bubble) {
                colorScheme = this.configurationService.configuration.environment.chartColors.bubble;
            }

            this.options = {
                color: this.grayscale ? [
                    '#d8d8d8',
                    '#c8c8c8',
                    '#b9b9b9',
                    '#aaaaaa',
                    '#999999',
                    '#878787',
                    '#6c6c6c',
                ] : colorScheme,
                series: [
                    {
                        type: 'pie',
                        radius: '50%',
                        data: this.data,
                        showEmptyCircle: false,
                        label: {
                            color: this.grayscale ? '#6c6c6c' : '#000000',
                            position: 'outside',
                            formatter: '{b}\n({d}%)',
                            overflow: 'break',
                            alignTo: 'labelLine',
                            bleedMargin: 0,
                        },
                        emphasis: {
                            scale: false,
                            itemStyle: {
                                color: 'inherit',
                            },
                        },
                        itemStyle: {
                            borderWidth: 1,
                            borderColor: '#FFFFFF',
                        }
                    }
                ],
            };
        }
    }

    public ngOnInit(): void {
    }

    constructor(private configurationService: ConfigurationService) {
    }

    public onChartInit($event: any) {

    }

    public onChartRendered($event: unknown) {

    }
}
