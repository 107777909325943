/**
 * Created by Alex Klein <alex@rocketloop.de> on 10/17/17.
 */

import { Action } from '@ngrx/store';
import { WatchlistLoadingState } from '../../models/watchlist.model';
import {
    LoadShortlistAction,
    LoadShortlistFailedAction,
    LoadShortlistSucceededAction,
    ShortlistActionTypes,
} from '../actions/shortlist.actions';
import {
    AddProductsToWatchlistSucceededAction,
    ClearWatchlistSucceededAction,
    CreateWatchlistSucceededAction,
    DeleteWatchlistSucceededAction,
    ExecuteWatchlistImportAction,
    ExecuteWatchlistImportFailedAction,
    ExecuteWatchlistImportSucceededAction,
    LoadWatchlistAction,
    LoadWatchlistDataAction,
    LoadWatchlistDataSucceededAction,
    LoadWatchlistEquivalenceDataAction,
    LoadWatchlistEquivalenceDataFailedAction,
    LoadWatchlistEquivalenceDataSucceededAction,
    LoadWatchlistsGroupSucceededAction,
    LoadWatchlistsSucceededAction,
    LoadWatchlistStructuresAction,
    LoadWatchlistStructuresSucceededAction,
    LoadWatchlistSucceededAction,
    LoadWatchlistTabStructuresAction,
    LoadWatchlistTabStructuresFailedAction,
    LoadWatchlistTabStructuresSucceededAction,
    LoadWatchlistValuesSucceededAction,
    PatchWatchlistSucceededAction, PerformTmcCheckAction, PerformTmcCheckFailedAction, PerformTmcCheckSucceededAction,
    RemoveProductsFromWatchlistSucceededAction,
    UpdateWatchlistSucceededAction,
    ValidateProductsForWatchlistImportAction,
    ValidateProductsForWatchlistImportFailedAction,
    ValidateProductsForWatchlistImportSucceededAction,
    WatchlistActionTypes,
} from '../actions/watchlist.actions';
import { WatchlistState } from '../state/watchlist.state';

export const initialState: WatchlistState = {
    watchlists: {},
    watchlistsGroup: null,
    watchlistsImportValidation: {},
    watchlistsImportValidationError: {},
    watchlistsImportError: {},
    watchlistsImportSuccess: {},
    watchlistsTabs: {},
    shareableTargets: null,
    products: {},
    productGroups: {},
    equivalenceProductGroups: {},
    equivalenceProducts: {},
    equivalenceError: {},
    equivalenceResultMap: {},
    watchlistToProductGroupMapping: {},
    productGroupToProductsMapping: {},
    createdWatchlist: false,
    deletedWatchlist: false,
    productsWereAdded: false,
    productsWereRemoved: false,
    lastCreatedWatchlistId: null,
    actions: null,
    loadingState: {},
    shortlists: {},
    shortlistsError: {},
    tmcCheck: {
        products: {},
        error: {},
        resultMap: {},
    },
};

const transformLoadingState = (watchlistId: string, state: WatchlistState, key: keyof WatchlistLoadingState, value: boolean) => ({
    loadingState: {
        ...state.loadingState,
        [watchlistId]: {
            ...state.loadingState[watchlistId],
            [key]: value,
        },
    },
});

/**
 * The reducer responsible for the @link{WatchlistState}
 * @param state
 * @param action
 * @returns {any}
 */
export function reducer(state: WatchlistState = initialState,
                        action: Action): WatchlistState {
    switch (action.type) {
        case WatchlistActionTypes.LOAD_WATCHLIST:
            const loadWatchListAction = action as LoadWatchlistAction;
            return {
                ...state,
                ...transformLoadingState(loadWatchListAction.payload.watchlistId, state, 'watchlist', true),
            };

        case WatchlistActionTypes.LOAD_WATCHLIST_STRUCTURES:
            const loadWatchlistStructuresAction = action as LoadWatchlistStructuresAction;
            return {
                ...state,
                ...transformLoadingState(loadWatchlistStructuresAction.payload.watchlistId, state, 'structure', true),
            };

        case WatchlistActionTypes.LOAD_WATCHLIST_DATA:
            const loadWatchlistDataAction = action as LoadWatchlistDataAction;
            return {
                ...state,
                ...transformLoadingState(loadWatchlistDataAction.payload.watchlistId, state, 'data', true),
            };

        case WatchlistActionTypes.PERFORM_TMC_CHECK: {
            const performTmcCheckAction = action as PerformTmcCheckAction;
            return {
                ...state,
                tmcCheck: {
                    error: {
                        ...state.tmcCheck.error,
                        [performTmcCheckAction.payload.watchlistId]: null,
                    },
                    products: {
                        ...state.tmcCheck.products,
                        [performTmcCheckAction.payload.watchlistId]: {},
                    },
                    resultMap: {
                        ...state.tmcCheck.resultMap,
                        [performTmcCheckAction.payload.watchlistId]: null,
                    },
                },
                ...transformLoadingState(performTmcCheckAction.payload.watchlistId, state, 'tmc', true),
            };
        }

        case WatchlistActionTypes.PERFORM_TMC_CHECK_SUCCEEDED: {
            const performTmcCheckSucceededAction = action as PerformTmcCheckSucceededAction;
            return {
                ...state,
                tmcCheck: {
                    ...state.tmcCheck,
                    products: {
                        ...state.tmcCheck.products,
                        [performTmcCheckSucceededAction.payload.watchlistId]: performTmcCheckSucceededAction.payload.data,
                    },
                    resultMap: {
                        ...state.tmcCheck.resultMap,
                        [performTmcCheckSucceededAction.payload.watchlistId]: performTmcCheckSucceededAction.payload.resultId,
                    },
                },
                ...transformLoadingState(performTmcCheckSucceededAction.payload.watchlistId, state, 'tmc', false),
            };
        }

        case WatchlistActionTypes.PERFORM_TMC_CHECK_FAILED: {
            const performTmcCheckFailedAction = action as PerformTmcCheckFailedAction;

            return {
                ...state,
                tmcCheck: {
                    ...state.tmcCheck,
                    error: {
                        ...state.tmcCheck.error,
                        [performTmcCheckFailedAction.payload.watchlistId]: performTmcCheckFailedAction.payload.error,
                    },
                },
                ...transformLoadingState(performTmcCheckFailedAction.payload.watchlistId, state, 'tmc', false),
            };
        }

        // Stores the loaded watchlists
        case WatchlistActionTypes.LOAD_WATCHLISTS_SUCCEEDED:
            const loadWatchlistsSucceededAction = action as LoadWatchlistsSucceededAction;
            return {
                ...state,
                watchlists: loadWatchlistsSucceededAction.payload.watchlists.reduce((watchlists, watchlist) => {
                    watchlists[watchlist.id] = watchlist;
                    return watchlists;
                }, {}),
            };

        case WatchlistActionTypes.LOAD_WATCHLISTS_GROUP_SUCCEEDED:
            const loadWatchlistsGroupSucceededAction = action as LoadWatchlistsGroupSucceededAction;
            return {
                ...state,
                watchlistsGroup: loadWatchlistsGroupSucceededAction.payload.group,
            };

        case WatchlistActionTypes.LOAD_WATCHLIST_EQUIVALENCE_DATA:
            const loadWatchlistEquivalenceDataAction = action as LoadWatchlistEquivalenceDataAction;

            return {
                ...state,
                equivalenceError: {
                    ...state.equivalenceError,
                    [loadWatchlistEquivalenceDataAction.payload.watchlistId]: null,
                },
                equivalenceProducts: {
                    ...state.equivalenceProducts,
                    [loadWatchlistEquivalenceDataAction.payload.watchlistId]: {},
                },
                equivalenceResultMap: {
                    ...state.equivalenceResultMap,
                    [loadWatchlistEquivalenceDataAction.payload.watchlistId]: null,
                },
                ...transformLoadingState(loadWatchlistEquivalenceDataAction.payload.watchlistId, state, 'equivalence', true),
            };

        case WatchlistActionTypes.LOAD_WATCHLIST_EQUIVALENCE_DATA_SUCCEEDED:
            const loadWatchlistEquivalenceDataSucceededAction = action as LoadWatchlistEquivalenceDataSucceededAction;

            return {
                ...state,
                equivalenceProducts: {
                    ...state.equivalenceProducts,
                    [loadWatchlistEquivalenceDataSucceededAction.payload.watchlistId]: loadWatchlistEquivalenceDataSucceededAction.payload.data,
                },
                equivalenceResultMap: {
                    ...state.equivalenceResultMap,
                    [loadWatchlistEquivalenceDataSucceededAction.payload.watchlistId]: loadWatchlistEquivalenceDataSucceededAction.payload.resultId,
                },
                ...transformLoadingState(loadWatchlistEquivalenceDataSucceededAction.payload.watchlistId, state, 'equivalence', false),
            };

        case WatchlistActionTypes.LOAD_WATCHLIST_EQUIVALENCE_DATA_FAILED:
            const loadWatchlistEquivalenceDataFailedAction = action as LoadWatchlistEquivalenceDataFailedAction;

            return {
                ...state,
                equivalenceError: {
                    ...state.equivalenceError,
                    [loadWatchlistEquivalenceDataFailedAction.payload.watchlistId]: loadWatchlistEquivalenceDataFailedAction.payload.error,
                },
                ...transformLoadingState(loadWatchlistEquivalenceDataFailedAction.payload.watchlistId, state, 'equivalence', false),
            };

        // Stores the loaded watchlist
        case WatchlistActionTypes.LOAD_WATCHLIST_SUCCEEDED:
            const loadWatchlistSucceededAction = action as LoadWatchlistSucceededAction;
            return {
                ...state,
                ...transformLoadingState(loadWatchlistSucceededAction.payload.watchlist.id, state, 'watchlist', false),
                watchlists: {
                    ...state.watchlists,
                    [loadWatchlistSucceededAction.payload.watchlist.id]: loadWatchlistSucceededAction.payload.watchlist,
                },
            };

        // Stores the newly created watchlist
        case WatchlistActionTypes.CREATE_WATCHLIST_SUCCEEDED:
            const createWatchlistsSucceededAction = action as CreateWatchlistSucceededAction;
            return {
                ...state,
                createdWatchlist: true,
                watchlists: {
                    ...state.watchlists,
                    [createWatchlistsSucceededAction.payload.watchlist.id]: createWatchlistsSucceededAction.payload.watchlist,
                },
                lastCreatedWatchlistId: createWatchlistsSucceededAction.payload.watchlist.id,
            };

        // Clear the flag that stores if the watchlist was created
        case WatchlistActionTypes.CLEAR_WATCHLIST_CREATED:
            return {
                ...state,
                createdWatchlist: false,
                lastCreatedWatchlistId: null,
            };

        // Stores the updated watchlist
        case WatchlistActionTypes.UPDATE_WATCHLIST_SUCCEEDED:
            const updateWatchlistSucceededAction = action as UpdateWatchlistSucceededAction;
            return {
                ...state,
                watchlists: {
                    ...state.watchlists,
                    [updateWatchlistSucceededAction.payload.watchlist.id]: {
                        ...state.watchlists[updateWatchlistSucceededAction.payload.watchlist.id],
                        ...updateWatchlistSucceededAction.payload.watchlist,
                    },
                },
            };

        // Stores the patched watchlist
        case WatchlistActionTypes.PATCH_WATCHLIST_SUCCEEDED:
            const patchWatchlistSucceededAction = action as PatchWatchlistSucceededAction;
            return {
                ...state,
                watchlists: {
                    ...state.watchlists,
                    [patchWatchlistSucceededAction.payload.watchlist.id]: {
                        ...state.watchlists[patchWatchlistSucceededAction.payload.watchlist.id],
                        ...patchWatchlistSucceededAction.payload.watchlist,
                    },
                },
            };

        // Delete the watchlist
        case WatchlistActionTypes.DELETE_WATCHLIST_SUCCEEDED:
            const deleteWatchlistSucceededAction = action as DeleteWatchlistSucceededAction;
            return {
                ...state,
                watchlists: {
                    ...state.watchlists,
                    [deleteWatchlistSucceededAction.payload.watchlistId]: null,
                },
                deletedWatchlist: true,
            };

        // Clear the flag that stores if the watchlist was deleted
        case WatchlistActionTypes.CLEAR_WATCHLIST_DELETED:
            return {
                ...state,
                deletedWatchlist: false,
            };

        // Stores the patched watchlist
        case WatchlistActionTypes.CLEAR_WATCHLIST_SUCCEEDED:
            const clearWatchlistSucceededAction = action as ClearWatchlistSucceededAction;
            return {
                ...state,
                watchlists: {
                    ...state.watchlists,
                    [clearWatchlistSucceededAction.payload.watchlist.id]: {
                        ...state.watchlists[clearWatchlistSucceededAction.payload.watchlist.id],
                        ...clearWatchlistSucceededAction.payload.watchlist,
                    },
                },
            };

        // Stores the new watchlist
        case WatchlistActionTypes.ADD_PRODUCTS_TO_WATCHLIST_SUCCEEDED:
            const addProductsToWatchlistSucceededAction = action as AddProductsToWatchlistSucceededAction;
            return {
                ...state,
                watchlists: {
                    ...state.watchlists,
                    [addProductsToWatchlistSucceededAction.payload.watchlist.id]: {
                        ...state.watchlists[addProductsToWatchlistSucceededAction.payload.watchlist.id],
                        ...addProductsToWatchlistSucceededAction.payload.watchlist,
                    },
                },
                productsWereAdded: true,
            };

        // Clear the flag that stores if the products were added to the watchlist
        case WatchlistActionTypes.CLEAR_PRODUCTS_ADDED_TO_WATCHLIST:
            return {
                ...state,
                productsWereAdded: false,
            };

        // Clear the flag that stores if the products were added to the watchlist
        case WatchlistActionTypes.CLEAR_PRODUCTS_REMOVED_FROM_WATCHLIST:
            return {
                ...state,
                productsWereRemoved: false,
            };

        // Stores the new watchlist
        case WatchlistActionTypes.REMOVE_PRODUCTS_FROM_WATCHLIST_SUCCEEDED:
            const removeProductsFromWatchlistSucceededAction = action as RemoveProductsFromWatchlistSucceededAction;
            return {
                ...state,
                watchlists: {
                    ...state.watchlists,
                    [removeProductsFromWatchlistSucceededAction.payload.watchlist.id]: {
                        ...state.watchlists[removeProductsFromWatchlistSucceededAction.payload.watchlist.id],
                        ...removeProductsFromWatchlistSucceededAction.payload.watchlist,
                    },
                },
                productsWereRemoved: true,
            };

        // Stores the watch list target values
        case WatchlistActionTypes.LOAD_WATCHLIST_VALUES_SUCCEEDED:
            const loadWatchlistValuesSucceededAction = action as LoadWatchlistValuesSucceededAction;
            return {
                ...state,
                shareableTargets: loadWatchlistValuesSucceededAction.payload.watchlistValues.shares,
            };

        // Stores the new watchlist
        case WatchlistActionTypes.LOAD_WATCHLIST_STRUCTURES_SUCCEEDED:
        case ShortlistActionTypes.LOAD_SHORTLIST_STRUCTURES_SUCCEEDED:
            const loadWatchlistStructuresSucceededAction = action as LoadWatchlistStructuresSucceededAction;
            return {
                ...state,
                ...transformLoadingState(loadWatchlistStructuresSucceededAction.payload.watchlistId, state, 'structure', false),
                productGroups: loadWatchlistStructuresSucceededAction.payload.structures.reduce((groups, group) => {
                    groups[group.id] = group;
                    return groups;
                }, { ...state.productGroups }),
                watchlistsTabs: {
                    ...state.watchlistsTabs,
                    [loadWatchlistStructuresSucceededAction.payload.watchlistId]: loadWatchlistStructuresSucceededAction.payload.tabs,
                },
                watchlistToProductGroupMapping: {
                    ...state.watchlistToProductGroupMapping,
                    [loadWatchlistStructuresSucceededAction.payload.watchlistId]:
                        loadWatchlistStructuresSucceededAction.payload.structures.map(
                            (structure) => structure.id),
                },
                actions: loadWatchlistStructuresSucceededAction.payload.actions,

                ...(loadWatchlistStructuresSucceededAction.payload.title ? {
                    watchlists: {
                        ...state.watchlists,
                        [loadWatchlistStructuresSucceededAction.payload.watchlistId]: {
                            ...state.watchlists[loadWatchlistStructuresSucceededAction.payload.watchlistId],
                            name: loadWatchlistStructuresSucceededAction.payload.title,
                        },
                    },
                } : {}),
            };

        case WatchlistActionTypes.LOAD_WATCHLIST_TAB_STRUCTURES: {
            const loadWatchlistTabStructures = action as LoadWatchlistTabStructuresAction;

            return {
                ...state,
                ...transformLoadingState(loadWatchlistTabStructures.payload.watchlistId, state, 'tabStructures', true),
            };
        }

        case WatchlistActionTypes.LOAD_WATCHLIST_TAB_STRUCTURES_SUCCEEDED: {
            const loadWatchlistTabStructuresSucceeded = action as LoadWatchlistTabStructuresSucceededAction;
            const overviewTab = loadWatchlistTabStructuresSucceeded.payload.tabs.find((tab) => tab.configuration.type === 'OVERVIEW');

            return {
                ...state,
                ...transformLoadingState(loadWatchlistTabStructuresSucceeded.payload.watchlistId, state, 'tabStructures', false),
                ...(overviewTab ? {
                    productGroups: overviewTab.structureInformation.structures.reduce((groups, group) => {
                        groups[group.id] = group;
                        return groups;
                    }, { ...state.productGroups }),
                    actions: overviewTab.structureInformation.actions,
                    watchlistToProductGroupMapping: {
                        ...state.watchlistToProductGroupMapping,
                        [loadWatchlistTabStructuresSucceeded.payload.watchlistId]:
                            overviewTab.structureInformation.structures.map(
                                (structure) => structure.id),
                    },
                } : {}),
                watchlistsTabs: {
                    ...state.watchlistsTabs,
                    [loadWatchlistTabStructuresSucceeded.payload.watchlistId]: loadWatchlistTabStructuresSucceeded.payload.tabs,
                },
                ...(loadWatchlistTabStructuresSucceeded.payload.title ? {
                    watchlists: {
                        ...state.watchlists,
                        [loadWatchlistTabStructuresSucceeded.payload.watchlistId]: {
                            ...state.watchlists[loadWatchlistTabStructuresSucceeded.payload.watchlistId],
                            name: loadWatchlistTabStructuresSucceeded.payload.title,
                        },
                    },
                } : {}),
            };
        }

        case WatchlistActionTypes.LOAD_WATCHLIST_TAB_STRUCTURES_FAILED: {
            const loadWatchlistTabStructuresFailed = action as LoadWatchlistTabStructuresFailedAction;

            return {
                ...state,
                ...transformLoadingState(loadWatchlistTabStructuresFailed.payload.watchlistId, state, 'tabStructures', false),
            };
        }

        // Stores the new watchlist
        case WatchlistActionTypes.LOAD_WATCHLIST_DATA_SUCCEEDED:
        case ShortlistActionTypes.LOAD_SHORTLIST_DATA_SUCCEEDED:
            const loadWatchlistDataSucceededAction = action as LoadWatchlistDataSucceededAction;
            const entries = {};
            Object.keys(loadWatchlistDataSucceededAction.payload.data).map((key) => {
                loadWatchlistDataSucceededAction.payload.data[key].map((entry) => entries[entry.id] = entry);
            });
            return {
                ...state,
                ...transformLoadingState(loadWatchlistDataSucceededAction.payload.watchlistId, state, 'data', false),
                productGroupToProductsMapping: Object.keys(loadWatchlistDataSucceededAction.payload.data).reduce(
                    (groups, key) => {
                        groups[key] = loadWatchlistDataSucceededAction.payload.data[key].map((entry) => entry.id);
                        return groups;
                    }, {
                        ...state.productGroupToProductsMapping,
                    }),
                products: entries,
            };

        case WatchlistActionTypes.VALIDATE_WATCHLIST_IMPORT: {
            const validateProductsForWatchlistImportAction = action as ValidateProductsForWatchlistImportAction;

            return {
                ...state,
                ...transformLoadingState(validateProductsForWatchlistImportAction.payload.watchlistId, state, 'validate', true),
                watchlistsImportValidationError: {
                    ...state.watchlistsImportValidationError,
                    [validateProductsForWatchlistImportAction.payload.watchlistId]: null,
                },
            };
        }

        case WatchlistActionTypes.VALIDATE_WATCHLIST_IMPORT_SUCCEEDED: {
            const validateProductsForWatchlistImportSucceededAction = action as ValidateProductsForWatchlistImportSucceededAction;
            const watchlistId = validateProductsForWatchlistImportSucceededAction.payload.watchlistId;

            return {
                ...state,
                ...transformLoadingState(watchlistId, state, 'validate', false),
                watchlistsImportValidation: {
                    ...state.watchlistsImportValidation,
                    [watchlistId]: {
                        ...validateProductsForWatchlistImportSucceededAction.payload.data,
                    },
                },
            };
        }

        case WatchlistActionTypes.VALIDATE_WATCHLIST_IMPORT_FAILED: {
            const validateProductsForWatchlistImportFailedAction = action as ValidateProductsForWatchlistImportFailedAction;
            const watchlistId = validateProductsForWatchlistImportFailedAction.payload.watchlistId;

            return {
                ...state,
                ...transformLoadingState(watchlistId, state, 'validate', false),
                watchlistsImportValidationError: {
                    ...state.watchlistsImportValidationError,
                    [watchlistId]: validateProductsForWatchlistImportFailedAction.payload.error,
                },
            };
        }

        case WatchlistActionTypes.EXECUTE_WATCHLIST_IMPORT: {
            const executeWatchlistImportAction = action as ExecuteWatchlistImportAction;

            return {
                ...state,
                ...transformLoadingState(executeWatchlistImportAction.payload.watchlistId, state, 'import', true),
                watchlistsImportSuccess: {
                    ...state.watchlistsImportSuccess,
                    [executeWatchlistImportAction.payload.watchlistId]: false,
                },
                watchlistsImportError: {
                    ...state.watchlistsImportError,
                    [executeWatchlistImportAction.payload.watchlistId]: null,
                },
            };
        }

        case WatchlistActionTypes.EXECUTE_WATCHLIST_IMPORT_SUCCEEDED: {
            const executeWatchlistImportSucceededAction = action as ExecuteWatchlistImportSucceededAction;

            return {
                ...state,
                ...transformLoadingState(executeWatchlistImportSucceededAction.payload.watchlistId, state, 'import', false),
                watchlistsImportSuccess: {
                    ...state.watchlistsImportSuccess,
                    [executeWatchlistImportSucceededAction.payload.watchlistId]: true,
                },
                watchlists: {
                    ...state.watchlists,
                    [executeWatchlistImportSucceededAction.payload.watchlistId]: executeWatchlistImportSucceededAction.payload.data,
                },
            };
        }

        case WatchlistActionTypes.EXECUTE_WATCHLIST_IMPORT_FAILED: {
            const executeWatchlistImportFailedAction = action as ExecuteWatchlistImportFailedAction;

            return {
                ...state,
                ...transformLoadingState(executeWatchlistImportFailedAction.payload.watchlistId, state, 'import', false),
                watchlistsImportError: {
                    ...state.watchlistsImportError,
                    [executeWatchlistImportFailedAction.payload.watchlistId]: executeWatchlistImportFailedAction.payload.error,
                },
            };
        }

        case ShortlistActionTypes.LOAD_SHORTLIST:
            const loadShortlistAction = action as LoadShortlistAction;
            return {
                ...state,
                ...transformLoadingState(loadShortlistAction.payload.shortlistId, state, 'watchlist', true),
            };

        case ShortlistActionTypes.LOAD_SHORTLIST_FAILED:
            const loadShortlistFailedAction = action as LoadShortlistFailedAction;
            return {
                ...state,
                ...transformLoadingState(loadShortlistFailedAction.payload.shortlistId, state, 'watchlist', false),
            };

        case ShortlistActionTypes.LOAD_SHORTLIST_SUCCEEDED:
            const loadShortlistSucceededAction = action as LoadShortlistSucceededAction;

            return {
                ...state,
                ...transformLoadingState(loadShortlistSucceededAction.payload.shortlistId, state, 'watchlist', false),
                shortlists: {
                    ...state.shortlists,
                    [loadShortlistSucceededAction.payload.shortlistId]: {
                        data: loadShortlistSucceededAction.payload.data,
                        structure: loadShortlistSucceededAction.payload.structure,
                    },
                },
            };

        default:
            return state;
    }
}
