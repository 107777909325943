import { Injectable } from "@angular/core";
import { Actions, concatLatestFrom, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { EMPTY } from "rxjs";
import { of } from "rxjs";
import { catchError, filter, map, switchMap } from "rxjs/operators";
import * as ProductDetailsSelectors from "../../../routes/product-details/store/selectors/product-details.selectors";
import { truthy } from "../../../shared/helpers/general.helper";
import { AdApi } from "../../api/ad.api";
import { AppError } from "../../errors/base.errors";
import * as AdAction from "../actions/ad.action";
import * as UIAction from "../actions/ui.action";
import { ActionTypes } from "../actions/ui.action";
import * as AuthSelectors from "../selectors/auth.selectors";
import * as RouterSelectors from "../selectors/router.selectors";
import * as UiSelectors from "../selectors/ui.selectors";

@Injectable()
export class AdEffects {

    constructor(
        private actions$: Actions,
        private store: Store,
        private adApi: AdApi
    ) { }

    public loadAd$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AdAction.loadAd),
            concatLatestFrom(() => this.store.select(UiSelectors.selectOverlayOpen).pipe(
                filter((overlayOpen) => !overlayOpen)
            )),
            concatLatestFrom(() => [
                this.store.select(AuthSelectors.selectAuthUserId),
                this.store.select(RouterSelectors.selectContext).pipe(
                    filter(truthy)
                ),
                this.store.select(RouterSelectors.selectCastedParameter)
            ]),
            switchMap(([, userId, context, castedParameter]) =>
                this.adApi.getAd(userId, context, castedParameter).pipe(
                    map((response) =>
                        AdAction.setAd({ data: response.data })
                    ),
                    catchError((error: AppError) =>
                        error.statusCode === 404 ? of(AdAction.resetAd()) : EMPTY
                    )
                )
            )
        )
    });

    public loadAdForOverlay$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AdAction.loadAd),
            concatLatestFrom(() => this.store.select(UiSelectors.selectOverlayOpen).pipe(
                filter((overlayOpen) => overlayOpen)
            )),
            concatLatestFrom(() => [
                this.store.select(AuthSelectors.selectAuthUserId),
                this.store.select(RouterSelectors.selectOverlayContext).pipe(
                    filter(truthy)
                ),
                this.store.select(ProductDetailsSelectors.selectProductDq).pipe(
                    filter(truthy)
                )
            ]),
            switchMap(([, userId, context, productDq]) =>
                this.adApi.getAd(userId, context, { productDq }).pipe(
                    map((response) =>
                        AdAction.setAd({ data: response.data })
                    ),
                    catchError((error: AppError) =>
                        error.statusCode === 404 ? of(AdAction.resetAd()) : EMPTY
                    )
                )
            )
        )
    });

    public resetAdOnOverlayStateChange$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                ActionTypes.SET_OVERLAY_OPEN,
                ActionTypes.SET_OVERLAY_CLOSED
            ),
            map(() => AdAction.resetAd())
        )
    });

    public resetAdOnPageInvisible$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UIAction.setPageVisibility),
            filter((action) => !action.visible),
            map(() => AdAction.resetAd())
        )
    });

    public activatePollingOnPageVisible$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UIAction.setPageVisibility),
            filter((action) => action.visible),
            map(() => AdAction.activatePolling())
        );
    });
}
