<div class="tw-overflow-x-auto">
    <table class="tw-table-fixed 2xl:tw-w-full">
        <tbody>
            <ng-container *ngFor="let instrumentGroups of tranformedData; let lastInstrumentGroups = last">
                <ng-container *ngFor="let instrumentGroup of instrumentGroups; let lastInstrumentGroup = last">
                    <tr
                        [ngClass]="{
                            'tw-border-b tw-border-neutral-300 dark:tw-border-old-gray-200' : !lastInstrumentGroup,
                            'tw-border-b-2 tw-border-neutral-300 dark:tw-border-old-gray-200' : lastInstrumentGroup && !lastInstrumentGroups
                        }">
                        <ng-container *ngFor="let instrument of instrumentGroup; let first = first">
                            <td
                                class="tw-px-0 tw-text-sm tw-py-2.5 tw-pl-7.5 tw-pr-5 tw-align-top"
                                [ngClass]="{'tw-pl-7.5' : !first}"
                                [ngStyle]="columnWidth()">
                                <a 
                                    [routerLink]="['/app', { outlets: { overlay: ['product-details', instrument.dq] } }]"
                                    class="tw-uppercase tw-text-black hover:tw-text-primary-base dark:tw-text-old-black">{{ instrument.label }}</a>
                            </td>
                            <td class="tw-align-top tw-pt-1.5 tw-w-6">
                                <ng-container *ngIf="instrument.prevDayPerformance === 0"><span class="material-symbols-rounded tw-text-xl tw-text-neutral-400 tw-block">arrow_forward</span></ng-container>
                                <ng-container *ngIf="instrument.prevDayPerformance > 0"><span class="material-symbols-rounded tw-text-xl tw-text-lime-600 tw-block">arrow_upward</span></ng-container>
                                <ng-container *ngIf="instrument.prevDayPerformance < 0"><span class="material-symbols-rounded tw-text-xl tw-text-red-600 tw-block">arrow_downward</span></ng-container>
                            </td>
                            <td class="tw-align-top tw-text-sm tw-text-right tw-text-black tw-py-2.5 tw-pl-5 dark:tw-text-old-black">{{ (instrument.prevDayPerformance || instrument.prevDayPerformance === 0) ? (instrument.prevDayPerformance | translocoPercent) : '-' }}</td>
                            <td class="tw-align-top tw-text-sm tw-text-right tw-text-black tw-py-2.5 tw-pl-5 dark:tw-text-old-black">{{ (instrument.quote || instrument.quote === 0) ? (instrument.quote | translocoDecimal) : '-' }}</td>
                        </ng-container>
                    </tr>
                </ng-container>
            </ng-container>
        </tbody>
    </table>
</div>
