import { Entity } from '../../../../shared/models/entities.model';
import { COLUMN_MONTHS } from './data/tableConfig';
import { VolatilityDataRequestResponse } from './model/market-analysis.model';

export function transformVolaDataToEntities(data, filter): Entity[] {
    const volaType = filter.volaType || 'ttm';
    const volaTypeBy = filter[volaType] || (volaType === 'ttm' ? '1y' : 'atm');

    return Object.keys(data).reduce((arr, key) => {
        const item = { ... data[key] } as Entity;
        item.id = item.isin;
        item.impliedVolatility = Object.keys(item.impliedVolatility).reduce((acc, volatilityKey) => {
            acc[volatilityKey] = item.impliedVolatility[volatilityKey].map((n) => n && n * 100 || n);
            return acc;
        }, {});

        item.__processed = true;

        arr.push(item);
        return arr;
    }, []);
}

export function transformDiviDataToEntities(data, filter): Entity[] {
    return Object.keys(data).reduce((arr, key) => {
        const item = data[key] as Entity;
        item.isin = item.ids.isin;
        item.id = item.isin;
        item.date = new Date(item.date);

        arr.push(item);
        return arr;
    }, []);
}

export function transformVolaDataForMatrix(data): VolatilityDataRequestResponse {
    const entity = {...data};
    entity.id = data.isin;

    const tableData = [];
    const MONEYNESS = [ 70, 80, 90, 100, 110, 120, 130 ];

    for (let i = 0; i < MONEYNESS.length; i++) {
        tableData.push({
            ttm: MONEYNESS[i],
            moneyness: [...COLUMN_MONTHS.map((k) => {
                if (!entity.__processed) {
                    const n = entity.impliedVolatility[k][i];
                    return n && n * 100 || n;
                }

                return entity.impliedVolatility[k][i];
            })],
        });
    }

    entity.__processed = true;

    return {
        entity,
        data: tableData,
    };
}

export function arraysToObjects(properties: string[], values: any[][]) {
   return values.map((v) => properties.reduce((obj: any, prop: string, index) => {
        obj[prop] = v[index];
        return obj;
    }, {}));
}
