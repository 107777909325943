/**
 * Created by Alex Klein <alex@rocketloop.de> on 10/17/17.
 */

import { Action, createAction, props } from '@ngrx/store';
import { AppError } from '../../../../../core/errors/base.errors';
import { type } from '../../../../../core/store/util';
import { GenericActionStructure } from '../../../../../shared/components/entity-actions/entity-actions.component';
import { MultiRowComments } from '../../../../../shared/components/entity-table-multi-row-comment/entity-table-multi-row-comment.interface';
import { WatchlistProductsCheckData } from '../../api/models/watchlist.model';
import {
    CreateWatchlistRequest,
    Watchlist,
    WatchlistData, WatchlistImportProduct, WatchlistImportValidationResult,
    WatchlistProductGroup,
    WatchlistsGroup, WatchlistTab,
    WatchlistValues,
} from '../../models/watchlist.model';

export const WatchlistActionTypes = {
    LOAD_WATCHLISTS: type('WATCHLIST:LOAD_WATCHLISTS'),
    LOAD_WATCHLISTS_SUCCEEDED: type('WATCHLIST:LOAD_WATCHLISTS_SUCCEEDED'),
    LOAD_WATCHLISTS_FAILED: type('WATCHLIST:LOAD_WATCHLISTS_FAILED'),

    LOAD_WATCHLISTS_GROUP: type('WATCHLIST:LOAD_WATCHLISTS_GROUP'),
    LOAD_WATCHLISTS_GROUP_SUCCEEDED: type('WATCHLIST:LOAD_WATCHLISTS_GROUP_SUCCEEDED'),
    LOAD_WATCHLISTS_GROUP_FAILED: type('WATCHLIST:LOAD_WATCHLISTS_GROUP_FAILED'),

    LOAD_WATCHLIST: type('WATCHLIST:LOAD_WATCHLIST'),
    LOAD_WATCHLIST_SUCCEEDED: type('WATCHLIST:LOAD_WATCHLIST_SUCCEEDED'),
    LOAD_WATCHLIST_FAILED: type('WATCHLIST:LOAD_WATCHLIST_FAILED'),

    LOAD_WATCHLIST_VALUES: type('WATCHLIST:LOAD_WATCHLISTS_VALUES'),
    LOAD_WATCHLIST_VALUES_SUCCEEDED: type('WATCHLIST:LOAD_WATCHLISTS_VALUES_SUCCEEDED'),
    LOAD_WATCHLIST_VALUES_FAILED: type('WATCHLIST:LOAD_WATCHLISTS_VALUES_FAILED'),

    CREATE_WATCHLIST: type('WATCHLIST:CREATE_WATCHLIST'),
    CREATE_WATCHLIST_SUCCEEDED: type('WATCHLIST:CREATE_WATCHLIST_SUCCEEDED'),
    CREATE_WATCHLIST_FAILED: type('WATCHLIST:CREATE_WATCHLIST_FAILED'),

    VALIDATE_WATCHLIST_IMPORT: type('WATCHLIST:VALIDATE_WATCHLIST_IMPORT'),
    VALIDATE_WATCHLIST_IMPORT_SUCCEEDED: type('WATCHLIST:VALIDATE_WATCHLIST_IMPORT_SUCCEEDED'),
    VALIDATE_WATCHLIST_IMPORT_FAILED: type('WATCHLIST:VALIDATE_WATCHLIST_IMPORT_FAILED'),

    EXECUTE_WATCHLIST_IMPORT: type('WATCHLIST:EXECUTE_WATCHLIST_IMPORT'),
    EXECUTE_WATCHLIST_IMPORT_SUCCEEDED: type('WATCHLIST:EXECUTE_WATCHLIST_IMPORT_SUCCEEDED'),
    EXECUTE_WATCHLIST_IMPORT_FAILED: type('WATCHLIST:EXECUTE_WATCHLIST_IMPORT_FAILED'),

    CLEAR_WATCHLIST_CREATED: type('WATCHLIST:CLEAR_WATCHLIST_CREATED'),

    UPDATE_WATCHLIST: type('WATCHLIST:UPDATE_WATCHLIST'),
    UPDATE_WATCHLIST_SUCCEEDED: type('WATCHLIST:UPDATE_WATCHLIST_SUCCEEDED'),
    UPDATE_WATCHLIST_FAILED: type('WATCHLIST:UPDATE_WATCHLIST_FAILED'),

    PATCH_WATCHLIST: type('WATCHLIST:PATCH_WATCHLIST'),
    PATCH_WATCHLIST_SUCCEEDED: type('WATCHLIST:PATCH_WATCHLIST_SUCCEEDED'),
    PATCH_WATCHLIST_FAILED: type('WATCHLIST:PATCH_WATCHLIST_FAILED'),

    DELETE_WATCHLIST: type('WATCHLIST:DELETE_WATCHLIST'),
    DELETE_WATCHLIST_SUCCEEDED: type('WATCHLIST:DELETE_WATCHLIST_SUCCEEDED'),
    DELETE_WATCHLIST_FAILED: type('WATCHLIST:DELETE_WATCHLIST_FAILED'),

    CLEAR_WATCHLIST_DELETED: type('WATCHLIST:CLEAR_WATCHLIST_DELETED'),

    CLEAR_WATCHLIST: type('WATCHLIST:CLEAR_WATCHLIST'),
    CLEAR_WATCHLIST_SUCCEEDED: type('WATCHLIST:CLEAR_WATCHLIST_SUCCEEDED'),
    CLEAR_WATCHLIST_FAILED: type('WATCHLIST:CLEAR_WATCHLIST_FAILED'),

    ADD_PRODUCTS_TO_WATCHLIST: type('WATCHLIST:ADD_PRODUCTS_TO_WATCHLIST'),
    ADD_PRODUCTS_TO_WATCHLIST_SUCCEEDED: type('WATCHLIST:ADD_PRODUCTS_TO_WATCHLIST_SUCCEEDED'),
    ADD_PRODUCTS_TO_WATCHLIST_FAILED: type('WATCHLIST:ADD_PRODUCTS_TO_WATCHLIST_FAILED'),

    CLEAR_PRODUCTS_ADDED_TO_WATCHLIST: type('WATCHLIST:CLEAR_PRODUCTS_ADDED_TO_WATCHLIST'),
    CLEAR_PRODUCTS_REMOVED_FROM_WATCHLIST: type('WATCHLIST:CLEAR_PRODUCTS_REMOVED_FROM_WATCHLIST'),

    REMOVE_PRODUCTS_FROM_WATCHLIST: type('WATCHLIST:REMOVE_PRODUCTS_FROM_WATCHLIST'),
    REMOVE_PRODUCTS_FROM_WATCHLIST_SUCCEEDED: type('WATCHLIST:REMOVE_PRODUCTS_FROM_WATCHLIST_SUCCEEDED'),
    REMOVE_PRODUCTS_FROM_WATCHLIST_FAILED: type('WATCHLIST:REMOVE_PRODUCTS_FROM_WATCHLIST_FAILED'),

    LOAD_WATCHLIST_STRUCTURES: type('WATCHLIST:LOAD_WATCHLIST_STRUCTURES'),
    LOAD_WATCHLIST_STRUCTURES_SUCCEEDED: type('WATCHLIST:LOAD_WATCHLIST_STRUCTURES_SUCCEEDED'),
    LOAD_WATCHLIST_STRUCTURES_FAILED: type('WATCHLIST:LOAD_WATCHLIST_STRUCTURES_FAILED'),

    LOAD_SINGLE_WATCHLIST_STRUCTURE: type('WATCHLIST:LOAD_SINGLE_WATCHLIST_STRUCTURE'),
    LOAD_SINGLE_WATCHLIST_STRUCTURE_SUCCEEDED: type('WATCHLIST:LOAD_SINGLE_WATCHLIST_STRUCTURE_SUCCEEDED'),
    LOAD_SINGLE_WATCHLIST_STRUCTURE_FAILED: type('WATCHLIST:LOAD_SINGLE_WATCHLIST_STRUCTURE_FAILED'),

    LOAD_WATCHLIST_DATA: type('WATCHLIST:LOAD_WATCHLIST_DATA'),
    LOAD_WATCHLIST_DATA_SUCCEEDED: type('WATCHLIST:LOAD_WATCHLIST_DATA_SUCCEEDED'),
    LOAD_WATCHLIST_DATA_FAILED: type('WATCHLIST:LOAD_WATCHLIST_DATA_FAILED'),

    LOAD_SINGLE_WATCHLIST_DATA: type('WATCHLIST:LOAD_SINGLE_WATCHLIST_DATA'),
    LOAD_SINGLE_WATCHLIST_DATA_SUCCEEDED: type('WATCHLIST:LOAD_SINGLE_WATCHLIST_DATA_SUCCEEDED'),
    LOAD_SINGLE_WATCHLIST_DATA_FAILED: type('WATCHLIST:LOAD_SINGLE_WATCHLIST_DATA_FAILED'),

    LOAD_WATCHLIST_TAB_STRUCTURES: type('WATCHLIST:LOAD_WATCHLIST_TAB_STRUCTURES'),
    LOAD_WATCHLIST_TAB_STRUCTURES_SUCCEEDED: type('WATCHLIST:LOAD_WATCHLIST_TAB_STRUCTURES_SUCCEEDED'),
    LOAD_WATCHLIST_TAB_STRUCTURES_FAILED: type('WATCHLIST:LOAD_WATCHLIST_TAB_STRUCTURES_FAILED'),

    LOAD_WATCHLIST_EQUIVALENCE_DATA: type('WATCHLIST:LOAD_WATCHLIST_EQUIVALENCE_DATA'),
    LOAD_WATCHLIST_EQUIVALENCE_DATA_SUCCEEDED: type('WATCHLIST:LOAD_WATCHLIST_EQUIVALENCE_DATA_SUCCEEDED'),
    LOAD_WATCHLIST_EQUIVALENCE_DATA_FAILED: type('WATCHLIST:LOAD_WATCHLIST_EQUIVALENCE_DATA_FAILED'),

    PERFORM_TMC_CHECK: type('WATCHLIST:PERFORM_TMC_CHECK'),
    PERFORM_TMC_CHECK_SUCCEEDED:  type('WATCHLIST:PERFORM_TMC_CHECK_SUCCEEDED'),
    PERFORM_TMC_CHECK_FAILED: type('WATCHLIST:PERFORM_TMC_CHECK_FAILED'),
};

export interface PerformTmcCheckAction extends Action {
    payload: {
        watchlistId: string;
    };
}

export function performTmcCheck(watchlistId: string): PerformTmcCheckAction {
    return {
        type: WatchlistActionTypes.PERFORM_TMC_CHECK,
        payload: {
            watchlistId,
        },
    };
}

export interface PerformTmcCheckSucceededAction extends Action {
    payload: {
        watchlistId: string;
        resultId?: string;
        data: WatchlistProductsCheckData;
    };
}

export function performTmcCheckSucceeded(watchlistId: string, data: WatchlistProductsCheckData): PerformTmcCheckSucceededAction {
    return {
        type: WatchlistActionTypes.PERFORM_TMC_CHECK_SUCCEEDED,
        payload: {
            watchlistId,
            data,
        },
    };
}

export interface PerformTmcCheckFailedAction extends Action {
    payload: {
        watchlistId: string;
        error: AppError;
    };
}

export function performTmcCheckFailed(watchlistId: string, error: AppError): PerformTmcCheckFailedAction {
    return {
        type: WatchlistActionTypes.PERFORM_TMC_CHECK_FAILED,
        payload: {
            watchlistId,
            error,
        },
    };
}

/** LoadWatchlistEquivalenceData **/
export interface LoadWatchlistEquivalenceDataAction extends Action {
    payload: {
        watchlistId: string;
        deriBwIds: Array<string>;
    };
}

export function loadWatchlistEquivalenceData(watchlistId: string, deriBwIds: Array<string>): LoadWatchlistEquivalenceDataAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLIST_EQUIVALENCE_DATA,
        payload: {
            watchlistId,
            deriBwIds
        }
    };
}

export interface LoadWatchlistEquivalenceDataSucceededAction extends Action {
    payload: {
        watchlistId: string;
        resultId: string;
        data: WatchlistProductsCheckData;
    };
}

export function loadWatchlistEquivalenceDataSucceeded(watchlistId: string, resultId: string, data: WatchlistProductsCheckData): LoadWatchlistEquivalenceDataSucceededAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLIST_EQUIVALENCE_DATA_SUCCEEDED,
        payload: {
            watchlistId,
            resultId,
            data,
        },
    };
}

export interface LoadWatchlistEquivalenceDataFailedAction extends Action {
    payload: {
        watchlistId: string;
        error: AppError;
    };
}

export function loadWatchlistEquivalenceDataFailed(watchlistId: string, error: AppError): LoadWatchlistEquivalenceDataFailedAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLIST_EQUIVALENCE_DATA_FAILED,
        payload: {
            watchlistId,
            error,
        },
    };
}

export const updateWatchlistEquivalenceComments = createAction(
    '[Watchlist] Update Equivalence Comments',
    props<{
        watchlistId: string;
        comments: MultiRowComments;
    }>()
);

/** LoadWatchlistsAction **/

export interface LoadWatchlistsAction extends Action {
    payload: object;
}

export function loadWatchlists(): LoadWatchlistsAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLISTS,
        payload: {},
    };
}

/** LoadWatchlistsSucceededAction **/

export interface LoadWatchlistsSucceededAction extends Action {
    payload: {
        watchlists: Watchlist[];
    };
}

export function loadWatchlistsSucceeded(watchlists: Watchlist[]): LoadWatchlistsSucceededAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLISTS_SUCCEEDED,
        payload: {
            watchlists,
        },
    };
}

/** LoadWatchlistsFailedAction **/

export interface LoadWatchlistsFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadWatchlistsFailed(error: AppError): LoadWatchlistsFailedAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLISTS_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadWatchlistsGroupAction **/

export interface LoadWatchlistsGroupAction extends Action {
    payload: {
        moduleId: string,
    };
}

export function loadWatchlistsGroup(moduleId: string): LoadWatchlistsGroupAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLISTS_GROUP,
        payload: {
            moduleId,
        },
    };
}

/** LoadWatchlistsGroupSucceededAction **/

export interface LoadWatchlistsGroupSucceededAction extends Action {
    payload: {
        group: WatchlistsGroup,
    };
}

export function loadWatchlistsGroupSucceeded(group: WatchlistsGroup): LoadWatchlistsGroupSucceededAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLISTS_GROUP_SUCCEEDED,
        payload: {
            group,
        },
    };
}

/** LoadWatchlistsFailedAction **/

export interface LoadWatchlistsGroupFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadWatchlistsGroupFailed(error: AppError): LoadWatchlistsGroupFailedAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLISTS_GROUP_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadWatchlistAction **/

export interface LoadWatchlistAction extends Action {
    payload: {
        watchlistId: string;
    };
}

export function loadWatchlist(watchlistId: string): LoadWatchlistAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLIST,
        payload: {
            watchlistId,
        },
    };
}

/** LoadWatchlistSucceededAction **/

export interface LoadWatchlistSucceededAction extends Action {
    payload: {
        watchlist: Watchlist;
    };
}

export function loadWatchlistSucceeded(watchlist: Watchlist): LoadWatchlistSucceededAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLIST_SUCCEEDED,
        payload: {
            watchlist,
        },
    };
}

/** LoadWatchlistFailedAction **/

export interface LoadWatchlistFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadWatchlistFailed(error: AppError): LoadWatchlistsFailedAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLIST_FAILED,
        payload: {
            error,
        },
    };
}

/** Load Watchlist Values Actions **/

export interface LoadWatchlistValuesAction extends Action {
    payload: object;
}

/**
 * LoadWatchlistsAction
 * @returns {Action}
 */
export function loadWatchlistValues(): LoadWatchlistValuesAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLIST_VALUES,
        payload: {},
    };
}

/** Load Watchlist Succeeded Actions **/

export interface LoadWatchlistValuesSucceededAction extends Action {
    payload: {
        watchlistValues: WatchlistValues;
    };
}

/**
 * LoadWatchlistValuesSucceededAction
 * @returns {Action}
 */
export function loadWatchlistValuesSucceeded(watchlistValues: WatchlistValues): LoadWatchlistValuesSucceededAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLIST_VALUES_SUCCEEDED,
        payload: {
            watchlistValues,
        },
    };
}

/** LoadWatchlistValuesFailedAction **/

export interface LoadWatchlistValuesFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadWatchlistValuesFailed(error: AppError): LoadWatchlistValuesFailedAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLIST_VALUES_FAILED,
        payload: {
            error,
        },
    };
}

/** Create Watchlist Actions **/

export interface CreateWatchlistsAction extends Action {
    payload: {
        request: CreateWatchlistRequest,
    };
}

/**
 * CreateWatchlistAction
 * @returns {Action}
 */
export function createWatchlist(request: CreateWatchlistRequest): CreateWatchlistsAction {
    return {
        type: WatchlistActionTypes.CREATE_WATCHLIST,
        payload: {
            request,
        },
    };
}

/** Create Watchlist SucceededActions **/

export interface CreateWatchlistSucceededAction extends Action {
    payload: {
        watchlist: Watchlist;
    };
}

/**
 * CreateWatchlistSucceededAction
 * @returns {Action}
 */
export function createWatchlistSucceeded(watchlist: Watchlist): CreateWatchlistSucceededAction {
    return {
        type: WatchlistActionTypes.CREATE_WATCHLIST_SUCCEEDED,
        payload: {
            watchlist,
        },
    };
}

/** ClearWatchlistCreatedAction **/

export interface ClearWatchlistCreatedAction extends Action {
    payload?: void;
}

export function clearWatchlistCreated(): ClearWatchlistCreatedAction {
    return {
        type: WatchlistActionTypes.CLEAR_WATCHLIST_CREATED,
    };
}

/** CreateWatchlistFailedAction **/

export interface CreateWatchlistsFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function createWatchlistFailed(error: AppError): CreateWatchlistsFailedAction {
    return {
        type: WatchlistActionTypes.CREATE_WATCHLIST_FAILED,
        payload: {
            error,
        },
    };
}

/** Create Watchlist Actions **/

export interface UpdateWatchlistAction extends Action {
    payload: {
        watchlistId: string;
        data: any
    };
}

/**
 * UpdateWatchlistAction
 * @returns {Action}
 */
export function updateWatchlist(watchlistId: string, data: any): UpdateWatchlistAction {
    return {
        type: WatchlistActionTypes.UPDATE_WATCHLIST,
        payload: {
            watchlistId,
            data,
        },
    };
}

/** Update Watchlist SucceededActions **/

export interface UpdateWatchlistSucceededAction extends Action {
    payload: {
        watchlist: Watchlist;
    };
}

/**
 * UpdateWatchlistSucceededAction
 * @returns {Action}
 */
export function updateWatchlistSucceeded(watchlist: Watchlist): UpdateWatchlistSucceededAction {
    return {
        type: WatchlistActionTypes.UPDATE_WATCHLIST_SUCCEEDED,
        payload: {
            watchlist,
        },
    };
}

/** UpdateWatchlistFailedAction **/

export interface UpdateWatchlistFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function updateWatchlistFailed(error: AppError): UpdateWatchlistFailedAction {
    return {
        type: WatchlistActionTypes.UPDATE_WATCHLIST_FAILED,
        payload: {
            error,
        },
    };
}

/** Patch Watchlist Actions **/

export interface PatchWatchlistAction extends Action {
    payload: {
        watchlistId: string;
        data: any
    };
}

/**
 * PatchWatchlistAction
 * @returns {Action}
 */
export function patchWatchlist(watchlistId: string, data: any): PatchWatchlistAction {
    return {
        type: WatchlistActionTypes.PATCH_WATCHLIST,
        payload: {
            watchlistId,
            data,
        },
    };
}

/** Patch Watchlist SucceededActions **/

export interface PatchWatchlistSucceededAction extends Action {
    payload: {
        watchlist: Watchlist;
    };
}

/**
 * PatchWatchlistSucceededAction
 * @returns {Action}
 */
export function patchWatchlistSucceeded(watchlist: Watchlist): PatchWatchlistSucceededAction {
    return {
        type: WatchlistActionTypes.PATCH_WATCHLIST_SUCCEEDED,
        payload: {
            watchlist,
        },
    };
}

/** PatchWatchlistFailedAction **/

export interface PatchWatchlistFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function patchWatchlistFailed(error: AppError): PatchWatchlistFailedAction {
    return {
        type: WatchlistActionTypes.PATCH_WATCHLIST_FAILED,
        payload: {
            error,
        },
    };
}

/** Delete Watchlist Actions **/

export interface DeleteWatchlistAction extends Action {
    payload: {
        watchlistId: string;
    };
}

/**
 * DeleteWatchlistAction
 * @returns {Action}
 */
export function deleteWatchlist(watchlistId: string): DeleteWatchlistAction {
    return {
        type: WatchlistActionTypes.DELETE_WATCHLIST,
        payload: {
            watchlistId,
        },
    };
}

/** Delete Watchlist SucceededActions **/

export interface DeleteWatchlistSucceededAction extends Action {
    payload: {
        watchlistId: string;
    };
}

/**
 * DeleteWatchlistSucceededAction
 * @returns {Action}
 */
export function deleteWatchlistSucceeded(watchlistId: string): DeleteWatchlistSucceededAction {
    return {
        type: WatchlistActionTypes.DELETE_WATCHLIST_SUCCEEDED,
        payload: {
            watchlistId,
        },
    };
}

/** DeleteWatchlistFailedAction **/

export interface DeleteWatchlistFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function deleteWatchlistFailed(error: AppError): DeleteWatchlistFailedAction {
    return {
        type: WatchlistActionTypes.DELETE_WATCHLIST_FAILED,
        payload: {
            error,
        },
    };
}

/** ClearWatchlistDeletedAction **/

export interface ClearWatchlistDeletedAction extends Action {
    payload?: void;
}

export function clearWatchlistDeleted(): ClearWatchlistDeletedAction {
    return {
        type: WatchlistActionTypes.CLEAR_WATCHLIST_DELETED,
    };
}

/** Clear Watchlist Actions **/

export interface ClearWatchlistAction extends Action {
    payload: {
        watchlistId: string;
    };
}

/**
 * ClearWatchlistAction
 * @returns {Action}
 */
export function clearWatchlist(watchlistId: string): ClearWatchlistAction {
    return {
        type: WatchlistActionTypes.CLEAR_WATCHLIST,
        payload: {
            watchlistId,
        },
    };
}

/** Clear Watchlist SucceededActions **/

export interface ClearWatchlistSucceededAction extends Action {
    payload: {
        watchlist: Watchlist;
    };
}

/**
 * ClearWatchlistSucceededAction
 * @returns {Action}
 */
export function clearWatchlistSucceeded(watchlist: Watchlist): ClearWatchlistSucceededAction {
    return {
        type: WatchlistActionTypes.CLEAR_WATCHLIST_SUCCEEDED,
        payload: {
            watchlist,
        },
    };
}

/** ClearWatchlistFailedAction **/

export interface ClearWatchlistFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function clearWatchlistFailed(error: AppError): ClearWatchlistFailedAction {
    return {
        type: WatchlistActionTypes.CLEAR_WATCHLIST_FAILED,
        payload: {
            error,
        },
    };
}

/** ValidateProductsForWatchlistImportAction **/
export interface ValidateProductsForWatchlistImportAction extends Action {
    payload: {
        watchlistId: string,
        productIds: string[],
    };
}

export function validateProductsForWatchlistImport(watchlistId: string, productIds: string[]): ValidateProductsForWatchlistImportAction {
    return {
        type: WatchlistActionTypes.VALIDATE_WATCHLIST_IMPORT,
        payload: {
            watchlistId,
            productIds,
        },
    };
}

export interface ValidateProductsForWatchlistImportSucceededAction extends Action {
    payload: {
        data: WatchlistImportValidationResult;
        watchlistId: string;
    };
}

export function validateProductsForWatchlistImportSucceeded(watchlistId: string, data: WatchlistImportValidationResult): ValidateProductsForWatchlistImportSucceededAction {
    return {
        type: WatchlistActionTypes.VALIDATE_WATCHLIST_IMPORT_SUCCEEDED,
        payload: {
            watchlistId,
            data,
        },
    };
}

export interface ValidateProductsForWatchlistImportFailedAction extends Action {
    payload: {
        error: AppError;
        watchlistId: string;
    };
}

export function validateProductsForWatchlistImportFailed(watchlistId: string, error: AppError): ValidateProductsForWatchlistImportFailedAction {
    return {
        type: WatchlistActionTypes.VALIDATE_WATCHLIST_IMPORT_FAILED,
        payload: {
            watchlistId,
            error,
        },
    };
}

/** ExecuteWatchlistImportAction **/
export interface ExecuteWatchlistImportAction extends Action {
    payload: {
        watchlistId: string,
        products: WatchlistImportProduct[],
        reset: boolean,
    };
}

export function executeWatchlistImport(watchlistId: string, products: WatchlistImportProduct[], reset: boolean): ExecuteWatchlistImportAction {
    return {
        type: WatchlistActionTypes.EXECUTE_WATCHLIST_IMPORT,
        payload: {
            watchlistId,
            products,
            reset,
        },
    };
}

export interface ExecuteWatchlistImportSucceededAction extends Action {
    payload: {
        data: Watchlist;
        watchlistId: string;
    };
}

export function executeWatchlistImportSucceeded(watchlistId: string, data: Watchlist): ExecuteWatchlistImportSucceededAction {
    return {
        type: WatchlistActionTypes.EXECUTE_WATCHLIST_IMPORT_SUCCEEDED,
        payload: {
            watchlistId,
            data,
        },
    };
}

export interface ExecuteWatchlistImportFailedAction extends Action {
    payload: {
        error: AppError;
        watchlistId: string;
    };
}

export function executeWatchlistImportFailed(watchlistId: string, error: AppError): ExecuteWatchlistImportFailedAction {
    return {
        type: WatchlistActionTypes.EXECUTE_WATCHLIST_IMPORT_FAILED,
        payload: {
            watchlistId,
            error,
        },
    };
}

/** Add Products To Watchlist Actions **/

export interface AddProductsToWatchlistAction extends Action {
    payload: {
        watchlistId: string;
        products: string[];
    };
}

/**
 * AddProductsToWatchlistAction
 * @returns {Action}
 */
export function addProductsToWatchlist(watchlistId: string, products: string[]): AddProductsToWatchlistAction {
    return {
        type: WatchlistActionTypes.ADD_PRODUCTS_TO_WATCHLIST,
        payload: {
            watchlistId,
            products,
        },
    };
}

/** AddProductsTo Watchlist SucceededActions **/

export interface AddProductsToWatchlistSucceededAction extends Action {
    payload: {
        watchlist: Watchlist;
    };
}

/**
 * AddProductsToWatchlistSucceededAction
 * @returns {Action}
 */
export function addProductsToWatchlistSucceeded(watchlist: Watchlist): AddProductsToWatchlistSucceededAction {
    return {
        type: WatchlistActionTypes.ADD_PRODUCTS_TO_WATCHLIST_SUCCEEDED,
        payload: {
            watchlist,
        },
    };
}

/** AddProductsToWatchlistFailedAction **/

export interface AddProductsToWatchlistFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function addProductsToWatchlistFailed(error: AppError): AddProductsToWatchlistFailedAction {
    return {
        type: WatchlistActionTypes.ADD_PRODUCTS_TO_WATCHLIST_FAILED,
        payload: {
            error,
        },
    };
}

/** ClearWatchlistCreatedAction **/

export interface ClearProductsAddedToWatchlistAction extends Action {
    payload?: void;
}

export function clearProductsAddedToWatchlist(): ClearProductsAddedToWatchlistAction {
    return {
        type: WatchlistActionTypes.CLEAR_PRODUCTS_ADDED_TO_WATCHLIST,
    };
}

export interface ClearProductsRemovedFromWatchlistAction extends Action {
    payload?: void;
}

export function clearProductsRemovedFromWatchlist(): ClearProductsRemovedFromWatchlistAction {
    return {
        type: WatchlistActionTypes.CLEAR_PRODUCTS_REMOVED_FROM_WATCHLIST,
    };
}

/** RemoveProductsFromWatchlistAction **/

export interface RemoveProductsFromWatchlistAction extends Action {
    payload: {
        watchlistId: string;
        products: string[];
    };
}

/**
 * RemoveProductsFromWatchlistAction
 * @returns {Action}
 */
export function removeProductsFromWatchlist(watchlistId: string,
                                            products: string[]): RemoveProductsFromWatchlistAction {
    return {
        type: WatchlistActionTypes.REMOVE_PRODUCTS_FROM_WATCHLIST,
        payload: {
            watchlistId,
            products,
        },
    };
}

/** RemoveProductsFrom Watchlist SucceededActions **/

export interface RemoveProductsFromWatchlistSucceededAction extends Action {
    payload: {
        watchlist: Watchlist;
    };
}

/**
 * RemoveProductsFromWatchlistSucceededAction
 * @returns {Action}
 */
export function removeProductsFromWatchlistSucceeded(watchlist: Watchlist): RemoveProductsFromWatchlistSucceededAction {
    return {
        type: WatchlistActionTypes.REMOVE_PRODUCTS_FROM_WATCHLIST_SUCCEEDED,
        payload: {
            watchlist,
        },
    };
}

/** RemoveProductsFromWatchlistFailedAction **/

export interface RemoveProductsFromWatchlistFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function removeProductsFromWatchlistFailed(error: AppError): RemoveProductsFromWatchlistFailedAction {
    return {
        type: WatchlistActionTypes.REMOVE_PRODUCTS_FROM_WATCHLIST_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadWatchlistTabStructures **/
export interface LoadWatchlistTabStructuresAction extends Action {
    payload: {
        watchlistId: string;
    };
}

export function loadWatchlistTabStructures(watchlistId: string): LoadWatchlistTabStructuresAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLIST_TAB_STRUCTURES,
        payload: {
            watchlistId,
        },
    };
}

/** LoadWatchlistTabStructuresSucceededAction **/
export interface LoadWatchlistTabStructuresSucceededAction extends Action {
    payload: {
        watchlistId: string;
        tabs?: WatchlistTab[];
        title?: string;
    };
}

export function loadWatchlistTabStructuresSucceeded(watchlistId: string, tabs?: WatchlistTab[], title?: string): LoadWatchlistTabStructuresSucceededAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLIST_TAB_STRUCTURES_SUCCEEDED,
        payload: {
            watchlistId,
            tabs,
            title,
        },
    };
}

/** LoadWatchlistTabStructuresFailedAction **/
export interface LoadWatchlistTabStructuresFailedAction extends Action {
    payload: {
        error: AppError;
        watchlistId: string;
    };
}

export function loadWatchlistTabStructuresFailed(watchlistId: string, error: AppError): LoadWatchlistTabStructuresFailedAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLIST_TAB_STRUCTURES_FAILED,
        payload: {
            watchlistId,
            error,
        },
    };
}

/** LoadWatchlistStructuresAction **/

export interface LoadWatchlistStructuresAction extends Action {
    payload: {
        watchlistId: string;
    };
}

/**
 * LoadWatchlistStructuresAction
 * @returns {Action}
 */
export function loadWatchlistStructures(watchlistId: string): LoadWatchlistStructuresAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLIST_STRUCTURES,
        payload: {
            watchlistId,
        },
    };
}

/** LoadWatchlistStructuresSucceededAction **/

export interface LoadWatchlistStructuresSucceededAction extends Action {
    payload: {
        watchlistId: string;
        actions: GenericActionStructure[]
        structures: WatchlistProductGroup[];
        tabs?: WatchlistTab[];
        title?: string;
    };
}

/**
 * LoadWatchlistStructuresSucceededAction
 * @returns {Action}
 */
export function loadWatchlistStructuresSucceeded(watchlistId: string, actions: GenericActionStructure[],
                                                 structures: WatchlistProductGroup[], title?: string, tabs?: WatchlistTab[]): LoadWatchlistStructuresSucceededAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLIST_STRUCTURES_SUCCEEDED,
        payload: {
            watchlistId,
            actions,
            structures,
            title,
            tabs,
        },
    };
}

/** LoadWatchlistStructuresFailedAction **/

export interface LoadWatchlistStructuresFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadWatchlistStructuresFailed(error: AppError): LoadWatchlistStructuresFailedAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLIST_STRUCTURES_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadSingleWatchlistStructureAction **/

export interface LoadSingleWatchlistStructureAction extends Action {
    payload: {
        watchlistId: string;
        groupId: string;
    };
}

/**
 * LoadSingleWatchlistStructureAction
 * @returns {Action}
 */
export function loadSingleWatchlistStructure(watchlistId: string, groupId: string): LoadSingleWatchlistStructureAction {
    return {
        type: WatchlistActionTypes.LOAD_SINGLE_WATCHLIST_STRUCTURE,
        payload: {
            watchlistId,
            groupId,
        },
    };
}

/** LoadSingleWatchlistStructureSucceededAction **/

export interface LoadSingleWatchlistStructureSucceededAction extends Action {
    payload: {
        structure: WatchlistProductGroup;
    };
}

/**
 * LoadSingleWatchlistStructureSucceededAction
 * @returns {Action}
 */
export function loadSingleWatchlistStructureSucceeded(structure: WatchlistProductGroup): LoadSingleWatchlistStructureSucceededAction {
    return {
        type: WatchlistActionTypes.LOAD_SINGLE_WATCHLIST_STRUCTURE_SUCCEEDED,
        payload: {
            structure,
        },
    };
}

/** LoadSingleWatchlistStructureFailedAction **/

export interface LoadSingleWatchlistStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadSingleWatchlistStructureFailed(error: AppError): LoadSingleWatchlistStructureFailedAction {
    return {
        type: WatchlistActionTypes.LOAD_SINGLE_WATCHLIST_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadWatchlistDataAction **/

export interface LoadWatchlistDataAction extends Action {
    payload: {
        watchlistId: string,
    };
}

/**
 * LoadWatchlistDataAction
 * @returns {Action}
 */
export function loadWatchlistData(watchlistId: string): LoadWatchlistDataAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLIST_DATA,
        payload: {
            watchlistId,
        },
    };
}

/**  LoadWatchlistDataSucceededAction **/

export interface LoadWatchlistDataSucceededAction extends Action {
    payload: {
        data: WatchlistData,
        watchlistId: string,
    };
}

/**
 * LoadWatchlistDataSucceededAction
 * @returns {Action}
 */
export function loadWatchlistDataSucceeded(data: WatchlistData, watchlistId: string): LoadWatchlistDataSucceededAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLIST_DATA_SUCCEEDED,
        payload: {
            data,
            watchlistId,
        },
    };
}

/** LoadWatchlistDataFailedAction **/

export interface LoadWatchlistDataFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadWatchlistDataFailed(error: AppError): LoadWatchlistDataFailedAction {
    return {
        type: WatchlistActionTypes.LOAD_WATCHLIST_DATA_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadSingleWatchlistDataAction **/

export interface LoadSingleWatchlistDataAction extends Action {
    payload: {
        watchlistId: string;
        groupId: string;
    };
}

/**
 * LoadSingleWatchlistDataAction
 * @returns {Action}
 */
export function loadSingleWatchlistData(watchlistId: string, groupId: string): LoadSingleWatchlistDataAction {
    return {
        type: WatchlistActionTypes.LOAD_SINGLE_WATCHLIST_DATA,
        payload: {
            watchlistId,
            groupId,
        },
    };
}

/**  LoadSingleWatchlistDataSucceededAction **/

export interface LoadSingleWatchlistDataSucceededAction extends Action {
    payload: object;
}

/**
 * LoadSingleWatchlistDataSucceededAction
 * @returns {Action}
 */
export function loadSingleWatchlistDataSucceeded(): LoadSingleWatchlistDataSucceededAction {
    return {
        type: WatchlistActionTypes.LOAD_SINGLE_WATCHLIST_DATA_SUCCEEDED,
        payload: {},
    };
}

/** LoadSingleWatchlistDataFailedAction **/

export interface LoadSingleWatchlistDataFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadSingleWatchlistDataFailed(error: AppError): LoadSingleWatchlistDataFailedAction {
    return {
        type: WatchlistActionTypes.LOAD_SINGLE_WATCHLIST_DATA_FAILED,
        payload: {
            error,
        },
    };
}
