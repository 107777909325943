import { Input, Directive } from '@angular/core';

import { Menu, MenuItem } from '../../models/menu.model';

@Directive()
export class MenuComponent {
    /**
     * List of menu rows displayed in the menu
     */
    public rows: MenuItem[][] = [];

    /**
     * The menu model used for this menu
     */
    protected _menu: Menu;

    /**
     * Setter for the `menu` Input
     * @param menu
     */
    @Input()
    public set menu(menu: Menu) {
        this._menu = menu;
        this.updateRows();
    }

    /**
     * The currently expanded path of @link{MenuItem}s
     * @private
     */
    protected _currentExpandedPath: number[] = [];

    /**
     * Getter for the `currentExpandedPath` field
     * @returns {number[]}
     */
    protected get currentExpandedPath(): number[] {
        return this._currentExpandedPath;
    }

    /**
     * Setter for the `currentExpandedPath` field. Updates the displayed rows as a side-effect
     * @param path
     */
    protected set currentExpandedPath(path: number[]) {
        this._currentExpandedPath = path;
        this.updateRows();
    }

    /**
     * Getter for the current expanded menu depth
     * @returns {number}
     */
    public get currentDepth(): number {
        return this.currentExpandedPath.length + 1;
    }

    /**
     * Calculate a flat representation of the currently expanded menu path from the menu fields
     * and store it in the `rows` field
     */
    public updateRows() {
        const rows: MenuItem[][] = [];
        rows.push(this._menu.items);
        let lastRow = this._menu.items;
        this.currentExpandedPath.forEach((index) => {
            const node = lastRow[index];
            if (node.children && !node.hasSubmenu) {
                rows.push(node.children);
                lastRow = node.children;
            }
        });

        this.rows = rows;
    }

    /**
     * Click handler for item clicks
     */
    public onItemClick(itemIndex: number, rowIndex: number): void {
        this.onItemSelected(itemIndex, rowIndex);
    }

    /**
     * Checks whether the provided path is part of the currently expanded path
     * @param {number[]} path   Array of path indices
     * @returns {boolean}
     */
    public isPathSelected(path: number[]): boolean {
        if (this.currentExpandedPath.length < path.length) {
            return false;
        }
        for (let i = 0; i < path.length; i++) {
            if (path[i] !== this.currentExpandedPath[i]) {
                return false;
            }
        }
        return true;
    }

    /**
     * Gets the corresponding @link{MenuItem} for the provided path
     * @param {number[]} path   Array of path indices
     * @returns {MenuItem}
     */
    public getItemForPath(path: number[]): MenuItem {
        let currentItem = this._menu.items[path[0]];
        for (let i = 1; i < path.length; i++) {
            if (currentItem.children[path[i]]) {
                currentItem = currentItem.children[path[i]];
            } else {
                return null;
            }
        }
        return currentItem;
    }

    /**
     * Checks if the @link{MenuItem} identified by the row and item index is part of the currently expanded path
     * @param itemIndex
     * @param rowIndex
     * @returns {boolean}
     */
    public isInCurrentPath(itemIndex: number, rowIndex: number): boolean {
        const parentPath = this.currentExpandedPath.slice(0, rowIndex);
        const path = [...parentPath, itemIndex];

        return this.isPathSelected(path);
    }

    /**
     * Called when an item was selected, checks if the selected item should be expanded or if the selected item is
     * already expanded and should collapse the menu. Returns whether the event was handled.
     * @param {number} itemIndex    Index of the item in the current row
     * @param {number} rowIndex     Index of the current row
     * @returns {boolean}
     */
    protected onItemSelected(itemIndex: number, rowIndex: number): boolean {
        const parentPath = this.currentExpandedPath.slice(0, rowIndex);
        const path = [...parentPath, itemIndex];
        const item = this.getItemForPath(path);
        if (this.isPathSelected(path)) {
            this.currentExpandedPath = parentPath;
            return true;
        } else if (item.children) {
            this.currentExpandedPath = path;
            return true;
        } else if (!item.link) {
            return true;
        } else {
            this.currentExpandedPath = path;
            return false;
        }
    }
}
