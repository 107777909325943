import {InjectionToken} from '@angular/core';
import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {environment} from '../../../../environments/environment';
import {reducer as riskManagerReducer} from '../../../core/store/reducers/risk-manager.reducer';
import {reducer as portfolioManagerReducer} from '../../../routes/admin/routes/portfolio-manager/store/reducers/portfolio-manager.reducer';
import {reducer as userManagerReducer} from '../../../routes/admin/routes/user-manager/store/reducers/user-manager.reducer';
import {reducer as advisorProductBasketsReducer} from '../../../routes/advisor-product-baskets/store/reducers/advisor-product-baskets.reducers';
import {assetsModuleReducer} from '../../../routes/assets/store/reducers/assets.reducer';
import {reducer as bwlistReducer} from '../../../routes/bwlist/store/reducers/bwlist.reducer';
import {reducer as dashboardReducer} from '../../../routes/dashboard/store/reducers/dashboard.reducer';
import {reducer as dpcReducer} from '../../../routes/dpc/store/reducers/dpc.reducer';
import {reducer as landingReducer} from '../../../routes/landing/store/reducers/landing.reducer';
import {reducer as marketAnalysisReducer} from '../../../routes/market-analysis/store/reducers/market-analysis.reducer';
import {reducer as otherProductsReducer} from '../../../routes/other-products/store/reducers/other-products.reducer';
import {reducer as fileListAdministrationReducer } from '../../../routes/file-list-administration/store/reducers/file-list-administration.reducer';
import {reducer as productDesignerReducer} from '../../../routes/product-designer/store/reducers/product-designer.reducer';
import {productDetailsReducer} from '../../../routes/product-details/store/reducers/product-details.reducer';
import {reducer as productSelectionReducer} from '../../../routes/product-selection/store/reducers/product-selection.reducer';
import {reducer as productsExportReducer} from '../../../routes/products-export/store/reducers/products-export.reducer';
import {filterReducer} from '../../../routes/products/store/reducers/filter.reducer';
import {productReducer} from '../../../routes/products/store/reducers/product.reducer';
import {querySetReducer} from '../../../routes/products/store/reducers/query-set.reducer';
import {reducer as recommendationReducer} from '../../../routes/recommendations/store/reducers/recommendation.reducer';
import {reducer as rfqIssuerReducer} from '../../../routes/rfq-issuer/store/reducers/rfq-issuer.reducer';
import {productSharedReducer} from '../../../routes/shared/product-shared/store/reducers/product-shared.reducer';
import {reducer as watchlistReducer} from '../../../routes/shared/watchlist-shared/store/reducers/watchlist.reducer';
import {reducer as targetMarketReducer} from '../../../routes/target-market/store/reducers/target-market.reducer';
import {tradesModuleReducer} from '../../../routes/trades/store/reducers/trades.reducer';
import {reducer as volatilityMatrixReducer} from '../../../routes/volatility-matrix/store/reducers/volatility-matrix.reducer';
import {watchlistDetailsModuleReducer} from '../../../routes/watchlist-details/store/reducers/watchlist-details.reducer';
import { reducer as fundsCompareReducer } from '../../../routes/shared/funds-compare/store/reducers/funds-compare.reducer';
import {reducer as fileListReducer} from '../../../routes/file-list/store/reducers/file-list.reducer';
import { reducer as customerSelectionReducer } from '../../../routes/customer-selection/store/reducers/customer-selection.reducer';
import { reducer as issueMessageReducer } from '../../../core/store/reducers/issue-message.reducer';
import { reducer as auditsReducer } from '../../../routes/audits/store/reducer/audits.reducer';
import { reducer as adReducer } from './ad.reducer';
import { reducer as searchReducer } from './search.reducer';
import { reducer as userReducer } from './user.reducer';

import {AppState} from '../state/index.state';
import {asyncLinkReducer} from './async-link.reducer';
import {authReducer} from './auth.reducer';
import {configReducer} from './config.reducer';
import {stringsReducer} from './strings.reducer';
import { reducer as uiReducer } from './ui.reducer';
import {uploadReducer} from './upload.reducer';
import {reducer as additionalWidgetsDataReducer} from './widgets-data.reducer';
import { localStorageSyncReducer } from './local-storage-sync.reducer';
import { routerReducer } from '@ngrx/router-store';

/**
 * Configure the root reducer using either a production or development configuration
 */

export const metaReducers: Array<MetaReducer<AppState, any>> = [
    localStorageSyncReducer
];

export const reducers: ActionReducerMap<AppState> = {
    ui: uiReducer,
    auth: authReducer,
    config: configReducer,
    strings: stringsReducer,
    upload: uploadReducer,
    targetMarket: targetMarketReducer,
    filter: filterReducer,
    querySet: querySetReducer,
    product: productReducer,
    productSelection: productSelectionReducer,
    productDetails: productDetailsReducer,
    productsExport: productsExportReducer,
    otherProducts: otherProductsReducer,
    fileListAdministration: fileListAdministrationReducer,
    watchlist: watchlistReducer,
    download: asyncLinkReducer,
    bwlist: bwlistReducer,
    router: routerReducer,
    recommendations: recommendationReducer,
    trades: tradesModuleReducer,
    assets: assetsModuleReducer,
    productShared: productSharedReducer,
    dpc: dpcReducer,
    productDesigner: productDesignerReducer,
    rfqIssuer: rfqIssuerReducer,
    dashboard: dashboardReducer,
    landing: landingReducer,
    riskManager: riskManagerReducer,
    marketAnalysis: marketAnalysisReducer,
    volaMatrix: volatilityMatrixReducer,
    watchlistDetails: watchlistDetailsModuleReducer,
    userManager: userManagerReducer,
    portfolioManager: portfolioManagerReducer,
    advisorProductBaskets: advisorProductBasketsReducer,
    fundsCompare: fundsCompareReducer,
    additionalWidgetsData: additionalWidgetsDataReducer,
    fileListGroup: fileListReducer,
    customerSelection: customerSelectionReducer,
    issueMessage: issueMessageReducer,
    audits: auditsReducer,
    ad: adReducer,
    search: searchReducer,
    user: userReducer
};

export function rootReducerFactory() {
    return reducers;
}

export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<AppState>>('ROOT_REDUCERS');
