import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthActionTypes, TokenRefreshedAction, refreshToken } from '../store/actions/auth.action';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;

  constructor(
    private store: Store,
    private actions: Actions
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (request.headers.has('Authorization')) {
      return next.handle(request).pipe(
        catchError(error => {
          if (
            error instanceof HttpErrorResponse &&
            error.status === 401 &&
            !request.url.includes('auth/token')
          ) {
            return this.handleAuthError(request, next);
          }
          
          return throwError(error);
        })
      );
    }

    return next.handle(request);
  }

  private handleAuthError(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.store.dispatch(refreshToken());
    }
    
    return this.actions.pipe(
      ofType(AuthActionTypes.TOKEN_REFRESHED),
      tap(() => this.isRefreshing = false),
      switchMap((action: TokenRefreshedAction) =>
        next.handle(this.addToken(request, action.payload.tokenResponse.token))
      )
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}
