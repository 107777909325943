/**
 * Created by Alex Klein <alex@rocketloop.de> on 12/11/2017.
 */

import { Action } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { type } from '../../../../core/store/util';
import { Entity } from '../../../../shared/models/entities.model';
import { TradeStatistics } from '../../models/trades.model';

export const TradeActionTypes = {
    LOAD_TRADES_FOR_QUERY_SET: type('TRADES:LOAD_TRADES_FOR_QUERY_SET'),
    TRADES_FOR_QUERY_SET_LOADED: type('TRADES:TRADES_FOR_QUERY_SET_LOADED'),
    LOAD_TRADES_FOR_QUERY_SET_FAILED: type('TRADES:LOAD_TRADES_FOR_QUERY_SET_FAILED'),

    LOAD_TRADES_STATISTICS: type('TRADES:LOAD_TRADES_STATISTICS'),
    TRADES_STATISTICS_LOADED: type('TRADES:TRADES_STATISTICS_LOADED'),
    LOAD_TRADES_STATISTICS_FAILED: type('TRADES:LOAD_TRADES_STATISTICS_FAILED'),

    ADD_TRADE_TO_SELECTION: type('TRADES:ADD_TRADE_TO_SELECTION'),
    REMOVE_TRADE_FROM_SELECTION: type('TRADES:REMOVE_TRADE_FROM_SELECTION'),

    ADD_ALL_TRADES_TO_SELECTION: type('TRADES:ADD_ALL_TRADES_TO_SELECTION'),
    REMOVE_ALL_TRADES_FROM_SELECTION: type('TRADES:REMOVE_ALL_TRADES_FROM_SELECTION'),
};

/** LoadTradesForQuerySetAction **/

export interface LoadTradesForQuerySetAction extends Action {
    payload: {
        productTypeId: string;
        querySetId: string;
        limit?: number;
        offset?: number;
    };
}

export function loadTradesForQuerySet(productTypeId: string, querySetId: string, limit?: number,
                                      offset?: number): LoadTradesForQuerySetAction {
    return {
        type: TradeActionTypes.LOAD_TRADES_FOR_QUERY_SET,
        payload: {
            productTypeId,
            querySetId,
            limit,
            offset,
        },
    };
}

/** TradesForQuerySetLoadedAction **/

export interface TradesForQuerySetLoadedAction extends Action {
    payload: {
        querySetId: string;
        trades: Entity[];
    };
}

export function tradesForQuerySetLoaded(querySetId: string, trades: Entity[]): TradesForQuerySetLoadedAction {
    return {
        type: TradeActionTypes.TRADES_FOR_QUERY_SET_LOADED,
        payload: {
            querySetId,
            trades,
        },
    };
}

/** LoadTradesForQuerySetFailedAction **/

export interface LoadTradesForQuerySetFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadTradesForQuerySetFailed(error: AppError): LoadTradesForQuerySetFailedAction {
    return {
        type: TradeActionTypes.LOAD_TRADES_FOR_QUERY_SET_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadTradesStatisticsAction **/

export interface LoadTradesStatisticsAction extends Action {
    payload: {
        querySetId: string;
    };
}

export function loadTradesStatistics(querySetId: string): LoadTradesStatisticsAction {
    return {
        type: TradeActionTypes.LOAD_TRADES_STATISTICS,
        payload: {
            querySetId,
        },
    };
}

/** TradesStatisticsLoadedAction **/

export interface TradesStatisticsLoadedAction extends Action {
    payload: {
        querySetId: string;
        statistics: TradeStatistics
    };
}

export function tradesStatisticsLoaded(querySetId: string, statistics: TradeStatistics): TradesStatisticsLoadedAction {
    return {
        type: TradeActionTypes.TRADES_STATISTICS_LOADED,
        payload: {
            querySetId,
            statistics,
        },
    };
}

/** LoadTradesStatisticsFailedAction **/

export interface LoadTradesStatisticsFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadTradesStatisticsFailed(error: AppError): LoadTradesStatisticsFailedAction {
    return {
        type: TradeActionTypes.LOAD_TRADES_STATISTICS_FAILED,
        payload: {
            error,
        },
    };
}

/** AddTradeToSelection **/

export interface AddTradeToSelectionAction extends Action {
    payload: {
        id: string;
    };
}

export function addTradeToSelection(id: string): AddTradeToSelectionAction {
    return {
        type: TradeActionTypes.ADD_TRADE_TO_SELECTION,
        payload: {
            id,
        },
    };
}

/** RemoveTradeFromSelection **/

export interface RemoveTradeFromSelectionAction extends Action {
    payload: {
        id: string;
    };
}

export function removeTradeFromSelection(id: string): AddTradeToSelectionAction {
    return {
        type: TradeActionTypes.REMOVE_TRADE_FROM_SELECTION,
        payload: {
            id,
        },
    };
}

/** AddAllTradesToSelection **/

export interface AddAllTradesToSelectionAction extends Action {
    payload: {
        ids: string[];
    };
}

export function addAllTradesToSelection(ids: string[]): AddAllTradesToSelectionAction {
    return {
        type: TradeActionTypes.ADD_ALL_TRADES_TO_SELECTION,
        payload: {
            ids,
        },
    };
}

/** RemoveAllTradesFromSelection **/

export interface RemoveAllTradesFromSelectionAction extends Action {
    payload?: void;
}

export function removeAllTradesFromSelection(): RemoveAllTradesFromSelectionAction {
    return {
        type: TradeActionTypes.REMOVE_ALL_TRADES_FROM_SELECTION,
    };
}
