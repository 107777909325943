import {type} from '../../../../core/store/util';
import {Action} from '@ngrx/store';
import {AppError} from '../../../../core/errors/base.errors';
import {FileListGroup} from '../../api/model/file-list.model';

export const DocumentListGroupActionTypes = {
    DOCUMENT_LIST_GROUP_REQUESTED: type('DOCUMENT_LIST_GROUP:REQUESTED'),
    DOCUMENT_LIST_GROUP_SUCCCESS: type('DOCUMENT_LIST_GROUP:SUCCESS'),
    DOCUMENT_LIST_GROUP_FAILED: type('DOCUMENT_LIST_GROUP:FAILED'),


    CLEAR_DOCUMENT_LIST_GROUP: type('DOCUMENT_LIST_GROUP:CLEAR')
};


export interface LoadDocumentListGroupAction extends Action {
    payload: {
        moduleId: string;
        limit?: number;
        offset?: number;
    };
}

export function loadDocumentListGroup(
    moduleId: string,
    limit?: number,
    offset?: number
): LoadDocumentListGroupAction {
    return {
        type: DocumentListGroupActionTypes.DOCUMENT_LIST_GROUP_REQUESTED,
        payload: {
            moduleId,
            limit,
            offset
        }
    };
}


export interface LoadDocumentListGroupSuccessAction extends Action {
    payload: {
        moduleId: string,
        fileListGroup: FileListGroup
    };
}

export function loadDocumentListGroupSuccess(
    moduleId: string,
    fileListGroup: FileListGroup
): LoadDocumentListGroupSuccessAction {
    return {
        type: DocumentListGroupActionTypes.DOCUMENT_LIST_GROUP_SUCCCESS,
        payload: {
            moduleId,
            fileListGroup
        }
    };
}

export interface LoadDocumentListGroupFailedAction extends Action {
    payload: {
        error: AppError
    };
}

export function loadDocumentListGroupFailed(error: AppError): LoadDocumentListGroupFailedAction {
    return {
        type: DocumentListGroupActionTypes.DOCUMENT_LIST_GROUP_FAILED,
        payload: {
            error
        }
    };
}

export interface LoadDocumentListGroupClearAction extends Action {
    payload: null;
}

export function loadDocumentListGroupClear(): LoadDocumentListGroupClearAction {
    return {
        type: DocumentListGroupActionTypes.CLEAR_DOCUMENT_LIST_GROUP,
        payload: null
    };
}
