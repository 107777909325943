import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChange,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormControlConfig } from '../../../core/models/form.model';
import { Product, ProductComponentStructure } from '../../../routes/shared/product-designer-shared/models/product-designer.model';
import { FilterTradeDateComponent } from '../../../routes/trades/components/filter-trade-date/filter-trade-date.component';
import { ComponentRegistries, getInjectionTokenForComponentType } from '../../helpers/dynamic-component.helper';
import { FilterAssetsClassSelectionComponent } from '../filter-assets-class-selection/filter-assets-class-selection.component';
import { FilterCheckboxGroupComponent } from '../filter-checkbox-group/filter-checkbox-group.component';
import { FilterCheckboxSelectGroupComponent } from '../filter-checkbox-select-group/filter-checkbox-select-group.component';
import { FilterCheckboxTmcComponent } from '../filter-checkbox-tmc/filter-checkbox-tmc.component';
import { FilterCheckboxComponent } from '../filter-checkbox/filter-checkbox.component';
import { FilterControl } from '../filter-control';
import { FilterDateInputComponent } from '../filter-date-input/filter-date-input.component';
import { FilterDoubleDropdownInputComponent } from '../filter-double-dropdown-input/filter-double-dropdown-input.component';
import { FilterFundFigureInputComponent } from '../filter-fund-figure-input/filter-fund-figure-input.component';
import { FilterLabeledDateInputComponent } from '../filter-labeled-date-input/filter-labeled-date-input.component';
import { FilterLabeledNumberInputComponent } from '../filter-labeled-number-input/filter-labeled-number-input.component';
import { FilterLabelledNumberComparisonInputComponent } from '../filter-labelled-number-comparison-input/filter-labelled-number-comparison-input.component';
import { FilterMaturityDateComponent } from '../filter-maturity-date/filter-maturity-date.component';
import { FilterMaturityRuleSelectorComponent } from '../filter-maturity-rule-selector/filter-maturity-rule-selector.component';
import { FilterMaturityComponent } from '../filter-maturity/filter-maturity.component';
import { FilterMultiSelectCellsComponent } from '../filter-multi-select-cells/filter-multi-select-cells.component';
import { FilterMultiSelectComponent } from '../filter-multi-select/filter-multi-select.component';
import { FilterRadioButtonGroupComponent } from '../filter-radio-button-group/filter-radio-button-group.component';
import { FilterRadioGroupHorizontalComponent } from '../filter-radio-group-horizontal/filter-radio-group-horizontal.component';
import { FilterRadioGroupComponent } from '../filter-radio-group/filter-radio-group.component';
import { FilterRangeSelectComponent } from '../filter-range-select/filter-range-select.component';
import { FilterSelectCellsComponent } from '../filter-select-cells/filter-select-cells.component';
import { FilterSingleSelectComponent } from '../filter-single-select/filter-single-select.component';
import { FilterSwitchableDropdownInputComponent } from '../filter-switchable-dropdown-input/filter-switchable-dropdown-input.component';
import { FilterTextInputComponent } from '../filter-text-input/filter-text-input.component';
import { FilterTextSearchComponent } from '../filter-text-search/filter-text-search.component';
import { FilterUnderlyingSelectorComponent } from '../filter-underlying-selector/filter-underlying-selector.component';
import { FilterMaturityDateQuickComponent } from '../filter-maturity-date-quick/filter-maturity-date-quick.component';

/**
 * Resolve the right control type by the given control type
 */
export function resolveControlType(controlType: string): Type<FilterControl> {
    switch (controlType) {

        case 'radioGroup':
            return FilterRadioGroupComponent;
        case 'radioButtonGroup':
            return FilterRadioButtonGroupComponent;
        case 'checkboxGroup':
            return FilterCheckboxGroupComponent;
        case 'checkboxSelectGroup':
            return FilterCheckboxSelectGroupComponent;
        case 'checkbox':
            return FilterCheckboxComponent;
        case 'checkboxTMC':
            return FilterCheckboxTmcComponent;
        case 'dropdownSelect':
            return FilterSingleSelectComponent;
        case 'multiSelect':
            return FilterMultiSelectComponent;
        case 'maturitySelector':
            return FilterMaturityComponent;
        case 'maturityQuickSelector':
            return FilterMaturityDateQuickComponent;
        case 'detailedUnderlyingSelector':
            return FilterUnderlyingSelectorComponent;
        case 'valueRange':
            return FilterRangeSelectComponent;
        case 'quickSearch':
            return FilterTextSearchComponent;
        case 'textInput':
        case 'text':
            return FilterTextInputComponent;

        case 'tradeDateSelector':
            return FilterTradeDateComponent;
        case 'maturityRuleSelector':
            return FilterMaturityRuleSelectorComponent;
        case 'maturityDateSelector':
            return FilterMaturityDateComponent;
        case 'labelledNumberInput':
            return FilterLabeledNumberInputComponent;
        case 'labelledDateInput':
            return FilterLabeledDateInputComponent;
        case 'labelledNumberComparisonInput':
            return FilterLabelledNumberComparisonInputComponent;
        case 'radioGroupHorizontal':
            return FilterRadioGroupHorizontalComponent;
        case 'dateInput':
            return FilterDateInputComponent;
        case 'doubleDropdownInput':
            return FilterDoubleDropdownInputComponent;
        case 'switchableDropdownInput':
            return FilterSwitchableDropdownInputComponent;
        case 'fundFigureInput':
            return FilterFundFigureInputComponent;
        case 'selectCells':
            return FilterSelectCellsComponent;
        case 'multiselectCells':
            return FilterMultiSelectCellsComponent;
        case 'fundAssetClassInvestmentFocus':
            return FilterAssetsClassSelectionComponent;
        default:
            return null;
    }
}

/**
 * FilterControlContainerComponent loads the specific control component that is responsible for the specified
 * FormControlConfig
 */

@Component({
    selector: 'app-filter-control-container',
    templateUrl: './filter-control-container.component.html',
    styleUrls: ['./filter-control-container.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FilterControlContainerComponent),
            multi: true,
        }, {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FilterControlContainerComponent),
            multi: true,
        },
    ],
    standalone: true
})
export class FilterControlContainerComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
    @ViewChild('control', {read: ViewContainerRef, static: true}) public viewContainerRef: ViewContainerRef;

    @Input() public config: FormControlConfig;
    @Input() public values: any;
    @Input() public initiallyDisabled = false;
    @Input() public disableControl = false;
    @Input() public fullProductData: Product;
    @Input() public componentStructure: ProductComponentStructure;

    @Input() public visibleInput = true;
    
    @Output() public blurControl: EventEmitter<void> = new EventEmitter<void>();
    public onChange: () => object;
    public onTouched: () => object;
    public componentSubscription: Subscription;
    private componentRef: ComponentRef<FilterControl>;

    constructor(private injector: Injector, private componentFactoryResolver: ComponentFactoryResolver) {
    }

    @HostBinding('class')
    get className() {
        const styleHintClasses = (this.config && this.config.styleHints)
            ? this.config.styleHints.map((hint) => `filter-control--${hint}`) : [];
        return ['filter-control', ...styleHintClasses].join(' ');
    }

    public ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.config) {
            this._updateComponent(changes, true);
        } else {
            this._updateComponent(changes);
        }
    }

    /**
     * Writes the filter value for the control component
     * @param value
     */
    public writeValue(value: any): void {
        if (this.componentRef) {
            this.componentRef.instance.writeValue(value);
        }
    }

    /**
     * Registers the onChange for the control component
     * @param fn
     */
    public registerOnChange(fn: any): void {
        this.onChange = fn;

        if (this.componentRef) {
            this.componentRef.instance.registerOnChange(fn);
        }
    }

    /**
     * Registers the onTouched for the control component
     * @param fn
     */
    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
        if (this.componentRef) {
            this.componentRef.instance.registerOnTouched(fn);
        }

    }

    public setDisabledState(isDisabled: boolean): void {
        if (this.componentRef) {
            this.componentRef.instance.setDisabledState(isDisabled);
        }
    }

    /**
     * Validates the filter control for the control component
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this.componentRef) ? this.componentRef.instance.validate(c) : {
            filterControlError: 'Control component is not loaded',
        };
    }

    /**
     * Loads the control component
     */
    private _updateComponent(changes: SimpleChanges, recreate = false) {
        let compChanges: SimpleChanges = {};
        let controlType = (this.config) ? resolveControlType(this.config.type) : null;

        if (!this.componentRef || recreate) {
            if (!controlType) {
                const injectionToken = this.config ? getInjectionTokenForComponentType<FilterControl>(
                    ComponentRegistries.FILTER_CONTROL,
                    this.config.type,
                ) : null;

                if (injectionToken) {
                    controlType = this.injector.get(injectionToken);
                } else {
                    return;
                }
            }

            if (this.componentRef && recreate) {
                this.componentRef.destroy();
                if (this.componentSubscription) {
                    this.componentSubscription.unsubscribe();
                }
            }
            const factory = this.componentFactoryResolver.resolveComponentFactory(controlType);
            this.componentRef = this.viewContainerRef.createComponent(factory) as ComponentRef<FilterControl>;

            this.componentRef.instance.registerOnChange(this.onChange);
            this.componentRef.instance.registerOnTouched(this.onTouched);

            if (this.componentRef.instance.blurControl) {
                this.componentSubscription = this.componentRef.instance.blurControl.subscribe(this.blurControl);
            }
            compChanges = {
                config: new SimpleChange(null, this.config, true),
                values: new SimpleChange(null, this.values, true),
                fullProductData: new SimpleChange(null, this.fullProductData, true),
                componentStructure: new SimpleChange(null, this.componentStructure, true),
            };
        } else {
            if (changes.config) {
                compChanges.config = changes.config;
            }
            if (changes.values) {
                compChanges.values = changes.values;
            }

            if (changes.fullProductData) {
                compChanges.fullProductData = changes.fullProductData;
            }

            if (changes.componentStructure) {
                compChanges.componentStructure = changes.componentStructure;
            }
        }

        this.componentRef.instance.config = this.config;
        this.componentRef.instance.values = this.values;
        this.componentRef.instance.fullProductData = this.fullProductData;
        this.componentRef.instance.disabled = this.initiallyDisabled;
        this.componentRef.instance.componentStructure = this.componentStructure;
        this.componentRef.instance.visibleInput = this.visibleInput;

        if (this.componentRef.instance.ngOnChanges) {
            this.componentRef.instance.ngOnChanges(compChanges);
        }
    }

}
