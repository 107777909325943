/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 7/4//17.
 */

import { Action, combineReducers } from '@ngrx/store';
import { EntitiesState } from '../../../../core/store/state/entities.state';
import { StringMappingState } from '../../../../core/store/state/mapping.state';
import { QuerySet } from '../../../../shared/models/entities.model';
import { CreateQuerySetAction, QuerySetActionTypes, QuerySetCountLoadedAction, QuerySetCreatedAction } from '../actions/query-set.actions';
import { AssetQuerySetState } from '../state/assets.state';

export const initialState: AssetQuerySetState = {
    entities: {},
    productTypeMapping: {},
    loadingQuerySet: false,
};

/**
 * The reducer responsible for the entities of the @link{QuerySetState}
 * @param state
 * @param action
 * @returns {EntitiesState<QuerySet>}
 */
export function querySetEntitiesReducer(state: EntitiesState<QuerySet> = initialState.entities,
                                        action: Action): EntitiesState<QuerySet> {

    switch (action.type) {

        case QuerySetActionTypes.QUERY_SET_CREATED:
            const querySetCreatedAction = action as QuerySetCreatedAction;
            return {
                ...state,
                [querySetCreatedAction.payload.querySet.id]: querySetCreatedAction.payload.querySet,
            };

        case QuerySetActionTypes.QUERY_SET_COUNT_LOADED: {
            const querySetCountLoadedAction = action as QuerySetCountLoadedAction;
            return {
                ...state,
                [querySetCountLoadedAction.payload.querySetId]: {
                    ...state[querySetCountLoadedAction.payload.querySetId],
                    count: querySetCountLoadedAction.payload.count,
                },
            };
        }

        default:
            return state;
    }
}

/**
 * The reducer responsible for the product type mapping of the @link{QuerySetState}
 * @param state
 * @param action
 * @returns {StringMappingState<string>}
 */
export function querySetProductTypeMappingReducer(state: StringMappingState<string> = initialState.productTypeMapping,
                                                  action: Action): StringMappingState<string> {

    switch (action.type) {

        case QuerySetActionTypes.QUERY_SET_CREATED:
            const querySetCreatedAction = action as QuerySetCreatedAction;
            return {
                ...state,
                [querySetCreatedAction.payload.productTypeId]: querySetCreatedAction.payload.querySet.id,
            };

        default:
            return state;
    }
}

/**
 * The reducer responsible for the loading query set flag of the @link{QuerySetState}
 * @param state
 * @param action
 * @returns {boolean}
 */
export function loadingQuerySetReducer(state: boolean = initialState.loadingQuerySet, action: Action): boolean {

    switch (action.type) {

        case QuerySetActionTypes.CREATE_QUERY_SET:
            const createQuerySetAction = action as CreateQuerySetAction;
            return !createQuerySetAction.payload.resort;

        case QuerySetActionTypes.CREATE_QUERY_SET_FAILED:
        case QuerySetActionTypes.QUERY_SET_CREATED:
            return false;

        default:
            return state;
    }
}

/**
 * The reducer responsible for the @link{QuerySetState}
 * @type {ActionReducer<QuerySetState>}
 */
export const assetsModuleQuerySetReducer = combineReducers({
    entities: querySetEntitiesReducer,
    productTypeMapping: querySetProductTypeMappingReducer,
    loadingQuerySet: loadingQuerySetReducer,
});
