import { Component, Input, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { cva } from 'cva';
import { RouterLink } from '@angular/router';
import { getRouterLinkFromMenuItemTargetConfig } from '../../../core/helpers/menu.helper';

export const buttonStyle = cva([
    'tw-inline-flex', 'tw-justify-center', 'tw-items-center', 'tw-gap-x-2.5',
    'tw-w-full', 'tw-p-4', 'tw-no-underline',
    'tw-text-lg',
    'tw-rounded', 'tw-shadow', 'dark:tw-rounded-none', 'dark:tw-shadow-none',
    'hover:tw-bg-primary-hover-dark',
    'focus-visible:tw-outline', 'focus-visible:tw-outline-3',
    'focus-visible:tw-shadow-none',
    'focus-visible:tw-outline-primary-focus'
  ], {
  variants: {
    appearance: {
      'FILL': [
        'tw-bg-primary-base',
        'tw-text-white'
      ],
      'OUTLINE': [
        'tw-group',
        'tw-bg-white',
        'tw-text-black', 'dark:tw-text-old-black',
        'tw-ring-inset', 'tw-ring-1', 'tw-ring-neutral-100', 'hover:tw-ring-primary-hover-dark', 'dark:tw-ring-old-gray-300'
      ]
    }
 }
});

@Component({
  selector: 'app-entity-widget-quicklink',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './entity-widget-quicklink.component.html'
})
export class EntityWidgetQuicklinkComponent implements OnChanges {

  @Input() public options: {
    buttons: Array<{
      target: string;
      type?: string;
      external: boolean;
      label: string;
      style: 'FILL' | 'OUTLINE'
    }>;
  };

  public buttonStyle = buttonStyle;

  public routerLinkPath = getRouterLinkFromMenuItemTargetConfig;

  public ngOnChanges() {}
}
