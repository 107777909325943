/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 7/4//17.
 */
import { Action } from '@ngrx/store';

import { AsyncLinkActionTypes } from '../../../../core/store/actions/async-link.action';
import { EntitiesState } from '../../../../core/store/state/entities.state';
import {
    AddAllAssetsToSelectionAction,
    AddAssetToSelectionAction,
    AssetActionTypes,
    RemoveAssetFromSelectionAction,
    ReportDataLoadedAction,
} from '../actions/assets.actions';
import { AssetMetaState } from '../state/assets.state';

export const initialState: AssetMetaState = {
    selections: [],
    reportData: null,
    loadingReport: false,
};

/**
 * The reducer responsible for the entities part of the @link{AssetState}
 * @param state
 * @param action
 * @returns {EntitiesState<AssetState>}
 */
export function assetsModuleMetaReducer(state: AssetMetaState = initialState, action: Action): AssetMetaState {
    switch (action.type) {
        case AssetActionTypes.ADD_ASSET_TO_SELECTION:
            const addAssetToSelectionAction = action as AddAssetToSelectionAction;
            return {
                ...state,
                selections: [...state.selections, addAssetToSelectionAction.payload.id],
            };

        case AssetActionTypes.REMOVE_ASSET_FROM_SELECTION:
            const removeAssetFromSelectionAction = action as RemoveAssetFromSelectionAction;
            return {
                ...state,
                selections: state.selections.filter((id) => id !== removeAssetFromSelectionAction.payload.id),
            };

        case AssetActionTypes.ADD_ALL_ASSETS_TO_SELECTION:
            const addAllAssetsToSelectionAction = action as AddAllAssetsToSelectionAction;
            return {
                ...state,
                selections: addAllAssetsToSelectionAction.payload.ids,
            };

        case AssetActionTypes.REMOVE_ALL_ASSETS_FROM_SELECTION:
            return {
                ...state,
                selections: [],
            };

        case AssetActionTypes.REPORT_DATA_LOADED:
            const reportDataLoadedAction = action as ReportDataLoadedAction;
            return {
                ...state,
                reportData: reportDataLoadedAction.payload.data,
            };

        case AsyncLinkActionTypes.REPORT_REQUEST:
            return {
                ...state,
                loadingReport: true,
            };

        case AsyncLinkActionTypes.REPORT_SUCCESS:
        case AsyncLinkActionTypes.REPORT_FAILURE:
            return {
                ...state,
                loadingReport: false,
            };

        default:
            return state;
    }
}
