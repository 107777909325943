/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 1/5/17.
 */
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, take } from 'rxjs/operators';
import { truthy } from '../../../shared/helpers/general.helper';

import { UploadService } from '../../services/upload.service';
import {
    AddFileToUploadQueueAction,
    updateUploadProgress,
    UploadActionTypes,
    uploadCompleted,
    uploadFailed,
} from '../actions/upload.action';

/**
 * Effect class for upload effects
 */
@Injectable()
export class UploadEffects {
    /** Effect Declarations **/

    
    public addFileToUploadQueue$ = createEffect(() => this.actions$.pipe(
        ofType(UploadActionTypes.ADD_FILE_TO_UPLOAD_QUEUE),
        concatMap((action: AddFileToUploadQueueAction) => this.onAddFileToUploadQueue(action)),
    ));

    constructor(private actions$: Actions, private uploadService: UploadService) {
    }

    /** Effect Handler **/

    /**
     * Effect handler called upon the AddFileToUploadQueueAction
     *
     * This effect handler manages the upload of the file
     * @param action
     * @returns {Observable<Action>}
     */
    public onAddFileToUploadQueue(action: AddFileToUploadQueueAction): Observable<Action> {
        return this.uploadService.getUploadQueue(action.payload.queueId).pipe(
            take(1),
            switchMap((queue) => queue.uploadHandler(action.payload.uploadFile)),
            map((event: HttpEvent<any>) => {
                if (event.type === HttpEventType.UploadProgress) {
                    // This is an upload progress event. Compute the % done:
                    const progress = event.loaded / event.total;
                    return updateUploadProgress(action.payload.uploadFile.id, progress);
                } else if (event instanceof HttpResponse) {
                    const response = event as HttpResponse<any>;
                    return uploadCompleted(action.payload.queueId, action.payload.uploadFile.id, response.body);
                }
            }),
            catchError((err) => [uploadFailed(action.payload.queueId, action.payload.uploadFile.id, err)]),
            filter(truthy),
        );
    }
}
