import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormControlConfig, FormControlSelectOption, FormValues } from '../../../core/models/form.model';
import { SingleValueFilterControl } from '../filter-control';
import { FilterControlContainerComponent } from '../filter-control-container/filter-control-container.component';
import { NgIf } from '@angular/common';

const KEY_ASSETS_CLASS = 'assetClass';
const KEY_INVESTMENT_FOCUS = 'investmentFocus';

@Component({
    selector: 'app-filter-assets-class-selection',
    templateUrl: './filter-assets-class-selection.component.html',
    styleUrls: ['./filter-assets-class-selection.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, FilterControlContainerComponent]
})
export class FilterAssetsClassSelectionComponent implements OnInit, SingleValueFilterControl<any>, OnChanges, OnDestroy {
    @Input() public config: FormControlConfig<any>;

    @Input() public values: FilterAssetsClassSelectionValues;

    @Input() public disabled = false;

    public _values: FilterAssetsClassSelectionValues = null;
    public onChange: (value: any) => void;

    public onTouched: () => void;

    public form: UntypedFormGroup;

    public valueChangesSubscription: Subscription;
    public assetsControlConfig: FormControlConfig = {
        options: {},
        path: '',
        type: 'dropdownSelect',
        values: '',
        disabled: false,
        styleHints: [],
    };

    public investmentFocusControlConfig: FormControlConfig = {
        options: {},
        path: '',
        type: 'multiSelect',
        values: '',
        disabled: false,
        styleHints: [],
    };

    get assetsClassValues(): FormControlSelectOption[] {
        return this._values && this._values[KEY_ASSETS_CLASS] || [];
    }

    get investmentFocusValues(): FormControlSelectOption[] {
        return this._values && this._values[KEY_INVESTMENT_FOCUS] || [];
    }

    constructor(private fb: UntypedFormBuilder, private cdf: ChangeDetectorRef) {
    }

    public ngOnInit() {
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.config && this.config) {
            this._updateForm();
        }
        if ((changes.config || changes.values) && this.values) {
            this._updateDefaultValues();
        }
    }

    public ngOnDestroy() {
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }
    }

    public writeValue(values: FormValues): void {
        if (values) {
            this.form.patchValue(values, {emitEvent: true});
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = (value) => {
            if (fn) {
                fn(value);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * Checks if the filter is valid
     * 
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this.form.valid) ? null : {
            doubleDropdownInputError: 'Invalid filter state specified',
        };
    }

    public setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    private _updateForm() {
        if (this.valueChangesSubscription) {
            this.valueChangesSubscription.unsubscribe();
        }

        const fields = {
            [KEY_ASSETS_CLASS]: [],
            [KEY_INVESTMENT_FOCUS]: [],
        };

        const oldValue = (this.form) ? this.form.value : undefined;

        this.form = this.fb.group(fields);
        if (oldValue) {
            this.form.patchValue(oldValue);
        }

        this.valueChangesSubscription = this.form.valueChanges.subscribe((value) => this.onChange(value));

        this.valueChangesSubscription.add(
            this.form.controls[KEY_ASSETS_CLASS].valueChanges.subscribe((value) => {
                if (!this.values) {
                    return;
                }
                this._values = {
                    [KEY_ASSETS_CLASS]: [...this.values[KEY_ASSETS_CLASS]],
                    [KEY_INVESTMENT_FOCUS]: [
                        ...this.values[KEY_INVESTMENT_FOCUS].filter((o) => o.assetClasses.includes(value)),
                    ],
                };

                this.cdf.detectChanges();
            }),
        );
    }

    private _updateDefaultValues() {
        if (this.values && !this._values) {
            this._values = {
                [KEY_ASSETS_CLASS]: [...this.values[KEY_ASSETS_CLASS]],
                [KEY_INVESTMENT_FOCUS]: [...this.values[KEY_INVESTMENT_FOCUS]],
            };
        }

        const assetsControl = this.form.get(KEY_ASSETS_CLASS);
        const investmentFocusControl = this.form.get(KEY_INVESTMENT_FOCUS);
        const patchValue = {};

        if (
            (assetsControl.value === undefined || assetsControl.value === null)
            && this.assetsClassValues.length
        ) {
            patchValue[KEY_ASSETS_CLASS] = this.assetsClassValues[0].id;
        }

        if (assetsControl.value || patchValue[KEY_ASSETS_CLASS]) {
            this._values[KEY_INVESTMENT_FOCUS] = [
                ...this.values[KEY_INVESTMENT_FOCUS].filter((o) =>
                    o.assetClasses.includes(assetsControl.value || patchValue[KEY_ASSETS_CLASS]),
                ),
            ];
        }

        if (
            (investmentFocusControl.value === undefined || investmentFocusControl.value === null)
            && this.investmentFocusValues.length
        ) {
            patchValue[KEY_INVESTMENT_FOCUS] = this.investmentFocusValues[0].id;
        }

        this.form.patchValue(patchValue);
    }
}

export interface FilterAssetsClassSelectionValues {
    [KEY_ASSETS_CLASS]: FormControlSelectOption[];
    [KEY_INVESTMENT_FOCUS]: (FormControlSelectOption & { assetClasses: string[] })[];
}
