import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { IconComponent } from '../../icon/icon.component';
import { NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NumberInputComponent } from '../../number-input/number-input.component';

@Component({
    selector: 'app-range-filter',
    templateUrl: './range-filter.component.html',
    styleUrls: ['./range-filter.component.scss'],
    standalone: true,
    imports: [NumberInputComponent, FormsModule, NgIf, IconComponent]
})
export class RangeFilterComponent implements OnInit {

    @HostBinding('class.range-filter') public numberInputClass = true;

    @Input() public unit: string;
    @Input() public min: number | string;
    @Input() public max: number | string;
    @Input() public minLabel: string;
    @Input() public maxLabel: string;
    @Input() public label: string;
    @Input() public enabled: boolean;
    @Input() public disabled = false;

    @Output() public minValue: EventEmitter<number> = new EventEmitter();
    @Output() public maxValue: EventEmitter<number> = new EventEmitter();
    @Output() public enabledValue: EventEmitter<boolean> = new EventEmitter();

    constructor() {
    }

    public ngOnInit() {
    }

    public toggle(event) {
        if (!event.target.checked) {
            this.enabled = false;
            this.max = null;
            this.min = null;
        } else {
            this.enabled = true;
        }
        this.enabledValue.next(this.enabled);
    }

    public onResetClicked() {
        this.max = null;
        this.min = null;
    }

    public onMinValueChanged(min: number) {
        this.min = min;
        this.minValue.next(this.min);
    }

    public onMaxValueChanged(max: number) {
        this.max = max;
        this.maxValue.next(this.max);
    }

}
