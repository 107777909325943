import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { EntityWidgetTableOptions } from '../../../../routes/product-details/models/product-details-structure.model';
import { sortEntities } from '../../../../routes/shared/product-shared/helpers/sort.helpers';
import { truthy } from '../../../helpers/general.helper';
import { Entity, EntitySortSetting, EntityTableConfig } from '../../../models/entities.model';
import { GenericActionStructure, EntityActionsComponent } from '../../../components/entity-actions/entity-actions.component';
import { EntityEvent, EntityTableComponent } from '../../../components/entity-table/entity-table.component';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf, AsyncPipe } from '@angular/common';
import { CustomPaginationControlsComponent } from '../../../components/custom-pagination-controls/custom-pagination-controls.component';
import { NgxPaginationModule } from 'ngx-pagination';

@Component({
    selector: 'app-entity-widget-table',
    templateUrl: './entity-widget-table.component.html',
    styleUrls: ['./entity-widget-table.component.scss'],
    standalone: true,
    imports: [NgIf, EntityActionsComponent, EntityTableComponent, AsyncPipe, TranslocoModule, CustomPaginationControlsComponent, NgxPaginationModule]
})
export class EntityWidgetTableComponent implements OnInit, OnChanges, EntityWidgetContentComponent, OnDestroy {
    @Input() public entityId: string;
    @Input() public width: number;

    @Input() set options(options: EntityWidgetTableOptions) {
        this.currentOptions$.next(options);
    }

    @Input() set data(data: Entity[]) {
        this.currentData$.next(data);
    }

    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();
    @Output() public componentEvent: EventEmitter<EntityEvent> = new EventEmitter();

    public currentOptions$: BehaviorSubject<EntityWidgetTableOptions> = new BehaviorSubject(null);
    public tableConfig$: Observable<EntityTableConfig>;
    public currentData$: BehaviorSubject<Entity[]> = new BehaviorSubject(null);
    public currentPage = 1;

    /**
     * Observable of the current sort settings
     * @type {BehaviorSubject<EntitySortSetting>}
     */
    public currentSort$: BehaviorSubject<EntitySortSetting> = new BehaviorSubject(null);
    public sortedTableValues$: Observable<Entity[]>;
    public selectionModel: SelectionModel<string> = new SelectionModel(true, []);
    public selectionChangeSubscription: Subscription;

    constructor() {
    }

    public ngOnChanges(changes: SimpleChanges): void {
    }

    public ngOnInit() {
        this.selectionChangeSubscription = this.selectionModel.changed.pipe(
            map((selectionChange) => selectionChange.source.selected),
        ).subscribe((selected) => {
            this.action.next({
                type: 'selectionChange',
                payload: {
                    selected,
                },
            });
        });

        this.tableConfig$ = this.currentOptions$.pipe(
            filter(truthy),
            map((options) => {
                return {
                    columns: options.fields,
                    actions: options.actions,
                    removable: options.removable,
                    selectable: options.selectable,
                    stickyHeader: options.stickyHeader,
                    stickyOffset: options.stickyOffset,
                };
            }),
        );

        this.sortedTableValues$ = combineLatest([
            this.currentSort$,
            this.currentData$,
        ]).pipe(
            map(([sort, values]) => {
                return sortEntities(values, sort);
            }),
        );
    }

    public ngOnDestroy() {
        this.selectionChangeSubscription.unsubscribe();
        this.selectionModel.clear();
        delete this.selectionModel;
    }

    /**
     * Called when the user changed the table's sorting
     * @param {EntitySortSetting} sort
     */
    public onSortChanged(sort: EntitySortSetting) {
        this.currentSort$.next(sort);
    }

    /**
     * Called when the user has clicked on an action
     * @param {GenericActionStructure} action
     */
    public onActionClicked(action: GenericActionStructure) {
        this.action.next({
            type: 'tableAction',
            payload: {
                action,
                currentSort: this.currentSort$.getValue(),
            },
        });
    }

    public onPageChanged(page: number): void {
        this.currentPage = page;
    }

    public onToggleSelection({entityId, selected}: { entityId: string, selected: boolean }) {
        if (selected) {
            this.selectionModel.select(entityId);
        } else {
            this.selectionModel.deselect(entityId);
        }
    }

    public onToggleSelections({entityIds, selected}: { entityIds: string[], selected: boolean }) {
        if (selected) {
            this.selectionModel.select(...entityIds);
        } else {
            this.selectionModel.deselect(...entityIds);
        }
    }

    public onRemove(entity: Entity) {
        this.action.next({
            type: 'remove',
            payload: {
                entityId: entity.id,
            },
        });

        this.selectionModel.deselect(entity.id);
    }

    public onComponentEvent(e: EntityEvent) {
        this.componentEvent.next(e);
    }
}
