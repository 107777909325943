import { createReducer, on } from "@ngrx/store";
import { clone, setWith } from "lodash/fp";
import * as CustomerSelectionActions from "../actions/customer-selection.actions";
import { CustomerSelectionState } from "../state/customer-selection.state";

export const initialState: CustomerSelectionState = {
    tab: {
        list: []
    },
    activated: {
        label: 'targetMarket.customerSelection',
        link: ['/app', {outlets: {'overlay': ['customer-selection']}}],
        customer: null
    }
} 

export const reducer = createReducer(initialState,
    on(CustomerSelectionActions.setTabSelected, (state, { selected }): CustomerSelectionState => 
        ({
            ...state,
            tab: {
                ...state.tab,
                selected,
                link: ['/app', {outlets: {'overlay': ['customer-selection', selected]}}]
            },
            activated: {
                ...state.activated,
                link: ['/app', {outlets: {'overlay': ['customer-selection', selected]}}]
            }
        })
    ),
    on(CustomerSelectionActions.loadTabList, (state, { list }): CustomerSelectionState =>
        ({
            ...state,
            tab: {
                ...state.tab,
                list
            }
        })
    ),
    on(CustomerSelectionActions.activateCustomer, (state, { customer, customerType }): CustomerSelectionState  =>
        ({
            ...state,
            activated: {
                label: 'targetMarket.customCustomerActivated',
                labelParams: {
                    customerName: customer.details.customerId 
                },
                link: setWith(
                    clone,
                    '[1].outlets.overlay',
                    ['customer-selection', customerType, customer.id],
                    state.activated.link
                ),
                customer
            }
        })
    ),
    on(CustomerSelectionActions.activateHALCustomer, (state, { customer }): CustomerSelectionState  =>
        ({
            ...state,
            activated: {
                label: 'targetMarket.customerActivated',
                labelParams: {
                    customerId: customer.id.criteriaId,
                    customerName: customer.customData.name
                },
                link: setWith(
                    clone,
                    '[1].outlets.overlay',
                    ['customer-selection', 'hal', customer.id.fullPath.join(',')],
                    state.activated.link
                ),
                customer
            }
        })
    ),
    on(CustomerSelectionActions.deactivateCustomer, (state): CustomerSelectionState  =>
        ({
            ...state,
            activated: {
                label: initialState.activated.label,
                link: initialState.activated.link,
                customer: initialState.activated.customer
            }
        })
    )      
);
