/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 3/30/17.
 */

import { Action } from '@ngrx/store';

import {
    ActivateTargetMarketCustomCustomerAction,
    ActivateTargetMarketCustomerAction, ActivateTargetMarketReferenceCustomerAction,
    CreateTargetMarketCustomCustomerSucceededAction,
    EngageTargetMarketCriteriaAction,
    LoadTargetMarketCriteriaAvailableValuesSucceededAction,
    LoadTargetMarketCriteriaStructureSucceededAction,
    LoadTargetMarketCustomCustomersSucceededAction,
    LoadTargetMarketCustomerSuggestionsSucceededAction, LoadTargetMarketReferenceCustomersSucceededAction,
    LoadTargetMarketSelectedCustomerSucceededAction,
    RemoveTargetMarketCustomCustomerSucceededAction,
    SaveTargetMarketCustomCustomerSucceededAction,
    SetTempTargetMarketCriteriaAction,
    StoreTargetMarketCustomCustomerAction,
    TargetMarketActionTypes,
    UpdateTargetMarketCriteriaAction,
} from '../actions/target-market.actions';

import { TargetMarketState } from '../state/target-market.state';

import { omit } from 'lodash';

export const initialState: TargetMarketState = {
    criteria: null,
    encodedCriteria: null,
    activatedCustomCustomerId: null,
    activatedReferenceCustomerId: null,
    customCustomers: {},
    referenceCustomers: {},
    structure: null,
    values: null,
    activatedCustomer: null,
    tempCriteria: null,
    customCustomerSaved: null,
    customCustomerCreated: null,
    selectedCustomer: null,
    suggestions: null,
};

/**
 * The reducer responsible for the @link{TargetMarketState}
 * @param state
 * @param action
 * @returns {any}
 */
export function reducer(state: TargetMarketState = initialState, action: Action): TargetMarketState {
    switch (action.type) {

        /**
         * Engage the target market criteria
         */
        case TargetMarketActionTypes.ENGAGE_TARGET_MARKET_CRITERIA:
            const engageTargetMarketCriteriaAction = action as EngageTargetMarketCriteriaAction;
            return {
                ...state,
                criteria: engageTargetMarketCriteriaAction.payload.criteria,
                encodedCriteria: engageTargetMarketCriteriaAction.payload.encodedCriteria,
            };

        /**
         * Disengage the target market criteria
         */
        case TargetMarketActionTypes.DISENGAGE_TARGET_MARKET_CRITERIA:
            return {
                ...state,
                criteria: null,
                encodedCriteria: null,
                activatedCustomer: null,
                activatedCustomCustomerId: null,
                activatedReferenceCustomerId: null,
            };

        /**
         * Update the current target market criteria
         */
        case TargetMarketActionTypes.UPDATE_TARGET_MARKET_CRITERIA:
            const updateTargetMarketCriteriaAction = action as UpdateTargetMarketCriteriaAction;
            return {
                ...state,
                criteria: updateTargetMarketCriteriaAction.payload.criteria,
            };

        /**
         * Clear the current target market criteria and disengage them
         */
        case TargetMarketActionTypes.CLEAR_TARGET_MARKET_CRITERIA:
            return {
                ...state,
                criteria: null,
                encodedCriteria: null,
            };

        /**
         * Store the provided target market customer
         */
        case TargetMarketActionTypes.STORE_TARGET_MARKET_CUSTOM_CUSTOMER:
            const storeTargetMarketCustomerAction = action as StoreTargetMarketCustomCustomerAction;
            return {
                ...state,
                customCustomers: {
                    ...state.customCustomers,
                    [storeTargetMarketCustomerAction.payload.customer.id]: storeTargetMarketCustomerAction.payload.customer,
                },
            };

        // /**
        //  * Select the provided target market customer
        //  */
        // case TargetMarketActionTypes.SELECT_TARGET_MARKET_CUSTOM_CUSTOMER:
        //     const selectTargetMarketCustomerAction = action as SelectTargetMarketCustomerCustomerAction;
        //     return {
        //         ...state,
        //         criteriaEngaged: true,
        //         criteria: selectTargetMarketCustomerAction.payload.customer.criteria,
        //         activatedCustomCustomerId: selectTargetMarketCustomerAction.payload.customer.id
        //     };

        /**
         * Unselect the currently selected target market customer
         */
        case TargetMarketActionTypes.UNSELECT_TARGET_MARKET_CUSTOMER:
            return {
                ...state,
                criteria: initialState.criteria,
                activatedCustomCustomerId: null,
                activatedReferenceCustomerId: null,
                encodedCriteria: null,
            };

        /**
         * Store the target market criteria structure
         */
        case TargetMarketActionTypes.LOAD_TARGET_MARKET_CRITERIA_STRUCTURE_SUCCEEDED:
            const loadTargetMarketCriteriaStructureSucceededAction = action as LoadTargetMarketCriteriaStructureSucceededAction;
            return {
                ...state,
                structure: loadTargetMarketCriteriaStructureSucceededAction.payload.structure,
            };

        /**
         * Store the target market criteria available values
         */
        case TargetMarketActionTypes.LOAD_TARGET_MARKET_CRITERIA_AVAILABLE_VALUES_SUCCEEDED:
            const loadTargetMarketCriteriaAvailableValuesSucceededAction = action as LoadTargetMarketCriteriaAvailableValuesSucceededAction;
            return {
                ...state,
                values: loadTargetMarketCriteriaAvailableValuesSucceededAction.payload.values,
            };

        /**
         * Store the activated target market customer
         */
        case TargetMarketActionTypes.ACTIVATE_TARGET_MARKET_CUSTOMER:
            const activateTargetMarketCustomerAction = action as ActivateTargetMarketCustomerAction;
            return {
                ...state,
                activatedCustomer: activateTargetMarketCustomerAction.payload.customer,
                activatedCustomCustomerId: null,
                activatedReferenceCustomerId: null,
            };

        /**
         * Store the custom target market customers
         */
        case TargetMarketActionTypes.LOAD_TARGET_MARKET_CUSTOM_CUSTOMERS_SUCCEEDED:
            const loadTargetMarketCustomCustomersSucceededAction = action as LoadTargetMarketCustomCustomersSucceededAction;
            return {
                ...state,
                customCustomers: loadTargetMarketCustomCustomersSucceededAction.payload.customers.reduce(
                    (customers, customer) => {
                        customers[customer.id] = customer;
                        return customers;
                    }, {}),
            };

        /**
         * Store the reference target market customers
         */
        case TargetMarketActionTypes.LOAD_TARGET_MARKET_REFERENCE_CUSTOMERS_SUCCEEDED:
            const loadTargetMarketReferenceCustomersSucceededAction = action as LoadTargetMarketReferenceCustomersSucceededAction;
            return {
                ...state,
                referenceCustomers: loadTargetMarketReferenceCustomersSucceededAction.payload.customers.reduce(
                    (customers, customer) => {
                        customers[customer.id] = customer;
                        return customers;
                    }, {}),
            };

        /**
         * Store the activated target market custom customer
         */
        case TargetMarketActionTypes.ACTIVATE_TARGET_MARKET_REFERENCE_CUSTOMER:
            const activateTargetMarketReferenceCustomerAction = action as ActivateTargetMarketReferenceCustomerAction;

            return {
                ...state,
                activatedReferenceCustomerId: activateTargetMarketReferenceCustomerAction.payload.customer.id,
                activatedCustomCustomerId: null,
                activatedCustomer: null,
            };

        /**
         * Store the activated target market custom customer
         */
        case TargetMarketActionTypes.ACTIVATE_TARGET_MARKET_CUSTOM_CUSTOMER:
            const activateTargetMarketCustomCustomerAction = action as ActivateTargetMarketCustomCustomerAction;
            return {
                ...state,
                activatedCustomCustomerId: activateTargetMarketCustomCustomerAction.payload.customer.id,
                activatedReferenceCustomerId: null,
                activatedCustomer: null,
            };

        /**
         * Store the new target market custom customer
         */
        case TargetMarketActionTypes.CREATE_TARGET_MARKET_CUSTOM_CUSTOMER:
            return {
                ...state,
                customCustomerCreated: false,
            };

        /**
         * Store the new target market custom customer
         */
        case TargetMarketActionTypes.CREATE_TARGET_MARKET_CUSTOM_CUSTOMER_SUCCEEDED:
            const createTargetMarketCustomCustomerSucceededAction = action as CreateTargetMarketCustomCustomerSucceededAction;
            return {
                ...state,
                customCustomers: {
                    ...state.customCustomers,
                    [createTargetMarketCustomCustomerSucceededAction.payload.customer.id]:
                    createTargetMarketCustomCustomerSucceededAction.payload.customer,
                },
                customCustomerCreated: true,
            };

        /**
         * Reset the saved state of the custom customer
         */
        case TargetMarketActionTypes.SAVE_TARGET_MARKET_CUSTOM_CUSTOMER:
            return {
                ...state,
                customCustomerSaved: false,
            };

        /**
         * Store the new version of the target market custom customer
         */
        case TargetMarketActionTypes.SAVE_TARGET_MARKET_CUSTOM_CUSTOMER_SUCCEEDED:
            const saveTargetMarketCustomCustomerSucceededAction = action as SaveTargetMarketCustomCustomerSucceededAction;
            const isCustomerActive = state.activatedCustomCustomerId === saveTargetMarketCustomCustomerSucceededAction.payload.customer.id;

            return {
                ...state,
                customCustomers: {
                    ...state.customCustomers,
                    [saveTargetMarketCustomCustomerSucceededAction.payload.customer.id]:
                    saveTargetMarketCustomCustomerSucceededAction.payload.customer,
                },
                customCustomerSaved: true,
                criteria: isCustomerActive ? {
                    ...state.criteria,
                    ...saveTargetMarketCustomCustomerSucceededAction.payload.customer.criteria,
                } : state.criteria,
            };

        /**
         * Remove the target market custom customer from the store
         */
        case TargetMarketActionTypes.REMOVE_TARGET_MARKET_CUSTOM_CUSTOMER_SUCCEEDED:
            const removeTargetMarketCustomCustomerSucceededAction = action as RemoveTargetMarketCustomCustomerSucceededAction;
            return {
                ...state,
                customCustomers: omit(state.customCustomers,
                    removeTargetMarketCustomCustomerSucceededAction.payload.customerId,
                ),
            };

        /**
         * Set the given criteria in the temp criteria slot
         */
        case TargetMarketActionTypes.SET_TEMP_TARGET_MARKET_CRITERIA:
            const setTempTargetMarketCriteria = action as SetTempTargetMarketCriteriaAction;
            return {
                ...state,
                tempCriteria: setTempTargetMarketCriteria.payload.criteria,
            };

        /**
         * Clear the temp criteria slot
         */
        case TargetMarketActionTypes.CLEAR_TEMP_TARGET_MARKET_CRITERIA:
            return {
                ...state,
                tempCriteria: null,
            };

        /**
         * Set the given suggestions into the suggestions slot
         */
        case TargetMarketActionTypes.LOAD_TARGET_MARKET_CUSTOMER_SUGGESTIONS_SUCCEEDED:
            const loadTargetMarketCustomerSuggestionsSucceededAction = action as LoadTargetMarketCustomerSuggestionsSucceededAction;
            return {
                ...state,
                suggestions: loadTargetMarketCustomerSuggestionsSucceededAction.payload.data,
            };

        /**
         * Clear the target market customer suggestions
         */
        case TargetMarketActionTypes.CLEAR_TARGET_MARKET_CUSTOMER_SUGGESTIONS:
            return {
                ...state,
                suggestions: [],
            };

        /**
         * Clear the currently selected target market customer
         */
        case TargetMarketActionTypes.CLEAR_TARGET_MARKET_SELECTED_CUSTOMER:
            return {
                ...state,
                selectedCustomer: null,
            };

        /**
         * Set the given criteria into the criteria slot
         */
        case TargetMarketActionTypes.LOAD_TARGET_MARKET_SELECTED_CUSTOMER_SUCCEEDED:
            const loadTargetMarketSelectedCustomerSucceededAction = action as LoadTargetMarketSelectedCustomerSucceededAction;
            return {
                ...state,
                selectedCustomer: loadTargetMarketSelectedCustomerSucceededAction.payload.customerData,
            };

        default:
            return state;
    }
}
