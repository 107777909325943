import { createAction, props } from '@ngrx/store';
import { DynamicChartTimeFrame, ExtendedDynamicChartData, ExtendedDynamicChartOptions } from '../../../../../shared/models/dynamic-chart.model';
import { FundsCompareStructureResponse } from '../../models/funds-compare.model';

export const loadStructure = createAction(
    '[Funds Compare] Load Structure',
    props<{ dqs: Array<string> }>()
);

export const setStructure = createAction(
    '[Funds Compare] Set Structure',
    props<{
        dqs: Array<string>;
        structure: FundsCompareStructureResponse;
    }>()
);

export const loadProducts = createAction(
    '[Funds Compare] Load Products',
    props<{ dqs: Array<string> }>()
);

export const setProducts = createAction(
    '[Funds Compare] Set Products',
    props<{ products: Array<any> }>()
);

export const setChart = createAction(
    '[Funds Compare] Set Chart',
    props<{
        data: ExtendedDynamicChartData;
        options: ExtendedDynamicChartOptions;
    }>()
);

export const updateChartTimeFrame = createAction(
    '[Funds Compare] Update Chart Filter',
    props<{ timeFrame: DynamicChartTimeFrame }>()
);
