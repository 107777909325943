import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { ConfirmDialogData } from './confirm-dialog-data.interface';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf } from '@angular/common';
import { IconComponent } from '../../icon/icon.component';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    standalone: true,
    imports: [IconComponent, NgIf, TranslocoModule]
})
export class ConfirmDialogComponent {
  public title: string;
  public message: string;
  public html: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ConfirmDialogData,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {
    this.title = this.data.title;
    this.message = this.data.message;
    this.html = !!this.data.html;
  }

  public onCloseClick(e: Event): void {
    e.preventDefault();
    this.dialogRef.close({ confirmed: false });
  }

  public onYesClick(e: Event): void {
    e.preventDefault();
    this.dialogRef.close({ confirmed: true });
  }

  public onNoClick(e: Event): void {
    e.preventDefault();
    this.dialogRef.close({ confirmed: false });
  }
}
