<div *ngIf="visibleInput else: textOnly" class="form-group">
    <app-multi-select-dropdown
        placeholder="Bitte wählen"
        [items]="_availableItems"
        [active]="_selectedItems"
        [disabled]="disabled"
        (data)="onSelectionChange($event)">
    </app-multi-select-dropdown>
</div>

<ng-template #textOnly>
    <ng-container *ngIf="_selectedItems.length > 0">
        <div class="ui-select-match mt-2">
            <div *ngFor="let item of _selectedItems">
                <span>{{item.text}}</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="_selectedItems.length === 0">
        <span>&mdash;</span>
    </ng-container>
</ng-template>