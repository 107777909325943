import { get } from 'lodash';
import {
    ImageReference,
    ImageReferenceAddress,
    SetReferenceAddress,
    SimpleReferenceAddress,
    UnifiedImageReference,
} from '../models/image-reference.model';

export const ImageMaps = {
    dashboard: {
        ACTION_POINT_01_BW: 'ACTION_POINT_01_BW.png',
        ACTION_POINT_01_COLOR: 'ACTION_POINT_01_COLOR.png',
        ACTION_PUZZLE_01_BW: 'ACTION_PUZZLE_01_BW.png',
        ACTION_PUZZLE_01_COLOR: 'ACTION_PUZZLE_01_COLOR.png',
        BLOCKS_MIDDLE_BW: 'BLOCKS_MIDDLE_BW.jpg',
        BLOCKS_MIDDLE_COLOR: 'BLOCKS_MIDDLE_COLOR.jpg',
        LOGO_BAYER_01_BW: 'LOGO_BAYER_01_BW.png',
        LOGO_BAYER_01_COLOR: 'LOGO_BAYER_01_COLOR.png',
        LOGO_DWS_01_BW: 'LOGO_DWS_01_BW.png',
        LOGO_DWS_01_COLOR: 'LOGO_DWS_01_COLOR.png',
        LOGO_ALLIANZ_01_BW: 'LOGO_ALLIANZ_01_BW.png',
        LOGO_ALLIANZ_01_COLOR: 'LOGO_ALLIANZ_01_COLOR.png',
        LOGO_COCA_COLA_01_BW: 'LOGO_COCA_COLA_01_BW.png',
        LOGO_COCA_COLA_01_COLOR: 'LOGO_COCA_COLA_01_COLOR.png',
        MAGNIFIER_MIDDLE_BW: 'MAGNIFIER_MIDDLE_BW.jpg',
        MAGNIFIER_MIDDLE_COLOR: 'MAGNIFIER_MIDDLE_COLOR.jpg',
        MAGNIFIER_RIGHT_BW: 'MAGNIFIER_RIGHT_BW.jpg',
        MAGNIFIER_RIGHT_COLOR: 'MAGNIFIER_RIGHT_COLOR.jpg',
        PEN_LEFT_BW: 'PEN_LEFT_BW.jpg',
        PEN_LEFT_COLOR: 'PEN_LEFT_COLOR.jpg',
        PEN_RIGHT_BW: 'PEN_RIGHT_BW.jpg',
        PEN_RIGHT_COLOR: 'PEN_RIGHT_COLOR.jpg',
        PUZZLE_BW: 'PUZZLE_BW.jpg',
        PUZZLE_COLOR: 'PUZZLE_COLOR.jpg',
        OIL_PUMP_01_BW: 'OIL_PUMP_01_BW.jpg',
        OIL_PUMP_01_COLOR: 'OIL_PUMP_01_COLOR.jpg',
        COFFEE_BW: 'COFFEE_BW.png',
        COFFEE_COLOR: 'COFFEE_COLOR.png',
    },
    issuers: {
        BNP: 'bnp.png',
        CITIGROUP: 'citigroup.png',
        CREDIT_SUISSE: 'credit-suisse.png',
        DEUTSCHE_BANK: 'deutsche-bank.png',
        DZ_BANK: 'dz-bank.png',
        GOLDMAN_SACHS: 'goldman_sachs.png',
        HSBC: 'hsbc.png',
    },
};

export function isSimpleReferenceAddress(arg: any): arg is SimpleReferenceAddress {
    return arg.type === 'SIMPLE';
}

export function isSetReferenceAddress(arg: any): arg is SetReferenceAddress {
    return arg.type === 'SET';
}

export function isSimpleImageReference(arg: UnifiedImageReference): boolean {
    return arg.type === 'SIMPLE';
}

export function isSetImageReference(arg: UnifiedImageReference): boolean {
    return arg.type === 'SET';
}

export function getImagePathFromReference(ref: UnifiedImageReference, key: string): string {
    if (isSetImageReference(ref)) {
        return ref.set.has(key) ? ref.set.get(key).path : '';
    } else {
        return ref.simple.path;
    }
}
export function getImageFromReference(ref: UnifiedImageReference, key: string): ImageReference {
    if (isSetImageReference(ref)) {
        return ref.set.has(key) ? ref.set.get(key) : null;
    } else {
        return ref.simple;
    }
}

export function parseReference(ref: string): ImageReferenceAddress {
    try {
        const decodedRef = atob(ref);
        const json = JSON.parse(decodedRef);

        return json as ImageReferenceAddress;
    } catch (e) {
        const pathParts = ref.split('.');
        const type = pathParts.pop();

        if (type.endsWith('_BW') || type.endsWith('_COLOR')) {
            let imageBW = type;
            let imageColor = type;

            if (type.endsWith('_BW')) {
                imageColor = type.substr(0, type.length - 3) + '_COLOR';
            } else if (type.endsWith('_COLOR')) {
                imageColor = type;
                imageBW = type.substr(0, type.length - 5) + '_BW';
            }

            let imageBWPath = [...pathParts, imageBW].join('.');
            let imageCOLORPath = [...pathParts, imageColor].join('.');

            if (!get(ImageMaps, imageBWPath)) {
                imageBWPath = imageCOLORPath;
            } else if (!get(ImageMaps, imageCOLORPath)) {
                imageCOLORPath = imageBWPath;
            }

            return {
                type: 'SET',
                paths: {
                    BW: {
                        location: 'LOCAL',
                        path: imageBWPath,
                        mode: 'BW',
                    } as SimpleReferenceAddress,
                    COLOR: {
                        location: 'LOCAL',
                        path: imageCOLORPath,
                        mode: 'COLOR',
                    } as SimpleReferenceAddress,
                },
            } as SetReferenceAddress;
        }

        return {
            type: 'SIMPLE',
            location: 'LOCAL',
            path: ref,
        } as SimpleReferenceAddress;
    }
}
