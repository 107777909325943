import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, fromEvent, Observable, Subject } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UiService } from './ui.service';

@Injectable({
    providedIn: 'root',
})
export class ScrollService {
    public overlayScroll$ = new BehaviorSubject<number>(0);
    private windowScroll$ = fromEvent(window, 'scroll').pipe(
        map((event) => window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0),
    );
    public combinedScroll$: Observable<number>;
    public windowShouldScroll$ = new Subject<number>();
    public overlayShouldScroll$ = new Subject<number>();

    constructor(private uiService: UiService) {
        this.combinedScroll$ = combineLatest([
            this.uiService.isOverlayOpen(),
            this.overlayScroll$.asObservable(),
            this.windowScroll$,
        ]).pipe(
            map(([isOverlayOpen, overlayScroll, windowScroll]) => isOverlayOpen ? overlayScroll : windowScroll),
        );

        this.windowShouldScroll$.asObservable().subscribe((position) => {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        });
    }

    public scroll(position) {
        this.uiService.isOverlayOpen().pipe(
            take(1),
        ).subscribe((isOverlayOpen) => {
            if (isOverlayOpen) {
                this.overlayShouldScroll$.next(position);
            } else {
                this.windowShouldScroll$.next(position);
            }
        });
    }

    public emitOverlayScroll(position: number) {
        this.overlayScroll$.next(position);
    }
}
