/**
 * Created by Alex Klein <alex@rocketloop.de> on 9/7/17.
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    assetsExcelAllRequest,
    assetsExcelRequest,
    attachmentRequest,
    equivalenceCheckExportRequest,
    equivalenceExportRequest,
    externalProductLinkRequest,
    multiProductReportRequest,
    onePagerRequest,
    productsExcelAllRequest,
    productsExcelRequest,
    reportRequest,
    rfqMultiRequestExcelExportRequest,
    rfqRequestExportRequest,
    rfqResultExportRequest,
    shortlistExportRequest,
    shortlistGroupedExportRequest,
    tmcCheckReportRequest, tmcExportRequest,
    tradesExcelAllRequest,
    tradesExcelRequest,
    usersExcelAllRequest,
    usersExcelRequest,
    watchlistExcelRequest,
} from '../store/actions/async-link.action';
import { AppState } from '../store/state/index.state';
import { Dictionary } from '@ngrx/entity';
import { MultiRowComments } from '../../shared/components/entity-table-multi-row-comment/entity-table-multi-row-comment.interface';
import { StringObject } from '../models/generic.model';

/**
 * Service responsible for download requests
 */
@Injectable()
export class AsyncLinkService {

    constructor(private readonly store: Store<AppState>) {
    }

    /**
     * Dispatchs a one pager request
     * @param {string[]} productDqs
     * @param {string} type
     * @param targetMarketCriteria
     */
    public sendOnePagerRequest(productDqs: string[], type?: string, targetMarketCriteria?: string, context?:string) {
        this.store.dispatch(onePagerRequest(productDqs, type, targetMarketCriteria, context));
    }

    public sendProductsExcelRequest(dqs: string[], context?: string) {
        this.store.dispatch(productsExcelRequest(dqs, context));
    }

    public sendWatchlistExcelRequest(watchlistId: string, dqs: string[], context: string) {
        this.store.dispatch(watchlistExcelRequest(watchlistId, dqs, context));
    }

    public sendProductsExcelAllRequest() {
        this.store.dispatch(productsExcelAllRequest());
    }

    public sendTradesExcelRequest(dqs: string[]) {
        this.store.dispatch(tradesExcelRequest(dqs));
    }

    public sendTradesExcelAllRequest(sessionId: string) {
        this.store.dispatch(tradesExcelAllRequest(sessionId));
    }

    public sendAssetsExcelRequest(sessionId: string, dqs: string[]) {
        this.store.dispatch(assetsExcelRequest(sessionId, dqs));
    }

    public sendAssetsExcelAllRequest(sessionId: string) {
        this.store.dispatch(assetsExcelAllRequest(sessionId));
    }

    public sendUsersExcelRequest(users: string[]) {
        this.store.dispatch(usersExcelRequest(users));
    }

    public sendRFQExportRequest(moduleId: string, quoteId: string, redirectToken: string) {
        this.store.dispatch(rfqRequestExportRequest(moduleId, quoteId, redirectToken));
    }

    public sendRFQExportResult(moduleId: string, quoteId: string, redirectToken: string) {
        this.store.dispatch(rfqResultExportRequest(moduleId, quoteId, redirectToken));
    }

    public sendRFQExcelExportRequest(moduleId: string, quoteId: string) {
        this.store.dispatch(rfqMultiRequestExcelExportRequest(moduleId, quoteId));
    }

    public sendReportRequest(dq: string, quote: number, date: string) {
        this.store.dispatch(reportRequest({
            dq,
            quote,
            date,
        }));
    }

    public sendTmcCheckReportRequest(reference: Record<'tmc' | 'label', string>, productDq: string, comment?: string) {
        this.store.dispatch(tmcCheckReportRequest(reference, productDq, comment));
    }

    public sendMultiProductReportRequest(dqs: string[]) {
        this.store.dispatch(multiProductReportRequest({
            dqs
        }));
    }

    public sendEquivalenceExportRequest(
        watchlistId: string,
        resultId: string,
        comments: MultiRowComments
    ): void {
        this.store.dispatch(equivalenceExportRequest(watchlistId, resultId, comments));
    }

    public sendEquivalenceCheckExportRequest(
        productDq: string,
        equivalenceCheckId: string,
        comments: StringObject
    ): void {
        this.store.dispatch(
            equivalenceCheckExportRequest(
                productDq,
                equivalenceCheckId,
                comments
            )
        );
    }

    public sendTmcExportRequest(watchlistId: string, comments: Dictionary<string>) {
        this.store.dispatch(tmcExportRequest(watchlistId, comments));
    }

    public sendAttachmentRequest(attachmentId: string) {
        this.store.dispatch(attachmentRequest(attachmentId));
    }

    public sendExternalLinkRequest(productDq: string, linkType: string, tmc?: string) {
        this.store.dispatch(externalProductLinkRequest(productDq, linkType, tmc));
    }

    public sendUsersExcelAllRequest() {
        this.store.dispatch(usersExcelAllRequest());
    }

    public sendShortlistExportRequest(shortlistId: string, ids: string[]) {
        this.store.dispatch(shortlistExportRequest(shortlistId, ids));
    }

    public sendShortlistGroupedExportRequest(id: string): void {
        this.store.dispatch(shortlistGroupedExportRequest(id));
    } 
}

export interface ReportRequestData {
    dq: string;
    quote: number;
    date: string;
}

export interface MultiProductReportRequestData {
    dqs: string[];
}

export interface TmcReportRequestData {
    productDq: string;
    comment?: string;
    reference: {
        tmc: string;
        label: string;
    };
}
