/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 5/4/17.
 */
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { of } from 'rxjs';
import { AuthStoreService } from '../services/auth-store.service';
import { LogglyLoggerService } from '../services/loggly-logger.service';
import { ApiOptions, BaseApi } from './base.api';

/**
 * API abstraction layer for the Auth API
 */
export class AuthenticatedApi extends BaseApi {
    constructor(baseUrl: string, http: HttpClient, private authStoreApi: AuthStoreService, loggerService: LogglyLoggerService) {
        super(baseUrl, http, loggerService);
    }

    protected isRequestAuthenticated(apiOptions: ApiOptions) {
        return apiOptions.authenticated !== undefined ? apiOptions.authenticated : true;
    }

    protected getOptions(apiOptions: ApiOptions) {
        const options = {
            ...apiOptions,
            headers: apiOptions.headers || {},
        };
        return this.isRequestAuthenticated(options)
            ? this.authStoreApi.getAccessToken().pipe(
                  take(1),
                  map((accessToken) => {
                      if (accessToken) {
                          options.headers.Authorization = `Bearer ${accessToken}`;
                      }
                      return options;
                  }),
              )
            : of(options);
    }

    public get(endpoint: string, apiOptions: ApiOptions = {}): Observable<any> {
        return this.getOptions(apiOptions).pipe(switchMap((options) => super.get(endpoint, options)));
    }

    public post(endpoint: string, body: any, apiOptions: ApiOptions = {}): Observable<any> {
        return this.getOptions(apiOptions).pipe(switchMap((options) => super.post(endpoint, body, options)));
    }

    public put(endpoint: string, body: any, apiOptions: ApiOptions = {}): Observable<any> {
        return this.getOptions(apiOptions).pipe(switchMap((options) => super.put(endpoint, body, options)));
    }

    public patch(endpoint: string, body: any, apiOptions: ApiOptions = {}): Observable<any> {
        return this.getOptions(apiOptions).pipe(switchMap((options) => super.patch(endpoint, body, options)));
    }

    public delete(endpoint: string, apiOptions: ApiOptions = {}): Observable<any> {
        return this.getOptions(apiOptions).pipe(switchMap((options) => super.delete(endpoint, options)));
    }
}
