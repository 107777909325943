import { CommonModule, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { ProductSelectionService } from '../routes/product-selection/services/product-selection.service';
import { ProductSharedModule } from '../routes/shared/product-shared/product-shared.module';
import { TargetMarketApi } from '../routes/target-market/api/target-market.api';
import { SharedModule } from '../shared/shared.module';
import { AsyncLinkApi } from './api/async-link.api';
import { ConfigApi } from './api/config.api';
import { DerichartApi } from './api/derichart.api';
import { ReportApi } from './api/report.api';
import { RiskManagerApi } from './api/risk-manager.api';
import { StringsApi } from './api/strings.api';
import { WidgetDialogComponent } from './components/widget-dialog/widget-dialog.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { ScrollTopButtonComponent } from './components/scroll-top-button/scroll-top-button.component';
import { AuthGuard } from './guards/auth.guard';
import { NoAuthGuard } from './guards/no-auth.guard';
import { AdminLayoutComponent } from './layouts/admin-layout.component';
import { DefaultLayoutComponent } from './layouts/default-layout.component';
import { ExternalLayoutComponent } from './layouts/external-layout.component';
import { LandingLayoutComponent } from './layouts/landing-layout.component';
import { AsyncLinkService } from './services/async-link.service';
import { AuthStoreService } from './services/auth-store.service';
import { AuthService } from './services/auth.service';
import { EntityActionsActivationTriggersService } from './services/entity-actions-activation-triggers.service';
import { EntityTableDecoratorService } from './services/entity-table-decorator.service';
import { EntityTableStyleDecoratorService } from './services/entity-table-style-decorator.service';
import { GlobalErrorHandler } from './services/error.service';
import { ImageReferenceResolverService } from './services/image-reference-resolver.service';
import { LocaleFormatterService } from './services/locale-formatter.service';
import { LogglyLoggerService } from './services/loggly-logger.service';
import { MenuService } from './services/menu.service';
import { PopupService } from './services/popup.service';
import { ResizeService } from './services/resize.service';
import { RiskManagerService } from './services/risk-manager.service';
import { ScheduledActionService } from './services/scheduled-action.service';
import { StringsService } from './services/strings.service';
import { UiService } from './services/ui.service';
import { UploadService } from './services/upload.service';
import { AdditionalWidgetsDataService } from './services/widgets-data.service';
import { EquivalenceApi } from './api/equivalence.api';
import { ProductFormApi } from './api/product-form-api';
import { ObjectMaker } from './helpers/form.helper';
import { ActionPayloadDialogComponent } from './components/action-payload-dialog/action-payload-dialog.component';
import { NewVersionToastComponent } from './toasts/new-version-toast/new-version-toast.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        ProductSharedModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        DefaultLayoutComponent,
        ExternalLayoutComponent,
        AdminLayoutComponent,
        LandingLayoutComponent,
        TopBarComponent,
        ScrollTopButtonComponent,
        MainMenuComponent,
        OverlayComponent,
        WidgetDialogComponent,
        ActionPayloadDialogComponent,
        NewVersionToastComponent
    ],
    exports: [
        DefaultLayoutComponent,
        ExternalLayoutComponent,
        AdminLayoutComponent,
        LandingLayoutComponent
    ],
    providers: [
        UiService,
        ResizeService,
        AdditionalWidgetsDataService,
        ConfigApi,
        StringsApi,
        StringsService,
        EquivalenceApi,
        AsyncLinkApi,
        ReportApi,
        TargetMarketApi,
        DerichartApi,
        ProductFormApi,
        AuthStoreService,
        AuthService,
        MenuService,
        AsyncLinkService,
        AuthGuard,
        NoAuthGuard,
        ProductSelectionService,
        ScheduledActionService,
        EntityTableDecoratorService,
        ImageReferenceResolverService,
        EntityTableStyleDecoratorService,
        EntityActionsActivationTriggersService,
        UploadService,
        RiskManagerApi,
        RiskManagerService,
        LocaleFormatterService,
        PopupService,
        DatePipe,
        DecimalPipe,
        PercentPipe,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        LogglyLoggerService,
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'DD.MM.YYYY',
                },
                display: {
                    dateInput: 'DD.MM.YYYY',
                    monthYearLabel: 'DD.MM.YYYY',
                    dateA11yLabel: 'DD.MM.YYYY',
                    monthYearA11yLabel: 'DD.MM.YYYY',
                },
            },
        },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { 
            provide: MAT_RIPPLE_GLOBAL_OPTIONS,
            useValue: { disabled: true }
        }
    ]
})
export class CoreModule {
    constructor(public injector: Injector) {
        ObjectMaker.angularInjector = injector;
    }
}
