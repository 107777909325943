<ng-container *ngIf="itemsPerPageOptions.length > 1">
    <div class="custom-pagination-controls__items-per-page">
        <span class="custom-pagination-controls__items-per-page-label">Einträge pro Seite</span>
        <a class="custom-pagination-controls__items-per-page-link"
        *ngFor="let option of itemsPerPageOptions"
        [class.custom-pagination-controls__items-per-page-link--active]="instance?.itemsPerPage === option"
        href="#" (click)="onItemsPerPageClicked($event, option)">{{option}}</a>
    </div>
</ng-container>

<div class="custom-pagination-controls__pagination pagination">

    <button class="pagination__button pagination__button--previous" (click)="onPreviousPage()">
        &lsaquo;
    </button>

    <span class="pagination__label">
        <ng-container *ngIf="pageEnd > 1">{{pageStart}} - </ng-container>
        {{pageEnd}} von {{instance?.totalItems}}
    </span>

    <button class="pagination__button pagination__button--next" (click)="onNextPage()">
        &rsaquo;
    </button>

</div>
