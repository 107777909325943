<div [ngClass]="{'read-mode' : options.readMode, 'max-height' : options.maxHeight}">
    <app-markdown
        [text]="text"
        [readMode]="options.readMode"
        (action)="onActionClicked($event)">
    </app-markdown>
    <div *ngIf="options.readMode" class="read-mode-action">
        <button
            type="button"
            class="btn btn-link py-0"
            (click)="onReadMode()">{{ 'widgets.readMode' | transloco }}</button>
    </div>
</div>
