import { TargetMarketSuggestionEntry } from '../models/target-market-criteria-structure.model';

export function transformTargetMarketSubset(data: any): TargetMarketSuggestionEntry[] {
    return data.map((entry) => {
        return {
            id: entry[0],
            label: entry[1],
        };
    });
}
