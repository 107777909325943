import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replaceLineBreaks',
    standalone: true
})
export class ReplaceLineBreaks implements PipeTransform {
    public transform(value: string): string {
        if (!value) {
            return '';
        }

        return value.replace(/\n/g, '<br/>');
    }
}
