import { Component, Input } from '@angular/core';
import { ButtonBase, ButtonSize, buttonStyle } from './button-base';

type NeutralAppearance = 'outline' | 'text';

@Component({
    selector: 'button[chroma-button-neutral], a[chroma-button-neutral]',
    templateUrl: './button.html',
    standalone: true
})
export class ButtonNeutralComponent extends ButtonBase {

    constructor() {
        super('neutral');
    }

    @Input() set appearance(value: NeutralAppearance) {
        this._appearance = value;
        this.hostClasses = buttonStyle({ [this._appearance]: 'neutral', size: this._size });
    }

    @Input() set size(value: ButtonSize) {
        this._size = value;
        this.hostClasses = buttonStyle({ [this._appearance]: 'neutral', size: this._size });
    }
    
}
