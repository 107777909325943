import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SingleValueFilterControl } from '../filter-control';
import { FormControlConfig, FormControlSelectOption } from '../../../core/models/form.model';
import { isEqual, get, set } from 'lodash';
import { AbstractControl, ValidationErrors, FormsModule } from '@angular/forms';
import { Product } from '../../../routes/shared/product-designer-shared/models/product-designer.model';
import { getControlValue } from '../../../routes/shared/product-designer-shared/helpers/product-designer.helpers';
import * as moment from 'moment';
import { holidayDateFilter } from '../../../core/helpers/holidays.helper';
import { calculateRealDate } from '../../helpers/date.helper';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { TranslocoModule } from '@ngneat/transloco';
import { IconComponent } from '../icon/icon.component';
import { DateInputComponent } from '../date-input/date-input.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-filter-maturity-date-quick',
    templateUrl: './filter-maturity-date-quick.component.html',
    styleUrls: ['./filter-maturity-date-quick.component.scss'],
    standalone: true,
    imports: [FormsModule, NgFor, NgIf, DateInputComponent, IconComponent, TranslocoModule, TranslocoLocaleModule]
})
export class FilterMaturityDateQuickComponent implements OnInit, SingleValueFilterControl<any>, OnChanges {

    @Input() public config: FormControlConfig<MaturityDateQuickOptions>;
    @Input() public values: any;
    @Input() public disabled = false;
    @Input() public fullProductData: Product;

    public currentValue: any = {};

    public onChange: () => void;
    public onTouched: () => void;

    public holidayDateFilter = holidayDateFilter;

    constructor() { }

    public ngOnInit() {
    }

    public writeValue(value: any): void {
        if (!isEqual(this.currentValue, value)) {
            this.currentValue = (value) ? value : {};

            this._updateAbsoluteDate();
        }
    }

    protected _isValidValue(value: any) {
        if (!this.monthValue && !this.dateValue) return false;
        return true;

    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this.currentValue);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return (this._isValidValue(this.currentValue)) ? null : {};
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.values) {
            this.currentValue = (this._isValidValue(this.currentValue)) ? this.currentValue : {
                ...(this.currentValue || {}),
                [this.config.options.monthPath]: (this.dropdownValues && this.dropdownValues[0] && this.dropdownValues[0].id) || null,
                [this.config.options.pathToDatePickerEnabled]: false,
            };

            this._updateAbsoluteDate();

            if (this.onChange) {
                this.onChange();
            }
        }
    }

    get relativeDate() {
        return moment(getControlValue(
            this.fullProductData || {},
            this.currentValue || {},
            this.config.options.pathToRelativeDate
        ) || new Date());
    }

    get monthValue() {
        return get(this.currentValue, this.config.options.monthPath);
    }

    get dateValue() {
        return get(this.currentValue, this.config.options.datePath);
    }

    get dropdownValues(): FormControlSelectOption[] {
        return this.values || [];
    }

    public onDateChanged(value: any) {
        this.isDatePickerEnabled = true;
        set(this.currentValue, this.config.options.datePath, value);

        if (this.onChange) {
            this.onChange();
        }
    }

    public onClickEditDate(event: MouseEvent) {
        event.preventDefault();

        this.isDatePickerEnabled = true;

        if (this.onChange) {
            this.onChange();
        }
    }

    public onChangeMonth(value: any) {
        if (value === 'CUSTOM') {
            set(this.currentValue, this.config.options.monthPath, null);
            this.isDatePickerEnabled = true;
        } else {
            set(this.currentValue, this.config.options.monthPath, value);
            this.isDatePickerEnabled = false;
            this._updateAbsoluteDate();
        }


        if (this.onChange) {
            this.onChange();
        }
    }

    get isDatePickerEnabled() {
        return get(this.currentValue, this.config.options.pathToDatePickerEnabled, false);
    }

    set isDatePickerEnabled(value: boolean) {
        set(this.currentValue, this.config.options.pathToDatePickerEnabled, value);
    }

    public _updateAbsoluteDate() {
        if (!this.isDatePickerEnabled) {
            const newDate = calculateRealDate(this.relativeDate, this.monthValue);

            if (newDate) {
                set(this.currentValue, this.config.options.datePath, newDate.format());
            }
        }
    }
}

type ParsedMonthValue = {
    type: 'RELATIVE' | 'ABSOLUTE',
    value?: number,
    year?: number,
    month?: number
};

export interface MaturityDateQuickOptions {
    monthPath: string;
    datePath: string;
    pathToRelativeDate: string;
    pathToDatePickerEnabled: string;
}
