import { Component, HostBinding, Input } from '@angular/core';
import { range } from 'lodash';
import { NgSwitch, NgSwitchCase, NgFor } from '@angular/common';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
    standalone: true,
    imports: [NgSwitch, NgSwitchCase, NgFor]
})
export class SpinnerComponent {

    @HostBinding('class.spinner') public spinnerClass = true;

    @HostBinding('class.spinner--inverse')
    @Input() public inverse: boolean;
    @Input() public type: 'boxes' | 'small-boxes' | 'text' | 'circle-box'  = 'boxes';

    constructor() {
    }

    public range(end: number): number[] {
        return range(end);
    }

}
