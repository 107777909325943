import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TopTradesItem } from './entity-widget-top-trades.component';
import { TruncatePipe } from '../../../pipes/truncate.pipe';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { NgIf, NgFor } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-entity-widget-top-trades-item',
    template: `
        <div class="entity-widget-top-trades__list__item" [routerLink]="['/app', {'outlets': {'overlay': ['product-details', trade?.dq || '']}}]">
            <div class="entity-widget-top-trades__list__item__content">
                <a class="entity-widget-top-trades__list__item__name" href="#" [routerLink]="['/app', {'outlets': {'overlay': ['product-details', trade?.dq || '']}}]">{{trade.dericonName | truncate:[70] }}</a>
                <span class="entity-widget-top-trades__list__item__meta" *ngIf="trade.rows">
                    <span class="d-block" *ngFor="let r of trade.rows">{{r}}</span>
                </span>
                <span class="entity-widget-top-trades__list__item__volume">{{ trade.volume | translocoCurrency:'symbol' }}</span>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [RouterLink, NgIf, NgFor, TranslocoLocaleModule, TruncatePipe]
})
export class EntityWidgetTopTradesItemComponent {
    @Input()
    public trade: TopTradesItem;
}
