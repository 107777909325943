import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({
    name: 'dateNoOffset',
    pure: true,
    standalone: true
})
export class DateNoOffsetPipe extends DatePipe implements PipeTransform {

    transform(value: any, format?: string, timezone?: string, locale?: string): any {
        const newValue = new Date(moment(value).format('YYYY-MM-DDTHH:mm:ss'));
        return super.transform(newValue, format, timezone, locale);
    }

}
