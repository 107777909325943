<div class="form-group row" [class.has-danger]="!disabled && !input.valid">
    <div class="col-12 d-flex">
        <app-date-input
            #input="ngModel"
            [ngModel]="dateValue"
            [disabled]="disabled"
            [dateFilter]="dateFilter.bind(this)"
            [format]="dateFormat"
            [disableChangeEventOnInit]="true"
            (ngModelChange)="onDateChange($event)"
        ></app-date-input>

        <app-time-input
            *ngIf="config.options?.showTimeInput"
            [ngModel]="timeValue"
            (ngModelChange)="onTimeChange($event)"
        ></app-time-input>
    </div>
</div>
