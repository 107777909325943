/**
 * Created by Florian Reifschneider <florian@rocketloop.de> on 7/4//17.
 */

import { Action, combineReducers } from '@ngrx/store';
import { EntitiesState } from '../../../../core/store/state/entities.state';
import { StringMappingListState } from '../../../../core/store/state/mapping.state';
import { Entity } from '../../../../shared/models/entities.model';
import { TradeStatistics } from '../../models/trades.model';
import { QuerySetActionTypes } from '../actions/query-set.actions';
import { TradeActionTypes, TradesForQuerySetLoadedAction, TradesStatisticsLoadedAction } from '../actions/trade.actions';
import { TradeState } from '../state/trades.state';

export const initialState: TradeState = {
    entities: {},
    loadingTrades: false,
    querySetMapping: {},
    statistics: null,
};

/**
 * The reducer responsible for the entities part of the @link{TradeState}
 * @param state
 * @param action
 * @returns {EntitiesState<TradeState>}
 */
export function tradeEntitiesReducer(state: EntitiesState<Entity> = initialState.entities, action: Action): EntitiesState<Entity> {

    switch (action.type) {

        case TradeActionTypes.TRADES_FOR_QUERY_SET_LOADED:
            const tradesForQuerySetLoadedAction = action as TradesForQuerySetLoadedAction;
            return tradesForQuerySetLoadedAction.payload.trades.reduce((trades, trade) => {
                trades[trade.id] = trade;
                return trades;
            }, {...state});

        default:
            return state;
    }
}

/**
 * The reducer responsible for the entities part of the @link{TradeState}
 * @param state
 * @param action
 * @returns {EntitiesState<TradeState>}
 */
export function tradeQuerySetMappingReducer(state: StringMappingListState<string> = initialState.querySetMapping, action: Action): StringMappingListState<string> {

    switch (action.type) {

        case TradeActionTypes.TRADES_FOR_QUERY_SET_LOADED:
            const tradesForQuerySetLoadedAction = action as TradesForQuerySetLoadedAction;
            const loadedTrades = tradesForQuerySetLoadedAction.payload.trades.map((trade) => trade.id);
            const newMapping = (state[tradesForQuerySetLoadedAction.payload.querySetId]) ? [
                ...state[tradesForQuerySetLoadedAction.payload.querySetId],
                ...loadedTrades,
            ] : loadedTrades;
            return {
                ...state,
                [tradesForQuerySetLoadedAction.payload.querySetId]: newMapping,
            };

        default:
            return state;
    }
}

/**
 * The reducer responsible for the entities part of the @link{TradeState}
 * @param state
 * @param action
 * @returns {EntitiesState<TradeState>}
 */
export function tradeStatisticsReducer(state: TradeStatistics = initialState.statistics, action: Action): TradeStatistics {

    switch (action.type) {

        case TradeActionTypes.TRADES_STATISTICS_LOADED:
            const tradesStatisticsLoadedAction = action as TradesStatisticsLoadedAction;
            return tradesStatisticsLoadedAction.payload.statistics;

        case QuerySetActionTypes.QUERY_SET_CREATED:
            return null;

        default:
            return state;
    }
}

export function loadingTradesReducer(
    state: boolean = initialState.loadingTrades,
    action: Action,
): boolean {
    switch (action.type) {
        case TradeActionTypes.LOAD_TRADES_FOR_QUERY_SET:
            return true;

        case TradeActionTypes.TRADES_FOR_QUERY_SET_LOADED:
        case TradeActionTypes.LOAD_TRADES_FOR_QUERY_SET_FAILED:
            return false;

        default:
            return state;
    }
}

/**
 * The reducer responsible for the @link{TradeState}
 * @type {ActionReducer<TradeState>}
 */
export const tradesModuleTradeReducer = combineReducers({
    entities: tradeEntitiesReducer,
    querySetMapping: tradeQuerySetMappingReducer,
    statistics: tradeStatisticsReducer,
    loadingTrades: loadingTradesReducer,
});
