<div [formGroup]="form" class="form-group row">
    <div class="col double-dropdown-input__label">
        {{ config?.options?.label }}
        <div *ngIf="config?.options?.helpText" class="double-dropdown-input__info" [matTooltip]="config?.options?.helpText"></div>
    </div>
    <div class="operator-selection">
        <select class="form-control" [formControlName]="config?.options?.paths?.operator">
            <option [value]="null" disabled style="display: none;">-</option>
            <option *ngFor="let value of operatorValues" [value]="value.id">{{value.label}}</option>
        </select>
    </div>
    <div class="value-selection">
        <div class="value-input" [class.has-danger]="!disabled && !form.get(config?.options?.paths?.input)?.valid">
            <app-number-input class="form-control" [formControlName]="config?.options?.paths?.input"></app-number-input>
        </div>
        <div class="value-description">
            <span *ngIf="config?.options?.unit" class="double-dropdown-input__unit">
                {{ config?.options?.unit }}
            </span>
            <span *ngIf="config?.options?.sampleInput" class="double-dropdown-input__sample">
                {{ config?.options?.sampleInput }}
            </span>
        </div>
    </div>
    <div class="horizon-selection" *ngIf="config?.options?.displaySecondaryDropdown && horizonValues">
        <select class="form-control" [formControlName]="config?.options?.paths?.horizon" [class.hide]="horizonValues.length === 1">
            <option *ngFor="let value of horizonValues" [value]="value.id">{{value.label}}</option>
        </select>

        <span class="horizon-selection__text" [class.hide]="horizonValues.length > 1" *ngIf="horizonValues.length">
            {{horizonValues[0].label}}
        </span>
    </div>
</div>
