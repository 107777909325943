import { Action } from '@ngrx/store';
import {
    AddManyWatchlistProductsToSelectionAction,
    AddWatchlistProductToSelectionAction, RemoveAllWatchlistProductsFromSelectionAction,
    RemoveManyWatchlistProductsFromSelectionAction,
    RemoveWatchlistProductFromSelectionAction,
    WatchlistDetailsActionTypes,
} from '../actions/watchlist-details.actions';
import { WatchlistDetailsMetaState, WatchlistProductSelection } from '../state/watchlist-details.state';

import { find, reject, remove } from 'lodash';

export const initialState: WatchlistDetailsMetaState = {
    selections: [],
};

export function watchlistDetailsMetaReducer(
    state: WatchlistDetailsMetaState = initialState,
    action: Action,
): WatchlistDetailsMetaState {

    switch (action.type) {

        case WatchlistDetailsActionTypes.ADD_WATCHLIST_PRODUCT_TO_SELECTION:
            const addWatchlistProductsToSelectionAction = action as AddWatchlistProductToSelectionAction;

            if (!find(state.selections, addWatchlistProductsToSelectionAction.payload)) {
                return {
                    ...state,
                    selections: [
                        ...state.selections,
                        addWatchlistProductsToSelectionAction.payload,
                    ],
                };
            } else {
                return state;
            }

        case WatchlistDetailsActionTypes.REMOVE_WATCHLIST_PRODUCT_FROM_SELECTION:
            const removeWatchlistProductFromSelectionAction = action as RemoveWatchlistProductFromSelectionAction;

            if (find(state.selections, removeWatchlistProductFromSelectionAction.payload)) {
                const removeSelection = reject<WatchlistProductSelection>(
                    state.selections,
                    removeWatchlistProductFromSelectionAction.payload,
                );

                return {
                    ...state,
                    selections: removeSelection,
                };
            } else {
                return state;
            }

        case WatchlistDetailsActionTypes.ADD_MANY_WATCHLIST_PRODUCTS_TO_SELECTION:
            const addAllWatchlistProductsToSelectionAction = action as AddManyWatchlistProductsToSelectionAction;

            const addAllSelections = [
                ...state.selections,
            ];

            addAllWatchlistProductsToSelectionAction.payload.productIds.forEach((productId) => {
                const selection = {
                    watchlistId: addAllWatchlistProductsToSelectionAction.payload.watchlistId,
                    entityType: addAllWatchlistProductsToSelectionAction.payload.entityType,
                    productId,
                };

                if (!find(addAllSelections, selection)) {
                    addAllSelections.push(selection);
                }
            });

            return {
                ...state,
                selections: addAllSelections,
            };

        case WatchlistDetailsActionTypes.REMOVE_MANY_WATCHLIST_PRODUCTS_FROM_SELECTION:
            const removeManyWatchlistProductsFromSelectionAction = action as RemoveManyWatchlistProductsFromSelectionAction;

            const productIds = removeManyWatchlistProductsFromSelectionAction.payload.productIds;
            if (productIds && Array.isArray(productIds) && productIds.length) {
                const allSelections = [...state.selections];

                removeManyWatchlistProductsFromSelectionAction.payload.productIds.forEach((productId) => {
                    const selection = {
                        watchlistId: removeManyWatchlistProductsFromSelectionAction.payload.watchlistId,
                        entityType: removeManyWatchlistProductsFromSelectionAction.payload.entityType,
                        productId,
                    };

                    remove(allSelections, selection);
                });

                return {
                    ...state,
                    selections: allSelections,
                };
            } else {
                delete removeManyWatchlistProductsFromSelectionAction.payload.productIds;
                const removeAllSelections = reject<WatchlistProductSelection>(
                    state.selections,
                    removeManyWatchlistProductsFromSelectionAction.payload,
                );

                return {
                    ...state,
                    selections: removeAllSelections,
                };
            }

        case WatchlistDetailsActionTypes.REMOVE_ALL_WATCHLIST_PRODUCTS_FROM_SELECTION:
            const removeAllWatchlistProductsFromSelectionAction = action as RemoveAllWatchlistProductsFromSelectionAction;

            return {
                ...state,
                selections: reject(
                    state.selections, [
                        'watchlistId',
                        removeAllWatchlistProductsFromSelectionAction.payload.watchlistId,
                    ],
                ),
            };
        default:
            return state;
    }
}
