import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { DynamicChartComponent } from '../../../components/dynamic-chart/dynamic-chart.component';
import { NgIf } from '@angular/common';

/**
 * Component that display the chart given be the widget config and wiget data
 */
@Component({
    selector: 'app-entity-widget-graph',
    templateUrl: './entity-widget-graph.component.html',
    styleUrls: ['./entity-widget-graph.component.scss'],
    standalone: true,
    imports: [NgIf, DynamicChartComponent]
})
export class EntityWidgetGraphComponent implements OnInit, OnChanges, EntityWidgetContentComponent {

    @Input() public entityId: string;
    @Input() public width: number;

    @Input() public options: any;
    @Input() public data: any;

    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();

    constructor() {
    }

    public ngOnChanges(changes: SimpleChanges): void {

    }

    public ngOnInit() {
    }

    get hasNoChartData() {
        return !this.data || !this.data.length;
    }
}
