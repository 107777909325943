/**
 * Created by Alex Klein <alex@rocketloop.de> on 12/11/2017.
 */

import { Action } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { type } from '../../../../core/store/util';
import { Entity } from '../../../../shared/models/entities.model';
import { ReportData } from '../../models/assets.model';

export const AssetActionTypes = {
    LOAD_ASSETS_FOR_QUERY_SET: type('ASSETS:LOAD_ASSETS_FOR_QUERY_SET'),
    ASSETS_FOR_QUERY_SET_LOADED: type('ASSETS:ASSETS_FOR_QUERY_SET_LOADED'),
    LOAD_ASSETS_FOR_QUERY_SET_FAILED: type('ASSETS:LOAD_ASSETS_FOR_QUERY_SET_FAILED'),

    LOAD_REPORT_DATA: type('ASSETS:LOAD_REPORT_DATA'),
    REPORT_DATA_LOADED: type('ASSETS:REPORT_DATA_LOADED'),
    LOAD_REPORT_DATA_FAILED: type('ASSETS:LOAD_REPORT_DATA_FAILED'),

    ADD_ASSET_TO_SELECTION: type('ASSETS:ADD_ASSET_TO_SELECTION'),
    REMOVE_ASSET_FROM_SELECTION: type('ASSETS:REMOVE_ASSET_FROM_SELECTION'),

    ADD_ALL_ASSETS_TO_SELECTION: type('ASSETS:ADD_ALL_ASSETS_TO_SELECTION'),
    REMOVE_ALL_ASSETS_FROM_SELECTION: type('ASSETS:REMOVE_ALL_ASSETS_FROM_SELECTION'),
};

/** LoadAssetsForQuerySetAction **/

export interface LoadAssetsForQuerySetAction extends Action {
    payload: {
        productTypeId: string;
        querySetId: string;
        limit?: number;
        offset?: number;
    };
}

export function loadAssetsForQuerySet(productTypeId: string, querySetId: string, limit?: number,
                                      offset?: number): LoadAssetsForQuerySetAction {
    return {
        type: AssetActionTypes.LOAD_ASSETS_FOR_QUERY_SET,
        payload: {
            productTypeId,
            querySetId,
            limit,
            offset,
        },
    };
}

/** AssetsForQuerySetLoadedAction **/

export interface AssetsForQuerySetLoadedAction extends Action {
    payload: {
        querySetId: string;
        assets: Entity[];
    };
}

export function assetsForQuerySetLoaded(querySetId: string, assets: Entity[]): AssetsForQuerySetLoadedAction {
    return {
        type: AssetActionTypes.ASSETS_FOR_QUERY_SET_LOADED,
        payload: {
            querySetId,
            assets,
        },
    };
}

/** LoadAssetsForQuerySetFailedAction **/

export interface LoadAssetsForQuerySetFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadAssetsForQuerySetFailed(error: AppError): LoadAssetsForQuerySetFailedAction {
    return {
        type: AssetActionTypes.LOAD_ASSETS_FOR_QUERY_SET_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadReportDataAction **/

export interface LoadReportDataAction extends Action {
    payload: {
        id: string,
    };
}

export function loadReportData(id: string): LoadReportDataAction {
    return {
        type: AssetActionTypes.LOAD_REPORT_DATA,
        payload: {
            id,
        },
    };
}

/** ReportDataLoadedAction **/

export interface ReportDataLoadedAction extends Action {
    payload: {
        data: ReportData,
    };
}

export function reportDataLoaded(data: ReportData): ReportDataLoadedAction {
    return {
        type: AssetActionTypes.REPORT_DATA_LOADED,
        payload: {
            data,
        },
    };
}

/** LoadReportDataFailedAction **/

export interface LoadReportDataFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadReportDataFailed(error: AppError): LoadReportDataFailedAction {
    return {
        type: AssetActionTypes.LOAD_REPORT_DATA_FAILED,
        payload: {
            error,
        },
    };
}

/** AddAssetToSelection **/

export interface AddAssetToSelectionAction extends Action {
    payload: {
        id: string;
    };
}

export function addAssetToSelection(id: string): AddAssetToSelectionAction {
    return {
        type: AssetActionTypes.ADD_ASSET_TO_SELECTION,
        payload: {
            id,
        },
    };
}

/** RemoveAssetFromSelection **/

export interface RemoveAssetFromSelectionAction extends Action {
    payload: {
        id: string;
    };
}

export function removeAssetFromSelection(id: string): AddAssetToSelectionAction {
    return {
        type: AssetActionTypes.REMOVE_ASSET_FROM_SELECTION,
        payload: {
            id,
        },
    };
}

/** AddAllAssetsToSelection **/

export interface AddAllAssetsToSelectionAction extends Action {
    payload: {
        ids: string[];
    };
}

export function addAllAssetsToSelection(ids: string[]): AddAllAssetsToSelectionAction {
    return {
        type: AssetActionTypes.ADD_ALL_ASSETS_TO_SELECTION,
        payload: {
            ids,
        },
    };
}

/** RemoveAllAssetsFromSelection **/

export interface RemoveAllAssetsFromSelectionAction extends Action {
    payload?: void;
}

export function removeAllAssetsFromSelection(): RemoveAllAssetsFromSelectionAction {
    return {
        type: AssetActionTypes.REMOVE_ALL_ASSETS_FROM_SELECTION,
    };
}
