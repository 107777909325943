<div class="tw-border-b tw-border-neutral-200 dark:tw-border-transparent">
    <div class="3xl:tw-container tw-w-full tw-mx-auto tw-px-8">
        <app-top-bar></app-top-bar>
    </div>
</div>
<div class="tw-bg-primary-bg dark:tw-bg-white">
    <div class="3xl:tw-container tw-w-full tw-mx-auto tw-px-8 tw-pb-9">
        <app-issue-message></app-issue-message>
        <router-outlet name="slide-down"></router-outlet>
        <app-ad-for-default-layout *ngIf="(overlayOpen$ | async) === false"></app-ad-for-default-layout>
        <app-inline-menu *ngIf="inlineMenuConfig$ | async as menuConfig" [menu]="menuConfig"></app-inline-menu>
        <router-outlet></router-outlet>
    </div>
</div>
<app-main-menu [menu]="menu$ | async" [@menuState]="'visible'" *ngIf="menuVisible$ | async"></app-main-menu>
<router-outlet name="overlay"></router-outlet>
<app-scroll-top-button></app-scroll-top-button>
