/**
 * Created by Alex Klein <alex@rocketloop.de> on 10/17/17.
 */

import { Action } from '@ngrx/store';
import { AppError } from '../../../../core/errors/base.errors';
import { FormValues } from '../../../../core/models/form.model';
import { type } from '../../../../core/store/util';
import {
    BWListAction,
    BWListSpecificStructure,
    BWListStructure,
    BWListStructureSection,
    BWListValues,
    BWLListList,
} from '../../models/bwlist.model';

export const BWListActionTypes = {
    LOAD_BWLIST_STRUCTURE: type('BWLIST:LOAD_BWLIST_STRUCTURE'),
    LOAD_BWLIST_STRUCTURE_SUCCEEDED: type('BWLIST:LOAD_BWLIST_STRUCTURE_SUCCEEDED'),
    LOAD_BWLIST_STRUCTURE_FAILED: type('BWLIST:LOAD_BWLIST_STRUCTURE_FAILED'),

    LOAD_SPECIFIC_STRUCTURE: type('BWLIST:LOAD_SPECIFIC_STRUCTURE'),
    LOAD_SPECIFIC_STRUCTURE_SUCCEEDED: type('BWLIST:LOAD_SPECIFIC_STRUCTURE_SUCCEEDED'),
    LOAD_SPECIFIC_STRUCTURE_FAILED: type('BWLIST:LOAD_SPECIFIC_STRUCTURE_FAILED'),

    LOAD_SPECIFIC_VALUES: type('BWLIST:LOAD_SPECIFIC_VALUES'),
    LOAD_SPECIFIC_VALUES_SUCCEEDED: type('BWLIST:LOAD_SPECIFIC_VALUES_SUCCEEDED'),
    LOAD_SPECIFIC_VALUES_FAILED: type('BWLIST:LOAD_SPECIFIC_VALUES_FAILED'),

    LOAD_SPECIFIC_SELECTED_LIST: type('BWLIST:LOAD_SPECIFIC_SELECTED_LIST'),
    LOAD_SPECIFIC_SELECTED_LIST_SUCCEEDED: type('BWLIST:LOAD_SPECIFIC_SELECTED_LIST_SUCCEEDED'),
    LOAD_SPECIFIC_SELECTED_LIST_FAILED: type('BWLIST:LOAD_SPECIFIC_SELECTED_LIST_FAILED'),

    UPDATE_SPECIFIC_LIST: type('BWLIST:UPDATE_SPECIFIC_LIST'),
    UPDATE_SPECIFIC_BWLIST_LIST_SUCCEEDED: type('BWLIST:UPDATE_SPECIFIC_BWLIST_LIST_SUCCEEDED'),
    UPDATE_SPECIFIC_BWLIST_LIST_FAILED: type('BWLIST:UPDATE_SPECIFIC_BWLIST_LIST_FAILED'),

    ADD_PRODUCT_TO_SPECIFIC_BWLIST_LIST: type('BWLIST:ADD_PRODUCT_TO_SPECIFIC_BWLIST_LIST'),
    REMOVE_PRODUCT_TO_SPECIFIC_BWLIST_LIST: type('BWLIST:REMOVE_PRODUCT_TO_SPECIFIC_BWLIST_LIST'),

    LOAD_SPECIFIC_SELECTED_FILTER: type('BWLIST:LOAD_SPECIFIC_SELECTED_FILTER'),
    LOAD_SPECIFIC_SELECTED_FILTER_SUCCEEDED: type('BWLIST:LOAD_SPECIFIC_SELECTED_FILTER_SUCCEEDED'),
    LOAD_SPECIFIC_SELECTED_FILTER_FAILED: type('BWLIST:LOAD_SPECIFIC_SELECTED_FILTER_FAILED'),

    UPDATE_SPECIFIC_FILTER: type('BWLIST:UPDATE_SPECIFIC_FILTER'),
    UPDATE_SPECIFIC_FILTER_SUCCEEDED: type('BWLIST:UPDATE_SPECIFIC_FILTER_SUCCEEDED'),
    UPDATE_SPECIFIC_FILTER_FAILED: type('BWLIST:UPDATE_SPECIFIC_FILTER_FAILED'),
};

/** LoadBWListStructureAction **/

export interface LoadBWListStructureAction extends Action {
    payload: {
        type: string;
    };
}

export function loadBWListStructure(t: string): LoadBWListStructureAction {
    return {
        type: BWListActionTypes.LOAD_BWLIST_STRUCTURE,
        payload: {
            type: t,
        },
    };
}

/** LoadBWListStructureSucceededAction **/

export interface LoadBWListStructureSucceededAction extends Action {
    payload: {
        sections: BWListStructureSection[];
        actions: BWListAction[]
    };
}

export function loadBWListStructureSucceeded(structure: BWListStructure): LoadBWListStructureSucceededAction {
    return {
        type: BWListActionTypes.LOAD_BWLIST_STRUCTURE_SUCCEEDED,
        payload: {
            actions: structure.actions,
            sections: structure.groups,
        },
    };
}

/** LoadBWListStructureFailedAction **/

export interface LoadBWListStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadBWListStructureFailed(error: AppError): LoadBWListStructureFailedAction {
    return {
        type: BWListActionTypes.LOAD_BWLIST_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadSpecificStructureAction **/

export interface LoadSpecificStructureAction extends Action {
    payload: {
        type: string;
        topic: string;
    };
}

export function loadSpecificStructure(t: string, topic: string): LoadSpecificStructureAction {
    return {
        type: BWListActionTypes.LOAD_SPECIFIC_STRUCTURE,
        payload: {
            type: t,
            topic,
        },
    };
}

/** LoadSpecificStructureSucceededAction **/

export interface LoadSpecificStructureSucceededAction extends Action {
    payload: {
        topic: string;
        structure: BWListSpecificStructure
    };
}

export function loadSpecificStructureSucceeded(
    topic: string,
    structure: BWListSpecificStructure,
): LoadSpecificStructureSucceededAction {
    return {
        type: BWListActionTypes.LOAD_SPECIFIC_STRUCTURE_SUCCEEDED,
        payload: {
            topic,
            structure,
        },
    };
}

/** LoadSpecificStructureFailedAction **/

export interface LoadSpecificStructureFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadSpecificStructureFailed(error: AppError): LoadSpecificStructureFailedAction {
    return {
        type: BWListActionTypes.LOAD_SPECIFIC_STRUCTURE_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadSpecificValuesAction **/

export interface LoadSpecificValuesAction extends Action {
    payload: {
        type: string;
        topic: string;
    };
}

export function loadSpecificValues(t: string, topic: string): LoadSpecificValuesAction {
    return {
        type: BWListActionTypes.LOAD_SPECIFIC_VALUES,
        payload: {
            type: t,
            topic,
        },
    };
}

/** LoadSpecificValuesSucceededAction **/

export interface LoadSpecificValuesSucceededAction extends Action {
    payload: {
        topic: string;
        values: BWListValues;
    };
}

export function loadSpecificBWListFilterValuesSucceeded(topic: string,
                                                        values: BWListValues): LoadSpecificValuesSucceededAction {
    return {
        type: BWListActionTypes.LOAD_SPECIFIC_VALUES_SUCCEEDED,
        payload: {
            topic,
            values,
        },
    };
}

/** LoadSpecificValuesFailedAction **/

export interface LoadSpecificValuesFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadSpecificValuesFailed(error: AppError): LoadSpecificValuesFailedAction {
    return {
        type: BWListActionTypes.LOAD_SPECIFIC_VALUES_FAILED,
        payload: {
            error,
        },
    };
}

/** LoadSpecificSelectedListAction **/

export interface LoadSpecificSelectedListAction extends Action {
    payload: {
        type: string;
        topic: string;
    };
}

export function loadSpecificSelectedList(t: string, topic: string): LoadSpecificSelectedListAction {
    return {
        type: BWListActionTypes.LOAD_SPECIFIC_SELECTED_LIST,
        payload: {
            type: t,
            topic,
        },
    };
}

/** LoadSpecificSelectedListSucceededAction **/

export interface LoadSpecificSelectedListSucceededAction extends Action {
    payload: {
        topic: string;
        list: BWLListList;
    };
}

export function loadSpecificSelectedListSucceeded(topic: string,
                                                  list: BWLListList): LoadSpecificSelectedListSucceededAction {
    return {
        type: BWListActionTypes.LOAD_SPECIFIC_SELECTED_LIST_SUCCEEDED,
        payload: {
            topic,
            list,
        },
    };
}

/** LoadSpecificSelectedListFailedAction **/

export interface LoadSpecificSelectedListFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadSpecificSelectedListFailed(error: AppError): LoadSpecificSelectedListFailedAction {
    return {
        type: BWListActionTypes.LOAD_SPECIFIC_SELECTED_LIST_FAILED,
        payload: {
            error,
        },
    };
}

/** UpdateSpecificListAction **/

export interface UpdateSpecificListAction extends Action {
    payload: {
        type: string;
        topic: string;
        ids: string[];
    };
}

export function updateSpecificBWListList(t: string, topic: string, ids: string[]): UpdateSpecificListAction {
    return {
        type: BWListActionTypes.UPDATE_SPECIFIC_LIST,
        payload: {
            type: t,
            topic,
            ids,
        },
    };
}

/** UpdateSpecificBWListListSucceededAction **/

export interface UpdateSpecificBWListListSucceededAction extends Action {
    payload: {
        topic: string;
        list: BWLListList;
    };
}

export function updateSpecificBWListListSucceeded(topic: string,
                                                  list: BWLListList): UpdateSpecificBWListListSucceededAction {
    return {
        type: BWListActionTypes.UPDATE_SPECIFIC_BWLIST_LIST_SUCCEEDED,
        payload: {
            topic,
            list,
        },
    };
}

/** UpdateSpecificBWListListFailedAction **/

export interface UpdateSpecificBWListListFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function updateSpecificBWListListFailed(error: AppError): UpdateSpecificBWListListFailedAction {
    return {
        type: BWListActionTypes.UPDATE_SPECIFIC_BWLIST_LIST_FAILED,
        payload: {
            error,
        },
    };
}

/** AddProductToSpecificBWListListAction **/

export interface AddProductToSpecificBWListListAction extends Action {
    payload: {
        topic: string;
        productId: string;
    };
}

export function addProductToSpecificBWListList(topic: string, productId: string): AddProductToSpecificBWListListAction {
    return {
        type: BWListActionTypes.ADD_PRODUCT_TO_SPECIFIC_BWLIST_LIST,
        payload: {
            topic,
            productId,
        },
    };
}

/** RemoveProductToSpecificBWListListAction **/

export interface RemoveProductFromSpecificBWListListAction extends Action {
    payload: {
        topic: string;
        productId: string;
    };
}

export function removeProductFromSpecificBWListList(topic: string, productId: string): RemoveProductFromSpecificBWListListAction {
    return {
        type: BWListActionTypes.REMOVE_PRODUCT_TO_SPECIFIC_BWLIST_LIST,
        payload: {
            topic,
            productId,
        },
    };
}

/** LoadSpecificSelectedFilterAction **/

export interface LoadSpecificSelectedFilterAction extends Action {
    payload: {
        type: string;
        topic: string;
    };
}

export function loadSpecificSelectedFilter(t: string, topic: string): LoadSpecificSelectedFilterAction {
    return {
        type: BWListActionTypes.LOAD_SPECIFIC_SELECTED_FILTER,
        payload: {
            type: t,
            topic,
        },
    };
}

/** LoadSpecificSelectedFilterSucceededAction **/

export interface LoadSpecificSelectedFilterSucceededAction extends Action {
    payload: {
        topic: string;
        value: FormValues;
    };
}

export function loadSpecificSelectedFilterSucceeded(topic: string, value: FormValues): LoadSpecificSelectedFilterSucceededAction {
    return {
        type: BWListActionTypes.LOAD_SPECIFIC_SELECTED_FILTER_SUCCEEDED,
        payload: {
            topic,
            value,
        },
    };
}

/** LoadSpecificSelectedFilterFailedAction **/

export interface LoadSpecificSelectedFilterFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function loadSpecificSelectedFilterFailed(error: AppError): LoadSpecificSelectedFilterFailedAction {
    return {
        type: BWListActionTypes.LOAD_SPECIFIC_SELECTED_FILTER_FAILED,
        payload: {
            error,
        },
    };
}

/** UpdateSpecificFilterAction **/

export interface UpdateSpecificFilterAction extends Action {
    payload: {
        type: string;
        topic: string;
        value: FormValues;
    };
}

export function updateSpecificFilter(t: string, topic: string, value: FormValues): UpdateSpecificFilterAction {
    return {
        type: BWListActionTypes.UPDATE_SPECIFIC_FILTER,
        payload: {
            type: t,
            topic,
            value,
        },
    };
}

/** UpdateSpecificFilterSucceededAction **/

export interface UpdateSpecificFilterSucceededAction extends Action {
    payload: {
        topic: string;
        value: FormValues;
    };
}

export function updateSpecificFilterSucceeded(topic: string, value: FormValues): UpdateSpecificFilterSucceededAction {
    return {
        type: BWListActionTypes.UPDATE_SPECIFIC_FILTER_SUCCEEDED,
        payload: {
            topic,
            value,
        },
    };
}

/** UpdateSpecificFilterFailedAction **/

export interface UpdateSpecificFilterFailedAction extends Action {
    payload: {
        error: AppError;
    };
}

export function updateSpecificFilterFailed(error: AppError): UpdateSpecificFilterFailedAction {
    return {
        type: BWListActionTypes.UPDATE_SPECIFIC_FILTER_FAILED,
        payload: {
            error,
        },
    };
}
