import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, mergeMap, skipUntil, skipWhile, tap } from 'rxjs/operators';

import { getParamValueQueryString, truthy } from '../../shared/helpers/general.helper';
import { TokenUser, UserRole } from '../api/models/me.model';
import { AppState } from '../store/state/index.state';
import { resetTokens, signOut } from '../store/actions/auth.action';
import { Params } from '@angular/router';

@Injectable()
export class AuthStoreService {
    public jwtHelperService: JwtHelperService;

    constructor(private readonly store: Store<AppState>) {
        this.jwtHelperService = new JwtHelperService();
    }

    public getAccessToken(): Observable<string> {
        return this.store.pipe(
            select((state) => state.auth.accessToken),
        );
    }

    public getRefreshToken(): Observable<string> {
        return this.store.pipe(
            select((state) => state.auth.refreshToken),
        );
    }

    public getAuthUser(): Observable<TokenUser> {
        return this.store.pipe(
            select((state) => state.auth.authUser),
        );
    }

    public getRedirectTokenLoginState(): Observable<any> {
        return this.store.pipe(
            select((state) => state.auth.redirectTokenLoginMode),
        );
    }

    public isAccessTokenStillValid(): Observable<boolean> {
        return this.getAccessToken().pipe(
            map((token) => token && !this.jwtHelperService.isTokenExpired(token)),
        );
    }

    public isRefreshTokenStillValid(queryParams?: Params): Observable<boolean> {
        return this.getRedirectTokenLoginState().pipe(
            skipWhile((state) => state === 'PROGRESS' || queryParams?.hasOwnProperty('redirectToken')),
            mergeMap((state) => {
                return this.getRefreshToken().pipe(
                    map((token) => {
                        return (token && !this.jwtHelperService.isTokenExpired(token));
                    }),
                );
            }),
            tap((isValid) => {
                if(!isValid){
                    this.store.dispatch(resetTokens());
                }
            })
        );
    }

    public getAuthUserRoles(): Observable<UserRole[]> {
        return this.getAuthUser().pipe(
            filter(truthy),
            select((user) => user.roles),
        );
    }
}
