import { Component, Input, OnInit } from '@angular/core';
import { ImageReferenceResolverService } from '../../../core/services/image-reference-resolver.service';
import { ContactPartner } from '../../models/contact-partner.model';
import { TranslocoModule } from '@ngneat/transloco';
import { IconComponent } from '../icon/icon.component';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'app-contact-partners',
    templateUrl: './contact-partners.component.html',
    styleUrls: ['./contact-partners.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass, NgFor, IconComponent, TranslocoModule]
})
export class ContactPartnersComponent implements OnInit {

  @Input() public list: Array<ContactPartner>;
  @Input() public columns: number;

  public grid = 12;

  constructor(private imageResolver: ImageReferenceResolverService) { }

  public ngOnInit(): void {
  }

  public resolveDerifilesImage(ref: string) {
    return this.imageResolver.resolveRemoteImagePath(ref);
  }

}
