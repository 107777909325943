import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Data } from '../../../../core/models/data.model';
import { FormValues } from '../../../../core/models/form.model';
import { EntityWidgetConfig, EntityWidgetOptions } from '../../../models/widgets.model';
import { EntityEvent } from '../../../components/entity-table/entity-table.component';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { EntityWidgetContainerComponent } from '../entity-widget-container/entity-widget-container.component';

@Component({
    selector: 'app-entity-widget-combined',
    templateUrl: './entity-widget-combined.component.html',
    styleUrls: ['./entity-widget-combined.component.scss'],
    standalone: true,
    imports: [EntityWidgetContainerComponent]
})
export class EntityWidgetCombinedComponent implements OnInit, OnChanges, EntityWidgetContentComponent {
    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public options: CompositeEntityOptions;
    @Input() public data: Data;
    @Input() public formValues: FormValues;
    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();
    @Output() public componentEvent: EventEmitter<EntityEvent> = new EventEmitter();

    constructor() {
    }

    public ngOnInit() {

    }

    public ngOnChanges(changes: SimpleChanges): void {

    }

    public onAction(data: { type: string; payload: any }) {
        this.action.next(data);
    }

    public onComponentEvent(event: EntityEvent) {
        this.componentEvent.next(event);
    }
}

interface CompositeEntityOptions extends EntityWidgetOptions {
    widgets: EntityWidgetConfig[];
}
