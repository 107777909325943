/**
 * Created by Alex Klein <alex@rocketloop.de> on 5/5/17.
 */
import { ApiResponse } from '../../../../core/api/models/api.model';
import { WatchlistData, WatchlistProductGroup, WatchlistStructures } from '../models/watchlist.model';
import { ShortListResponse, WatchListDataEntry, WatchListDataResponse } from './models/watchlist.model';

export function transformWatchlistStructures(
    watchlistId: string,
    structuresResponse: ApiResponse<WatchlistStructures>,
): WatchlistProductGroup[] {

    return structuresResponse.data.structures.map((structure) => {
        return {
            ...structure,
            id: watchlistId + '_' + structure.id,
        };
    });
}

export function transformWatchlistTabsStructures(
    watchlistId: string,
    response: ApiResponse<WatchlistStructures>
): WatchlistStructures {
    return {
        ...response.data,
        tabs: response.data.tabs.map((tab) => {
            return {
                ...tab,
                structureInformation: tab.configuration.type === 'OVERVIEW' ? {
                    ...tab.structureInformation,
                    structures: tab.structureInformation.structures.map((structure) => {
                        return {
                            ...structure,
                            id: watchlistId + '_' + structure.id,
                        };
                    })
                } : (tab.structureInformation || {}),
            };
        }),
    };
}

export function transformWatchlistDataResponse(
    watchlistId: string,
    productsResponse: ApiResponse<WatchListDataResponse>,
): WatchlistData {
    const data = {};
    Object.keys(productsResponse.data).map((key) => {
        data[watchlistId + '_' + key] = productsResponse.data[key].map((entry) => transformWatchlistDataEntry(entry));
    });
    return data;
}

export function transformWatchlistDataEntry(data: WatchListDataEntry): WatchListDataEntry {
    const entry = {
        ...data,
        id: data._id,
    };
    delete entry._id;
    return entry;
}

export function transformShortlistResponse(response: ShortListResponse): ShortListResponse {
    const data = response.data;
    Object.keys(data).forEach((k) => {
        Object.keys(data[k]).forEach((l) => {
            data[k][l] = data[k][l].map((entry) => transformWatchlistDataEntry(entry));
        });
    });

    return {
        structure: response.structure,
        data,
    };
}
