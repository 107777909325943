import { Action } from '@ngrx/store';
import { AsyncLinkState } from '../state/download.state';

/**
 * Created by Alex Klein <alex@rocketloop.de> on 9/7/17.
 */

export const initialState: AsyncLinkState = {};

/**
 * The reducer responsible for the @link{AsyncLinkState}
 * @param state
 * @param action
 * @returns {any}
 */
export function asyncLinkReducer(state: AsyncLinkState = initialState, action: Action): AsyncLinkState {
    switch (action.type) {
        default:
            return state;

    }
}
