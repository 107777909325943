import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { marked } from 'marked';
import { getImageFromReference } from '../../core/helpers/image-referece-resolver.helper';
import { ImageReferenceResolverService } from '../../core/services/image-reference-resolver.service';

@Pipe({
    name: 'markdownToHtml',
    standalone: true
})
export class MarkdownToHtmlPipe implements PipeTransform {
    private readonly markedRenderer: marked.Renderer;

    constructor(
        private domSanitizer: DomSanitizer,
        private imageResolver: ImageReferenceResolverService,
    ) {
        this.markedRenderer = new marked.Renderer();

        this.markedRenderer.code = (code: string, language: string, isEscaped: boolean) => {
            switch (language) {
                case '$entity-table':
                    return `<div data-entity-table="${code}"></div>`;
                default:
                    return `<code>${code}</code>`;
            }
        };

        this.markedRenderer.image = (href: string, title: string, text: string) => {
            let path = href.trim();
            let classes = '';

            if (href.charAt(0) === '$') {
                const reference = href.slice(1);

                const resolvedImage = this.imageResolver.getImageReferenceFromAddress(reference);
                const image = getImageFromReference(resolvedImage, 'DEFAULT');

                if (image && image.ref.mode === 'BW') {
                    classes += 'bw grayscale';
                }

                path = image ? image.path : '';
            }

            return `<img class="${classes}" src="${path}" alt="${text || ''}" title="${title || ''}">`;
        };

    }

    public transform(markdown: string): SafeHtml {
        if (markdown == null) {
            return '';
        }

        return this.domSanitizer.bypassSecurityTrustHtml(marked(markdown, {
            renderer: this.markedRenderer,
            mangle: false,
            headerIds: false
        }));
    }

}
