import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { truthy } from '../../shared/helpers/general.helper';

import { Entity, EntityTableConfig } from '../../shared/models/entities.model';
import { FormStructureConfig, FormValues } from '../models/form.model';
import {
    clearModuleData,
    loadFactSheetContext,
    loadListModuleContext,
    loadProductSelection,
    loadSingleModuleContext, loadWatchlistContext, loadWatchlistModuleData,
    saveWatcher,
    updateWatcher,
    saveWatchers,
} from '../store/actions/risk-manager.actions';
import { AppState } from '../store/state/index.state';
import {
    RiskManagerContext,
    RiskManagerWatchlistContext,
    WatchlistRiskManagerData,
    WatchlistRiskManagerModuleData
} from '../models/risk-manager.model';
import { Dictionary } from '@ngrx/entity';

@Injectable()
export class RiskManagerService {
    constructor(private readonly store: Store<AppState>) {
    }

    /** Selectors **/

    /**
     * Get the filter structure for the module
     * @param {string} moduleId
     * @returns {Observable<FormStructureConfig>}
     */
    public getModuleFilterStructure(moduleId: string): Observable<FormStructureConfig> {
        return this.store.pipe(
            select(
                (state: AppState) => state.riskManager.structure[moduleId]
                    ? state.riskManager.structure[moduleId].triggerConfiguration
                    : null,
            ),
        );
    }

    /**
     * Get the filter values for the module
     * @param {string} moduleId
     * @returns {Observable<FormValues>}
     */
    public getModuleFilterValues(moduleId: string): Observable<FormValues> {
        return this.store.pipe(
            select(
                (state: AppState) => state.riskManager.values[moduleId]
                    ? state.riskManager.values[moduleId].triggerConfiguration
                    : null,
            ),
        );
    }

    public getWatchlistModulesData(watchlistId: string): Observable<WatchlistRiskManagerModuleData> {
        return this.store.pipe(
            select(
                (state: AppState) => state.riskManager.watchlist[watchlistId]
            ),
        );
    }

    public getWatchlistContext(watchlistId: string): Observable<RiskManagerWatchlistContext> {
        return this.store.pipe(
            select(
                (state: AppState) => state.riskManager.watchlistContext[watchlistId]
            ),
        );
    }

    /**
     * Get the watcher for the module
     * @param {string} moduleId
     * @returns {Observable<any>}
     */
    public getModuleWatcher(moduleId: string): Observable<any> {
        return this.store.pipe(
            select((state: AppState) => state.riskManager.watcher[moduleId]),
        );
    }

    /**
     * Get the current module context
     * @returns {Observable<any>}
     */
    public getCurrentModuleContext(): Observable<any> {
        return this.store.pipe(
            select(
                (state: AppState) => (state.riskManager.currentContext
                        ? state.riskManager.currentContext
                        : null
                ),
            ),
        );
    }

    /**
     * Get the product selection table structure for the module
     * @param moduleId
     * @returns {Observable<EntityTableConfig>}
     */
    public getProductSelectionTableStructure(moduleId): Observable<EntityTableConfig> {
        return this.store.pipe(
            select((state: AppState) => state.riskManager.productSelectionTableStructure[moduleId]),
        );
    }

    /**
     * Get the product selection table values for the module
     * @param {string} moduleId
     * @returns {Observable<Entity[]>}
     */
    public getProductSelectionTableValues(moduleId: string): Observable<Entity[]> {
        return this.store.pipe(
            select((state: AppState) => state.riskManager.productSelectionTableValues[moduleId]),
        );
    }

    /**
     * Get the product selection filter structure for the module
     * @param {string} moduleId
     * @returns {Observable<FormStructureConfig>}
     */
    public getProductSelectionFilterStructure(moduleId: string): Observable<FormStructureConfig> {
        return this.store.pipe(
            select((state: AppState) => state.riskManager.productSelectionFilterStructure[moduleId]),
        );
    }

    /**
     * Get recipient configuration structure for the module
     * @param {string} moduleId
     * @returns {Observable<any>}
     */
    public getModuleRecipientConfigurationStructure(moduleId: string): Observable<any> {
        return this.store.pipe(
            select(
                (state: AppState) =>
                    state.riskManager.structure[moduleId]
                        ? state.riskManager.structure[moduleId].recipientConfiguration
                        : null,
            ),
        );
    }

    /**
     * Get settings saved statusCode
     * @returns {Observable<boolean>}
     */
    public getSettingsSavedStatus(): Observable<boolean> {
        return this.store.pipe(
            select((state: AppState) => state.riskManager.settingsSaved),
        );
    }

    public getLoadingSingleModuleContext(): Observable<boolean> {
        return this.store.pipe(
            select((state: AppState) => state.riskManager.loadingContext),
        );
    }

    public getLoadingSingleModuleData(): Observable<boolean> {
        return this.store.pipe(
            select((state: AppState) => state.riskManager.loadingModuleData),
        );
    }

    public getLoadingWatchlistModules(): Observable<Dictionary<boolean>> {
        return this.store.pipe(
            select((state: AppState) => state.riskManager.loadingWatchlistData),
        );
    }

    public getLoadingWatchlistContext(): Observable<boolean> {
        return this.store.pipe(
            select((state: AppState) => state.riskManager.loadingWatchlistContext),
        );
    }

    public getWatchlistSavedStatus(): Observable<boolean> {
        return this.store.pipe(
            select((state: AppState) => state.riskManager.watchlistSaved),
        )
    }

    /** Actions **/

    /**
     * Load the list module context
     * @param {string} moduleId
     * @param productDq
     */
    public loadFactSheetModuleContext(moduleId: string, productDq: string) {
        this.store.dispatch(loadFactSheetContext(moduleId, productDq));
    }

    /**
     * Load the list module context
     * @param {string} moduleId
     */
    public loadListModuleContext(moduleId: string) {
        this.store.dispatch(loadListModuleContext(moduleId));
    }

    /**
     * Load the list module context
     * @param {string} moduleId
     * @param {string} productDq
     */
    public loadSingleModuleContext(moduleId: string, productDq: string) {
        this.store.dispatch(loadSingleModuleContext(moduleId, productDq));
    }

    public loadWatchlistContext(moduleId: string, watchlistId: string) {
        this.store.dispatch(loadWatchlistContext(moduleId, watchlistId));
    }

    public loadWatchlistModuleData(moduleId: string, context: RiskManagerContext) {
        this.store.dispatch(loadWatchlistModuleData(moduleId, context));
    }

    /**
     * Update the watcher for the module locally
     * @param {string} moduleId
     * @param watcherData
     */
    public updateWatcher(moduleId: string, watcherData: any) {
        this.store.dispatch(updateWatcher(moduleId, watcherData));
    }

    /**
     * Save the watcher for the module
     * @param {string} moduleId
     */
    public saveWatcher(moduleId: string) {
        combineLatest([
            this.getCurrentModuleContext().pipe(filter(truthy)),
            this.getModuleWatcher(moduleId).pipe(filter(truthy)),
        ]).pipe(
            take(1),
        ).subscribe(([context, watcher]) => {
            this.store.dispatch(saveWatcher(moduleId, context, watcher));
        });
    }

    public saveWatchers(moduleId: string, data: Record<'context' | 'watcher', any>[]) {
        this.store.dispatch(saveWatchers(moduleId, data));
    }

    /**
     * Load the product selection for the module
     * @param {string} moduleId
     */
    public loadProductSelection(moduleId: string) {
        this.store.dispatch(loadProductSelection(moduleId));
    }

    /**
     * Clear the module data for the specified module id
     * @param {string} moduleId
     */
    public clearModuleData(moduleId: string) {
        this.store.dispatch(clearModuleData(moduleId));
    }
}
