<div *ngIf="visibleInput else: textOnly" class="custom-controls-stacked">
    <label
        *ngFor="let value of values"
        class="custom-control"
        [class.custom-checkbox]="!config.options.switches"
        [class.custom-switch]="config.options.switches">

        <input
            [name]="config.path"
            type="checkbox"
            class="custom-control-input"
            [checked]="_values[value.id]"
            [disabled]="disabled"
            (change)="onCheckboxChange($event, value)">

        <span class="custom-control-indicator"></span>
        <span class="custom-control-description">{{value.label}}</span>
    </label>
</div>

<ng-template #textOnly>
    <ng-container *ngIf="!isObjectEmpty(_values)">
        <div class="ui-select-match mt-2">
            <div *ngFor="let value of values">
                <ng-container *ngIf="_values[value.id]">
                    <span>{{ value.label }}</span>
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isObjectEmpty(_values) || _values['NONE']">
        <span>&mdash;</span>
    </ng-container>
</ng-template>