import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DerifinApi } from '../../../../core/api/derifin.api';
import { ApiResponse } from '../../../../core/api/models/api.model';
import { FormValues } from '../../../../core/models/form.model';
import { AuthStoreService } from '../../../../core/services/auth-store.service';
import { ConfigurationService } from '../../../../core/services/configuration.service';
import { LogglyLoggerService } from '../../../../core/services/loggly-logger.service';

@Injectable()
export class ProductRecommendApi extends DerifinApi {

    constructor(http: HttpClient, authStoreService: AuthStoreService, loggerService: LogglyLoggerService, appConfigService: ConfigurationService) {
        super('recommendation', appConfigService.configuration.environment.id, http, authStoreService, loggerService, appConfigService);
    }

    public updateProductDericonRecommend(productDq: string, instrumentType: string, data: FormValues): Observable<boolean> {
        return this.post(instrumentType, { productDq, ...data }).pipe(
            map((response: ApiResponse<any>) => response.data),
        );
    }
}
