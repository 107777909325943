import { ErrorHandler, Injectable, Injector, NgModule } from '@angular/core';
import { GenericError } from '../errors/base.errors';
import { LogglyLoggerService } from './loggly-logger.service';

/**
 * Global error handler which catches all uncaught exceptions and sends them
 * to the logger service. This allows to keep the app running if exceptions
 * occur.
 */
@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
    constructor(private injector: Injector) {
        super();
    }

    public handleError(error) {
        console.error('An unexpected error occurred.', error);
        const errorDescription: GenericError = {
            name: error.name,
            message: error.message,
            type: 'generic',
            stack: error.stack,
            severity: 'ERROR',
        };
        const loggerService = this.injector.get(LogglyLoggerService);
        loggerService.log(errorDescription);
    }
}
