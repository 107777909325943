/**
 * Created by Alex Klein <alex@rocketloop.de> on 02/01/18.
 */

import { Action } from '@ngrx/store';
import {
    AddQuotesToSelectionAction,
    AddQuoteToSelectionAction,
    DPCActionTypes,
    GetQuoteOffersSucceededAction,
    GetRecentHistoryDataSucceededAction,
    GetRecentHistoryStructureSucceededAction,
    IssueProductSucceededAction,
    LoadDPCInitializeStructureSucceededAction,
    LoadDPCUnderlyingInfoBoxStructureSucceededAction,
    LoadDPCUnderlyingInfoBoxTabDataSucceededAction,
    LoadDPCUnderlyingInfoBoxTabStructureSucceededAction,
    LoadHistoryDataAction,
    LoadHistoryDataForRestoreSucceededAction,
    LoadHistoryDataSucceededAction,
    LoadHistoryStructureSucceededAction,
    LoadIssuedProductsDataAction,
    LoadIssuedProductsDataSucceededAction,
    LoadIssuedProductsStructureSucceededAction,
    RemoveQuoteFromSelectionAction,
    RemoveQuotesFromSelectionAction,
    RequestQuoteSucceededAction,
    SetCurrentUnderlyingInfoBoxTabIdAction,
    UpdateDPCConfigSucceededAction,
    UpdateQuoteRequestAction,
} from '../actions/dpc.actions';
import { DPCState } from '../state/dpc.state';

export const initialState: DPCState = {
    steps: {},
    summaryStructure: null,
    summaryValues: {},
    dataValues: {},
    quoteRequest: {},
    shadowRequest: {},
    currentStep: null,
    currentUnderlyingInfoBoxTab: null,
    factsheet: {
        structure: null,
        value: null,
    },
    quotesTable: {
        structure: null,
        values: null,
    },
    recentHistoryStructure: null,
    recentHistoryData: null,
    underlyingInfoBoxTabs: null,
    underlyingInfoBoxTabsData: {},
    issuedProductsFilterStructure: null,
    issuedProductsFilterValues: null,
    issuedProductsTableStructure: null,
    issuedProductsTableValues: null,
    loadingIssuedProductsTableValues: null,

    historyFilterStructure: null,
    historyFilterValues: null,
    historyTableStructure: null,
    historyTableValues: null,
    loadingHistoryTableValues: null,

    isDisplayingHistory: null,

    quoteSelection: [],
};

/**
 * The reducer responsible for the @link{DPCState}
 * @param state
 * @param action
 * @returns {any}
 */
export function reducer(state: DPCState = initialState,
                        action: Action): DPCState {
    switch (action.type) {

        case DPCActionTypes.LOAD_DPC_INITIALIZE_STRUCTURE_SUCCEEDED:
            const loadDPCInitializeStructureSucceeded = action as LoadDPCInitializeStructureSucceededAction;
            return {
                ...state,
                steps: Object.keys(loadDPCInitializeStructureSucceeded.payload.structure.steps).reduce(
                    (list, stepId) => {
                        list[stepId] = loadDPCInitializeStructureSucceeded.payload.structure.steps[stepId];
                        return list;
                    }, { ...state.steps }),
                summaryStructure: loadDPCInitializeStructureSucceeded.payload.structure.summary,
            };

        case DPCActionTypes.UPDATE_DPC_CONFIG_SUCCEEDED:
            const updateDPCConfigSucceededAction = action as UpdateDPCConfigSucceededAction;
            return {
                ...state,
                quoteRequest: updateDPCConfigSucceededAction.payload.config.data.quoteRequest,
                shadowRequest: updateDPCConfigSucceededAction.payload.config.data.shadowRequest,
                dataValues: {
                    ...state.dataValues,
                    ...updateDPCConfigSucceededAction.payload.config.data.values,
                },
                summaryValues: updateDPCConfigSucceededAction.payload.config.data.summary,
                currentStep: updateDPCConfigSucceededAction.payload.config.control.step,
                steps: {
                    ...state.steps,
                    ...updateDPCConfigSucceededAction.payload.config.structure,
                },
            };

        case DPCActionTypes.UPDATE_QUOTE_REQUEST:
            const updateQuoteRequest = action as UpdateQuoteRequestAction;
            return {
                ...state,
                quoteRequest: {
                    ...state.quoteRequest,
                    ...updateQuoteRequest.payload.changes,
                },
            };

        case DPCActionTypes.REQUEST_QUOTE_SUCCEEDED:
            const requestQuoteSucceededAction = action as RequestQuoteSucceededAction;
            return {
                ...state,
                factsheet: requestQuoteSucceededAction.payload.quote.factsheet,
                quotesTable: {
                    ...state.quotesTable,
                    structure: requestQuoteSucceededAction.payload.quote.table.structure,
                },
            };

        case DPCActionTypes.GET_QUOTE_OFFERS_SUCCEEDED:
            const getQuoteOffersSucceededAction = action as GetQuoteOffersSucceededAction;
            return {
                ...state,
                quotesTable: {
                    ...state.quotesTable,
                    values: getQuoteOffersSucceededAction.payload.offers,
                },
            };

        case DPCActionTypes.ISSUE_PRODUCT_SUCCEEDED:
            const issueProductSucceededAction = action as IssueProductSucceededAction;
            return {
                ...state,
                quotesTable: {
                    ...state.quotesTable,
                    values: issueProductSucceededAction.payload.offers,
                },
            };

        case DPCActionTypes.GET_RECENT_HISTORY_STRUCTURE_SUCCEEDED:
            const getRecentHistoryStructureSucceeded = action as GetRecentHistoryStructureSucceededAction;
            return {
                ...state,
                recentHistoryStructure: getRecentHistoryStructureSucceeded.payload.structure,
            };

        case DPCActionTypes.GET_RECENT_HISTORY_DATA_SUCCEEDED:
            const getRecentHistoryDataSucceeded = action as GetRecentHistoryDataSucceededAction;
            return {
                ...state,
                recentHistoryData: getRecentHistoryDataSucceeded.payload.data,
            };

        case DPCActionTypes.SET_CURRENT_UNDERLYING_INFO_BOX_TAB_ID:
            const setCurrentUnderlyingInfoBoxTabIdAction = action as SetCurrentUnderlyingInfoBoxTabIdAction;
            return {
                ...state,
                currentUnderlyingInfoBoxTab: setCurrentUnderlyingInfoBoxTabIdAction.payload.tabId,
            };

        case DPCActionTypes.LOAD_DPC_UNDERLYING_INFO_BOX_STRUCTURE_SUCCEEDED:
            const loadDPCUnderlyingInfoBoxStructureSucceededAction = action as LoadDPCUnderlyingInfoBoxStructureSucceededAction;
            return {
                ...state,
                underlyingInfoBoxTabs: loadDPCUnderlyingInfoBoxStructureSucceededAction.payload.structure.tabs,
            };

        case DPCActionTypes.LOAD_DPC_UNDERLYING_INFO_BOX_TAB_STRUCTURE_SUCCEEDED:
            const loadDPCUnderlyingInfoBoxTabStructureSucceededAction = action as LoadDPCUnderlyingInfoBoxTabStructureSucceededAction;
            return {
                ...state,
                underlyingInfoBoxTabsData: {
                    ...state.underlyingInfoBoxTabsData,
                    [loadDPCUnderlyingInfoBoxTabStructureSucceededAction.payload.tabId]: {
                        ...state.underlyingInfoBoxTabsData[loadDPCUnderlyingInfoBoxTabStructureSucceededAction.payload.tabId],
                        structure: loadDPCUnderlyingInfoBoxTabStructureSucceededAction.payload.structure,
                    },
                },
            };

        case DPCActionTypes.LOAD_DPC_UNDERLYING_INFO_BOX_TAB_DATA_SUCCEEDED:
            const loadDPCUnderlyingInfoBoxTabDataSucceededAction = action as LoadDPCUnderlyingInfoBoxTabDataSucceededAction;
            return {
                ...state,
                underlyingInfoBoxTabsData: {
                    ...state.underlyingInfoBoxTabsData,
                    [loadDPCUnderlyingInfoBoxTabDataSucceededAction.payload.tabId]: {
                        ...state.underlyingInfoBoxTabsData[loadDPCUnderlyingInfoBoxTabDataSucceededAction.payload.tabId],
                        values: loadDPCUnderlyingInfoBoxTabDataSucceededAction.payload.values,
                    },
                },
            };

        case DPCActionTypes.LOAD_ISSUED_PRODUCTS_STRUCTURE_SUCCEEDED:
            const loadIssuedProductsStructureSucceededAction = action as LoadIssuedProductsStructureSucceededAction;
            return {
                ...state,
                issuedProductsFilterStructure: loadIssuedProductsStructureSucceededAction.payload.structure.filter.structure.common,
                issuedProductsFilterValues: loadIssuedProductsStructureSucceededAction.payload.structure.filter.values,
                issuedProductsTableStructure: loadIssuedProductsStructureSucceededAction.payload.structure.table.structure,
            };

        case DPCActionTypes.LOAD_ISSUED_PRODUCTS_DATA:
            const loadIssuedProductsDataAction = action as LoadIssuedProductsDataAction;
            if (loadIssuedProductsDataAction.payload.offset === undefined || loadIssuedProductsDataAction.payload.offset === null) {
                return {
                    ...state,
                    loadingIssuedProductsTableValues: true,
                };
            } else {
                return state;
            }

        case DPCActionTypes.LOAD_ISSUED_PRODUCTS_DATA_SUCCEEDED:
            const loadIssuedProductsDataSucceededAction = action as LoadIssuedProductsDataSucceededAction;
            return {
                ...state,
                issuedProductsTableValues: (state.issuedProductsTableValues && !loadIssuedProductsDataSucceededAction.payload.clear) ? [
                    ...state.issuedProductsTableValues,
                    ...loadIssuedProductsDataSucceededAction.payload.data,
                ] : loadIssuedProductsDataSucceededAction.payload.data,
                loadingIssuedProductsTableValues: false,
            };

        case DPCActionTypes.LOAD_HISTORY_STRUCTURE_SUCCEEDED:
            const loadHistoryStructureSucceededAction = action as LoadHistoryStructureSucceededAction;
            return {
                ...state,
                historyFilterStructure: loadHistoryStructureSucceededAction.payload.structure.filter.structure.common,
                historyFilterValues: loadHistoryStructureSucceededAction.payload.structure.filter.values,
                historyTableStructure: loadHistoryStructureSucceededAction.payload.structure.table.structure,
            };

        case DPCActionTypes.LOAD_HISTORY_DATA:
            const loadHistoryDataAction = action as LoadHistoryDataAction;
            if (loadHistoryDataAction.payload.offset === undefined || loadHistoryDataAction.payload.offset === null) {
                return {
                    ...state,
                    loadingHistoryTableValues: true,
                };
            } else {
                return state;
            }

        case DPCActionTypes.LOAD_HISTORY_DATA_SUCCEEDED:
            const loadHistoryDataSucceededAction = action as LoadHistoryDataSucceededAction;
            return {
                ...state,
                historyTableValues: (state.historyTableValues && !loadHistoryDataSucceededAction.payload.clear) ? [
                    ...state.historyTableValues,
                    ...loadHistoryDataSucceededAction.payload.data,
                ] : loadHistoryDataSucceededAction.payload.data,
                loadingHistoryTableValues: false,
            };

        case DPCActionTypes.LOAD_HISTORY_DATA_FOR_RESTORE_SUCCEEDED:
            const loadHistoryDataForRestoreSucceededAction = action as LoadHistoryDataForRestoreSucceededAction;
            return {
                ...state,
                quoteRequest: loadHistoryDataForRestoreSucceededAction.payload.data.quoteRequest,
                steps: loadHistoryDataForRestoreSucceededAction.payload.data.filter.structure,
                dataValues: loadHistoryDataForRestoreSucceededAction.payload.data.filter.values,
                currentStep: 5,
                factsheet: loadHistoryDataForRestoreSucceededAction.payload.data.factsheet,
                summaryStructure: loadHistoryDataForRestoreSucceededAction.payload.data.summary.structure,
                summaryValues: loadHistoryDataForRestoreSucceededAction.payload.data.summary.values,
                quotesTable: loadHistoryDataForRestoreSucceededAction.payload.data.table,
            };

        case DPCActionTypes.RESET_DPC_STATE:
            return {
                ...state,
                quoteRequest: null,
                steps: null,
                dataValues: null,
                currentStep: 0,
                factsheet: null,
                summaryStructure: null,
                summaryValues: null,
                quotesTable: null,
            };

        case DPCActionTypes.ADD_QUOTE_TO_SELECTION: {
            const addQuoteToSelectionAction = action as AddQuoteToSelectionAction;
            const isInSelection = state.quoteSelection.indexOf(addQuoteToSelectionAction.payload.quoteId) > -1;
            return {
                ...state,
                quoteSelection: (isInSelection) ? state.quoteSelection : [
                    ...state.quoteSelection,
                    addQuoteToSelectionAction.payload.quoteId,
                ],
            };
        }

        case DPCActionTypes.ADD_QUOTES_TO_SELECTION: {
            const addQuotesToSelectionAction = action as AddQuotesToSelectionAction;
            return {
                ...state,
                quoteSelection: addQuotesToSelectionAction.payload.quoteIds,
            };
        }

        case DPCActionTypes.REMOVE_QUOTE_FROM_SELECTION: {
            const removeQuoteFromSelectionAction = action as RemoveQuoteFromSelectionAction;
            const index = state.quoteSelection.indexOf(removeQuoteFromSelectionAction.payload.quoteId);
            return {
                ...state,
                quoteSelection: (index > -1) ? [
                    ...state.quoteSelection.slice(0, index),
                    ...state.quoteSelection.slice(index + 1),
                ] : state.quoteSelection,
            };
        }

        case DPCActionTypes.REMOVE_QUOTES_FROM_SELECTION: {
            const removeQuotesFromSelectionAction = action as RemoveQuotesFromSelectionAction;
            return {
                ...state,
                quoteSelection: state.quoteSelection.filter((id) => removeQuotesFromSelectionAction.payload.quoteIds.indexOf(id) < 0),
            };
        }

        case DPCActionTypes.CLEAR_QUOTE_SELECTION:
            return {
                ...state,
                quoteSelection: [],
            };

        default:
            return state;
    }
}
