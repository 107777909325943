import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf, AsyncPipe } from '@angular/common';
import { IconComponent } from '../../../../../shared/components/icon/icon.component';
import { RouterLink } from '@angular/router';
import { ButtonComponent } from 'chroma-ui';
import * as ProductSelectionSelectors from '../../../../product-selection/store/selectors/product-selection.selectors';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-product-selection-indicator',
    templateUrl: './product-selection-indicator.component.html',
    standalone: true,
    imports: [IconComponent, NgIf, AsyncPipe, TranslocoModule, RouterLink, ButtonComponent]
})
export class ProductSelectionIndicatorComponent implements OnInit {

    public shelvedProductsCount$: Observable<number>;

    constructor(private store: Store) { }

    public ngOnInit() {
        this.shelvedProductsCount$ = this.store.select(ProductSelectionSelectors.selectShelvedProductCount);
    }
}
