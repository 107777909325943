/**
 * Created by Alex Klein <alex@rocketloop.de> on 5/16//17.
 */

import { Action } from '@ngrx/store';
import {
    FilterActionTypes,
    FilterAvailableValuesLoadedAction,
    FilterInitialValuesLoadedAction,
    FilterStructureLoadedAction,
    UpdateSpecificFilterOpenedAction,
} from '../actions/filter.actions';
import { FilterState } from '../state/filters.state';

export const initialState: FilterState = {
    structure: {},
    availableValues: {},
    initialValues: {},
    specificFilterIsOpen: {},
};

/**
 * The reducer responsible for the @link{FilterState}
 * @param state
 * @param action
 * @returns {any}
 */
export function filterReducer(state: FilterState = initialState, action: Action): FilterState {

    switch (action.type) {

        /**
         * Handle product filter fields loaded action
         */
        case FilterActionTypes.FILTER_STRUCTURE_LOADED:
            const filterStructureLoadedAction = action as FilterStructureLoadedAction;
            return {
                ...state,
                structure: {
                    ...state.structure,
                    [filterStructureLoadedAction.payload.productTypeId]: filterStructureLoadedAction.payload.config,
                },
            };

        /**
         * Handle product filter available values loaded action
         */
        case FilterActionTypes.FILTER_AVAILABLE_VALUES_LOADED:
            const filterAvailableValuesLoadedAction = action as FilterAvailableValuesLoadedAction;
            return {
                ...state,
                availableValues: {
                    ...state.availableValues,
                    [filterAvailableValuesLoadedAction.payload.productTypeId]: filterAvailableValuesLoadedAction.payload.availableValues,
                },
            };

        /**
         * Handle product filter initial values loaded action
         */
        case FilterActionTypes.FILTER_INITIAL_VALUES_LOADED:
            const filterInitialValuesLoadedAction = action as FilterInitialValuesLoadedAction;
            return {
                ...state,
                initialValues: {
                    ...state.availableValues,
                    [filterInitialValuesLoadedAction.payload.productTypeId]: filterInitialValuesLoadedAction.payload.initialValues,
                },
                specificFilterIsOpen: {
                    ...state.specificFilterIsOpen,
                    [filterInitialValuesLoadedAction.payload.productTypeId]:
                        !!filterInitialValuesLoadedAction.payload.initialValues.expandSpecific,
                },
            };

        /**
         * Handle update specific filter opened
         */
        case FilterActionTypes.UPDATE_SPECIFIC_FILTER_OPENED:
            const updateSpecificFilterOpenedAction = action as UpdateSpecificFilterOpenedAction;
            return {
                ...state,
                specificFilterIsOpen: {
                    ...state.specificFilterIsOpen,
                    [updateSpecificFilterOpenedAction.payload.id]: updateSpecificFilterOpenedAction.payload.open,
                },
            };

        default:
            return state;
    }
}
