import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Options, SlideComponent, Splide } from '@splidejs/splide';
import { ResizeService } from '../../../core/services/resize.service';

@Component({
    selector: 'app-slider',
    template: `
        <div class="navigation-wrapper">
            <div class="splide" #sliderRef>
                <div class="splide__arrows">
                    <button class="splide__arrow splide__arrow--prev entity-widget-market-indicator__prev-btn btn btn-sm btn-primary">&lt;</button>
                    <button class="splide__arrow splide__arrow--next entity-widget-market-indicator__next-btn btn btn-sm btn-primary">&gt;</button>
                </div>

                <div class="splide__track">
                    <div class="splide__list">
                        <ng-content></ng-content>
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: [
        './slider.component.scss',
    ],
    standalone: true
})
export class SliderComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @ViewChild('sliderRef', {static: true})
    public sliderRef: ElementRef<HTMLElement>;

    @Input() public pause = false;
    @Input() public numberOfItems = 0;
    @Input() public selectedItemIndex = -1;
    @Input() public splideOptions: Options = {
        type: 'loop',
        interval: 2000,
        perPage: 6,
        autoplay: true,
        perMove: 1,
        pauseOnFocus: false,
        slideFocus: false,
        pagination: false
    };

    @Output()
    public selectedItemIndexChanged: EventEmitter<number> = new EventEmitter<number>();

    public slider: Splide = null;

    constructor(
        private resizeService: ResizeService,
        private elRef: ElementRef,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.pause && this.slider) {
            if (this.pause) {
                this.slider.Components.Autoplay.pause();
            } else {
                this.slider.Components.Autoplay.play();
            }
        }

        if (changes.selectedItemIndex) {
            const currentIndex = changes.selectedItemIndex.currentValue;
            const previousIndex = changes.selectedItemIndex.previousValue;

            if (previousIndex !== -1) {
                this.toggleSelectedClassForSlide(previousIndex, false);
            }

            if (currentIndex !== -1) {
                this.toggleSelectedClassForSlide(currentIndex, true);
            }
        }
    }

    public toggleSelectedClassForSlide(slideIndex: number, enable: boolean) {
        if (this.slider) {
            this.slider.Components.Slides.filter((Slide: SlideComponent, index: number, Slides: SlideComponent[]) => {
                return (Slide.slideIndex === -1 && Slide.index === slideIndex) || Slide.slideIndex === slideIndex;
            }).forEach((item) => {
                if (enable) {
                    item.slide.classList.add('selected');
                } else {
                    item.slide.classList.remove('selected');
                }
            });
        }
    }

    public ngAfterViewInit() {
        this.slider = new Splide(this.sliderRef.nativeElement, this.splideOptions);
        this.slider.on('click', this.onClickSlide);

        const handleAddIsLast = () => {
            const visibleSlides = this.slider.Components.Slides.filter((Slide: SlideComponent) => {
                return Slide.slide.classList.contains('is-visible');
            });

            visibleSlides[visibleSlides.length - 1]?.slide.classList.add('is-last');
        };

        const handleRemoveIsLast = () => {
            this.slider.Components.Slides.forEach((Slide: SlideComponent, index: number, Slides: SlideComponent[]) => {
                Slide.slide.classList.remove('is-last');
            });
        };

        this.slider.on('move', handleRemoveIsLast);
        this.slider.on('moved', handleAddIsLast);

        this.slider.on('resize', () => {
            handleRemoveIsLast();
            handleAddIsLast();
        });

        this.slider.on('resized', () => {
            handleRemoveIsLast();
            handleAddIsLast();
        });

        setTimeout(() => {
            this.slider.mount();
        });
    }

    public onClickSlide = (slide: SlideComponent, event: MouseEvent) => {
        const itemIndex = slide.slideIndex === -1 ? slide.index : slide.slideIndex;
        this.selectedItemIndexChanged.emit(itemIndex === this.selectedItemIndex ? -1 : itemIndex);
    };

    public ngOnInit() {
        this.resizeService.addResizeEventListener(this.elRef.nativeElement.parentElement, (elem) => {
            const containerWidth = elem.offsetWidth;
            let perPage;

            if (containerWidth <= 250) {
                perPage = 1;
            } else if (containerWidth <= 575) {
                perPage = 2;
            } else if (containerWidth >= 576 && containerWidth <= 767) {
                perPage = 4;
            } else if (containerWidth >= 768 && containerWidth <= 1400) {
                perPage = 6;
            } else {
                perPage = 8;
            }

            this.slider.options = {
                perPage: Math.min(this.numberOfItems, +perPage),
            };
        });
    }

    public ngOnDestroy(): void {
        if (this.slider) {
            this.slider.destroy();
        }

        this.resizeService.removeResizeEventListener(this.elRef.nativeElement.parentElement);
    }

    public refresh() {

    }
}
