import { Component, EventEmitter, forwardRef, HostBinding, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { FormControlSelectOption } from '../../../../core/models/form.model';
import { SelectItem } from '../../../models/widgets.model';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-entity-widget-baskets-admin-button-group',
    template: `
        <div class="app-filter-radio-button-group">
            <div class="mat-button-toggle-group mat-button-toggle-group-appearance-standard">
                <div class="mat-button-toggle mat-button-toggle-appearance-standard default-button" [class.mat-button-toggle-checked]="value?.id === defaultValue?.id">
                    <button class="mat-button-toggle-button" type="button" tabindex="0" (click)="onClickButton($event, defaultValue)">
                        <div class="mat-button-toggle-label-content">{{defaultValue?.label}}</div>
                    </button>
                </div>
                <div class="mat-button-toggle mat-button-toggle-appearance-standard" [class.mat-button-toggle-checked]="value?.id === val?.id" *ngFor="let val of values; let i=index">
                    <button class="mat-button-toggle-button" type="button" [attr.tabindex]="i + 1" (click)="onClickButton($event, val)">
                        <div class="mat-button-toggle-label-content">{{val?.label}}</div>
                    </button>
                </div>
            </div>
        </div>
    `,
    styles: [`
        .mat-button-toggle-group-appearance-standard, .mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
            border-radius: 4px;
        }

        .mat-button-toggle-group, .mat-button-toggle-standalone {
            position: relative;
            display: inline-flex;
            flex-direction: row;
            white-space: nowrap;
            overflow: hidden;
            border-radius: 2px;
            -webkit-tap-highlight-color: transparent;
        }

        .mat-button-toggle-button {
            border: 0;
            background: 0 0;
            color: inherit;
            padding: 0;
            margin: 0;
            font: inherit;
            outline: 0;
            width: 100%;
            cursor: pointer;
        }

        .mat-button-toggle-label-content {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            display: inline-block;
            line-height: 36px;
            padding: 0 16px;
            position: relative;
        }

        .mat-button-toggle {
            white-space: nowrap;
            position: relative;
        }
    `],
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EntityWidgetBasketsAdminButtonGroupComponent),
            multi: true,
        }, {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => EntityWidgetBasketsAdminButtonGroupComponent),
            multi: true,
        }],
    standalone: true,
    imports: [NgFor]
})
export class EntityWidgetBasketsAdminButtonGroupComponent implements OnInit, OnChanges {
    @Input() public values: FormControlSelectOption[];
    @Input() public defaultValue: FormControlSelectOption;

    public onChange: () => void;
    public onTouched: () => void;
    public value: FormControlSelectOption;
    public disabled: boolean;

    constructor() {
    }

    public ngOnInit() {
    }

    public writeValue(value: any): void {
        if (this.value && this.value.id !== value) {
            this.value = this.values.find((v) => v.id === value);
        }
    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                fn(this.value.id);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        return null;
    }

    public setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
    }

    public onClickButton(event: MouseEvent, value: FormControlSelectOption) {
        if (this.value === value) {
            this.value = this.defaultValue;
        } else {
            this.value = value;
        }

        if (this.onChange) {
            this.onChange();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.values || changes.defaultValue) {
            if (!this.value) {
                this.value = this.defaultValue;
            }
        }
    }
}
