import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

import { isEqual } from 'lodash';
import { FormControlConfig, FormControlSelectOptionExtra } from '../../../core/models/form.model';
import { MultiValueFilterControl } from '../filter-control';
import { NgFor, NgIf } from '@angular/common';

/**
 * Filter multi select component is responsible for multi select filter where the user can select one or more items
 * that are displayed below the select
 */
@Component({
    selector: 'app-filter-multi-select-cells',
    templateUrl: './filter-multi-select-cells.component.html',
    styleUrls: ['./filter-multi-select-cells.component.scss'],
    standalone: true,
    imports: [NgFor, NgIf]
})
export class FilterMultiSelectCellsComponent implements OnInit, MultiValueFilterControl<string> {

    @Input() public config: FormControlConfig;

    @Input() public values: FormControlSelectOptionExtra[];

    @Input() public disabled = false;

    private _values: { [value: string]: boolean };

    public onChange: () => void;

    public onTouched: () => void;

    constructor() {
    }

    public ngOnInit() {
    }

    public textFormatter(format = '{0}', ...args) {
        return String(format).replace(/{(\d+)}/g, (match, num) => {
            return typeof args[num] !== 'undefined' ? args[num] : match;
        });
    }

    public getCellTitle(cell: FormControlSelectOptionExtra) {
        if (!this.config.options) {
            return 'label';
        }
        const titlePath = this.config.options.titlePath || 'label';

        if (this.config.options.titleFormat) {
            return this.textFormatter(this.config.options.titleFormat, cell[titlePath]);
        }

        return cell[titlePath];
    }

    public getCellSubtitle(cell: FormControlSelectOptionExtra) {
        if (!this.config.options) {
            return 'label';
        }
        const subtitlePath = this.config.options.subtitlePath || null;
        if (!subtitlePath) {
            return null;
        }

        if (this.config.options.subtitleFormat) {
            return this.textFormatter(this.config.options.subtitleFormat, cell[subtitlePath]);
        }

        return cell[subtitlePath];
    }

    /**
     * Writes the values for the multi select
     * @param {string[]} values
     */
    public writeValue(values: string[]): void {
        const transformedValues = (values) ? values.reduce((vals, value) => {
            vals[value] = true;
            return vals;
        }, {}) : {};

        if (!isEqual(this._values, transformedValues)) {
            this._values = transformedValues;
        }
    }

    /**
     * Checks if the filter value is valid
     * @param {string} value
     * @returns {boolean}
     * @private
     */
    protected _isValidValue(value: string) {
        if (this.values) {
            return this.values.reduce((hasValue, validValue) => {
                return value === validValue.id || hasValue;
            }, false);
        } else {
            return false;
        }

    }

    public registerOnChange(fn: any): void {
        this.onChange = () => {
            if (fn) {
                const values = Object.keys(this._values).filter((value) => this._values[value]);
                fn(values);
            }
        };
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    /**
     * Validates the filter control
     * @param {AbstractControl} c
     * @returns {ValidationErrors | any}
     */
    public validate(c: AbstractControl): ValidationErrors | any {
        const invalidValues = Object.keys(this._values).map((value) => {
            return {
                value,
                valid: this._isValidValue(value),
            };
        }).filter((v) => !v.valid);

        if (invalidValues.length > 0) {
            return {
                multiSelectError: {
                    message: 'Invalid values specified.',
                    invalidValues,
                },
            };
        } else {
            return null;
        }
    }

    public onCellClick(event, value: FormControlSelectOptionExtra) {
        event.preventDefault();

        if (this._values.hasOwnProperty(value.id)) {
            this._values[value.id] = false;
        } else {
            this._values[value.id] = true;
        }

        this.onChange();
    }

    public isItemSelected(value: FormControlSelectOptionExtra) {
        return this._values[value.id] === true;
    }
}
