import { getCurrencySymbol, NgIf, NgFor, AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges, } from '@angular/core';
import { Currency, TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { Options } from '@splidejs/splide';
import { cloneDeep, keyBy } from 'lodash';

import { BehaviorSubject } from 'rxjs';
import { EntityWidgetOptions } from '../../../models/widgets.model';
import { EntityWidgetContentComponent } from '../entity-widget/entity-widget.component';
import { SliderComponent } from '../../../components/slider/slider.component';

@Component({
    selector: 'app-entity-widget-market-indicator',
    templateUrl: './entity-widget-market-indicator.component.html',
    styleUrls: ['./entity-widget-market-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, SliderComponent, NgFor, AsyncPipe, TranslocoLocaleModule]
})
export class EntityWidgetMarketIndicatorComponent implements OnChanges, EntityWidgetContentComponent {
    public static disableTitle = true;
    @HostBinding('class.entity-widget-market-indicator') public focusListWidgetClass = true;

    @Input() public entityId: string;
    @Input() public width: number;
    @Input() public options: EntityWidgetOptions;
    @Input() public data: MarketIndicatorItem[] = [];
    @Output() public action: EventEmitter<{ type: string; payload: any }> = new EventEmitter();

    splideOptions: Options = {
        type: 'loop',
        interval: 2000,
        perPage: 6,
        autoplay: false,
        perMove: 1,
        pauseOnFocus: false,
        slideFocus: false,
        pagination: false,
        keyboard: false
    };

    public data$: BehaviorSubject<ProcessedMarketIndicatorItem[]> = new BehaviorSubject([]);
    public lastAnimate = 0;

    constructor() { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && this.data) {
            if (!this.data$.getValue() || !this.data$.getValue().length) {
                this.data$.next(cloneDeep(this.data));
            } else {
                const prevData = this.data$.getValue();
                const oldData = keyBy(prevData, 'dq');

                this.data.forEach((item) => {
                    const oldItem = oldData[item.dq];

                    if (oldItem) {
                        oldItem._less = [];
                        oldItem._more = [];

                        Object.keys(item).forEach((k) => {
                            if (item[k] > oldItem[k]) {
                                oldItem._more.push(k as MarketIndicatorItemKey);
                            } else if (item[k] < oldItem[k]) {
                                oldItem._less.push(k as MarketIndicatorItemKey);
                            }

                            oldItem[k] = item[k];
                        });
                    }
                });

                this.data$.next(prevData);
                this.lastAnimate = this.lastAnimate ? 0 : 1;
            }

        }
    }

    public getClassesForItem(startClasses: string[], item: ProcessedMarketIndicatorItem | any, key: MarketIndicatorItemKey) {
        const classes = [...startClasses];

        if (item._more && item._more.includes(key)) {
            classes.push('md-live-data--rising' + (this.lastAnimate ? '-1' : '-2'));
        } else if (item._less && item._less.includes(key)) {
            classes.push('md-live-data--falling' + (this.lastAnimate ? '-1' : '-2'));
        }

        return classes.join(' ');
    }

    public isCurrencySymbol(currency: Currency, ): boolean {
        return getCurrencySymbol(currency, 'narrow') !== currency;
    }
}

type MarketIndicatorItemKey = keyof MarketIndicatorItem;
type MarketIndicatorItemKeys = MarketIndicatorItemKey[];
type ProcessedMarketIndicatorItem = MarketIndicatorItem & {
    _more?: MarketIndicatorItemKeys; _less?: MarketIndicatorItemKeys;
};

export interface MarketIndicatorItem {
    dq: string;
    name: string;
    quote: number;
    currency: string;
    change: number;
    timestamp: string;
}
