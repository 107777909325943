import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { XAxisTicksComponent } from '@swimlane/ngx-charts';
import { NgFor, NgIf } from '@angular/common';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'g[app-charts-x-axis-ticks]',
    template: `
        <svg:g #ticksel>
            <svg:g *ngFor="let tick of ticks; let i=index" class="tick" [attr.transform]="tickTransform(tick)">
                <title>{{ tickFormat(tick) }}</title>
                <svg:text
                    stroke-width="0.01"
                    [attr.text-anchor]="tickTextAnchor(tick, i)"
                    [attr.transform]="tickTextTransform(tick, i)"
                    [style.font-size]="'12px'"
                >
                    {{ tickTrim(tickFormat(tick)) }}
                </svg:text>
            </svg:g>
        </svg:g>

        <svg:g *ngFor="let tick of ticks" [attr.transform]="tickTransform(tick)">
            <svg:g *ngIf="showGridLines" [attr.transform]="gridLineTransform()">
                <svg:line class="gridline-path gridline-path-vertical" [attr.y1]="-gridLineHeight" y2="0"/>
            </svg:g>
        </svg:g>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgFor, NgIf]
})
export class DynamicChartXAxisTicksComponent extends XAxisTicksComponent {
    @Input()
    public minimal = false;

    public tickTextTransform(tick, index) {
        if (index === 0 && this.minimal) {
            const tickText = this.tickFormat(tick).toString();
            let tickLength = tick.length;
            if (this.trimTicks) {
                tickLength = this.tickTrim(tickText).length;
            }

            const wordWidth = tickLength * 8;
            if (this.textTransform !== '' && wordWidth > 40) {
                return 'rotate(-90)';
            }
        }

        return this.textTransform;
    }

    public tickTextAnchor(tick, index) {
        if (index === 0 && this.minimal) {
            if (this.textTransform !== '') {
                return 'end';
            } else {
                return 'start';
            }
        }

        return this.textAnchor;
    }
}
