import { Directive, DoCheck, HostBinding, Input, Optional } from '@angular/core';
import { AbstractControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { cva } from 'cva';
import { ErrorStateMatcher } from '../core/error/error-options';
import { ChromaFormFieldControl } from '../form-field/form-field-control';

export const inputStyle = cva([
  'tw-w-full',
  'tw-text-black', 'placeholder:tw-text-gray-400',
  'tw-text-base', 'tw-leading-6',
  'focus:tw-outline-none',
  'autofill:tw-bg-clip-text'
]);

@Directive({
  selector: 'input[chromaInput]',
  host: {
    '(focus)': '_focusChanged(true)',
    '(blur)': '_focusChanged(false)'
  },
  standalone: true,
  providers: [{provide: ChromaFormFieldControl, useExisting: ChromaInput}]
})
export class ChromaInput implements ChromaFormFieldControl, DoCheck {

  @HostBinding('class')
  hostClasses = inputStyle();

  focused = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  protected _disabled = false;

  errorState = false;

  constructor(
    @Optional() private _parentForm: NgForm,
    @Optional() private _parentFormGroup: FormGroupDirective,
    private _defaultErrorStateMatcher: ErrorStateMatcher,
    private ngControl: NgControl
  ) { }

  _focusChanged(isFocused: boolean) {
    if (isFocused !== this.focused) {
      this.focused = isFocused;
    }
  }

  ngDoCheck() {
    if (this.ngControl) {
      this.updateErrorState()
    }
  }

  updateErrorState() {
    const oldState = this.errorState;
    const control = this.ngControl ? (this.ngControl.control as AbstractControl) : null;
    const parent = this._parentFormGroup || this._parentForm;
    const newState = this._defaultErrorStateMatcher.isErrorState(control, parent)

    if (newState !== oldState) {
      this.errorState = newState;
    }
  }

  onContainerClick() { }
}
