<form
    [formGroup]="widgetForm"
    (ngSubmit)="onSubmit()">
    <div class="tw-flex">
        <div class="tw-flex tw-gap-x-3 tw-mr-auto">
            <select
                class="custom-select"
                formControlName="bwlist">
                <option [value]="''">Bitte wählen</option>
                <ng-container *ngIf="dropDownValues">
                    <option [value]="item.id" *ngFor="let item of dropDownValues">{{ item.label }}</option>
                </ng-container>
            </select>
            <button 
                type="submit"
                chroma-button
                class="tw-whitespace-nowrap"
                [disabled]="!widgetForm.get('bwlist').value">{{ 'widgets.equivalenceCheck.buttonSubmit' | transloco }}</button>
        </div>
        <div class="tw-flex tw-gap-x-3">
            <button
                type="button"
                chroma-button
                appearance="outline"
                [disabled]="!isCommentsSavingEnabled(additionalData$ | async)"
                (click)="onSaveComments()">{{ 'widgets.equivalenceCheck.saveComments' | transloco }}</button>
            <button
                type="button"
                chroma-button
                appearance="outline"
                [disabled]="!isPDFExportEnabled(additionalData$ | async)"
                (click)="onClickPDFExport()">Als PDF exportieren</button>
        </div>
    </div>
</form>

<div class="text-center mt-4" *ngIf="(additionalData$ | async)?.loading">
    <div class="p-4 text-center">
        <app-spinner type="small-boxes"></app-spinner>
    </div>
    <div class="p-2 text-center">
        Bitte haben Sie einen Moment Geduld, der Äquivalenz-Check wird für Sie durchgeführt.
    </div>
</div>

<div class="alert alert-danger mt-4" *ngIf="(additionalData$ | async)?.error">
    Leider ist beim Äquivalenzcheck etwas schief gelaufen. Bitte wiederholen Sie den Vorgang oder informieren Sie einen Administrator über diesen Fehler.
</div>

<div class="mt-4 pt-2" *ngIf="(additionalData$ | async)?.data && !(additionalData$ | async)?.loading">
    <ng-container *ngIf="(additionalData$ | async)?.data?.products.length === 0; else dataList">
        <div class="alert alert-success">
            {{ 'widgets.equivalenceCheck.errorNoProducts' | transloco }}
        </div>
    </ng-container>

    <ng-template #dataList>
        <h6>{{ 'widgets.equivalenceCheck.foundProductsTitle' | transloco }}</h6>
        <form [formGroup]="commentsForm">
            <ng-container formArrayName="comments">
                <ul class="entity-widget-equivalence-check__list">
                    <li *ngFor="let product of (additionalData$ | async).data.products; let i=index">
                        <div class="row">
                            <div class="col-6">
                                <a [routerLink]="['/app', {'outlets': {'overlay': ['product-details', product.dq || '']}}]">{{ product.name }}</a>
                                <div>{{ product.wkn }} / {{ product.isin }}</div>
                                <div *ngIf="product.costs">Kosten: {{ product?.costs / 100 | translocoPercent }}</div>
                            </div>
                            <div class="col-6">
                                <ng-container [formGroupName]="i">
                                    <textarea
                                        class="form-control"
                                        [formControlName]="product.dq"></textarea>
                                </ng-container>
                            </div>
                        </div>
                    </li>
                </ul>
            </ng-container>
        </form>
    </ng-template>
</div>
